import React from 'react';
import styles from './ErrorBoundary.module.scss';
import { withTranslation } from 'react-i18next';
import Button from 'src/Widgets/common/basicElements/Button/Button';

// see also https://react.christmas/2018/14,
// https://reactjs.org/docs/error-boundaries.html

class ErrorBoundary extends React.Component {
    state = {
        error: null,
    };
    static getDerivedStateFromError(error) {
        // console.log("getDerivedStateFromError")
        // console.log(error)
        return { error };
    }
    componentDidMount() {
        // window.onerror = this.logError;
    }
    componentDidCatch(...args) {
        // console.log('componentDidCatch')
        // console.log(args)
        // this.logError(args);
    }
    // logError(args) {
    //     try {
    //         fetch('/error', {
    //             method: 'post',
    //             body: JSON.stringify(args),
    //         });
    //     } catch (e) {

    //     }
    // }
    render() {
        const { t } = this.props;
        if (this.state.error) {
            // console.log(this.state.error)
            return (
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <h2>{t('generic_error_msg')}</h2>
                        <Button
                            style={{ width: '100px' }}
                            onClick={() => { this.setState({ error: undefined }) }}
                            type="primary">
                            {t('reload')}
                        </Button>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}

export default withTranslation()(ErrorBoundary);