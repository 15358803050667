import GenericCalls from './genericCalls';

class APILMU {
    // LMU = language model update
    static async request(word, language, queryId, hideErrors) {
        return await GenericCalls.get('/api/lmu/word', {
            word: word,
            ll: language,
            qid: queryId,
        }, undefined, hideErrors);
    }
}

export default APILMU;
