export const mockClusters = [
    {
        cluster_number: 1,
        descriptive_title: 'Greatest Mock Data Ever',
        cluster_summary:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie fermentum dui ut sagittis. Praesent facilisis erat sed sodales volutpat. Integer justo lectus, egestas non porta nec, cursus et enim. Quisque vel ex gravida, luctus nibh non, aliquet nunc. Nam sit amet interdum metus, imperdiet auctor enim. Maecenas imperdiet vel purus sed viverra. Mauris vel ante imperdiet, vulputate sem et, elementum libero. Curabitur eu nunc tempor.',
        notification_ids: [
            {
                notification_id: 541318332,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'positive',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'CNBC',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 541318332,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'positive',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'CBS',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 541318332,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'positive',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'Fox News',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 541445253,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'neutral',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'CNN',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987666,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'negative',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'CNN',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987626,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'neutral',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'NBC',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987626,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'neutral',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'NBC',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987626,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'neutral',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'NBC',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987626,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'neutral',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'NBC',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987626,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'neutral',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'NBC',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            }
        ]
    },
    {
        cluster_number: 2,
        descriptive_title: 'Greatest Mock Data Ever2',
        cluster_summary: 'Phasellus euismod est ut elit elementum, nec hendrerit mi rutrum. Vestibulum ut dolor a dui pellentesque scelerisque quis eleifend lacus. Curabitur nec vulputate tellus. Morbi egestas erat in leo feugiat hendrerit.',
        notification_ids: [
            {
                notification_id: 541318332,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'negative',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'ABC',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 541445253,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'neutral',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'NBC',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987666,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'negative',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'CNN',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987666,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'negative',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'CNN',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987666,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'negative',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'CNN',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987666,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'negative',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'CNN',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987666,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'negative',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'CNN',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987666,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'negative',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'CNN',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            }
        ]
    },
    {
        cluster_number: 3,
        descriptive_title: 'Greatest Mock Data Ever3',
        cluster_summary: 'Sed et velit quis turpis luctus ultrices nec ac massa. Nunc sit amet neque eget justo feugiat dictum. Integer lobortis interdum est, eu tempor est pellentesque nec. Duis lacinia ante blandit tellus volutpat, vel scelerisque nunc bibendum. Integer porttitor pretium urna sed tempus. Nulla facilisi. Integer venenatis metus eu pharetra consectetur. ',
        notification_ids: [
            {
                notification_id: 541318332,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'positive',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'ATV',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 541445253,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'neutral',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'CNN',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            },
            {
                notification_id: 542987666,
                icon: '/mms/images/tv-icons/cnn.gif',
                sentiment: 'negative',
                kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
                channelName: 'CNN',
                channelNumber: 2,
                beginF: '2023-07-27 23:11:03',
                viewLink:
                    'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
            }
        ]
    }
];

export const mockCluster = {
    cluster_number: 1,
    descriptive_title: 'Greatest Mock Data Ever',
    cluster_summary:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie fermentum dui ut sagittis. Praesent facilisis erat sed sodales volutpat. Integer justo lectus, egestas non porta nec, cursus et enim. Quisque vel ex gravida, luctus nibh non, aliquet nunc. Nam sit amet interdum metus, imperdiet auctor enim. Maecenas imperdiet vel purus sed viverra. Mauris vel ante imperdiet, vulputate sem et, elementum libero. Curabitur eu nunc tempor.',
    notification_ids: [
        {
            notification_id: 541318332,
            icon: '/mms/images/tv-icons/cnn.gif',
            sentiment: 'positive',
            kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
            channelName: 'CNBC',
            channelNumber: 2,
            beginF: '2023-07-27 23:11:03',
            viewLink:
                'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
        },
        {
            notification_id: 541318332,
            icon: '/mms/images/tv-icons/cnn.gif',
            sentiment: 'positive',
            kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
            channelName: 'CBS',
            channelNumber: 2,
            beginF: '2023-07-27 23:11:03',
            viewLink:
                'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
        },
        {
            notification_id: 541318332,
            icon: '/mms/images/tv-icons/cnn.gif',
            sentiment: 'positive',
            kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
            channelName: 'Fox News',
            channelNumber: 2,
            beginF: '2023-07-27 23:11:03',
            viewLink:
                'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
        },
        {
            notification_id: 541445253,
            icon: '/mms/images/tv-icons/cnn.gif',
            sentiment: 'neutral',
            kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
            channelName: 'CNN',
            channelNumber: 2,
            beginF: '2023-07-27 23:11:03',
            viewLink:
                'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
        },
        {
            notification_id: 542987666,
            icon: '/mms/images/tv-icons/cnn.gif',
            sentiment: 'negative',
            kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
            channelName: 'CNN',
            channelNumber: 2,
            beginF: '2023-07-27 23:11:03',
            viewLink:
                'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
        },
        {
            notification_id: 542987626,
            icon: '/mms/images/tv-icons/cnn.gif',
            sentiment: 'neutral',
            kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
            channelName: 'NBC',
            channelNumber: 2,
            beginF: '2023-07-27 23:11:03',
            viewLink:
                'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
        },
        {
            notification_id: 542987626,
            icon: '/mms/images/tv-icons/cnn.gif',
            sentiment: 'neutral',
            kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
            channelName: 'NBC',
            channelNumber: 2,
            beginF: '2023-07-27 23:11:03',
            viewLink:
                'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
        },
        {
            notification_id: 542987626,
            icon: '/mms/images/tv-icons/cnn.gif',
            sentiment: 'neutral',
            kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
            channelName: 'NBC',
            channelNumber: 2,
            beginF: '2023-07-27 23:11:03',
            viewLink:
                'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
        },
        {
            notification_id: 542987626,
            icon: '/mms/images/tv-icons/cnn.gif',
            sentiment: 'neutral',
            kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
            channelName: 'NBC',
            channelNumber: 2,
            beginF: '2023-07-27 23:11:03',
            viewLink:
                'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
        },
        {
            notification_id: 542987626,
            icon: '/mms/images/tv-icons/cnn.gif',
            sentiment: 'neutral',
            kfLink: 'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM&kf=3',
            channelName: 'NBC',
            channelNumber: 2,
            beginF: '2023-07-27 23:11:03',
            viewLink:
                'https://next.emediamonitor.net/cgi-bin/player?target=AH6zTFOXKtFohF0c0tyVFzuz3OOYd_33w3AZ_BPR2hR3cbB-4X77wwVeRLWa-pZxT-xZ3MX4niY17zzD3id6oHleSo1c9TTWiRwe-0Q2RuM'
        }
    ]
}