
const useDateUtils = () => {

  const dateConverter = (date: Date) => {
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const day = String(date?.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getDateRange = (type: string, count: number) => {
    let toDate: string | Date = new Date();
        toDate.setDate(toDate.getDate() - 1);
        toDate = toDate.toISOString().split('T')[0];
        
    let fromDate = '';

    if (type === 'days') {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - count);
      fromDate = currentDate.toISOString().split('T')[0];
    } else if (type === 'weeks') {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - count * 7);
      fromDate = currentDate.toISOString().split('T')[0];
    } else if (type === 'months') {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1);
      fromDate = currentDate.toISOString().split('T')[0];
    }

    return [fromDate, toDate];
  };

  return {
    dateConverter,
    getDateRange,
  };
};

export default useDateUtils;
