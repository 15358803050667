//Pads numbers with a preceding 0 if the number is less than 10.
export function padWithZero(val: string | number): string {
    if (Number(val) < 10){
        return "0" + val;
    }

    return val.toString();
}

/**
 * Reorders items in an array by updating their `uiPosition` property, then sorting by uiPosition (and optionally marking affected items as updated)
 *
 * @template T - The type of items in the array, which must include a `uiPosition` property.
 * @param {T[]} items - The array of items to reorder.
 * @param {number} oldPos - The current position (`uiPosition`) of the item to be moved.
 * @param {number} newPos - The new desired position (`uiPosition`) for the item.
 * @param {boolean} [applyUpdatedProp=true] - Whether to add an `updated` flag to affected items.
 * @returns {T[]} A new array with reordered items and updated properties.
 */
export const reorderItems = <T extends { uiPosition: number, updated?: boolean }>(
    items: T[], 
    oldPos: number, 
    newPos: number,
    applyUpdatedProp: boolean = true,
): T[] => {
    // Clone to avoid mutating the original
    const updatedItems = items.map(item => ({ ...item }));

    // Find the item to move
    const itemToMove = updatedItems.find(item => item.uiPosition === oldPos);
    const newPosItem = updatedItems.find(item => item.uiPosition === newPos);
    if (!itemToMove || !newPosItem) return updatedItems; // No item found for source or target position - returning original items

    // Adjust positions for other items
    updatedItems.forEach(item => {
        if (newPos > oldPos) { // Moving down
            if (item.uiPosition > oldPos && item.uiPosition <= newPos) {
                item.uiPosition--;
                if (applyUpdatedProp) { item.updated = true; }
            }
        } else if (newPos < oldPos) { // Moving up
            if (item.uiPosition >= newPos && item.uiPosition < oldPos) {
                item.uiPosition++;
                if (applyUpdatedProp) { item.updated = true; }
            }
        }
    });

    // Update the position of the moved item
    itemToMove.uiPosition = newPos;
    itemToMove.updated = true;

    updatedItems.sort((a, b) => a.uiPosition - b.uiPosition);
    return updatedItems;
}