import React, {useEffect, useState} from 'react';
import styles from './LastX.module.scss';
import formStyles from 'src/Widgets/common/basicStyles/forms.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import SelectAsync from '../SelectAsync/SelectAsync';

function LastX(props) {
    const archive = props.archive ? props.me.archive : false;
    const units = [
        { label: props.t('days'), value: 'days', archive: archive ? archive.dbArchiveDays : undefined },
        { label: props.t('weeks'), value: 'weeks', archive: archive ? archive.dbArchiveWeeks : undefined },
        { label: props.t('months'), value: 'months', archive: archive ? archive.dbArchiveMonths : undefined },
    ];
    const [state, setState] = useState({
        unit: props.value ? _.find(units,{value: props.value.unit.value}) : units[0],
        number: props.value ? props.value.number : undefined,
    });

    useEffect(() => {
        if (props.value) {
            // ensure we update changed prop "value.number" if it gets changed after mount
            setState({
                ...state, number: props.value.number,
                unit: units.find((u)=> u.value === props.value.unit.value)
            });
        }
    },[props.value])

    let placeholderLastX = props.t("Select number of") + " " + state.unit.label;
    let numMax = archive ? state.unit.archive : 10; // future TODO (if required): allow changing default "10" if archive = false ?
    let numbers = _.map(_.range(1, (numMax+1)), (e) => {
        return { label: e, value: e };
    });

    let number = !props.value || Array.isArray(props.value) ? 7 : state.number;
    number = { value: number, label: number };
    let formStyle = props.useFormStyle !== false ? formStyles.select : undefined;

    return(
        <div className={styles.lastx} style={props.style}>
            <div className={styles.lastText}>{props.t('Last')}</div>
            <SelectAsync
                placeholder={placeholderLastX}
                options={numbers}
                value={number}
                onChange={(val) => {
                    setState({...state, number: val !== null ? val.value : 7});
                    if(val !== null && state.unit !== null){
                        let unit = _.cloneDeep(state.unit);
                        delete unit.archive;
                        props.onChange({ unit, number: val.value });
                    }
                }}
                isClearable={props.allowClear !== false}
                className={[styles.select, styles.number, formStyle].join(' ')}
            />
            <SelectAsync
                options={units}
                value={state.unit}
                onChange={(val) => {
                    
                    // set selected number to "1" (if number is not supported by new unit)
                    const number = state.number > _.find(units, {value: val.value}).archive
                        ? numbers[0].value
                        : state.number;
                    
                    setState({...state, unit: val, number});
                    if(number !== null && val !== null){
                        let unit = _.cloneDeep(val);
                        delete unit.archive;
                        props.onChange({ unit, number });
                    }
                }}
                className={[styles.select, styles.unit, formStyle].join(' ')}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    //theme: state.theme,
});
export default connect(mapStateToProps,{ /* insert redux actions here */ })(withTranslation()(LastX));