import styles from './ChannelGroupsNext.module.scss'
import GroupsContainer from './components/GroupsContainer/GroupsContainer'
import ChannelsTableContainer from './components/ChannelsTableContainer/ChannelsTableContainer'
import useSelectedChannelGroup from './hooks/useSelectedChannelGroup';
import useEditChannelGroup from './hooks/useEditChannelGroup';
import { useState } from 'react';

const ChannelGroupsNext = () => {

  const [isEditViewOpen, setIsEditViewOpen] = useState<boolean>(false);

  const {getSelectedGroup, selectGroupHandler} = useSelectedChannelGroup();
  const selectedGroup = getSelectedGroup();
  const {editView} = useEditChannelGroup(selectedGroup, selectGroupHandler, setIsEditViewOpen);

  return (
    <div className={styles.channelGroupsContainer}>
    {isEditViewOpen && editView}
        <GroupsContainer selectedGroup={selectedGroup} selectGroupHandler={selectGroupHandler} setIsEditViewOpen={setIsEditViewOpen}/>
        <ChannelsTableContainer getSelectedGroup={getSelectedGroup} selectGroupHandler={selectGroupHandler}/>
    </div>
  )
}

export default ChannelGroupsNext