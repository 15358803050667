import React from 'react'
import { useTranslation } from 'react-i18next';
import APIStripe from 'src/API/APIStripe';
import Button from 'src/Widgets/common/basicElements/Button/Button'

interface CheckoutProps{
    priceId: string,
    customerId: string,
    selectedCountryPackages: any,
}

function Checkout({ priceId, customerId, selectedCountryPackages }: CheckoutProps) {
  const { t } = useTranslation();
  // const form = APIStripe.getCheckoutFormDetails(priceId, customerId, selectedCountryPackages.length);

  return (<Button btnType='submit' type='success' onClick={async() => {
    const res = await APIStripe.getCheckoutFormDetails(priceId, customerId, selectedCountryPackages.length);
    window.location.href = res.url;
  }}>{t('Continue')}</Button>)

  // return (
  //   <form action={form.action} method="POST"
  //   // target='_blank'
  //   >
  //     {Object.keys(form.inputs).map((key) => {
  //       // @ts-ignore
  //       const input = form.inputs[key];
  //       return (<input type="hidden" name={key} id={key} value={input} />);
  //     })}
  //       {/* <input type="hidden" name="priceId" id="priceId" value={priceId} />
  //       <input type="hidden" name="customerId" id="customerId" value={customerId} />
  //       <input type="hidden" name="countryPackages" id="countryPackages" value={JSON.stringify(selectedCountryPackages)} /> */}
  //     <Button btnType='submit' type='success' onClick={async() => {
  //       const res = await APIStripe.getCheckoutFormDetails(priceId, customerId, selectedCountryPackages.length);
  //       console.log(res.url);
  //     }}>{t('Continue')}</Button>
  //   </form>
  // )
}

export default Checkout