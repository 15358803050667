import React, { Component } from 'react';
import styles from './IssueMsg.module.scss';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import Button from 'src/Widgets/common/basicElements/Button/Button';

// to be discussed: component might be used to be shown on rc != 0 (e.g. UserManager: click on user but backend returns rc!=0, no permission?)

class IssueMsg extends Component {
  static propTypes = {
    fullHeight:PropTypes.bool,
    showOptions: PropTypes.bool,
    optionKeys: PropTypes.array,  // array of keys to select subset of predefined options that should be shown to the user
  }

  render() {
    let { options, optionKeys } = this.props;

    if(!options){
      options = {
        5:[ // 5xx server error
          {
            key: 'reload',
            title: 'Reload'
            ,text: <><a href="/" onClick={window.location.reload}>Reload this page</a> right now and try to perform the action again.</>
          },
          {
            key: 'later',
            title: 'Try later'
            ,text: 'Try again later, e.g. in 30 minutes'
          },
          {
            key: 'otherServer',
            title: 'Use another server'
            ,text: 'You may try a different eMM server (please still note the respective implications that apply to each of them)'
          },
          // {
          //   title: 'Continue'
          //   ,text: <>
          //   <div>Continue (without reloading) and try to perform the action again or do some other tasks meanwhile</div>
          //   <Button type='secondary'>Continue</Button>
          //   </>
          // },
          // {
          //   title: 'Contact'
          //   ,text: 'Contact some@example.com'
          // }
        ]
      };
      options = options[5];
      if (optionKeys) {
        let selectedOpts = [];
        options.map((o) => {
          if (optionKeys.includes(o.key)) {
            selectedOpts.push(o);
          }
          return undefined;
        });
        options = selectedOpts;
      }
    }
    let classes = [styles.issueMsg];
    if(this.props.fullHeight){ classes.push(styles.fullHeight); }

    return (
      <div className={classNames(classes)}>
        <FontAwesomeIcon icon={faExclamationCircle} className={styles.icon} />
        <div className={styles.heading}>{this.props.t('generic-issue')}</div>
        { this.props.showOptions !== false ?
          <div className={styles.options}>
          These are the options to help you with resolving this issue:
          {
            options.map((o, idx)=>(
              <div key={idx} className={styles.option}>
                <div className={styles.heading}>{o.title}</div>
                {o.text}
              </div>
            ))
          }
        </div>
        :null}
      </div>
    );
  }
}

export default withTranslation()(IssueMsg);