import { useTranslation } from "react-i18next";
import ContactUs from "src/ContactUs/ContactUs";
import BackButton from "src/Widgets/common/basicElements/BackButton/BackButton";

function UserExpired({
    onGoBack
}) {
    const { t } = useTranslation();
    
    return (
        <div style={{marginBottom:'3.5rem'}}>
            <h2>{t('user_locked')}</h2>
            <p>{t('user_locked_details')}</p>
            {onGoBack &&
                <BackButton
                    onClick={onGoBack}
                />
            }
        </div>
    )
}

export default UserExpired;