const countryFlagsBundle = {
    "AD": require("./c_flags/ad.svg").default,
    "AE": require("./c_flags/ae.svg").default,
    "AF": require("./c_flags/af.svg").default,
    "AG": require("./c_flags/ag.svg").default,
    "AI": require("./c_flags/ai.svg").default,
    "AL": require("./c_flags/al.svg").default,
    "AM": require("./c_flags/am.svg").default,
    "AO": require("./c_flags/ao.svg").default,
    "AQ": require("./c_flags/aq.svg").default,
    "AR": require("./c_flags/ar.svg").default,
    "AS": require("./c_flags/as.svg").default,
    "AT": require("./c_flags/at.svg").default,
    "AU": require("./c_flags/au.svg").default,
    "AW": require("./c_flags/aw.svg").default,
    "AX": require("./c_flags/ax.svg").default,
    "AZ": require("./c_flags/az.svg").default,
    "BA": require("./c_flags/ba.svg").default,
    "BB": require("./c_flags/bb.svg").default,
    "BD": require("./c_flags/bd.svg").default,
    "BE": require("./c_flags/be.svg").default,
    "BF": require("./c_flags/bf.svg").default,
    "BG": require("./c_flags/bg.svg").default,
    "BH": require("./c_flags/bh.svg").default,
    "BI": require("./c_flags/bi.svg").default,
    "BJ": require("./c_flags/bj.svg").default,
    "BL": require("./c_flags/bl.svg").default,
    "BM": require("./c_flags/bm.svg").default,
    "BN": require("./c_flags/bn.svg").default,
    "BO": require("./c_flags/bo.svg").default,
    "BQ": require("./c_flags/bq.svg").default,
    "BR": require("./c_flags/br.svg").default,
    "BS": require("./c_flags/bs.svg").default,
    "BT": require("./c_flags/bt.svg").default,
    "BV": require("./c_flags/bv.svg").default,
    "BW": require("./c_flags/bw.svg").default,
    "BY": require("./c_flags/by.svg").default,
    "BZ": require("./c_flags/bz.svg").default,
    "CA": require("./c_flags/ca.svg").default,
    "CC": require("./c_flags/cc.svg").default,
    "CD": require("./c_flags/cd.svg").default,
    "CF": require("./c_flags/cf.svg").default,
    "CG": require("./c_flags/cg.svg").default,
    "CH": require("./c_flags/ch.svg").default,
    "CI": require("./c_flags/ci.svg").default,
    "CK": require("./c_flags/ck.svg").default,
    "CL": require("./c_flags/cl.svg").default,
    "CM": require("./c_flags/cm.svg").default,
    "CN": require("./c_flags/cn.svg").default,
    "CO": require("./c_flags/co.svg").default,
    "CR": require("./c_flags/cr.svg").default,
    "CU": require("./c_flags/cu.svg").default,
    "CV": require("./c_flags/cv.svg").default,
    "CW": require("./c_flags/cw.svg").default,
    "CX": require("./c_flags/cx.svg").default,
    "CY": require("./c_flags/cy.svg").default,
    "CZ": require("./c_flags/cz.svg").default,
    "DE": require("./c_flags/de.svg").default,
    "DJ": require("./c_flags/dj.svg").default,
    "DK": require("./c_flags/dk.svg").default,
    "DM": require("./c_flags/dm.svg").default,
    "DO": require("./c_flags/do.svg").default,
    "DZ": require("./c_flags/dz.svg").default,
    "EC": require("./c_flags/ec.svg").default,
    "EE": require("./c_flags/ee.svg").default,
    "EG": require("./c_flags/eg.svg").default,
    "EH": require("./c_flags/eh.svg").default,
    "ER": require("./c_flags/er.svg").default,
    "ES": require("./c_flags/es.svg").default,
    "ET": require("./c_flags/et.svg").default,
    "FI": require("./c_flags/fi.svg").default,
    "FJ": require("./c_flags/fj.svg").default,
    "FK": require("./c_flags/fk.svg").default,
    "FM": require("./c_flags/fm.svg").default,
    "FO": require("./c_flags/fo.svg").default,
    "FR": require("./c_flags/fr.svg").default,
    "GA": require("./c_flags/ga.svg").default,
    "GB-ENG": require("./c_flags/gb-eng.svg").default,
    "GB-NIR": require("./c_flags/gb-nir.svg").default,
    "GB-SCT": require("./c_flags/gb-sct.svg").default,
    "GB-WLS": require("./c_flags/gb-wls.svg").default,
    "GB": require("./c_flags/gb.svg").default,
    "GD": require("./c_flags/gd.svg").default,
    "GE": require("./c_flags/ge.svg").default,
    "GF": require("./c_flags/gf.svg").default,
    "GG": require("./c_flags/gg.svg").default,
    "GH": require("./c_flags/gh.svg").default,
    "GI": require("./c_flags/gi.svg").default,
    "GL": require("./c_flags/gl.svg").default,
    "GM": require("./c_flags/gm.svg").default,
    "GN": require("./c_flags/gn.svg").default,
    "GP": require("./c_flags/gp.svg").default,
    "GQ": require("./c_flags/gq.svg").default,
    "GR": require("./c_flags/gr.svg").default,
    "GS": require("./c_flags/gs.svg").default,
    "GT": require("./c_flags/gt.svg").default,
    "GU": require("./c_flags/gu.svg").default,
    "GW": require("./c_flags/gw.svg").default,
    "GY": require("./c_flags/gy.svg").default,
    "HK": require("./c_flags/hk.svg").default,
    "HM": require("./c_flags/hm.svg").default,
    "HN": require("./c_flags/hn.svg").default,
    "HR": require("./c_flags/hr.svg").default,
    "HT": require("./c_flags/ht.svg").default,
    "HU": require("./c_flags/hu.svg").default,
    "ID": require("./c_flags/id.svg").default,
    "IE": require("./c_flags/ie.svg").default,
    "IL": require("./c_flags/il.svg").default,
    "IM": require("./c_flags/im.svg").default,
    "IN": require("./c_flags/in.svg").default,
    "IO": require("./c_flags/io.svg").default,
    "IQ": require("./c_flags/iq.svg").default,
    "IR": require("./c_flags/ir.svg").default,
    "IS": require("./c_flags/is.svg").default,
    "IT": require("./c_flags/it.svg").default,
    "JE": require("./c_flags/je.svg").default,
    "JM": require("./c_flags/jm.svg").default,
    "JO": require("./c_flags/jo.svg").default,
    "JP": require("./c_flags/jp.svg").default,
    "KE": require("./c_flags/ke.svg").default,
    "KG": require("./c_flags/kg.svg").default,
    "KH": require("./c_flags/kh.svg").default,
    "KI": require("./c_flags/ki.svg").default,
    "KM": require("./c_flags/km.svg").default,
    "KN": require("./c_flags/kn.svg").default,
    "KP": require("./c_flags/kp.svg").default,
    "KR": require("./c_flags/kr.svg").default,
    "KW": require("./c_flags/kw.svg").default,
    "KY": require("./c_flags/ky.svg").default,
    "KZ": require("./c_flags/kz.svg").default,
    "LA": require("./c_flags/la.svg").default,
    "LB": require("./c_flags/lb.svg").default,
    "LC": require("./c_flags/lc.svg").default,
    "LI": require("./c_flags/li.svg").default,
    "LK": require("./c_flags/lk.svg").default,
    "LR": require("./c_flags/lr.svg").default,
    "LS": require("./c_flags/ls.svg").default,
    "LT": require("./c_flags/lt.svg").default,
    "LU": require("./c_flags/lu.svg").default,
    "LV": require("./c_flags/lv.svg").default,
    "LY": require("./c_flags/ly.svg").default,
    "MA": require("./c_flags/ma.svg").default,
    "MC": require("./c_flags/mc.svg").default,
    "MD": require("./c_flags/md.svg").default,
    "ME": require("./c_flags/me.svg").default,
    "MF": require("./c_flags/mf.svg").default,
    "MG": require("./c_flags/mg.svg").default,
    "MH": require("./c_flags/mh.svg").default,
    "MK": require("./c_flags/mk.svg").default,
    "ML": require("./c_flags/ml.svg").default,
    "MM": require("./c_flags/mm.svg").default,
    "MN": require("./c_flags/mn.svg").default,
    "MO": require("./c_flags/mo.svg").default,
    "MP": require("./c_flags/mp.svg").default,
    "MQ": require("./c_flags/mq.svg").default,
    "MR": require("./c_flags/mr.svg").default,
    "MS": require("./c_flags/ms.svg").default,
    "MT": require("./c_flags/mt.svg").default,
    "MU": require("./c_flags/mu.svg").default,
    "MV": require("./c_flags/mv.svg").default,
    "MW": require("./c_flags/mw.svg").default,
    "MX": require("./c_flags/mx.svg").default,
    "MY": require("./c_flags/my.svg").default,
    "MZ": require("./c_flags/mz.svg").default,
    "NA": require("./c_flags/na.svg").default,
    "NC": require("./c_flags/nc.svg").default,
    "NE": require("./c_flags/ne.svg").default,
    "NF": require("./c_flags/nf.svg").default,
    "NG": require("./c_flags/ng.svg").default,
    "NI": require("./c_flags/ni.svg").default,
    "NL": require("./c_flags/nl.svg").default,
    "NO": require("./c_flags/no.svg").default,
    "NP": require("./c_flags/np.svg").default,
    "NR": require("./c_flags/nr.svg").default,
    "NU": require("./c_flags/nu.svg").default,
    "NZ": require("./c_flags/nz.svg").default,
    "OM": require("./c_flags/om.svg").default,
    "PA": require("./c_flags/pa.svg").default,
    "PE": require("./c_flags/pe.svg").default,
    "PF": require("./c_flags/pf.svg").default,
    "PG": require("./c_flags/pg.svg").default,
    "PH": require("./c_flags/ph.svg").default,
    "PK": require("./c_flags/pk.svg").default,
    "PL": require("./c_flags/pl.svg").default,
    "PM": require("./c_flags/pm.svg").default,
    "PN": require("./c_flags/pn.svg").default,
    "PR": require("./c_flags/pr.svg").default,
    "PS": require("./c_flags/ps.svg").default,
    "PT": require("./c_flags/pt.svg").default,
    "PW": require("./c_flags/pw.svg").default,
    "PY": require("./c_flags/py.svg").default,
    "QA": require("./c_flags/qa.svg").default,
    "RE": require("./c_flags/re.svg").default,
    "RO": require("./c_flags/ro.svg").default,
    "RS": require("./c_flags/rs.svg").default,
    "RU": require("./c_flags/ru.svg").default,
    "RW": require("./c_flags/rw.svg").default,
    "SA": require("./c_flags/sa.svg").default,
    "SB": require("./c_flags/sb.svg").default,
    "SC": require("./c_flags/sc.svg").default,
    "SD": require("./c_flags/sd.svg").default,
    "SE": require("./c_flags/se.svg").default,
    "SG": require("./c_flags/sg.svg").default,
    "SH": require("./c_flags/sh.svg").default,
    "SI": require("./c_flags/si.svg").default,
    "SJ": require("./c_flags/sj.svg").default,
    "SK": require("./c_flags/sk.svg").default,
    "SL": require("./c_flags/sl.svg").default,
    "SM": require("./c_flags/sm.svg").default,
    "SN": require("./c_flags/sn.svg").default,
    "SO": require("./c_flags/so.svg").default,
    "SR": require("./c_flags/sr.svg").default,
    "SS": require("./c_flags/ss.svg").default,
    "ST": require("./c_flags/st.svg").default,
    "SV": require("./c_flags/sv.svg").default,
    "SX": require("./c_flags/sx.svg").default,
    "SY": require("./c_flags/sy.svg").default,
    "SZ": require("./c_flags/sz.svg").default,
    "TC": require("./c_flags/tc.svg").default,
    "TD": require("./c_flags/td.svg").default,
    "TF": require("./c_flags/tf.svg").default,
    "TG": require("./c_flags/tg.svg").default,
    "TH": require("./c_flags/th.svg").default,
    "TJ": require("./c_flags/tj.svg").default,
    "TK": require("./c_flags/tk.svg").default,
    "TL": require("./c_flags/tl.svg").default,
    "TM": require("./c_flags/tm.svg").default,
    "TN": require("./c_flags/tn.svg").default,
    "TO": require("./c_flags/to.svg").default,
    "TR": require("./c_flags/tr.svg").default,
    "TT": require("./c_flags/tt.svg").default,
    "TV": require("./c_flags/tv.svg").default,
    "TW": require("./c_flags/tw.svg").default,
    "TZ": require("./c_flags/tz.svg").default,
    "UA": require("./c_flags/ua.svg").default,
    "UG": require("./c_flags/ug.svg").default,
    "UM": require("./c_flags/um.svg").default,
    "US": require("./c_flags/us.svg").default,
    "UY": require("./c_flags/uy.svg").default,
    "UZ": require("./c_flags/uz.svg").default,
    "VA": require("./c_flags/va.svg").default,
    "VC": require("./c_flags/vc.svg").default,
    "VE": require("./c_flags/ve.svg").default,
    "VG": require("./c_flags/vg.svg").default,
    "VI": require("./c_flags/vi.svg").default,
    "VN": require("./c_flags/vn.svg").default,
    "VU": require("./c_flags/vu.svg").default,
    "WF": require("./c_flags/wf.svg").default,
    "WS": require("./c_flags/ws.svg").default,
    "XK": require("./c_flags/xk.svg").default,
    "YE": require("./c_flags/ye.svg").default,
    "YT": require("./c_flags/yt.svg").default,
    "ZA": require("./c_flags/za.svg").default,
    "ZM": require("./c_flags/zm.svg").default,
    "ZW": require("./c_flags/zw.svg").default,
};

export default countryFlagsBundle;