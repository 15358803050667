import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import APISettings from "src/API/APISettings";

interface UserForgotPasswordProps{
    initUsername: string
    setSentPwLink: Function
    setSendPwLinkError: Function
    text: JSX.Element
    onUsernameChanged?: Function
    setUsername: Function
    username: string
    history: any
}

export const useForgotPassword = ({initUsername, setSentPwLink, setSendPwLinkError, text, onUsernameChanged, setUsername, username,history}:UserForgotPasswordProps) => {

    const { t } = useTranslation();

    useEffect(() => {
        setUsername(initUsername);
    }, [])

    const sendResetPasswordLink = async () => {
        const res = await APISettings.sendResetPasswordLink(username,['20196', '20198', '20199']);
        if (res.rc === 0) {
            setSentPwLink(true);
        } else {
            switch (res.rc) {
                case '20196': history.push('/user-problem');
                    break;
                case '20198': history.push('/user-locked');
                    break;
                case '20199': history.push('/user-expired');
                    break;
                default: setSendPwLinkError(res?.msg);
                    break;
            }
        }
    }

    const handleUsernameChange = (event: any) => {
        const username = event?.target?.value;
        setSentPwLink(false);
        setSendPwLinkError(undefined);
        setUsername(username);

        if (onUsernameChanged) {
            onUsernameChanged(event);
        }
    }

    const renderText = () => {
        return (
            <div style={{ flexDirection: 'column' }}>
                {text
                    ? text
                    : t('forgot_pw_send_email_text')
                }
            </div>
        )
    }

    return {
        sendResetPasswordLink,
        handleUsernameChange,
        renderText
    }
}