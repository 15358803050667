import React from 'react';
import styles from './GroupCardPagination.module.css';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';

class GroupCardPagination extends React.Component {
    static propTypes = {
        currentPage: PropTypes.number.isRequired,
        numberOfItems: PropTypes.number.isRequired,
        itemsPerPage: PropTypes.number.isRequired,
        onNextClick: PropTypes.func.isRequired,
        onPrevClick: PropTypes.func.isRequired,
    }
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { currentPage, numberOfItems, itemsPerPage, t, onNextClick, onPrevClick } = this.props;
        const numberOfPages = Math.ceil(numberOfItems / itemsPerPage);
        const hasPrevPage = currentPage - 1 > 0;
        const hasNextPage = currentPage + 1 <= numberOfPages;
        const dir = this.props.i18n.dir();

        if (numberOfPages > 1) {
            return(
                <span className={styles.pageCtrl}>
                    <FontAwesomeIcon
                        icon={dir === 'rtl' ? 'chevron-right' : 'chevron-left'}
                        alt={t("Back")}
                        title={t("Back")}
                        className={
                            classNames(
                                styles.arrow,
                                !hasPrevPage ? styles.arrowDisabled : ''
                            )
                        }
                        onClick={hasPrevPage ? onPrevClick : null}
                        size="2x"
                    />

                    <span style={{direction: 'ltr'}}>{currentPage} / {numberOfPages}</span>
                
                    <FontAwesomeIcon
                        icon={dir === 'rtl' ? 'chevron-left' : 'chevron-right'}
                        alt={t("Next")}
                        title={t("Next")}
                        className={
                            classNames(
                                styles.arrow,
                                !hasNextPage ? styles.arrowDisabled : ''
                            )
                        }
                        onClick={hasNextPage ? onNextClick : null}
                        size="2x"
                    />
                </span>
            )
        } else {
            return null;
        }
    }
}
export default withTranslation()(GroupCardPagination);