import styles from './Tag.module.scss';
import { TagProps } from './Tag.types';

function Tag({
    style,
    children,
    className
}:TagProps){
    return (
        <span
            className={[styles.tag, className].join(' ')}
            style={{
                // color: 'black',
                ...style
            }}
        >
            {children}
        </span>
    )
}

export default Tag;