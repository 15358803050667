import { useTranslation } from 'react-i18next';
import styles from './Divider.module.scss';

function Divider({
    children
}) {
    return (
        <div className={styles.divider}><hr />{children}<hr /></div>
    )
}
export default Divider