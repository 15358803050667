import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createDefaultWorkspaces, getWeekdays } from "src/Widgets/common/helpers";
import { setCurrentWorkspace, setLoadingGlobal } from "src/redux/actions/actions.board";
import defaultWorkspaces from 'src/Board/defaultWorkspaces/mentionFindr';
import APIMe from "src/API/APIMe";
import APIOptions from "src/API/APIOptions";
import APISettings from "src/API/APISettings";
import LoadingSpinner from "src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner";
import { getWorkspaces } from "src/redux/actions/actions.workspaces";
import LabeledSwitch from "src/Widgets/common/basicElements/SwitchElements/LabeledSwitch";
import styles from './MfSettings.module.scss';
//@ts-ignore
import Select from 'react-select';
import Button from "src/Widgets/common/basicElements/Button/Button";
import { SelectAsyncTypes } from "src/Widgets/common/basicElements/SelectAsync/SelectAsync.types";
import Popup from "src/Widgets/common/basicElements/Popup/Popup";
import { HelpersTypes } from "src/Widgets/common/helpers.types";
import Link from "src/Widgets/common/basicElements/Link/Link";

function MfSettings({history}:any) {
    const [settings, setSettings] = useState({
        timezone: '',
        itemsPP: '',
        username: '',
        maxDownloadLength: 0,
      });
    const [edited, setEdited] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [warningText, setWarningText] = useState('');
    const [timezones, setTimezones] = useState([]);
    const [loadingMe, setLoadingMe] = useState(true);

    // @ts-ignore
    const theme = useSelector((state) => state.theme);

    const {t} = useTranslation();
    const dispatch = useDispatch();
    // @ts-ignore
    const workspaces = useSelector(state => state.workspaces)


    useEffect(() => {
        let isMounted = true;
        dispatch(setCurrentWorkspace(undefined))
      const fetchData = async () => {
        try {
          const me = (await APIMe.get()).data;
          const tzs = (await APIOptions.getTimezones()).data;
        //   const fetched_userSettings = await APISettings.getItems('general');
        //   let userSettings = fetched_userSettings?.data.find((item) => item.key === 'accountSettings');
        //   if (userSettings === undefined) {
        //     userSettings = fetched_userSettings?.data?.find((e) => e.themeName !== undefined);
        //   }
  
          if (isMounted) {
            setSettings(me);
            setTimezones(tzs);
            setLoadingMe(false);
          }
        } catch (error) {
          console.error(error);
          setLoadingMe(false);
        }
      };
  
      fetchData();
  
      return () => {
        isMounted = false;
      };
    }, []);

      const save = async () => {
        const timezoneObj:any = timezones.find((item:any) => item.id === settings.timezone);
        await APIMe.put({ TZ: timezoneObj.title, itemsPP: settings.itemsPP });
        // const settingsUpdate = {
        //   ...userSettings,
        //   key: USER_SETTINGS.GENERAL_KEYS.ACCOUNT_SETTINGS,
        // };
    
        // if (userSettings.id !== undefined) {
        //   // if one item already exists, update the existing item in 'general' settings
        //   APISettings.putItem('general', userSettings.id, settingsUpdate);
        // } else {
        //   // create a new item in general settings
        //   APISettings.postItem('general', settingsUpdate);
        // }
    
        if (edited) {
        //   dispatch(updateAccountSettings(settingsUpdate))
          setEdited(false);
        }
      };

      const handleConfirm = async () => {
        setShowPopup(false);
        dispatch(setLoadingGlobal(true))
        await Promise.all(
          workspaces?.map(async (workspace:any) => {
            await Promise.all(
              workspace.widgets?.map(async (w:any) => {
                await APISettings.deleteItem('widgets', w.dbId);
              })
            );
            await APISettings.deleteItem('workspaces', workspace.id);
          })
        );
        await createDefaultWorkspaces(defaultWorkspaces);
    
        dispatch(setLoadingGlobal(false))
        dispatch(getWorkspaces())
        history?.push('/workspaces/1');
      };

      const handleCancel = () => {
        setShowPopup(false);
      };

      const resetWorkspacesHandler = () => {
        const warningText = t(
          'Do you really want to delete all current workspaces and recreate the default workspaces?'
        );
        setShowPopup(true);
        setWarningText(warningText);
      };
    
    if (loadingMe) {
        return <LoadingSpinner />;
    }

    const handleChange = (key:string, value:any) => {
      setSettings((prevState) => ({
        ...prevState,
        [key]: value,
      }));
      setEdited(true);
    };
    
    const timezoneOptions = timezones.map((tz:any) => ({
      label: tz.title,
      value: tz.id,
    }));  
        
       
    const timezoneSelected = timezoneOptions.find((tz) => tz.label === settings.timezone);   
        
    const languageList = window.navigator.languages.slice(0, 3);

    const renderWeekdays = () => {
      return getWeekdays()?.map((d:HelpersTypes.Weekday, i:number) => (
        <LabeledSwitch
              key={i}
            // @ts-ignore
          checked={settings.weekend?.includes(d.id)}
          disabled
          className={styles.labeledSwitch}
          onChange={() => {}}
        >
          {d.text}
        </LabeledSwitch>
      ));
    };

    return (
        <section className={styles.mainWrapper}>
            <h2 style={{color:theme.primary}}>{t('Settings')}</h2>
                    <div className={styles.optionsWrap}>
                        <span className={styles.label}>{t('Timezone')}:</span>
                        <Select
                            className={styles.select}
                            placeholder={t('Select Timezone') + '*'}
                            options={timezoneOptions}
                            value={timezoneSelected}
                            onChange={(o:SelectAsyncTypes.Option) => {
                                handleChange('timezone', o.value);
                            }}
                />
                <span className={styles.controls}>
                        <Button
                    type="success"
                            disabled={!edited}
                            onClick={() => {
                                save();
                            }}
                        >
                            {t('Save')}
                        </Button>
                    </span>
                    </div>
            
                    <div className={styles.optionsWrap}>
                        <span className={styles.label}>{t('Weekend days')}:</span>
                        <span className={styles.weekdays}>
                        {renderWeekdays()}
                        </span>
            </div>
            
            <div className={styles.optionsWrap}>
                <span className={styles.changeButtonLabels}>
                    {t('Change your current password to a new one')}:
                </span>
                {/* @ts-ignore */}
                <Link
                    // type="secondary"
                    onClick={() =>
                        window.open(
                            '/cgi-bin/requestPwdLink?forV3=1&username=' +
                                settings?.username,
                            '_blank'
                        )
                    }
                    title={t(
                        'This will send an e-mail to you. Follow the instructions in the e-mail to set a new password.'
                    )}
                >
                    {t('set new password')}
                </Link>
            </div>
            <div className={styles.optionsWrap}>
                <span className={styles.changeButtonLabels}>
                    {t('Reset recent workspace to default workspaces')}:
                </span>
                {/* @ts-ignore */}
                <Link
                    // type="secondary"
                    // btnStyle={{ marginInlineStart: 0 }}
                    onClick={() => {
                        resetWorkspacesHandler();
                    }}
                >
                    {/* TODO: add loading indicator ? */}
                    {t('reset workspaces')}
                </Link>
            </div>

            <div className={styles.optionsWrap}>
                <span className={styles.label}>
                    {t('Your preferred browser languages')}:
                </span>
                <div className={styles.list}>
                    {languageList?.map((l, i) => (
                        <div className={styles.langItem} key={i}>
                            {i + 1}. {l}
                        </div>
                    ))}
                </div>
            </div>
            
            {/* <div className={styles.adminSettings}> */}

                <div className={styles.optionsWrap}>
                    <span className={styles.label}>{t('Maximum clip length')}:</span>
                    {Math.round((settings.maxDownloadLength / 60) * 10) / 10 +
                        ' ' +
                        t('min')}
                </div>
            {/* </div> */}
            {showPopup && (
                // @ts-ignore
                <Popup
                    onOk={handleConfirm}
                    onCancel={handleCancel}
                    okTxt={t('Ok')}
                    cancelTxt={t('Cancel')}
                    wrapperStyle={{
                        position: 'fixed',
                        insetBlockStart: 'unset',
                        insetBlockEnd: 0,
                        height: 'calc(100% - 2.2rem)'
                    }}
                    size="auto"
                    blockContent
                >
                    <p>{warningText}</p>
                </Popup>
            )}
        </section>
    );
}

export default MfSettings;