import { useTranslation } from 'react-i18next';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';
import styles from './ChannelsTableButtons.module.scss';
import { useAppSelector } from 'src/redux/hooks';
import useChannelsCount from '../../hooks/useChannelsCount';

const ChannelsTableButtons = ({
    selectGroupHandler,
    setShowOnlySelected,
    selectedChannels,
    setSelectedChannels,
    shownChannelsData,
    setPopupType,
    setIsOpen,
    selectedGroup,
    filteredDataCount
}: any) => {
    const { t } = useTranslation();
    const isMobileOnTablet = useIsScreenSize(1280);

    const channels = useAppSelector((state) => state.channelReducers.data);

    const {
        dynamicHeadingText
    } = useChannelsCount({
        selectedChannels,
        shownChannelsData,
        selectedGroup,
        filteredDataCount
    });

    const deselectAllHandler = () => {
        setSelectedChannels([]);
        setShowOnlySelected(false);
    };

    const showAllHandler = () => {
        selectGroupHandler({});
        setShowOnlySelected(false);
    };

    const showSelectedHandler = () => {
        selectGroupHandler({});
        setShowOnlySelected(true);
    };

    const popupShowHandler = (type: string) => {
        setIsOpen?.(true);
        setPopupType?.(type);
    }

    return (
        <div className={styles.headingContainer}>
            {dynamicHeadingText()}
            <div className={styles.buttonSection}>
            <Button
                btnClass={isMobileOnTablet && styles.mobileButtonPadding}
                type="primary"
                onClick={deselectAllHandler}
                disabled={selectedChannels.length < 1}
            >
                {t('Deselect All')}
            </Button>
            <Button
                btnClass={isMobileOnTablet && styles.mobileButtonPadding}
                type="primary"
                onClick={showSelectedHandler}
                disabled={selectedChannels.length < 1}
            >
                {t('Show Selected')}
            </Button>
            <Button
                btnClass={isMobileOnTablet && styles.mobileButtonPadding}
                type="primary"
                onClick={showAllHandler}
                disabled={shownChannelsData.length === channels.length}
            >
                {t('Show All')}
            </Button>
            <Button
                btnClass={isMobileOnTablet && styles.mobileButtonPadding}
                type="primary"
                onClick={() => popupShowHandler('add')}
                disabled={!selectedChannels.length}
            >
                {t('Add')}
            </Button>
            <Button
                btnClass={isMobileOnTablet && styles.mobileButtonPadding}
                type="primary"
                onClick={() => popupShowHandler('delete')}
                disabled={
                    selectedGroup && Object.keys(selectedGroup).length === 0 || !selectedChannels.length
                }
            >
                {t('Delete')}
            </Button>
            </div>
        </div>
    );
};

export default ChannelsTableButtons