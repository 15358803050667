import APITelemetry from './API/APITelemetry';
import StackTrace from 'stacktrace-js';

window.onerror = async function (msg, url, lineNumber, columnNumber, error) {
    // fetch js errors and report to backend, use stacktrace-js library to get correct file-numbers (otherwise we would get line numbers of build files here)
    let out = { msg };
    try {
        out.stackframes = await StackTrace.fromError(error);
    } catch (e) {
        if(error !== undefined && error !== null) {
            out.stackframesString = error.stack;
        }
    }
    // const stringifiedStack = stackframes.map((sf) => sf.toString()).join('\n');
    console.log('Error: ' + msg);
    APITelemetry.postConsoleError(out);
    return false;
};

window.onunhandledrejection =async function(e){
    // NOTE: this will fetch all async errors. but it needs a solution to get the actual stacktrace with line numbers here to be useful
    // one option would be to wrap all async functs with ./helpers.js/handleAsync() - see User.js componentDidMount() for example
    // or override js Promise implementation ?
    if(e.reason){
        APITelemetry.postConsoleError({
            msg: e.reason.message,
            details:{
                columnNumber: e.reason.columnNumber,
                fileName: e.reason.fileName,
                lineNumber: e.reason.lineNumber,
            }
        });
        if (e.reason.lineNumber === undefined) {
            if (window.rxStore) {
                const redux = window.rxStore.getState();
                if (redux.me && redux.me.companyid === 1) {
                    alert('eMM internal message: Some error occurred and it is hard to reproduce it. If possible, please explain CT how to reproduce this (even if you can not see any actual problem besides this message. Thank you.')
                }
            }
        }
    }
}

// Promise = class extends Promise {
//     constructor(...args) {        
//         let data = super(...args)
//         return data;
//     }
//     catch(...args) {
//         args[0] = (e)=>{
//             // console.log("a");
//             // window.onerror(undefined,undefined,undefined,undefined,e);
//             return args[0](e);
//         }
//         let d = super.catch(...args);
//         return d;
//     }
// };

const _warn = console.warn;
console.warn = (...params) => { // override default console.warn function and add reporting to backend
    let msg;
    if (params.length > 1) {
        msg = JSON.stringify(params);
    } else {
        msg = params[0];
    }
    APITelemetry.postConsoleWarning(msg);
    _warn(...params);
}