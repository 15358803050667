import React, { Component } from 'react';
import Switch from 'react-switch';
import styles from './LabeledSwitch.module.scss';
import classNames from 'classnames';

/**
 * Simple switch component, looks in some cases nicer than a checkbox
 */
class LabeledSwitch extends Component {
    render() {
        const {
            children,
            checked,
            onChange,
            width,
            height,
            className,
            disabled,
            labelPosition,
            title,
        } = this.props;

        const POSITIONS = {
            start: 'start'
        }

        let switchClasses = [styles.switch];
        if (labelPosition === POSITIONS.start) {
            switchClasses.push(styles.switchLabelLeft)
        }

        return (
            <label
                title={title}
                className={classNames(styles.switchLabel, className, disabled ? styles.disabled : '')}
            >
                {labelPosition === POSITIONS.start && children}
                <Switch
                    checked={checked}
                    onChange={onChange}
                    width={width || 40}
                    height={height || 20}
                    className={switchClasses.join(' ')}
                    disabled={disabled}
                />
                {labelPosition !== POSITIONS.start && children}
            </label>
        );
    }
}

export default LabeledSwitch;
