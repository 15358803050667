import GenericCalls from './genericCalls';

class APIUsers {
    static async get(id) {
        if (id) return await GenericCalls.get('/api/users/' + id);
        else return await GenericCalls.get('/api/users');
    }

    // deprecated api call
    // static async getFeedGroups(id) {
    //     return await GenericCalls.get(`/api/users/${id}/feed-groups`);
    // }

    static async search(searchTerm, start, limit) {
        let url = "/api/users/search/" + searchTerm;
        if (start && limit) url += "?start=" + start + "&limit=" + limit;

        return GenericCalls.get(url);
    }

    static async post(data, hideErrors) {
        return await GenericCalls.post('/api/users/', data, undefined, hideErrors);
    }

    static async put(id, data, hideErrors) {
        return await GenericCalls.put('/api/users/' + id, data, undefined, hideErrors);
    }

    static async delete(id) {
        return await GenericCalls.delete('/api/users/' + id);
    }

    static async sendEmail(user, admin, toCreatorOnly = 0, forV3 = 1) {
        const url = `/cgi-bin/requestPwdLink?username=${user}&creator=${admin}&targetUrl=https%3A%2F%2Fdart.eMediaMonitor.net&lang=en&toCreatorOnly=${toCreatorOnly}&forV3=${forV3}&outputType=JSON`;
        return await GenericCalls.get(url, undefined, undefined, true);
    }
}

export default APIUsers;
