import { ChangeEvent, FC, useEffect, useState } from 'react'
import { CompanyEditContainerProps } from './CompanyEditContainer.types'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { getGroups } from 'src/redux/actions/actions.channelGroups'
import TextInput from 'src/Widgets/common/basicElements/TextInput/TextInput'
import { useTranslation } from 'react-i18next'

import styles from './CompanyEditContainer.module.scss'
import LabeledSelect from 'src/Widgets/common/basicElements/LabeledSelect/LabeledSelect'
import DatePicker from 'react-date-picker'
import Button from 'src/Widgets/common/basicElements/Button/Button'
import DeleteRow from 'src/Widgets/common/basicElements/DeleteRow/DeleteRow'

import FormFooter from 'src/Widgets/common/basicElements/FormFooter/FormFooter'
import ShareGroupCards from 'src/Widgets/GroupCardWidgets/ShareGroupCards'
import APICompanies from 'src/API/APICompanies'
import APIOptions from 'src/API/APIOptions'

const CompanyEditContainer: FC<CompanyEditContainerProps> = ({
    selectedCompany,
    isAddView,
    setEditIsOn,
    getCompanyHandler,
    openCardsOnBack,
    groupId
}) => {
    const channelGroups = useAppSelector((state) => state.channelGroupReducers);

    const defaultCompanyData = {
        company: {
            title: '',
            nickname: '',
            contractEndDate: new Date(),
            paymentOverdueDate: new Date(),
            supportEmail: '',
            opsEmail: '',
            techEmail: '',
            channelGroupId: null,
            users: selectedCompany.users,
            companyGroupId: groupId,
        }
    };
    const [editedCompanyData, setEditedCompanyData] = useState(defaultCompanyData);
    const [companyUsers, setCompanyUsers] = useState([])
    const [isEditingShareGroups, setIsEditingShareGroups] = useState<boolean>();
    const [saveFailed, setSaveFailed] = useState<boolean>(false);
    const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
    const [hasChanged, setHasChanged] = useState<boolean>(false);
    const [isMounted, setIsMounted] = useState(true);
    const [selectedTime, setSelectedTime] = useState({
        paymentOverdueTime: '00:00',
        contractEndTime: '00:00'
    });
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [selectedTimezone, setSelectedTimezone] = useState(userTimezone);
    const [displayedDateTime, setDisplayedDateTime] = useState<any>({
        paymentOverdueTime: '',
        contractEndTime: ''
    });
    const [timezones, setTimezones] = useState<any[]>([]);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const getCompanyUsers = async (companyId: number) => {
        const res = await APICompanies.get(companyId)
        const users = res.data.users
        setCompanyUsers(users)
    }

    //!Track if component is mounted then update state
    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);

    useEffect(() => {
        dispatch(getGroups());
        initTZ();
        setEditedCompanyData((prevState: any) => {
            const updatedCompany = {
                ...prevState.company,
                title: selectedCompany['Company Name'],
                nickname: selectedCompany['Nickname'],
                supportEmail: selectedCompany['Support Email'],
                opsEmail: selectedCompany['Ops Email'],
                techEmail: selectedCompany['Tech Email'],
                //@ts-ignore
                contractEndDate: selectedCompany['Contract End'] === '0000-00-00 00:00:00'
                        ? new Date('1970-01-01 00:00:00')
                        : new Date(selectedCompany['Contract End']),
                //@ts-ignore
                paymentOverdueDate: selectedCompany['Payment Overdue'] === '0000-00-00 00:00:00'
                        ? new Date('1970-01-01 00:00:00')
                        : new Date(selectedCompany['Payment Overdue']),
                channelGroupId: selectedCompany.channelGroupId
            };

            setSelectedTime(
                //@ts-ignore
                {
                    paymentOverdueTime: new Date(selectedCompany['Payment Overdue']).toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    contractEndTime: new Date(selectedCompany['Contract End']).toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                }
            );

            return {
                ...prevState,
                company: updatedCompany,
                channelGroups: []
            };
        });
        !isAddView && getCompanyUsers(selectedCompany.id)
    }, []);

    useEffect(() => {
        isAddView && setEditedCompanyData(defaultCompanyData);
    }, [isAddView]);

    const goBackHandler = () => {
        openCardsOnBack(groupId)
        setEditIsOn(false)
    }

    const changeHandler = (key: string, value: any) => {
        setEditedCompanyData((prevState) => ({
          ...prevState,
          company: {
            ...prevState.company,
            [key]: value,
          },
        }));
        if (value !== '') {
          setHasChanged(true);
        } else {
          setHasChanged(false);
        }
      };
    
      const onTextfieldChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
        changeHandler(key, e.target.value);
      };
    
      const onSelectChange = (option: any, key: string) => {
        changeHandler(key, option.value);
      };
    
      const onDateChange = (date: Date | null, key: string) => {
        changeHandler(key, date);
    };

    const handleTimeChange = (e: ChangeEvent<HTMLInputElement>, type: string) => {
        const { value } = e.target;
        setSelectedTime((prevState) => ({
            ...prevState,
            [type]: value !== '' ? value : null
        }));
        if (value !== '' || value !== null) {
            setHasChanged(true);
          } else {
            setHasChanged(false);
          }
    }
    const handleTimezoneChange = (option: any) => setSelectedTimezone(option.value);

  // Function to update the displayed date/time in the selected timezone
  const updateDisplayedDateTime = () => {
    if (selectedTime.paymentOverdueTime && selectedTime.contractEndTime) {
        const [phours, pminutes] = selectedTime.paymentOverdueTime.split(':').map(Number);
        const [chours, cminutes] = selectedTime.contractEndTime.split(':').map(Number);
        const pDateTime = new Date(editedCompanyData.company.paymentOverdueDate);
        const cDateTime = new Date(editedCompanyData.company.contractEndDate);
        pDateTime.setHours(phours, pminutes);
        cDateTime.setHours(chours, cminutes);

        // Convert to the selected timezone and format for display
        const options: any = { timeZone: selectedTimezone, hour: '2-digit', minute: '2-digit' };
        const localizedPdate = pDateTime.toLocaleDateString('en-GB', options);
        const localizedCdate = cDateTime.toLocaleDateString('en-GB', options);
        setDisplayedDateTime({
            paymentOverdueTime: localizedPdate,
            contractEndTime: localizedCdate
        });
    }
};

// Update displayed date/time whenever timezone or time changes
  useEffect(updateDisplayedDateTime, [
    selectedTimezone,
    selectedTime,
    editedCompanyData.company.paymentOverdueDate,
    editedCompanyData.company.contractEndDate
]);

    const initTZ = async () => {
        const tzs = (await APIOptions.getTimezones()).data;
        //const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone.replace(/ /g, '_');
        const updatedTzs = tzs.map((tz: any) => ({
            ...tz,
            id: tz.id.replace(/ /g, '_'),
        }));
        setTimezones(updatedTzs);
        //setSelectedTimezone(clientTimezone);
    };

    const saveCompanyHandler = async () => {
        let response;
        let updatedCompanyData: any = {
            ...editedCompanyData,
            company: {
                ...editedCompanyData.company,
                paymentOverdueDate: null,
                contractEndDate: null,
            }
        };
    
        if (selectedTime.paymentOverdueTime) {
            const [phours, pminutes] = selectedTime.paymentOverdueTime.split(':').map(Number);
            const utcDateTimeP = new Date(editedCompanyData.company.paymentOverdueDate);
            utcDateTimeP.setHours(phours - utcDateTimeP.getTimezoneOffset() / 60, pminutes);
            updatedCompanyData.company.paymentOverdueDate = utcDateTimeP.toISOString();
        }
    
        if (selectedTime.contractEndTime) {
            const [chours, cminutes] = selectedTime.contractEndTime.split(':').map(Number);
            const utcDateTimeC = new Date(editedCompanyData.company.contractEndDate);
            utcDateTimeC.setHours(chours - utcDateTimeC.getTimezoneOffset() / 60, cminutes);
            updatedCompanyData.company.contractEndDate = utcDateTimeC.toISOString();
        }
    
        if (!isAddView) {
            response = await APICompanies.put(selectedCompany.id, updatedCompanyData.company);
        } else {
            response = await APICompanies.post(updatedCompanyData.company);
        }
    
        // Check if the component is still mounted before performing state updates
        if (!response || response.status) {
            if (!isMounted) {
                return;
            }
    
            setSaveFailed(true);
            return;
        }
    
        if (!isMounted) {
            return; 
            // Do nothing if the component is unmounted
        }
    
        setSaveSuccess(true);
        getCompanyHandler(selectedCompany.id);
        goBackHandler();
    };

    const channelGroupOptions = channelGroups.map((group: any) => ({
        label: group.title,
        value: group.id
    }));

    const deleteCompanyHandler = async () => {
        await APICompanies.delete(selectedCompany.id);
        getCompanyHandler(selectedCompany.id)
        goBackHandler();
    };

    const channelGroupSelected = channelGroupOptions.find(
        (option: any) => option.value === editedCompanyData.company.channelGroupId
    );

    const timezoneSelected = timezones.find(
        (option: any) => option.value === selectedTimezone
    );

    if (isEditingShareGroups) {
        return (
            <>
                <div className={styles.sharegroupsWrapper}>
                    {
                        //@ts-ignore
                        <ShareGroupCards
                            //@ts-ignore
                            isCompanyManagerWidget={true}
                            companyId={selectedCompany.id}
                            getGroups={() => {
                                return APICompanies.getShareGroups(selectedCompany.id);
                            }}
                            getUsers={() => {
                                return APICompanies.get(selectedCompany.id);
                            }}
                            users={companyUsers}
                            onGoBack={() => setIsEditingShareGroups(false)}
                        />
                    }
                </div>
            </>
        );
    }

    const conditionalMessage = () => {
        if (isAddView && saveSuccess) {
            return t('Successfully created');
        } else if (!isAddView && saveSuccess) {
            return t('Successfully saved');
        } else {
            return '';
        }
    };

    return (
        <div className={styles.companyEditContainer} data-testid="editcontainer">
            <FormFooter
                onGoBack={goBackHandler}
                onAction={() => saveCompanyHandler()}
                actionDisabled={!hasChanged}
                success={conditionalMessage()}
                fail={saveFailed ? t('Could not save') : ''}
                actionLabel={!isAddView ? t('Save Changes') : t('Create Company')}
            />
            <TextInput
                value={editedCompanyData.company.title}
                onChange={(e) => onTextfieldChange(e, 'title')}
                label={t('Name')}
            />
            <TextInput
                value={editedCompanyData.company.nickname}
                onChange={(e) => onTextfieldChange(e, 'nickname')}
                label={t('Nickname')}
            />
            {
                //@ts-ignore
                <LabeledSelect
                    label={t('Channel Group')}
                    placeholder={t('Select Channel Group')}
                    options={channelGroupOptions}
                    value={channelGroupSelected}
                    onChange={(option: any) => onSelectChange(option, 'channelGroupId')}
                    className={styles.select}
                />
            }
            <div className={styles.datePickers}>
                {/* <div className="timezoneDisplay">
                    Contract End Date in <b>{selectedTimezone}</b>: {displayedDateTime.contractEndTime}
                </div>
                <div className="timezoneDisplay">
                    Payment Overdue Date in <b>{selectedTimezone}</b>: {displayedDateTime.paymentOverdueTime}
                </div>

                <LabeledSelect
                    label="Timezone Calculator (Date and time in selected timezone will be displayed on above)"
                    options={timezones.map((tz) => ({ label: tz.title, value: tz.id }))}
                    value={timezoneSelected}
                    className={styles.select}
                    onChange={handleTimezoneChange}
                /> */}
       
                    {/* <span>{t('Your current timezone:')} <b>{userTimezone}</b></span> */}
                
                <div className={styles.companyDatePickerWithLabel}>
                    {t('Contract End')}
                    {': '}
                    <DatePicker
                        onChange={(e: Date) => onDateChange(e, 'contractEndDate')}
                        value={editedCompanyData.company.contractEndDate}
                        className={styles.contractDatePicker}
                        clearIcon={null}
                    />
                    <div className={styles.timeContainer}>
                    <TextInput
                        inputType="time"
                        value={selectedTime.contractEndTime}
                        onChange={
                            (e: ChangeEvent<HTMLInputElement>) => handleTimeChange(e, 'contractEndTime')
                        }
                        label="Time (UTC)"
                    />
                    </div>
                </div>

                <div
                    className={styles.companyDatePickerWithLabel}
                    data-testid="datepicker"
                >
                    {t('Payment Overdue')}
                    {': '}

                    <DatePicker
                        onChange={(e: Date) => onDateChange(e, 'paymentOverdueDate')}
                        value={editedCompanyData.company.paymentOverdueDate}
                        className={styles.companyDatePicker}
                    />
                    <div className={styles.timeContainer}>
                    <TextInput
                        inputType="time"
                        value={selectedTime.paymentOverdueTime}
                        onChange={
                            (e: ChangeEvent<HTMLInputElement>) => handleTimeChange(e, 'paymentOverdueTime')
                        }
                        label="Time (UTC)"
                    />
                    </div>
                </div>

                <TextInput
                    placeholder={t('Support Email')}
                    value={editedCompanyData.company.supportEmail}
                    onChange={(e) => onTextfieldChange(e, 'supportEmail')}
                    label={t('Support')}
                />

                <TextInput
                    placeholder={t('Operations Email')}
                    value={editedCompanyData.company.opsEmail}
                    onChange={(e) => onTextfieldChange(e, 'opsEmail')}
                    label={t('Operations')}
                />

                <TextInput
                    placeholder={t('Tech Email')}
                    value={editedCompanyData.company.techEmail}
                    onChange={(e) => onTextfieldChange(e, 'techEmail')}
                    label={t('Tech')}
                />
                {!isAddView && (
                    <Button type="primary" onClick={() => setIsEditingShareGroups(true)}>
                        {t('Edit Share Groups')}
                    </Button>
                )}
                {!isAddView && (
                    //@ts-ignore
                    <DeleteRow
                        labelText={t('Remove Company')}
                        confirmationText={t('Really remove company?')}
                        onClick={() => deleteCompanyHandler()}
                        disabled={companyUsers.length > 0}
                        deletePopup
                    />
                )}
            </div>
        </div>
    );
};

export default CompanyEditContainer