import { getCookie } from 'src/Widgets/common/helpers';
import {
    // INIT_GOOGLE_API,
    SET_IS_AUTHENTICATED,
    SET_IS_WRONG_USER,
    SET_SESSION_EXPIRED,
    // LOGIN_GOOGLE_API,
} from '../types/actionTypes.auth';
import _ from 'lodash';

const authReducers = (state = {
    google: { },
    session: {
        isAuthenticated: getCookie('emmP') !== undefined,
        isExpired: false,
    },
}, action) => {
    let update;
    switch (action.type) {
        case SET_SESSION_EXPIRED:
            update = _.cloneDeep(state);
            update.session.isExpired = action.data;
            return update;
        case SET_IS_AUTHENTICATED:
            update = _.cloneDeep(state);
            update.session.isAuthenticated = action.data.isAuthenticated;
            update.session.redirectTo = action.data.redirectTo;
            update.session.isManualLogout = action.data.isManualLogout;
            return update;
        case SET_IS_WRONG_USER:
            update = _.cloneDeep(state);
            update.session.isWrongUser = action.data;
            return update;
        // case INIT_GOOGLE_API:
        //     update = _.cloneDeep(state);
        //     update.google.api = action.data;
        //     update.google.initialized = true;
        //     return update;
        // case LOGIN_GOOGLE_API:

        //     state.google.
        //     return state;
        default:
            return state;
    }
};

export default authReducers;