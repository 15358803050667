import APITelemetry from 'src/API/APITelemetry';
import { SET, CLEAR } from '../types/actionTypes.error';
import { convertUrlToGeneric, endpoints } from 'src/API/endpoints';
import { getResponseHeader } from 'src/Widgets/common/helpers';

export const setError = ({ data, reportTelemetry }) => {
  return async (dispatch) => {
    let telemetryVarnishId;
    if (!reportTelemetry !== false) {
      const url = convertUrlToGeneric(data.url, endpoints);
      
      const telemetryResp = await APITelemetry.post({ msg: `2nd level error shown; status=${data.status}; method=${data.method}; rc=${data?.data?.rc}; url=${url}`, details: data });
      telemetryVarnishId = getResponseHeader(telemetryResp, 'x-varnish');
    }

    dispatch({ type: SET
      , data: {...data, telemetryVarnishId}
     });
  }
}

export const clearError = data =>
  ({ type: CLEAR
  })