import React from 'react';
import styles from './Waterfall.module.scss';
import WaterfallCard from './WaterfallCard/WaterfallCard';
import APIPushFeed from '../../API/APIPushFeed';
// import APIPushFeed from '../../API/static/PushFeed2';
import _ from 'lodash';
import WaterfallOptions from './WaterfallOptions/WaterfallOptions';
import { sleep, timestampToDate } from '../common/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import WaterfallPlayer from './WaterfallPlayer/WaterfallPlayer';
import { connect } from 'react-redux';
import LoadingSpinner from '../common/basicElements/LoadingSpinner/LoadingSpinner';
import { getProfileGroups } from 'src/redux/actions/actions.profileGroups';

library.add(faCircle);

export class Waterfall extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profiles: {},
            startID: undefined,
            currentStep: 1,
            speed: 8,
            fetchFailedCount: 0,
            activeItems: [],
            steps: [],
            direction: undefined,  // 'next' or 'prev' depending on direction of last move
            playVideo: {
                play: false,
                profile: undefined
            },
        };
        this.fetch = this.fetch.bind(this);
        this.onStepSliderChange = this.onStepSliderChange.bind(this);
        this.onSpeedSliderChange = this.onSpeedSliderChange.bind(this);
        this.onManualStepSliderChange = this.onManualStepSliderChange.bind(this);
        this.onManualSpeedSliderChange = this.onManualSpeedSliderChange.bind(this);
        // this.dates = ['mon', 'tue', 'wed'];
    }

    async componentDidMount() {
        this._isMounted = true;
        // this.sliderInput.focus();
        if (!this.props.profileGroups.status && !this.props.profileGroups.isLoading) {
            this.props.getProfileGroups();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
    }

    async fetch() { // fetch new results from backend
        if (!this._isMounted) {
            // ensure to not start any further requests if component is not mounted anymore
            return;
        }
        const startID = this.state.startID ? this.state.startID : undefined;
        let results = (await APIPushFeed.get(startID, 60));
        if (results.status !== undefined && results.status !== 200) {
            this.setState({
                fetchFailedCount: parseInt(this.state.fetchFailedCount) + 1
            });
            await sleep(5000);
            this.fetch();
        } else {
            if (results.data.length > 0) {

                const superProfileIds = this.props.profileGroups.data.reduce((acc, group) => {
                    const superProfileIds = group.profiles
                      .filter((profile) => profile.super === 1)
                      .map((profile) => profile.id);
                    return acc.concat(superProfileIds);
                  }, []);

                const standartProfileIds = this.props.profileGroups.data.reduce((acc, group) => {
                        const standartProfileIds = group.profiles
                          .filter((profile) => profile.super !== 1)
                          .map((profile) => profile.id);
                        return acc.concat(standartProfileIds);
                      }, []);

                const superProfileNotifications = results.data.filter((profile) => 
                superProfileIds.includes(Number(profile.pid)) && profile.manual === 100
                );

                const standartProfileNotifications = results.data.filter((profile) =>
                standartProfileIds.includes(Number(profile.pid)) && profile.manual !== 100
                );

                const waterfallNotifications = superProfileNotifications.concat(standartProfileNotifications);

                waterfallNotifications.map((h) => {
                    const channel = _.find(this.props.channels, { name: h.channel });
                    // const channel = _.find(this.state.channels, { id: h.channelId });
                    if(channel) h.channelDisplayName = channel.displayName;
                    return h;
                } );

                let profiles = _.groupBy(waterfallNotifications, 'pid');
                profiles = this.mergeProfiles(_.cloneDeep(this.state.profiles), profiles);
                this.setState({
                    startID: results.startID,
                    profiles: profiles,
                    fetchFailedCount: 0
                }, () => {
                    this.calcSteps(profiles);
                    this.onStepSliderChange(this.state.currentStep); // required to set first entry to read
                    this.onSpeedSliderChange(this.state.speed);
                        this.fetch();
                });
            } else {
                this.setState({ startID: results.startID }, () => {
                    this.fetch();
                });
            }
        }
    }

    mergeProfiles(profiles, newProfiles) {
        // const compare = (i1, i2) => { return i1.beginTS - i2.beginTS; };
        for (let key in newProfiles) {
            if (profiles[key] === undefined) {
                profiles[key] = newProfiles[key];
            }
            else {
                profiles[key] = profiles[key].concat(newProfiles[key]);
            }
            // profiles[key].sort(compare);
        }
        return profiles;
    }

    componentDidUpdate(prevProps, prevState) {
        const prev = prevState.currentStep;
        const curr = this.state.currentStep;

        if (!_.isEqual(prevProps.channels, this.props.channels)) {
            this.fetch();
        }

        let direction = undefined;
        if (curr > prev) {
            direction = 'next';
        }
        else if (curr < prev) {
            direction = 'prev';
        }

        if(direction !== undefined) {
            this.setState({ direction });
        }
    }

    onManualStepSliderChange(stepIndex) {
        this.stopScrolling();
        this.setState({ speed: 0 });
        this.onStepSliderChange(stepIndex);
        this.props.matomo.push(['trackEvent', 'waterfall', 'stepChange: ' + stepIndex])
    }

    onStepSliderChange(stepIndex) {
        const { profiles, steps } = this.state;

        const idxs = this.getItemIndexForStep(profiles, steps, (stepIndex-1));
        
        this.setState((s) => {
            for (const profile in idxs) {
                const itemIndex = idxs[profile];
                
                s.profiles[profile][itemIndex]._read = true;
            }
            return {currentStep: stepIndex, profiles: s.profiles};
        });
    }

    onManualSpeedSliderChange(speed) {
        this.onSpeedSliderChange(speed);
        this.props.matomo.push(['trackEvent', 'waterfall', 'speedChange: ' + speed]);
    }

    onSpeedSliderChange(speed) {
        speed = parseInt(speed);
        this.stopScrolling();
        this.setState({ speed }, () => {
            if (speed !== 0) {
                this.startScrolling();
            }
        });
    }

    startScrolling() {
        const timeout = setTimeout(() => {
            const nextSuccess = this.nextStep();
            if (nextSuccess) this.startScrolling(this.state.speed);
        }, (11 -this.state.speed) * 1000);
        this.setState({ timeout: timeout });
    }

    nextStep() {
        if (this.state.steps.length > this.state.currentStep) {
            let step = this.state.currentStep;
            step++;
            this.onStepSliderChange(step);
            return true;
        } else {
            return false;
        }
    }

    stopScrolling() {
        clearTimeout(this.state.timeout);
    }

    calcSteps(lists) {
        let steps = [];
        let step;
        do {
            let itemIdxs = this.getItemIndexForStep(lists, steps);
            step = this.calcStep(lists, itemIdxs);
            steps.push(step);
        } while (step !== false)
        if (this.state.currentStep > steps.length) {    // if new calculation leads to less steps than currentPosition, set position to last possible step
            this.setState({ currentStep: steps.length });
        }
        this.setState({ steps: steps });
    }

    findProfileWithLowestNext(profiles, itemIdxs) {   // find lowest next timestamp of all profiles that have at least 2 more results
        let lowest;

        for (let key in profiles) {
            const step = itemIdxs[key];
            const profile = profiles[key];
            let pNext = profile[step + 1];
            let pNext2 = profile[step + 2];
            
            if (pNext2 !== undefined) {
                if (lowest) {
                    const lowPid = lowest[0].pid;
                    if (pNext.beginTS < lowest[itemIdxs[lowPid] + 1].beginTS) {
                        lowest = profile;
                    }
                } else {
                    lowest = profile;
                }
            }
        };
        return lowest;
    }

    getItemIndexForStep(profiles, steps, untilStep) {
        untilStep = untilStep === undefined ? steps.length : untilStep;
        let rv = {};
        
        for (const key in profiles) {
            let index = 0;
            
            for (let i = 0; i < untilStep; i++) {
                const step = steps[i];
                if (step[key]) {
                    index++;
                }
            }
            rv[key] = index;
        }

        return rv;
    }
    
    calcStep(profiles, itemIdxs) {  // calculates which windows should be scrolled together to always see items that are closest to each other (compared by time)
        let rv = {};
        let lowest = this.findProfileWithLowestNext(profiles, itemIdxs);

        if (lowest) {
            const lowPid = lowest[0].pid;
            rv[lowPid] = true;
            let lowestNext = lowest[itemIdxs[lowPid] + 1];
            let lowestNext2 = lowest[itemIdxs[lowPid] + 2];

            for (let key in profiles) {
                const step = itemIdxs[key];
                const profile = profiles[key];
                let pNext = profile[step + 1];
                if (profile !== lowest) {
                    if (lowestNext !== undefined) {
                        if (pNext !== undefined) {
                            if (lowestNext2 !== undefined) {        
                                if (pNext.beginTS - lowestNext.beginTS < lowestNext2.beginTS - pNext.beginTS) {
                                    rv[key] = true;
                                }
                            } else {
                                return;
                            }
                        }
                    } else {
                        rv[lowest[0].pid] = false;
                    } 
                }
            }
        } else {
            // if no windows with >= 2 next entries available, add step to scroll remaining entries
            rv = {};
            for (const key in profiles) {
                const profile = profiles[key];
                const step = itemIdxs[key];
                if (profile[step + 1]) {
                    rv[key] = true;
                }
            }
            if (Object.keys(rv).length > 0) {
                return rv;
            } else {    // if no more entry to scroll, quit calc
                return false;
            }
        }

        return rv;
    }

    render() {

        // // If profiles object is empty, render LoadingSpinner
        // if (Object.keys(this.state.profiles).length === 0) {
        //     return <LoadingSpinner />;
        // }
        // translation method with fallback
        const t = this.props.t || (k => k);
        const { playVideo } = this.state;

        const { fetchFailedCount, direction, currentStep, profiles, steps, speed } = this.state;
        const statusStyles = [
            styles.status,
            fetchFailedCount > 2 ? styles.statusBad : styles.statusGood
        ];
        let itemIdxsPrev;
        switch (direction) {
            case 'prev':
                    itemIdxsPrev = this.getItemIndexForStep(profiles, steps, currentStep);
                break;
            case 'next':
                    itemIdxsPrev = this.getItemIndexForStep(profiles, steps, (currentStep - 2));
                break;
            default: break;
        }
        const itemIdxs = this.getItemIndexForStep(profiles, steps, (currentStep - 1));
        const profileIds = Object.keys(itemIdxs);
        const timeSum = _.reduce(profileIds, (sum, profileId) => {
            const profile = profiles[profileId];
            const itemIndex = itemIdxs[profileId];
            const p = profile[itemIndex];
            return sum + p.beginTS;
        }, 0);
        const avgTime = profileIds.length !== 0 ? timestampToDate(timeSum / profileIds.length) : undefined;

        return (
            playVideo.play ?
                <WaterfallPlayer
                    onGoBack={() => { this.setState({ playVideo: {play: false} }) }}
                    hits={_.cloneDeep(profiles[playVideo.profile])}
                    showControls={true}
                    looping={true}
                />                
            :
                <div
                    className={styles.waterfallWrapper}
                >
                    {/* <button onClick={() => { this.fetch() }}>fetch</button> */}
                    <FontAwesomeIcon title={t('This circle shows the connection state to the servers. Green means you receive updates.')} className={classNames(statusStyles)} icon="circle" size="1x" />
                    
                    <WaterfallOptions
                        inputRef={(c) => { this.sliderInput = c; }}
                        onStepChange={this.onManualStepSliderChange}
                        onSpeedChange={this.onManualSpeedSliderChange}
                        step={currentStep}
                        speed={speed}
                        fetchFailedCount={fetchFailedCount}
                        numberOfSteps={steps.length}
                        avgTime={avgTime}
                    />
                    <div className={styles.cardWrapper}>                    
                        {
                            _.map(profiles, (o, index) => {
                                const active = ((itemIdxsPrev === undefined) || (itemIdxs[index] === itemIdxsPrev[index])) ? false : true;
                                return (
                                    <WaterfallCard
                                        key={index}
                                        items={o}
                                        current={itemIdxs[index]}
                                        active={active}
                                        onAutoPlay={() => { this.setState({ playVideo: { play: true, profile: o[0].pid } }); }}
                                    />
                                )
                            })
                        }                
                    </div>
                </div>
        );
    }
}

 const mapStateToProps = state => ({ 
     matomo: state.matomo,
     profileGroups: state.profileGroups
 });

export default connect(mapStateToProps,{getProfileGroups})
(
    withTranslation()(
        Waterfall
    )
);