
import useStructuredChannelsData from '../../hooks/useStructuredChannelsData'
import styles from './ChannelsTableContainer.module.scss';
import ChannelsTableButtons from '../ChannelsTableButtons/ChannelsTableButtons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopup } from '../../hooks/usePopup';
import { ChannelType } from '../../types/types';
import GenericTableView from 'src/Widgets/common/GenericTable/GenericTableView';

const ChannelsTableContainer = ({getSelectedGroup, selectGroupHandler}: any) => {

  const [selectedChannels, setSelectedChannels] = useState<ChannelType[]>([]);
  const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);
  const [filteredDataCount, setFilteredDataCount] = useState<number>(0);

  const {t} = useTranslation();

  const selectedGroup = getSelectedGroup();

  const {setIsOpen, setPopupType, popupComponent, isOpen} = usePopup(selectedChannels, selectedGroup);
  const {structuredChannelsData} = useStructuredChannelsData(selectedChannels, showOnlySelected, selectedGroup);

  const rowClickFunction = (selectedData: ChannelType[]) => {
    setSelectedChannels(selectedData);
    selectedData.length < 1 && setShowOnlySelected(false);
  }

  const noResultsMessage = selectedGroup.channels?.length === 0 ? t("No channels in group") : t("No match");

  return (
    <div className={styles.tableAreaContainer}>
      {isOpen && popupComponent}
    <ChannelsTableButtons selectGroupHandler={selectGroupHandler} setShowOnlySelected={setShowOnlySelected} selectedChannels={selectedChannels} setSelectedChannels={setSelectedChannels} shownChannelsData={structuredChannelsData} setPopupType={setPopupType} setIsOpen={setIsOpen} selectedGroup={selectedGroup} filteredDataCount={filteredDataCount}/>
    <GenericTableView tableData={structuredChannelsData} handleRowClick={rowClickFunction} selectedElements={selectedChannels} noTranslateHeaders={['id']} areFiltersActive multipleRowSelection noResultsMessage={noResultsMessage} cgwOptions={{setFilteredDataCount, selectedGroup}}/>
    </div>
  )
}

export default ChannelsTableContainer