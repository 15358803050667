import React, { useEffect, useState } from 'react'
import styles from './GroupsContainer.module.scss';
import AddButtonSmall from '../../shared/AddButtonSmall/AddButtonSmall';
import GroupCardList from '../../components/GroupCardList/GroupCardList';
import { useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import { ChannelGroup } from '../../components/GroupCardList/GroupCardList.types';
import { GroupsContainerProps } from './GroupsContainer.types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';


const GroupsContainer = ({isAdminUser}: GroupsContainerProps) => {
    const privateChannelGroups:ChannelGroup[] = useAppSelector((state) => state.channelGroupReducers);
    const adminChannelGroups:ChannelGroup[] = []
    const [isGroupListOpen, setIsGroupListOpen] = useState<boolean>(false)
    const {t} = useTranslation();
    const isMobileOnSmallDesktop = useIsScreenSize(1384)

    //When screen size is not mobile, group list collapse container hidden
    useEffect(() => {
        !isMobileOnSmallDesktop && setIsGroupListOpen(false)
    },[isMobileOnSmallDesktop])
    
    return (
        <div className={styles.groupsOuterContainer}>
        <div className={styles.groupsHeadingSection}>
        {!isMobileOnSmallDesktop && <h2>{t('My Channel Groups')}</h2>}
        </div>
        {isMobileOnSmallDesktop && <button className={styles.toggleGroupsOnMobileButton} onClick={() => setIsGroupListOpen(!isGroupListOpen)}>
            {!isGroupListOpen ? <span><FontAwesomeIcon icon={faEye}/>{`${t('Show Channel Groups')}`}</span> : <span><FontAwesomeIcon icon={faEyeSlash}/>{`${t('Hide Channel Groups')}`}</span>}
            </button>}
            {<h2 className={`${!isGroupListOpen && styles.hiddenGroupsMobile}`}>{t('My Channel Groups')}</h2>}
        {isAdminUser && !isMobileOnSmallDesktop && <span className={styles.groupsHeading}>{t('Admin Groups Heading')}</span>}
        {isAdminUser && <span className={`${styles.groupsHeading} ${!isGroupListOpen && styles.hiddenGroupsMobile}`}>{t('Admin Groups Heading')}</span>}
        {isAdminUser && !isMobileOnSmallDesktop && <div className={styles.groupsInnerContainer}>
            <GroupCardList channelGroups={adminChannelGroups} isAdminUser={isAdminUser}/>
            <AddButtonSmall isAdminUser={isAdminUser}/>
        </div>}
        {isAdminUser && <div className={`${isMobileOnSmallDesktop && styles.groupsMobileInnerContainer} ${!isGroupListOpen && styles.hiddenGroupsMobile}`}>
            <GroupCardList channelGroups={adminChannelGroups} isAdminUser={isAdminUser}/>
            <AddButtonSmall isAdminUser={isAdminUser}/>
        </div>}
        {isAdminUser && !isMobileOnSmallDesktop && <span className={styles.groupsHeading}>{t('Private Groups Heading')}</span>}
        {isAdminUser && <span className={`${styles.groupsHeading} ${!isGroupListOpen && styles.hiddenGroupsMobile}`}>{t('Private Groups Heading')}</span>}
        {
        <div className={`${isMobileOnSmallDesktop && styles.groupsMobileInnerContainer} ${!isGroupListOpen && styles.hiddenGroupsMobile}`}>
            <GroupCardList channelGroups={privateChannelGroups} isAdminUser={isAdminUser}/>
            <AddButtonSmall isAdminUser={isAdminUser}/>
        </div>
        }
        {!isMobileOnSmallDesktop && <div className={`${styles.groupsInnerContainer}`}>
            <GroupCardList channelGroups={privateChannelGroups} isAdminUser={isAdminUser}/>
            <AddButtonSmall isAdminUser={isAdminUser}/>
        </div>}
        
        </div>

    );
};

export default GroupsContainer;
