import React from 'react';
import styles from './WaterfallPlayer.module.scss';
import { withTranslation } from 'react-i18next';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import WaterfallItem from '../WaterfallCard/WaterfallItem/WaterfallItem';
import prevIcon from '../../../assets/prev_segment.svg';
import nextIcon from '../../../assets/next_segment.svg';
import classNames from 'classnames';
import PushFeed from '../../../API/APIPushFeed.js';
import BackButton from '../../common/basicElements/BackButton/BackButton';
import LoadingSpinner from '../../common/basicElements/LoadingSpinner/LoadingSpinner';
import theme from '../../../assets/theme.module.css';
import { connect } from 'react-redux';
// import CustomControls from '../VideoPlayer/CustomControls/CustomControls';

library.add(faArrowLeft, faArrowRight);

class WaterfallPlayer extends React.Component {
    static propTypes = {
        onGoBack: PropTypes.func, // function; ; defines action that happens when click back button
        hits: PropTypes.array.isRequired,   // array of hits containing video urls and info to display items
        showControls: PropTypes.bool,    // default = true;
        looping: PropTypes.bool // default = false; defines if first clip should start playing again after watching last video. if set true and new data arrives, new clips will be played (after the currently playing clip)
    }

    constructor(props) {
        super(props);
        this.state = {
            currentVideo: 0,
            streamUrl: undefined,
            watchedLatest: undefined,   // index of video that was "last clip" + has been watched
            shouldLoop: props.looping ? props.looping : false,
            newDataArrived: false,
            hits: props.hits.reverse(),
            countDown: undefined,
            failedVidIndex: undefined,
            videoFailed: undefined
        };

        this.waitOnOffline = 10;
        this.itemRef = undefined;
        this.countDownInterval = undefined;

        this.nextVideo = this.nextVideo.bind(this);
        this.prevVideo = this.prevVideo.bind(this);
        this.retryOnOffline = this.retryOnOffline.bind(this);
        this.onVideoEnded = this.onVideoEnded.bind(this);
        this.onVideoFailed = this.onVideoFailed.bind(this);
    }

    nextVideo() {
        let nextVideo = this.state.currentVideo;
        if (this.state.hits.length > (this.state.currentVideo + 1)) {
            if (this.state.watchedLatest && this.state.newDataArrived) {
                nextVideo = 0;
                this.setState({ watchedLatest: undefined, newDataArrived: false });
            } else {
                nextVideo = this.state.currentVideo + 1;
            }
        } else if (this.state.shouldLoop) {
            if (this.state.currentVideo === 0 && this.videoRef) {
                this.videoRef.currentTime = 0;
                this.videoRef.play();
            }
            nextVideo = 0;
        }
        if(this.hitsContainerRef !== null) this.hitsContainerRef.scrollTo({
            top: this.itemRef.offsetHeight * (nextVideo),
            behavior: 'smooth'
        });

        this.debouncedChangeVideo(nextVideo);
    }

    prevVideo() {
        this.hitsContainerRef.scrollTo({
            top: this.itemRef.offsetHeight * (this.state.currentVideo - 1),
            behavior: 'smooth'
        });

        this.debouncedChangeVideo(this.state.currentVideo - 1);
    }

    async changeVideo(videoIndex) {
        const streamUrl = await this.getStream(this.state.hits[videoIndex].playerLink);
        this.setState({
            videoFailed: false,
            streamUrl: streamUrl,
            currentVideo: videoIndex
        });
    }

    debouncedChangeVideo = _.debounce(this.changeVideo, 0, {
        maxWait: 0
    });

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.hits.length !== prevProps.hits.length) {
            this.setState({ // reverse items + add number of new items length to currentVideo (to stay at same video)
                hits: this.props.hits.reverse(),
                currentVideo: this.state.currentVideo + (this.props.hits.length - prevProps.hits.length)
            });
        }
        if (this.props.hits.length > prevProps.hits.length && this.state.watchedLatest) {
            if (this.state.shouldLoop === false) { // if latest clip was watched until end and new one arrives, jump to next (if looping is disabled)
                this.nextVideo();
            } else {
                this.setState({ newDataArrived: true });
            }
        }
    }

    async componentDidMount() {
        this.setState({
            streamUrl: await this.getStream(this.state.hits[this.state.currentVideo].playerLink)
        });
    }

    onOfflineDetected() {
        if (this.state.countDown === undefined) {
            this.setState({ offline: true, countDown: this.waitOnOffline }, () => {
                if (this.state.countDown > 0) {
                    this.countDownInterval = setInterval(() => {
                        if (this.state.countDown > 0) {
                            this.setState({ countDown: this.state.countDown - 1 })
                        } else {
                            this.retryOnOffline();
                        }
                    }, 1000);
                }
            });
        }
    }

    async getStream(playerLink) {
        const resp = await PushFeed.getStreamUrl(playerLink);
        if (resp.status === 0) {
            this.onOfflineDetected();
        } else {
            this.setState({ offline: undefined });
        }
        return resp.streamingUrl;
    }

    retryOnOffline() {
        this.setState({ countDown: undefined }); 
        this.changeVideo(this.state.currentVideo);
        clearInterval(this.countDownInterval);
    }

    onVideoEnded() {
        this.nextVideo();
        if (this.state.hits.length - 1 === this.state.currentVideo) {
            this.setState({watchedLatest: this.state.currentVideo});
        }
    }

    onVideoFailed(httpStatusCode) {
        if (httpStatusCode !== 0) { // internet connection of device may not be available
            const compare = this.state.hits[this.state.currentVideo + 1] ? this.state.currentVideo + 1 : 0;
            if (compare === this.state.failedVidIndex) {
                this.setState({ videoFailed: true });
                return;
            }
            if (this.state.failedVidIndex === undefined) {
                this.setState({ failedVidIndex: this.state.currentVideo });
            }
            if (this.lastVideo !== this.state.currentVideo) {
                this.nextVideo();
                this.lastVideo = this.state.currentVideo;   
            }
        }
    }    

    renderVideoFailed() {
        return (
            <div className={styles.videoPlaceholder}>
                <span>
                    <div className={styles.videoFailedText}>Failed to load clips.</div>
                    <div className={styles.buttonContainer}>
                        <button className={classNames(theme.btn, theme.btnSecondary)} onClick={()=>{this.nextVideo()}}>Try again</button>
                    </div>
                </span>
            </div>
        )
    }

    renderOffline() {
        return (
            <div className={styles.videoPlaceholder}>
                <span>
                    <div className={styles.videoFailedText}>It looks like your device might be offline. We will automatically retry to load the content in a moment.</div>
                    <div className={styles.buttonContainer}>
                        <button className={classNames(theme.btn, theme.btnSecondary)} onClick={this.retryOnOffline}>Try again ({this.state.countDown})</button>
                    </div>
                </span>
            </div>
        )
    }

    render() {
        const { currentVideo, hits, offline } = this.state;
        const { onGoBack } = this.props;
        const showControls = this.props.showControls !== undefined ? this.props.showControls : true;

        const currVideoData = hits[currentVideo];
        const dir = this.props.i18n.dir();

        return (
            <div className={styles.waterfallPlayer}>
                <div className={styles.videoContainer}>
                    <div className={styles.topbar}>
                        {/* <button onClick={this.props.onFetch}>fetch</button> */}
                        <BackButton onClick={onGoBack} className={styles.back} />
                        <div className={styles.topInfo}>
                            <span style={{fontWeight: 'bold', marginInlineEnd: '.3rem'}}>
                                {currVideoData.ptitle}:{' '}
                            </span>
                            {currVideoData.title}
                        </div>
                    </div>
                    {
                        this.state.videoFailed ?
                            this.renderVideoFailed()
                        : offline === true ?
                            this.renderOffline()
                        : this.state.streamUrl ?
                            <VideoPlayer
                                url={this.state.streamUrl}
                                // url={this.rand > 0.5 ? "http://httpstat.us/404?" + this.state.currentVideo : this.state.streamUrl}                                
                                // url={"http://httpstat.us/404?" + Math.random()}
                                autoPlay={true}
                                onVideoEnded={this.onVideoEnded}
                                showControls={showControls}
                                // customControls={CustomControls}
                                hls={true}
                                hlsRef={(o) => { this.hls = o; }}
                                onVideoFailed={this.onVideoFailed}
                                videoRef={(c) => { this.videoRef = c; }}
                                clearFailedVideo={()=>{ this.setState({failedVidIndex: undefined}) }}
                            />
                        : <LoadingSpinner/>
                    }
                    {
                        showControls
                        ?   <>
                                {
                                    (currentVideo - 1) >= 0 ?
                                        <img
                                            alt="previous"
                                            src={dir === 'rtl' ? nextIcon : prevIcon}
                                            className={classNames(styles.arrow, styles.arrowLeft)}
                                            onClick={this.prevVideo}
                                        />
                                    : null
                                }
                                
                                {
                                    hits.length > (currentVideo+1) ?
                                        <img
                                            alt="next"
                                            src={dir === 'rtl' ? prevIcon : nextIcon}
                                            className={classNames(styles.arrow, styles.arrowRight)}
                                            onClick={this.nextVideo}
                                        />
                                    : null
                                }                                
                            </>
                        : null
                    }
                </div>
                <div
                    ref={(c) => { this.hitsContainerRef = c; }}
                    className={styles.hitsContainer}
                >
                    {
                        _.map(hits, (item, key)=>{
                            return <WaterfallItem
                                item={item}
                                key={key}
                                active={key === currentVideo}
                                onClick={() => {
                                    this.debouncedChangeVideo(key);
                                }}
                                title="Click to play clip"
                                onThumbnailClick={(e) => {
                                    e.stopPropagation();
                                    window.open(item.viewLink, '_blank');
                                    // TODO: PAUSE VIDEO ?
                                }}
                                thumbnailTitle=""
                                itemRef={(ref) => { if (key === currentVideo) this.itemRef = ref; }}
                                headerActiveStyle={this.props.theme.backgroundSecondary}
                            />
                        })
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(withTranslation()(WaterfallPlayer));