import React from "react";
// import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";

// import "./map.css";

import MapChart from "./MapChart.js";

class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: ""
        };
    }

    render() {
        const { content } = this.state;
        const {data, allowZoom, wrapperRef} = this.props;
        return(
            <div style={{cursor:'move'}} ref={wrapperRef}>
                <MapChart
                    setTooltipContent={(content) => {
                        this.setState({ content });
                    }}
                    // markers={[
                    //     // TODO: should not be static
                    //     {
                    //       markerOffset: -15,
                    //       name: "Buenos Aires",
                    //       coordinates: [-58.3816, -34.6037]
                    //     },
                    // ]}
                    markerType={<circle r={10} fill="#7c93c4" stroke="#011b6a" strokeWidth={2} />}
                    data={data.map(d => ({ ...d, fill: '#003855' }))}
                    fill='#cbd5df'  // maybe: #a8bdc7
                    hoverFillColor='#d78b21'
                    allowZoom={allowZoom}
                />

                {/* TODO, fix tooltip */}
                <ReactTooltip>{content}</ReactTooltip>
            </div>
        )
    }
}

export default Map;