import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './GroupCardHeader.css';
import styles from './GroupCardHeader.module.scss';
import _ from 'lodash';

// FontAwesome Base
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faPen, faSave } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import GroupCardPagination from '../GroupCardPagination/GroupCardPagination';
import Tag from 'src/Widgets/common/basicElements/TagsInput/Tag/Tag';

// fontawesome icons
library.add(faTimes, faPen, faSave);

/**
 * - id
 * - title
 * - deleteGroup (see GroupCards)
 * - changeGroupTitle (see GroupCards)
 * - uneditable
 */
export class GroupCardHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editTitle: false,
            title: this.props.title
        };

        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidUpdate() {
        if (this.titleInput) this.titleInput.focus();
    }

    componentWillUnmount() {
        if (this.delayedBlur) clearTimeout(this.delayedBlur);
    }

    onTitleChange = e => this.setState({ title: e.target.value });

    onKeyDown(e) {
        if (e.key === 'Enter') {
            this.props.changeGroupTitle(this.props.id, this.state.title);
            this.setState({ editTitle: false });
        } else if (e.key === 'Escape') {
            this.setState({ editTitle: false });
        }
    }

    render() {
        const editTitle = this.state.editTitle;
        const editOnClick = this.props.showGroupView
            ? () => this.props.showGroupView(this.props.id)
            : () => this.setState({ editTitle: !editTitle });

        // translation method with fallback
        const t = this.props.t || (k => k);

        return (
            <h3 className="groupcard-header">
                <div className={styles.flexCenter}>
                    {!this.props.uneditable && <button
                        className="groupcard-edit"
                        onClick={editOnClick}
                    >
                        <FontAwesomeIcon icon="pen" />
                    </button> }

                    <span className={styles.flexCenter}>
                        {editTitle ? (
                            <input
                                value={this.state.title}
                                onChange={this.onTitleChange}
                                onKeyDown={this.onKeyDown}
                                onBlur={() =>
                                    (this.delayedBlur = _.delay(
                                        () => this.onKeyDown({ key: 'Escape' }),
                                        150
                                    ))
                                }
                                ref={input => {
                                    this.titleInput = input;
                                }}
                                className="groupcard-input"
                            />
                        ) : (
                            this.props.title
                        )}
                        {this.props.titleTags &&
                            <span
                                className={styles.titleTags}
                            >
                                {this.props.titleTags?.map(t=><Tag>{t}</Tag>)}
                            </span>
                        }
                    </span>
                </div>

                {
                    !editTitle ? 
                        <GroupCardPagination
                            {...this.props.pagination}
                        />
                    :null
                }

                {editTitle && (
                    <div className="groupcard-controls">
                        <button
                            className="groupcard-save"
                            onClick={() => {
                                this.props.changeGroupTitle(
                                    this.props.id,
                                    this.state.title
                                );
                                this.setState({ editTitle: false });
                            }}
                        >
                            <FontAwesomeIcon icon="save" /> {t('Save')}
                        </button>

                        <button
                            className="groupcard-delete"
                            onClick={() =>
                                this.props.deleteGroup(this.props.id)
                            }
                        >
                            <FontAwesomeIcon icon="times" /> {t('Delete')}
                        </button>
                    </div>
                )}
            </h3>
        );
    }
}

export default withTranslation()(GroupCardHeader);
