import { nestedObjectToQueryString, sanitizeUrlParamsObj } from 'src/Widgets/common/helpers';
import GenericCalls from './genericCalls';

const withBodyCallSettings = { contentType: 'application/x-www-form-urlencoded; charset=utf-8' };

class APIStripe {
    static readonly BASE_URL = `/api/billing/stripe/v1`;

    // TODO: should actually be fetched from apid ? suggestion: /api/me/products
    // static async getProducts() {
    //     return await GenericCalls.get('/stripe/products');
    // }

    // static async createCheckoutSession(priceId: string, customerId: string) {
    //     return await GenericCalls.post("/stripe/public/create-checkout-session", { priceId });
    // }

    static async getCheckoutFormDetails(priceId: string, customerId: string, quantity: number) {
        const props = {
            action: `${APIStripe.BASE_URL}/checkout/sessions`,
            inputs: {
                ui_mode: "hosted",
                billing_address_collection: "required",
                customer: customerId,
                tax_id_collection: { enabled: true },
                customer_update: {
                    name: 'auto',
                    address: 'auto',
                },
                line_items: [
                    {
                        price: priceId,
                        quantity: quantity,
                    }
                ],
                mode: "subscription",
                success_url:window.location.origin + window.location.pathname,
                cancel_url: window.location.origin + window.location.pathname
            }
        }
        return await GenericCalls.post(`${APIStripe.BASE_URL}/checkout/sessions`, props.inputs, withBodyCallSettings);
    }

    static async createPortalSession(customerId: string, returnUrl?: string) {
        return await GenericCalls.post(`${APIStripe.BASE_URL}/billing_portal/sessions`, {
            customer: customerId,
            return_url: returnUrl ?? window.location.origin + window.location.pathname
        }, withBodyCallSettings);
    }

    static async createCustomer(emmUserId: number) {
        return await GenericCalls.post(`${APIStripe.BASE_URL}/customers`, { metadata: { emmUserId } }, withBodyCallSettings);
    }

    static async getSubscriptions(customerId: string, startingAfter?: string, fetchedItems?:any) {
        const params = sanitizeUrlParamsObj({
            customer: customerId,
            limit: 100,
            starting_after: startingAfter,
        });
        const res = await GenericCalls.get(`${APIStripe.BASE_URL}/subscriptions`, params);
        const all = fetchedItems||[].concat(res);
        if (res.has_more) {
            this.getProducts(res.data[res.data.length - 1].id, all);
        }
        return res;
    }

    static async getSubscriptionDetails(subscriptionId: string) {
        return await GenericCalls.get(`${APIStripe.BASE_URL}/subscriptions/${subscriptionId}`);
    }

    static async updateSubscription(subscriptionId: string, priceId: string, countryPackages: any) {
        return await GenericCalls.post(`${APIStripe.BASE_URL}/subscriptions`, { subscriptionId, priceId, countryPackages }, withBodyCallSettings);
    }

    static async getProducts(startingAfter?: string, fetchedItems?: any) {
        const params = sanitizeUrlParamsObj({
            active: true,
            limit: 100,
            starting_after: startingAfter,
        });
        const res = await GenericCalls.get(`${APIStripe.BASE_URL}/products`, params);
        const all = fetchedItems||[].concat(res);
        if (res.has_more) {
            this.getProducts(res.data[res.data.length - 1].id, all);
        }
        return res;
    }

    static async getPriceDetails(priceId: string) {
        return await GenericCalls.get(`${APIStripe.BASE_URL}/prices/${priceId}`);
    }

    static async getSubscriptionUpdatePricePreview(subscriptionId: string, priceId: string, countryPackages: any) {
        let params:any = {
            subscription_details: {
                items: [{
                    price: priceId,
                    quantity: countryPackages?.length
                }]
            }
        };
        if (subscriptionId) {
            params.subscription = subscriptionId; 
            const subscription = await APIStripe.getSubscriptionDetails(subscriptionId);
            params.subscription_details.items[0].id = subscription['items']['data'][0]['id'];
        }
        return await GenericCalls.get(`${APIStripe.BASE_URL}/invoices/upcoming?${nestedObjectToQueryString(params)}`);
    }
}

export default APIStripe;