import { ChannelTypeLowerCase } from "src/@types/shared.types";
import { useAppSelector } from "src/redux/hooks";

const useChannelIcons = () => {
    const channels = useAppSelector((state) => state.channelReducers.data);
    const findChannelIconsById = (id: number) => {
        const channel = channels?.filter(
            (channelData: ChannelTypeLowerCase) => channelData.id === id
        );
        return channel[0]?.icon;
    };
  return {
    findChannelIconsById
  }
}

export default useChannelIcons