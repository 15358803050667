import { useTranslation } from "react-i18next";
import GenericTableView from "../common/GenericTable/GenericTableView";
import Button from "../common/basicElements/Button/Button";
import LabeledSwitch from "../common/basicElements/SwitchElements/LabeledSwitch";
import ReactSwitch from "react-switch";
import TextInput from "../common/basicElements/TextInput/TextInput";

import { useEffect, useState, useRef, useLayoutEffect } from "react";
import MentionsVisualizer from "../MentionsVisualizer/MentionsVisualizer";
import BackButton from "../common/basicElements/BackButton/BackButton";

// @ts-ignore
import _ from 'lodash';
import Star from "../common/basicElements/Icons/Star";
import { faEnvelope, faCalendarDay, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import Popup from "../common/basicElements/Popup/Popup";
import SimpleSchedule from "./SimpleSchedule/SimpleSchedule";
import { SearchAgentsTypes } from "./SearchAgents.types";
import { sleep } from "../common/helpers";
import styles from './SearchAgentsTable.module.scss';
import APIProfiles from "src/API/APIProfiles";
library.add(faEnvelope, faCalendarDay, faTrash, faPen);


function SearchAgentsTable({ items }: SearchAgentsTypes.Props) {
    const { t } = useTranslation();
    const [isSearch, setIsSearch] = useState(false);
    const [modalType, setModalType] = useState<SearchAgentsTypes.ModalTypes>();
    const [agents, setAgents] = useState(items);
    const [isTitleEdit, setIsTitleEdit] = useState(false);
    const [currentAgent, setCurrentAgent] = useState<number|undefined>();

    useEffect(() => {
        setAgents(items);
    }, []);

    const titleRef = useRef<HTMLInputElement>(null);

    const enable = async (isEnabled: boolean, index: number) => {
        let updated = [...agents];
        updated[index].enabled = isEnabled;
        APIProfiles.put(updated[index].id,updated[index]);  // TODO: get queries available here - needs profile details
        setAgents(updated);
    }
    const updateTitle = async (title: string, index: number) => {
        let updated = [...agents];
        updated[index].title = title;
        APIProfiles.put(updated[index].id,updated[index]);  // TODO: get queries available here - needs profile details
        setAgents(updated);
    }
    const updateEdit = (isEdit: boolean, index: number) => {
        let updated = [...agents]; updated[index].titleEdit = isEdit;
        setAgents(updated);
        setIsTitleEdit(isEdit);
    }
    const remove = async (index: number) => {
        const id = agents[index].id;
        const updatedAgents = [...agents];
        updatedAgents.splice(index, 1);
        setAgents(updatedAgents);
        APIProfiles.delete(id);
        setCurrentAgent(undefined);
    }
    
    const renderEnableBtn = (isEnabled: boolean, index: number) => (
        <ReactSwitch
        checked={isEnabled}
        onChange={(enabled) => {
            // if (enabled || (!enabled && window.confirm(t('really?')))) {
                enable(enabled, index)
            // }
        }}
        width={40}
        height={ 20}
    />)

    useEffect(() => {
        (async () => {
            await sleep(200);   // TODO: improvement: get working without timeout
            if (isTitleEdit && titleRef.current) {
                titleRef.current.focus();
            }
        })();
    }, [isTitleEdit]);

    
    const tData = agents.map((t, index) => {
        // return index === tableData.length - 1 ? t :
        return {
            Favorite: <div className={styles.col} onClick={()=>{}}><Star active={t.enabled} /></div>,
            Title: (t.titleEdit
                ? (<TextInput inputRef={titleRef} onBlur={() => { updateEdit(false, index); }} onChange={(e) => { updateTitle(e.target.value, index); }} value={t.title} />)
                : <>
                    <div className={styles.col} onClick={() => { updateEdit(true, index); }}>
                        {t?.title}
                    </div>
                </>
            ),
            Query: t.query,
            Enabled: renderEnableBtn(t.enabled, index),
            Notifications: <div className={styles.col} onClick={() => { setModalType('changeSchedule'); }}>
                <FontAwesomeIcon icon="envelope" />
            </div>,
            Edit: <div className={styles.col} onClick={() => { setIsSearch(true); }}><FontAwesomeIcon icon="pen" /></div>,
            Delete: <div className={styles.col} onClick={() => { setCurrentAgent(index); setModalType('deleteSearchAgent'); }}><FontAwesomeIcon icon="trash" /></div>,
         }
    });

    let modal;
    if (modalType) {
        switch (modalType) {
            case 'changeSchedule':
                // @ts-ignore
                modal = (<Popup
                    size='auto'
                    blockContent={true}
                    onOk={() => { setModalType(undefined); }}
                    onCancel={()=>{ setModalType(undefined); }}
                >
                    <SimpleSchedule/>
                </Popup>)
                break;
            case 'saveSearchAgent':
                // @ts-ignore
                modal = (<Popup
                    size='auto'
                    blockContent={true}
                    onOk={() => { setModalType(undefined); setIsSearch(false); }}
                    onCancel={() => { setModalType(undefined); setIsSearch(false); }}
                >
                    {t('want_to_save_changes')}
                </Popup>)
                break;
            case 'deleteSearchAgent':
                // @ts-ignore
                modal = (<Popup
                    size='auto'
                    blockContent={true}
                    onOk={() => { if(currentAgent !== undefined){ remove(currentAgent); } setModalType(undefined); }}
                    onCancel={() => { setModalType(undefined); }}
                >
                    {t('really_delete_search_agent')}
                </Popup>)
                break;
        }
    }

    return (
        <div style={{height:'100%'}}>
            {modal}
        {isSearch ? <div style={{height:'100%'}}><BackButton
            onClick={() => {
                setModalType('saveSearchAgent');
            }}
    /><MentionsVisualizer isSearch={true}/></div> : <GenericTableView
        tableData={ tData
        //   this.props.listColumns ? items.map((i) => {
        //     let row = {};
        //     this.props.listColumns.forEach((column) => {
        //         row[column.label] = column.formatter
        //             ? column.formatter(i[column.key])
        //             : i[column.key];
        //     });
        //     return row;
        // }) : items
        }
        areFiltersActive
                // handleRowClick={(item) => {
                //     setIsSearch(true)
                //  }}
        disabledColumns={[]}
        noResultsMessage={t('No match')}
        // customRenderers={this.props.customTableRenderers}
        // disabledColumnFilters={this.props.disabledColumnFilters}
            />}
            </div>
    )
}

export default SearchAgentsTable;