import React from 'react';
//import styles from './ReChart.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import BarChart from './BarChart';

class ReChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            containerSize: undefined,
            fontSize: undefined,
        };
    }

    onRefChange = (r)=>{
        // set font size based on container width + height
        if(r) {
            const s = this.state.containerSize;
            const w = r.current.clientWidth;
            const h = r.current.clientHeight;
            if(!s || s.w !== w || s.h !== h){
                const fontSize = Math.min(w, h) / 30;   // divisor 30 was found by experimenting with diff. values and widget sizes, could be adjusted to something else
                this.setState({
                    containerSize:{
                        w: r.current.clientWidth,
                        h: r.current.clientHeight
                    },
                    fontSize: fontSize > 14 ? fontSize : 14,
                })
            }
        }
    }

    render() {
        const children = React.Children.toArray(this.props.children);
        return(React.cloneElement(children[0],{
            ...this.props,
            onRefChange: this.onRefChange,
            fontSize: this.state.fontSize,
        }));
    }
}

const mapStateToProps = state => ({
    //theme: state.theme,
});
export default connect(mapStateToProps,{ /* insert redux actions here */ })(withTranslation()(ReChart));