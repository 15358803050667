import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NotificationCard.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

// fontawesome icons
library.add(faCheck);

const NotificationCard = props => {
    return (
        <div className={'notification-card'}>
            <div
                className="notification-content"
                dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
                onClick={() => {
                    window.open(props.link, '_blank');
                }}
            />
            {props.closeNotification && (
                <button
                    className="notification-closer"
                    onClick={props.closeNotification}
                >
                    <FontAwesomeIcon icon="check" />
                </button>
            )}
        </div>
    );
};

export default NotificationCard;
