import { FC, useEffect, useRef, useState } from 'react';
import styles from '../OverviewCluster.module.scss';
import { SentimentIconBoxProps } from './SentimentIconBox.types';
import VideoCard from '../VideoCard/VideoCard';
import APIMentions from 'src/API/APIMentions';
import useChannelIcons from 'src/hooks/useChannelIcons';

const SentimentIconBox: FC<SentimentIconBoxProps> = ({
    notif,
    conditionalSentimentIcon
}) => {
    const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false);
    const [detailsData, setDetailsData] = useState<any[]>([]);
    const boxRef = useRef<HTMLSpanElement>(null);

    //const { findChannelIconsById } = useChannelIcons();

    const handleBoxClick = (value: boolean) => {
        setIsBoxOpen(value);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
            handleBoxClick(false);
        }
    };

/*     const fetchNotificationDetails = async (notificationId: number) => {
        try {
            const res = await APIMentions.getNotificationResults(notificationId);
            setDetailsData(res.data);
        } catch (error) {
            console.log(error);
        }
    }; */


    useEffect(() => {
        //notif && fetchNotificationDetails(notif.notification_id)
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const channelIconSrc = notif.icon; //findChannelIconsById(notif.channelNumber);

    return (
        <span
            className={styles.iconBox}
            key={notif.id}
            ref={boxRef}
            data-testid="iconBoxContainer"
            onClick={() => handleBoxClick(!isBoxOpen)}
        >
            {isBoxOpen && (
                <div
                    className={`${styles.modalContainer} ${isBoxOpen ? styles.open : ''}`}
                    onClick={() => handleBoxClick(false)}
                    data-testid="modalContainer"
                >
                    <VideoCard
                        icon={channelIconSrc}
                        cluster={notif}
                        conditionalSentimentIcon={conditionalSentimentIcon}
                        date={notif.beginF}
                    />
                </div>
            )}
            <div className={styles.imagesContainer}>
                <img
                    className={
                        styles.moreIconNotActive
                    }
                    src={notif.icon}
                    alt="more icon"
                />
                <img
                    className={
                        isBoxOpen ? styles.kfActive : styles.kfNotActive
                    }
                    src={notif.kfLink}
                    onClick={() => handleBoxClick(!isBoxOpen)}
                    alt="more icon"
                />
            </div>

            {/* {conditionalSentimentIcon(notif.sentiment)} */}
        </span>
    );
};

export default SentimentIconBox;
