import { setIsEditable as boardSetIsEditable, setShowUserMenu, setShowWidgetSelection, setShowHelpMenu } from "src/redux/actions/actions.board";
import { JoyrideTour } from "../JoyrideTour.types";

const getTourSteps = (params: JoyrideTour.GetStepsParams) => {
    const { dispatch, t, renderTourBox, renderOrangeBox, history, supportEmail } = params;
    const steps = [
        {
            disableBeacon: true,
            content: () =>
            (
                renderTourBox(<><b>Comming soon</b> <div>Tour will be available soon</div></>)
            ),
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                dispatch(setShowHelpMenu(true));
            },
            target: '[data-tut="tour__helpmenu"]',
        },
    
    ];

    return steps;
}

export {getTourSteps}