import React, { Component, createRef, useState } from 'react';
import _ from 'lodash';
import './GroupCard.css';
import GroupCardHeader from './GroupCardHeader/GroupCardHeader';
import GroupCardItem from './GroupCardItem/GroupCardItem';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';

// FontAwesome Base
import {
    faPlus,
    faPen,
    faChevronRight,
    faChevronLeft
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import Button from '../../basicElements/Button/Button';
import { InfoIcon } from '../../basicElements/InfoIcon/InfoIcon';
import { getSortFunct } from '../../helpers';
//import { useTranslation } from 'react-i18next';

/**
 * - id
 * - title
 * - items (see GroupCards) (required)
 * - changeGroupTitle (see GroupCards)
 * - deleteGroup (see GroupCards)
 * - addItem (see GroupCards)
 *
 * - useGravatar (see GroupCardsContainer)
 * - uneditable
 * - editItemIcon (boolean)
 * - maxItems (number, optional)
 * - addItemDisabled: renders plus button disabled
 * - addItemTitle: string, added as title prop. of plus button
 */
// fontawesome icons
library.add(faPlus, faPen, faChevronRight, faChevronLeft);

export class GroupCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxItems: props.maxItems ? props.maxItems : 10,
            page: 0
        };

        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
    }
    // bool secondary
    renderItems(secondary) {
        const { page, maxItems } = this.state;
        let toMap = secondary ? this.props.secondaryItems : this.props.items;
        
        const from = page * maxItems;
        const to = (page + 1) * maxItems;
        if (toMap) {
            toMap = toMap.sort(getSortFunct('title'));
            toMap = toMap.slice(from, to);
        }

        return _.map(toMap && toMap.sort(getSortFunct('title')), item => (
            <GroupCardItem
                key={(secondary ? 'sec-' : '') + item.id}
                onClick={item.onClick}
                title={item.title}
                icon={item.icon}
                secondary={secondary}
                disabled={item.disabled}
                highlighted={item.highlighted}
                useGravatar={this.props.useGravatar}
            />
        ));
    }

    nextPage() {
        this.setState({ page: this.state.page + 1 });
    }

    previousPage() {
        this.setState({ page: this.state.page - 1 });
    }

    render() {
        const items = this.props.items;
        const secItems = this.props.secondaryItems;
        let numberOfItems = 0;
        numberOfItems += items ? items.length : 0;
        numberOfItems += secItems ? secItems.length : 0;
        const currentPage = this.state.page + 1;

        // translation method with fallback
        const t = this.props.t || (k => k);

        const renderItems =
            items.length > 0 || (secItems && secItems.length > 0) ? (
                _.concat(this.renderItems(true), this.renderItems())
            ) : (                
                <div>
                    {t('No items in this group')}
                    {this.props.onDrop ? <><br></br><br></br>{t("Hint drop files")}</> : null}
                </div>                
            );

        return (
            <article ref={this.props.itemRef} className={'groupcard ' + this.props.className}>
                <GroupCardHeader
                    id={this.props.id}
                    title={
                        <>
                            {this.props.title}
                            {this.props.groupCardtitleInfo &&
                                <InfoIcon
                                    style={{ paddingInlineStart: '.4rem' }}
                                    title={this.props.groupCardtitleInfo}
                                />
                            }
                        </>
                    }
                    deleteGroup={this.props.deleteGroup}
                    changeGroupTitle={this.props.changeGroupTitle}
                    showGroupView={this.props.showGroupView}
                    uneditable={this.props.uneditable}
                    pagination={{
                        currentPage:currentPage,
                        numberOfItems:numberOfItems,
                        itemsPerPage:this.state.maxItems,
                        onNextClick:this.nextPage,
                        onPrevClick:this.previousPage
                    }}
                    titleTags={this.props.titleTags}
                />
                <section className="groupcard-content">

                    {(this.props.addItem || this.props.onDrop) && <Button
                        type='secondary'
                        btnClass={classNames(
                            'btnRoundSmall',
                            'item-add-button'
                        )}
                        disabled={this.props.addItemDisabled}
                        title={this.props.addItemTitle}
                        onClick={
                            () => this.props.onDrop 
                                ? this.dropzone.open() 
                                : this.props.addItem(this.props.id)
                        }
                    >
                        {this.props.editItemIcon ? (
                            <FontAwesomeIcon icon="pen" />
                        ) : (
                            <FontAwesomeIcon icon="plus" />
                        )}
                    </Button>}
                    
                    {
                        this.props.onDrop ?
                            <Dropzone
                                onDrop={(files) => { this.props.onDrop(files, this.props.id) }}
                                noClick={true}
                                ref={(ref) => { this.dropzone = ref; }}
                            >
                            {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ 
                                className: 'dropzone'
                            })}>
                                <input {...getInputProps()} />
                                {renderItems}
                            </div>
                            )}
                        </Dropzone>
                        :  renderItems 
                    }
                </section>
            </article>
        );
    }
}

export default withTranslation()(GroupCard);