import React, { useEffect, useState } from 'react';
import styles from './GenericTable.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import GenericTableFilter from './GenericTableFilter/GenericTableFilter';
import useGenericTableToggleFilterState from 'src/Widgets/common/GenericTable/hooks/useGenericTableToggleFilterState';
import { useGenericTableFiltering } from 'src/Widgets/common/GenericTable/hooks/useGenericTableFiltering';
import { GenericTableProps } from './GenericTable.types';
import useGenericTableFilterCheckbox from './hooks/useGenericTableFilterCheckbox';
import LoadingSpinner from '../basicElements/LoadingSpinner/LoadingSpinner';
import GenericTableHover from './GenericTableHover/GenericTableHover';


const GenericTable: React.FC<GenericTableProps> = ({
    data,
    onRowClick,
    areFiltersActive,
    multipleRowSelection,
    selectedElements,
    noResultsMessage,
    cgwOptions,
    customRenderers,
    disabledColumnFilters = []
}) => {
    const {
        convertedData: { columnHeaders, matrix }
    } = data;

    const { setFilteredDataCount, selectedGroup } = cgwOptions || {};

    const [sortingDirection, setSortingDirection] = useState<{[key: string]: string}>({});
    const [selectedRows, setSelectedRows] = useState<{[key: string | number]: string | number}[]>([]);

    const { checkedState, handleCheckboxClick, hasCheckedItems, currentlyCheckedValues, setCheckedState } =
        useGenericTableFilterCheckbox({
            onToggle: (header, rowIndex) =>
                matrix[rowIndex][columnHeaders.indexOf(header)]
        });

    const { handleColumnClick, openColumnHeader, setOpenColumnHeader } = useGenericTableToggleFilterState();

    const { filteredMatrixData, filteredRows } = useGenericTableFiltering(
        checkedState,
        data,
        openColumnHeader,
        currentlyCheckedValues,
        sortingDirection
    );

    // If the selectedGroup exists and prop changes, clear the checkedState state
    useEffect(() => {
        if(cgwOptions){
            setCheckedState({});
            setOpenColumnHeader(null);
        }
    }
    , [selectedGroup]);

    const isColumnFilterDisabled = (header: string) => disabledColumnFilters.includes(header);

    // If the row is clicked, update the selectedRows state
    const handleTableRowClick = (selectedRow: {[key: string | number]: string | number}) => {
        let newSelectedRows;
        if (multipleRowSelection) {
            // If multipleRowSelection is true, toggle the selection status
            const selectedIndex = selectedRows.findIndex(row => row.id === selectedRow.id);
            if (selectedIndex === -1) {
                // Row not selected, add it to the array
                newSelectedRows = [...selectedRows, selectedRow];
            } else {
                // Row already selected, remove it from the array
                newSelectedRows = selectedRows.filter((row) => row.id !== selectedRow.id);
            }
            setSelectedRows(newSelectedRows);
            if(onRowClick) onRowClick(newSelectedRows);
        } else {
            // If multipleRowSelection is false, update the single selected object
            if(onRowClick) onRowClick(selectedRow);
        }  
    };

    //If the selectedElements prop changes, clear the selectedRows state
    useEffect(() => {
        selectedElements && !selectedElements.length && setSelectedRows([]);
    }
    , [selectedElements]);

    //Store the filtered data count in the state
    useEffect(() => {
        setFilteredDataCount &&
            setFilteredDataCount(
                filteredMatrixData[0]?.length === 0 ? 0 : filteredMatrixData.length
            );
    }, [filteredMatrixData]);
    
    // If the data is not loaded yet, show a loading spinner
    if ((filteredMatrixData[0]?.length === 0 && selectedGroup && !Object.keys(selectedGroup).length)) {
        return <LoadingSpinner size='2rem'/>
    }

    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {columnHeaders.map((header, index) => (
                            <th
                                style={{ position: 'relative' }}
                                key={index}
                                className={styles.headersRow}
                                onClick={(e) => e.stopPropagation()}
                            >
                                {/* If the header is the first one and multipleRowSelection is true, add a checkbox */}
                                {index === 0 && multipleRowSelection && (
                                    <>
                                        <input
                                            type="checkbox"
                                            className={styles.customCheckbox}
                                            data-testid="headerCheckbox_TestID"
                                            id={'allCheckbox'}
                                            checked={
                                                selectedRows.length ===
                                                    filteredRows.length &&
                                                filteredRows.length !== 0
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={() => {
                                                if (
                                                    selectedRows.length ===
                                                    filteredRows.length
                                                ) {
                                                    setSelectedRows([]);
                                                    if(onRowClick) onRowClick([]);
                                                } else {
                                                    setSelectedRows(filteredRows);
                                                    if(onRowClick) onRowClick(filteredRows);
                                                }
                                            }}
                                        />
                                        <label htmlFor={'allCheckbox'}></label>
                                    </>
                                )}
                                {header}
                                {!isColumnFilterDisabled(header) && areFiltersActive && (
                                    <FontAwesomeIcon
                                        icon={faFilter}
                                        className={styles.filterIcon}
                                        style={
                                            hasCheckedItems(header)
                                                ? { color: 'rgb(14, 101, 232)' }
                                                : undefined
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleColumnClick(e, header);
                                        }}
                                    />
                                )}
                                {openColumnHeader === header && areFiltersActive && (
                                    <GenericTableFilter
                                        openColumnHeader={openColumnHeader}
                                        data={data}
                                        checkedState={checkedState}
                                        handleCheckboxClick={handleCheckboxClick}
                                        handleColumnClick={handleColumnClick}
                                        currentlyCheckedValues={currentlyCheckedValues}
                                        sortingDirection={sortingDirection}
                                        setSortingDirection={setSortingDirection}
                                    />
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredMatrixData.map((row, rowIndex) => {
                        return (
                            <tr
                                data-testid="genericTableRow_TestID"
                                className={[styles.tableRow, onRowClick ? styles.clickable : ''].join(' ')}
                                key={rowIndex}
                                onClick={() =>
                                    handleTableRowClick(filteredRows[rowIndex])
                                }
                            >
                                {row.map((cell, cellIndex) => {
                                    // Check if a custom renderer is provided for this column
                                    const columnHeader = columnHeaders[cellIndex];
                                    const customRenderer =
                                        customRenderers?.[columnHeader];

                                    return (
                                        <td key={cellIndex} data-label={columnHeader}>
                                            {cellIndex === 0 && multipleRowSelection ? (
                                                <span className={styles.firstColumn}>
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox_${rowIndex}_${cellIndex}`}
                                                            className={
                                                                styles.customCheckbox
                                                            }
                                                            checked={selectedRows.some(
                                                                (selectedRow) =>
                                                                    selectedRow?.id ===
                                                                    filteredRows[rowIndex]
                                                                        ?.id
                                                            )}
                                                            onChange={() =>
                                                                handleTableRowClick(
                                                                    filteredRows[rowIndex]
                                                                )
                                                            }
                                                        />
                                                        <label></label>
                                                        {customRenderer
                                                            ? customRenderer(
                                                                filteredRows[rowIndex],
                                                                  rowIndex
                                                              )
                                                            : cell}
                                                    </div>
                                                    <GenericTableHover data={cell} />
                                                </span>
                                            ) : customRenderer ? (
                                                customRenderer(filteredRows[rowIndex], rowIndex)
                                            ) : (
                                                cell
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {(Object.values(data.tableData).length < 1 || filteredRows.length < 1) &&
                areFiltersActive &&
                noResultsMessage && (
                    <span className={styles.noResultContainer}>{noResultsMessage}</span>
                )}
        </div>
    );
};

export default GenericTable;
