import React from 'react';
import styles from './SignUp.module.scss';
import EmmBanner from 'src/Login/EmmBanner/EmmBanner';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import TextInput from 'src/Widgets/common/basicElements/TextInput/TextInput';
import LabeledSelect from 'src/Widgets/common/basicElements/LabeledSelect/LabeledSelect';
import _ from 'lodash';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';

function SignUp({
    timezones
    ,selectedTimezone
    ,onTimezoneChange
    ,languages
    ,selectedLanguage
    ,onLanguageChange
    ,onCreate
    , email
    ,isCreatingWorkspaces
}) {
    const { t } = useTranslation();
    const languageOptions = _.map(languages, (lang, index) => ({
        label: t(lang),
        value: lang
    }));
    const selectedLanguageOption = _.find(languageOptions, {
        value: selectedLanguage
    });

    const timezoneOptions = _.map(timezones, (tz, index) => ({
        label: tz.title,
        value: tz.id
    }));
    const selectedTzOption = _.find(timezoneOptions, {
        value: selectedTimezone
    });

    return(
        <EmmBanner>
            <div className={styles.userInput}>
                <h2>{t('sign_up')}</h2>
                {
                    isCreatingWorkspaces ?
                        <div style={{paddingBlockStart:'1rem'}}>
                            <LoadingSpinner
                                size='3.5rem'
                            />
                            <p
                                style={{ fontWeight: '500', fontSize: '1.1rem' }}
                            >
                                {t('account_prepare_first_time')}
                            </p>
                            </div>
                        :
                        <>
                <div className={styles.setup}>
                    <p
                        style={{display:'flex', alignItems:'center'}}
                    >
                        {t("create_account")}
                        {/* <img src={googleLogo} style={{marginLeft: '0.4rem',height:'1rem'}} alt="Google"/> */}
                    </p>
                    <TextInput
                        value={email}
                        disabled={true}
                        onChange={() => {}}
                    />
                    <p>
                        {t("create_account2")}
                    </p>
                </div>
            
                <div style={{width:'100%'}}>
                    
                    <LabeledSelect
                        style={{width:'100%'}}
                        label={t('Timezone') + '*'}
                        info={t('info_timezone')}
                        className={styles.select}
                        placeholder={t('Timezone') + '*'}
                        options={timezoneOptions}
                        value={selectedTzOption}
                        onChange={(o) => { onTimezoneChange(o?.value); }}
                    />

                    <LabeledSelect
                        style={{width:'100%'}}
                        label={t('E-Mail Language') + '*'}
                        info={t('info_email')}
                        className={styles.select}
                        placeholder={t('E-Mail Language') + '*'}
                        options={languageOptions}
                        value={selectedLanguageOption}
                        onChange={(o) => { onLanguageChange(o?.value); }}
                    />
                </div>

                <p style={{marginBlockStart:0}}>
                    {t('click_agree_tos')} <a target="_blank" rel="noreferrer" href="https://www.emediamonitor.net/en/terms-of-use/">{t('terms_of_use')}</a>, <a target="_blank" rel="noreferrer" href="https://www.emediamonitor.net/en/product-privacy-policy/">{t('privacy_policy')}</a>.
                </p>
                <Button
                    type='success'
                    onClick={onCreate}
                >
                    {t('create_my_account')}
                </Button>
                </>}
            </div>
        </EmmBanner>
    );
}

export default withRouter(SignUp);