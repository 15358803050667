import styles from './TableFilter.module.scss';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/Widgets/common/basicElements/TextInput/TextInput';
import SortClickable from '../../components/SortClickable/SortClickable';
import Checkbox from 'src/Widgets/common/basicElements/Checkbox/Checkbox';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import {
    toggleFilter
} from 'src/redux/actions/actions.channelGroupsWidget';
import { ChangeEvent, FC } from 'react';
import { TableFilterProps } from './TableFilter.types';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';


const TableFilter: FC<TableFilterProps> = ({
    setInputData,
    inputData,
    filterData,
    checkedValues,
    handleAllFunction,
    clearFilterFunction
}) => {
    const { t } = useTranslation();
    const { filteredChannels, sortedChannels, searchedChannels } =
        useAppSelector((state) => state.channelGroupsWidgetReducer);
    const dispatch = useAppDispatch();

    const closeButtonHandler = () => {
        dispatch(toggleFilter(false));
    };

    const channelTypes = filteredChannels || sortedChannels || searchedChannels;


    const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setInputData(e.target.value)
    }
  
    return (
        <>
            <TextInput
        value={inputData}
        onChange={inputChangeHandler}
        inputClassName={styles.searchInput}
        icon={faSearch}
        placeholder={`${t('Search')}...`}
        />

            <SortClickable sortData={channelTypes} />
            <div className={styles.allCheckbox}>
                <Checkbox
                    key="all"
                    text={t("All")}
                    withBorder={true}
                    checked={checkedValues?.all}
                    onClick={() => {
                        handleAllFunction();
                    }}
                />
            </div>
            <div style={{ flex: '1 1 auto' }} className={styles.filteredContainer}>
                {filterData}
            </div>
            <div className={styles.buttonsContainer}>
                <Button
                    onClick={closeButtonHandler}
                    btnClass={styles.button}
                    type="downloadButton"
                >
                    {t("Ok")}
                </Button>
                <Button
                    onClick={clearFilterFunction}
                    btnClass={styles.button}
                    type="danger"
                >
                    {t("Cancel")}
                </Button>
            </div>
        </>
    );
};

export default TableFilter;
