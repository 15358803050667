import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledSwitch from 'src/Widgets/common/basicElements/SwitchElements/LabeledSwitch';
import styles from './SimpleSchedules.module.scss';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import Weekdays from './Weekdays/Weekdays';
import Separator from 'src/Widgets/common/basicElements/Separator/Separator';
import { useSelector } from 'react-redux';
import TextInput from 'src/Widgets/common/basicElements/TextInput/TextInput';
import { addRangeToSet } from 'src/Widgets/common/helpers';

function SimpleSchedule() {
    const hours = Array.from({ length: 24 }, (_, i) => i);

    const [isRealtime, setIsRealtime] = useState(false);
    const [daysSelected, setDaysSelected] = useState<Set<number>>(new Set());
    const [hoursSelected, setHoursSelected] = useState<Set<number>>(new Set());
    const [selectFrom, setSelectFrom] = useState(0);
    const [selectTo, setSelectTo] = useState(23);
    // @ts-ignore
    const theme = useSelector((state) => state.theme);

    const { t } = useTranslation();

    function selectNumbers(from:number, to:number) {
        const hoursSelected = addRangeToSet(from, to, new Set());
        setHoursSelected(hoursSelected);
    }

    return (
        <div className={styles.wrapper}>
            <h2 className={theme.textPrimary}>Notifications</h2>
            <p style={{ fontSize: '0.9rem' }}>{t('simple_schedule_info')}</p>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <span>From  </span>
                {/* <input
                    type="number"
                    min="0"
                    max="23"
                    value={selectFrom}
                    onChange={(e)=>{
                        const val = parseInt(e.target.value);
                        if(val < 0) return;
                        setSelectFrom(val)
                    }}
                /> */}
                <TextInput
                    placeholder=' '
                    inputType="number"
                    max={23}
                    min={0}
                    className={styles.repeatNumber}
                    value={selectFrom}
                    onChange={(e)=>{
                        const val = parseInt(e.target.value);
                        if(val < 0) return;
                        setSelectFrom(val)
                    }}
                />
                <span>to</span>
                <TextInput
                    placeholder=' '
                    inputType="number"
                    max={23}
                    min={0}
                    className={styles.repeatNumber}
                    value={selectTo}
                    onChange={(e)=>{
                        setSelectTo(parseInt(e.target.value))
                    }}
                />
                <Button onClick={()=>{ selectNumbers(selectFrom, selectTo)}} type="secondary">Apply</Button>
            </div>
            <div className={styles.schedule} style={{ opacity: isRealtime ? '0.5' : 1 }}>
                <Weekdays
                    className={styles.weekdays}
                    isDisabled={isRealtime}
                    selected={daysSelected}
                    onClick={(day: any) => {
                        setDaysSelected((prev) => {
                            console.log(day);
                            const rv = new Set(prev);
                            if (rv.has(day)) {
                                rv.delete(day);
                            } else {
                                rv.add(day);
                            }
                            return rv;
                        });
                    }}
                />
                <div className={styles.hours}>
                    {hours.map((h, index: number) => {
                        return (
                            <Button
                                type={hoursSelected.has(h) ? 'secondary' : 'cancel'}
                                key={index}
                                btnStyle={{width:'2.9rem'}}
                                disabled={isRealtime}
                                onClick={() => {
                                    setHoursSelected((prev) => {
                                        const rv = new Set(prev);
                                        if (rv.has(h)) {
                                            rv.delete(h);
                                        } else {
                                            rv.add(h);
                                        }
                                        return rv;
                                    });
                                }}
                            >
                                {h}
                            </Button>
                        );
                    })}
                </div>
            </div>
            <Separator />
            <div className={styles.realtime}>
                <LabeledSwitch
                    checked={isRealtime}
                    onChange={() => {
                        setIsRealtime(!isRealtime);
                    }}
                >
                    {t('real time')}
                </LabeledSwitch>
            </div>
        </div>
    );
}

export default SimpleSchedule;
