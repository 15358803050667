import React from 'react';
import styles from './WorkspaceSettings.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import WidgetSettings from 'src/Widgets/common/WidgetSettings/WidgetSettings';
import _ from 'lodash';
import { putWorkspace } from 'src/redux/actions/actions.workspaces';
import { getUniqueWidgetsSettings } from 'src/Widgets/common/helpers';
import { getAvailableWidgets } from '../availableWidgets';

class WorkspaceSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.availableWidgets = getAvailableWidgets(this.props.me?.experimentalWidgetsSelectable, this.props.me?.allWidgets);
    }

    render() {
        const { t, workspace, putWorkspace } = this.props;
        const settings = getUniqueWidgetsSettings(workspace.widgets, this.availableWidgets);

        return (
            <>
            <WidgetSettings
                {...this.props}
                show={workspace.state.showSettings}
                workspaceId={workspace.id}
                settings={settings}
                isWorkspace={true}
                placeholderText={
                    t("workspace-no-settings")
                }
                saveWidgetState={(workspaceId, widgetId, data) => {
                    let w = _.cloneDeep(workspace);
                    w.state.settings = { ...data.settings };
                    Object.keys(w.state.settings).map((k) => {
                        if (w.state.settings[k] === undefined) {
                            delete w.state.settings[k]; // do not keep empty settings
                        }
                        return undefined;
                    })

                    // if workspace settings are active: ensure we close opened widgets settings (if any)
                    w.widgets = w.widgets.map(w => {
                        if (w.state.showSettings) {
                            w.state.showSettings = false;
                        }
                        return w;
                    });
                    
                    putWorkspace(w);
                }}
                toggleWidgetSettings={() => {
                    let workspace = _.cloneDeep(this.props.workspace);
                    workspace.state = workspace.state || {};
                    workspace.state.showSettings = false;
                    putWorkspace(workspace);
                }}
                className={styles.wrapper}
                />
                </>
        )
    
    }
}

const mapStateToProps = state => ({
    workspaces: state.workspaces,
    board: state.board,
    me: state.me,
});
export default connect(mapStateToProps,{ putWorkspace })(withTranslation()(WorkspaceSettings));