import React from 'react';
import _ from 'lodash';

import delayUnmounting from '../../../HOC/delayUnmounting';

import styles from './WidgetSelection.module.scss';
import classNames from 'classnames';
// import randomColor from 'randomcolor';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class WidgetSelection extends React.Component {
    constructor(props){
        super(props);
        this.colors=[

            {r:255, g:147, b:0},
            {r:48, g:79, b:144},
            {r:129, g:169, b:196},
            {r:207, g:101, b:34},
            {r:13, g:45, b:61},

        ]
    }

    render() {
        const t = this.props.t || (k => k);

        const groupedWidgets = _.groupBy(
            this.props.widgetsToSelect.filter(widget=>this.shouldShow(widget, this.props.me)),
            w => w.tag || t('Misc')
        );

        return (
            <>
                <div className={styles.backdrop} onClick={this.props.hideWidgetSelection}>
                </div>
                <div
                    className={classNames(
                        'animated',
                        { fadeIn: this.props.isMounted, fadeOut: !this.props.isMounted },
                        styles.popup
                    )}
                >
                    <div className={styles.selection}>
                        <div className={styles.titleWrap}>
                            <span className={styles.ruler} />
                            <span className={styles.title}>{t('Widget Selection')}</span>
                            <span
                                className={classNames(styles.ruler, styles.rulerSmall)}
                            />
                        </div>

                        {this.renderWidgetList(groupedWidgets)}
                    </div>
                </div>
            </>
        );
    }

    shouldShow(widget, me) {
        // companyGroupAdmin > companyAdmin
        if (widget.isCompanyGroupAdmin) {
            if (!me.isCompanyGroupAdmin) return false;
        }

        if (widget.isUserManagerForCompany) {
            if (!me.isUserManagerForCompany && !me.isCompanyGroupAdmin) return false;
        }

        return true;
    }

    renderWidgetList(groupedWidgets) {
        const t = this.props.t || (k => k);
        const { theme } = this.props;
        let groupIdx = -1;

        return _.map(groupedWidgets, (group, key) => {
            groupIdx = groupIdx + 1;
            return (
                <div className={styles.group} key={key}>
                    <div className={styles.groupHeader}>
                        <span
                            className={classNames(
                                styles.ruler,
                                styles.rulerSmall,
                                theme.backgroundPrimary
                            )}
                        />
                        <span className={classNames(theme.textPrimary)}>
                            {t(key)}
                        </span>
                        <span className={classNames(styles.ruler, theme.backgroundPrimary)} />
                    </div>
                    <div className={styles.groupButtons}>
                        {_.map(group, (widget, widgetKey) => {
                            let colorGrad = 1;

                            // lowering channel of background color for each widget (until min-value)
                            // disabled - as requested by LV, MLO (we have too many widgets in e.g. 'Analytics' category anyways for this to make sense)
                            // let colorGrad = 1 - (widgetKey + 1)*0.1;

                            // if (colorGrad <= 0.29){
                            //     colorGrad = 0.3
                            // }

                            let c = this.colors[groupIdx];
                            if(!c){ c = { r:0, g:0, b:0 } } // fallback to ensure the widgets list does not crash if running out of colors (if new groups aka 'tags' get added)
                            const cStr = `rgba(${c.r}, ${c.g}, ${c.b}, ${colorGrad})`;
                            
                            return <div key={widgetKey} className={widget.experimental !== false ? styles.experimentalWrapper : undefined}>
                                    {widget.experimental !== false && 
                                        <div
                                            className={styles.experimental}
                                            title="Experimental: This widget is in early development. It is shown on server 'next' only."
                                        >
                                            experimental
                                            <FontAwesomeIcon style={{marginInlineStart:'.3rem'}} icon={faInfoCircle}/> 
                                        </div>
                                    }
                                <Button
                                    type='widgetSelection'
                                    title={
                                        (widget.disabled  && (process.env.REACT_APP_ENV !== 'development') ? `${t('widget_not_allowed')}\n\n${t('widget_description')}:\n` : '') +
                                        (widget.title ? t(widget.title) : '')
                                    }
                                    disabled={widget.disabled && (process.env.REACT_APP_ENV !== 'development')}
                                    btnStyle={{
                                        
                                        backgroundColor: cStr
                                        
                                    }}
                                        onClick={() => {
                                            this.props.addWidget(widget.key, widget.name);
                                            this.props.hideWidgetSelection();
                                        }}
                                    key={widget.key}
                                >
                                    {t(widget.name)}
                                </Button></div>
                                })}
                    </div>
                </div>
            );
        });
    }
}

const mapStateToProps = state => ({ me: state.me, theme: state.theme })
export default connect(mapStateToProps)(withTranslation()(delayUnmounting(WidgetSelection)));
