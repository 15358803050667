import Button from "src/Widgets/common/basicElements/Button/Button"
import { HelpersTypes } from "src/Widgets/common/helpers.types";
import { getWeekdays } from "src/Widgets/common/helpers";
import { WeekdaysProps } from "./Weekdays.props";



function Weekdays({className, onClick, selected, isDisabled}: WeekdaysProps.WeekdaysProps) {
    return (
        <div className={[className].join(' ')}>
            {getWeekdays().map((day:HelpersTypes.Weekday, index:number) => {
              return (
                <Button
                  disabled={isDisabled}
                  type={selected.has(index) ? 'secondary' : "cancel"}
                  key={index}
                  // checked={task.until !== undefined ? true : false}
                  onClick={()=>onClick(index)}
                >
                  {day.text}
                </Button>
              )
            })}
        </div>
    )
}

export default Weekdays;