import React from 'react';

// returns HOC that delays unmounting of base component to set delay time
// useful for out-animations

// uses isMounted as parameter for rendering
// if isMounted === false, than the component doesn't get rendered after delay

function delayUnmounting(Component) {
    return class extends React.Component {
        state = {
            shouldRender: this.props.isMounted
        };

        componentDidUpdate(prevProps) {
            if (prevProps.isMounted && !this.props.isMounted) {
                setTimeout(
                    () => this.setState({ shouldRender: false }),
                    this.props.delayTime
                );
            } else if (!prevProps.isMounted && this.props.isMounted) {
                this.setState({ shouldRender: true });
            }
        }

        render() {
            return this.state.shouldRender ? (
                <Component {...this.props} />
            ) : null;
        }
    };
}

export default delayUnmounting;
