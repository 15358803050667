import { SHOW, SET_STEP, SET_STEPS, SET_WORKSPACES } from '../types/actionTypes.tour';

export const setStep = (stepNumber = 0) =>{
    return ({
        type: SET_STEP
   , data: stepNumber
  })};

export const showTour = (show = false) =>{
  return ({
      type: SHOW
      ,data: show
  })
};

export const setSteps = (steps) => {
  return ({
    type: SET_STEPS,
    data: steps
  })
};

export const setTourWorkspaces = (workspaces) => {
  return ({
    type: SET_WORKSPACES,
    data: workspaces
  })
};