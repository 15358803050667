import React from 'react';
import styles from './Link.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class Link extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        console.log(this.props.theme.primary)
        return(
            <span
                onClick={this.props.onClick}
                className={[styles.link,this.props.theme.textSecondaryHover].join(' ')}
                style={{
                    textDecorationColor: this.props.theme.secondary
                }}
            >
                {this.props.children}
            </span>
        );
    }
}

const mapStateToProps = state => ({
    theme: state.theme,
});
export default connect(mapStateToProps,{ /* insert redux actions here */ })(withTranslation()(Link));