import {
    CHANGE_ROUTE
} from '../types/actionTypes.route';

const routeReducers = (state = null, action) => {
    switch (action.type) {
        case CHANGE_ROUTE:
            return action.data;
        default: return state;
    }
}

export default routeReducers;