import classNames from 'classnames';
import styles from './Button.module.scss';
import { ButtonProps } from './Button.types';
import { useAppSelector } from 'src/redux/hooks';

const Button = ({
    children,
    type,
    btnClass,
    btnStyle,
    onClick,
    btnType,
    ...props
}: ButtonProps) => {
    const theme = useAppSelector((state) => state.theme);

    const buttonStyles: Record<string, string> = {
        primary: classNames(styles.btn, theme.backgroundPrimary),
        secondary: classNames(styles.btn, theme.backgroundSecondary),
        success: classNames(styles.btn, theme.backgroundSuccess),
        danger: classNames(styles.btn, theme.backgroundDanger),
        addWorkspace: classNames(
            styles.btn,
            styles.addWorkspace,
            theme.backgroundSecondary
        ),
        editBoard: classNames(styles.btn, styles.editBoard),
        saveBoard: classNames(styles.btn, theme.backgroundSecondary, styles.lockBoard),
        cancelBoard: classNames(styles.btn, theme.backgroundPrimary, styles.lockBoard),
        cancel: classNames(styles.btn, styles.cancel),
        widgetSelection: classNames(styles.btn, styles.widgetSelection),
        downloadButton: classNames(styles.btn, theme.backgroundPrimary, styles.download),
        white: [styles.btn, styles.white].join(' ')
    };

    const styling = buttonStyles[type] || classNames(styles.btn);

    return (
        <button
            className={classNames(styling, btnClass)}
            style={btnStyle}
            onClick={onClick}
            type={btnType}
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;
