import GenericCalls from './genericCalls';
import _ from 'lodash';
import config from 'src/config/config';
// import tmp from 'src/API/tmp-json.js';

class APISearch {
    static async getLegacy(parameters, channels, hideErrors, abortSignal) {
        let mappedParams = [
            {action: 'generate'}
            ,{outputType: 'JSON'}
            ,{words: parameters.query}
            ,{from: parameters.dateFrom}
            ,{to: parameters.dateTo}
            ,{rankBy: parameters.rankBy === 'relevance' ? 'BM25' : parameters.rankBy}
            ,{last: parameters.last}
            ,{
              cgid:
                    parameters.channelGroupId &&
                        (!parameters.channelIds || parameters.channelIds.length === 0)
                            ? parameters.channelGroupId
                            : undefined
            }
            ,{channel: undefined}
            ,{pageNo: parameters.pageNo}
            ,{ profileid: parameters.profileId }
            ,{language: parameters.language}
        ];
        if (parameters.channelIds && parameters.channelIds.length > 0) {
            mappedParams.push({ chn: parameters.channelIds });
        }
        let params = [];
        mappedParams.forEach(
            (p)=>(p[Object.keys(p)[0]] !== undefined ? params.push(p) : undefined)
        );
        
        let call = '/cgi-bin/feed/query';

        let res = await GenericCalls.get(call, params, {abortSignal}, hideErrors);
        // res = tmp;
        // res.facets = res.facets.map()    // TODO:
        // const c = _.find(channels,{text:"CTV"});
        if(res && res.results) {
            res.results = res.results.map((r)=>{
                // const c = _.find(channels,{text:r.channel});
                return {
                    thumbnail: r.kfLink
                    ,texts: [{text:r.desc, ref: r.viewLink}]
                    // ,channelIcon: c ? c.icon : undefined
                    ,title: r.title
                    , timestamp: r.beginTS
                    , channelId: r.channelid
                }
            });
            res.facets.byChannel = res.facets.chnid.map((f) => {
                // const c = _.find(channels, { id: f[0] });
                // if(c){
                    return {
                        id: f[0]
                        ,channel:{title: f[2],}
                        ,resultsCount: f[1]
                    }
                // } else {
                //     APITelemetry.post({
                //         msg: `got search facet result for channel '${f[0]}' but can not find it in GET /api/channels response`
                //     });
                // }
            });
            res.facets.byDate = res.facets.bt.map((f)=>{
                return {
                    date: f[0]
                    ,resultsCount: f[1]
                }
            });
        }
        return res;
    }

    static async get(parameters) {        
        let call = '/api/search';        
        if (parameters !== undefined) {
            let params = '';
            _.map(parameters, (val, key) => {
                params += '&' + key + '=' + val;
            });
            params = params.substr(1);
            call += '?' + params;
        }

        let res = await GenericCalls.get(call);
        res.data.results = res.data.results.map((r)=>{ r.texts = r.hits; delete r.hits; return r; })
        return res;
    }
}

export default APISearch;
