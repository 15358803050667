import { GET_PRODUCTS, GET_SUBSCRIPTIONS, SET_CUSTOMER_ID } from '../types/actionTypes.stripe';
// import _ from 'lodash';

const stripeReducers = (state = {
    data: {
        customerId: undefined,
        subscriptions: undefined,
    }
}, action) => {
    // let update;
    switch (action.type) {
        case SET_CUSTOMER_ID:
            return { 
                ...state, 
                data: {
                    ...state.data, 
                    customerId: action.data
                }
            };
        case GET_SUBSCRIPTIONS:
            return { 
                ...state, 
                data: {
                    ...state.data, 
                    subscriptions: action.data
                }
            };
            case GET_PRODUCTS:
                return { 
                    ...state, 
                    data: {
                        ...state.data, 
                        products: action.data
                    }
                };
        default:
            return state;
    }
};

export default stripeReducers;