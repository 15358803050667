import { INIT_GOOGLE_API, SET_IS_AUTHENTICATED, SET_SESSION_EXPIRED, SET_IS_WRONG_USER } from '../types/actionTypes.auth';
import config from 'src/config/config.js';
import APITelemetry from 'src/API/APITelemetry';
import { clearError, setError } from 'src/redux/actions/actions.error.js';


// export const setGoogleApiInstance = (id) => {
//     return (dispatch) => {
//         dispatch({
//             type: SET_GOOGLE_API_INSTANCE,
//             data: id
//         });
//     }
// };

export const setSessionExpired = (isExpired) => {
    return (dispatch) => {
        dispatch({
            type: SET_SESSION_EXPIRED,
            data: isExpired,
        });
    }
}

export const setIsAuthenticated = ({isAuthenticated, redirectTo, isManualLogout}) => {
    return (dispatch) => {
        dispatch({
            type: SET_IS_AUTHENTICATED,
            data: {isAuthenticated, redirectTo, isManualLogout},
        });
    }
}

export const setIsWrongUser = (isWrongUser) => {
    return (dispatch) => {
        dispatch({
            type: SET_IS_WRONG_USER,
            data: isWrongUser,
        });
    }
}

// export const initGoogleApi = (callback) => {
//     let api;
//     let store = window.rxStore;
//     let auth = store.getState().auth;
//     console.log(auth)
//     if (!auth.googleApi_initialized) {
//         return (dispatch) => {
//             const script = document.createElement('script');
//             script.src = "https://apis.google.com/js/platform.js";
//             script.async = true;
//             script.defer = true;

//             document.body.appendChild(script);
//             clearError();
//             console.log('aaa')
//             script.onerror = (e)=>{
//                 // NOTE: we probably want to show info to user instead/in addition to setError()
//                 // and maybe get behaviour reusable for other ext. scripts ?
//                 setError( { status: 'Failed', url: e.srcElement.src, method: 'GET' } );
//                 APITelemetry.postConsoleError({msg:`failed loading script: '${e.srcElement.src}'`});
//             }
//             script.onload = () => { 
//                 window.gapi.load('auth2', async () => {
//                     api = await window.gapi.auth2.init({ client_id: config.google_client_id });
//                     // todo add redux action call
//                     // console.log(this.auth2)
//                     // this.props.setGoogleApiInstance(this.auth2);
//                     // this.setState({ isInit: true });
//                     dispatch({
//                         type: INIT_GOOGLE_API,
//                         data: api,
//                     });
//                     if (callback) { callback(); }
//                 });
//             }
//         }
//     }
// };

// export const loginGoogleApi = () => {
//     const googleUser = await this.auth2.signIn(
//         { prompt: 'select_account' }
//     );
//     const authResponse = googleUser.getAuthResponse();

//     this.onLoginSuccess(authResponse.id_token, authResponse.expires_at);
// }

// export const validateAuth = ( event,
//     from,
//     auth = {},   // { google: {id_token: 'Bearer ...'} }) => {
//     username,
//     password
// ) => {
//     let authString;
//     let basicEncoded = btoa(username + ':' + password);
//     if (event) { event.preventDefault(); }

//     if (auth.google) {
//         authString = 'Bearer ' + auth.google.id_token;
//     } else {
//         authString = 'Basic ' + basicEncoded;
//     }
    
//     let authenticated;
//     authenticated = await APIAuth.validate(authString);

//     if (authenticated === true) {
//         if (auth.google) {
//             config.STORAGE.setItem('googleAuth', auth.google.id_token);
//             config.STORAGE.setItem('auth_expires_at', auth.google.expires_at);
//         } else {
//             config.STORAGE.setItem('credentials', basicEncoded);
//         }
//         this.props.authenticate(from);
//     } else if(authenticated.status){
//         this.setState({serverIssue: true})
//     } else this.setState({ loginFailed: true });
// }

// export const isTokenExpired = () => {
//     const expires = localStorage.getItem('google_auth_token_expires_at');
//     const expiresInMinutes = (expires - Date.now())/60/1000;
//     return expiresInMinutes < 5 ? true : false;
// }

// export const ensureTokenNotExpired = () => {
//     this.props.auth.google.api.reloadAuthResponse();
// }

// function init(callback) {
//     if(!this.state.isInit){

//     } else {
//         callback();
//     }
// }