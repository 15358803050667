import React, { Component } from 'react';
import './AddButton.css';
import _ from 'lodash';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from '../../basicElements/Button/Button';
import { withTranslation } from 'react-i18next';

// fontawesome icons
library.add(faPlus);

/**
 * - addGroup(title)
 */
class AddButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showTextfield: false,
            title: ''
        };

        this.pressButton = this.pressButton.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidUpdate() {
        if (this.titleInput) {
            this.titleInput.focus();
        }
    }

    componentWillUnmount() {
        if (this.delayedBlur) clearTimeout(this.delayedBlur);
    }

    onTitleChange = e => this.setState({ title: e.target.value });

    onKeyDown(e) {
        if (e.key === 'Enter') {
            this.addGroup();
        } else if (e.key === 'Escape') {
            this.setState({
                showTextfield: false,
                title: ''
            });
        }
    }

    pressButton() {
        if (!this.state.showTextfield) {
            this.setState({ showTextfield: true });
        } else {
            this.addGroup();
        }
    }

    addGroup() {
        if(this.state.title.length === 0) {
            return;
        }

        this.props.addGroup(this.state.title);

        this.setState({
            showTextfield: false,
            title: ''
        });
    }

    render() {
        const input = this.state.showTextfield ? (
            <input
                type="text"
                className="add-input"
                style={this.props.customInputStyle}
                placeholder={this.props.t("Group Title")}
                value={this.state.title}
                onChange={this.onTitleChange}
                onKeyDown={this.onKeyDown}
                onBlur={() =>
                    (this.delayedBlur = _.delay(
                        () => this.onKeyDown({ key: 'Escape' }),
                        150
                    ))
                }
                ref={input => {
                    this.titleInput = input;
                }}
            />
        ) : null;

        return (
            <div className="groupcards-add-wrapper">
                {input}

                <Button
                    type='secondary'
                    btnClass='btnRound'
                    onClick={this.pressButton}
                    btnStyle={this.props.customButtonStyle}
                    aria-label={this.props.buttonProps?.ariaLabel}
                >
                    <FontAwesomeIcon icon="plus" />
                </Button>
            </div>
        );
    }
}

export default withTranslation()(AddButton);