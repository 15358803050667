import React from 'react';
import styles from './SearchResults.module.scss';
import RecordingCard from '../../common/RecordingCards/RecordingCard/RecordingCard';
// import InfiniteScroll from '../../common/basicElements/InfiniteScroll/InfiniteScroll';
import _ from 'lodash';
import QueryVisualization from '../../common/QueryVisualization/QueryVisualization';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/basicElements/LoadingSpinner/LoadingSpinner';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import IFrameWrapper from '../../common/basicElements/IFrameWrapper/IFrameWrapper';
import { postEpgHistoryItem, deleteEpgHistoryItem } from '../../../redux/actions/actions.epgHistory';
import { connect } from 'react-redux';
import InfiniteScroll from 'src/Widgets/common/basicElements/InfiniteScroll/InfiniteScroll';

class SearchResults extends React.Component {
    static propTypes = {
        queryVisualization: PropTypes.object, // object to visualize query
        cards: PropTypes.array.isRequired,   // array of search results
        showTree: PropTypes.bool,
        isLoading: PropTypes.bool.isRequired,    // tells comp. if data is loading or already loaded
        querySearched: PropTypes.bool.isRequired,
        resultsDisabled: PropTypes.bool,     // used e.g. when query visualization is in foreground to change opacity accordingly
        msg: PropTypes.string,  // message which will be shown instead of results (e.g. when the used query syntax was invalid)
    }
    constructor(props) {
        super(props);
        this.state = {
            iFrameUrl: undefined
        };

        this.changeIFrameUrl = this.changeIFrameUrl.bind(this);
    }

/*     async componentDidMount() {
        
    }

    async search() {
        
    } */

    changeIFrameUrl(url, recordingCard) {
        if(recordingCard){
            this.props.postEpgHistoryItem({
                channelId: recordingCard.channelId,
                channel: recordingCard.channel,
                timestamp: recordingCard.timestamp*1000
            });
        }
        this.setState({iFrameUrl: url});
    }

    renderResultsPlaceholder(){
        if(this.props.msg){
            return <div className={styles.resultsText}>{this.props.msg}</div>
        }
        const {t} = this.props;
        if(this.props.cards.length === 0){
            if(this.props.querySearched){
                return <div className={styles.resultsText}>{t('No results for this query')}.</div>
            } else {
                return <div className={styles.resultsText}>{t('Type your query to start a search')}.</div>
            }
        }
        return null;
    }

    render () {
        const { isLoading, t } = this.props;

        if (isLoading && this.props.cards.length === 0) {
            return <LoadingSpinner />;
        } else if (this.state.iFrameUrl) {
            return (
                <IFrameWrapper
                    url={this.state.iFrameUrl}
                    onGoBack={() => this.changeIFrameUrl(null)}
                    title="Media View"
                />
            );
        } else {
            // const vis = "<div class='and'>\n<div class='word'> bmw </div>  <br/> <span class='opAND'> AND </span><br/>\n <div class='word'> audi </div></div>\n";
            let cards = this.props.cards;
            let resultsStyles = [styles.results];
            if (this.props.resultsDisabled) {
                resultsStyles.push(styles.disabled);
            }
            return (
                <div className={styles.resultsWrapper}>
                    {cards.length > 0 ? <div className={styles.numbers}>{`${this.props.t('Search Results')} (1-${cards.length} ${this.props.t('of')} ${this.props.totalFound})`}</div> : null}
                    {this.props.showTree ?
                        <div className={styles.queryVisWrapper}>
                            <QueryVisualization
                                data={this.props.queryVisualization}
                            />
                        </div>
                    : null}
                    {this.renderResultsPlaceholder()}
                    <div className={classNames(resultsStyles)}>
                        <InfiniteScroll 
                            className={[styles.wrapper,].join(' ')}
                            hasMore={this.props.hasMore}
                            loadMore={()=>{this.props.search();}}
                            loadMoreOffset={15}
                            itemName={t('search results')}
                        >
                            {_.map(cards, (c, idx) => (
                                <RecordingCard
                                    key={idx}
                                    content={c}
                                    changeIFrameUrl={(url) => { this.changeIFrameUrl(url, c); }}
                                    disabled={this.props.resultsDisabled}
                                    htmlText={true}
                                />
                            ))}
                        </InfiniteScroll>
                    </div>
                </div>
            )
        }
    }
}

export default connect(null, { postEpgHistoryItem, deleteEpgHistoryItem })(withTranslation()(SearchResults));