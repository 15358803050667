// @ts-ignore
import { withRouter } from 'react-router-dom';
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import EmmBanner from "src/Login/EmmBanner/EmmBanner";
import Button from "src/Widgets/common/basicElements/Button/Button";
import Link from "src/Widgets/common/basicElements/Link/Link";
import TextInput from "src/Widgets/common/basicElements/TextInput/TextInput";
import { useDispatch, useSelector } from 'react-redux';
import { setSignupProps } from 'src/redux/actions/actions.signupEmail';
import APIAuth from 'src/API/APIAuth';

// TODO: check email valid

function EmailSignup({history}:any) {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [resendCountDown, setResendCountdown] = useState<number|null>(null);
    const [allowResend, setAllowResend] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const emailInputRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();
    // @ts-ignore
    const signupEmail = useSelector((state) => state.signupEmail);

    const sendVerifyEmail = () => {
        dispatch(setSignupProps({ ...signupEmail, email }));
        APIAuth.sendEmailVerifyLink(email);
        setEmailSent(true);
        setAllowResend(false);
        setResendCountdown(120);
        // history.push('sign-up-details');
        

        const interval = setInterval(() => {
            setResendCountdown((prev:number|null) => {
              if (prev === null) return prev;
              if (prev <= 1) {
                clearInterval(interval);
                setAllowResend(true);
                return null;
              }
              return prev - 1;
            });
        }, 1000);
    }

    return (
        <EmmBanner>
            <div style={{flexDirection:'column'}}>
                
                {emailSent
                    ? <div>
                        <h1>{t('check_email_inbox')}</h1>
                        <p>{t('check_email_inbox_text')}</p>
                        <Button disabled={!allowResend} type="primary" onClick={()=>sendVerifyEmail()}>{ allowResend ? 'Resend E-mail' : `Resend E-mail (${resendCountDown})` }</Button>
                        </div>
                    : <div>
                        <h1>Sign up</h1>
                    <p>
                        {/* @ts-ignore */}
                        {t('If you already have an account click')} <Link onClick={() => { history.push('/login') }}>here</Link>
                    </p>
                        <TextInput
                            inputRef={emailInputRef}
                            value={email}
                            inputType='email'
                            onChange={(e) => {
                                // const isValid = emailInputRef.current?.checkValidity();
                                // if(isValid){}
                                setEmail(e.target.value)
                            }}
                        onKeyPress={e => e.key === 'Enter' && sendVerifyEmail()}
                        showPlaceholder={false}
                        label={t('Email')}
                    />
                        <Button
                            onClick={() => {
                            sendVerifyEmail();
                            }} type="secondary">{t('Continue')}</Button>
                </div>
                }
            </div>
        </EmmBanner>
    )
}

export default withRouter(EmailSignup);