import { useTranslation } from "react-i18next";
//@ts-ignore
import { useHistory } from "react-router-dom";
import Button from "src/Widgets/common/basicElements/Button/Button";

interface ResetPwSuccessProps{
    userId: string
}

const ResetPwSuccess = ({userId}:ResetPwSuccessProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    return <div>
        <h1>{t('pw_changed_headline')}</h1>
        {t('pw_changed_success')}
        <p>
            <Button onClick={()=>{ history.push(`/auth/${userId}`) }} type="secondary">{t('Log In')}</Button>
        </p>
    </div>
}

export default ResetPwSuccess;