import { useTranslation } from "react-i18next";

interface useChangePwInputsProps{
    setPwIssue:(issue:string)=>void,
    setInputsChanged:(inputsChanged:boolean)=>void,
    onSave:(password: string)=>void,
}

const useChangePwInputs = ({ setPwIssue, setInputsChanged, onSave }: useChangePwInputsProps) => {
    const { t } = useTranslation();

    const pwIsValid = (pw:string):boolean => {
        try {
            setPwIssue('');
            btoa(pw);
            return true;
        } catch (e: any) {
            if (e instanceof DOMException && e.name === 'InvalidCharacterError') {
                return false;
            } else {
                throw e;
            }
        }
    }

    const checkPw = (pw1: string | undefined, pw2: string | undefined) => {
        const isValid = !pw1 || pwIsValid(pw1);
        if (!isValid) {
            setPwIssue(t('password_invalid_character'));
        } else if(pw1 !== pw2) { 
            setPwIssue(t('passwords_not_equal'));
        } else {
            setPwIssue('');
        }
    }

    const onPwChange = (pw1: string | undefined, pw2: string | undefined) => {
        setInputsChanged(true);
        checkPw(pw1,pw2)
    }

    const onSaveBtnClick = (password:string|undefined) => {
        if (password) {
            setInputsChanged(false);
            onSave(password);
        }
    }

    return {
        onPwChange, onSaveBtnClick, pwIsValid, checkPw,
    }
}
export default useChangePwInputs;