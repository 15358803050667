import { useTranslation } from "react-i18next";
import BackButton from "src/Widgets/common/basicElements/BackButton/BackButton";
import EmmBanner from "../EmmBanner/EmmBanner";

function UserMessage({
    title,
    text,
    onGoBack,
    hideBanner
}) {

    const renderWithBanner = (content) => {
        return (
            <EmmBanner>
                <div>
                    {content}
                </div>
            </EmmBanner>
        )
    }

    const renderContent = () => (
        <div style={{marginBottom:'3.5rem'}}>
            <h2>{title}</h2>
            <p>{text}</p>
            {onGoBack &&
                <BackButton
                    onClick={onGoBack}
                />
            }
        </div>
    )
    
    return (
        hideBanner
            ? renderContent
            : renderWithBanner(renderContent())
    )
}

export default UserMessage;