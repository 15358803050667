import { useSelector } from 'react-redux';
import styles from './SubscriptionsManagement.module.scss';
import Link from 'src/Widgets/common/basicElements/Link/Link';
import BorderBox from 'src/Widgets/common/basicElements/BorderBox/BorderBox';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { useEffect, useState } from 'react';
import APIStripe from 'src/API/APIStripe';
import { ROUTES } from 'src/consts';
// @ts-ignore
import {useHistory} from 'react-router-dom'
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';

function SubscriptionsManagement() {
    // @ts-ignore
    const theme = useSelector((state) => state.theme);
    // @ts-ignore
    const {customerId } = useSelector((state) => state.stripe.data);
    const history = useHistory();
    // const [products,setProducts] = useState();
    // const me = useSelector((state) => state.me);

    useEffect(() => {
        if(customerId !== undefined){
            onCreatePortal();
        }
        // (async () => {
        //     const products = await APIStripe.getProducts();
        //     // setProducts(products)
        //     console.log(products)
        // })()
    },[customerId])

    const onChangePlan = () => {
        history.push(ROUTES.mef_pricing);
    }

    const onCreatePortal = async() => {
        const session = await APIStripe.createPortalSession(customerId);
        if (session.url) {
            window.location.href = session.url;
            // window.open(session.url, '_blank')
        }
    }

    return (<LoadingSpinner/>)

    return (
        <div className={styles.wrapper}>
            <h2 className={theme.textPrimary}>Subscription Management</h2>

            <h3 className={theme.textPrimary}>Current Plan</h3>
            <BorderBox>
                <div className={styles.center}><Button onClick={()=>onChangePlan()} type="secondary">Upgrade</Button></div>
            </BorderBox>
            <h3 className={theme.textPrimary}>Payment</h3>
            <BorderBox>
                <table className={styles.payment}>
                    <tr>
                        <td className={styles.label}>Last Payment</td>
                        <td>XX-XX-XXXX</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className={styles.label}>Next Payment</td>
                        <td>XX-XX-XXXX</td>
                        {/* @ts-ignore */}
                        <td><Link>Payment method</Link></td>
                    </tr>
                </table>
            </BorderBox>
            <div>
                {/* @ts-ignore */}
                <h3>Manage subscription</h3> <Link onClick={()=>{onCancelSubscription()}}>manage</Link>
            </div>
        </div>
    )
}

export default SubscriptionsManagement;