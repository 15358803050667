import React, { Component, createRef } from 'react';
import styles from './AutoComplete.module.scss';
import _ from 'lodash';
import TextInput from './TextInput';
import classNames from 'classnames';
import APIAutoComplete from '../../../../API/APIAutoComplete';
import { connect } from 'react-redux';
import { getLastQueryWord } from '../../helpers';
import APIQueryVisualization from 'src/API/APIQueryVisualization';

/**
 * props extending TextInput props, aswell as:
 *  - className affects the autocomplete div,
 *      while inputClassName the TextInput affects
 *  - onSelection
 *  - hideSuggestions: used to prevent showing results if user finishs input before suggestions were rendered (e.g. because waiting for server response)
 *      e.g. in search widget. future TODO: would be even better to abort the call instead (js AbortController) ?
 *  - invalid: boolean, true if the current input content is not valid
 */
class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: null,
            suggestionsAmount: 0,
            activeSuggestion: -1,
            onChangeActive: true,
            isFocused: false,
            wasEmptyOnFocus: false, // indicates if value was empty when text field got focus
            fromSelection: false
        };

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onSuggestionsMouseLeave = this.onSuggestionsMouseLeave.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.checkQuerySyntax = this.checkQuerySyntax.bind(this);

        this.inputRef = createRef();
    }

    componentDidMount() {
        if(this.props.inputRef){
            if (typeof this.props.inputRef === 'function') {
                this.props.inputRef(this);
            } else {
                this.props.inputRef.current = this;
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value
            && this.props.value
            && prevState.fromSelection === this.state.fromSelection) {
            this.debouncedFetchSuggestions();
            this.debouncedCheckQuerySyntax();
        }
    }

    calcSuggestionsAmount(suggestions) {
        let counter = 0;
        _.forEach(suggestions, s => _.forEach(s.options, o => counter++));

        return counter;
    }

    getSuggestion(index, suggestions) {
        let counter = -1;
        let out = '';

        _.forEach(suggestions, s =>
            _.forEach(s.options, o => {
                counter++;
                if (index === counter) {
                    out = o;
                    return;
                }
            })
        );

        return out;
    }
    
    async checkQuerySyntax() {
        let syntaxInvalid = false;
        if (this.props.checkQuerySyntax && this.props.value.length >= 3) {
            syntaxInvalid = (await APIQueryVisualization.checkQuerySyntax(this.props.value, [90013])).data;
            if (this.props.onSyntaxChecked) {
                this.props.onSyntaxChecked(syntaxInvalid, this.state.wasEmptyOnFocus, this.state.isFocused);
            }
            if (syntaxInvalid && syntaxInvalid.rc === 90013) {
                syntaxInvalid = true;
            } else {
                syntaxInvalid = false;
            }
            this.setState({ syntaxInvalid });
        }
    }

    removeSuggestions() {
        this.setState({
            suggestions: null,
            activeSuggestion: -1
        });
    }

    async fetchSuggestions() {
        if (!this.state.isFocused) {
            this.removeSuggestions();
            return;
        }

        if (this.props.value.length >= 3) {
            let stateUpdate = {};
            const word = getLastQueryWord(this.props.value);

            if (word && word.length >= 3) {
                let suggestions = await APIAutoComplete.get(word);
                if (!this.state.isFocused) {
                    this.removeSuggestions();
                    return;
                }
            
                if (suggestions && !suggestions.status) {  // continue if getting no error from backend
                    suggestions = suggestions.data;
                    if (suggestions.length === 0) suggestions = undefined;
                    stateUpdate.suggestions = suggestions;
                    stateUpdate.suggestionsAmount = this.calcSuggestionsAmount(suggestions);
                }
            } else {
                stateUpdate.suggestions = null;
            }

            this.setState(stateUpdate);
        } else {
                this.setState({
                suggestions: null,
                activeSuggestion: -1,
            });
        };
    }

    debouncedFetchSuggestions = _.debounce(this.fetchSuggestions, 250, {
        // maxWait: 500
    });

    debouncedCheckQuerySyntax = _.debounce(this.checkQuerySyntax, 250);

    onKeyDown(e) {
        switch (e.key) {
            case 'Escape':
                if(this.state.suggestions === null)
                    this.blur();
                this.setState({ suggestions: null });
                break;

            case 'ArrowUp':
                if (!this.state.suggestions) break;

                e.preventDefault();

                this.setState(s => {
                    const activeSuggestion = Math.max(0, s.activeSuggestion - 1);

                    if (this.refs[activeSuggestion])
                        this.refs[activeSuggestion].scrollIntoView({
                            block: 'nearest'
                        });

                    return {
                        activeSuggestion
                    };
                });
                break;

            case 'ArrowDown':
                if (!this.state.suggestions) break;

                e.preventDefault();

                this.setState(s => {
                    const activeSuggestion = Math.min(
                        this.state.suggestionsAmount - 1, // end of list
                        s.activeSuggestion + 1
                    );

                    if (this.refs[activeSuggestion])
                        this.refs[activeSuggestion].scrollIntoView({
                            block: 'nearest'
                        });

                    return {
                        activeSuggestion
                    };
                });
                break;

            case 'Enter':
                if (this.state.suggestions) {
                    if(this.state.activeSuggestion > -1){   // if any suggestion is selected
                        e.preventDefault(); // prevent triggering
                    }
                }
                if (!this.state.suggestions || this.state.activeSuggestion <= -1) {
                    this.setState({ onChangeActive: true });
                    break;
                }

                this.props.onSelection(
                    this.getSuggestion(
                        this.state.activeSuggestion,
                        this.state.suggestions
                    )
                    , getLastQueryWord(this.props.value)
                );
                this.setState(s => ({
                    suggestions: null,
                    activeSuggestion: -1,
                    onChangeActive: false,
                    fromSelection: !s.fromSelection
                }));
                break;

            default:
                this.setState({ onChangeActive: true });
        }
    }

    onMouseOver(id) {
        console.log(id);
        this.setState({ activeSuggestion: id });
    }

    onBlur() {
        if(this.props.onBlur){ this.props.onBlur(); }
        this.blurTimeout = setTimeout(() => {
            this.setState({
                suggestions: null,
                activeSuggestion: -1,
                isFocused: false
            });
        }, 200);
    }

    onFocus() {
        clearTimeout(this.blurTimeout);
        let wasEmptyOnFocus = this.props.value === undefined || this.props.value === null || this.props.value === '' ? true : false;
        if(this.props.onFocus){ this.props.onFocus(wasEmptyOnFocus); }

        this.setState({
            isFocused: true,
            wasEmptyOnFocus,
        });
    }

    onSelection(item) {
        if (this.inputRef.current)
            this.inputRef.current.focus();

        this.props.onSelection(item, getLastQueryWord(this.props.value));

        this.setState(s => ({
            suggestions: null,
            activeSuggestion: -1,
            onChangeActive: false,
            fromSelection: !s.fromSelection
        }));
    }

    focus() {
        if (this.inputRef.current)
            this.inputRef.current.focus();
    }

    blur() {
        if (this.inputRef.current)
            this.inputRef.current.blur();
    }

    render() {
        const {
            label,
            value,
            onChange,
            onKeyPress,
            inputClassName,
            isArea,
            isRequired,
            disabled,
            placeholder,
            onFocus,
            onBlur
        } = this.props;

        return (
            <div
                className={classNames(styles.wrapper, this.props.className)}
                onKeyDown={this.onKeyDown}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
            >
                <TextInput
                    inputRef={this.inputRef}
                    label={label}
                    value={value}
                    onChange={this.state.onChangeActive ? onChange : ()=>{}}
                    onKeyPress={onKeyPress}
                    className={inputClassName}
                    isArea={isArea}
                    isRequired={isRequired}
                    disabled={disabled}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    invalid={this.props.invalid !== undefined ? this.props.invalid: this.state.syntaxInvalid}
                    labelAppend={this.props.labelAppend}
                    labelDisabled={this.props.labelDisabled}
                />

                {!this.props.hideSuggestions && this.renderDropdown(this.state.suggestions)}
            </div>
        );
    }

    getStyledItem(item) {
        const currentWord = getLastQueryWord(this.props.value);

        if (!currentWord) return item;

        let highlightStart = item.search(
            new RegExp(currentWord.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'gi')
        );

        if (highlightStart === -1) return item;

        // highlightStart += currentWord.length;
        const highlightEnd = highlightStart + currentWord.length;

        const firstPart = item.substring(0, highlightStart);
        const secondPart = item.substring(highlightStart, highlightEnd);
        const thirdPart = item.substring(highlightEnd);

        return (
            <>
                <span style={{ opacity: 0.8 }}>{firstPart}</span>
                <span style={{ fontWeight: 'bold' }}>{secondPart}</span>
                <span style={{ opacity: 0.8 }}>{thirdPart}</span>
            </>
        );
    }

    onSuggestionsMouseLeave(){  // reset selection when leaving selection-div with mouse
        this.setState({ activeSuggestion: -1 });
    }

    renderDropdown(suggestions) {
        if (!suggestions) return null;

        let counter = -1;

        return (
            <div className={styles.dropdown} onMouseLeave={this.onSuggestionsMouseLeave}>
                {_.map(suggestions, (s, idx) => (
                    <React.Fragment key={idx}>
                        <span
                            className={classNames(
                                styles.dropdownHeader,
                                this.props.theme.textPrimary
                            )}
                        >
                            {s.category}
                        </span>

                        {_.map(s.options, (item, itemIdx) => {
                            counter++;

                            // against anomalies
                            // without const, function-building takes the last ever value,
                            // not the current one
                            const ctr = counter;

                            return (
                                <span
                                    key={itemIdx}
                                    ref={counter}
                                    className={classNames(styles.dropdownItem, {
                                        [styles.active]:
                                            this.state.activeSuggestion === ctr
                                    })}
                                    onClick={() => this.onSelection(item)}
                                    onMouseOver={() => this.onMouseOver(ctr)}
                                >
                                    {this.getStyledItem(item)}
                                </span>
                            );
                        })}
                    </React.Fragment>
                ))}
            </div>
        );
    }
}

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(AutoComplete);
