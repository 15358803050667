import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../common/basicElements/Button/Button";
import TextInput from "../common/basicElements/TextInput/TextInput";
import styles from './QuizContainerSettings.module.scss';

function QuizContainerSettings({
    init,
    onSave
}){
    const [url, setUrl] = useState(undefined);

    const { t } = useTranslation();

    useEffect(()=>{
        setUrl(init.url);
    },[init.url]);

    return (
        <div className={styles.wrapper}>
            {t('Url')}
            <TextInput
                value={url}
                placeholder="Url to json. e.g.: /v3/academy_api/en/courses/introduction/quiz.json"
                onChange={(e)=>{setUrl(e.target.value)}}
            />
            <Button
                type="success"
                onClick={() => { onSave({url}) }}
            >
                {t('Save')}
            </Button>
            {/* <Button type="primary">Close</Button> */}
        </div>
    )
}

export default QuizContainerSettings;