import { useState, useCallback } from 'react';
import { ChannelGroup } from '../types/types';

const useSelectedChannelGroup = () => {
  const [selectedGroup, setSelectedGroup] = useState<ChannelGroup>({} as ChannelGroup);

  const selectGroupHandler = useCallback((groupData: ChannelGroup) => {
    setSelectedGroup(groupData);
  }, []);

  const getSelectedGroup = (): ChannelGroup => {
    return selectedGroup;
  };

  return { selectedGroup, selectGroupHandler, getSelectedGroup};
};

export default useSelectedChannelGroup;
