import React from 'react';
// import styles from './LoadingIndicator.module.scss';
import Popup from 'src/Widgets/common/basicElements/Popup/Popup';
import ProgressBar from './ProgressBar/ProgressBar';


function LoadingIndicator({text, progress, blockContent}) {
    return(
        <Popup blockContent={blockContent}>
            {text}
            <ProgressBar progress={progress}/>
        </Popup>
    );
}

export default LoadingIndicator;