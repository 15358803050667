import React from 'react';
import './AllDone.scss';
import '../../../assets/animate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';

// FontAwesome Base
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons';

// fontawesome icons
library.add(faUmbrellaBeach);

const AllDone = props => (
    <div className={'all-done'}>
        <FontAwesomeIcon
            className={classNames(
                "all-done-icon",
                props.theme.textSecondary
            )}
            icon="umbrella-beach"
        />
        <span>{props.t('All done')}</span>
    </div>
);

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(withTranslation()(AllDone));
