import React, { FC, useEffect, useState } from 'react';
import styles from './TableContainer.module.scss';
import { getChannels } from 'src/redux/actions/actions.channels';
import Table from '../../shared/Table/Table';
import { filterChannels, showAllChannels } from 'src/redux/actions/actions.channelGroupsWidget';
import { useTranslation, TFunction } from 'react-i18next';
import TableHeading from '../../components/TableHeading/TableHeading';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { TableContainerProps } from './TableContainer.types';
import { Channel } from "src/@types/shared.types";
import { ChannelGroup } from '../../components/GroupCardList/GroupCardList.types';
import { ChannelLessData } from '../../shared/GroupCardSingleItem/GroupCardSingleItem.types';

const TableContainer:FC<TableContainerProps> = ({
  onSelectionChange, 
  setIsOpen, 
  channelsNotInGroup, 
  channelsWillRemovedInGroup, 
  setButtonType, 
  onHistoryRowClick, 
  historyChannels, 
  isChannelGroupsWidget
}) => {

    const dispatch = useAppDispatch();

    const channelGroupsWithChannels = useAppSelector(
        (state) => state.channelGroupReducers
    );
    const {
        selectedGroupTitle,
        selectedGroupId,
        allChannelsShown,
        searchedChannels,
        sortedChannels,
        filteredChannels,
        selectedSortType
    } = useAppSelector((state) => state.channelGroupsWidgetReducer);
    const allChannels = useAppSelector((state) => state.channelReducers);

    const [data, setData] = useState<any>([]);
    const [noAccessChannels, setNoAccessChannels] = useState<ChannelLessData[]>([]);

    const { t } = useTranslation();

    const tableHeaders = ["Name", "Type", "Country", "Owner", "Language", "Scope"]?.map((key) => key);
    
    const getChannelDetails = (channelId: number) => {
      let channelDetails = allChannels?.data.find((channel: Channel) => channel.id === channelId);
      if (!channelDetails) {
        channelDetails = noAccessChannels?.find((channel) => channel.id === channelId);
      }
      return channelDetails;
    };
    
    const compareChannelsResponseWithDetails = () => {
        const selectedGroup = channelGroupsWithChannels?.filter(
            (channelGroup: ChannelGroup) => {
                return channelGroup?.id === selectedGroupId;
            }
        );

        const channelGroupChannels = selectedGroup[0]?.channels?.map(
            (channel: Channel) => {
                let channelDetails, isNotAvailable;
                if (typeof channel?.id === 'number') {
                    channelDetails = getChannelDetails(channel?.id);
                    isNotAvailable = channel?.id < 0; // Checking if the channel id is negative
                }
                const isUnique =
                    !isNotAvailable &&
                    !!noAccessChannels?.find(
                        (uc) => uc.id === channel?.id
                    );

                return {
                    Name:channelDetails?.name || getInactiveChannelTitleById(channel?.id),
                    Type: channelDetails?.type || '',
                    Country: channelDetails?.country || '',
                    Market: channelDetails?.market || '',
                    Network: channelDetails?.network || '',
                    Owner: channelDetails?.owner || '',
                    Language: channelDetails?.language || '',
                    Scope: channelDetails?.scope || '',
                    Id: channel?.id,
                    isUnique,
                    isNotAvailable
                };
            }
        );

        dispatch(filterChannels(channelGroupChannels));
    };    

    const flattenChannels = (channelGroupsWithChannels: ChannelGroup[] | undefined) => {
        if (!channelGroupsWithChannels) {
            return [];
        }
        return [].concat(
            ...channelGroupsWithChannels.map((group) => group.channels || [])
        );
    };

    useEffect(() => {
      let flatChannels = flattenChannels(channelGroupsWithChannels);
      // Remove duplicates
      const channelsMap = new Map();
      flatChannels = flatChannels.filter((channel: Channel) => {
        const key = channel.id;
        if (!channelsMap.has(key)) {
          channelsMap.set(key, true);
          return true;
        }
      });
    
      // Get the channels that are not included in allChannels?.data but inside flatChannels
      const uniqueChannelsLocal = flatChannels?.filter((channel: Channel) => {
        return !allChannels?.data.some((dataChannel: Channel) => dataChannel.id === channel.id);
      }).map((channel: ChannelLessData) => ({ ...channel, isUnique: true })); 
      setNoAccessChannels(uniqueChannelsLocal);
    }, [allChannels, channelGroupsWithChannels]);
    
    
    useEffect(() => {
      dispatch(getChannels());
      dispatch(showAllChannels(true))
    }, [channelGroupsWithChannels]);
  
    useEffect(() => {
      compareChannelsResponseWithDetails();
    },[selectedGroupTitle, allChannels]);

    const getChannelValue = (channel: Channel, key: string) => {
      return channel?.[key] ?? channel?.[key.toLowerCase()] ?? '';
    };
    
    const getInactiveChannelTitleById = (id: number) => {
      const channel = noAccessChannels?.find((channel) => channel.id === id);
      return channel ? channel.title : null;
  }

    const mapChannelsToData = (channels: Channel[], t: TFunction<"translation">) => {
      return channels?.map((channel) => {
          const isNotAvailable = channel.Id < 0 || channel.id < 0;
          return {
              [t("Name")]: getChannelValue(channel, "Name") || getInactiveChannelTitleById(channel.Id),
              [t("Type")]: getChannelValue(channel, "Type"),
              [t("Country")]: getChannelValue(channel, "Country"),
              [t("Market")]: getChannelValue(channel, "Market"),
              [t("Network")]: getChannelValue(channel, "Network"),
              [t("Owner")]: getChannelValue(channel, "Owner"),
              [t("Language")]: getChannelValue(channel, "Language"),
              [t("Scope")]: getChannelValue(channel, "Scope"),
              Id: getChannelValue(channel, "Id"),
              isUnique: channel.isUnique,
              isNotAvailable,
          };
      });
    };

    useEffect(() => {
      let data;
      if (searchedChannels) {
        data = mapChannelsToData(searchedChannels, t);
      } else if (filteredChannels) {
        data = mapChannelsToData(filteredChannels, t);
      } else if (sortedChannels) {
        data = mapChannelsToData(sortedChannels, t);
      }
      setData(data);
    }, [searchedChannels, filteredChannels, sortedChannels, selectedSortType, selectedGroupId, allChannelsShown, noAccessChannels]);

    return (
        <div className={styles.tableOuterContainer}>
            {(
                <TableHeading
                    setIsOpen={setIsOpen}
                    setButtonType={setButtonType}
                    channelsNotInGroup={channelsNotInGroup}
                    channelsWillRemovedInGroup={channelsWillRemovedInGroup}
                    isChannelGroupsWidget={isChannelGroupsWidget}
                />
            )}
            <div className={styles.tableContainer}>
                <Table
                    data={data}
                    headers={tableHeaders}
                    isChannelGroupsWidget={isChannelGroupsWidget}
                    onSelectionChange={onSelectionChange}
                    historyChannels={historyChannels}
                    onHistoryRowClick={onHistoryRowClick}
                />
            </div>
        </div>
    );
};

export default TableContainer;
