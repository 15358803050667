import React from 'react';
import Initicon from 'react-initicon';
import Gravatar from 'react-gravatar';
import { stringToHash } from '../../helpers';
import styles from './Icon.module.scss';

/**
 * props:
 *  - icon: string to custom icon
 *  - title: string to be used for initicon seed
 *  - useGravatar: boolean
 *  - size: integer (in px)
 */
class Icon extends React.Component {
    constructor(props) {
        super(props);

        let icon;                
        let size = this.props.size ? parseInt(this.props.size) : 50;        

        if (this.props.icon) {
            icon = (
                <div
                    style={{
                        width: size + 'px',
                        height: size + 'px',
                        backgroundColor: 'white',
                        borderRadius: '100%',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <img
                        src={this.props.icon}
                        className={
                            styles.gravatar +
                            (this.props.disabled ? ' ' + styles.disabled : '')
                        }
                        alt="icon"
                        width={size}
                        onError={() => this.setFallbackImage(size)}
                    />
                </div>
            );
        // NOTE: GRAVATAR disabled for now. We agreed that we would like to prevent giving external
        // sources like gravatar possibility to gather info about users (ip, mms usage)
        // if we would like to reenable this, best option is to: have server side process that fetches + saves user images to our system (e.g. running once per day)

        // } else if (this.props.useGravatar) {
        //     icon = this.gravatar(size);
        } else {
            icon = this.initicon(size);
        }        

        this.state = { icon };
    }
    
    initicon = (size) => {
        return (
            <Initicon
                text={this.props.title || ''}
                size={size}
                seed={stringToHash(this.props.title || '')}
                color={this.props.secondary ? '#222' : ''}
                saturation={this.props.disabled ? '5%' : '25%'}
            />
        )
    };

    gravatar = (size) => {
        return (
            <Gravatar
                className={styles.gravatar}
                email={this.props.title}
                size={size}
            />
        )
    };

    setFallbackImage(size) {
        this.setState({ icon: this.initicon(size) });
    }

    render() {                
        return this.state.icon;
    }
}
export default Icon;
