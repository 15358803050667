import React from 'react';
import styles from './CountryColumn.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { MentionsVisualizerTypes } from '../../MentionsVisualizer.types';
import { euCountries } from 'src/Widgets/common/CountryFlag/countryFlags';
import CountryFlag from 'src/Widgets/common/CountryFlag/CountryFlag';
//@ts-ignore
import euFlag from '../../../common/CountryFlag/c_flags/eu.svg';

const CountryColumn = ({
    handleCountryFilter,
    handleLanguageFilter,
    getLanguagesByCountry,
    sortedUniqueCountries,
    activeFilter,
    selectedLanguages
}: MentionsVisualizerTypes.CountryColumnProps) => {
    
    //check if there are EU clips
    const hasEUClips: boolean = sortedUniqueCountries.some(
        (clip: MentionsVisualizerTypes.Clip) => euCountries.includes(clip.cc)
    );

    return (
        <div className={styles.countryContainer}>
            {hasEUClips && (
                <div className={styles.euFlagContainer}>
                    {sortedUniqueCountries.map((country) =>
                        euCountries.includes(country.cc)
                    ) && (
                        <div
                            className={styles.countryObject}
                            data-testid="eu-country-filter"
                            onClick={() => handleCountryFilter('EU')}
                        >
                            <img src={euFlag} alt="EU" width={20} height={15} />
                            EU
                        </div>
                    )}
                </div>
            )}
            {sortedUniqueCountries.map((clip) => {
                const isActive = activeFilter.countries.includes(clip.cc);
                return (
                    <div key={clip.notifID} className={styles.countryLanguageContainer}>
                        <div
                            className={
                                isActive
                                    ? styles.activeCountryObject
                                    : styles.countryObject
                            }
                            onClick={() => handleCountryFilter(clip.cc)}
                        >
                            <CountryFlag cc={clip.cc} width={20} title={clip.country}/>
                            {clip.cc}
                        </div>
                        {isActive && (
                            <div className={styles.languageContainer}>
                                {getLanguagesByCountry(clip.cc).map((language) => (
                                    <div
                                        key={language}
                                        className={
                                            selectedLanguages[clip.cc]?.includes(language)
                                                ? styles.activeLanguage
                                                : styles.language
                                        }
                                        onClick={() =>
                                            handleLanguageFilter(clip.cc, language)
                                        }
                                    >
                                        <span className={styles.languageRow}>
                                            <FontAwesomeIcon icon={faGlobe} /> {language}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default CountryColumn;
