const geographicalBreakDownData = [
    {
        "name": "ES",
        "_count": 7380
    },
    {
        "name": "DE",
        "_count": 5629
    },
    {
        "name": "AR",
        "_count": 5527
    },
    {
        "name": "CL",
        "_count": 4764
    },
    {
        "name": "CO",
        "_count": 4376
    },
    {
        "name": "GB",
        "_count": 4236
    },
    {
        "name": "MX",
        "_count": 3744
    },
    {
        "name": "FR",
        "_count": 2052
    },
    {
        "name": "ZA",
        "_count": 1617
    },
    {
        "name": "PE",
        "_count": 1485
    },
    {
        "name": "IT",
        "_count": 1249
    },
    {
        "name": "US",
        "_count": 1170
    },
    {
        "name": "CA",
        "_count": 969
    },
    {
        "name": "CH",
        "_count": 958
    },
    {
        "name": "AT",
        "_count": 917
    },
    {
        "name": "AU",
        "_count": 635
    },
    {
        "name": "IN",
        "_count": 510
    },
    {
        "name": "QA",
        "_count": 412
    },
    {
        "name": "IR",
        "_count": 406
    },
    {
        "name": "VE",
        "_count": 357
    },
    {
        "name": "IE",
        "_count": 335
    },
    {
        "name": "CG",
        "_count": 241
    },
    {
        "name": "CN",
        "_count": 236
    },
    {
        "name": "CR",
        "_count": 230
    },
    {
        "name": "BE",
        "_count": 212
    },
    {
        "name": "AE",
        "_count": 190
    },
    {
        "name": "NG",
        "_count": 183
    },
    {
        "name": "SG",
        "_count": 133
    },
    {
        "name": "HN",
        "_count": 99
    },
    {
        "name": "IL",
        "_count": 96
    },
    {
        "name": "LU",
        "_count": 94
    },
    {
        "name": "GR",
        "_count": 85
    },
    {
        "name": "EG",
        "_count": 75
    },
    {
        "name": "HK",
        "_count": 72
    },
    {
        "name": "JP",
        "_count": 69
    },
    {
        "name": "TR",
        "_count": 53
    },
    {
        "name": "CY",
        "_count": 40
    },
    {
        "name": "NA",
        "_count": 40
    },
    {
        "name": "HU",
        "_count": 35
    },
    {
        "name": "NL",
        "_count": 31
    },
    {
        "name": "DZ",
        "_count": 25
    },
    {
        "name": "KR",
        "_count": 22
    },
    {
        "name": "PT",
        "_count": 8
    },
    {
        "name": "DK",
        "_count": 5
    },
    {
        "name": "SA",
        "_count": 4
    },
    {
        "name": "FI",
        "_count": 4
    },
    {
        "name": "CZ",
        "_count": 4
    },
    {
        "name": "BR",
        "_count": 2
    }
];

export default geographicalBreakDownData;