import GenericCalls from './genericCalls';
// import _ from 'lodash';

class APISchedules {

    static async getSchedules() {
        return await GenericCalls.get(`/api/schedules/`);
    }

    static async getTasks(scheduleId) {
        return await GenericCalls.get(`/api/schedules/${scheduleId}`);
    }
    
    static async postSchedule(data) {
        // return{data:{id:3}}
        return await GenericCalls.post('/api/schedules/', data,
            // { contentType: 'application/json; charset=utf-8' }
        );
    }

    static async postTask(scheduleId, data) {
        return await GenericCalls.post(`/api/schedules/${scheduleId}/task`, data,
            // { contentType: 'application/json; charset=utf-8' }
        );
    }
    
    static async putSchedule(scheduleId, data) {
        return await GenericCalls.put(`/api/schedules/${scheduleId}`, data,
            // { contentType: 'application/json; charset=utf-8' }
        );
    }

    static async putTask(scheduleId, taskId, data) {
        return await GenericCalls.put(`/api/schedules/${scheduleId}/task/${taskId}`, data,
            // { contentType: 'application/json; charset=utf-8' }
        );
    }
    
    static async deleteSchedule(scheduleId,hideErrors) {
        return await GenericCalls.delete(`/api/schedules/${scheduleId}`, hideErrors);
    }

    static async deleteTask(scheduleId, taskId) {
        return await GenericCalls.delete(`/api/schedules/${scheduleId}/task/${taskId}`);
    }
}

export default APISchedules;