import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import pptxgen from 'pptxgenjs';    // version: ^3.9.0
import domtoimage from 'dom-to-image';
import styles from './Pptxgen.module.scss';
import {
    // dateToString,
    downloadFile,
    formatSettings,
    getOptions,
    getUniqueWidgetsSettings,
    scale,
    scaleMax,
    // convertTZ,
    // dateToTimeString
} from 'src/Widgets/common/helpers';
import LoadingIndicator from 'src/Widgets/common/basicElements/LoadingIndicator/LoadingIndicator';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import _ from 'lodash';
import { getAvailableWidgets } from 'src/Board/Workspace/availableWidgets';
import DownloadButton from 'src/Widgets/common/basicElements/DownloadButton/DownloadButton';
import { dynamicConfig } from 'src/config/dynamicConfig/dynamicConfig';

// TODOs:
// - ensure we only allow generation while widgets are not fetching data + at least one exportable chart is put on workspace
// - position charts "centered"
// - discuss client-side date (Timezone) ?
// - write filter names bold ?
// - warn if not all charts on workspace are exportable (show list) ?
// - (fix chart sizes (white space around charts, reason: rendered like that in ui ?))
// - allow aborting export ?


// TODO: render in DOM to get size from actual img instead ?
let logoSize = { w: 586, h :174 };
let imgFooterSize = { w: 2000, h: 245 };
let imgFooterBigSize = { w: 2000, h: 800 };


// prototype only, has hard coded values and some issues, see below
// allows 1. download of pptx file (1 chart per slide) 2. download svg files
// for the current workspace

// svg attempt issues:
// - images blurry if opened in libreoffice impress (see https://github.com/gitbrent/PptxGenJS/issues/401#issuecomment-1023461989)
// - pptx download not working for map svg
// - not possible to get e.g. legend (is no svg)
// - image gets stretched if widget is not a square (w/h calculations below done wrong ?)
// - image height needs to be reduced by height of header text field

class Pptxgen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            export:[],
            exporting: false,
            processed: 0,
        };

        this.layout = { name:'16x9', height: 5.63, width: 10 };
        this.logoSize = scale(logoSize,{ w: 1.67 });
        this.imgFooterSize = scale(imgFooterSize, { w: this.layout.width });
        this.imgFooterBigSize = scale(imgFooterBigSize, { w: this.layout.width });
        this.availableWidgets = getAvailableWidgets(this.props.me?.experimentalWidgetsSelectable, this.props.me?.allWidgets);

        const slideMasters = dynamicConfig.getSlideMasters(this.layout, this.logoSize, this.imgFooterSize, this.imgFooterBigSize)
        this.titleSideMaster = slideMasters.titleSlide;
        this.slideMaster = slideMasters.slide;
    }

    downloadSvgs(){
        // no issues here
        const svgs = this.getSvgs('svg.recharts-surface, svg.rsm-svg');
        svgs.forEach((svg)=>{
            const svgStr = new XMLSerializer().serializeToString(svg);
            downloadFile(svgStr,'demo.svg','image/svg+xml');
        });
    }

    getSvgs(selector){  // getting from workspace DOM
        let rv = [];
        const widgets = document.querySelectorAll(".widget");
        widgets.forEach((w)=>{
            const svg = w.querySelector(selector);
            if(svg) rv.push(svg);
        });
        return rv;
    }

    downloadPptx(){
        let pptx = new pptxgen();
        const slideM = { h: 5.63, w: 10 };
        pptx.defineLayout({ name:'16x9', width: slideM.w, height: slideM.h });
        const svgs = this.getSvgs('svg.recharts-surface');
        // const svgs = this.getSvgs('svg.rsm-svg');   // map chart svg - but this throws an error from pptxgen library
        svgs.forEach((svg)=>{
            const svgStr = new XMLSerializer().serializeToString(svg);
            const w = svg.getBBox().width / 96;
            const h = svg.getBBox().height / 96;
            const encodedData = 'data:image/svg+xml;base64,' + window.btoa(svgStr);
            this.addSlide(pptx, {data:encodedData,w,h}, slideM);
        })
        pptx.writeFile({ fileName: 'demo.pptx' });
    }

    renderBigCharts(widgets){
        // console.log(widgets);
        this.setState({export:widgets})
    }

    async downloadPptxPng(){
        let pptx = new pptxgen();
        pptx.defineLayout(this.layout);
        pptx.defineSlideMaster(_.cloneDeep(this.slideMaster));
        pptx.defineSlideMaster(_.cloneDeep(this.titleSideMaster));

        const workspace = this.props.workspaces[this.props.board.currentWorkspaceIndex];
        let widgets = _.cloneDeep(workspace.widgets);
        let exportableWidgets = [];
        let noExport = [];
        const workspaceSettings = workspace.state.settings;
        await Promise.all(widgets.map(async w=>{
            const widgetType = _.find(this.availableWidgets, {key: w.type});
            w.ref = document.querySelector(`.widget.widget-id-${w.id} .export-wrapper`);
            w.name = this.props.t(widgetType.name);
            if(w.ref){  // skip widget, if it does not contain .export-wrapper
                let settings;
                if(workspaceSettings && Object.keys(workspaceSettings).length > 0) {
                    settings = Object.keys(workspaceSettings)
                    .reduce( (sum, wKey) => {
                        if( _.has(widgetType.props.settings, wKey) ) {
                            sum[wKey] = workspaceSettings[wKey];
                        }
                        return sum;
                    },{});
                } else { settings = w.state.settings; }
                const widgetSettings = getUniqueWidgetsSettings(this.props.workspaces.reduce((sum,w)=>[...sum, ...w.widgets],[]), this.availableWidgets);
                w.formattedSettings = formatSettings(widgetSettings, settings, await getOptions(settings),{showDatesAbsolute: true});
                w.settingsStr = "";
                Object.keys(w.formattedSettings).map((key)=>{
                    const val = w.formattedSettings[key].data;
                    w.settingsStr += `${this.props.t(w.formattedSettings[key].title)}: ${Array.isArray(val) ? val.join(', ') : val.toString()}\n`;
                    return undefined;
                });
                exportableWidgets.push(w);
            } else if (w.type === 'reportTitlePage') {
                exportableWidgets.push(w);
            }
            else {
                noExport.push(w);
            }
        }));
        
        this.setState({exporting: true, processed: 0, total: exportableWidgets.length});
        this.renderBigCharts(exportableWidgets);
        const start = performance.now();
        exportableWidgets = _.sortBy(exportableWidgets, (o) => o.coordinates.y, (o) => o.coordinates.x);
        for (let i = 0; i < exportableWidgets.length; i++){
            let w = exportableWidgets[i];

            if (w.type === 'reportTitlePage') {
                this.addTitleSlide(
                    pptx,
                    workspaceSettings && workspaceSettings.date && `${workspaceSettings.date.display_from} to ${workspaceSettings.date.display_to}`,
                    w.state.title
                );
                continue;
            }

            // workaround to allow rendering charts bigger(and not cropped) but not showing them to the user (only for a short period - while exporting)
            w.ref.style.visibility = "visible";
            w.ref.style.position = 'relative';
            const canvas = await domtoimage.toPng(w.ref,{quality:1,style:{}});
            w.ref.style.visibility = "hidden";
            w.ref.style.position = 'fixed';
            // const canvas = await html2canvas(w);
            this.addSlide(
                pptx,
                {
                    data: canvas,
                    w: w.ref.clientWidth / 96,
                    h: w.ref.clientHeight / 96,
                },
                { w: this.layout.width, h: this.layout.height},
                w.name,
                w.settingsStr,
            );
        }

        // TODO: use comp. instead ?
        // const confirmed = window.confirm(
        //     `${this.props.t('The following widgets from this workspace will not appear in the exported file')}:\n\n${noExport.map(e=>'- ' + e.name).join('\n')}`
        //     + `\n\n${this.props.t('Possible reasons are')}:\n- ${this.props.t('no data shown due to filter selection')}\n- ${this.props.t('export not supported for widget type')}`
        // );
        // if(!confirmed){ this.setState({exporting: false}); return; }

        // console.log("to file");
        pptx.writeFile({ fileName: dynamicConfig.pptxFilename });
        console.log((performance.now() - start) / 1000);
        this.setState({ exporting: false });
    }

    addTitleSlide(pptx, text='', title) {
        let slide = pptx.addSlide({ masterName: 'TITLE_SLIDE' });
        slide.addText(title, {placeholder: 'title'})
        slide.addText(`${this.props.t("Reporting Period")}: ${text}`, {placeholder: 'text'})
    }

    addSlide(pptx, img, slideM, title, description){
        // console.log(slideM.h);
        // const destImgM = {
        //     w: slideM.h / img.h * img.w,
        //     h: slideM.h,
        // }
        // console.log(slideM.h, img.h, img.w,destImgM,slideM.h / img.h * img.w);
        // const settingsLength = 2;
        
        
        let slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
        const destImg = scaleMax(img, {h: this.layout.height - 1.9, w: this.layout.width * 0.65});
        slide.addImage({
            data: img.data,
            x: 0.2,
            y: 1.3,
            w: destImg.w - 0.2,
            h: destImg.h,
            // align:'center',
        })
        // const now = convertTZ(new Date(Date.now()), this.props.me.timezone);
        // slide.addText(`created: ${dateToString(now)} ${dateToTimeString(now)} (${this.props.me.timezone})`, {placeholder: 'top_left'})
        slide.addText(title, {placeholder: 'title'})
        slide.addText(description, {placeholder: 'report_element_text'})
        this.setState({processed:this.state.processed+1});
    }

    // html2canvas(
    //         divElement,
    //     ).then(canvas => (console.log(canvas))

    render() {
        const { processed, exporting, total } = this.state;

        return(
                <div>
                    {
                        <div className={exporting && styles.exportOverlay}>
                           {
                             exporting &&
                             <LoadingIndicator
                                 text={`${this.props.t('Preparing presentation')} ...`}
                                 progress={processed / total * 100}
                             />
                           }
                        </div>
                    }
                    <DownloadButton onClick={()=>{
                            this.setState({exporting: true, processed: 0, total: 1});
                            setTimeout(()=>{
                                this.downloadPptxPng();
                            },200)
                        }} btnClass={this.props.btnClass} type='pptx'/>
{/*                     <Button
                        type="primary"
                        btnClass={this.props.btnClass}
                        onClick={()=>{
                            this.setState({exporting: true, processed: 0, total: 1});
                            setTimeout(()=>{
                                this.downloadPptxPng();
                            },200)
                        }}
                    >
                    {this.props.t('Export')} (pptx)
                    </Button> */}
                    {/* <button onClick={()=>(this.downloadPptx())}>export (pptx)</button>
                    <button onClick={()=>(this.downloadSvgs())}>export (svgs)</button> */}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    workspaces: state.workspaces,
    board: state.board,
    me: state.me,
    //theme: state.theme,
});
export default connect(mapStateToProps,{ /* insert redux actions here */ })(withTranslation()(Pptxgen));