import { useEffect, useState } from 'react';
import styles from './TopicMentionsOverview.module.scss';
import { getChannels } from 'src/redux/actions/actions.channels';

import useDateUtils from 'src/hooks/useDateUtils';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import APIMentions from 'src/API/APIMentions';
import { Cluster } from './OverviewCluster/OverviewCluster.types';
import OverviewCluster from './OverviewCluster/OverviewCluster';
import { mockClusters } from './__mock__/mockData';

const TopicMentionsOverview = () => {
    const [mentionsData, setMentionsData] = useState([]);
    const [itemsDisplayed, setItemsDisplayed] = useState([]);
    const [clustersData, setClustersData] = useState<Cluster[]>(mockClusters);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const workspaces = useAppSelector((state) => state.workspaces);
    const board = useAppSelector((state) => state.board);

    const { dateConverter, getDateRange } = useDateUtils();

    const selectedProfileIds =
        workspaces[board.currentWorkspaceIndex]?.state.settings.profile;
    const selectedDates = workspaces[board.currentWorkspaceIndex]?.state.settings;

    const dateByUnit = {
        unit: workspaces[board.currentWorkspaceIndex]?.state.settings.date
            ? workspaces[board.currentWorkspaceIndex].state.settings.date.unit?.value
            : null,
        count: workspaces[board.currentWorkspaceIndex]?.state.settings.date
            ? workspaces[board.currentWorkspaceIndex].state.settings.date?.number
            : null
    };

    const dateByRangeSelect = {
        fromDate: selectedDates ? selectedDates?.date?.[0] : null,
        toDate: selectedDates ? selectedDates?.date?.[1] : null
    };

    const dateRangeFromUnits =
        dateByUnit && getDateRange(dateByUnit.unit, dateByUnit.count);
    const dateRangeByUnit = {
        fromDate: dateByUnit && dateRangeFromUnits[0],
        toDate: dateByUnit && dateRangeFromUnits[1]
    };

    const fetchMentions = async () => {
        try {
            const res = await APIMentions.getOverviewMentions(
                selectedProfileIds,
                !dateRangeByUnit.fromDate.length
                    ? dateConverter(dateByRangeSelect.fromDate)
                    : dateRangeByUnit.fromDate,
                !dateRangeByUnit.fromDate.length
                    ? dateConverter(dateByRangeSelect.toDate)
                    : dateRangeByUnit.toDate
            );
            const itemsPerPage = res.data.length;
            const startIndex = itemsDisplayed;
            const endIndex = itemsPerPage;

            if (res?.data?.length) {
                setMentionsData(res.data);
                setItemsDisplayed(endIndex);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchClusters = async (notificationIds: number[]) => {
        try {
            const res = await APIMentions.getComputedClustersData(notificationIds);
            setClustersData(res.data.clusters);
        } catch (error) {
            console.log(error);
        }
    };

/*     useEffect(() => {
        (dateByRangeSelect || dateRangeByUnit) &&
            selectedProfileIds?.length &&
            fetchMentions();
    }, [selectedDates, selectedProfileIds]); */

    useEffect(() => {
        dispatch(getChannels());
        //fetchMentions();
    }, []);

    return (
        <div className={styles.clustersContainer}>
            {selectedProfileIds && (dateByRangeSelect || dateRangeByUnit)
                ? clustersData.map((cluster: Cluster, index) => {
                      return <OverviewCluster key={index} cluster={cluster}/>;
                  })
                : t('No data to show')}
        </div>
    );
};

export default TopicMentionsOverview;
