import React from 'react';
import styles from './Banner.module.scss';
import classNames from 'classnames';
import '../../../../assets/animate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Banner = ({className, faIcon, faSpin, children, onClose}) =>
  <div 
    className={classNames(
      styles.banner,
      className,
      'animated fadeIn'
    )}
  >
    <FontAwesomeIcon
      className={classNames(
        styles.icon,
        { [styles.close]: onClose }
      )}
      icon={faIcon}
      spin={faSpin}
      onClick={onClose}
    />
    
    {children}
  </div>

export default Banner;