import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// import { getOptionsFunct } from 'src/Board/Workspace/availableWidgets';
import { getUniqueWidgetsSettings, formatSettings, getOptions } from '../helpers';
import { connect } from 'react-redux';
import styles from './CurrentWidgetSettings.module.scss';
import { getAvailableWidgets } from 'src/Board/Workspace/availableWidgets';

/**
 * props:
 *  - 
 */

class CurrentWidgetSettings extends React.Component {
    static propTypes = {
        settings: PropTypes.object // currently used settings
    };

    constructor(props) {
        super(props);
        this.state = {
            options: undefined,
            widgetSettings: undefined,
        };
        this.availableWidgets = getAvailableWidgets(this.props.me?.experimentalWidgetsSelectable, this.props.me?.allWidgets);
    }

    async onSettingsUpdated(){
        const options = await getOptions(this.props.settings);
        const widgetSettings = getUniqueWidgetsSettings(this.props.workspaces.reduce((sum,w)=>[...sum, ...w.widgets],[]), this.availableWidgets);
        return {options, widgetSettings};
    }

    async componentDidMount(){
        this.setState(await this.onSettingsUpdated());
    }

    async componentDidUpdate(prevProps){
        if(!_.isEqual(prevProps.settings, this.props.settings)){
            this.setState(await this.onSettingsUpdated());
        }
    }

    render() {
        const {settings, title} = this.props;
        const {options, widgetSettings} = this.state;
        if(!widgetSettings){
            return null;
        } else {
            const formatted = formatSettings(widgetSettings,settings,options);

            return (
                <div style={this.props.style} title={title}>
                    {/* <u>{title}</u> */}
                    {Object.keys(formatted).map((s, i) => {
                        return (
                            <span
                                className={styles.setting}
                                key={i}
                                title={
                                    Array.isArray(formatted[s].data)
                                        ? formatted[s].data.join(', ')
                                        : formatted[s].data
                                }
                            >
                                <b className={styles.title}>
                                    {this.props.t(formatted[s].title)}:
                                </b>
                                {Array.isArray(formatted[s].data)
                                    ? formatted[s].data.join(', ')
                                    : formatted[s].data}
                            </span>
                        );
                    })}
                    {settings.clipsType && (
                        <span className={styles.setting}>
                            <b className={styles.title}>
                                {this.props.t('Clips Type') + ':'}
                            </b>
                            {settings.clipsType === 'recent' ? this.props.t('Recent_clips') : this.props.t('Edited_clips')}
                        </span>
                    )}
                    {/* {!settings.date && (
                        <span className={styles.setting}>
                        <b className={styles.title}>
                            {this.props.t('Date') + ':'}
                        </b>
                        {`7${this.props.t('days')}`}
                    </span>
                    )} */}
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    workspaces: state.workspaces,
    me: state.me,
});
export default connect(mapStateToProps,{})(withTranslation()(CurrentWidgetSettings));