import EmmBanner from "src/Login/EmmBanner/EmmBanner";
import LoadingSpinner from "src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner";
import { useTranslation } from "react-i18next";

function AccountSetup(){
    const { t } = useTranslation();

    return <EmmBanner>
    <div style={{paddingBlockStart:'1rem'}}>
                <LoadingSpinner
                    size='3.5rem'
                />
                <p
                    style={{ fontWeight: '500', fontSize: '1.1rem' }}
                >
                    {t('account_prepare')}
                </p>
                </div>
</EmmBanner>
}

export default AccountSetup;