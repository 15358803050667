import Checkbox from "src/Widgets/common/basicElements/Checkbox/Checkbox";
import styles from './Question.module.scss';

function MultipleChoiceQuestion({
    answers,
    onAnswerChange,
}){
    return(
        <div>
            {answers.map((a,i)=>{
                return (
                    <div key={i} className={styles.wrapper} onClick={(val) => { onAnswerChange(i, !a.answer)}}>
                        <Checkbox
                            withBorder={true}
                            checked={a.answer}
                            text={a.text}
                        />
                    </div>
                )
            })}
        </div>
    )
}
export default MultipleChoiceQuestion;