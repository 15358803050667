import GenericCalls from './genericCalls';
import _ from 'lodash';

class APIProfileGroups {
    static removeTrash(res) {
        _.remove(res.data, { title: '__trash__' });
        return res;
    }

    static async get(id) {
        if (id) return await GenericCalls.get('/api/profile-groups/' + id);
        else {
            let res = APIProfileGroups.removeTrash(await GenericCalls.get('/api/profile-groups'));
            if (res && res.data) {
                res.data = res.data.map(pg => {
                    pg.profiles = pg.profiles.map(p=>({...p, isSuper: p.super, isEnabled: p.enabled}))
                    return pg;
                })
            }
            return res;
        }
    }
    
    static async search(searchTerm) {   //!
        return await APIProfileGroups.removeTrash(await GenericCalls.get('/api/profile-groups/search/' + searchTerm));
    }
    
    static async post(data, hideErrors) {
        return await GenericCalls.post('/api/profile-groups/', data, undefined, hideErrors);
    }
    
    static async put(id, data, hideErrors) {
        return await GenericCalls.put('/api/profile-groups/' + id, data, undefined, hideErrors);
    }
    
    static async delete(id, hideErrors) {
        return await GenericCalls.delete('/api/profile-groups/' + id, hideErrors);
    }
}

export default APIProfileGroups;