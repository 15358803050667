import { SHOW, SET_STEP, SET_STEPS, SET_WORKSPACES } from '../types/actionTypes.tour';

const paramsReducers = (state = {
    show: false,
    step: 0,
    steps: [],
    workspaces: undefined,
}, action) => {
    switch (action.type) {
        case SHOW:
            return {...state, show: action.data};
        case SET_STEP:
            return { ...state, step: action.data };
        case SET_STEPS:
            return { ...state, steps: action.data };
        case SET_WORKSPACES:
            return { ...state, workspaces: action.data };
        default:
            return state;
    }
};

export default paramsReducers;