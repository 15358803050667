import { useEffect, useState } from "react";

export const useIsScreenSize = (maxWidth: number) => {
  const [isUnder, setIsUnder] = useState(window.innerWidth <= maxWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsUnder(window.innerWidth <= maxWidth);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [maxWidth]);

  return isUnder;
}
