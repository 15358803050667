import GenericCalls from "./genericCalls";

class APIAuth {
    static async validate(credentials) {
        let headers = new Headers();

        if (credentials) {
            headers.append(
                'Authorization',
                credentials
            );
        }

        let url = '/api/auth/check';

        try {
            let response = await fetch(url, {
                method: 'GET',
                headers: headers
            });

            if (response.status !== 200) {
                console.log('Problem! Status Code: ' + response.status);
                return response;
                // let resp = await response.json();
                // return { status: response.status, data: resp };
            }

            const resp = await response.json();
            let auth = parseInt(resp.code) === 0 ? true : resp;

            // switch (process.env.REACT_APP_ENV) {
            //     case 'testing': auth = true; break;
            //     case 'production': auth = await halet auth = parseInt(resp.code) === 0 ? true : false;ndleResp(); break;
            //     default: auth = await handleResp(); break;
            // }

            return auth;
        } catch (err) {
            console.log('Fetch Error!', err);
        }
    }

    static async logout(){
        return await GenericCalls.get('/api/auth/check/logout');
    }

    static async login(googleApi) {
        const googleUser = await googleApi.signIn(
            { prompt: 'select_account' }
        );
        const authResponse = googleUser.getAuthResponse();
        this.onLoginSuccess(authResponse.id_token, authResponse.expires_at);
    }

    static async resetPassword(userId, password, pwCode) {
        return await GenericCalls.get(`/cgi-bin/pwd?forV3=1&id=${userId}&pwCode=${pwCode}&action=update&password=${password}&password2=${password}`);
    }

    static async sendEmailVerifyLink(email) {
        // new "email auth" user - email needs to be verified by sending link with code
        return await GenericCalls.get(`/api/email/verify`,{email});
    }

    static async verifyEmail(email, code) {
        // verify email address from user by using verification code (received via email)
        return await GenericCalls.get(`/api/email/verify`,{email, code});
    }
}

export default APIAuth;
