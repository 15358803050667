import { useEffect, useState } from "react";
import APIAuth from "src/API/APIAuth";
import { getHashParams } from "src/Widgets/common/helpers";
import EmailSignupSetPw from "./EmailSignupSetPw";

function VerifyEmail() {
    // @ts-ignore
    const { email, code } = getHashParams(['email', 'code']);
    const [ isVerified, setIsVerified ] = useState<boolean|undefined>(undefined);
    
    useEffect(() => {
        (async() => {
            console.log('asd', email, code)
            if (email && code) {
                const verifyResp = await APIAuth.verifyEmail(email, code);
                if (verifyResp?.rc === 0) {
                    setIsVerified(true);
                } else {
                    setIsVerified(false);
                }
            }
        })();
    }, []);

    if(!email || !code){ return <div>Invalid Url</div> }

    return (
        <div>
            {isVerified && <EmailSignupSetPw />}
            {isVerified === false && 'email verification failed' /* TODO: handle actual cases instead */ }
        </div>
    );
}

export default VerifyEmail;