import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePowerpoint, faRssSquare, faTable } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button/Button';
import { DownloadButtonProps } from './DownloadButton.types';

const DownloadButton: FC<DownloadButtonProps> = ({ type, disabled, onClick, btnClass }) => {
    
  const types = {
        xlsx: { title: 'XLSX', icon: faTable, color: 'green' },
        rss: { title: 'RSS', icon: faRssSquare, color: 'orange' },
        pptx: {title: 'PPTX', icon: faFilePowerpoint, color: '#ED6C47'}
      };

    const { title, icon, color } = types[type];

    return (
        <Button btnClass={btnClass} onClick={onClick} type="downloadButton">
            {`${title} `}
            <FontAwesomeIcon icon={icon} color={disabled ? undefined : color} size="1x" />
        </Button>
    );
};

export default DownloadButton;
