import ReactPiwik from 'react-piwik';
import { initMatomo } from './redux/actions/actions.matomo';
import { isMentionFindr } from './Widgets/common/helpers';
import { dynamicConfig } from './config/dynamicConfig/dynamicConfig';

let enabled = true;  // TODO: move this to some config file ?

const Matomo = () => {
    let store = window.rxStore;
    let matomo = store.getState().matomo;
    let rv;
    // switch (process.env.REACT_APP_ENV) {
    //     case 'dev1a': enabled = false; break;
    //     default: break;
    // }
    if (enabled) {
        if (matomo === null) {
            let conf = {
                trackErrors: true,
            };
            // if (process.env.REACT_APP_MATOMO === 'dev') {
            //     conf = {
            //         ...conf,
            //         url: 'ct:8082',
            //         siteId: 1,
            //     };
            // } else {
                conf = {
                    ...conf,
                    url: `${window.location.host}/m`,
                    siteId: dynamicConfig.matomoId,
                    jsFilename: 'tr.js',
                    phpFilename: 'tr.php'
                }
            // }
            matomo = new ReactPiwik(conf);
            // const user = atob(window.localStorage.getItem('credentials')).split(':')[0];    // read auth from localstorage and pass userid to matomo
            // ReactPiwik.push(['setUserId', user]);
            store.dispatch(initMatomo(ReactPiwik));
        }
        rv = ReactPiwik;
    } else {
        rv = {push: ()=> {}};
    }
    return rv;
}

export default Matomo;