import React from 'react';
import styles from './WaterfallCard.module.scss';
import itemStyles from './WaterfallItem/WaterfallItem.module.scss';
import WaterfallItem from './WaterfallItem/WaterfallItem';
import classNames from 'classnames';
import _ from 'lodash';
import { connect } from 'react-redux';

class WaterfallCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.currentItem = 0;
        this.itemDivs = [];
        
    }

    componentDidUpdate(prevProps, prevState) {
        const { current } = this.props;
        if (prevProps.current > current) { this.setState({ scroll: 'up', key:Math.random() }) }
        if (prevProps.current < current) { this.setState({ scroll: 'down', key:Math.random() }) };
    }

    renderItems(items) {
            let animation;
            switch (this.state.scroll) {
                case 'up': animation = styles.up; break;
                case 'down': animation = styles.down; break;
                default: break;
            }
            let rv = [];
            const { current } = this.props;
            const next = items[current+1];
            if (next) {
                    rv.push(
                    <WaterfallItem
                            item={next}
                            key="next"
                    />
                );
            } else {
                rv.push(<div className={itemStyles.item} key="placeholder"/>);    // placeholder item to always let appear first entry of profile (even if only one available)
            }
            
            for (let i = current; i >= 0 && (current - i) < 4; i--) {
                const e = items[i];
                const active = i === current ? true : false;
                rv.push(
                    <WaterfallItem
                        item={e}
                        key={i}
                        active={active}
                        // ref={(r) => { this.itemDivs[index] = r; }}
                    />
                );
            }

            return (
                <div className={classNames(styles.body, animation)} key={this.state.key}>
                    {rv}
                </div>
            );
    }

    render() {
        const { items, active, theme } = this.props;
        const unread = _.reduce(items, (sum, n) => n._read ? sum : ++sum, 0);   // get number of unread items
        let cardTitleStyle = [styles.header, theme.backgroundPrimary];
        if (active) cardTitleStyle.push(theme.backgroundSecondary);
        
        return(
            <div className={styles.card}>
                <div className={classNames(cardTitleStyle)}>
                    <span className={styles.headerContentLeft}>
                        {items[0].ptitle}
                        { unread > 0
                        ?
                            <span
                                className={classNames(
                                    styles.unreadCount,
                                    // theme.backgroundSecondary
                                )}
                            >
                                {unread}
                            </span>
                        :   null
                        }
                    </span>
                    <span className={styles.headerContentRight}>
                        {items[0]?.playerLink && <button
                            className={styles.autoplay}
                            onClick={this.props.onAutoPlay}
                        >
                            Autoplay
                        </button>}
                    </span>
                </div>
                
                <div className={styles.bodyContainer}>
                {/* TODO: investigate in "swiping" issue or drop swipe functionality: swipes but also scrolls afterwards */}
                {/* <Swipeable onSwiped={this.onSwiped}{...{trackMouse:true}}> */}
                        {
                            this.renderItems(items)
                        }                    
                        {/* </Swipeable> */}
                </div>
                
            </div>
        );
    }
}

const mapStateToProps = state => ({ 
    theme: state.theme,
    matomo: state.matomo 
});
export default connect(mapStateToProps)(WaterfallCard);