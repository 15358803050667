import styles from './VideoCard.module.scss';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { SentimentColor, VideoCardProps } from './VideoCard.types';

const sentimentColorCondition = (sentiment: string): SentimentColor => {
    switch (sentiment) {
        case 'positive':
            return { color: 'green', fontSize: '0.7rem' };
        case 'negative':
            return { color: 'red', fontSize: '0.7rem' };
        case 'neutral':
            return { color: 'grey', fontSize: '0.7rem', rotate: '-90deg' };
        default:
            return { color: 'black', fontSize: '0.7rem' };
    }
};

const VideoCard: FC<VideoCardProps> = ({ icon, cluster, conditionalSentimentIcon, date }) => {
    const handleOpenNewTab = (link: string) => {
        window.open(link, '_blank');
    };

    const sentimentStyle = sentimentColorCondition(cluster.sentiment);

    return (
        <article className={styles.videoCardContainer}>
            <div className={styles.videoCardHeader}>
                <div className={styles.iconWrapper}>
                    <img className={styles.channelIcon} src={icon} alt="icon" />
                    <span className={styles.channelName}>{cluster?.channelName}</span>
                </div>
                <span style={sentimentStyle}>{conditionalSentimentIcon(cluster.sentiment, 'overlay')}</span>
            </div>
            
            <section className={styles.videoPlayerSection}>
                <img data-testid="keyframe" className={styles.thumbnail} src={cluster.kfLink} alt="thumbnail" />
                <FontAwesomeIcon
                    icon={faPlay}
                    onClick={() => handleOpenNewTab(cluster.viewLink)}
                    className={styles.playIcon}
                />
            </section>
            <div className={styles.beginF}>{date}</div>
        </article>
    );
};

export default VideoCard;
