import React from 'react';
import { withTranslation } from 'react-i18next';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, Label } from 'recharts';
import { randomColor } from 'randomcolor';
import _ from 'lodash';
import styles from './AreaChart.module.scss';

const CustomizedAxisTick = props => {
    const { x, y, payload } = props
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text dy={16} textAnchor='middle' fill='#666'>{payload.value}</text>
      </g>
    )
}

class eAreaChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getColor(name) {
        return randomColor({
            luminosity: 'bright',
            seed: name
        });
    }

    render() {
        const { data, stacked, xLabel, yLabel } = this.props;
        const stackId = stacked !== true ? undefined : "1";
        const yLabelObj = yLabel ? { value: yLabel, angle: -90, position: 'insideLeft' } : undefined;
        
      return (
        <div className={styles.centeredWrapper} style={{direction: 'ltr'}}>
          <ResponsiveContainer debounce={200} width="90%" height="98%">
          <AreaChart
            data={data}
            margin={{top: 10, right: 30, left: 20, bottom: 35}}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={<CustomizedAxisTick/>}>
              { xLabel && <Label value={xLabel} offset={-20} position="insideBottom" /> }
            </XAxis>
            <YAxis label={yLabelObj} />
            <Tooltip />
            <Legend
              layout="horizontal"
              verticalAlign="top"
              align="top"
            />
            
              {_.map(data[0].data, (val, key) => {
                  return (
                      <Area
                          // type="monotone"
                          dataKey={"data["+key+"]"}
                          key={key}
                          name={data[0].legend[key]}
                          stackId={stackId}
                          fill={this.getColor(key)}
                          stroke={this.getColor(key)}
                          animationDuration={300}
                      />
                  )
              })};
                 {/* <Area type="monotone" dataKey="uv" stackId={stackId} stroke="#8884d8" fill="#8884d8" /> */}
          </AreaChart>
          </ResponsiveContainer>
          </div>
            
        );
    }
}
export default withTranslation()(eAreaChart);