import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
//import styles from './SpamStatus.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class SpamStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { spamStatus, t, runSpamCheck } = this.props;
        let title;

        if (spamStatus === undefined) return null;

        switch (spamStatus) {
            case -1:
                title = `${t('profile_query_unchecked')}`;
                break;
            case 0:
                title = `${t('profile_spam_success')}.`;
                break;
            case undefined: break;
            default:
                title = `${t('profile_query_spam_text')}.`;
                break;
        }
        return(
            <div
                style={{
                    marginInlineStart: '.4rem',
                    padding: '0.2rem',
                    backgroundColor: 'white',
                    borderRadius: '3px',
                    
                    height: '1.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    ...this.props.style,
                }}
                title={title}
            >
                {
                    spamStatus === -1
                        ? <FontAwesomeIcon
                            color={'orange'}
                            icon={faExclamationTriangle}
                            onClick={runSpamCheck}
                            style={runSpamCheck !== undefined ? {cursor:'pointer'} : undefined}
                            />
                        :spamStatus === 0
                            ? <FontAwesomeIcon color={'green'} icon={faCheckCircle} />
                            : <FontAwesomeIcon
                                color={'red'}
                                icon={faExclamationTriangle}
                            />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    // theme: state.theme,
});
export default connect(mapStateToProps,{ /* insert redux actions here */ })(withTranslation()(SpamStatus));