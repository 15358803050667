const workspaces = [
    {
        name: 'Search',
        widgets: [
            {
                id: 0,
                type: 'search_next',
                state: {},
                coordinates: { x: 0, y: 0, w: 6, h: 6 },
            }
        ],
        state: { settings: {} }
    },
    {
        name: 'Search Agents',
        widgets: [
            {
                id: 0,
                type: 'searchAgents',
                state: {},
                coordinates: { x: 0, y: 0, w: 6, h: 6 },
            },
        ],
        state: { settings: {} }
    },
    {
        name: 'Recent Clips',
        widgets: [
            {
                id: 0,
                type: "clips_recent_next",
                state: {},
                coordinates: { x: 0, y: 0, w: 6, h: 6 },
            },
        ],
        state: { settings: {} }
    },
    // {
    //     name: 'Edited Clips',
    //     widgets: [
    //         {
    //             id: 0,
    //             "type": "clips_edited_next",
    //             "state": {},
    //             coordinates: { x: 0, y: 0, w: 6, h: 6 },
    //         },
    //     ],
    //     state: { settings: {} }
    // },
    {
        name: 'Go To',
        widgets: [
            {
                id: 0,
                type: 'epg',
                state: {},
                coordinates: { x: 0, y: 0, w: 6, h: 6 },
            }
        ],
        state: { settings: {} }
    },
    {
        name: 'Analytics',
        widgets: [
            {
                id: 0,
                type: "reportTitlePage",
                name: "Title Page",
                state: {
                    showSettings: false
                },
                coordinates: { x: 0, y: 0, w: 3, h: 1 }
            },
            {
                id: 2,
                type: 'languageBreakdown',
                state: {
                    showSettings: false,
                    settings: {
                        date: { unit: { label: 'days', value: 'days' }, number: 7 }
                    }
                },
                coordinates: { x: 3, y: 0, w: 3, h: 3 },
            },
            {
                id: 3,
                type: 'mediaTypeBreakdown',
                state: {
                    showSettings: false,
                    settings: {
                        date: { unit: { label: 'days', value: 'days' }, number: 7 }
                    }
                },
                coordinates: { x: 0, y: 4, w: 3, h: 3 },
            },
            {
                id: 4,
                type: 'scopeBreakdown',
                state: {
                    showSettings: false,
                    settings: {
                        date: { unit: { label: 'days', value: 'days' }, number: 7 }
                    }
                },
                coordinates: { x: 0, y: 7, w: 3, h: 3 },
            },
            {
                id: 5,
                type: 'scopeOfMentions',
                state: {
                    showSettings: false,
                    settings: {
                        date: { unit: { label: 'days', value: 'days' }, number: 7 }
                    }
                },
                coordinates: { x: 3, y: 6, w: 3, h: 4 },
            },
            {
                id: 6,
                type: 'worldMap',
                state: {
                    showSettings: false,
                    settings: {
                        date: { unit: { label: 'days', value: 'days' }, number: 7 }
                    }
                },
                coordinates: { x: 3, y: 6, w: 3, h: 3 },
            },
            // {
            //     id: 7,
            //     type: 'topicMentionDetailsTable',
            //     state: {
            //         showSettings: false,
            //         settings: {
            //             date: { unit: { label: 'days', value: 'days' }, number: 7 }
            //         }
            //     },
            //     coordinates: { x: 0, y: 10, w: 6, h: 3 },
            // },
        ],
        state: {
            settings: { date: { unit: { label: 'days', value: 'days' }, number: 7 } },
            showSettings: true
        }
    }
];

export default workspaces;
