import {
    RECEIVE_PROFILE_GROUPS,
    ADD_PROFILE_GROUP,
    DELETE_PROFILE_GROUP,
    UPDATE_PROFILE_GROUP,
    PROFILE_GROUPS_LOADING,
    UPDATE_PROFILE,
    DELETE_PROFILE,
    ADD_PROFILE
} from '../types/actionTypes.profileGroups';
// import _ from 'lodash';
import APIProfileGroups from 'src/API/APIProfileGroups';
// import APIProfiles from 'src/API/APIProfiles';

export const getProfileGroups = () => {
    return (dispatch) => {
        dispatch({
            type: PROFILE_GROUPS_LOADING,
            data: true
        });
        APIProfileGroups.get().then((d)=>{
            dispatch({
                type: RECEIVE_PROFILE_GROUPS,
                data: d.data
            });
        })
    }
}

// export const addProfile = (profile, hideErrors) => {
//     return (dispatch) => {
//         APIProfiles.post(profileGroup, hideErrors).then((d) => {
//             if(d && d.rc === 0){
//                 dispatch({
//                     type: ADD_PROFILE_GROUP,
//                     data: {
//                         ...profileGroup,
//                         id: d.id,
//                         profiles: [],
//                     }
//                 });
//             }
//         })
//     }
// }

export const addProfileGroup = (profileGroup, hideErrors) => {
    return (dispatch) => {
        return APIProfileGroups.post(profileGroup, hideErrors).then((d) => {
            if(d && d.rc === 0){
                dispatch({
                    type: ADD_PROFILE_GROUP,
                    data: {
                        ...profileGroup,
                        id: d.id,
                        profiles: [],
                    }
                });
                dispatch({
                    type: PROFILE_GROUPS_LOADING,
                    data: true
                });
                APIProfileGroups.get().then((d)=>{
                    dispatch({
                        type: RECEIVE_PROFILE_GROUPS,
                        data: d.data
                    });
                })
       
            }
            return d;
            
        })
    }
}

export const addProfile = (profileGroupId, profile) => {
    return (dispatch) => {
        dispatch({
            type: ADD_PROFILE,
            data:{
                profileGroupId,
                profile
            }
      })
    }
}

export const updateProfile = (profileGroupId, profile) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE,
            data:{
                profileGroupId,
                profile
            }
      })
    }
}

export const deleteProfile = (profileGroupId, profileId) => {
    return (dispatch) => {
        dispatch({
            type: DELETE_PROFILE,
            data:{
                profileGroupId,
                profileId
            }
      })
    }
}

export const updateProfileGroup = (id, data) => {
    return (dispatch) => {
        console.log("ss")
        return APIProfileGroups.put(id, data).then((res) => {
            console.log("oo")
            dispatch({
                type: UPDATE_PROFILE_GROUP,
                data: {
                    id,
                    ...data,
                }
          });
          return res;
      })
    }
};
  
export const deleteProfileGroup = (id, hideErrors) => {
    return (dispatch) => {
        return APIProfileGroups.delete(id, hideErrors).then((res) => {
            if(res.rc === 0){
                dispatch({
                    type: DELETE_PROFILE_GROUP,
                    data: {
                        id
                    },
                });
            }
          return res;
      })
    }
  };