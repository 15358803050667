// run async tasks (e.g. api requests) one after another
// see also: https://stackoverflow.com/a/53540586
// usage example:
// const q = new SerialQueue();
// const result1 = await q.add(someApiFunct);
// const result2 = await q.add(someApiFunct);

const SerialQueue = () => {
    let running = Promise.resolve();
    const run = async (task) => {
        try{
            await running;
        } finally{
            return task();
        }
    }
    const add = (task) => {
        running = run(task);
        return running;
    }

    return {
        add: add,
    }
}
export default SerialQueue;

// not using class implementation for now because we can not easily encapsulate (e.g. use private methods/variables)
// class SerialQueue{
//     constructor(){
//         this.running = Promise.resolve();
//     }

//     _run = async(task) => {
//         console.log('run');
//         try{
//             await this.running;
//         } finally{
//             return task();
//         }
//     }

//     add(task){
//         this.running = this._run(task);
//         return this.running;
//     }
// }