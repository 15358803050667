import React from 'react';
// //@ts-ignore
// import { CoverageSummary } from 'istanbul-coverage-display';
// import 'istanbul-coverage-display/dist/bundle.css';
//@ts-ignore
import styles from './TestCoverage.module.scss';
import useTestCoverage from './useTestCoverage';

interface TestCoverageProps{
  show?: boolean
}

function TestCoverage({show}:TestCoverageProps) {
  const { renderOpen, renderCoverageDiv, isOpen, pos } = useTestCoverage();
  
    if(show || process.env.REACT_APP_TESTCOVERAGE === '1'){
      return (
        <>
          <div className={[styles.wrapper, pos==='bottomRight' ? styles.right : styles.left].join(' ')}>
          {isOpen ? renderOpen() : undefined}
            {renderCoverageDiv()}
          </div>
          {/* <CoverageSummary /> */}
          </>
        );
    } else {
        return null;
    }
  }
  
export default TestCoverage;