import React from 'react';
import styles from './About.module.scss';
import { withTranslation } from 'react-i18next';

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return(
            <section className={styles.wrap}>
                <h2>About</h2>
                <div className={styles.versionWrap}>
                    <span className={styles.label}>Version: {process.env.REACT_APP_VERSION}</span>
                </div>
            </section>
        );
    }
}
export default withTranslation()(About);