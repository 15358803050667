import React from 'react';
import styles from './LabeledSelect.module.scss';
import { connect } from 'react-redux';
import classNames from 'classnames';
import InfoIcon from '../InfoIcon/InfoIcon';
import SelectAsync from 'src/Widgets/common/basicElements/SelectAsync/SelectAsync';

const LabeledSelect = ({ label, theme, isRequired, ...props }) => {

  const renderedLabel = label || props.placeholder;

  return (<div className={styles.wrap} style={props.style}>
    <span
      className={classNames(
        styles.label,
        { [theme.textPrimary]: isRequired }
      )}
    >
      {renderedLabel}{isRequired && renderedLabel && <span>*</span>}
      {props.info &&
        <InfoIcon style={{ paddingInlineStart: '.3rem' }} title={props.info} />
      }
    </span>
    <SelectAsync {...props} />
  </div>);
}

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(LabeledSelect);