import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Home.scss';
import '../../assets/animate.css';
import { withTranslation } from 'react-i18next';
import Button from '../../Widgets/common/basicElements/Button/Button';
import { dynamicConfig } from "src/config/dynamicConfig/dynamicConfig";
import { connect } from 'react-redux';
import { getWorkspaces, postWorkspace } from '../../redux/actions/actions.workspaces';
// import APISettings from 'src/API/APISettings';
import { createDefaultWorkspaces } from 'src/Widgets/common/helpers';

const { defaultWorkspaces } = dynamicConfig;
class Home extends React.Component{
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <article className="home-wrapper">
                <span className="backdrop">
                    <FontAwesomeIcon icon="cloud-sun" className="animated fadeIn sun" />
                </span>
                <h1 className="animated fadeIn hometitle">{this.props.t('Welcome')}</h1>
                <h2 className="animated fadeIn homesubtitle">
                    {this.props.t('Welcome description')}
                    {
                        this.props.workspaces && this.props.workspaces.length === 0 ?
                            <div>
                                {this.props.t('You can also use the default workspace and customize it to your needs')}: <Button
                                    type='secondary'
                                    onClick={async () => {
                                        await createDefaultWorkspaces(defaultWorkspaces);
                                        this.props.getWorkspaces();
                                        this.props.toggleMenu();
                                        // TODO: show message to user when done ?                                        
                                    }}
                                >
                                    {this.props.t('use default')}
                                </Button>
                            </div>
                            : null
                    }
                </h2>
            </article>
        )
    }
};

const mapStateToProps = (state) => ({ workspaces: state.workspaces });

export default connect(mapStateToProps, { getWorkspaces, postWorkspace })(withTranslation()(Home));