import GenericCalls from "src/API/genericCalls";
import languages from "./supportedLanguages";
import _ from 'lodash';

// requests go to eMM backend, then forwarded to google cloud translation api

class GoogleTranslate{
    static async translate(text, source_lang, target_lang) {
        if (GoogleTranslate.languages.includes(source_lang) && GoogleTranslate.languages.includes(target_lang)) {
            let res = await GenericCalls.post(
                `/api/translation/google/translate`,
                {
                    q: text,
                    source: source_lang,
                    target: target_lang,
                },
                // {
                //     contentType: 'application/json; charset=utf-8'
                // }
            )
            if (res && res.data && res.data.translations && res.data.translations[0]) {
                let rv = res.data.translations[0];
                rv.text = _.unescape(rv.translatedText);
                delete rv.translatedText;
                console.log(rv);
                return rv;
            } else {
                return res;
            }
        } else {
            return -1;
        }
    }

    static languages = languages;
}

export default GoogleTranslate;