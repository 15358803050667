import { useTranslation } from "react-i18next";
import styles from './WrongUser.module.scss';
import Link from "src/Widgets/common/basicElements/Link/Link";

function WrongUser({onChangeUser}) {
    const { t } = useTranslation();
    
    return (    
        <div>
            <h2 className={styles.h2}>{t('changing_user')}</h2>
            <p>
                {t('wrong_user_text')}
                {onChangeUser &&
                    <><p>
                        {`${t('wrong_user_really_change_user')} `}
                        <Link onClick={onChangeUser}>{t('session_expired_link_text')}</Link>
                        {`. ${t('changes_will_be_lost')}`}
                    </p>
                    </>
                }
            </p>
        </div>
    )
}

export default WrongUser;