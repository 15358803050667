import { FC,  useState } from 'react';
//@ts-ignore
import {Link} from 'react-router-dom'
import { OverviewClusterProps, NotificationId, ShowMoreState } from './OverviewCluster.types';
import styles from './OverviewCluster.module.scss';

import { faPlus, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VideoCard from './VideoCard/VideoCard';
import useChannelIcons from 'src/hooks/useChannelIcons';
import SentimentIconBox from './SentimentIconBox/SentimentIconBox';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import { useAppDispatch } from 'src/redux/hooks';
import { setSentimentResults } from 'src/redux/actions/actions.mentions';

const OverviewCluster: FC<OverviewClusterProps> = ({ cluster }) => {
    const { findChannelIconsById } = useChannelIcons();
    const [showMore, setShowMore] = useState<ShowMoreState>({
        positive: false,
        neutral: false,
        negative: false
    });

    const dispatch = useAppDispatch();

    const conditionalSentimentIcon = (sentiment: string, isOverlay?: string) => {
        const overlayClass = isOverlay ? '-' + isOverlay : '';

        const iconProps = {
            title: sentiment,
            className: !isOverlay ? styles[sentiment] : styles[sentiment + overlayClass],
            size: '2x' as const
        };

        switch (sentiment) {
            case 'positive':
            case 'neutral':
                return <FontAwesomeIcon {...iconProps} icon={faThumbsUp} />;
            case 'negative':
                return <FontAwesomeIcon {...iconProps} icon={faThumbsDown} />;
            default:
                return null;
        }
    };

    const itemsToRender = cluster.notification_ids.slice(1);

    const filterBySentiment = (sentiment: string) =>
        itemsToRender.filter((notif: NotificationId) => notif.sentiment === sentiment);

    const positiveSentiments = filterBySentiment('positive');
    const neutralSentiments = filterBySentiment('neutral');
    const negativeSentiments = filterBySentiment('negative');

    const handleLinkClick = (sentiment: string) => {
        const sentimentResults = cluster.notification_ids.filter(
            (notif: NotificationId) => notif.sentiment === sentiment
        );
        dispatch(setSentimentResults(sentimentResults));
    };

    //Calculate sentiments percentage for progress bar
    const calculateSentimentPercentage = (totalCount: number, sentimentCount: number): string => {
        return ((sentimentCount / totalCount) * 100).toFixed(2);
    };

    const showConditionalSentimentPercentage = (sentiment: string) => {
        const totalCount = positiveSentiments.length + negativeSentiments.length + neutralSentiments.length;
        switch(sentiment){
            case 'positive':
                return calculateSentimentPercentage(totalCount, positiveSentiments.length)
            case 'neutral':
                return calculateSentimentPercentage(totalCount, neutralSentiments.length)
            case 'negative':
                return calculateSentimentPercentage(totalCount, negativeSentiments.length)
             default:
                return null;           
        }
    }

    return (
        <div className={styles.overviewClusterContainer}>
            {!cluster ? <LoadingSpinner size='2rem'/> : <>
            <VideoCard
                icon={cluster.notification_ids[0]?.icon}
                cluster={cluster.notification_ids[0]}
                conditionalSentimentIcon={conditionalSentimentIcon}
                date={cluster.notification_ids[0].beginF}
            />
            <div className={styles.midWrapper}>
                <div className={styles.clusterHeading}>
                    <h4 data-testid="clusterHeading">{cluster.descriptive_title}</h4>
                    <p data-testid="clusterSummary">{cluster.cluster_summary}</p>
                </div>
                <div className={styles.iconsContainer}>
                    {['positive', 'neutral', 'negative'].map((sentiment) => {
                        const sentiments =
                            sentiment === 'positive'
                                ? positiveSentiments
                                : sentiment === 'neutral'
                                ? neutralSentiments
                                : negativeSentiments;
                        return (
                            sentiments.length > 0 && (
                                <div className={styles.sentimentsWrapper}>
                                <div
                                    key={sentiment}
                                    data-testid={sentiment + 'SentimentsContainer'}
                                    className={styles[sentiment + 'SentimentsContainer']}
                                >
                                    {sentiments
                                        .slice(
                                            0,
                                            showMore[sentiment] ? sentiments.length : 5
                                        )
                                        .map((notif: NotificationId, index: number) => {
                                            return (
                                                <SentimentIconBox
                                                    key={index}
                                                    notif={notif}
                                                    conditionalSentimentIcon={
                                                        conditionalSentimentIcon
                                                    }
                                                />
                                            );
                                        })}
                                    {sentiments.length > 4 && (
                                        <Link
                                            className={styles.showMoreButton}
                                            to={`/embed/mentionResults/${cluster.cluster_number}/${sentiment}`}
                                            onClick={() => handleLinkClick(sentiment)}
                                            
                                            /* onClick={() =>
                                                setShowMore((prevState) => ({
                                                    ...prevState,
                                                    [sentiment]: !prevState[sentiment]
                                                }))
                                            } */
                                        >
                                                <FontAwesomeIcon
                                                    size="1x"
                                                    icon={faPlus}
                                                />
                                        </Link>
                                    )}
                                </div>
                                <div className={styles[sentiment + 'ProgressBar']}>
                                    {showConditionalSentimentPercentage(sentiment)}
                                    %
                                </div>
                                </div>
                            )
                        );
                    })}
                </div>
            </div>
            </>}
        </div>
    );
};

export default OverviewCluster;
