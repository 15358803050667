import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import APIMe from './API/APIMe';

async function init() {
    const userLanguage = await APIMe.getLanguage();
    const fallbackLanguage = 'en';

    let translationsEN = await APIMe.getTranslation(fallbackLanguage);
    let languageCodesEN = await APIMe.getLanguages(fallbackLanguage);
    let translations = {};
    let languageCodes = {};
    if (userLanguage !== fallbackLanguage) {
        translations = await APIMe.getTranslation(userLanguage);
        languageCodes = await APIMe.getLanguages(userLanguage);
        if (
            translations.status !== undefined
            && !isNaN(translations.status)
            && translations.status !== 200
        ) {
            translations = {};
        }
        if (
            languageCodes.status !== undefined
            && !isNaN(languageCodes.status)
            && languageCodes.status !== 200
        ) {
            languageCodes = {};
        }
    }
    translations = {
        ...translationsEN,
        ...translations,
        ...languageCodesEN,
        ...languageCodes
    };
    // const userLanguage = getBrowserLanguage();

    // the translations
    let resources = {};
    resources[userLanguage] = {translation: translations };

    let options = {
        resources,
        lng: userLanguage,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        fallbackLng: fallbackLanguage,
    };

    if (['next', 'development'].includes(process.env.REACT_APP_ENV)) {
        options.overloadTranslationOptionHandler = (args) => {
            return { defaultValue: '__T__' + args[0] };
        }
    }
    else {
        // fill in english translations for missing keys in other languages:
        // options.fallbackLng = 'en';
        // NOTE: we would need to always
        // fetch en translations(even if lang.is another one) if we want to use this(APIMe.getTranslation('en') + always add it to options.resources array)
    }

    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init(options);
    /* NOTE:
        read here on how to find extract translation keys:
        https://react.i18next.com/guides/extracting-translations
        

        how to improve readability for translators (if this is an issue):
        https://react.i18next.com/latest/trans-component
    */
    
    document.dir = i18n.dir(); // automatically switching between ltr and rtl based on provided options.lng (e.g. arabic 'ar' --> rtl)
    // note: this adds 'dir="..." attribute to <body>'

    return i18n;
}

export default {init};
