//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { absToRelPath } from './helpers.coverage';
import styles from './TestCoverage.module.scss';

import { saveAs } from 'file-saver'; 
import genericCalls from '../API/genericCalls';

const prepAndSendCoverage = async (event: any, keepalive?: boolean) => {
    // @ts-ignore
    if (!window.__coverage__) { return }
    // @ts-ignore
    const coverageRel = absToRelPath(window.__coverage__, `${process.env.REACT_APP_BUILD_ROOT}/` ?? '');
    const res = await genericCalls.post('/_local-dev-api/coverage', coverageRel, { keepalive });
    if (res?.url) {
      window.open(res.url, 'coverage-report');
    }
    // TODO: save to actual backend after implemented (concatenate multiple coverage reports using nyc and generate report `yarn nyc report --reporter=html`)
    return res?.rc === 0
  }

function useTestCoverage() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pos, setPos] = useState('bottomLeft');
  
  const downloadJson = (obj:any) => {
    const str = JSON.stringify(obj);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
        type: "application/json;charset=utf-8"
    });
    const fileName = `test_coverage_${new Date()}.json`;
    saveAs(blob, fileName);
  }
  
    const renderOpen = () =>{
      return (
          <div className={styles.coverageSettings}>
            <div>
              Firefox prerequisits: type "about:config" into address bar and press enter. Set dom.require_user_interaction_for_beforeunload=false
            </div>
          <button disabled={isLoading} onClick={
            async(e) => {
              setIsLoading(true);
              const sendSuccess = await prepAndSendCoverage(e);
              setIsLoading(false);
            }
            }>{isLoading ? 'loading' : 'send'}</button>
            <button disabled={true} title='not implemented'>start</button>
            <button disabled={true} title='not implemented'>stop</button>
          <button onClick={() => {
            // @ts-ignore
            downloadJson(window.__coverage__)
          }}>download</button>
          </div>
      )
    }
  
    const renderCoverageDiv = () => {
      return (
        <>
              <span title={`${isOpen ? 'close' : 'open'} coverage report settings`} onClick={() => { setIsOpen(!isOpen) }} className={[styles.openBtn, styles.action].join(' ')}>Coverage</span>
          {pos === 'bottomLeft'
            ? <span title="move to bottom right" className={[styles.movePos, styles.action].join(' ')} onClick={() => { setPos('bottomRight') }}>{`>`}</span>
            : <span title="move to bottom left" className={[styles.movePos, styles.action].join(' ')} onClick={() => { setPos('bottomLeft') }}>{`<`}</span>}
        </>
        
        
      )
    }
    
    useEffect(() => {
      const beforeunload = async (event: any) => {
        // prepAndSendCoverage(event, true);
        const msg = "Test-coverage is enabled. Please do not navigate away from this page until you have clicked the 'save' button to send the coverage report. Navigate away now?";
        event.preventDefault()
        event.returnValue = msg;
        return msg;
      }
        if(process.env.REACT_APP_TESTCOVERAGE==='1'){
          // TODO: seems like this is not useful due to browser preventing send of api call here ? keepalive=true might fix it, but not supported in firefox yet?
          
            window.addEventListener("beforeunload", beforeunload);
    
          return () => {
            window.removeEventListener("beforeunload", beforeunload);
          }
        }
    }, []);
    
    return ({
        renderOpen,
        renderCoverageDiv,
        isOpen,
        pos
  }
  )
}

export default useTestCoverage