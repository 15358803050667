import { ChannelGroup, ChannelType } from "../types/types"

export const filterIdBySelectedTitle = (groupOption: any, groups: any) => {
    const filteredId = groups?.filter((group: any) => {
        if(group?.title === groupOption?.label){
            return group?.id
        }
    })
    return filteredId[0]?.id
}

export const lowerCaseSelectedChannels = (selectedChannels: any) => {
    return selectedChannels?.map((channel: any) => 
        Object.keys(channel).reduce((result, key) => {
            result[key.toLowerCase() as keyof typeof result] = channel[key] as never;
            return result;
        }, {})
    );
}

export const processChannels = (channelGroups: ChannelGroup[], selectedGroup: ChannelGroup, selectedChannels: ChannelType[]) => {
    const sortedChannelGroupTitles = channelGroups
        ?.sort((a: any, b: any) => a?.title.localeCompare(b?.title))

    const channelGroupTitle = sortedChannelGroupTitles?.map((group: any) => {
        return { label: group?.title, value: group?.title }
    })

    const channelIdsInGroup = selectedGroup.channels?.map((channel: any) => channel?.Id || channel?.id);

    const channelsNotInGroup = selectedChannels?.filter((channel) => !channelIdsInGroup?.includes(channel?.id || channel?.Id));

    const channelsWillBeRemovedInGroup = selectedChannels?.filter((channel) => {
        return !channelsNotInGroup?.some(
            (channelsNotInGroup) => channelsNotInGroup?.id === channel?.id
        );
    });

    return { channelsWillBeRemovedInGroup, channelGroupTitle };
}

export const findGroupChannelsInAllChannels = (groupChannels: ChannelType[], allChannels: ChannelType[]) => {
    const foundInAllChannels = allChannels.filter((channel:any) => (
        groupChannels?.some((groupChannel: any) => (
            channel.id === groupChannel.id
        ))
    ))
    return foundInAllChannels
}

export const getChannelIdByName = (channelName: string, allChannels: ChannelType[]) => {
    const foundChannel = allChannels.find((channel: any) => channel.name === channelName);
    return foundChannel ? foundChannel.id : null;
}

export const getGroupsByChannelId = (channelId: number | null, allGroups: ChannelGroup[]) => {
    const foundGroups = allGroups.filter((group: any) => 
        group.channels.some((channel: any) => channel.id === channelId)
    );
    return foundGroups;
}