import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/Widgets/common/basicElements/Button/Button";
import TextInput from "src/Widgets/common/basicElements/TextInput/TextInput";
import styles from './ChangePwInputs.module.scss';
import useChangePwInputs from "./useChangePwInputs";

interface ChangePwInputsProps{
    userId: string | null,
    onSave: (password: string) => {},
}

const ChangePwInputs = ({userId, onSave}:ChangePwInputsProps) => {
    const [password, setPassword] = useState<string|undefined>(undefined);
    const [password2, setPassword2] = useState<string|undefined>(undefined);
    const [pwIssue, setPwIssue] = useState('');
    const [inputsChanged, setInputsChanged] = useState(false);
    const { t } = useTranslation();

    const saveDisabled = pwIssue.length > 0 || !password || !password2 || !inputsChanged;
    const {onPwChange, onSaveBtnClick} = useChangePwInputs({setPwIssue, setInputsChanged, onSave});

    return (
        <><h1>{t('set_pw_headline')} <u>{userId}</u></h1>
            <p>{t('set_pw_instruction')}</p>
            <p className={styles.inputIssue} style={{height:'1rem'}}>{pwIssue}</p>
            <TextInput
                inputType="password"
                value={password}
                className={pwIssue ? styles.inputIssue : undefined}
                onChange={(e) => {
                    setPassword(e.target.value);
                    onPwChange(e.target.value, password2)
                }}
                // onKeyPress={e => e.key === 'Enter' && resetPw()}
                label={t('Password')}
                showPlaceholder={false}
            />
            <TextInput
                inputType="password"
                value={password2}
                className={pwIssue ? styles.inputIssue : undefined}
                onChange={(e) => {
                    setPassword2(e.target.value);
                    onPwChange(e.target.value, password)
                }}
                // onKeyPress={e => e.key === 'Enter' && resetPw()}
                label={t('repeat_password')}
                showPlaceholder={false}
            />
            <Button disabled={saveDisabled} onClick={()=>onSaveBtnClick(password)} type="secondary">{t('Save')}</Button>
        </>
    )
}

export default ChangePwInputs;