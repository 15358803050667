import { useEffect, useState } from "react";
import Quiz from "./Quiz";
import { QUERY_TYPE_SINGLE_CHOICE } from "src/_data/academy/consts";
import _ from 'lodash';
import { cacheBust, isRelativeUrl } from "../common/helpers";
import QuizContainerSettings from "./QuizContainerSettings";
import GenericCalls from "src/API/genericCalls";
import { connect } from "react-redux";
import { deleteQuiz, getQuizzes, postQuiz, putQuiz } from 'src/redux/actions/actions.quiz';
import { STATUS_SUCCEEDED } from "src/redux/types/status";
// import Button from "../common/basicElements/Button/Button";
import APIQuiz from "src/API/APIQuiz";
import { useTranslation } from "react-i18next";

// done since last 'all hands':
// - quiz widget
// - settings add enable academy toggle

// done since last:
// - admin mode:
    // - do not show widget settings/filters if no admin
    // - do not show "edit board" in acad mode - except for "admin" (admin = hardcoded userid?)
    // - place button to switch acad/v3 (below workspaces list)
// - onPageLoad: read url param academy=1 (and save to redux: board.isAcad=true|false) which does:
    // - show google login
    // - enables academy mode, no matter what is set in userSettings
// - settings: dont show 'reset workspaces' button in academy mode
// - avoid loading code from google before clicking button?
// - fetch/add workspaces/widgets from general settings instead of user settings

// TODO:
// academy general:
// - google auth:
    // - verify signup is working after bug 1554 has been discussed
    // (- immediately after Signup: set enableAcademy=true) not needed?
// [L] (- replace hardcoded academyAdmin in config.js - use backend resp instead. as soon as implemented)

// to be discussed: settings page. hided "reset workspaces" in acad mode for now. but maybe it should be completely different thing ?

function QuizContainer({
    state,
    saveWidgetState,
    workspaceId,
    widgetId,
    getQuizzes,
    postQuiz,
    putQuiz,
    quizzes,
    deleteQuiz,
}){
    const [url, setUrl] = useState(undefined);
    const [currQuestionIndex, setCurrQuestionIndex] = useState(-1);
    const [quizFinished, setQuizFinished] = useState(false);
    const [showQuestionResult, setShowQuestionResult] = useState(false);
    const [quiz, setQuiz] = useState(_.cloneDeep(undefined));
    const [quizSrc, setQuizSrc] = useState(undefined);
    const [answerIsCorrect, setAnswerIsCorrect] = useState(false);
    const question = quiz?.questions ? quiz?.questions[currQuestionIndex] : undefined;
    const [quizUserSettings, setQuizUserSettings] = useState(false);

    const { i18n } = useTranslation();

    useEffect(()=>{
        getQuizzes();
    },[quiz])

    const resetAll = ()=>{
        quizzes.data.map(q=>{
            deleteQuiz(q.id);
        })
    }

    useEffect(()=>{
        (async ()=>{
            if(quizzes.status === STATUS_SUCCEEDED && !quizUserSettings && quiz){
                let savedQuizUserSettings = _.find(quizzes.data, { quiz_id: quiz.id });
                
                if (savedQuizUserSettings) {
                    // display latest unanswered question / quiz finished page
                    let maxId = Math.max(...quiz?.questions.map(q => q.id));
                    // const allAnswered = quiz?.questions.every(q => savedQuizUserSettings.correctQuestionIds.includes(q.id));

                    
                    const correctQuestionIds = savedQuizUserSettings.correctQuestionIds
                        .filter(qId => _.findIndex(quiz.questions, { id: qId }) !== -1 ? true : false); // filter out answered questions which are not included in quiz anymore
                    let maxAnsweredId = correctQuestionIds.length > 0 ? Math.max(...correctQuestionIds) :  undefined;
                    const lastAnsweredIdx = _.findIndex(quiz.questions, {id: maxAnsweredId})
                    if(lastAnsweredIdx >= 0){
                        if(maxAnsweredId < maxId){
                            setCurrQuestionIndex(lastAnsweredIdx + 1);
                        } else {
                            setQuizFinished(true);
                        }
                    }
                }
                else {
                    // create quiz setting if not existing yet - for given quiz id
                    postQuiz({quiz_id: quiz.id, correctQuestionIds: []});
                }
                setQuizUserSettings(true);
            }
        })();

    },[quizzes])
    
    useEffect(() => {
        
        (async () => {
            const url = state.settings?.url;
            if (url) {
                let langUrl = url.replace(/{{LANG}}/, i18n.language);

                const finalUrl = isRelativeUrl(langUrl) ? cacheBust(`${langUrl}`) : langUrl;
                setUrl(url);
                const quiz = await APIQuiz.getQuiz(finalUrl);
                setQuiz(quiz);
                setQuizSrc(_.cloneDeep(quiz));
            }
        })();
    },[state.settings?.url]);


    const onAnswerChange = (index, value) => {
        setQuiz((s)=>{
            if(s.questions[currQuestionIndex].type === QUERY_TYPE_SINGLE_CHOICE){
                // single-choice --> reset all answers before adding current answer
                s.questions[currQuestionIndex].answers.map(a => { a.answer = undefined; return a; });
            }
            s.questions[currQuestionIndex].answers[index].answer = value;
            return {...s};
        })
    }

    const onShowResultOk = () => {
        setShowQuestionResult(false);
        if(answerIsCorrect){
            if( (currQuestionIndex+1) < quiz.questions.length){
                setCurrQuestionIndex(currQuestionIndex + 1);
            } else {
                setQuizFinished(true);
            }
        }
    }

    const checkAnswer = () => {
        setShowQuestionResult(true);
        const isCorrect = question.answers.every(a => {
            return (a.correct && a.answer) || (!a.correct && !a.answer)
        });
        setAnswerIsCorrect(isCorrect);

        if(isCorrect){
            const q = _.find(quizzes.data,{quiz_id:quiz.id});
            if(q && !q.correctQuestionIds.includes(question.id)){
                putQuiz({...q, correctQuestionIds:[...q.correctQuestionIds, question.id]});
            }
        }
    }

    return(
        <>
            {/* <Button type="primary" onClick={resetAll}>reset all</Button> */}
            {state.showSettings &&
                <QuizContainerSettings
                    init={{url}}
                    onSave={(data) => {
                        const {url} = data;
                        saveWidgetState(workspaceId, widgetId, { state, settings: {url} });
                        setUrl(url);
                    }}
                />
            }
            {quiz?.questions
                ? <Quiz
                    showStartPage={currQuestionIndex<0 && !quizFinished}
                    onStart={()=>{ setCurrQuestionIndex(0); }}
                    hasPrev={currQuestionIndex !== 0}
                    question={question}
                    onDoneClick={()=>{checkAnswer()}}
                    onPrevClick={()=>{ setCurrQuestionIndex(currQuestionIndex - 1); }}
                    showQuestionResult={showQuestionResult}
                    onShowResultOk={onShowResultOk}
                    onAnswerChange={onAnswerChange}
                    finished={quizFinished}
                    answerIsCorrect={answerIsCorrect}
                    restartQuiz={()=>{
                        setCurrQuestionIndex(0);
                        setQuizFinished(false);

                        // reset answers
                        setQuiz(quizSrc);   // TODO: not working all the time - or always but never on 2nd time ?
                    }}
                />
                : <div style={{margin:" 1rem"}}>
                    <h2>No valid quiz data provided</h2>
                    If you already provided an url, please check if it is correct and contains data in a valid "quiz data format".
                </div>
            }
        </>
    )
}

const mapStateToProps = state => ({
    schedules: state.schedules,
    me: state.me,
    quizzes: state.quizzes
});
export default connect(mapStateToProps,{ 
    getQuizzes,
    postQuiz,
    putQuiz,
    deleteQuiz
 })(QuizContainer);
