import { useEffect, useRef, useState } from 'react';
import styles from './EPGContent.module.scss';
import '../../../assets/animate.css';
import IFrameWrapper from '../../common/basicElements/IFrameWrapper/IFrameWrapper';
import { dateToString } from '../../common/helpers';
import { useTranslation } from 'react-i18next';
import EPGRow from '../EPGRow/EPGRow';
import { Content } from './EPGContent.types';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';

interface EPGContentProps{
    content: any,
    iFrameUrl: string,
    changeIFrameUrl: Function,
    onEpgClick: Function,
    channelSelected: boolean,
    isLoading: boolean
}

const EPGContent = ({content, iFrameUrl, changeIFrameUrl, onEpgClick, channelSelected, isLoading}:EPGContentProps) => {
    const [currentlyOpen, setCurrentlyOpen] = useState<number>(-1)
    const {t} = useTranslation()
    const prevContentRef = useRef(content);

    useEffect(() => {
        if (JSON.stringify(prevContentRef.current) !== JSON.stringify(content)) {
            setCurrentlyOpen(-1);
        }

        prevContentRef.current = content;
    }, [content]);

    const onUnfold = async (index: number) => {
        setCurrentlyOpen(prevOpen => prevOpen === index ? -1 : index);
    }

    const renderContentEmpty = (text:string|JSX.Element) => {
        return (
            <div className={`${styles.epgContent} ${styles.epgContentEmpty}`}>
                {text}
                {/* {t('Please select channel or search for program!')} */}
            </div>
        );
    }

    if (isLoading) {
        return renderContentEmpty(<LoadingSpinner />);
    }

    if (!channelSelected) {
        return renderContentEmpty(t('Please select a channel!'));
    }

    if (channelSelected && !isLoading && content.length === 0) {
        return renderContentEmpty(t('No data available for the selected date and channel.'));
    }

    if (iFrameUrl) {
        return (
            <IFrameWrapper
                url={iFrameUrl}
                onGoBack={() => changeIFrameUrl(null)}
                title={t("Media View")}
            />
        );
    }

    return (
        <div className={styles.epgContent}>
            {content?.map((content: Content, index: number) => (
                <EPGRow
                    key={index}
                    onUnfold={() =>
                        onUnfold(index)
                    }
                    isOpen={index === currentlyOpen}
                    availability={content.available}
                    date={dateToString(content.startDate)}
                    start={content.startTime}
                    end={content.endTime}
                    titleProp={content.title}
                    genre={content.genre}
                    description={content.description}
                    onClick={
                        content.available.statusCode !== "N"
                            ? () => { onEpgClick(content.channelId, content.channelName, content.startTS, content.title) }
                            : undefined
                    }
                />
            ))}
        </div>
    );
}

export default EPGContent;
