import { useTranslation } from "react-i18next";
import Link from "src/Widgets/common/basicElements/Link/Link";
import styles from './AuthExpired.module.scss';
import { useSelector } from "react-redux";
import WrongUser from "../WrongUser/WrongUser";

function AuthExpired({
    onChangeUser
}) {
    const { t } = useTranslation();
    const isWrongUser = useSelector((state) => state?.auth?.session?.isWrongUser);
    
    return (
        <div>
            {isWrongUser
                ? <WrongUser onChangeUser={onChangeUser} />
                : <>
                    <h2 className={styles.h2}>{t('session_expired')}</h2>
                    <p>
                        {t('session_expired_text')}
                    </p>
                    {onChangeUser &&
                        <p>
                            {t('session_expired_switch_user_text') + ' '}
                            <Link onClick={onChangeUser}>{t('session_expired_link_text')}</Link>
                            {'. ' + t('note_changes_will_be_lost')}
                        </p>
                    }
                </>
            }
        </div>
    )
}

export default AuthExpired;