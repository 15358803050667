import React from 'react';
import styles from './TitlePage.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import TextInput from '../common/basicElements/TextInput/TextInput';
import Button from '../common/basicElements/Button/Button';
import { getWidgetState } from '../common/helpers';

class TitlePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            edited: false,
        };
    }

    async componentDidMount() {
        const res = await getWidgetState(this.props.workspaces, this.props.board, this.props.widgetId);
        if (res) {
            this.setState({ title: res.title });
        }
    }

    onChange = (e) => {
        this.setState({ title: e.target.value, edited: true })
    }
    onSave = async() => {
        await this.props.saveWidgetState(this.props.workspaceId, this.props.widgetId, { title: this.state.title });
        this.setState({ edited: false });
    }

    render() {
        const { t } = this.props;
        const { edited } = this.state;
        return (
            <div className={styles.wrapper}>
                <TextInput
                    value={this.state.title}
                    onChange={this.onChange}
                    placeholder={this.props.placeholder || t('Title')}
                    className={styles.textInput}
                    inputClassName={styles.input}
                />
                {
                    <Button
                        disabled={!edited}
                        onClick={this.onSave}
                        type='primary'
                    >{this.props.t('Save')}</Button>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    workspaces: state.workspaces,
    board: state.board,
});
export default connect(mapStateToProps,{ /* insert redux actions here */ })(withTranslation()(TitlePage));