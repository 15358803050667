import { useState, useEffect, useCallback, useMemo, memo, useRef } from 'react';
import styles from './FilterBoxContainer.module.scss';
import Checkbox from 'src/Widgets/common/basicElements/Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { VariableSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import TableFilter from '../../shared/TableFilter/TableFilter';
import {
    searchChannels,
    selectSortType,
    setCheckboxFilters,
    setFilterColorValues,
    setFilterValues,
    showAllChannels,
    toggleFilter
} from 'src/redux/actions/actions.channelGroupsWidget';
import { useTranslation } from 'react-i18next';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';

const FilterBoxContainer = (props) => {
    const { checkedValues, setCheckedValues, setInputDataArray, inputDataArray, setAllSelected } = props;
    const filterValues = new Set([]);
    const [changeHistory, setChangeHistory] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [prevInputLen, setPrevInputLen] = useState(0);
    const listRef = useRef();
    const [filteredByCheckedKeysChannels, setFilteredByCheckedKeysChannels] = useState(
        []
    );
    const {
        filteredChannels,
        filterColorValues,
        filterBoxIndex,
        allChannelsShown,
        selectedGroupId,
        selectedFilter,
        selectedChannels,
        selectedButtonStatus,
        filterValuesArray,
        checkboxFilters
    } = useSelector((state) => state.channelGroupsWidgetReducer);

    const dispatch = useDispatch();
    const allChannels = useSelector((state) => state.channelReducers);

    const { t } = useTranslation();

    const isMediumScreen = useIsScreenSize(1750)

    const lowerCaseFilter = selectedFilter?.toLowerCase();
    const channels =
        selectedButtonStatus && selectedChannels?.length
            ? selectedChannels
            : filteredChannels || allChannels?.data;

    //Filtering function
    const getChannelsToFilter = (updatedCheckedValues) => {
        const selectedFiltersByFilterBox = Object.keys(updatedCheckedValues)
            .map((filterBoxKey) =>
                Object.keys(updatedCheckedValues[filterBoxKey]).filter(
                    (key) => key !== 'all' && updatedCheckedValues[filterBoxKey][key]
                )
            )
            .filter((f) => f.length !== 0); // exclude empty filter boxes
        const filteredChannels = channels?.filter((channel) => {
            const values = Object.values(channel);
            // If all selected filters are from the same filter box
            if (selectedFiltersByFilterBox.length === 1) {
                // Check if the channel includes any selected filter
                return selectedFiltersByFilterBox[0].some((filter) =>
                    values.includes(filter)
                );
            } else {
                // If selected filters are from different filter boxes
                // Check if the channel includes all selected filters
                return selectedFiltersByFilterBox.every((filterBox) =>
                    filterBox.some((filter) => values.includes(filter))
                );
            }
        });
        return filteredChannels.length > 0 ? filteredChannels : [''];
    };

    // Set search inputdata for every filterboxcontainer
    const setInputData = useCallback(
        (inputData) => {
            setInputDataArray((prev) => {
                const currentFilterBoxData = prev.find(
                    (item) => item.filterBoxIndex === filterBoxIndex
                );
                if (currentFilterBoxData) {
                    // Update the inputData of the current filterBoxIndex
                    return prev.map((item) =>
                        item.filterBoxIndex === filterBoxIndex
                            ? { ...item, inputData }
                            : item
                    );
                } else {
                    // Add a new item to the array
                    return [...prev, { filterBoxIndex, inputData }];
                }
            });
        },
        [setInputDataArray, filterBoxIndex]
    );

    // Extract the inputData for the current filterBoxIndex
    const inputData = useMemo(() => {
        const currentFilterBoxData = inputDataArray?.find(
            (item) => item.filterBoxIndex === filterBoxIndex
        );
        return currentFilterBoxData ? currentFilterBoxData.inputData : '';
    }, [inputDataArray, filterBoxIndex]);

    //Checkbox filter by check function
    const checkBoxFilterHandler = useCallback((filterValue) => {
        setAllSelected(false)
        const updatedCheckedValues = [...checkedValues];
        if (!updatedCheckedValues[filterBoxIndex])
            updatedCheckedValues[filterBoxIndex] = {};
        updatedCheckedValues[filterBoxIndex][filterValue] =
            !checkedValues[filterBoxIndex]?.[filterValue];
        updatedCheckedValues[filterBoxIndex].all = false; // uncheck "All" checkbox

        const change = {
            index: filterBoxIndex,
            value: filterValue,
            newState: updatedCheckedValues[filterBoxIndex][filterValue]
        };
        setChangeHistory([...changeHistory, change]);

        const allSelectedFilters = Object.keys(updatedCheckedValues).flatMap(
            (filterBoxKey) =>
                Object.keys(updatedCheckedValues[filterBoxKey]).filter(
                    (key) => key !== 'all' && updatedCheckedValues[filterBoxKey][key]
                )
        );

        const channelsToFilter = getChannelsToFilter(updatedCheckedValues);

        // Add or remove checked key from checkedKeys
        const key = `${filterBoxIndex}_${filterValue}`;
        let updatedCheckedKeys;
        if (checkedKeys.includes(key)) {
            updatedCheckedKeys = checkedKeys.filter((k) => k !== key);
        } else {
            updatedCheckedKeys = [...checkedKeys, key];
        }

        // Check if all values in all filterBoxContainers are false
        const allFalse = Object.values(updatedCheckedValues).every(filterBoxContainer =>
          Object.values(filterBoxContainer).every(val => val === false)
        );

        let updatedFilterValues = allFalse ? [] : channelsToFilter;
        allFalse && dispatch(showAllChannels(true))
        setCheckedValues(updatedCheckedValues);
        dispatch(setCheckboxFilters(allSelectedFilters));
        setFilteredByCheckedKeysChannels(channelsToFilter);
        setCheckedKeys(updatedCheckedKeys);
        dispatch(setFilterValues(updatedFilterValues));
    }, [checkedValues, channels, selectedFilter, allChannelsShown, dispatch, filterBoxIndex, lowerCaseFilter]);

    //Show filtered results on table
    useEffect(() => {
        dispatch(searchChannels(filteredByCheckedKeysChannels));
    }, [filteredByCheckedKeysChannels, filterValuesArray, dispatch]);

    // All Checkbox Behaviour
    const handleAllFilterClick = () => {
        setCheckedValues((prevCheckedValues) => {
            const updatedCheckedValues = [...prevCheckedValues];
            const isAllChecked = !updatedCheckedValues[filterBoxIndex]?.all;
            // If "all" is going to be checked, check all other checkboxes.
            // Otherwise, uncheck all checkboxes.
            filteredCheckboxes.forEach((checkbox) => {
                if (updatedCheckedValues[filterBoxIndex]) {
                    updatedCheckedValues[filterBoxIndex][checkbox.key] = isAllChecked;
                } else {
                    updatedCheckedValues[filterBoxIndex] = {
                        [checkbox.key]: isAllChecked
                    };
                }
            });
            updatedCheckedValues[filterBoxIndex].all = isAllChecked;
            
            const allCheckboxes = [{ key: 'all' }, ...filteredCheckboxes];

        allCheckboxes.forEach((checkbox) => {
            if (updatedCheckedValues[filterBoxIndex]) {
                updatedCheckedValues[filterBoxIndex][checkbox.key] = isAllChecked;
            } else {
                updatedCheckedValues[filterBoxIndex] = {
                    [checkbox.key]: isAllChecked
                };
            }
        });

        const changes = allCheckboxes.map(checkbox => ({
            index: filterBoxIndex,
            value: checkbox.key,
            newState: isAllChecked
        }));
        setChangeHistory(prevChangeHistory => [...prevChangeHistory, ...changes]);
            
            const allSelectedFilters = Object.keys(updatedCheckedValues).flatMap(
                (filterBoxKey) =>
                    Object.keys(updatedCheckedValues[filterBoxKey]).filter(
                        (key) =>
                            key !== 'all' && updatedCheckedValues[filterBoxIndex][key]
                    )
            );
            const channelsToFilter = getChannelsToFilter(updatedCheckedValues);
            // Check if all values in checkedValues are false
            const allFalse = Object.entries(updatedCheckedValues[filterBoxIndex]).every(
                ([key, val]) => (key === 'all' ? true : val === false)
            );
            const newFilterValues = allFalse
                ? filteredByCheckedKeysChannels
                : channelsToFilter;
            const newSortType = '';
            dispatch(setFilterValues(newFilterValues));
            dispatch(setCheckboxFilters(allSelectedFilters));
            dispatch(selectSortType(newSortType));
            setFilteredByCheckedKeysChannels(channelsToFilter);
            return updatedCheckedValues;
        });
    };

    //Enter - ESC keys functions
    const handleKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
                setTimeout(() => {
                    dispatch(toggleFilter(false));
                }, 0);
                break;
            case 'Escape':
                initializeFilterCheckboxValues();
                clearFilterHandler();
                break;
            default:
                break;
        }
    };

    //Event listeners
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        // Event listener cleanup
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

       const checkboxDataMap = useMemo(() => {
        const newCheckboxDataMap = [];
        const uniqueValues = new Set();
        const channelValues = Object.values(channels || {});
    
        channelValues.forEach((channel) => {
            const lowerCaseValue = channel[lowerCaseFilter];
            const selectedValue = channel[selectedFilter];
            const value =
                (allChannelsShown || selectedChannels?.length) &&
                (!selectedGroupId || selectedButtonStatus)
                    ? lowerCaseValue
                    : selectedValue;
            if (value && !uniqueValues.has(value)) {
                uniqueValues.add(value);
                newCheckboxDataMap.push({
                    [filterBoxIndex]: { key: value, value: value }
                });
            }
        });

        return newCheckboxDataMap;
    }, []);

    useEffect(() => {
      dispatch(setCheckboxFilters(Array.from(new Set(checkboxDataMap.map(item => item[filterBoxIndex].value)))));
    }, [checkboxDataMap, dispatch]);
    
    // Pre-calculate the lower-cased search string
    const filteredCheckboxes = useMemo(() => {
        const lowerCasedInputData = inputData.toLowerCase();
        return checkboxDataMap
            .reduce((result, checkbox) => {
                const value = checkbox?.[filterBoxIndex]?.value;
                // If checkbox or value is undefined, ignore this checkbox
                if (!value) return result;
                // Check if checkbox should be included
                if (!value.toLowerCase().includes(lowerCasedInputData)) return result;
                result.push({
                    key: checkbox[filterBoxIndex].key,
                    value,
                    checked:
                        checkedValues[filterBoxIndex]?.[checkbox[filterBoxIndex]?.key]
                });
                return result;
            }, [])
            .sort((a, b) => {
                const textA = a.value.toUpperCase();
                const textB = b.value.toUpperCase();
                if (textA < textB) return -1;
                if (textA > textB) return 1;
                return 0;
            });
    }, [inputData, checkboxDataMap, checkedValues, filterBoxIndex]);

    // Checkbox handler in useCallback to prevent unnecessary rerenders
    const handleCheckboxClick = useCallback(
        (key) => () => checkBoxFilterHandler(key),
        [checkBoxFilterHandler]
    );

    // Create the JSX elements for react-window
    const CheckboxItem = memo(({ index, style }) => {
        const checkbox = filteredCheckboxes[index];
        const classNames = {
            wrapper: styles.wrapper
        };
        return (
            <div style={{ ...style, display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    key={checkbox.key}
                    text={checkbox.value}
                    withBorder={true}
                    checked={checkbox.checked}
                    onClick={handleCheckboxClick(checkbox.key)}
                    classNames={classNames}
                />
                <span className={styles.checkmark}></span>
            </div>
        );
    }, areEqual);

    //React-window variable item row size calculation
    const getItemSize = useCallback(
        (index) => {
            const checkbox = filteredCheckboxes[index];
            const numberOfCharacters = checkbox.value.length;
            const numberOfLines = Math.ceil(numberOfCharacters / 25);
            const lineHeight = 25;
            const padding = numberOfCharacters > 13 ? 10 : 5;
            const rowHeight = numberOfLines * lineHeight + padding;
            return rowHeight;
        },
        [filteredCheckboxes]
    );

    // React-window list element creation
    const checkboxesJSX = (
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemCount={filteredCheckboxes.length}
              itemSize={getItemSize}
              width={width}
              itemData={filteredCheckboxes}
              ref={listRef}
            >
              {CheckboxItem}
            </List>
          )}
        </AutoSizer>
      );

      useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0, true);
        }
    }, [filteredCheckboxes]);

    //Allcheckbox persist when there is searched data && when you erase input uncheck "All"
    useEffect(() => {
        const allChecked = filteredCheckboxes.every((checkbox) => checkbox.checked);
        if (
            inputData.length !== prevInputLen &&
            checkedValues[filterBoxIndex].all === true &&
            !allChecked
        ) {
            const updatedCheckedValues = [...checkedValues];
            updatedCheckedValues[filterBoxIndex] = {
                ...updatedCheckedValues[filterBoxIndex],
                all: false
            };
            setCheckedValues(updatedCheckedValues);
        }
        setPrevInputLen(inputData.length);
    }, [inputData]);

    //Color indicator changer
    useEffect(() => {
        const newColorValues = Object.keys(checkedValues).map((key, index) => {
            const filterBox = checkedValues[key];
            const hasCheckedValue = Object.entries(filterBox).some(
                ([valueKey, value]) => value && valueKey !== 'all'
            );
            const colorCondition = hasCheckedValue ? '#0E65E8' : '#000000';
            return index === filterBoxIndex ? colorCondition : filterColorValues?.[index];
        });

        dispatch(setFilterColorValues(newColorValues));
        // Check if all values in checkedValues are true
        const allTrue = Object.values(checkedValues).every(
            (filterBox) => filterBox.all === true
        );
        //Empty filter values array
        if (allTrue) {
            dispatch(setFilterValues([]));
        }
    }, [checkedValues, filterBoxIndex]);

    //Clear button and showall button checked values clear when all indicator colors black
    useEffect(() => {
        if (filterColorValues?.every((color) => color === '#000000')) {
            const newCheckedValues = [...checkedValues];
            if (checkboxFilters) {
                const filtersArray = Array.from(filterValues);
                filtersArray.forEach((filterValue) => {
                    newCheckedValues[filterBoxIndex] = {
                        ...newCheckedValues[filterBoxIndex],
                        [filterValue]: false,
                        all: false
                    };
                });
            }
    
            // set all checkboxes in every filterboxcontainer to false
            newCheckedValues.forEach((value, index) => {
                newCheckedValues[index] = Object.keys(newCheckedValues[index]).reduce((acc, curr) => {
                    acc[curr] = false;
                    return acc;
                }, {});
            });
    
            if (JSON.stringify(newCheckedValues) !== JSON.stringify(checkedValues)) {
                setCheckedValues(newCheckedValues);
            }
            //when all indicators black, clear searchInput
            inputData?.length && setInputData('');
        }
    }, [filterColorValues]);

    //Clear filter handler
    const clearFilterHandler = () => {
        let revertedCheckedValues = [...checkedValues];

        // Traverse the changeHistory in reverse order and revert each change
        for (let i = changeHistory.length - 1; i >= 0; i--) {
            const change = changeHistory[i];
            revertedCheckedValues[change.index][change.value] = !change.newState;
        }

        setCheckedValues(revertedCheckedValues);
        setChangeHistory([]);
        const channelsToFilter = getChannelsToFilter(revertedCheckedValues);
        setFilteredByCheckedKeysChannels(channelsToFilter);
        dispatch(selectSortType(''));
        setTimeout(() => {
            dispatch(toggleFilter(false));
        }, 0);
        const allFalse = Object.values(revertedCheckedValues).every(filterBoxContainer =>
            Object.values(filterBoxContainer).every(val => val === false)
        );
        const newFilterValues = allFalse
                ? []
                : channelsToFilter;
        dispatch(setFilterValues(newFilterValues));
    };

    //Initialize checkboxes when its first opened
    const initializeFilterCheckboxValues = () => {
        const filtersArray = Array.from(filterValues);
        const updatedCheckedValues = [...checkedValues];
        const filterBox = checkedValues[filterBoxIndex] || {};
        const hasCheckedValue = Object.entries(filterBox).some(
            ([valueKey, value]) => value && valueKey !== 'all'
        );
        filtersArray.forEach((filterValue) => {
            updatedCheckedValues[filterBoxIndex] = {
                ...updatedCheckedValues[filterBoxIndex],
                [filterValue]: false
            };
        });

        !hasCheckedValue && setCheckedValues(updatedCheckedValues);
    };

    useEffect(() => {
        initializeFilterCheckboxValues();
    }, []);

    return (
        <div
            className={`${styles.filterboxContainer} ${
                filterBoxIndex === filterColorValues?.length - 1 && isMediumScreen && styles.lastBoxPosition
            }`}
        >
            <TableFilter
                checkedValues={checkedValues[filterBoxIndex] || {}}
                clearFilterFunction={clearFilterHandler}
                handleAllFunction={handleAllFilterClick}
                filterData={
                    filteredCheckboxes.length > 0
                        ? checkboxesJSX
                        : !filteredCheckboxes.length && <p>{t('No filters found')}</p>
                }
                setInputData={setInputData}
                inputData={inputData}
            />
        </div>
    );
};

export default FilterBoxContainer;
