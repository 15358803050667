import { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './GenericTableHover.module.scss';
import useChannelExistsInGroup from 'src/Widgets/ChannelGroupsNext/hooks/useChannelExistsInGroup';
import { ChannelGroup } from 'src/Widgets/ChannelGroupsNext/types/types';
import { useTranslation } from 'react-i18next';

const GenericTableHover = ({data}: any) => {
  const [showInfo, setShowInfo] = useState(false);

  const {groupsInfo} = useChannelExistsInGroup(data);

  const {t} = useTranslation();

  return (
    <div>
      <FontAwesomeIcon 
        data-testid="img"
        icon={faInfoCircle} 
        onMouseEnter={() => setShowInfo(true)}
        onMouseLeave={() => setShowInfo(false)}
        className={styles.infoIcon}
      />
      {showInfo && <div className={styles.infoBox} data-testid="tooltip">
      <span>{t("Included In")}</span>
        {groupsInfo.map((item: ChannelGroup, index: number) => (
            <div key={item.id} className={styles.infoItem} data-testid="groupItems">
                {item.title}
            </div>
        ))}
        </div>}
    </div>
  );
};

export default GenericTableHover;