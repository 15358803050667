import React from 'react';
// import PropTypes from 'prop-types';
import WidgetSettings from 'src/Widgets/common/WidgetSettings/WidgetSettings';
import APIReporting from 'src/API/APIReporting';
import PieChart from 'src/Widgets/common/Charts/PieChart';
import { withTranslation } from 'react-i18next';
import { ResponsiveContainer } from 'recharts';
import _ from 'lodash';
import BarChart from '../../Charts/BarChart';
// import LineChart from '../../Charts/LineChart';
import LoadingSpinner from '../../basicElements/LoadingSpinner/LoadingSpinner';
import styles from './ReportElement.module.scss';
import { connect } from 'react-redux';
import Map from '../../Charts/Map';
// import Table from 'src/Widgets/Statistics/Table/Table';
import ReactTable from 'src/Widgets/common/ReactTable/ReactTable';
import { scaleMax, sleep } from 'src/Widgets/common/helpers.js';
import ReChart from '../../Charts/ReChart';
import * as actionCreators from 'src/redux/actions/actions.workspaces';
import { getFakeChartData } from './ReportElementFuncts';

class ReportElement extends React.Component {
    static propTypes = {
        // vizType: PropTypes.string.isRequired,   // type of visualization, e.g. bar, line, pie, ...
        // uiFilters: PropTypes.array  // filters that can be seen and adjusted within the widget and/or workspace
    };

    constructor(props) {
        super(props);
        this.state = {
            data: undefined,
            settings: {},
            loading: false,
            filtersLoadLater: undefined
        };

        this.getData = this.getData.bind(this);
        this.isProfileFromSearchGroup = this.isProfileFromSearchGroup.bind(this);
        this.isCreatedBy = this.isCreatedBy.bind(this);
    }

    onReceiveChartData(res) {
        const display = {
            display_from: res.filterSettings.display_from,
            display_to: res.filterSettings.display_to
        };
        let workspace = _.cloneDeep(
            _.find(this.props.workspaces, { id: this.props.workspaceId })
        );
        let state = _.find(workspace.widgets, { id: this.props.widgetId }).state;

        if (state.settings && state.settings.date && state.settings.date.unit) {
            // store display date from backend response
            let wiDate = state.settings.date;
            wiDate.display_from = display.display_from;
            wiDate.display_to = display.display_to;

            if (
                workspace.state &&
                workspace.state.settings &&
                workspace.state.settings.date
            ) {
                // store display date from backend response to workspace settings
                let woDate = workspace.state.settings.date;
                woDate.display_from = display.display_from;
                woDate.display_to = display.display_to;
            }
        }
        this.props.updateWorkspace(workspace);
    }

    isProfileFromSearchGroup(profileID) {
        const { profileGroups } = this.props;
        const searchGroup = _.find(profileGroups, { title: '[Recent Searches]' });
        if (searchGroup) {
            if (searchGroup.profiles.find((p) => p.id === profileID)) {
                return true;
            } else {
                return false;
            }
        }
    }

    //find if workspaces has createdBy property
    isCreatedBy() {
        const { workspaces, workspaceId } = this.props;
        const workspace = _.find(workspaces, { id: workspaceId });
        if (workspace && workspace.createdBy) {
            return workspace.createdBy;
        } else {
            return false;
        }
    }

    async getData(filters, clipsType = 'recent') {
        // const workspace = _.find(this.props.workspaces, { id: this.props.workspaceId });
        // if (
        //     filters.profile && this.props.settings['profile']
        //     || filters.profileGroup && this.props.settings['profileGroup']
        // ) {
        //     this.setState({ loading: true });
        // }
        // if(this.props.waitLoading !== undefined){
        //     if (_.find(workspace.widgets, { id: this.props.widgetId })._local.status === 'done') {
        //         this.props.setWidgetStatus(this.props.workspaceId, this.props.widgetId, undefined);
        //     }
        //     if (this.props.waitLoading) {
        //         this.props.setWidgetStatus(this.props.workspaceId, this.props.widgetId, 'waitLoading');
        //         this.setState({ filtersLoadLater: filters });
        //         return;
        //     }
        // }
        const { dataSource, groupBy, vizSettings, select } = this.props;
        if (filters.profileGroup) {
            this.setState({ loading: false });
            let stateData = _.cloneDeep(this.state.data);

            stateData = {
                header: [],
                data: []
            };

            if (vizSettings && vizSettings.columns) {
                stateData.header = [
                    {
                        Header: 'Profile Group',
                        accessor: 'group'
                    },
                    ...vizSettings.columns
                ];
            }

            const profileGroups = this.props.profileGroups;
            //(await APIProfileGroups.get()).data

            filters.profileGroup.map((pgId) => {
                const pg = _.find(profileGroups, { id: pgId });
                if (pg) {
                    stateData.data.push({
                        data: { group: pg.title },
                        id: pgId,
                        group: pg.title,
                        progress: 0
                    });
                }
                return undefined;
            });
            this.setState({ data: stateData });

            for (const element of filters.profileGroup) {
                let pgId = element;

                // await Promise.all(await filters.profileGroup.map( async (pgId) => {
                const pg = _.find(profileGroups, { id: pgId });
                let pgCount = 0;
                if (pg) {
                    await Promise.all(
                        pg.profiles.map(async (profile) => {
                            let res = await APIReporting.getChartData(
                                dataSource.type,
                                groupBy,
                                this.isProfileFromSearchGroup(profile.id)
                                    ? {
                                          ...filters,
                                          profile: [
                                              this.isCreatedBy() === 'search' && clipsType !== 'edited'
                                                  ? profile.id + 't'
                                                    :
                                                this.isCreatedBy() === 'search' && clipsType === 'edited'
                                                    ? profile.id + 'e'
                                                  : this.isCreatedBy() === 'edited' || clipsType === 'edited'
                                                  ? profile.id + 'e'
                                                  : profile.id
                                          ]
                                      }
                                    : {
                                          ...filters,
                                          profile: [profile.id],
                                          clipsType: clipsType
                                      },
                                select
                            );
                            this.onReceiveChartData(res);

                            let data = res.data;
                            pgCount++;

                            const rowIndex = _.findIndex(stateData.data, { id: pgId });
                            let row = stateData.data[rowIndex];
                            row.progress = Math.round(
                                (pgCount / pg.profiles.length) * 100
                            );
                            data.map((d) => {
                                Object.keys(d).forEach((key) => {
                                    if (!['name'].includes(key)) {
                                        if (!vizSettings || !vizSettings.columns) {
                                            stateData.header[`${d.name} ${key}`] =
                                                undefined;
                                        }
                                        if (row.data[d.name] === undefined) {
                                            row.data[d.name] = {};
                                        }
                                        row.data[d.name][key] =
                                            (row.data[d.name][key] || 0) + d[key];
                                    }
                                    return undefined;
                                });
                                return undefined;
                            });
                            this.setState({
                                data: _.cloneDeep(stateData)
                            });
                        })
                    );
                }
            }
        } else if (filters.profile) {
            // TODO: should be generic for different filters ?
            this.setState({ loading: true });

            const res = await APIReporting.getChartData(
                dataSource.type,
                groupBy,
                //if is profile from search group, then add profile to filters
                this.isProfileFromSearchGroup(filters.profile[0])
                    ? {
                          ...filters,
                          profile: filters.profile.map((profile) =>
                             
                            this.isCreatedBy() === 'search' && clipsType !== 'edited'
                            ? profile + 't'
                              :
                          this.isCreatedBy() === 'search' && clipsType === 'edited'
                              ? profile + 'e'
                            : this.isCreatedBy() === 'edited' || clipsType === 'edited'
                            ? profile + 'e'
                            : profile
                          )
                      }
                    : { ...filters, clipsType: clipsType },
                select
            );

            this.onReceiveChartData(res);
            this.setState({ data: res.data, loading: false });
        } else {
            this.setState({ data: undefined });
        }
        // if (this.props.waitLoading !== undefined) {
        //     this.props.setWidgetStatus(this.props.workspaceId, this.props.widgetId, 'done');
        // }
    }

    /*      async componentDidUpdate(prevProps, prevState) {
        // if (!_.isEqual(this.props.waitLoading, prevProps.waitLoading)) {
        //     if (this.state.filtersLoadLater) {
        //         this.getData(this.state.filtersLoadLater);
        //     }
        //     this.setState({ filtersLoadLater: undefined });
        // }

        
    }

    async componentDidMount() {
    
    } */

    renderVisualization(data) {
        const { vizType, vizSettings, select } = this.props;
        let rv;
        switch (vizType) {
            case 'pie':
                rv = (
                    <ReChart>
                        <PieChart
                            dataKey={select[0]}
                            data={data}
                            percent={vizSettings && vizSettings.percent}
                        />
                    </ReChart>
                );
                break;
            case 'donut':
                rv = (
                    <ReChart>
                        <PieChart
                            dataKey={select[0]}
                            data={data}
                            type="donut"
                            percent={vizSettings && vizSettings.percent}
                        />
                    </ReChart>
                );
                break;
            case 'bar':
                rv = (
                    <ReChart>
                        <BarChart
                            stacked={vizSettings.stacked}
                            data={data}
                            percent={vizSettings.percent}
                            xLabel={vizSettings.xLabel}
                            yLabel={vizSettings.yLabel}
                            // showValues={vizSettings.showValues}
                        />
                    </ReChart>
                );
                break;
            // case 'line':
            //     rv = <LineChart
            //         data={data}
            //         xLabel={vizSettings.xLabel}
            //         yLabel={vizSettings.yLabel}/>
            //     break;
            case 'map':
                rv = (
                    <Map
                        allowZoom={!this.props.keys || this.props.keys['Shift']}
                        data={data.map((d) => ({ ...d, value: d._count }))}
                        wrapperRef={(r) => {
                            this.eRef = r;
                            if (r && !this.state.eRef) {
                                this.setState({ eRef: true });
                            }
                        }}
                    />
                );
                break;
            case 'table':
                const d = data.data.map((row) => ({
                    ...row.data,
                    progress: row.progress
                }));
                rv = (
                    <ReactTable
                        colSum={true}
                        data={d}
                        columns={data.header}
                        removeEmpty={true}
                        tableRef={(r) => {
                            this.eRef = r;
                        }}
                        widgetId={this.props.widgetId}
                        isProfileFromSearchGroup={this.isProfileFromSearchGroup}
                        isCreatedBy={this.isCreatedBy}
                    />
                );
                break;
            default:
                break;
        }

        let exportWrapperSize = {
            w: '2000px',
            h: '2000px'
        };
        if (this.eRef) {
            exportWrapperSize = scaleMax(
                { w: this.eRef.clientWidth, h: this.eRef.clientHeight },
                { w: 2000, h: 2000 }
            );
        }

        return (
            <>
                <ResponsiveContainer debounce={200} width="100%" height="100%">
                    {rv}
                </ResponsiveContainer>

                {/* render with greater size (used for pptx png export) */}
                <div
                    data-testid="report-chart"
                    style={{
                        width: exportWrapperSize.w,
                        height: exportWrapperSize.h,
                        position: 'fixed',
                        visibility: 'hidden'
                    }}
                    className="export-wrapper"
                >
                    {React.cloneElement(rv, { isInteractive: false })}
                </div>
            </>
        );
    }

    render() {
        const {
            settings,
            toggleWidgetSettings,
            t,
            saveWidgetState,
            getWidgetState,
            widgetId,
            workspaceId,
            state
        } = this.props;
        const { loading } = this.state;
        const { dataSourceType, groupBy, select } = this.props;
        const data = this.props.tour.show
            ? getFakeChartData(dataSourceType, groupBy, select)
            : this.state.data;

        return (
            <>
                <WidgetSettings
                    show={settings && state.showSettings}
                    settings={settings}
                    toggleWidgetSettings={toggleWidgetSettings}
                    getData={this.getData}
                    // waitLoading={this.props.waitLoading}
                    saveWidgetState={saveWidgetState}
                    getWidgetState={getWidgetState}
                    widgetId={widgetId}
                    workspaceId={workspaceId}
                    state={state}
                />
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {data && data.length !== 0 ? (
                            this.renderVisualization(data)
                        ) : (
                            <div className={styles.noData}>
                                <h2>{t('No data for selected filters')}</h2>
                                {t('Use the filter icon at the top to change filters')}
                            </div>
                        )}
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    workspaces: state.workspaces,
    board: state.board,
    profileGroups: state.profileGroups.data,
    tour: state.tour
});

const mapDispatchToProps = {
    ...actionCreators
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ReportElement));
