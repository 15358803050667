import React from 'react';
import styles from './MySelect.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

library.add(faAngleDown);

class MySelect extends React.Component {
    // note: this was created (while trying to make it look similar to react-select ui element) to be used instead
    // of react-select due to this specific issue which I was not able to fix easily:
    // list of tasks in schedules widget has overflow:auto (to allow getting scrollbar if having more tasks than screen space allows without scrollbar)
    // prop 'menuPosition=' can be toggled between 'absolute' and 'fixed' which leads to either: dropdown options not overflowing border of tasks div
    // or dropdown options being positioned on 'randomly wrong' positions based on the scrollbar position


    // it would propbably be better to solve the issue with react-select and use react-select everywhere (instead of this component)
    // hint to solution, might be something like this:
    // https://stackoverflow.com/a/22927412

    constructor(props) {
        super(props);
        this.state = {
            value: undefined
        };
    }

    render() {
        return (
            // <div className={styles.select}>
            //         {this.state.selected ? this.state.selected : this.props.placeholder}
            //         {this.props.options.map((o) => <option value={o.value}>{o.label}</option>) }
            //     <div className={styles.arrow}>
            //         <FontAwesomeIcon icon="angle-down" />
            //     </div>
            // </div>

            <div className={styles.selectWrapper}>
                <select
                    className={styles.select}
                    onChange={(e) => {
                        const o = _.find(this.props.options, { value: e.target.value });
                        this.props.onChange(o);
                        this.setState({value: o.value});
                    }}
                    value={this.state.value}
                >
                    {
                        this.props.options.map((o) => {
                            // if (o.value === this.props.value) {
                            //     return <option selected='selected' value={o.value}>{o.label}</option>
                            // } else {
                                return <option value={o.value}>{o.label}</option>
                            // }
                        })
                    }
                </select>
                </div>
        );
    }
}

const mapStateToProps = state => ({
    //theme: state.theme,
});
export default connect(mapStateToProps,{ /* insert redux actions here */ })(withTranslation()(MySelect));