const config = {
    STORAGE: localStorage,
    mobileDesktopBreakpoint: 650,  // number of pixels to distinguish between mobile and desktop view
    apiPrefix: '/api',
    urls: {
        mediaEditor: '/mediaEditor/?handleAuth=1'
    },
    userGuideLanguages: ['en', 'es'],    // list of languages in which user guide is available (and therefore gets linked by GUI - default: link 'en' user guide)
    onlineSupportLanguages: ['en'], // list of languages in which "Online support" pages are available
    allowMixedProfileTypesInGroup: true,    // defines if UI should allow mixed types in a profile group. types are: v2 created / v3 simple / v3 super profile
    // allowAbsRedirect: false, // Warning: use with care. we might not want to use that at all - due to security reasons? used to decide if we should allow redirects to absolute urls (which means it could be other domains) in some places. e.g. url parameters in /login?redirect=... and /signup?redirect=...
    
    // TODO: think about this in detail; main idea would be to be able to deploy "breaking changes" regarding saved settings (/api/settings)
    // this could be stored to each created/updated client setting
    // CLIENT_SETTINGS_VERSION: 1,
    
    // superset: {
    //     // datasetId: 53
    //     embed_url: `https://a.emediamonitor.net`, // used by iframe
    //     api_url: 'https://a.emediamonitor.net/api/v1',
    //     login: {
    //         username: 'username',
    //         password: 'password',
    //         provider: 'db'
    //     }
    // }
    academyAdminUserIds: 
        [
            603, //  c.twrdy
            1874   // dm-admin@emediamonitor.net
        ],
    stripe: {
        public_key: 'pk_test_KQmtOFl1Z80L8mGyM0dsTQaV00QeB2nKiq',
    },
    searchQueryGroupTitle: '[Recent Searches]',
    possibleAutoSummaryLanguages: [
        'ar',
        'de',
        'en',
        'es',
        'fr',
    ]
}

export default config;