
import CompanyCard from "./components/CompanyCard/CompanyCard"

import styles from './CompanyManager.module.scss'
import { CompanyManagerData } from "./CompanyManager.types";
import { useEffect, useState } from "react";
import APICompanyGroups from "src/API/APICompanyGroups";

import { useAppSelector } from "src/redux/hooks";
import NoAccess from "src/Board/NoAccess/NoAccess";


const CompanyListContainer = () => {
    const [companiesData, setCompaniesData] = useState([]);

    const me = useAppSelector(state => state.me)
    
    const fetchCompaniesDataHandler = async () => {
        const res = await APICompanyGroups.get();
        if(res.rc === 0){
            setCompaniesData(res.data)
        }else{
            console.log('company fetch error');
        }
    }

    useEffect(() => {
        fetchCompaniesDataHandler()
    }, [])

    if (!me || me.managedCompanyGroups.length === 0)
    {
        return <NoAccess />;
    }

    return (
      <div className={styles.companyListContainer}>
          {
              companiesData.map((groupData: CompanyManagerData) => {
                  return (
                      <CompanyCard
                          key={groupData.id}
                          groupData={groupData}
                      />
                  );
              })
          }
      </div>
  );
}

export default CompanyListContainer