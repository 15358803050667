import { SET, CLEAR } from '../types/actionTypes.signup';

const paramsReducers = (state = {
    // user
}, action) => {
    switch (action.type) {
        case SET:
            return action.data;
        case CLEAR:
            return null;
        default:
            return state;
    }
};

export default paramsReducers;