import React, { Component } from 'react';
import styles from './NoAccess.module.scss';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

class NoAccess extends Component {
  render() {
    return (
      <div className={styles.noAccess}>
        <FontAwesomeIcon icon={faLock} className={styles.icon} />
        {this.props.t('noaccess')}
      </div>
    );
  }
}

export default withTranslation()(NoAccess);