import styles from './Question.module.scss';

function SingleChoiceQuestion({
    answers,
    onAnswerChange,
}){
    return(
        <div>
            {answers.map((a,i)=>{
                return (
                    <div
                        key={i}
                        className={styles.wrapper}
                        onClick={(e)=>{
                            onAnswerChange(i, true)
                        }}
                    >
                        <input
                            name="option"
                            style={{cursor: 'pointer'}}
                            checked={a.answer}
                            // onChange={(e)=>{
                            //     onAnswerChange(i, true)
                            // }}
                            type="radio"
                            value={a.text}
                        />{a.text}
                    </div>
                )
            })}
        </div>
    )
}
export default SingleChoiceQuestion;