import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import APITelemetry from 'src/API/APITelemetry';
import { urlParamsToObj } from 'src/Widgets/common/helpers';
import { setIsWrongUser, setSessionExpired } from 'src/redux/actions/actions.auth';
import { setIsAuthenticated } from 'src/redux/actions/actions.auth';
import { changeRoute } from 'src/redux/actions/actions.route';

function OAuth2Handler({
    location,
}) {
    const { authProvider } = useParams();

    const reportUnknownError = async(rc, error) => {
        await APITelemetry.post({ msg: `#/oauth2/${authProvider}: Do not know how to handle rc=${rc} with error=${error}` })
    }

    const getHashParams = () => { return urlParamsToObj(location?.search, ['rc', 'email', 'locale', 'name', 'family_name', 'given_name', 'sub', 'academy', 'error']) };
    const hashParams = getHashParams();
    // let urlParams = '';
    // if (hashParams?.academy) { urlParams += '?academy=1'; }
    // let href = `${process.env.PUBLIC_URL}${urlParams}${window.location.search}`;
    const opener = window?.opener;
    const redux = opener.window.rxStore.getState();

    if(hashParams.rc !== 'TMP_WRONG_USER'){
        opener.window.rxStore.dispatch( setIsWrongUser(false) );
    }

    switch (hashParams.rc) {
        case '0':
            opener.window.rxStore.dispatch(
                setSessionExpired(false)
            );

            if(!redux.auth.session.isExpired){
                opener.window.rxStore.dispatch(
                    setIsAuthenticated({isAuthenticated: true, redirectTo: {pathname: '/'}})
                );
            }

            window.close();
            break;
        case '334':
            opener.window.rxStore.dispatch(
                changeRoute(`/oauth2/${authProvider}/createNewUser${location.search}`)
            );
            window.close();
            break;
        case '333': // wrong user after re-authentication
                opener.window.rxStore.dispatch(
                    setIsWrongUser(true)
                );
                window.close();
                break;
        case '335':
            switch (hashParams.error) {
                case '20199':
                    opener.window.rxStore.dispatch(
                        changeRoute('/user-expired')
                    );
                    window.close();
                    break;
                case '20198':
                    opener.window.rxStore.dispatch(
                        changeRoute('/user-locked')
                    );
                    window.close();
                    break;
                case '20197':
                        opener.window.rxStore.dispatch(
                            changeRoute('/user-no-permission')
                        );
                        window.close();
                    break;
                case '20196':
                        opener.window.rxStore.dispatch(
                            changeRoute('/user-problem')
                        );
                        window.close();
                        break;
                // case 'locked':
                    // TODO: implement
                    // break;
                default:
                    (async()=>await reportUnknownError(hashParams.rc, hashParams.error))();
                    window.close();
                    break;
            }
            break;
        default:
            (async()=>await reportUnknownError(hashParams.rc, hashParams.error))();
            window.close();
            break;
    }
    return null;
}

export default connect(
    null,
    { changeRoute }
    )
    (withRouter(OAuth2Handler));