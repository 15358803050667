import EPG from '../../Widgets/EPG/EPG';
import ProfileGroupCards from '../../Widgets/GroupCardWidgets/ProfileGroupCards';
import ChannelGroupCards from '../../Widgets/GroupCardWidgets/ChannelGroupCards';
import UserManager from '../../Widgets/GroupCardWidgets/UserManager';
import CompanyGroupCards from '../../Widgets/GroupCardWidgets/CompanyGroupCards';
import ShareGroupCards from '../../Widgets/GroupCardWidgets/ShareGroupCards';
import Monitor from '../../Widgets/Monitor/Monitor';
import Search from '../../Widgets/Search/Search';
import LogoFamilyCards from '../../Widgets/GroupCardWidgets/LogoFamilyCards';
import HitsPerProfile from '../../Widgets/Statistics/ProfileStats/HitsPerProfile';
import ProfileGroupStats from '../../Widgets/Statistics/ProfileGroupStats/ProfileGroupStats';
// import Waterfall from '../../Widgets/Waterfall/Waterfall';
import WaterfallContainer from 'src/Widgets/Waterfall/WaterfallContainer';
import EditorialReportCategoryTime from 'src/Widgets/Statistics/EditorialReport/EditorialReportCategoryTime';
import EditorialReportUserTime from 'src/Widgets/Statistics/EditorialReport/EditorialReportUserTime';
import EditorialReportProfileTime from 'src/Widgets/Statistics/EditorialReport/EditorialReportProfileTime';
import EditorialReportCategoryPerUser from 'src/Widgets/Statistics/EditorialReport/EditorialReportCategoryPerUser';
// import WaterfallPlayer from 'src/Widgets/Waterfall/WaterfallPlayer/WaterfallPlayer';
// import FeedGroupCards from '../../Widgets/GroupCardWidgets/FeedGroupCards';
// import ChartSelector from 'src/Widgets/common/Charts/superset/ChartSelector';
// import DashboardSelector from 'src/Widgets/common/Charts/superset/DashboardSelector';
import ReportElement from 'src/Widgets/common/Reporting/ReportElement/ReportElement';
// import APIProfileGroups from 'src/API/APIProfileGroups';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { downloadFile, dateToString, getLastX } from 'src/Widgets/common/helpers';
import APIProfiles from 'src/API/APIProfiles';
import Schedules from 'src/Widgets/Schedules/Schedules';
import LinkWidget from 'src/Widgets/common/LinkWidget/LinkWidget';
import DownloadButton from 'src/Widgets/common/basicElements/DownloadButton/DownloadButton';
import IFrameWrapper from 'src/Widgets/common/basicElements/IFrameWrapper/IFrameWrapper';
import TitlePage from 'src/Widgets/TitlePage/TitlePage';
// import config from 'src/config/config';
import CustomIframe from 'src/Widgets/CustomIframe/CustomIframe';
import QuizContainer from 'src/Widgets/Quiz/QuizContainer';
import CourseOverview from 'src/Widgets/CourseOverview/CourseOverview';
import ChannelGroups from 'src/Widgets/ChannelGroups/ChannelGroups';
import CoursesOverviewContainer from 'src/Widgets/CoursesOverviewContainer/CoursesOverviewContainer';
import TopicMentionsOverview from 'src/Widgets/TopicMentionsOverview/TopicMentionsOverview';
import CompanyManager from 'src/Widgets/CompanyManager/CompanyManager';
import ChannelGroupsNext from '../../Widgets/ChannelGroupsNext/ChannelGroupsNext'
import MentionsVisualizer from 'src/Widgets/MentionsVisualizer/MentionsVisualizer';
// import RecentClips from 'src/Widgets/RecentClips/RecentClips';
import SearchAgents from 'src/Widgets/SearchAgents/SearchAgents';
import { AvailableWidgetsTypes } from './availableWidgets.types';

// TODO: allow using translation 't()' within this non-component file

export function getOptionsFunct(type:'profile'|'profileGroup') {
    switch (type) {
        case 'profile':
            return async () => {
                // @ts-ignore
                const pgs = window.rxStore.getState().profileGroups.data;
                return pgs.map((pg:any) => ({
                    label: pg.title,
                    options: pg.profiles.map((p:any) => ({
                        label: p.title,
                        value: p.id
                    }))
                }));
            }
        case 'profileGroup':
            return async () => {
                // @ts-ignore
                const pgs = window.rxStore.getState().profileGroups.data;
                return pgs.map((profileGroup:any) => ({
                    label: profileGroup.title,
                    value: profileGroup.id,
                }));
            }
        // case 'profileGroup':
        //     return async () =>
        //         (await APIProfileGroups.get()).data
        //             .map((profileGroup) => ({
        //                 label: profileGroup.title,
        //                 value: profileGroup.id,
        //     }))            
        default: console.log(`type '${type}' not implemented`); return ()=>(undefined);
    }
}

// TODO: i18m widget names (t(widget.name) is already in use)
let availableWidgets:AvailableWidgetsTypes.AvailableWidgets[] = [
    {
        key: 'epg',
        name: 'Go To',
        component: EPG,
        embedPath: '/embed/EPG',
        tag: 'Research',
        title: 'Go To Widget Title',
        experimental: false,
    },

    //{ key: 'monitor', name: 'My Clips', component: Monitor, embedPath: '/embed/Monitor', tag: 'Monitoring', title: 'My Clips Widget Title', },
    {
        key: 'clips_recent',
        name: 'Recent_clips',
        component: IFrameWrapper,
        props: {
            url: '/cgi-bin/feed/mymonitor',
            wrapperStyle: { height:'100%' }
        },
        embedPath: '/cgi-bin/feed/mymonitor',
        tag: 'Monitoring',
        title: 'Recent Clips Title',
        experimental: false,
    },
    {
        key: 'clips_recent_next',
        name: 'recent_clips_new',
        component: MentionsVisualizer,
        embedPath: '/embed/RecentClipsNext',
        tag: 'Monitoring',
        props: {
            isRecent: true
        },
        title: 'Recent Clips New Title',
        experimental: false,
    },
    {
        key: 'clips_editorial_workflow_next',
        name: 'editorial_workflow_new',
        component: MentionsVisualizer,
        embedPath: '/embed/EditorialWorkflowNext',
        tag: 'Monitoring',
        props: {
            isRecent: true,
            isEditorial: true
        },
        title: 'Editorial Workflow New Title',
        experimental: true,
    },
    {
        key: 'clips_edited_next',
        name: 'edited_clips_new',
        component: MentionsVisualizer,
        embedPath: '/embed/EditedClipsNext',
        tag: 'Monitoring',
        props: {
            isEdited: true
        },
        title: 'Edited Clips Title',
        experimental: false,
    },
    {
        key: 'search_next',
        name: 'search_new',
        component: MentionsVisualizer,
        embedPath: '/embed/SearchNext',
        tag: 'Research',
        title: 'Search Widget New Title',
        experimental: false,
        props: {
            isSearch: true
        },
    },
    {
        key: 'clips_edited',
        name: 'Edited_clips',
        component: IFrameWrapper,
        props: {
            url:'/cgi-bin/feed/mymonitor?efeed=1',
            wrapperStyle: { height:'100%' }
        },
        embedPath: '/cgi-bin/feed/mymonitor?efeed=1',
        tag: 'Monitoring',
        title: 'Edited Clips Title',
        experimental: false,
    },
    // {
    //     key: 'editorial',
    //     name: 'editorial_workflow',
    //     component: LinkWidget,
    //     // embedPath: '/embed/...',
    //     tag: 'Monitoring',
    //     title: 'Editorial Workflow Title',
    //     props: {
    //         title: 'open in new tab',
    //         url: config.apiPrefix + '/feed/editorial'
    //     },
    //     experimental: false,
    // },
    {
        key: 'editorial2',
        name: 'editorial_workflow2',
        component: LinkWidget,
        // embedPath: '/embed/...',
        tag: 'Monitoring',
        title: 'Editorial Workflow 2 Title',
        props: {
            title: 'open in new tab',
            url: '/cgi-bin/feed/editorial2'
        },
        experimental: false,
    },
    {
        key: 'profileGroupCards',
        name: 'Profiles',
        component: ProfileGroupCards,
        tag: 'Set Up',
        title: 'Profiles Widget Title',
        headerElements: [<DownloadButton
                            type='xlsx'
                            onClick={async () => {
                                const d = await APIProfiles.getXls();
                                downloadFile(d, `eMM_profiles_${dateToString(new Date())}.xlsx`);
                            }}
                        />],
        embedPath: '/embed/ProfileGroupCards',   // todo: consider using embedPath in App.js to auto-generate Routes from it and dont specify twice ?
        // props: {
        //     settings: {
                // xlsDownload: {  // TODO: translations
                //     globalSetting: false,   // = do not show this 'setting' inside workspace settings
                //     title: 'Download',
                //     comp: <div>
                //         <Button
                //             type='secondary'
                //             onClick={async () => {
                //                 const d = await APIProfiles.getXls();
                //                 downloadFile(d, `eMM_profiles_${dateToString(new Date())}.xlsx`);
                //             }}
                //         >Download XLSX</Button>
                //     </div>
                // }
        //     },
        // }
        experimental: false,
    },

    // {
    //     key: 'channelGroupCards',
    //     name: 'Channel Groups',
    //     component: ChannelGroupCards,
    //     tag: 'Set Up',
    //     title: 'Channels Widget Title',
    //     embedPath: '/embed/ChannelGroupCards',
    //     experimental: false,
    // },
    {
        key: 'channelGroupCards',
        name: 'Channel Groups',
        component: ChannelGroups,
        tag: 'Set Up',
        title: 'Channels Widget Title',
        embedPath: '/embed/ChannelGroupCards',
        experimental: false,
    },

       {
        key: 'topicMentionsDetailOverview',
        name: 'Topic-Mentions-Overview',
        component: TopicMentionsOverview,
        tag: 'Analytics',
        title: 'Topic Mentions Overview Title',
        embedPath: '/embed/topicMentionsOverview',
        experimental: true,
        props: {
            settings: {
                // date: { title: 'Date', column: 'timestamp' },
                profile: {
                    title: 'Profile',
                    // required: true,
                    getOptions: getOptionsFunct('profile')
                },
                date: {
                    title: 'Date',
                    type: 'daterange',
                }
                // profileGroup: {
                //     title: 'Profile Group',
                //     getOptions: async () =>
                //         (await APIProfileGroups.get()).data
                //             .map((pg) => ({ label: pg.title, value: pg.id }))
                // },
            },
        },
    },  

    {
        key: 'companyCards',
        name: 'User Manager',
        component: UserManager,
        tag: 'Administration',
        title: 'User Manager Widget Title',
        embedPath: '/embed/UserManager',
        experimental: true,

        // also shows on isCompanyGroupAdmin
        isUserManagerForCompany: true
    },
    {
        key: 'searchAgents',
        name: 'Search Agents',
        component: SearchAgents,
        tag: 'Set Up',
        title: 'Search Agents Widget Title',
        embedPath: '/embed/SearchAgents',
        experimental: true,
    },

    // {
    //     key: 'companyGroupCards',
    //     name: 'Company Manager',
    //     component: CompanyGroupCards,
    //     tag: 'Administration',
    //     title: 'Company Manager Widget Title',
    //     isCompanyGroupAdmin: true,
    //     embedPath: '/embed/CompanyGroupCards',
    //     experimental: true,
    // },

    {
        key: 'shareGroupCards',
        name: 'Share-Groups',
        component: ShareGroupCards,
        tag: 'Set Up',
        title: 'Share Groups Widget Title',
        embedPath: '/embed/ShareGroupCards',
        experimental: true,
    },

    // {
    //     key: 'feedGroupCards',
    //     name: 'Feed-Groups',
    //     component: FeedGroupCards,
    //     tag: 'Cards',
    //     embedPath: '/embed/FeedgroupCards'
    // },

    {
        key: 'search',
        name: 'Search',
        component: Search,
        embedPath: '/embed/Search',
        tag: 'Research',
        title: 'Search Widget Title',
        experimental: false,
        props: {
            showAddProfile: true
        },
    },

    // {
    //     key: 'logoFamilyCards',
    //     name: 'Logo Families',
    //     component: LogoFamilyCards,
    //     tag: 'Cards',
    //     embedPath: '/embed/LogoFamilyCards'
    // },

    {
        key: 'hitsPerProfile',
        name: 'Coverage',
        component: HitsPerProfile,
        tag: 'Analytics',
        title: 'Coverage Widget Title',
        embedPath: '/embed/Statistics/Profiles',
        experimental: false,
    },
    // {
    //     key: 'hitsPerProfileGroup',
    //     name: 'Coverage by Group',
    //     component: ProfileGroupStats,
    //     tag: 'Analytics',
    //     embedPath: '/embed/Statistics/ProfileGroups'
    // },
    {
        key: 'waterfall',
        name: 'Waterfall',
        component: WaterfallContainer,
        tag: 'Monitoring',
        title: 'Waterfall Widget Title',
        embedPath: '/embed/Waterfall',
        experimental: false,
        public: true,  // not asking for auth if used via embedPath url - as we currently use this in v2
    },
    // {
    //     key: 'editorialStatsCategoriesTime',
    //     name: 'Editorial Statistics - Categories | Time',
    //     component: EditorialReportCategoryTime,
    //     tag: 'Administration',
    //     title: 'Editorial Statistics Widget Title',
    //     embedPath: '/embed/Statistics/Editorial/Categories-Time'
    // },
    // {
    //     key: 'editorialStatsUsersTime',
    //     name: 'Editorial Statistics - Users | Time',
    //     component: EditorialReportUserTime,
    //     tag: 'Administration',
    //     title: 'Editorial Statistics Widget Title',
    //     embedPath: '/embed/Statistics/Editorial/Users-Time'
    // },
    // {
    //     key: 'editorialStatsProfilesTime',
    //     name: 'Editorial Statistics - Profiles | Time',
    //     component: EditorialReportProfileTime,
    //     tag: 'Administration',
    //     title: 'Editorial Statistics Widget Title',
    //     embedPath: '/embed/Statistics/Editorial/Profiles-Time'
    // },
    // {
    //     key: 'editorialStatsCategoryUser',
    //     name: 'Editorial Statistics - Categories | User',
    //     component: EditorialReportCategoryPerUser,
    //     tag: 'Administration',
    //     title: 'Editorial Statistics Widget Title',
    //     embedPath: '/embed/Statistics/Editorial/Categories-User'
    // },
    // {
    //     key: 'supersetChart',
    //     name: 'Superset Chart',
    //     component: ChartSelector,
    //     tag: 'Visualization',
    //     embedPath: '/embed/superset/chart'
    // },
    // {
    //     key: 'supersetDashboard',
    //     name: 'Superset Dashboard',
    //     component: DashboardSelector,
    //     tag: 'Visualization',
    //     embedPath: '/embed/superset/dashboard'
    // },
    {
        key: 'schedules',
        name: 'Schedules',
        component: Schedules,
        tag: 'Set Up',
        title: 'Schedules Widget Title',
        embedPath: '/embed/Schedules',
        public: true,
        experimental: false,
    },

    {
        key: 'companyGroupCards',
        name: 'Company Manager',
        component: CompanyManager,
        tag: 'Administration',
        title: 'Company Manager Widget Title',
        embedPath: '/embed/CompanyManager',
        public: true,
        experimental: true,
        isCompanyGroupAdmin: true,
    },

    {
        key: 'channelGroupsNext',
        name: 'Channel Groups Next',
        component: ChannelGroupsNext,
        tag: 'Testing',
        title: 'Channel Groups Next Widget Title',
        embedPath: '/embed/ChannelGroupsNext',
        public: true,
        experimental: true,
    },

    // Reporting
    {
        key: 'reportTitlePage',
        name: 'Title Page',
        component: TitlePage,
        tag: 'Analytics',
        title: 'Title Page Widget Title',
        // embedPath: '/embed/reporting/sentiment-breakdown'
        experimental: false,
    },
    {
        key: 'sentimentBreakdown',
        name: 'Sentiment Breakdown',
        component: ReportElement,
        // delayedLoading: true,
        props: {
            vizType: 'donut',
            vizSettings: {
                percent: true,
            },
            groupBy: ['sentiment'],
            select: ['_count'],
            limit: 5,
            dataSource: {
                type: 'count'
            },
            settings: {
                // date: { title: 'Date', column: 'timestamp' },
                profile: {
                    title: 'Profile',
                    // required: true,
                    getOptions: getOptionsFunct('profile')
                },
                date: {
                    title: 'Date',
                    type: 'daterange',
                }
                // profileGroup: {
                //     title: 'Profile Group',
                //     getOptions: async () =>
                //         (await APIProfileGroups.get()).data
                //             .map((pg) => ({ label: pg.title, value: pg.id }))
                // },
            },
        },
        tag: 'Analytics',
        title: 'Sentiment Breakdown Widget Title',
        // embedPath: '/embed/reporting/sentiment-breakdown'
        experimental: false,
    },
    {
        key: 'languageBreakdown',
        name: 'Language Breakdown',
        component: ReportElement,
        // delayedLoading: true,
        props: {
            vizType: 'donut',
            vizSettings: {
                percent: true,
            },
            groupBy: ['language'],
            select: ['_count'],
            limit: 5,
            dataSource: {
                type: 'count'
            },
            settings: {
                // date: { title: 'Date', column: 'timestamp' },
                // topic: { title: 'Topic', getOptions: () => APIReporting.getFilters('profileGroup') },
                profile: {
                    title: 'Profile',
                    // required: true,
                    getOptions: getOptionsFunct('profile')
                },
                date: {
                    title: 'Date',
                    type: 'daterange',
                }
            },
        },
        tag: 'Analytics',
        title: 'Language Breakdown Widget Title',
        // embedPath: '/embed/reporting/language-breakdown'
        experimental: false,
    },
    {
        key: 'mediaTypeBreakdown',
        name: 'Media Type Breakdown',
        component: ReportElement,
        // delayedLoading: true,
        props: {
            vizType: 'pie',
            vizSettings:{
                percent: true,
            },
            groupBy: ['type'],
            select: ['_count'],
            limit: 5,
            dataSource: {
                type: 'count'
            },
            settings: {
                // date: { title: 'Date', column: 'timestamp' },
                // topic: {
                //     title: 'Topic',
                //     type: { type: 'dropdown', multi: true },
                //     // getOptions: () => APIReporting.getFilters('profileGroup'),
                // },
                profile: {
                    title: 'Profile',
                    // required: true,
                    getOptions: getOptionsFunct('profile')
                },
                date: {
                    title: 'Date',
                    type: 'daterange',
                }
            },
        },
        tag: 'Analytics',
        title: 'Media Type Breakdown Widget Title',
        // embedPath: '/embed/reporting/media-type-breakdown'
        experimental: false,
    },
    {
        key: 'scopeBreakdown',
        name: 'Scope Breakdown',
        component: ReportElement,
        // delayedLoading: true,
        props: {
            vizType: 'pie',
            groupBy: ['scope'],
            limit: 5,
            select: ['_count'],
            dataSource: {
                type: 'count'
            },
            settings: {
                profile: {
                    title: 'Profile',
                    getOptions: getOptionsFunct('profile')
                },
                date: {
                    title: 'Date',
                    type: 'daterange',
                }
            },
        },
        tag: 'Analytics',
        title: 'Scope Breakdown Widget Title',
        // embedPath: '/embed/reporting/scope-breakdown'
        experimental: false,
    },
    {
        // /api/analytics/scope/by/userdate/select/profiles/35941
        key: 'scopeOfMentions',
        name: 'Scope of Mentions (relative)',
        component: ReportElement,
        // delayedLoading: true,
        props: {
            vizType: 'bar',
            vizSettings: {
                stacked: true,
                percent: true,
                xLabel: 'Day',
                yLabel: 'Mentions',
                // showValues: true,
            },
            limit: 5,
            select: ['_count', 'scope'],
            groupBy: ['userdate', 'scope'],
            dataSource: {
                type: 'time-based',
                granularity: 'day'
            },
            settings: {
                profile: {
                    title: 'Profile',
                    getOptions: getOptionsFunct('profile')
                },
                date: {
                    title: 'Date',
                    type: 'daterange',
                }
            },
            // settings: {
            // date: { title: 'Date', column: 'timestamp' },
            // topic: { title: 'Topic', getOptions: () => APIReporting.getFilters('profileGroup') },
            // keyword: { title: 'Keyword', getOptions: () => APIReporting.getFilters('profile') },
            // },
        },
        tag: 'Analytics',
        title: 'Scope of Mentions Widget Title',
        // embedPath: '/embed/reporting/scope-of-mentions',
        experimental: false,
    },
    {
        // /api/analytics/scope/by/userdate/select/profiles/35941
        key: 'scopeOfMentionsAbsolute',
        name: 'Scope of Mentions (absolute)',
        component: ReportElement,
        // delayedLoading: true,
        props: {
            vizType: 'bar',
            vizSettings: {
                stacked: true,
                percent: false,
                xLabel: 'Day',
                yLabel: 'Mentions',
                // showValues: true,
            },
            limit: 5,
            select: ['_count', 'scope'],
            groupBy: ['userdate', 'scope'],
            dataSource: {
                type: 'time-based',
                granularity: 'day'
            },
            settings: {
                profile: {
                    title: 'Profile',
                    getOptions: getOptionsFunct('profile')
                },
                date: {
                    title: 'Date',
                    type: 'daterange',
                }
            },
        },
        tag: 'Analytics',
        title: 'Scope of Mentions Absolute Widget Title',
        // embedPath: '/embed/reporting/scope-of-mentions',
        experimental: false,
    },
    {
        key: 'mentionsPerChannelAbsolute',
        name: 'Mentions per Channel (absolute)',
        component: ReportElement,
        props: {
            vizType: 'bar',
            vizSettings: {
                stacked: true,
                percent: false,
                xLabel: 'Day',
                yLabel: 'Mentions',
            },
            limit: 5,
            select: ['_count', 'channelName'],
            groupBy: ['userdate', 'channelName'],
            dataSource: {
                type: 'time-based',
                granularity: 'day'
            },
            settings: {
                profile: {
                    title: 'Profile',
                    getOptions: getOptionsFunct('profile')
                },
                date: {
                    title: 'Date',
                    type: 'daterange',
                }
            },
        },
        tag: 'Analytics',
        title: 'Mentions per Channel Widget Title',
        // embedPath: '/embed/reporting/scope-of-mentions',
        experimental: false,
    },
    {
        key: 'worldMap',
        name: 'Geographical Breakdown',
        info: 'Hold Shift and scroll to zoom while hovering over the map',
        component: ReportElement,
        tag: 'Analytics',
        title: 'Geographical Breakdown Widget Title',
        // embedPath: '/embed/reporting/map',
        // delayedLoading: true,
        props: {
            vizType: 'map',
            groupBy: ['cc'],
            select: ['_count'],
            dataSource: {
                type: 'count'
            },
            settings: {
                profile: {
                    title: 'Profile',
                    getOptions: getOptionsFunct('profile')
                },
                date: {
                    title: 'Date',
                    type: 'daterange',
                }
            },
        },
        experimental: false,
    },
    {
        key: 'customIframe',
        name: 'Custom Iframe',
        component: CustomIframe,
        tag: 'Administration',
        title: 'Custom Iframe Title',
        experimental: false,
        props: {
            settings: {}
        },
        academyOnly: true,
    },
    {
        key: 'quiz',
        name: 'Quiz',
        component: QuizContainer,
        tag: 'Administration',
        title: 'Quiz Title',
        experimental: false,
        props: {
            settings: {}
        },
        academyOnly: true,
    },
    {
        key: 'courseOverview',
        name: 'Course Overview',
        component: CourseOverview,
        tag: 'Administration',
        title: 'CourseOverview Title',
        experimental: false,
        props: {
            settings: {}
        },
        academyOnly: true,
    },
    {
        key: 'coursesOverview',
        name: 'Courses Overview',
        component: CoursesOverviewContainer,
        tag: 'Administration',
        title: 'CoursesOverview Title',
        experimental: false,
        academyOnly: true,
    },
    {
        key: 'topicMentionDetailsTable',
        name: 'Topic Mention Details Table',
        component: ReportElement,
        tag: 'Analytics',
        title: 'Topic Mention Details Table Widget Title',
        // embedPath: '/embed/reporting/topic-mention-details-table',
        // delayedLoading: true,
        props: {
            vizType: 'table',
            vizSettings: {
                columns: [
                    // TODO: future improvement: fetch types of channels and auto-add columns for all of them?
                    {
                        Header: 'AM Radio',
                        condition: 'AM Radio',
                        columns: [
                            {
                                Header: 'Mentions',
                                apiProp: '_count',
                                accessor: 'AM Radio._count',   // accessor = to reference this, e.g. for 'calc' - can be changed to any unique name
                            },
                            {
                                Header: '%',    // TODO: find a way to not use "this.props.t()" on this? not very important. but will show as "__T__&"" in development environment for now
                                calc: { type: '%', accessor: 'AM Radio._count' },
                                accessor: 'AM Radio._count_%',
                                unit: '%',
                                digits: 0,
                            },
                            {
                                Header: 'Ad value',
                                apiProp: 'ave',
                                accessor: 'AM Radio.ave'
                            },
                            {
                                Header: 'Reach',
                                apiProp: 'reach',
                                accessor: 'AM Radio.reach'
                            },
                        ],
                    },
                    {
                        Header: 'FM Radio',
                        condition: 'FM Radio',
                        columns: [
                            {
                                Header: 'Mentions',
                                apiProp: '_count',
                                accessor: 'FM Radio._count',
                            },
                            {
                                Header: '%',
                                calc: { type: '%', accessor: 'FM Radio._count' },
                                accessor: 'FM Radio._count_%',
                                unit: '%',
                                digits: 0,
                            },
                            {
                                Header: 'Ad value',
                                apiProp: 'ave',
                                accessor: 'FM Radio.ave'
                            },
                            {
                                Header: 'Reach',
                                apiProp: 'reach',
                                accessor: 'FM Radio.reach'
                            },
                        ],
                    },
                    {
                        Header: 'TV',
                        condition: 'TV',
                        columns: [
                            {
                                Header: 'Mentions',
                                apiProp: '_count',
                                accessor: 'TV._count',   // accessor = to reference this, e.g. for 'calc' - can be changed to any unique name
                            },
                            {
                                Header: '%',
                                calc: { type: '%', accessor: 'TV._count' },
                                accessor: 'TV.count_%',
                                unit: '%',
                                digits: 0,
                            },
                            {
                                Header: 'Ad value',
                                apiProp: 'ave',
                                accessor: 'TV.ave'
                            },
                            {
                                Header: 'Reach',
                                apiProp: 'reach',
                                accessor: 'TV.reach'
                            },
                        ],
                    },
                    {
                        Header: 'Web Text',
                        condition: 'Web Text',
                        columns: [
                            {
                                Header: 'Mentions',
                                apiProp: '_count',
                                accessor: 'Web Text._count',   // accessor = to reference this, e.g. for 'calc' - can be changed to any unique name
                            },
                            {
                                Header: '%',
                                calc: { type: '%', accessor: 'Web Text._count' },
                                accessor: 'Web Text._count_%',
                                unit: '%',
                                digits: 0,
                            },
                            {
                                Header: 'Ad value',
                                apiProp: 'ave',
                                accessor: 'Web Text.ave'
                            },
                            {
                                Header: 'Reach',
                                apiProp: 'reach',
                                accessor: 'Web Text.reach'
                            },
                        ],
                    },
                    {
                        Header: 'Web TV',
                        condition: 'Web TV',
                        columns: [
                            {
                                Header: 'Mentions',
                                apiProp: '_count',
                                accessor: 'Web TV._count',   // accessor = to reference this, e.g. for 'calc' - can be changed to any unique name
                            },
                            {
                                Header: '%',
                                calc: { type: '%', accessor: 'Web TV._count' },
                                accessor: 'Web TV._count_%',
                                unit: '%',
                                digits: 0,
                            },
                            {
                                Header: 'Ad value',
                                apiProp: 'ave',
                                accessor: 'Web TV.ave'
                            },
                            {
                                Header: 'Reach',
                                apiProp: 'reach',
                                accessor: 'Web TV.reach'
                            },
                        ],
                    },
                    {
                        Header: 'Press',
                        condition: 'Press',
                        columns: [
                            {
                                Header: 'Mentions',
                                apiProp: '_count',
                                accessor: 'Press._count',   // accessor = to reference this, e.g. for 'calc' - can be changed to any unique name
                            },
                            {
                                Header: '%',
                                calc: { type: '%', accessor: 'Press._count' },
                                accessor: 'Press._count_%',
                                unit: '%',
                                digits: 0,
                            },
                            {
                                Header: 'Ad value',
                                apiProp: 'ave',
                                accessor: 'Press.ave'
                            },
                            {
                                Header: 'Reach',
                                apiProp: 'reach',
                                accessor: 'Press.reach'
                            },
                        ],
                    },
                    {
                        Header: 'Social TV',
                        condition: 'SocialTV',
                        columns: [
                            {
                                Header: 'Mentions',
                                apiProp: '_count',
                                accessor: 'SocialTV._count',   // accessor = to reference this, e.g. for 'calc' - can be changed to any unique name
                            },
                            {
                                Header: '%',
                                calc: { type: '%', accessor: 'SocialTV._count' },
                                accessor: 'SocialTV._count_%',
                                unit: '%',
                                digits: 0,
                            },
                            {
                                Header: 'Ad value',
                                apiProp: 'ave',
                                accessor: 'SocialTV.ave'
                            },
                            {
                                Header: 'Reach',
                                apiProp: 'reach',
                                accessor: 'SocialTV.reach'
                            },
                        ],
                    },
                ]
            },
            groupBy: ['type'],
            select: [
                '_count',
                'ave',
                'reach',
            ],
            dataSource: {
                type: 'count', // todo: remove usage of this variable and check existence in 'select' above?
                // oneByOne: true,
            },
            settings: {
                profileGroup: {
                    title: 'Profile Group',
                    getOptions: getOptionsFunct('profileGroup')
                },
                date: {
                    title: 'Date',
                    type: 'daterange',
                    default: getLastX({ unit: 'days', number: 7 })
                }
            },
            // getData: () => {
            //     console.log('get data')
            // }
        },
        experimental: false,
    },
    // {
    //     key: 'waterfallPlayer',
    //     name: 'Waterfall Player',
    //     component: WaterfallPlayer,
    //     tag: 'WaterfallPlayer'
    //     title: '',
    // },
];

export function getAvailableWidgets(allowExperimentalWidgets: boolean, allWidgets: string[]) {
    // return all possible widgets (unfiltered) in development environment
    if (process.env.REACT_APP_ENV === 'development') {
        return availableWidgets;
    }
    let widgets = [...availableWidgets];

    // filter widgets which are defined in frontend based on "allWidgets" list (provided by backend: GET /api/me)
    // TODO: enable - but allWidgets list from BE needs to be cleaned up
    // widgets = widgets.filter( w => allWidgets.includes(w.key));

    // filter out experimental widgets if not in 'development' mode and logged in user should not see them (see also GET /api/me: experimentalWidgets)
    if (!allowExperimentalWidgets) {
        widgets = widgets.filter(w => w.experimental === false);
    }
    return widgets;
}