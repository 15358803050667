import styles from './Quiz.module.scss';
import { useTranslation } from 'react-i18next';
import { QUERY_TYPE_MULTIPLE_CHOICE, QUERY_TYPE_SINGLE_CHOICE } from 'src/_data/academy/consts';
import MultipleChoiceQuestion from './Question/Types/MultipleChoiceQuestion';
import SingleChoiceQuestion from './Question/Types/SingleChoiceQuestion';
import Button from '../common/basicElements/Button/Button';
import QuizQuestionResult from './Question/QuizQuestionResult';
import QuizFinished from './QuizFinished';
import QuizStart from './QuizStart';

function Quiz({
    question,
    hasPrev,
    showQuestionResult,
    onPrevClick,
    onDoneClick,
    onAnswerChange,
    onShowResultOk,
    finished,
    answerIsCorrect,
    showStartPage,
    onStart,
    restartQuiz,
}) {
    const { t } = useTranslation(); 

    return (
        <div className={styles.quiz}>
            {showStartPage
                ? <QuizStart onStart={onStart} />
                : finished
                    ? <QuizFinished restartQuiz={restartQuiz}/>
                    : <div>
                        <b>{t(question.type)}: </b>{question.text}
                        {question.type === QUERY_TYPE_MULTIPLE_CHOICE && <MultipleChoiceQuestion onAnswerChange={onAnswerChange} answers={question.answers}/>}
                        {question.type === QUERY_TYPE_SINGLE_CHOICE && <SingleChoiceQuestion onAnswerChange={onAnswerChange} answers={question.answers} />}
                        <div className={styles.buttonsWrapper}>
                            <Button
                                disabled={!hasPrev}
                                onClick={onPrevClick}
                                btnClass={styles.btn}
                                type="secondary">
                                {t('Back')}
                            </Button>
                            <Button
                                onClick={onDoneClick}
                                btnClass={styles.btn}
                                type="primary"
                            >
                                {t('Done')}
                            </Button>
                        </div>
                        {showQuestionResult && <QuizQuestionResult onOk={onShowResultOk} isCorrect={answerIsCorrect}/>}
                    </div>
            }
        </div>
    )
}

export default Quiz;