import { GET_THEME, UPDATE_THEME } from '../types/actionTypes.theme';
import themes from '../../assets/themes/themes';

let defaultTheme = themes.emm;


const themeReducers = (state = defaultTheme, action) => {
    switch (action.type) {
        case UPDATE_THEME:
            return action.data;
        case GET_THEME:
            return action.data;
        default:
            return state;
    }
};

export default themeReducers;