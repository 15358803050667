import {
    DELETE_QUIZ,
    POST_QUIZ,
    PUT_QUIZ,
    RECEIVE_QUIZZES
} from '../types/actionTypes.quiz';
import {
    // STATUS_LOADING,
    STATUS_SUCCEEDED,
} from '../types/status';
import _ from 'lodash';

const scheduleReducers = (state = {
    status: 'idle', // https://redux.js.org/tutorials/essentials/part-5-async-logic#loading-state-for-requests
    data: [],
}, action) => {
    let rv, idx;

    switch (action.type) {
        case RECEIVE_QUIZZES:
            rv = _.cloneDeep(state);
            rv.data = action.data;
            rv.status = STATUS_SUCCEEDED;
            return rv;
        case DELETE_QUIZ:
            rv = _.cloneDeep(state);
            const i = _.findIndex(rv.data,{id:action.id});
            rv.data.splice(i,1);
            return rv;
        case POST_QUIZ:
            rv = _.cloneDeep(state);
            rv.data.push(action.data);
            return rv;
        case PUT_QUIZ:
            rv = _.cloneDeep(state);
            idx = _.findIndex(state.data,{id: action.id});
            rv.data[idx] = action.data;
            return rv;
        default: return state;
    }
}

export default scheduleReducers;