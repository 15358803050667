import { setIsEditable as boardSetIsEditable, setShowUserMenu, setShowWidgetSelection, setShowHelpMenu } from "src/redux/actions/actions.board";
import { JoyrideTour } from "../JoyrideTour.types";

const getTourSteps = (params: JoyrideTour.GetStepsParams) => {
    const { dispatch, t, renderTourBox, renderOrangeBox, history, supportEmail } = params;
    const steps = [
        {
            disableBeacon: true,
            content: () =>
            (
                renderTourBox(<><b>{t('tour_welcome_1')}</b> {t('tour_welcome_2')}</>)
            ),
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                dispatch(setShowHelpMenu(true));
            },
            target: '[data-tut="tour__helpmenu"]',
        },
    
        {
            disableBeacon: true,
            placement: 'right',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => { if (fromPrev) { dispatch(setShowHelpMenu(false)); } },
            target: '[data-tut="tour__navBar"]',
            content: () => (renderTourBox(
                <>
                    <h4>{t('tour_workspaces_widgets_heading')}</h4>
                    {t('tour_workspaces_widgets_text')}
                </>
            )),
        },
    
        {
            disableBeacon: true,
            placement: 'right',
            target: '[data-tut="tour__editBoard"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                if (!fromPrev) {
                    dispatch(boardSetIsEditable(false));
                }
            },
            
            content: () => (<>
                {renderTourBox(t('tour_edit_board1'))}
                {renderOrangeBox(t('tour_edit_board2'))}
            </>),
            spotlightPadding: 7
        },
    
        {
            disableBeacon: true,
            target: '.widget-content-blocker-text',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                if (fromPrev) {
                    dispatch(boardSetIsEditable(true));
                } else {
                    dispatch(setShowWidgetSelection(false));
                }
            },
            content: () => (
                renderTourBox(
                    t('tour_edit_board3')
                )),
            spotlightPadding: 5
        },
    
        {
            disableBeacon: true,
            target: '[data-tut="tour__addWidget"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                if (fromPrev) {
                    dispatch(setShowWidgetSelection(true));
                } else {
                    history.replace('/workspaces/1');
                    dispatch(setShowWidgetSelection(true));
                    dispatch(boardSetIsEditable(true));
                }
            },
            content: () => (
                <>
                    {renderTourBox(t('tour_add_widget'))}
                    {renderOrangeBox(
                        <>{t('tour_add_widget_detail') + ' '}<a href={`mailto:${supportEmail}`}>{supportEmail}</a></>
                    )}
                </>),
            spotlightPadding: 3
        },
    
        {
            disableBeacon: true,
            target: '[data-tut="tour__workspace-6"]',
            placement: 'right',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                if (fromPrev) {
                    dispatch(setShowWidgetSelection(false));
                    dispatch(boardSetIsEditable(false));
                    history.replace('/workspaces/6');
                } else {
                    dispatch(setShowHelpMenu(false));
                }
            },
            content: () => (
                <>
                    {renderTourBox(t('tour_profiles'))}
                    {renderOrangeBox(
                        t('tour_profiles_lmu')
                    )}
                </>),
        },
    
        {
            disableBeacon: true,
            target: '[data-tut="tour__helpmenu"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                dispatch(setShowHelpMenu(true));
                if (fromPrev) {
                    
                } else {
                    history.replace('/workspaces/6');
                }
            },
            content: () => (
                renderTourBox(t('tour_profiles_user_guide'))
            ),
        },
    
        {
            disableBeacon: true,
            target: '[data-tut="tour__workspace-1"]',
            placement: 'right',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                if (fromPrev) {
                    dispatch(setShowHelpMenu(false));
                    history.replace('/workspaces/1');
                } else {
                    
                }
            },
            content: () => (
                renderTourBox(t('tour_recent_clips'))
            )
            
        },
    
        {
            disableBeacon: true,
            placement: 'right',
            target: '[data-tut="tour__workspace-1"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                if (fromPrev) {
                    
                } else {
                    history.replace('/workspaces/1');
                }
            },
            content: () => (
                <>
                    {renderTourBox(t('tour_recent_clips_media_editor'))}
                    {renderOrangeBox(
                        <>{t('tour_recent_clips_media_editor_download') + ' '}<a href={`mailto:${supportEmail}`}>{supportEmail}</a></>
                    )}
                </>)
            
        },
        
        {
            disableBeacon: true,
            placement: 'right',
            target: '[data-tut="tour__workspace-4"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                history.replace('/workspaces/4');
                if (fromPrev) {
                    
                } else {
                    
                }
            },
            content: () => (
                <>
                    {renderTourBox(t('tour_goto'))}
                    {renderOrangeBox(
                        <>{t('tour_goto2') + ' '}<a href={`mailto:${supportEmail}`}>{supportEmail}</a></>
                    )}
                </>
            )
            
        },
    
        {
            placement: 'right',
            disableBeacon: true,
            target: '[data-tut="tour__workspace-8"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                if (fromPrev) {
                    history.replace('/workspaces/8');
                } else {
    
                }
            },
            content: () => (
                renderTourBox(t('tour_search'))
            )
            
        },
    
        {
            disableScrolling: true,
            disableBeacon: true,
            target: '[data-tut="tour__search_add_profile"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                history.replace('/workspaces/8');
                if (fromPrev) {
                    
                } else {
                }
            },
            content: () => (
                renderTourBox(t('tour_search_add_profile'))
            )
            
        },
    
        {
            placement: 'right',
            disableBeacon: true,
            target: '[data-tut="tour__workspace-5"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                history.replace('/workspaces/5');
                if (fromPrev) {
    
                } else {
                    
                }
            },
            content: () => (
                renderTourBox(t('tour_waterfall'))
            )
            
        },
    
        {
            placement: 'right',
            disableBeacon: true,
            target: '[data-tut="tour__workspace-9"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                history.replace('/workspaces/9');
                if (fromPrev) {
                    
                } else {
                    dispatch(setShowHelpMenu(false));
                }
            },
            content: () => (
                renderTourBox(t('tour_analytics'))
            )
        },
    
        {
            disableBeacon: true,
            target: '[data-tut="tour__workspaceFilter"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                if (fromPrev) {
                    history.replace('/workspaces/9');
                } else {
                    dispatch(setShowHelpMenu(false));
                }
            },
            content: () => (
                <>
                    {renderTourBox(t('tour_analytics_filters'))}
                </>),
            spotlightPadding: 3
        },
    
        {
            disableBeacon: true,
            target: '[data-tut="tour__widgetFilter"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                if (fromPrev) {
                    
                } else {
                    dispatch(setShowHelpMenu(false));
                    history.replace('/workspaces/9');
                }
            },
            content: () => (
                <>
                    {renderTourBox(t('tour_analytics_filters2'))}
                </>),
            spotlightPadding: 3
        },
        {
            disableBeacon: true,
            target: '[data-tut="tour_placeholder"]',
            placement: 'center',
            content: () => (
                <>
                    {renderTourBox(t('tour_almost_done'))}
                </>),
            spotlightPadding: 3,
        },
    
        {
            disableBeacon: true,
            target: '[data-tut="tour__helpmenu"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                dispatch(setShowHelpMenu(true));
                if (fromPrev) {
                    
                } else {
                    dispatch(setShowUserMenu(false));
                }
            },
            content: () => (
                <>
                    {renderTourBox(t('tour_help_menu'))}
                </>),
            
        },
    
        {
            disableBeacon: true,
            target: '[data-tut="tour__usermenu"]',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                dispatch(setShowHelpMenu(false));
                dispatch(setShowUserMenu(true));
                if (fromPrev) {
                    
                } else {
    
                }
            },
            content: () => (
                <>
                    {renderTourBox(t('tour_user_menu'))}
                </>
            )
            
        },
    
        {
            disableBeacon: true,
            target: '[data-tut="tour_placeholder"]',
            placement: 'center',
            cAction: ({ fromPrev }: JoyrideTour.CAction) => {
                dispatch(setShowHelpMenu(true));
                if (fromPrev) {
                    dispatch(setShowUserMenu(false));
                } else {
                    
                }
            },
            content: () => (
                <>
                    {renderTourBox(t('tour_done'))}
                </>),
            
        },
    ];

    return steps;
}

export {getTourSteps}