const topicMentionDetailsTableData = {
    "header": [
      {
        "Header": "Profile Group",
        "accessor": "group",
        "sum": 0
      },
      {
        "Header": "AM Radio",
        "condition": "AM Radio",
        "columns": [
          {
            "Header": "Mentions",
            "apiProp": "_count",
            "accessor": "AM Radio._count",
            "sum": 6589
          },
          {
            "Header": "%",
            "calc": {
              "type": "%",
              "accessor": "AM Radio._count"
            },
            "accessor": "AM Radio._count_%",
            "unit": "%",
            "digits": 0,
            "sum": 100
          },
          {
            "Header": "Ad value",
            "apiProp": "ave",
            "accessor": "AM Radio.ave",
            "sum": 0
          },
          {
            "Header": "Reach",
            "apiProp": "reach",
            "accessor": "AM Radio.reach",
            "sum": 0
          }
        ]
      },
      {
        "Header": "FM Radio",
        "condition": "FM Radio",
        "columns": [
          {
            "Header": "Mentions",
            "apiProp": "_count",
            "accessor": "FM Radio._count",
            "sum": 25433
          },
          {
            "Header": "%",
            "calc": {
              "type": "%",
              "accessor": "FM Radio._count"
            },
            "accessor": "FM Radio._count_%",
            "unit": "%",
            "digits": 0,
            "sum": 100
          },
          {
            "Header": "Ad value",
            "apiProp": "ave",
            "accessor": "FM Radio.ave",
            "sum": 0
          },
          {
            "Header": "Reach",
            "apiProp": "reach",
            "accessor": "FM Radio.reach",
            "sum": 0
          }
        ]
      },
      {
        "Header": "TV",
        "condition": "TV",
        "columns": [
          {
            "Header": "Mentions",
            "apiProp": "_count",
            "accessor": "TV._count",
            "sum": 22096
          },
          {
            "Header": "%",
            "calc": {
              "type": "%",
              "accessor": "TV._count"
            },
            "accessor": "TV.count_%",
            "unit": "%",
            "digits": 0,
            "sum": 100
          },
          {
            "Header": "Ad value",
            "apiProp": "ave",
            "accessor": "TV.ave",
            "sum": 0
          },
          {
            "Header": "Reach",
            "apiProp": "reach",
            "accessor": "TV.reach",
            "sum": 0
          }
        ]
      },
      {
        "Header": "Web Text",
        "condition": "Web Text",
        "columns": [
          {
            "Header": "Mentions",
            "apiProp": "_count",
            "accessor": "Web Text._count",
            "sum": 0
          },
          {
            "Header": "%",
            "calc": {
              "type": "%",
              "accessor": "Web Text._count"
            },
            "accessor": "Web Text._count_%",
            "unit": "%",
            "digits": 0,
            "sum": 0
          },
          {
            "Header": "Ad value",
            "apiProp": "ave",
            "accessor": "Web Text.ave",
            "sum": 0
          },
          {
            "Header": "Reach",
            "apiProp": "reach",
            "accessor": "Web Text.reach",
            "sum": 0
          }
        ]
      },
      {
        "Header": "Web TV",
        "condition": "Web TV",
        "columns": [
          {
            "Header": "Mentions",
            "apiProp": "_count",
            "accessor": "Web TV._count",
            "sum": 56
          },
          {
            "Header": "%",
            "calc": {
              "type": "%",
              "accessor": "Web TV._count"
            },
            "accessor": "Web TV._count_%",
            "unit": "%",
            "digits": 0,
            "sum": 100
          },
          {
            "Header": "Ad value",
            "apiProp": "ave",
            "accessor": "Web TV.ave",
            "sum": 0
          },
          {
            "Header": "Reach",
            "apiProp": "reach",
            "accessor": "Web TV.reach",
            "sum": 0
          }
        ]
      },
      {
        "Header": "Press",
        "condition": "Press",
        "columns": [
          {
            "Header": "Mentions",
            "apiProp": "_count",
            "accessor": "Press._count",
            "sum": 0
          },
          {
            "Header": "%",
            "calc": {
              "type": "%",
              "accessor": "Press._count"
            },
            "accessor": "Press._count_%",
            "unit": "%",
            "digits": 0,
            "sum": 0
          },
          {
            "Header": "Ad value",
            "apiProp": "ave",
            "accessor": "Press.ave",
            "sum": 0
          },
          {
            "Header": "Reach",
            "apiProp": "reach",
            "accessor": "Press.reach",
            "sum": 0
          }
        ]
      },
      {
        "Header": "Social TV",
        "condition": "SocialTV",
        "columns": [
          {
            "Header": "Mentions",
            "apiProp": "_count",
            "accessor": "SocialTV._count",
            "sum": 14
          },
          {
            "Header": "%",
            "calc": {
              "type": "%",
              "accessor": "SocialTV._count"
            },
            "accessor": "SocialTV._count_%",
            "unit": "%",
            "digits": 0,
            "sum": 100
          },
          {
            "Header": "Ad value",
            "apiProp": "ave",
            "accessor": "SocialTV.ave",
            "sum": 0
          },
          {
            "Header": "Reach",
            "apiProp": "reach",
            "accessor": "SocialTV.reach",
            "sum": 0
          }
        ]
      }
    ],
    "data": [
      {
        "data": {
          "group": "0 FRONTEX",
          "FM Radio": {
            "_count": 25433,
            "_count_%": 100
          },
          "TV": {
            "_count": 22096,
            "count_%": 100
          },
          "AM Radio": {
            "_count": 6589,
            "_count_%": 100
          },
          "Web TV": {
            "_count": 56,
            "_count_%": 100
          },
          "SocialTV": {
            "_count": 14,
            "_count_%": 100
          }
        },
        "id": 129119,
        "group": "0 FRONTEX",
        "progress": 100
      }
    ]
  }
  
export default topicMentionDetailsTableData;