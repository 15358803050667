import React from 'react';
import styles from './WaterfallItem.module.scss';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import defaultThumbnail from '../../../../assets/defaultThumbnail.png';

class WaterfallItem extends React.Component {
    static propTypes = {
        onClick: PropTypes.func,
        onThumbnailClick: PropTypes.func, // function; ; defines action that happens when clicking on thumbnail
        itemRef: PropTypes.func,
        title: PropTypes.string,    // html title attribute of components div
        thumbnailTitle: PropTypes.string,   // html title attribute of thumbnail wrapper
        headerActiveStyle: PropTypes.string
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { item, active, title, thumbnailTitle } = this.props;        
        const thumbnail = item.kfLink ? item.kfLink : {
            thumbnail: defaultThumbnail
        };                
        // const d = timestampToDate(item.beginTS);
        // const dateString = dateToFullStringIfRequired(d);
        const dateString = item.beginF.slice(0, -3);
        let itemStyle = [styles.item, styles.disableSelection];
        let headerStyle = [styles.header, this.props.theme.backgroundPrimary];
        if (this.props.headerActiveStyle && active) { headerStyle.push(this.props.headerActiveStyle); }
        
        if (!active){
            itemStyle.push(styles.inactive);
            // headerStyle.push(theme.secondaryDisabledBackground);
        }
        const onClick = this.props.onClick !== undefined ? this.props.onClick : () => { window.open(item.viewLink, '_blank') };
        const onThumbnailClick = this.props.onThumbnailClick ? this.props.onThumbnailClick : () => { };

        return (
            <div
                className={classNames(itemStyle)}
                style={this.props.style}
                ref={this.props.itemRef}
                onClick={onClick}
                title={title}
            >
                <div className={classNames(headerStyle)}>
                    <span>{item.channelDisplayName ? item.channelDisplayName : item.channel}</span>
                    <span>{dateString}</span>
                </div>
                <div className={styles.body}>
                    <div
                        onClick={onThumbnailClick}
                        className={styles.thumbnailWrapper}>
                        <img
                            className={styles.thumbnail}
                            src={thumbnail}
                            alt="thumbnail"
                            onError={this.addDefaultThumbnail}
                            title={thumbnailTitle}
                        />
                        </div>
                    <div
                        className={classNames(styles.text, this.props.theme.hitBox)}
                        dangerouslySetInnerHTML={{ __html: item.desc }}
                    >
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(WaterfallItem);