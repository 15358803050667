import React from 'react'
import { useAppSelector } from 'src/redux/hooks';
import { getChannelIdByName, getGroupsByChannelId } from '../utils/utils';

const useChannelExistsInGroup = (channelName: string) => {

    const privateChannelGroups = useAppSelector((state) => state.channelGroupReducers);
    const channels = useAppSelector((state) => state.channelReducers.data);

    const channelIds = getChannelIdByName(channelName, channels);

    const groupsInfo = getGroupsByChannelId(channelIds, privateChannelGroups);

  return {groupsInfo}
}

export default useChannelExistsInGroup