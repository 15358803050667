import React, { ChangeEvent, FC, SyntheticEvent, useEffect, useState } from 'react';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import styles from './DuplicateRow.module.scss';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/Widgets/common/basicElements/TextInput/TextInput';
import { useDispatch } from 'react-redux';
import { duplicateNameSet } from 'src/redux/actions/actions.channelGroupsWidget';
import useDebounce from 'src/hooks/useDebounce';
import { DuplicateRowProps } from './DuplicateRow.types';
import { useAppSelector } from 'src/redux/hooks';

const DuplicateRow: FC<DuplicateRowProps> = ({ duplicateFunction, labelText, externalClass }) => {

    const { t } = useTranslation();
    const [duplicateInput, setDuplicateInput] = useState('');
    
    const { createStatusLog } = useAppSelector(
        (state) => state.channelGroupsWidgetReducer
    ) as { createStatusLog: { isSuccess: boolean } };

    const theme = useAppSelector(state => state.theme)

    const debouncedDuplicateInput = useDebounce(duplicateInput, 200);
    const dispatch = useDispatch();

    //Only dispatched after debounceTime over, using useDebounce Custom hook.
    useEffect(() => {
        dispatch(duplicateNameSet(duplicateInput));
    }, [debouncedDuplicateInput]);

    const duplicateInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        let duplicateInputValue = e.target.value
        duplicateInputValue = duplicateInputValue.replace(/^\s/g, '')
        setDuplicateInput(duplicateInputValue)
    }

    return (
        <div className={styles.duplicateRow}>
            <h3>{labelText}</h3>
            <div className={styles.duplicateInputContainer}>
              {createStatusLog?.isSuccess ? <span className={`${styles.label} ${theme.textSuccess}`}>{t('Successfully created')}</span>
              :
              <>
              <TextInput
                    inputClassName={externalClass ? externalClass : ''}
                    className={styles.duplicateNameInput}
                    placeholder={t('Enter a name for duplicate')}
                    onChange={duplicateInputHandler}
                    value={duplicateInput}
                />
                <Button
                    onClick={duplicateFunction}
                    btnClass="btnSecondary"
                    type="downloadButton"
                    disabled={!duplicateInput}
                >
                    {t('Duplicate')}
                </Button></>
              }
                
            </div>
        </div>
    );
};

export default DuplicateRow;
