import React from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';

class RouteChangeListener extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.onRouteChange();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname === this.props.pathname) return;
        this.onRouteChange(prevProps);
    }

    onRouteChange(prevProps) {
        const prevPath = prevProps ? prevProps.location.pathname : undefined;
        const { actions } = this.props;
        _.map(actions, (action) => {
            action(this.props.location.pathname, prevPath);
        });
    }

    render() {
        return null;
    }
}
export default withRouter(RouteChangeListener);