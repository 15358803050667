// Skeleton.tsx
import React from 'react';
import styles from './Skeleton.module.scss';

const Skeleton: React.FC<{ width: string, height: string, isClipCard: boolean }> = ({ width, height, isClipCard }) => {
    return (
        <div className={styles.skeleton} style={{ width, height }}>
           
            {isClipCard && (
                 <>
                 <div className={styles.skeletonThumbnail}/>
                 <div className={styles.skeletonTitleContainer}>
                     <div className={styles.skeletonTitle}/>
                     <div className={styles.skeletonTitle}/>
                 </div>
                 </>
            )}
        </div>
    );
};

export default Skeleton;
