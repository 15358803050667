import React from 'react';
import styles from './VideoPlayer.module.scss';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import Hls from 'hls.js';
import APITelemetry from '../../../API/APITelemetry';

class VideoPlayer extends React.Component {
    static propTypes = {
        autoPlay: PropTypes.bool, // boolean; default=false; define if video should start playing without user interaction
        onVideoEnded: PropTypes.func,    //
        url: PropTypes.string.isRequired,    // video source
        showControls: PropTypes.bool,    // default = true;
        fullscreen: PropTypes.bool,  // default = false;
        hls: PropTypes.bool,    // default = true;
        hlsRef: PropTypes.func,
        videoRef: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.state = {
            hlsReady: false
        };
        this.hls = undefined;

        this.setVideoRef = this.setVideoRef.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {   // TODO: figure out why this would be needed to avoid continuous reloads of this component ?
        if (nextProps.url !== this.props.url || this.state.hlsReady !== nextState.hlsReady) {
            return true;
        }
        else {
            return false;
        }
    }

    componentDidUpdate() {
        if (this.videoRef && this.props.onVideoEnded) {
            this.videoRef.removeEventListener('ended', this.props.onVideoEnded, false);
            this.videoRef.addEventListener('ended', this.props.onVideoEnded, false);
        }
        if (this.props.fullscreen === true)
        {
            this.videoRef.requestFullscreen();
        }
    }

    componentDidMount() {

    }

    loadVideo() {
        if (this.props.hls && Hls.isSupported()) {
            if (this.hls) { this.hls.destroy(); }
            this.hls = new Hls({ enableWorker: false, startPosition: 0 });
            if(this.props.hlsRef) this.props.hlsRef(this.hls);
            const that = this;
            this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
                if (that.props.autoPlay) {
                    that.props.clearFailedVideo();
                    that.videoRef.play();
                }
            });
            this.hls.on(Hls.Events.ERROR, function (event, data) {
                console.log(event, data);
                let errString = 'Hls --> type:' + data.type + ' --> details: ' + data.details;
                if (data.frag) errString += ' --> url: ' + data.frag._url;
                APITelemetry.post({
                    msg: errString
                });
                switch (data.type) {
                    case 'networkError':
                        that.props.onVideoFailed(data.networkDetails.status);
                        break;
                    default: break;
                    // TODO maybe: if buffer stalled: wait x and if still stalled: show "device offline msg" ?
                }
            });
            this.setState({ hlsReady: true });
        }
        if (this.props.hls && this.state.hlsReady) {
            this.videoRef.pause();
            this.hls.loadSource(this.props.url);
            this.hls.attachMedia(this.videoRef);
        }
    }

    setVideoRef(ref) {
        this.props.videoRef(ref)
        this.videoRef = ref;
    }

    render() {
        this.loadVideo();
        const showControls = this.props.showControls !== undefined ? this.props.showControls : true;        
        return (
                <video
                    controls={showControls}
                    className={styles.player}
                    ref={this.setVideoRef}
                >
                { !this.props.hls ? <source src={this.props.url} type="video/mp4" /> : null }
                </video>
        );
    }
}
export default withTranslation()(VideoPlayer);