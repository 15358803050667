import React, { Component } from 'react';
import _ from 'lodash';
import styles from './ChannelGroup.module.css';
import LoadingSpinner from '../../basicElements/LoadingSpinner/LoadingSpinner';
import APIChannels from '../../../../API/APIChannels';
import APIChannelGroups from '../../../../API/APIChannelGroups';
import SearchBar from '../../basicElements/SearchBar/SearchBar';
import FormFooter from '../../basicElements/FormFooter/FormFooter';
import ItemSelection from '../../basicElements/ItemSelection/ItemSelection';
import { connect } from 'react-redux';
import {
    getGroups,
    addChannels, removeChannels, addChannelGroups, removeChannelGroups
} from '../../../../redux/actions/actions.channelGroups';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

/**
 * channelselection for channelgroup updates!
 * (not a generic component, see ItemSelection for that)
 *
 * props:
 *  - groupId
 *  - onGoBack
 */
class ChannelGroup extends Component {
    static propTypes = {
        groupId: PropTypes.number.isRequired,
        onGoBack: PropTypes.func.isRequired
    }
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            edited: false,

            searchTerm: '',

            // channelIds:[],
            channels: [],
            channelGroups: [],
            selected: { channels: [], channelGroups: [] },

            allChannelsLoaded: true,

            pageLimit: 3,

            saveSuccessful: false,
            saveFailed: false
        };

        this.channelAttributes = ['id', 'name', 'country'];
        // translation method with fallback
        this.t = this.props.t || (k => k);

        this.save = this.save.bind(this);
        this.search = this.search.bind(this);
        this.loadMoreChannels = this.loadMoreChannels.bind(this);
    }

    mapChannels(apiChannels) {
        return _.map(apiChannels, c => ({
            id: c.id,
            name: c.name,
            country: c.country,
            icon: c.icon,
            type: c.type,
            isSelected: false,
            statusChanged: false
        }));
    }

    mapChannelGroups(apiChannelGroups) {
        return _.map(apiChannelGroups, g => ({
            id: g.id,
            title: g.title,
            isSelected: false,
            statusChanged: false
        }));
    }

    async componentDidMount() {
        const apiChannels = (await APIChannels.get()).data;
        // const apiChannelsIds = await APIChannels.get();
        // const apiChannels = [await APIChannels.get(apiChannelsIds[0].id)];
        
        this.props.getGroups();
        
        const apiChannelGroups = this.props.channelGroupReducers;
        const apiSelected = (await APIChannelGroups.get(this.props.groupId)).data;

        this.allItems = {};
        this.allItems.channels = this.mapChannels(apiChannels);
        this.allItems.channelGroups = this.mapChannelGroups(apiChannelGroups);

        this.setState({
            // channelIds: apiChannelsIds,
            channels: this.allItems.channels,
            channelGroups: this.allItems.channelGroups,
            isLoading: false,
            selected: {
                channelGroups: apiSelected.channelGroups,
                channels: this.getIdArray(apiSelected.channels)
            }
        });
    }

    // builds number array from id field in structure like
    // { a: [ {id, ...}, {...} ], b: ...}
    getIdArray(collection) {
        let idArray = [];

        for (let i = 0; i < collection.length; i++) {
            const c = collection[i];
            if (c.id !== null && c.id !== undefined)
                idArray.push(c.id);
            
        }
        return idArray;
    }

    changeSelected(type, ids) {
        this.setState(state => {
            // everything false
            _.forEach(state.selected[type], id => {
                let item = _.find(this.allItems[type], { id });
                if (item) item.isSelected = false;
            });

            // selected true
            _.forEach(ids, id => {
                let item = _.find(this.allItems[type], { id });
                if (item) item.isSelected = true;
            });

            // get all changed values
            const changed = _.concat(
                _.difference(state.selected[type], ids),
                _.difference(ids, state.selected[type])
            );

            // change changed status, to only PUT the changed ones
            // for performance
            _.forEach(changed, id => {
                let item;

                item = _.find(this.allItems[type], { id });
                if (item) item.statusChanged = true;

                item = _.find(this.state[type], { id });
                if (item) item.statusChanged = true;
            });

            // set new selected obj
            let out = {};
            out.selected = state.selected;
            out.selected[type] = ids;
            out.edited = true;

            return out;
        });
    }

    renderChannelGroups() {
        const selectables = _.reduce(this.state.channelGroups, (arr, group, idx) => {
            if (group.id !== this.props.groupId) {  // don't display channel group that is currently selected
                arr.push({
                    id: group.id,
                    text: group.title
                });
            }
            return arr;
        }, []);        

        return (
            <section className={styles.checklistWrapper}>
                <span className={styles.checklistHeader}>{this.t('Channel Groups')}</span>

                <ItemSelection
                    // TODO: pagination? -> probably not needed, since not that many groups (yet)
                    onSelectionChange={ids =>
                        this.changeSelected('channelGroups', ids)
                    }
                    items={selectables}
                    selected={this.state.selected.channelGroups}
                />
            </section>
        );
    }

    // boolean search - is there a search going on currently?
    async loadMoreChannels(search) {
        let apiChannels;

        if (!search) {
            apiChannels = (await APIChannels.get()).data;
            // apiChannels = await APIChannels.get(this.state.channelIds[this.state.channels.length].id);
        } else {
            apiChannels = (await APIChannels.search(
                this.state.searchTerm,
                this.state.channels.length,
                this.state.pageLimit,                
            )).data;
        }

        const channels = this.mapChannels(apiChannels);
        // const channels = this.mapChannels([apiChannels]);
        let allChannelsLoaded = false;

        // if (this.state.channels.length === this.state.channelIds) allChannelsLoaded = true;

        const concatChannels = _.concat(this.state.channels, channels);

        if (!search) this.allItems.channels = concatChannels;

        this.setState({
            channels: concatChannels,
            allChannelsLoaded
        });
    }

    renderChannels() {
        const selectables = _.map(this.state.channels, channel => ({
            id: channel.id,
            icon: channel.icon,
            mediaType: channel.type,
            text: channel.name,
            secondaryText: channel.country
        }));

        return (
            <section className={styles.checklistWrapper}>
                <span className={styles.checklistHeader}>{this.t('Channels')}</span>

                <ItemSelection
                    // loadMore={() =>
                    //     this.loadMoreChannels(this.state.searchTerm)
                    // }
                    hasMore={false}
                    // hasMore={!this.state.allChannelsLoaded}
                    onSelectionChange={ids =>
                        this.changeSelected('channels', ids)
                    }
                    items={selectables}
                    selected={this.state.selected.channels}
                />
            </section>
        );
    }

    // TODO: catch api errors
    save() {
        let updateData = {};

        updateData.channels = this.createUpdateData(
            this.allItems.channels,
            this.state.channels,
            this.state.selected.channels
        );
        updateData.channelGroups = this.createUpdateData(
            this.allItems.channelGroups,
            this.state.channelGroups,
            this.state.selected.channelGroups
        );
        
        this.props.addChannels(this.props.groupId, updateData.channels.add);
        this.props.removeChannels(this.props.groupId, updateData.channels.remove);
        this.props.addChannelGroups(this.props.groupId, updateData.channelGroups.add);
        this.props.removeChannelGroups(this.props.groupId, updateData.channelGroups.remove);
        // TODO: wait until above 4 are finished, then show success

        this.allItems.channels = this.resetChangeStatus(this.allItems.channels);
        this.allItems.channelGroups = this.resetChangeStatus(
            this.allItems.channelGroups
        );

        this.setState(state => ({
            edited: false,
            saveSuccessful: true,
            channels: this.resetChangeStatus(state.channels),
            channelGroups: this.resetChangeStatus(state.channelGroups)
        }));
    }

    createUpdateData(allObjs, stateObjs, selectedStateObjs) {
        let src;
        let out = {add:[], remove:[]};

        // unionize the two parts
        src = _.unionWith(allObjs, stateObjs, _.isEqual);

        // remove unchanged ones, because not necessary
        src = _.filter(src, c => c.statusChanged === true);
        src.map((i)=>{
            i.isSelected ? out.add.push(i) : out.remove.push(i);
            return undefined;
        });

        return out;
    }

    // returns new collection with collection[i].statusChanged = false
    resetChangeStatus(collection) {
        let out = _.cloneDeep(collection);

        for (let i = 0; i < out.length; i++) {
            out[i].statusChanged = false;
        }

        return out;
    }

    async search(searchTerm) {
        if (searchTerm.length >= 3) {
            this.debouncedSearch(searchTerm);
        } else {
            this.setState({
                searchTerm: '',
                channels: this.allItems.channels,
                channelGroups: this.allItems.channelGroups
            });
        }
    }

    async sendSearch(searchTerm) {
        const channels = this.state.channels.filter((c) => { return c.name.toLowerCase().includes(searchTerm.toLowerCase()); });
        const channelGroups = this.state.channelGroups.filter((c) => { return c.title.toLowerCase().includes(searchTerm.toLowerCase()); });
        // const channels = (await APIChannels.search(
        //     searchTerm,
        //     0,
        //     this.state.pageLimit
        // )).data;
        // const channelGroups = (await APIChannelGroups.search(searchTerm)).data;

        this.setState({
            searchTerm: searchTerm,
            allChannelsLoaded: false,
            channels: this.mapChannels(channels),
            channelGroups: this.mapChannelGroups(channelGroups)
        });
    }

    debouncedSearch = _.debounce(term => this.sendSearch(term), 250, {
        maxWait: 500
    });

    render() {
        if (this.state.isLoading) {
            return <LoadingSpinner />;
        }

        return (
            <div className={styles.wrapper}>
                <article className={styles.content}>
                    <SearchBar search={this.search} />
                    <section className={styles.checklistsWrapper}>
                        {this.renderChannels()}
                        {this.renderChannelGroups()}
                    </section>

                    <FormFooter
                        onGoBack={this.props.onGoBack}
                        onAction={this.save}
                        actionDisabled={!this.state.edited}
                        actionLabel={this.t('Save Changes')}
                        success={
                            this.state.saveSuccessful
                                ? this.t('Successfully saved')
                                : null
                        }
                        fail={this.state.saveFailed ? this.t('Could not save') : null}
                    />
                </article>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { channelGroupReducers: state.channelGroupReducers }
}

export default withTranslation()(connect(mapStateToProps, {
    getGroups,
    addChannels, removeChannels,
    addChannelGroups, removeChannelGroups
})(ChannelGroup));