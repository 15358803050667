import GenericCalls from './genericCalls';
import _ from 'lodash';
import { dateToString, getDatesArrayFromRange, objectToParamsString, stringToDate } from '../Widgets/common/helpers';

class APIStatistics {
    static async getProfilesCoverage(profileId, startDate, endDate) {
        let res = await GenericCalls.get(
            `/api/analytics/_count,profileTitle/by/profileID,userdate/select/profiles/${profileId}?from=${startDate}&to=${endDate}`
        );
        let rv = {...res, data: {data: []}};
        const dates = getDatesArrayFromRange(stringToDate(startDate), stringToDate(endDate));
        rv.data.data = dates.map(d => {
            let data = _.find(res.data, { userdate: dateToString(d) });
            return data ? data._count : 0;
        });
        return rv;
    }

    // NOTE: we deprecated this backend call below. do not use it if it still exists, instead use /api/analytics/... or request extension of it if its missing information
    // static async getProfileGroupsCoverage(startDate, endDate, data = 'profiles') {
    //     return await GenericCalls.get(
    //         `/api/statistics/profilegroups?start=${startDate}&end=${endDate}&data=${data}`
    //     );
    // }

    static async getCategoriesOverTime(user, start, end) {
        const params = { user, start, end };
        let res = await GenericCalls.get(`/api/statistics/editorial/categories${objectToParamsString(params)}`);
        res.data.data = _.map(res.data.data, (d) => {d.data = d.values; delete d.values; return d; }); // NOTE: possible code cleanup
        return res;
    }

    static async getUsersOverTime(category, start, end) {
        const params = { category, start, end };
        let res = await GenericCalls.get(`/api/statistics/editorial/users${objectToParamsString(params)}`);
        res.data.data = _.map(res.data.data, (d) => {d.data = d.values; delete d.values; return d; }); // NOTE: possible code cleanup
        return res;
    }

    static async getProfilesOverTime(category, start, end) {
        const params = { category, start, end };
        let res = await GenericCalls.get(`/api/statistics/editorial/profiles${objectToParamsString(params)}`);
        res.data.data = _.map(res.data.data, (d) => {d.data = d.values; delete d.values; return d; }); // NOTE: possible code cleanup
        return res;
    }

    static async getCategoriesPerUser(userId, startDate, endDate) {
        let d = await APIStatistics.getCategoriesOverTime(userId, startDate, endDate);
        let data = _.map(d.data.data, (category) => ({   // calculate sum for each category
            name: category.title,
            value: _.reduce(category.data, (sum, val) => (sum + val), 0),
        }));
        d.data = {
            data,
            filters:d.data.filters
        };
        console.log(d);
        return d;
    }

    static async generateChart(data, type, subtype) {
        const fileContent = await GenericCalls.post('/api/statistics/xlsx',
            { data, type, subtype },
            { responseType: 'blob' });
        return fileContent;
    }
}

export default APIStatistics;
