import React, { Component } from 'react';
import StatsHeader from '../StatsHeader/StatsHeader';
import styles from './ProfileStats.module.scss';
import _ from 'lodash';
import APIProfileGroups from '../../../API/APIProfileGroups';
import { dateToString, calcDaysDifference, trimArrayToLength, downloadFile, getDatesArrayFromRange, dateToNoTime, getWidgetState } from '../../common/helpers';
import { withTranslation } from 'react-i18next';
import Button from '../../common/basicElements/Button/Button';
import APIStatistics from '../../../API/APIStatistics';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import { connect } from 'react-redux';
import APIReporting from 'src/API/APIReporting';
import DownloadButton from 'src/Widgets/common/basicElements/DownloadButton/DownloadButton';

/**
 * generic coverage widget, can be seen as an abstract class
 * renders one of the given children with startDate and coverageData props
 * coverageData depends on API response call
 * 
 * props:
 *  - apiCall - func(id, dateString), function to retrieve data from
 *  - children - data display components that take startDate and coverageData as props,
 *              'name' prop is be used for tab names
 */
class ProfileStats extends Component {
    constructor(props) {
        super(props);

        let start = new Date();
        start.setDate(start.getDate() - 6);

        this.state = {
            dateRange: [start, new Date()],
            selectedProfiles: null,
            // selectedProfiles: [],
            coverageData: [],
            activeTab: props.params !== undefined && props.params.get('tab') ? parseInt(props.params.get('tab')) || 0 : 0,
            retrievingData: false,
            // selectablesFiltered: []
        };

        this.onProfilesChange = this.onProfilesChange.bind(this);
        this.onDateRangeChange = this.onDateRangeChange.bind(this);
        this.moveDate = this.moveDate.bind(this);
        // this.download = this.download.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
    }

    // returns updated data state
    // TODO: optimize for real data (skip already loaded, etc)
    async retrieveData(profiles, dateRange, getCall, data) {
        if (!data) data = [];
        if (!profiles || !dateRange || !dateRange[0] || !dateRange[1] || !getCall) return data;

        let newData = _.cloneDeep(data);
        const stringDates = [dateToString(dateRange[0]), dateToString(dateRange[1])];
        const daysDiff = calcDaysDifference(dateRange[0], dateRange[1]);

        // add new profiles to state
        // gets data from api
        for (let i = 0; i < profiles.length; i++) {
            const id = profiles[i].value;

            // don't add / call for anything that's in there already
            // if (_.find(newData, { id })) continue;
            let response = (await getCall(id, stringDates[0], stringDates[1])).data;
            if (!response || response.status) return;

            response.id = id;
            response.title = profiles[i].label;
            response.data = trimArrayToLength(response.data, daysDiff);
            response.group = profiles[i].group;    // add already known profile group title to response data

            newData.push(response);
        }

        // remove removed profiles
        // newData = _.filter(newData, o => _.indexOf(profiles, o.id) !== -1);

        return newData;
    }

    async componentDidMount() {
        const profileGroups = (await APIProfileGroups.get()).data;

        if (!profileGroups || profileGroups.status) return;

        const selectables = _.map(profileGroups, group => ({
            label: group.title,
            options: _.map(group.profiles, profile => ({
                label: profile.title,
                value: profile.id,
                group: group.title
            }))
        }));
        const profileGroupsSelectable = _.map(profileGroups, group =>({
            label: group.title,
            value: group.id
        }))

        if(this.props.getWidgetState !== undefined) {
            this.getCoverageData();
        }

        this.setState({ selectables, profileGroupsSelectable, selectablesFiltered: selectables });
    }

    async onProfilesChange(selectedProfiles) {
        this.setState({retrievingData: true})
        let coverageData = await this.retrieveData(
            selectedProfiles,
            this.state.dateRange,
            this.props.apiCall
        );
        this.setState({retrievingData: false})

        if (!coverageData || coverageData.status) return;

        this.setState({ selectedProfiles, coverageData });

        if(this.props.saveWidgetState !== undefined) {
            await this.saveCoverageData();
        }
    }

    async onDateRangeChange(dateRange,cb) {
        this.setState({retrievingData: true})
        let coverageData = await this.retrieveData(
            this.state.selectedProfiles,
            dateRange,
            this.props.apiCall
        );
        this.setState({retrievingData: false})
        
        if (!coverageData || coverageData.status) return;

        this.setState({ dateRange, coverageData });
        
        if(cb) cb(dateRange);

        if(this.props.saveWidgetState !== undefined) {
            await this.saveCoverageData();
        }
    }

    async saveCoverageData() {
        let widgetState = {}
        widgetState.dateRange = this.state.dateRange;
        widgetState.selectedProfiles = this.state.selectedProfiles?.map(p=>p.value);
        await this.props.saveWidgetState(this.props.workspaceId, this.props.widgetId, widgetState)
    }

    async getCoverageData() {
        let data = await this.props.getWidgetState(this.props.workspaceId, this.props.widgetId)
        // TODO: apply fix below to allow using saved state of coverage widget again - but it needs some fixing - calls "PUT widget" twice on load for some reason

        // let data = await getWidgetState(this.props.workspaces, this.props.board, this.props.widgetId);
        // data.selectedProfiles = data.selectedProfiles?.map(selectedP=>
        //     _.find(this.state.selectables, { options: [ { value: selectedP } ]}).options
        //         .find(p=>p.value===selectedP)
        // );
        if(data !== "" && data !== undefined
            // && data.dateRange
        ) {
            data.dateRange[0] = new Date(data.dateRange[0]);
            data.dateRange[1] = new Date(data.dateRange[1]);
            this.setState({
                dateRange: data.dateRange,
                selectedProfiles: data.selectedProfiles
            })
            this.onDateRangeChange(data.dateRange);
            this.onProfilesChange(data.selectedProfiles);
        }
    }

    async moveDate(forward, diff) {
        const { dateRange } = this.state;

        let startDate = new Date(dateRange[0]);
        let endDate = new Date(dateRange[1]);

        if(forward) {
            endDate.setDate(endDate.getDate() + diff);
            startDate = new Date(endDate);
            startDate.setDate(startDate.getDate() - diff + 1);
        } else {
            startDate.setDate(startDate.getDate() - diff );
            endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + diff - 1);
        }


        const today = dateToNoTime(new Date());
        startDate = startDate < this.props.me.archive.start ? this.props.me.archive.start : startDate;
        endDate = endDate > today ? today : endDate;

        this.onDateRangeChange([startDate, endDate]);
    }

    // async download(type) {
    //     const { dateRange } = this.state;
    //     let dates = getDatesArrayFromRange(dateRange[0], dateRange[1]);
    //     dates = _.map(dates, (d) => dateToString(d));
    //     const data = _.map(this.state.coverageData, (d) => [d.title, ...d.data]);
    //     const d = (await APIStatistics.generateChart([dates, ...data], type));
    //     downloadFile(d, 'eMM_profile-stats_' + dates[0] + '_to_' + dates[dates.length-1] + '.xlsx');
    // }

    onTabClick(activeTab) { 
        if (this.props.params !== undefined) {
            this.props.params.set("tab", activeTab);
            this.props.history.push(`?${this.props.params.toString()}`);
        }
        return this.setState({ activeTab });
    }

    render() {
        const { activeTab } = this.state;
        const children = React.Children.toArray(this.props.children);
        const t = this.props.t || (k => k);

        if(this.props.show){
            return (
                <article className={styles.wrapper}>
                    {/* HEADER */}
                    <StatsHeader
                        minDate={this.props.me.archive.start}
                        dateRange={this.state.dateRange}
                        onDateRangeChange={this.onDateRangeChange}
                        selectPlaceholder={t("Select Profiles") + " ..."}
                        selectables={this.state.selectablesFiltered}
                        selectedItems={this.state.selectedProfiles}
                        onSelectionChange={this.onProfilesChange}
                        moveDate={this.moveDate}
                        tabs={_.map(children, c => c.props.name)}
                        activeTab={activeTab}
                        onTabClick={this.onTabClick}
                        closeMenuOnSelect={false}
                        filterSelectablesOptions={this.state.profileGroupsSelectable}
                        filterSelectPlaceholder={t('Filter profiles by profile group') + ' ...'}
                        onFilterSelectChange={(option)=>{
                            if(option){
                                this.setState({selectablesFiltered: _.filter(this.state.selectables,(s)=>(s.label === option.label))});
                            } else {
                                this.setState({selectablesFiltered: this.state.selectables});
                            }
                        }}
                    />

                    {this.state.retrievingData ? (
                        <LoadingSpinner></LoadingSpinner>
                    ) : (
                        <>
                            {React.cloneElement(children[activeTab], {
                                startDate: this.state.dateRange[0],
                                endDate: this.state.dateRange[1],
                                data: this.state.coverageData
                            })}
                            {this.state.coverageData.length > 0 && children[activeTab].props.xlsxDownload ? (
                                <DownloadButton
                                    type="xlsx"
                                    onClick={ async () => {
                                        const xlsx = await APIReporting.getChartData(
                                            'count',
                                            'profileID,userdate',
                                            {
                                                profile: [this.state.selectedProfiles.map(o => o.value)],
                                                date: this.state.dateRange
                                            },
                                            '_count',
                                            true
                                        );
                                        
                                        downloadFile(
                                            xlsx,
                                            `eMM_profile-stats_${dateToString(this.state.dateRange[0])}_to_${dateToString(this.state.dateRange[1])}.xlsx`,
                                            'xlsx'
                                        );
                                    }
                                    // this.download(
                                    //     children[activeTab].props.series === 'lines'
                                    //         ? 'line'
                                    //         : undefined
                                        // )
                                    }
                                />
                            ) : null}
                        </>
                    )}
                </article>
            );
        }
        else { return null; }
    }
}

const mapStateToProps = (state) => ({ 
    me: state.me,
    // workspaces: state.workspaces,
    // board: state.board,
});

export default connect(mapStateToProps, {})(withTranslation()(ProfileStats));