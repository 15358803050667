import GenericCalls from './genericCalls';

class APINews {
    static async getUnread(start, limit) {
        let pagination = '';
        if (start && limit) pagination += '?start=' + start + '&limit=' + limit;

        return await GenericCalls.get('/api/news/unread' + pagination);
    }

    static async getRead(start, limit) {
        let pagination = '';
        if (start && limit) pagination += '?start=' + start + '&limit=' + limit;

        return await GenericCalls.get('/api/news/read' + pagination);
    }

    static async setToUnread(id) {
        return await GenericCalls.put('/api/news/unread/' + id);
    }

    static async setToRead(id) {
        return await GenericCalls.put('/api/news/read/' + id);
    }
}

export default APINews;