import { useState, useEffect, FC } from 'react';
import styles from './GroupCardList.module.scss'
import GroupCardSingleItem from '../../shared/GroupCardSingleItem/GroupCardSingleItem';
import { useTranslation } from 'react-i18next';
import { getGroups } from 'src/redux/actions/actions.channelGroups';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from 'src/redux/hooks';
import { GroupCardListProps } from './GroupCardList.types';


const GroupCardList: FC<GroupCardListProps> = ({
    channelGroups,
    isAdminUser
}: GroupCardListProps) => {
    
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        let isMounted = true;
        dispatch(getGroups()).finally(() => {
            if (isMounted) setLoading(false);
        });
        return () => {
            isMounted = false;
        };
    }, [dispatch]);

    const groupsConditionalRender = () => {
        if (loading) {
            return <LoadingSpinner size={'2rem'} />;
        } else if (channelGroups.length) {
            return channelGroups
                .sort((a, b) => a?.title.localeCompare(b?.title))
                .map((channelGroup) => (
                    <GroupCardSingleItem
                        groupTitle={channelGroup?.title}
                        key={channelGroup?.id}
                        id={channelGroup?.id}
                        groups={channelGroup?.groups}
                        channels={channelGroup?.channels}
                    />
                ));
        } else {
            return (
                <div className={styles.groupsErrorContainer}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <div className={styles.groupsErrorText}>{t('No groups created')}</div>
                </div>
            );
        }
    };

    return <>{groupsConditionalRender()}</>;
};

export default GroupCardList;
