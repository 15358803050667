import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import styles from './GenericTableFilter.module.scss';
import { GenericTableFilterProps } from './GenericTableFilter.types';
import { useGenericTableFiltering } from 'src/Widgets/common/GenericTable/hooks/useGenericTableFiltering';

import GenericTableSort from '../GenericTableSort/GenericTableSort';
import useGenericTableFilterButtons from '../hooks/useGenericTableFilterButtons';

const GenericTableFilter: React.FC<GenericTableFilterProps> = ({
  openColumnHeader,
  data,
  checkedState,
  handleCheckboxClick,
  handleColumnClick,
  currentlyCheckedValues,
  setSortingDirection,
  sortingDirection

}) => {

  //Destructuring data object
  const { convertedData: { columnHeaders, matrix } } = data;

  //Using filtering logic via filter custom hook
  const {
    generatedCheckboxes,
    isInFilteredData,
    filteredRows,
    isOnlyColumnChecked,
    FunctionalSearchComponent
  } = useGenericTableFiltering(checkedState, data, openColumnHeader, currentlyCheckedValues, sortingDirection);

  const { handleOkButtonClick, handleCancelButtonClick } = useGenericTableFilterButtons(data, checkedState, matrix, handleCheckboxClick, openColumnHeader, handleColumnClick);

  const { t } = useTranslation();

        //Handle "All" checkbox click
const handleSelectAllChange = () => {
    const allCheckboxesChecked = matrix.every((rowData, rowIndex) => {
        const isChecked = !checkedState[openColumnHeader]?.[rowIndex]?.checked;
        return isChecked;
    });

    // If all checkboxes are checked, uncheck all. If not all are checked, check all.
    const newCheckState = !allCheckboxesChecked

    generatedCheckboxes.forEach((row) => {
        handleCheckboxClick(
            { target: { checked: !newCheckState } } as React.ChangeEvent<HTMLInputElement>,
            openColumnHeader,
            row.originalRowIndex
        );
    });
};

    //Check if all checkboxes checked for "All" checkbox checked state
    const isAllCheckboxesChecked = generatedCheckboxes.every((rowData) => {
        const isChecked = checkedState[openColumnHeader]?.[rowData.originalRowIndex]?.checked;
        //const row = Array.isArray(rowData.row) ? rowData.row : [];
        return isChecked //&& isInFilteredData(row)
      });

  return (
      <div
          onClick={(e) => e.stopPropagation()}
          className={styles.genericTableFilterContainer}
      >
          {FunctionalSearchComponent}
          <GenericTableSort
              openColumnHeader={openColumnHeader}
              setSortingDirection={setSortingDirection}
              sortingDirection={sortingDirection}
          />
          <div className={styles.allCheckboxContainer}>
              <label
                  className={styles.genericTableFilterRow}
                  style={{ color: '#000000' }}
              >
                  <input
                      type="checkbox"
                      checked={isAllCheckboxesChecked}
                      onChange={handleSelectAllChange}
                      className={styles.allCheckbox}
                  />
                  <p>{'All'}</p>
              </label>
          </div>
          <div className={styles.genericTableFilterRowsContainer}>
              {generatedCheckboxes.length > 0 ? (
                  generatedCheckboxes.map(({ row, originalRowIndex }) => {
                      const columnIndex = columnHeaders.indexOf(openColumnHeader);
                      const value = row[columnIndex];
                      //Checkbox checked state
                      const isRowChecked = !checkedState[openColumnHeader]?.[originalRowIndex]
                      if (!value) return null;
                      return (
                          <label
                              key={originalRowIndex}
                              className={styles.genericTableFilterRow}
                              //   style={
                              //       !isOnlyColumnChecked &&
                              //       !isInFilteredData(value)
                              //           ? { color: 'gray' }
                              //           : { color: '#000000' }
                              //   }
                          >
                              <input
                                  type="checkbox"
                                  checked={!isRowChecked}
                                  //   disabled={
                                  //       !isOnlyColumnChecked &&
                                  //       !isInFilteredData(value)
                                  //   }
                                  onChange={(e) =>
                                      handleCheckboxClick(
                                          e,
                                          openColumnHeader,
                                          originalRowIndex
                                      )
                                  }
                              />
                              <p
                                //   style={
                                //       !isOnlyColumnChecked && !isInFilteredData(value)
                                //           ? { textDecoration: 'line-through' }
                                //           : { textDecoration: 'none' }
                                //   }
                              >
                                  {value}
                              </p>
                          </label>
                      );
                  })
              ) : (
                  <p>{t('No filters found')}</p>
              )}
          </div>

          <div className={styles.genericTableFilterButtonsContainer}>
              <Button
                  onClick={handleOkButtonClick}
                  btnClass={styles.button}
                  type="downloadButton"
              >
                  {t('Ok')}
              </Button>
              <Button
                  onClick={handleCancelButtonClick}
                  btnClass={styles.button}
                  type="danger"
              >
                  {t('Cancel')}
              </Button>
          </div>
      </div>
  );
};

export default GenericTableFilter;