import { useSelector } from "react-redux";
import styles from './ProgressBar.module.scss';

function ProgressBar({progress, progress2, barStyle, progressStyle}){
    const theme = useSelector((state) => state.theme);

    const renderProgress = () => (
        <div
            style={{
                width:`${progress}%`,
                background: theme.secondary,
                ...progressStyle
            }}
            className={styles.progress}
        >
        </div>
    )

    return (
        <div
            className={styles.bar}
            style={{
                background: theme.primary,
                ...barStyle
            }}
        >
            {
                progress2
                    ? <div
                            style={{
                                width:`${progress2}%`,
                                background: '#AEAEAE',
                                ...progressStyle
                            }}
                            className={styles.progress}>

                            {renderProgress()}
                        </div>
                    : renderProgress()
            }
        </div>
    )
}

export default ProgressBar;