import GenericCalls from './genericCalls';
import _ from 'lodash';
// import pf from './static/PushFeed.js';
import { sleep } from '../Widgets/common/helpers';
import config from 'src/config/config';

class APIPushFeed {

    static tmpConvertViewLink(link) {   // TODO: remove when url adapted to mediaEditor link
        const split = link.split("?");
        return config.urls.mediaEditor + '&' + split[1];
    }

    static convertItems(items) {
        return _.map(items, (i) => {
            // i.viewLink = this.tmpConvertViewLink(i.viewLink);
            i.beginTS = parseInt(i.beginTS);
            return i;
        }).filter(i=> i && i.manual !== 99);   // remove notification items received via pushFeed which are generated from super profiles (but are notifications where the 'alert' query did not match)
    }

    static async get(startID, timeout, profileID, outputType, resType) {
        await sleep(timeout);
        let call = `${config.apiPrefix}/feed/pushFeed?outputType=${outputType || 'json'}`;
        const responseType = resType || undefined;
        if (startID === undefined) startID = -50;
        call += '&startID=' + startID;
        if (timeout !== undefined)
            call += '&timeout=' + timeout;
        if (profileID !== undefined)
            call += '&profileID=' + profileID;
        let results = await GenericCalls.get(call,undefined,{responseType});
        if (results && (!results.status || results.status === 200) && results.data) {
            results.data = this.convertItems(results.data);
        }

        return results;
    }

    // static async get(startID, timeout) { // NOTE: for debugging purposes
    //     const d = pf.get();
    //     d.data = await this.convertItems(d.data); console.log(d); return d;
    // }

    static async getStreamUrl(playerLink) {
        // let url = playerLink.replace(/^http:\/\/mms/gi, "http://nightly") + "&json=1";
        let url = playerLink + "&json=1";
        if (process.env.REACT_APP_ENV === 'testing') {
            url = url.replace(/^http:\/\/nightly.emediamonitor.net/i,"/nightly");
        }
        const resp = await GenericCalls.get(url);
        return resp;
    }
}

export default APIPushFeed;