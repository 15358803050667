import GenericCalls from "./genericCalls";

class APICompanyGroups {
    static async get(id) {
        if (id) return await GenericCalls.get('/api/company-groups/' + id);
        return await GenericCalls.get('/api/company-groups');
    }

    static async put(id, data) {
        return await GenericCalls.put('/api/company-groups/' + id, data);
    }
}

export default APICompanyGroups;