import { RECEIVE_ITEMS, POST_ITEM, DELETE_ITEM } from '../types/actionTypes.epgHistory';
import APISettings from '../../API/APISettings';
import _ from 'lodash';

export const receivedItems = (data) => ({
    type: RECEIVE_ITEMS,
    data: data
});

export const postedItem = (data) => ({
    type: POST_ITEM,
    data: data
});

export const deletedItem = (id) => ({
    type: DELETE_ITEM,
    id: id
});


export const getEpgHistory = () => {
    const store = window.rxStore.getState();
    const max = Math.max(
        store.me.archive.userArchiveStart,
        store.me.archive.dbArchiveStart
    );

    return (dispatch) => {
        return APISettings.getItems('epgHistory').then((res) => {
            let toDelete = [];
            let toKeep = [];
            if (res) {
                res?.data?.map(e => {
                    if (e?.timestamp * 1000 >= max) {
                        toKeep.push(e);
                    } else {
                        toDelete.push(e);
                    }
                });
            }

            // add valid history items to redux store
            dispatch(receivedItems(toKeep));

            // delete all old history items
            // (where timestamp indicates that there is no video / audio for this date anymore)
            toDelete.map(e => dispatch(deleteEpgHistoryItem(e.id)));
            return res;
        })
    }
};

export const postEpgHistoryItem = (data) => {
    return async (dispatch, getState) => {
        let history = getState().epgHistory;
        // if (history.length === 0) {
            dispatch(getEpgHistory()).then((res) => {
                history = res.data;
                post();
            });
        // } else {    // NOTE: add this to reduce fetches (but risk duplicates when using multiple v3 instances simultaneously)
        //     return post();
        // }
        
        function post() {
            if (!historyExists(data, history)) {
                return APISettings.postItem('epgHistory', data).then((res) => {
                    data.id = res.data.id;
                    dispatch(postedItem(data));
                    return res;
                })
            }
        }
    }
};

export const deleteEpgHistoryItem = (id) => {
    return (dispatch) => {
        APISettings.deleteItem('epgHistory', id).then((res) => {
          dispatch(deletedItem(id));
      })
    }
};

const historyExists = (newItem, currentHistory) => {    // checks if item with same props already exists in the list that is stored inside the redux store
    currentHistory = _.cloneDeep(currentHistory);
    newItem = _.omitBy(newItem, _.isUndefined);
    delete newItem.id;
    return _.find(currentHistory, o => { delete o.id; return _.isEqual(_.omitBy(o, _.isUndefined), newItem)}) === undefined ? false : true;
}