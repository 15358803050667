
import classNames from 'classnames';

// FontAwesome Base
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import styles from './BackButton.module.scss'
import { FC } from 'react';
import { BackButtonProps } from './BackButton.types';

/**
 * simple 'back' styled button
 *
 * takes props:
 *  - onClick
 *  - className
 */

const BackButton:FC<BackButtonProps> = ({onClick, className}) => {

    const {t, i18n} = useTranslation()
    const dir = i18n.dir()
    
    return (
        <button
            onClick={onClick}
            className={classNames(styles.goBackButton, className)}
        >
            <FontAwesomeIcon icon={dir === 'rtl' ? faArrowRight : faArrowLeft} /> <span>{t('Back')}</span>
        </button>
    );
}

export default BackButton

/* export class BackButton extends React.Component {
    render() {
        // translation method with fallback
        const t = this.props.t || (k => k);
        const dir = i18n.dir();

        return (
            <button
                onClick={this.props.onClick}
                className={classNames("go-back-button", this.props.className)}
            >
                <FontAwesomeIcon icon={dir === 'rtl' ? "arrow-right" : "arrow-left"} /> <span>{t('Back')}</span>
            </button>
        );
    }
}
export default withTranslation()(BackButton); */
