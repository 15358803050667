import React, { useEffect, useRef, useState } from 'react';
import styles from './ClipCluster.module.scss';

import useClipsFilters from '../hooks/useClipsFilters';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';
import ChannelsColumn from './ChannelsColumn/ChannelsColumn';
import ClipColumn from './ClipColumn/ClipColumn';
import CountryColumn from './CountryColumn/CountryColumn';
import ClusterHeader from './ClusterHeader/ClusterHeader';
import ClipAnalytics from './ClipAnalytics/ClipAnalytics';
import { useAppSelector } from 'src/redux/hooks';
import { ChannelGroup } from 'src/Widgets/ChannelGroupsNext/types/types';
import APIClips from 'src/API/APIClips';
import Popup from 'src/Widgets/common/basicElements/Popup/Popup';
import { useTranslation } from 'react-i18next';

const ClipCluster = ({
    profileGroupContent,
    currentProfileGroup,
    clipStates,
    setClipStates,
    data,
    filteredData,
    filter,
    selectedDateRange,
    isSearch,
    isEdited,
    isEditorial,
    isMentionFindrApp,
    messageHandler,
}: MentionsVisualizerTypes.ClipClusterProps) => {
    const processedIndices: Set<number> = new Set();

    const [fetchedItems, setFetchedItems] = useState<Set<number>>(new Set());
    const [clipDetail, setClipDetail] = useState<MentionsVisualizerTypes.ClipDetailType>(
        {} as MentionsVisualizerTypes.ClipDetailType
    );
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [backFillSelectionVisible, setBackFillSelectionVisible] = useState(false);
    const [isClipStateUpdated, setIsClipStateUpdated] = useState(false);
    const [midAirCollisionPopup, setMidAirCollisionPopup] = useState(false);


    const [isStateUpdateRunning, setIsStateUpdateRunning] = useState(false);
    const channelGroups = useAppSelector((state) => state.channelGroupReducers);
    const me = useAppSelector((state) => state.me);
    const observerRef = useRef<NodeJS.Timeout | null>(null);

    const wFeedAbortCtrl = useRef(new AbortController());

    const {t} = useTranslation();

    const {
        activeFilter,
        setActiveFilter,
        handleChannelFilter,
        handleCountryFilter,
        handleProfileFilter,
        handleSearchChannels,
        handleTypeFilter,
        hasTypeInGroup,
        hasTypeInProfile,
        handleLanguageFilter,
        handleSentimentFilter,
        searchChannelsInput,
        setSearchChannelsInput,
        filteredChannelIcons,
        setFilteredChannelIcons,
        filteredDataToDisplay,
        listKey,
        applyVisualizerFilters,
        hasSentiment,
        sortedUniqueChannels,
        sortedUniqueCountries,
        getLanguagesByCountry,
        selectedLanguages
    } = useClipsFilters({
        profileGroupContent,
        setClipDetail,
        setFetchedItems,
        processedIndices,
        isEditorial
    });

    const notifIds = filteredDataToDisplay
    .slice(0, 1500)  // Limit to 1500 notifIds
    .flatMap((clip: MentionsVisualizerTypes.Clip) => 
        [clip.notifID, ...(clip.duplicates?.map((dup: MentionsVisualizerTypes.Clip) => dup.notifID) || [])]
    )
    // Get unique notifIds
    .filter((notifId: string, index: number, self: string[]) => self.indexOf(notifId) === index)
    
    .join(',');

    //fetch again when active filter changes
    useEffect(() => {
        applyVisualizerFilters();
    }, [activeFilter, selectedLanguages]);

    //fetch again when data changes in search
    useEffect(() => {
        if (isSearch) {
            applyVisualizerFilters();
        }
    }, [data]);

    useEffect(() => {
        if (isEditorial) {
            applyVisualizerFilters();
        }
    }, [filteredData]);

    //Prevent re-render on status filter change
    // // Reset profile filter when the status changes
    // useEffect(() => {
    //     if(isEditorial){
    //         setActiveFilter((prev) => ({
    //             ...prev,
    //             profile: null
    //         }));
    //     }
    // }, [filter.status])

    useEffect(() => {
        if (isEditorial) {
            fetchClipStates(notifIds);
        }
    }, []);
   
 //Editorial Workflow States Fetcher gets notfiIds seperated by commas
 const fetchClipStates = async (notifIds: string) => {
    //if notifIds is empty, return
    if (!notifIds) {
        return;
    }
    try {
        // Fetch the new states from the API
        const res = await APIClips.getClipsStates(notifIds);

        // Convert the fetched states object into an array
        const newStatesArray = Object.values(res.states || {});

        // Merge the new states with the existing ones
        setClipStates((prevClipStates: any) => {
            const existingStates = prevClipStates.states || [];
            const updatedStates = new Map();

            // Add existing states to the map
            existingStates.forEach((state: any) => {
                updatedStates.set(state.id, state);
            });

            // Add new states to the map (this will overwrite any existing states with the same id)
            newStatesArray.forEach((state: any) => {
                updatedStates.set(state.id, state);
            });

            // Convert the map back to an array
            const mergedStatesArray = Array.from(updatedStates.values());

            return {
                ...prevClipStates,
                cl: res.cl,
                msg: res.msg,
                rc: res.rc,
                states: mergedStatesArray
            };
        });
    } catch (error) {
        console.error('Error fetching clip states:', error);
        
    }
};

    // Function to observe state updates in real-time
    const stateUpdatesObserver = async (cl: number) => {
        console.log('stateUpdatesObserver called with cl:', cl);
        if (isStateUpdateRunning) {
            console.log('Observer already running, exiting');
            return; // Prevent overlapping calls
        }
        setIsStateUpdateRunning(true); // Set isRunning to true
    
        try {
            const res = await APIClips.clipStatesUpdateObserver(cl, wFeedAbortCtrl.current.signal, true);
    
            if (res.rc === 0) {
                
                console.log('State update response received:', res);
                setClipStates((prevClipStates: any) => ({
                    ...prevClipStates,
                    states: prevClipStates.states.map((stateItem: any) => {
                        const updatedState = res.states[Number(stateItem.id)];
                        return updatedState
                            ? { ...stateItem, ...updatedState }
                            : stateItem;
                    })
                }));
            }
            if (
                res.rc === 0 ||
                (Array.isArray(res) && res.length === 0) // api returns empty array when no status changed and timeout reached - also sending a new call in this case
            ) {
                
                if (!isStateUpdateRunning && isEditorial) {
                    console.log('Setting up new observer');
                    setTimeout(() => {
                        stateUpdatesObserver(cl);
                    }, 250);
                }
            } else {
                console.error('State update response error:', res);
                // setTimeout(() => {
                //     stateUpdatesObserver(cl);
                // }, 5000);
                // window.console && console.log(' wFeed HTTP error (' + res.status + ' ' + res.statusText + ')');
				// setTimeout( ()=>{ stateUpdatesObserver(cl); }, 5000 );
            }
        } catch (error) {
            console.error('Error in stateUpdatesObserver, retrying in 30 seconds:', error);
            if (observerRef.current) {
                clearTimeout(observerRef.current);
            }
            observerRef.current = setTimeout(() => {
                isEditorial && stateUpdatesObserver(cl);
            }, 30000);  // Retry after 30 seconds
        } finally {
            setIsStateUpdateRunning(false); // Reset isRunning when the call completes
        }
    };

    // Editorial Workflow State Updater
    const updateClipState = async (notifID: string, cas: number, cl: number, action: string, data?: { [key: string]: string }) => {
        if (isClipStateUpdated) {
            console.log('Update already in progress, skipping this call'); // Debugging log
            return;
        }
        setIsClipStateUpdated(true);
        const res = await APIClips.updateClipState(notifID, cas, cl, action, data, [22029]);


        if (res.rc === 0) {
            if (action !== 'take') {
                setIsClipStateUpdated(false);
                return;
            }

            // change state here immedeiately to make sure it's already changed when mediaEditor is asking for it
            setClipStates((prevClipStates:any) => {
                const clipIndex = clipStates.states.findIndex((clip: any) => clip.id == notifID);
                const updatedClip = {...prevClipStates.states[clipIndex], state:'locked', userid: me.name}
                let updatedStates = [...prevClipStates.states];
                updatedStates[clipIndex] = updatedClip;
                return {
                    ...prevClipStates,
                    states: updatedStates
                }
            })
            console.log('State updated successfully, running observer'); // Debugging log
            // stateUpdatesObserver(cl, true);  // Run state observer after state update
        } else if (res.rc === 22029) {
            setMidAirCollisionPopup(true);
        } else {
            console.error('State update failed'); // Debugging log
        }
        setIsClipStateUpdated(false);
    };
    

    // Ensure that the observer is cleared when the component is unmounted
    useEffect(() => {
        if (isEditorial && filteredDataToDisplay.length > 0 && !isStateUpdateRunning) {
            clipStates.cl && stateUpdatesObserver(clipStates.cl);
        }
        return () => {
            if (observerRef.current) {
                clearTimeout(observerRef.current);
            }
        };
    }, [clipStates.states]);

    const messageHandlerListen = (event: MessageEvent) => { 
        const { type, data } = event;
        const clipDetailData = clipDetail[data.payload.notificationId];

        const clipEditorialState = clipStates?.states?.find(
            (state: { id: string; state: string }) => Number(state.id) === data.payload.notificationId
        ) || { state: '' , id: ''};
        // If the type is set-updates, update the clip state accordingly
        if (type === 'message' && data.type === 'set-updates' && clipDetailData.notifid === data.payload.notificationId && data.payload.action) {
            // update the clip state with data.payload.action
            updateClipState(clipDetailData.notifid, clipEditorialState.cas, clipStates.cl, data.payload.action, data.payload.editorialData);
        }
        // If the type is get-updates, update the clip state accordingly
        else if (type === 'message' && data.type === 'get-updates') {
            console.log('sending set-updates', event, clipEditorialState.state)
            console.log(messageHandler?.getWindow())
            messageHandler?.send('editorial', 'set-updates', {
                notificationId: clipDetailData.notifid,
                clipState: clipEditorialState.state,
                headline: clipEditorialState.headline,
                summary: clipEditorialState.summary,
                userid: clipEditorialState.userid,
                sentiment: clipEditorialState.sentiment
            });
        } else {
            return;
        }
        console.log('processed event', event);
    }

    const addMessageListener = () => {
        // Listen to editorial messages
        messageHandler?.listen('editorial', messageHandlerListen);
    }

    useEffect(() => {
        if(isEditorial){
            messageHandler?.removeListener('editorial', messageHandlerListen);
            addMessageListener();
        }
    },[clipStates, clipDetail])

    useEffect(() => {
        if (isEditorial) {
            return () => {
                messageHandler?.removeListener('editorial', messageHandlerListen);
                wFeedAbortCtrl.current.abort();
            }
        }
    }, []);
    
    const checkChannelGroup = (
        channelNumber: number,
        selectedChannelGroup:
            | {
                  value: number;
                  label: string;
              }
            | undefined
    ): string => {
        let channelGroupTitle = '';
        channelGroups.forEach((channelGroup: ChannelGroup) => {
            if (
                channelGroup.channels.filter((channel) => channel.id === channelNumber)
                    .length > 0 &&
                selectedChannelGroup?.value === channelGroup.id
            ) {
                channelGroupTitle = channelGroup.title;
            }
        });
        return channelGroups && filter.selectedChannelGroup ? channelGroupTitle : '';
    };

    return (
        <div className={styles.clipClusterContainer} style={{}}>
            {/* @ts-ignore */}
            {midAirCollisionPopup && <Popup 
                onOk={() => setMidAirCollisionPopup(false)}
                size='auto'
                blockContent={true}
            >
                <div>
                    <h3>{t('Mid-air collision')}</h3>
                    <p>{t('Update rejected - item was changed in the meantime')}</p>
                </div>
                </Popup>}
            <ClusterHeader
                profileGroupContent={profileGroupContent}
                filteredDataToDisplay={filteredDataToDisplay}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                handleProfileFilter={handleProfileFilter}
                selectedDateRange={selectedDateRange}
                isSearch={isSearch}
                isEditorial={isEditorial}
                isEdited={isEdited}
                tooltipVisible={tooltipVisible}
                setTooltipVisible={setTooltipVisible}
                backFillSelectionVisible={backFillSelectionVisible}
                setBackFillSelectionVisible={setBackFillSelectionVisible}
                selectedChannelGroup={checkChannelGroup(
                    filteredDataToDisplay.length > 0
                        ? filteredDataToDisplay[0].channelNumber
                        : 0,
                    filter.selectedChannelGroup
                )}

            />
            <div className={styles.clusterMiddleContainer}>
                <ChannelsColumn
                    hasTypeInGroup={hasTypeInGroup}
                    hasTypeInProfile={hasTypeInProfile}
                    hasSentiment={hasSentiment}
                    handleTypeFilter={handleTypeFilter}
                    handleSentimentFilter={handleSentimentFilter}
                    handleSearchChannels={handleSearchChannels}
                    handleChannelFilter={handleChannelFilter}
                    profileGroupContent={profileGroupContent}
                    searchChannelsInput={searchChannelsInput}
                    setSearchChannelsInput={setSearchChannelsInput}
                    setFilteredChannelIcons={setFilteredChannelIcons}
                    sortedUniqueChannels={sortedUniqueChannels}
                    setActiveFilter={setActiveFilter}
                    activeFilter={activeFilter}
                    listKey={listKey}
                    isEdited={isEdited}
                    isEditorial={isEditorial}
                    filteredChannelIcons={filteredChannelIcons}
                />
                <ClipColumn
                    listKey={listKey}
                    filteredDataToDisplay={filteredDataToDisplay}
                    currentProfileGroup={currentProfileGroup}
                    isSearch={isSearch}
                    isEditorial={isEditorial}
                    processedIndices={processedIndices}
                    fetchedItems={fetchedItems}
                    setFetchedItems={setFetchedItems}
                    clipDetail={clipDetail}
                    setClipDetail={setClipDetail}
                    isEdited={isEdited}
                    activeFilter={activeFilter}
                    isMentionFindrApp={isMentionFindrApp}
                    backFillSelectionVisible={backFillSelectionVisible}
                    setBackFillSelectionVisible={setBackFillSelectionVisible}
                    clipStates={clipStates}
                    updateClipState={updateClipState}
                    fetchClipStates={fetchClipStates}
                    stateUpdatesObserver={stateUpdatesObserver}
                    wFeedAbortCtrl={wFeedAbortCtrl}
                    messageHandler={messageHandler}
                />
                <CountryColumn
                    handleCountryFilter={handleCountryFilter}
                    handleLanguageFilter={handleLanguageFilter}
                    getLanguagesByCountry={getLanguagesByCountry}
                    sortedUniqueCountries={sortedUniqueCountries}
                    activeFilter={activeFilter}
                    selectedLanguages={selectedLanguages}
                />
                <ClipAnalytics
                    selectedDateRange={selectedDateRange}
                    setTooltipVisible={setTooltipVisible}
                    tooltipVisible={tooltipVisible}
                    profileGroupContent={profileGroupContent}
                    activeFilter={activeFilter}
                    isEdited={isEdited}
                    isSearch={isSearch}
                />
            </div>
        </div>
    );
};

export default ClipCluster;
