import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DragDroppable from './DragDroppable';
import styles from './Sortable.module.scss';
// import PropTypes from 'prop-types';

/* props:
    - isDraggable: defines if draggable html attribute is set to true or false
 */

// example: two boxes with items. items can be moved from one box to another or rearranged inside the same box
// possible future improvements:
// - allow "multiple levels of nesting" ?
// - scrollbar while dragging ?

// known bugs:
// - if child elements are textfields in firefox, clicking textfield to set cursor position will not work (due to use of <Element droppable="true">)
//      see https://bugzilla.mozilla.org/show_bug.cgi?id=739071

class Sortable extends React.Component {
    // static propTypes = {
    //     some: PropTypes.number.isRequired,
    // }

    constructor(props) {
        super(props);
        this.state = {
            dragging: undefined,
            hovered: undefined,
        };
        this.count = 0;

        this.renderItem = this.renderItem.bind(this);
    }

    renderItem(item, itemIndex, listIndex, renderItemFunct, overlayWrapperClass){
        let classes = [];
        let isHovered, isDragged;
        if(this.state.dragging !== undefined){
            if(
                this.state.hovered !== undefined &&
                this.state.hovered.listIndex === listIndex &&
                this.state.hovered.itemIndex === itemIndex &&
                this.state.hovered.itemIndex !== this.state.dragging.itemIndex  // do not show hoveredClassName when hovering over the dragged element
            ){
                isHovered = true;
                classes.push(this.props.itemHoveredClassName, styles.hovered, this.props.theme.borderSecondary);
            }
            else if(
                this.state.dragging.itemIndex !== itemIndex
                || this.state.dragging.listIndex !== listIndex
            ){
                isDragged = true;
                classes.push(this.props.itemDroppableClassName, styles.droppable, this.props.theme.borderPrimary);
            }
        }
        const renderedItem = renderItemFunct ? renderItemFunct() : this.props.renderItem(item);
        const renderedItemCopy = React.cloneElement(renderedItem, {
            ...renderedItem.props,
            className:[
                renderedItem.props.className,
                // ...classes
            ].join(' ')
        });

        return(
            <DragDroppable
                draggable={this.props.isDraggable}
                key={itemIndex}
                // id={`drag-${itemIndex}`}
                showOverlay={isHovered || isDragged}
                overlayStyle={classes.join(' ')}
                overlayWrapperClass={overlayWrapperClass}
                onDragStart={ ()=>{
                    const update = {dragging: {itemIndex, listIndex, item}};
                    this.setState(update);
                    // console.log(update);
                }}
                onDragEnter={ (e)=>{
                    this.count += 1;
                    const update = {hovered: {itemIndex, listIndex, item}};
                    this.setState(update);
                    // console.log(update);
                }}
                onDragLeave={ (e)=>{
                    this.count--;
                    if(this.count === 0){
                        const update = {hovered: undefined};
                        this.setState(update);
                        // console.log(update);
                    }
                    
                }}
                onDragEnd={ ()=>{
                    this.count = 0;
                    const update = {hovered: undefined, dragging: undefined};
                    this.setState(update);
                    // console.log(update);
                }}
                onDrop={ ()=>{
                    this.props.onDrop({
                        drag: this.state.dragging,
                        drop: this.state.hovered,
                    });
                    this.setState({
                        dragging: undefined,
                        hovered: undefined,
                    })
                }}
            >
                {
                    renderedItemCopy
                }
            </DragDroppable>
        )
    }

    render() {
        const lists = [...Array(this.props.lists).keys()]
        return(
            lists.map((list, index)=>{ return this.props.renderList(index, this.renderItem); })
        );
    }
}

const mapStateToProps = state => ({
    theme: state.theme,
});
export default connect(mapStateToProps,{ /* insert redux actions here */ })(withTranslation()(Sortable));