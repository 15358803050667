import GenericCalls from './genericCalls';

class APIOptions {
    static async getTimezones() {        
        return await GenericCalls.get('/api/options/timezones');
    }

    static async getEmailLangs() {
        // possible languages to be set for (welcome) email to users
        const res = await GenericCalls.get('/api/options/email-langs');
        return {data: res?.emailLanguages};
    }
}

export default APIOptions;