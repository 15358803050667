//@ts-ignore
import AutoSizer from 'react-virtualized-auto-sizer';
//@ts-ignore
import {VariableSizeList, areEqual} from 'react-window'
import MentionCard from '../MentionCard/MentionCard';
import { AutoSizerProps, ReactWindowProps } from '../MentionResults.types';
import { MentionCardListProps } from './MentionCardList.types';
import { FC, memo, useState } from 'react';
import { NotificationRecord } from 'src/@types/shared.types';
import APIMentions from 'src/API/APIMentions';

const processedIndices = new Set();

const MentionCardList: FC<MentionCardListProps> = ({
    dataCondition,
    isMobileScreen,
    isTopicMentionDetails,
    getItemSize,
    descriptionRef,
    listRef,
    profileIds
}) => {

    const [notificationData, setNotificationData] = useState<NotificationRecord>({});
    const [fetchedItems, setFetchedItems] = useState<Set<number>>(new Set());

    const fetchNotificationDetails = async (index: number, notifID: number | undefined) => {
        let notifIDToFetch = notifID;
        if(profileIds.includes('t')) {
            notifIDToFetch = notifID + 't' as unknown as number;
        }
        if (!notificationData[index] && !fetchedItems.has(index)) {
          const res = await APIMentions.getNotificationResults(notifIDToFetch);
          setNotificationData((prevData: NotificationRecord) => ({
            ...prevData,
            [index]: res.data
          }));
          setFetchedItems((prevItems) => {
            const newItems = new Set(prevItems);
            newItems.add(index);
            return newItems;
          });
        }else{
            return
        }
      };


    //!React-window Row element creation with equal value comparison
    const Row = memo(({ index, style }: ReactWindowProps) => {
        const result = dataCondition[index];
        
        return (
            <div
                style={
                    isMobileScreen
                        ? {
                              ...style,
                              display: 'flex',
                              justifyContent: 'center'
                          }
                        : style
                }
            >
                <MentionCard
                    isTopicMentionDetails={isTopicMentionDetails}
                    key={index}
                    cardData={result}
                    descriptionRef={descriptionRef}
                    index={index}
                    notificationData={notificationData}
                />
            </div>
        );
    }, areEqual);

    
    return (
        <AutoSizer>
            {({ height, width }: AutoSizerProps) => (
                <VariableSizeList
                    height={height}
                    width={width}
                    itemCount={dataCondition.length}
                    itemSize={(index: number) => getItemSize(index)}
                    itemData={dataCondition}
                    ref={listRef}
                    onItemsRendered={({ visibleStartIndex, visibleStopIndex }: any) => {
                        for (let index = visibleStartIndex; index <= visibleStopIndex; index++) {
                            if (!fetchedItems.has(index) && !processedIndices.has(index)) {
                                processedIndices.add(index);
                                setTimeout(() => {
                                    fetchNotificationDetails(index, dataCondition[index]?.notifID);
                                }, 400)
                            }
                        }
                    }}
                >
                    {Row}
                </VariableSizeList>
            )}
        </AutoSizer>
    );
};

export default MentionCardList
