import GenericCalls from "./genericCalls";
import _ from 'lodash';

class APIQuiz{
    static async getQuiz(url) {
        let quiz = await GenericCalls.get(url);
        if (quiz?.questions) {
            quiz.questions = _.sortBy(quiz.questions, 'id');
        }
        return quiz;
    }
}

export default APIQuiz;