import { SET, CLEAR } from '../types/actionTypes.signup';

export const setParams = data =>{
    return ({
        type: SET
   , data: data
  })};

export const clearParams = () =>
  ({ type: CLEAR
  });