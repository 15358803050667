import {
    POST_QUIZ,
    PUT_QUIZ,
    RECEIVE_QUIZZES,
} from '../types/actionTypes.quiz';
import _ from 'lodash';
import APISettings from 'src/API/APISettings';

const SETTINGS_KEY = 'quiz';

export const getQuizzes = () => {
    return async(dispatch) => {
            const quiz = await APISettings.getItems(SETTINGS_KEY);
            let data = quiz?.data ? [...quiz.data] : [];
    
            dispatch({
                type: RECEIVE_QUIZZES,
                data: data,
            });
    };
};

export const deleteQuiz = (id) => {
    return async(dispatch) => {
        let resp = await APISettings.deleteItem(SETTINGS_KEY, id);
            dispatch({
                type: POST_QUIZ,
                id,
            });
        return id;
    };
};

export const postQuiz = (quiz) => {
    return async(dispatch) => {
        let resp = await APISettings.postItem(SETTINGS_KEY, quiz);
        const data = { ...quiz, id: resp.data.id }
            dispatch({
                type: POST_QUIZ,
                data
            });
        return data;
    };
};

export const putQuiz = (data) => {
    return async(dispatch) => {
        await APISettings.putItem(SETTINGS_KEY, data.id, data);

        dispatch({
            type: PUT_QUIZ,
            id: data.id,
            data: data,
        });
    };
};