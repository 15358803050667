import Joyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride';
import styles from './Tour.module.scss';
import Button from "src/Widgets/common/basicElements/Button/Button";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setIsEditable as boardSetIsEditable, setShowUserMenu, setShowWidgetSelection, setShowHelpMenu, setIsEditable } from "src/redux/actions/actions.board";
import { setStep, showTour } from '../redux/actions/actions.tour';
import JoyrideTooltip from './JoyrideTooltip';
import _ from 'lodash'
import { sleep } from 'src/Widgets/common/helpers';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { updateAccountSettings } from 'src/redux/actions/actions.theme';
import { dynamicConfig } from 'src/config/dynamicConfig/dynamicConfig';

// (potential) TODOs/improvements:
// - warn user before start of tour, that unsafed data would be lost?
// - keep workspaces in redux to avoid network requests or at least add loading indicator at begin/end of tour?

// styling issues:
// - check why search settings section suddenly gets more width about 1 sec after render. reason: history items loaded? fix it !

function JoyrideTour(){
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const firstUpdate = useRef(true);
    const tour = useSelector((state) => state.tour);
    const theme = useSelector((state) => state.theme);
    const loadingGlobal = useSelector((state) => state.board?.loadingGlobal);
    const accountSettings = useSelector((state) => state.accountSettings);
    const supportEmail = useSelector((state) => state.me.support);
    const history = useHistory();
    useEffect(() => {
        if (accountSettings?.data?.hadTour === false && !loadingGlobal) {
            dispatch(showTour(true))
        }
    },[accountSettings?.data?.hadTour, dispatch, loadingGlobal])

    useEffect(() => {
        if (!firstUpdate.current) {   // do not run on initial mount
            // undo non-tour specific things that could have been changed at some steps in tour (should be defined in step instead?)
            dispatch(setIsEditable(false));
            dispatch(setShowHelpMenu(false));
            dispatch(setShowUserMenu(false));
            history.replace('/workspaces/1');
        }
        firstUpdate.current = false;
    },[tour.show]);

    const runAction = ({ nextStepIndex, fromPrev }) => {
        if (steps[nextStepIndex]?.cAction) {
            steps[nextStepIndex]?.cAction({fromPrev});
        }
    }
    const renderOrangeBox = (children) => (
        <div
            className={styles.box}
            style={{ backgroundColor: theme.secondary, color: 'white' }}
        >
            {children}
        </div>
    )

    // const runAction = async(index, type) => {
    //     console.log(index, type, EVENTS.STEP_BEFORE, _.cloneDeep(steps))
    //     console.log(type === EVENTS.STEP_BEFORE, steps[index]?.actionB)
    //     if (type === EVENTS.STEP_BEFORE && steps[index].actionB) {
    //         // console.log('actionB',index, type)
    //         await steps[index].actionB();
    //     } else if (type === EVENTS.STEP_AFTER && steps[index].actionA) {
    //         // console.log('actionA',index, type)
    //         await steps[index].actionA();
    //     } else {
    //         // console.log('no-action',index, type)
    //     }
    // }

    const renderNavButtons = (steps, step = tour.step) => {
        const isFirst = step - 1 < 0;
        const isLast = step + 1 > steps.length - 1;
        return (
            <div className = { styles.btns } >
            <Button
                disabled={isFirst}
                type="primary"
                onClick={() => onNavClick(step,-1)}
                style={{ marginBottom: 0}}
            >
                {t('Back')}
                </Button>
                {isLast
                    ? <Button
                        onClick={() => {
                            onEnd();
                        }}
                        type="secondary"
                        style={{ marginBottom: 0}}
                    >
                        {t('Done')}
                    </Button>

                    : <Button
                        onClick={() => onNavClick(step,+1)}
                        type="secondary"
                        style={{ marginBottom: 0}}
                    >
                        {t('Next')}
                    </Button>
                }
        </div>
        )
    }

    const onNavClick = async (currStepIndex, jumpPlusSteps) => {
        // after current step
        
        const nextStepIndex = currStepIndex + jumpPlusSteps;
        if (!steps[nextStepIndex]) {
            onEnd();
            return;
        }
        runAction({ nextStepIndex, fromPrev: jumpPlusSteps > 0 ? true : false});

        while (!document.querySelector(steps[nextStepIndex].target)) {
            await sleep(30);
        }
        await sleep(150);
        dispatch(setStep(nextStepIndex));
    }

    const onEnd = () => {
        // undo tour specific things
        dispatch(showTour(false));
        dispatch(setStep(0));
        dispatch(updateAccountSettings(
            {
            ...accountSettings.data,
            hadTour: true,
            },
            true
        ));
    }

    const renderTourBox = (content) => {
        return (
            <div className={styles.box}>
                <div
                    title={t('Close')}
                    className={styles.close}
                    onClick={onEnd}
                >
                    <FontAwesomeIcon icon={faTimes}/>
                    
                </div>
                {content}
                {renderNavButtons(steps)}
            </div>
        )
    }

    const cbHandler = ({
        type,
        action,
        index,
        status
    }) => {
        // end tour
        if (
            ([ACTIONS.CLOSE].includes(action) && [EVENTS.STEP_AFTER].includes(type))
            || [EVENTS.TOUR_END].includes(type)
        ) {
            onEnd();
            return;
        }

        if(!tour.show) return;

        // start tour
        if (
            [EVENTS.TOUR_START, EVENTS.TOUR_STATUS].includes(type)
            && action === ACTIONS.START
        ) {
            // TODO: move to onNavClick() ? - moved everything here to ensure to preven "Target not mounted" warnings
            // but this warning is actually not happening here
            runAction({nextStepIndex: index});
        }

        // before next step
        // if ([EVENTS.STEP_BEFORE].includes(type)) {
        //     console.log('before', index)
        //     if(index === 0){
        //         runAction(index,
        //             // EVENTS.STEP_BEFORE
        //         );
        //     }
        // }

        // after current step
        // if ([EVENTS.STEP_AFTER].includes(type)) {
        // }
    }

    const steps = dynamicConfig.getTourSteps({ dispatch, t, renderTourBox, renderOrangeBox, history, supportEmail });

    return (
    <Joyride
        steps={steps}
        callback={cbHandler}
        run={tour.show}
        stepIndex={tour.step}
        continuous={true}
        autoStart
        tooltipComponent={JoyrideTooltip}
        showProgress={true}
        styles={{
            spotlight: {
                borderRadius: 0,
                // border: '3px solid orange'
            }
        }}
        spotlightPadding={0}
        spotlightClicks={false}
    />
    )
}
export default JoyrideTour;