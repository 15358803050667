import { FC, SyntheticEvent } from 'react';
import styles from './RecordingCard.module.scss';
//@ts-ignore
import Highlighter from 'react-highlight-words';
//@ts-ignore
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './RecordingCard.scss';
import defaultThumbnail from '../../../../assets/defaultThumbnail.png';
import defaultChannelIcon from '../../../../assets/defaultChannelIcon.png';
import { useAppSelector } from 'src/redux/hooks';
import { ContentTexts, RecordingCardProps } from './RecordingCard.types';

/**
 * props:
 *  - content: single object in form of API response (/api/monitor/id)
 *  - htmlText: boolean, if set to true: renders html within provided text. if not true: use <Highlighter> to highlight words based on provided highlight words
 */

/* interface DateOptions {
    year?: 'numeric' | '2-digit';
    month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
    day?: 'numeric' | '2-digit';
    hour?: 'numeric' | '2-digit';
    minute?: 'numeric' | '2-digit';
    second?: 'numeric' | '2-digit';
    timeZoneName?: 'long' | 'short';
    hourCycle?: 'h11' | 'h12' | 'h23' | 'h24';
} */

/*     const timestampToDateTime = (timestamp: number) => {

        const options: DateOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hourCycle: 'h24',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short'
        };

        const dateTime = new Date(0);
        dateTime.setUTCSeconds(timestamp);

        return dateTime.toLocaleString('default', options);
    }  */

const RecordingCard: FC<RecordingCardProps> = ({
    active,
    content,
    cardRef,
    changeIFrameUrl,
    htmlText,
    testIds
}) => {
    const { t } = useTranslation();

    const theme = useAppSelector((state) => state.theme);

    const addDefaultThumbnail = (ev: SyntheticEvent<HTMLImageElement, Event>) => {
        const imageElement = ev.target as HTMLImageElement;
        imageElement.src = defaultThumbnail;
    };

    const addDefaultChannelIcon = (ev: SyntheticEvent<HTMLImageElement, Event>) => {
        const iconElement = ev.target as HTMLImageElement;
        iconElement.src = defaultChannelIcon;
    };

    if (!content || _.isEmpty(content)) return null;

    return (
        <article
            translate="yes"
            ref={cardRef}
            className={[styles.card, active ? styles.active : null].join(' ')}
        >
            <div className={styles.thumbnailWrapper}>
                <img
                    data-testid={testIds?.thumbnail}
                    className={styles.thumbnail}
                    src={content.thumbnail}
                    alt="thumbnail"
                    onClick={() => changeIFrameUrl(content.texts[0].ref)}
                    onError={addDefaultThumbnail}
                />
            </div>

            <section className={styles.content}>
                <span
                    className={[
                        styles.title,
                        active ? theme.backgroundPrimary : null
                    ].join(' ')}
                >
                    <span
                        className={styles.titleText}
                        onClick={() => changeIFrameUrl(content.texts[0].ref)}
                    >
                        <img
                            src={
                                content.channelIcon
                                    ? content.channelIcon
                                    : defaultChannelIcon
                            }
                            alt="icon"
                            onError={addDefaultChannelIcon}
                        />

                        {content.channel}
                        {content.channel !== undefined && content.title !== undefined
                            ? ': '
                            : null}
                        {content.title}
                    </span>
                    {/*<span className={styles.timestamp}>{dateTime}</span>     Duplicate Date and Time*/}
                </span>

                {_.map(content.texts, (text: ContentTexts, idx: number) => (
                    <section key={idx} className={styles.highlightedText}>
                        {text.seconds > 0 && (
                            <span
                                className={styles.seenLater}
                                onClick={() => changeIFrameUrl(text.ref)}
                            >
                                [{text.seconds}
                                {t('x seconds later')}]{' '}
                            </span>
                        )}
                        {htmlText ? (
                            <span data-testid={testIds?.text} dangerouslySetInnerHTML={{ __html: text.text }}></span>
                        ) : (
                            <Highlighter
                                searchWords={_.map(content.highlights, (h: any) =>
                                    h.replace(/\W/g, '')
                                )}
                                textToHighlight={text.text}
                            />
                        )}
                    </section>
                ))}

                {content.otherHits && (
                    <section className={styles.otherHits}>
                        <span className={styles.alsoSeenAt}>
                            {t('Also seen at')}
                            {': '}
                        </span>
                        {_.map(content.otherHits, (o: any, idx: any) => (
                            <span
                                className={classNames(
                                    styles.otherHit,
                                    styles.selected,
                                    theme.borderSecondary
                                )}
                                key={idx}
                                onClick={() => changeIFrameUrl(o.ref)}
                            >
                                {o.text}
                            </span>
                        ))}
                    </section>
                )}
            </section>
        </article>
    );
};

export default RecordingCard;
