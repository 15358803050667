import React, { Component } from 'react';
import styles from './Graphs.module.scss';
// import '../../../../node_modules/react-vis/dist/style.css';
import _ from 'lodash';
import { dateToString, isDateEqual } from '../../common/helpers';
import { withTranslation } from 'react-i18next';
import BarChart from 'src/Widgets/common/Charts/BarChart';
import LineChart from 'src/Widgets/common/Charts/LineChart';
import AreaChart from 'src/Widgets/common/Charts/AreaChart';
import ReChart from 'src/Widgets/common/Charts/ReChart';

class HitsPerDayPlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crosshairValues: []
        };
    }

    prepareBar(dates, data) {
        return _.map(dates, (d, datesIndex) => {
            let dateVals = {
                name: dateToString(d), data: {},
                legend: {}
            };
            let obj = {};
            _.map(data, (c,index) => {
                obj[c.id || c.title] = c.data[datesIndex];
                dateVals.legend[c.id || c.title] = c.title;
            });
            dateVals.data = obj;
            return dateVals;
        });
    }

    prepareLine(dates, data) {
        let rv = _.map(data, (d) => {
            let serie = { name: d.title, data: [] };
            serie.data = _.map(dates, (date, dateIndex) => ({ category: dateToString(date), value: d.data[dateIndex] }))
            return serie;
        });
        return rv;
    }

    renderChart(series, dates, data) {
        let rv;
        let xLabel = this.props.t('Date');
        let yLabel = this.props.t('Mentions');

        switch (series) {
            case 'bars':
                data = this.prepareBar(dates, data);
                rv = <ReChart><BarChart stacked={this.props.stacked} data={data} xLabel={xLabel} yLabel={yLabel} /></ReChart>;
                break;
            case 'lines':
                data = this.prepareLine(dates, data);
                rv = <LineChart data={data} xLabel={xLabel} yLabel={yLabel} />;
                break;
            case 'area':
                    data = this.prepareBar(dates, data);
                    rv = <AreaChart stacked={this.props.stacked} data={data} xLabel={xLabel} yLabel={yLabel} />;
                    break;
            default: break;
        }
        return rv;
    }

    render() {
        const { startDate, endDate, data, series, t } = this.props;

        if (!data || data.length === 0)
            return <section className={styles.wrapper}>{t('No profile selected')}</section>;

        let dates = [];
        let date = new Date(startDate);
        for (;;) {
            dates.push(new Date(date));
            
            if (isDateEqual(date, endDate)) break;

            date.setDate(date.getDate() + 1);
        }

        return (
            <section className={styles.wrapper}>
                {this.renderChart(series, dates, data)}
            </section>
        );
    }
}

export default withTranslation()(HitsPerDayPlot);
