import GenericCalls from './genericCalls';
import _ from 'lodash';
import { dateToTimestamp, timestampToDate } from '../Widgets/common/helpers';

function fromDb(obj) {
    let rv = _.cloneDeep(obj);
    rv.data.contractEndDate = timestampToDate(rv.data.contractEndDate);
    rv.data.paymentOverdueDate = timestampToDate(rv.data.paymentOverdueDate);
    // console.log(rv);    
    return rv;
}

function toDb(obj) {
    let rv = _.cloneDeep(obj);
    delete rv.users;
    
    if (rv.contractEndDate !== undefined && rv.contractEndDate !== null) {
        rv.contractEndDate = dateToTimestamp(rv.contractEndDate);
    }
    
    if (rv.paymentOverdueDate !== undefined && rv.paymentOverdueDate !== null) {
        rv.paymentOverdueDate = dateToTimestamp(rv.paymentOverdueDate);
    }
    
    return rv;
}

class APICompanies {
    static async get(id) {
        if (id) {
            let c = await GenericCalls.get('/api/companies/' + id);
        
            // mock:
            // c.contractEndDate = new Date();
            // c.paymentOverdueDate = new Date();
        
            return fromDb(c);
        }
        
        // else return await GenericCalls.get('/api/companies');
    }

    static async search(searchTerm) {
        return await GenericCalls.get('/api/companies/search/' + '*' + searchTerm + '*');
    }
    
    static async post(data) {        
        return await GenericCalls.post('/api/companies/', toDb(data));
    }
    
    static async put(id, data) {
        let d = _.cloneDeep(data);
        return await GenericCalls.put('/api/companies/' + id, toDb(d));
    }
    
    static async delete(id) {
        return await GenericCalls.delete('/api/companies/' + id);
    }

    static async getShareGroups(id) {
        return await GenericCalls.get(`/api/companies/${id}/share-groups`);
    }
}

export default APICompanies;
