import { useTranslation } from "react-i18next";
import Button from "src/Widgets/common/basicElements/Button/Button";
import loginStyles from 'src/Login/Login.module.scss';
import TextInput from "src/Widgets/common/basicElements/TextInput/TextInput";
import { useEffect, useState } from "react";
import BackButton from "src/Widgets/common/basicElements/BackButton/BackButton";
import { useForgotPassword } from "./useForgotPassword";

interface ForgotPasswordProps{
    initUsername: string,
    onGoBack: ()=>void,
    onUsernameChanged?: Function,
    text: JSX.Element
    history: any
}

function ForgotPassword({
    initUsername,
    onGoBack,
    onUsernameChanged,
    text,
    history
}:Readonly<ForgotPasswordProps>) {

    const { t } = useTranslation();
    const [sentPwLink, setSentPwLink] = useState(false);
    const [sendPwLinkError, setSendPwLinkError] = useState(undefined);
    const [username, setUsername] = useState('');
    const {
        sendResetPasswordLink,
        handleUsernameChange,
        renderText,
    } = useForgotPassword({ initUsername, setSentPwLink, setSendPwLinkError, text, onUsernameChanged, username, setUsername, history })

    
    return (
        <div>
            <div>
                {
                    sendPwLinkError
                        ? <span data-testid="sendpw-failed-txt" className={`${loginStyles.loginFailed} animated bounceIn`}>{sendPwLinkError}</span>
                        : sentPwLink
                            ?
                                <div data-testid="sendpw-success-txt" style={{ flexDirection: 'column' }}>
                                    <h2 style={{ marginBottom: '0.4rem' }}>{t('forgot_pw_send_email_done_text1')} {username}</h2>
                                    <span>{t('forgot_pw_send_email_done_text2')}.</span>
                                </div>
                            : renderText()
                }
            </div>

            {
                sentPwLink ?
                    <BackButton onClick={onGoBack} />
                : <>
                        <TextInput
                            inputType="text"
                            value={username}
                            onChange={handleUsernameChange}
                            placeholder={t("Username")}
                            className={[loginStyles.textInputWrapper,].join(' ')}
                            style={{marginBlockStart:0}}
                            onKeyPress={e => e.key === 'Enter' && sendResetPasswordLink()}
                            // ref={(r) => { setRefUser(r); }}
                        />
                        <div style={{display:'flex', justifyContent: 'space-between'}}>
                            <BackButton onClick={onGoBack} />
                            <Button
                                disabled={sendPwLinkError || !username}
                                type='secondary'
                                onClick={e => sendResetPasswordLink()}
                                // @ts-ignore
                                style={{ width: '9.5rem' }}
                            >
                                {t('Send e-mail')}
                            </Button>
                        </div>
                    </>
            }
        </div>
    )
}

export default ForgotPassword;