import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from '../ClipCard/ClipCard.module.scss';

const useSentimentIcon = (sentiment: string, isArabic?: boolean) => {
    const sentimentIconSwitch = (sentiment: string) => {
        switch (sentiment) {
            case 'pos':
                return (
                    <FontAwesomeIcon
                        title={sentiment}
                        className={isArabic ? styles.sentimentRTL : styles.sentiment}
                        style={{ color: 'green' }}
                        size="2x"
                        icon={faThumbsUp}
                        data-testid="sentiment-icon"
                    />
                );
            case 'neu':
                return (
                    <FontAwesomeIcon
                        title={sentiment}
                        className={isArabic ? styles.sentimentRTL : styles.sentiment}
                        style={{ rotate: '-90deg', color: 'gray' }}
                        size="2x"
                        icon={faThumbsUp}
                        data-testid="sentiment-icon"
                    />
                );
            case 'neg':
                return (
                    <FontAwesomeIcon
                        title={sentiment}
                        className={isArabic ? styles.sentimentRTL : styles.sentiment}
                        style={{ color: 'red' }}
                        size="2x"
                        icon={faThumbsDown}
                        data-testid="sentiment-icon"
                    />
                );
            default:
        }
    };
    const sentimentIcon = sentimentIconSwitch(sentiment);
    return sentimentIcon;
    
}

export default useSentimentIcon;