import React, { useMemo, useState } from 'react';
import Popup from 'src/Widgets/common/basicElements/Popup/Popup';
//@ts-ignore
import ReactSelect from 'react-select';
import TextInput from 'src/Widgets/common/basicElements/TextInput/TextInput';
import { useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import styles from '../components/ChannelsTableContainer/ChannelsTableContainer.module.scss'
import useManageChannelGroup from './useManageChannelGroup';
import { ChannelGroup, ChannelType, SelectBoxGroupOption } from '../types/types';
import { processChannels } from '../utils/utils';

export const usePopup = (selectedChannels?: ChannelType[], selectedGroup?: ChannelGroup) => {

    const [selectedGroupOptions, setSelectedGroupOptions] = useState<SelectBoxGroupOption[]>([]);
    const [newGroupName, setNewGroupName] = useState<string>('');
    const [popupMessage, setPopupMessage] = useState('');

    const channelGroups = useAppSelector((state) => state.channelGroupReducers);

    const {
        addChannelsHandler,
        deleteChannelsHandler,
        clearPopupLogic,
        isOpen,
        popupType,
        setIsOpen,
        setPopupType
    } = useManageChannelGroup(
        {selectedChannels,
        setSelectedGroupOptions,
        selectedGroupOptions,
        channelGroups,
        newGroupName,
        selectedGroup,
        setPopupMessage
    }
    );

    const { t } = useTranslation();


    const { channelGroupTitle, channelsWillBeRemovedInGroup } = processChannels(channelGroups, selectedGroup!, selectedChannels!);

    const addChannelPopup = (
        //@ts-ignore
        <Popup
            size="auto"
            blockContent={true}
            onCancel={clearPopupLogic}
            okDisabled={true}
            addDisabled={(selectedGroupOptions === null && !newGroupName.length) || !newGroupName && selectedGroupOptions === null}
            onAdd={addChannelsHandler}
            wrapperStyle={{
                position: 'fixed',
                insetBlockStart: 'unset',
                insetBlockEnd: 0,
                height: 'calc(100% - 2.2rem)'
            }}
        >
            <div className={styles.addRemovePopupContainer}>
                {/* <h3>{t("Add Selected Channels")}</h3> */}
                <p>
                    {t('You have selected') + ' '}
                    {selectedChannels?.length} {t('Channels count')}.
                </p>
                <h4>{t('Add your selection')}</h4>
                <p>{t('Selected channel group')}</p>
                {/* <p>
                        {t("Dropdown menu contains")}
                    </p> */}

                <ReactSelect
                    isMulti
                    onChange={(...props: any[]) => setSelectedGroupOptions(props[0])}
                    className={styles.selectBox}
                    options={channelGroupTitle}
                    closeMenuOnSelect={
                        channelGroupTitle?.length - 1 === selectedGroupOptions?.length
                    }
                    backspaceRemovesValue
                />
                <p>{t('Enter a name for your')}</p>
                <TextInput
                    placeholder={t('Enter a name')}
                    onChange={(e: any) => setNewGroupName(e.target.value)}
                    value={newGroupName}
                />
            </div>
        </Popup>
    );

    const deleteChannelPopup = (
        //@ts-ignore
        <Popup
            size="auto"
            blockContent={true}
            onCancel={clearPopupLogic}
            okDisabled={true}
            onRemove={deleteChannelsHandler}
            wrapperStyle={{
                position: 'fixed',
                insetBlockStart: 'unset',
                insetBlockEnd: 0,
                height: 'calc(100% - 2.2rem)'
            }}
        >
            <div className={styles.addRemovePopupContainer}>
                <h3>{t('Delete Channels')}</h3>
                <p>
                    {t('You have selected') + ' '}
                    {channelsWillBeRemovedInGroup?.length || 0} {t('Channels in group')} "
                    {selectedGroup?.title || ''}".
                </p>
                <p>{t('Really delete channels')}</p>
            </div>
        </Popup>
    );

    const messagePopupGenerator = (message: string) => {
            return (
                //@ts-ignore
                <Popup
                    wrapperStyle={{
                        position: 'fixed',
                        insetBlockStart: 'unset',
                        insetBlockEnd: 0,
                        height: 'calc(100% - 2.2rem)'
                    }}
                    size="auto"
                    blockContent={true}
                    onOk={clearPopupLogic}
                >
                    <div>
                        <p style={{ overflow: 'hidden' }}>
                            {t(message)
                                ?.split('\n')
                                .map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                        </p>
                    </div>
                </Popup>
            );
    };

    const switchPopup = useMemo(() => {
        let selectedPopup = null;
        switch (popupType) {
            case 'add':
                //@ts-ignore
                selectedPopup = addChannelPopup;
                break;
            case 'delete':
                selectedPopup = deleteChannelPopup;
                break;
            case 'error':
                selectedPopup = messagePopupGenerator(popupMessage ?? '');
                break;   
            default:
                break;
        }
        return selectedPopup;
    }, [popupType, newGroupName, selectedGroupOptions]);

    return {
        setIsOpen,
        setPopupType,
        popupComponent: switchPopup,
        isOpen
    };
}