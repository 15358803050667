import { INIT } from '../types/actionTypes.matomo';

const matomoReducers = (state = null, action) => {
    switch (action.type) {
        case INIT:
            return action.data;
        default:
            return state;
    }
};

export default matomoReducers;