import React, { Component } from 'react';
import styles from './StatsHeader.module.scss';
import Select from 'react-select';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import TabBar from 'src/Widgets/common/basicElements/TabBar/TabBar';
import DateRangePicker from 'src/Widgets/common/basicElements/DateRangePicker/DateRangePicker';
import { withTranslation } from 'react-i18next';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { calcDaysDifference, dateIsToday, isDateEqual } from 'src/Widgets/common/helpers';
import formStyles from 'src/Widgets/common/basicStyles/forms.module.scss';
import SelectAsync from 'src/Widgets/common/basicElements/SelectAsync/SelectAsync';
import { connect } from 'react-redux';




// fontawesome icons
library.add(faArrowLeft, faArrowRight);

class StatsHeader extends Component {
    constructor(props) {
        super(props);

        this.selectAll = this.selectAll.bind(this);
    }   
    t = this.props.t || (k => k);
    initialDateDiff = calcDaysDifference(this.props.dateRange[0], this.props.dateRange[1])
    options = [{ value: 'lastXDays', label: this.t("Last") + " " + this.initialDateDiff + " " + this.t("days") },
        { value: 'lastWeek', label: this.t("Last Week") },
        { value: 'last2Weeks', label: this.t("Last 2 Weeks") },
        { value: 'lastMonth', label: this.t("Last Month") },
        { value: 'last2Months', label: this.t("Last 2 Months") }]
    state = {
        showMenu: false,
        dateDiff: this.initialDateDiff,
        dateRangeDropDownSelected: null
      };


    selectAll() {
        const { onSelectionChange, selectables } = this.props;

        // de-group
        let allOptions = _.reduce(
            selectables,
            (all, selectable) => _.concat(all, selectable.options),
            []
        );

        onSelectionChange(allOptions);
    }

    updateDateDiff(newDateDiff) {
        if(this.state.dateDiff !== newDateDiff) {
            let list = this.options.slice(0)
            list[0].label = this.t("Last") + " " + newDateDiff + " " + this.t("days")
            this.options = list
            this.setState({dateDiff: newDateDiff})
        }
    }

    getDateRangeToday(dateDiff) {
        let start = new Date();
        start.setDate(start.getDate() - dateDiff + 1);
        const currentDateRange = [start, new Date()]
        return currentDateRange
    }

    changeTimeframe(option,cb) {
        this.setState({dateRangeDropDownSelected: option});

        switch(option.value) {
            case 'lastXDays':
                this.props.onDateRangeChange(this.getDateRangeToday(this.state.dateDiff), cb);
                break;
            case 'lastWeek':
                this.props.onDateRangeChange(this.getDateRangeToday(7), cb);
                break;
            case 'last2Weeks':
                this.props.onDateRangeChange(this.getDateRangeToday(14), cb);
                break;
            case 'lastMonth':
                this.props.onDateRangeChange(this.getDateRangeToday(30), cb);
                break;
            case 'last2Months':
                this.props.onDateRangeChange(this.getDateRangeToday(60), cb);
                break;
            default: break;
        }
    }

    render() {
        // translation method with fallback
        const t = this.props.t || (k => k);
        const { multiSelect, closeMenuOnSelect, dateRange } = this.props;
        const dir = this.props.i18n.dir();

        return (
            <section className={styles.wrapper}>
                <div className={styles.filter}>
                        <SelectAsync
                            className={styles.profileSelect}
                            isMulti={multiSelect !== undefined ? multiSelect : true}
                            selectAll={multiSelect !== undefined ? multiSelect : true}
                            options={this.props.selectables}
                            value={this.props.selectedItems}
                            onChange={this.props.onSelectionChange}
                            placeholder={this.props.selectPlaceholder || t("Select") + " ..."}
                            isClearable
                            clearValue={()=>undefined}
                            menuList={{
                                width: "max-content",
                                minWidth: "100%",
                                maxHeight: "180px",
                            }}
                            closeMenuOnSelect={closeMenuOnSelect}
                        />
                        { this.props.filterSelectablesOptions &&
                            <SelectAsync
                                className={styles.filterSelect}
                                options={this.props.filterSelectablesOptions}
                                placeholder={this.props.filterSelectPlaceholder}
                                onChange={this.props.onFilterSelectChange}
                                isClearable
                                clearValue={()=>undefined}
                                menuList={{
                                    width: "max-content",
                                    minWidth: "100%",
                                    maxHeight: "150px",
                                }}
                            />
                        }

                    <DateRangePicker
                        minDate={this.props.minDate}
                        maxDate={new Date()}
                        value={this.props.dateRange}
                        onChange={(dateRange)=>{
                            this.updateDateDiff(calcDaysDifference(dateRange[0], dateRange[1]))
                            this.setState({dateRangeDropDownSelected: null});
                            this.props.onDateRangeChange(dateRange);
                        }}
                        className={styles.datepicker}
                        clearIcon={null}
                    />

                    <div className={styles.switchPageWrapper}>
                        <Button
                            disabled={this.props.minDate && isDateEqual(this.props.minDate, dateRange[0])}
                            type='primary'
                            btnClass={styles.switchPage}
                            onClick={() => {this.props.matomo.push(['trackEvent', 'coverage', 'selectEarlierDate']); this.props.moveDate(false, this.state.dateDiff)}}
                            title={t('earlier dates')}
                        >
                            <FontAwesomeIcon icon={dir === 'rtl' ? "arrow-right" : "arrow-left"} color="white" /> 
                        </Button>
                        <Button
                            disabled={dateIsToday(dateRange[1])}
                            type='primary'
                            btnClass={styles.switchPage}
                            onClick={() => {this.props.matomo.push(['trackEvent', 'coverage', 'selectLaterDate']); this.props.moveDate(true, this.state.dateDiff)}}
                            title={t('later dates')}
                            >
                            <FontAwesomeIcon icon={dir === 'rtl' ? "arrow-left" : "arrow-right"} color="white" />
                        </Button>
                    </div>
                    <div className={styles.dateRangeWrapper}>
                        <Select
                            options={this.options}
                            value={this.state.dateRangeDropDownSelected}
                            onChange={option => {
                                this.changeTimeframe(
                                    option,
                                    (dateRange)=>{this.updateDateDiff(calcDaysDifference(dateRange[0], dateRange[1]))}
                                );
                            }}
                            className={formStyles.select}
                            placeholder={t('Select date range') + ' ...'}
                        />
                    </div>
                </div>

                <TabBar tabs={this.props.tabs} active={this.props.activeTab} onClick={this.props.onTabClick}/>
            </section>
        );
    }
}
const mapStateToProps = state => ({ 
    matomo: state.matomo
});

export default connect(mapStateToProps)(withTranslation()(StatsHeader));
