import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';

const BackToTop = ({ listRef, scrollPosition }: any) => {
    const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <FontAwesomeIcon
        icon={faArrowCircleUp}
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      />
    </div>
  );
};

export default BackToTop;

