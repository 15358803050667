import styles from './Pricing.module.scss';
import { PlanTypes } from "./plans.types";
// @ts-ignore
import { useHistory } from "react-router-dom";
import { ROUTES } from "src/consts";
import APIStripe from "src/API/APIStripe";
import { useDispatch, useSelector } from "react-redux";
import Link from "src/Widgets/common/basicElements/Link/Link";
import { useEffect, useState } from "react";
import PricingCards from './PricingCards/PricingCards';
import CountryPackageSelection from '../CountryPackageSelection/CountryPackageSelection';
import { getSubscriptions } from 'src/redux/actions/actions.stripe';
import plans from './plans';
import { PricingTypes } from './Pricing.types';

function Pricing({auth=true}:PricingTypes.Props) {
    const history = useHistory();
    const dispatch = useDispatch();
    // @ts-ignore
    const isAuthenticated = useSelector((state) => state.auth?.session?.isAuthenticated);
    // @ts-ignore
    const { customerId, subscriptions } = useSelector((state) => state.stripe.data);
    const [selectedPlan, setSelectedPlan] = useState<PlanTypes.Plan>();
    const [planSelectionDone, setPlanselectionDone] = useState<boolean>(false);

    useEffect(() => {
        if(auth){
            dispatch(getSubscriptions(customerId));
        }
    }, []);
    
    useEffect(() => {
        if(subscriptions){
            setSelectedPlan(plans.find(p => p.priceId === subscriptions[0]?.plan?.id))
        }
    },[subscriptions])

    // const onPackageClicked = async(plan: PlanTypes.Plan) => {
    //     // history.push(`/checkout/${plan.priceId}`);

    //     // TODO: allow to use this instead if embedded from website ? - and avoid use of "/app" in the url?
    //     // window.open(`${process.env.PUBLIC_URL}/app/#/checkout/${plan.priceId}`, '_blank')
    // };

    const onCardClicked = (plan: any) => {
        if (auth) {
            setSelectedPlan(plan);
            setPlanselectionDone(true);
        } else {
            window.open(`${window.location.pathname}#/login`)
        }
    }

    return (
        <div className={styles.wrapper}>
            {!planSelectionDone && <>
                <PricingCards selectedPlan={selectedPlan} onCardClicked={onCardClicked} />
                {!isAuthenticated &&
                    // @ts-ignore
                    <div className={styles.trial}><Link onClick={() => { window.open(`${window.location.pathname}#/login`) }}>{'Try for Free'}</Link></div>
            }
            </>}
            {planSelectionDone && selectedPlan && <CountryPackageSelection onGoBack={() => { setPlanselectionDone(false); }} selectedPlan={selectedPlan} /> }
            
        </div>
    )
}
export default Pricing;