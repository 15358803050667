import React from 'react';
// import styles from './PieChart.module.css';
import { withTranslation } from 'react-i18next';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Legend, Cell } from 'recharts';
import { getColors } from '../helpers';

class Chart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            containerSize: undefined,
            fontSize: undefined,
        };
    }
    
    renderPercent = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        return `${(percent * 100).toFixed(0)}%`;
    }

    render() {
        const { data, type, dataKey, percent } = this.props;
        const colors = getColors(data.map((i)=>i.name));

        return (
            <div style={{ direction: 'ltr', width: "100%", height: "98%" } }>
                <ResponsiveContainer debounce={200} ref={(r)=>{ this.props.onRefChange(r); }}>
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey={dataKey || "value"}
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={"80%"}
                            innerRadius={type === 'donut' ? '50%' : undefined}
                            animationDuration={300}

                            // looks like animation is not working fine in combination with labels until this is fixed: https://github.com/recharts/recharts/issues/2235
                            isAnimationActive={false}

                            // label={percent ? this.renderPercent : dataKey}   // looks like this is working fine with animation, not sure why
                            label={(params)=>{
                                const fontSize = this.props.fontSize;

                                let x = params.x;
                                let y = params.y;
                                if(params.midAngle > 180 && params.midAngle < 359){
                                    y += fontSize || 0;  // shifting value labels for lower half of the chart based on the font size
                                }
                                return (
                                    <text
                                        style={{ fontSize }}
                                        fill={params.fill}
                                        x={x}
                                        y={y}
                                        textAnchor={params.x > params.cx ? "start" : "end"}
                                    >
                                        {percent ? this.renderPercent(params) : params.payload[dataKey]}
                                    </text>
                                )
                            }}
                        >
                            {data.map((item, index) => <Cell fill={colors[item.name][0]} key={index}/>)}
                        </Pie>

                        {/* TODO: add support for showing percentage ? */}
                        <Tooltip />
                        {/* this is leading to missing label when rendering (sometimes), not sure why: */}
                        {/* <Tooltip formatter={(...props)=>(props[0])} /> */}

                        <Legend
                            wrapperStyle={{fontSize: this.props.fontSize}}
                            iconSize={this.props.fontSize}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
export default withTranslation()(Chart);