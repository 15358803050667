import { FC, useState } from 'react';
import BackButton from '../BackButton/BackButton';
import classNames from 'classnames';
import styles from './FormFooter.module.scss';
import '../../../../assets/animate.css';
//@ts-ignore
import { CSSTransition } from 'react-transition-group';
import Button from '../Button/Button';
import { FormFooterProps } from './FormFooter.types';
import { useAppSelector } from 'src/redux/hooks';

/**
 * props:
 *  - onGoBack
 *  - onAction
 *  - actionDisabled
 *  - actionLabel
 *  - success - success message for action
 *  - fail - fail message for action
 */

const FormFooter: FC<FormFooterProps> = ({
    onGoBack,
    success,
    fail,
    actionDisabled,
    onAction,
    actionLabel
}) => {

    const [showAlert, setShowAlert] = useState(false);

    const theme = useAppSelector((state) => state.theme);

    const renderAlert = (alert: string, success: boolean) => {
        return (
            <CSSTransition
                in={alert && showAlert}
                classNames={{
                    enterActive: 'animated bounceIn',
                    exit: 'animated fadeOut'
                }}
                onEntered={() => setShowAlert(false)}
                timeout={2000}
                unmountOnExit
            >
                <span
                    className={classNames(
                        {
                            [theme.textSuccess]: success,
                            [theme.textDanger]: !success
                        },
                        styles.label
                    )}
                >
                    {alert}
                </span>
            </CSSTransition>
        );
    };

    return (
        <div className={styles.buttonRow}>
            <BackButton onClick={onGoBack} />

            <div className={styles.right}>
                {success && renderAlert(success, true)}
                {fail && renderAlert(fail, false)}

                <Button
                    type="success"
                    disabled={actionDisabled}
                    onClick={() => {
                        onAction && onAction();
                        setShowAlert(true);
                    }}
                >
                    {actionLabel}
                </Button>
            </div>
        </div>
    );
};

export default FormFooter;

/* class FormFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false
        };
    }

    render() {
        return (
            <div className={styles.buttonRow}>
                <BackButton onClick={this.props.onGoBack} />

                <div className={styles.right}>
                    {this.renderAlert(this.props.success, true)}
                    {this.renderAlert(this.props.fail, false)}

                    <Button
                        type='success'
                        disabled={this.props.actionDisabled}
                        onClick={() => {
                            this.props.onAction();
                            this.setState({ showAlert: true });
                        }}
                    >
                        {this.props.actionLabel}
                    </Button>
                </div>
            </div>
        );
    }

    renderAlert(alert, success) {
        return (
            <CSSTransition
                in={alert && this.state.showAlert}
                classNames={{
                    enterActive: 'animated bounceIn',
                    exit: 'animated fadeOut'
                }}
                onEntered={() => this.setState({ showAlert: false })}
                timeout={2000}
                unmountOnExit
            >
                <span
                    className={classNames(
                        {
                            [this.props.theme.textSuccess]: success,
                            [this.props.theme.textDanger]: !success
                        },                         
                        styles.label
                    )}
                >
                    {alert}
                </span>
            </CSSTransition>
        );
    }
}

const mapStateToProps = state => ({ theme: state.theme }); */

