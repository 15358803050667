import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import useDebounce from "src/hooks/useDebounce";
import { useAppSelector } from "src/redux/hooks";

import styles from './DuplicateRow.module.scss';
import TextInput from "src/Widgets/common/basicElements/TextInput/TextInput";
import Button from "src/Widgets/common/basicElements/Button/Button";


const DuplicateRow = ({ duplicateFunction, duplicateStatus, labelText, externalClass }: any) => {

    const { t } = useTranslation();

    const [duplicateInput, setDuplicateInput] = useState('');

    const theme = useAppSelector(state => state.theme)

    const duplicateInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        let duplicateInputValue = e.target.value
        duplicateInputValue = duplicateInputValue.replace(/^\s/g, '')
        setDuplicateInput(duplicateInputValue)
    }

    return (
        <div className={styles.duplicateRow}>
            <h3>{labelText}</h3>
            <div className={styles.duplicateInputContainer}>
              {duplicateStatus.isSuccess ? <span className={`${styles.label} ${theme.textSuccess}`}>{t('Successfully created')}</span>
              :
              <>
              <TextInput
                    inputClassName={externalClass ? externalClass : ''}
                    className={styles.duplicateNameInput}
                    placeholder={t('Enter a name for duplicate')}
                    onChange={duplicateInputHandler}
                    value={duplicateInput}
                />
                <Button
                    onClick={() => duplicateFunction(duplicateInput)}
                    btnClass="btnSecondary"
                    type="downloadButton"
                    disabled={!duplicateInput}
                >
                    {t('Duplicate')}
                </Button></>
              }
                
            </div>
        </div>
    );
};

export default DuplicateRow;
