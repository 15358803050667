import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Login from 'src/Login/Login';

// handle re-authentication after session expired
function ReAuth(){
    const me = useSelector((state) => state.me);
    const auth = useSelector((state) => state.auth);
    const history = useHistory();

    const username = useMemo(() => me ? me.username : undefined, [me]);
    const userid = useMemo( () => me ? me.id : undefined, [me]);

    return auth.session.isExpired === true
        &&
        <Login
            userid={userid}
            username={username}
            usernameDisabled={username ? true : false}
            isReAuthentication={userid !== undefined ? true : false}
            history={history}
        />
}

export default ReAuth;