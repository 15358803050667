// Replaces the rootPath in a string with an empty string
function replaceRootPathInString(str: string, rootPath: string): string {
    const regex = new RegExp('^' + rootPath);
    return str.replace(regex, '');
  }
  
// Recursively processes an object, replacing the rootPath in all keys and string values
  // e.g. used for converting abs. paths in window.__coverage__ variable to rel. paths (provided by istanbul coverage tool)
export function absToRelPath(obj: any, rootPath: string): any {
    if (Array.isArray(obj)) {
      return obj.map(value => {
        if (typeof value === 'object' && value !== null) {
          return absToRelPath(value, rootPath);
        } else if (typeof value === 'string') {
          return replaceRootPathInString(value, rootPath);
        } else {
          return value;
        }
      });
    } else {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
          const newKey = replaceRootPathInString(key, rootPath);
  
          if (typeof value === 'object' && value !== null) {
            return [newKey, absToRelPath(value, rootPath)];
          } else if (typeof value === 'string') {
            return [newKey, replaceRootPathInString(value, rootPath)];
          } else {
            return [newKey, value];
          }
        })
      );
    }
}