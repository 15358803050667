import Button from "src/Widgets/common/basicElements/Button/Button";
import Popup from "src/Widgets/common/basicElements/Popup/Popup";
import styles from "./QuizQuestionResult.module.scss";

function QuizQuestionResult({
    onOk,
    isCorrect
}){
    return(
        <Popup
            size='auto'
            blockContent={true}
            wrapperStyle={{position:'fixed', insetBlockStart: 'unset', insetBlockEnd: 0, height:'calc(100% - 2.2rem)'}}
            // onOk={()=>{ onOk() }}
        >
            <div style={{ overflow: 'auto' }}>
                <h2 className={isCorrect ? styles.correct : styles.wrong}>{isCorrect ? 'Correct!' : 'Oops, answer incorrect!'}</h2>
                <p>
                    {isCorrect
                        ? 'Well done, the answer you selected was correct.'
                        : 'You can try again or check out the theory for more information'
                    }
                </p>
                <Button onClick={onOk} type="success">{isCorrect ? 'Next' : 'Ok'}</Button>
            </div>
        </Popup>
    )
}

export default QuizQuestionResult;