import {
    RECEIVE_SCHEDULES,
    RECEIVE_TASKS,
    // DELETE_TASK,
    DELETE_SCHEDULE,
    POST_SCHEDULE,
    PUT_SCHEDULE,
} from '../types/actionTypes.schedules';
import APISchedules from 'src/API/APISchedules';
import _ from 'lodash';

const groupTasks = (tasks) => {
    // TODO
    let rv = _.sortBy(tasks, ['weekdays', 'time'])
        return rv;
}

// converts one task which uses "repeat" (e.g. "repeat from 12:00 every 2 hours until 16:00")
// to separate tasks without repeat (e.g. 1 task for 12:00, 1 task for 14:00, 1 task for 16:00)
const unGroupTasks = (tasks) => {
    let tmp = _.cloneDeep(tasks);
    let rv = [];
    tmp.map( (t) => {
        if(t.until){
            let curr = t.time;
            if(t.repeatUnit === 'hours') {
                t.repeatNumber *= 60;
                t.repeatUnit = 'minutes';
            }
            let count = 0;
            while(curr <= t.until){
                let task = {
                    weekdays: t.weekdays,
                    time: curr
                };
                if(count === 0){ task.id = t.id; }
                rv.push(task);

                const currMin = curr % 100;
                const currHour = Math.floor(curr / 100);
                let newMin = (currMin + t.repeatNumber);
                const newHour = currHour + Math.floor(newMin / 60);
                newMin = newMin % 60;
                curr = newMin + newHour*100;
                count++;
            }
        } else {
            rv.push(t);
        }
        return t;
    });
    rv.map((t) => {
        t.time = t.time.toString().padStart(4, '0');
        return t;
    });
    return rv;
}

const taskToDb = (t) => ({
    ...t,
    hhmm: t.time,
    time: undefined
});

const taskFromDb = (t) => ({
    ...t,
    time:t.hhmm,
    hhmm: undefined
});

export const getSchedules = () => {
    return async(dispatch) => {
            const schedules = await APISchedules.getSchedules();
            let data = schedules.data ? [...schedules.data] : [];
            data = _.sortBy(data, ['title']);
    
            dispatch({
                type: RECEIVE_SCHEDULES,
                data: data,
            });
    };
};

export const getTasks = (scheduleId) => {
    return async(dispatch) => {
            const tasks = (await APISchedules.getTasks(scheduleId)).data;
    
            dispatch({
                type: RECEIVE_TASKS,
                data: groupTasks( tasks.map(t=>taskFromDb(t)) ),
                id: scheduleId,
            });
    };
};

export const deleteSchedule = (schedule) => {
    return async(dispatch) => {
        // not sure if we need to do this ?, depends on backend implementation
        // await Promise.all(
        //     schedule.tasks.map(async(t)=>{
        //         await APISchedules.deleteTask(schedule.id, t.id);
        //     })
        // );

        const res = await APISchedules.deleteSchedule(schedule.id, [4445]);
        if(res && res.rc === 0) {
            dispatch({
                type: DELETE_SCHEDULE,
                id: schedule.id,
            });
            return res;
        } else {
            return res.data;
        }
    };
};

export const postSchedule = (schedule) => {
    return async(dispatch) => {
        let postData = _.cloneDeep(schedule);
        delete postData.tasks;
        let resp = await APISchedules.postSchedule(postData);
        const scheduleId = resp.data ? resp.data.id : undefined;

        // let taskIds = [];
        let tasks = _.cloneDeep(schedule.tasks);
        tasks = unGroupTasks(tasks);
        if(resp && resp.rc === 0){
            await Promise.all(
                tasks.map(async(t)=>{
                    await APISchedules.postTask(scheduleId, taskToDb(t));
                    // const id = resp.data ? resp.data.id : undefined;
                    // taskIds.push(id);
                })
            );
        }

        dispatch({
            type: POST_SCHEDULE,
            data: {
                ...schedule,
                id: scheduleId,
                // taskIds
            },
        });
        return scheduleId;
    };
};

export const putSchedule = (id, schedule, tasksToDelete=[]) => {
    return async(dispatch) => {
        schedule.tasks = unGroupTasks(schedule.tasks);
        await Promise.all(
            schedule.tasks.map(async(t)=>{
                if(t.id){
                    // update existing task
                    await APISchedules.putTask(schedule.id, t.id, taskToDb(t));
                } else {
                    // new task
                    await APISchedules.postTask(schedule.id,taskToDb(t));
                    // const id = resp.data ? resp.data.id : undefined;
                    // schedule.taskIds.push(id);
                }
            })
        );
        // delete tasks from schedule
        await Promise.all(tasksToDelete.map(async (tId) => {
            await APISchedules.deleteTask(schedule.id, tId);
        }));
        let postData = _.cloneDeep(schedule);
        delete postData.tasks;
        await APISchedules.putSchedule(schedule.id, postData);

        dispatch({
            type: PUT_SCHEDULE,
            id: schedule.id,
            data: schedule,
        });
    };
};

// export const deleteTask = (taskId) => {
//     return async(dispatch) => {
//         await sleep(5000);
//         dispatch({
//             type: DELETE_TASK,
//             id: taskId
//         })
//     };
// };