import { useEffect, useState } from 'react';
import styles from './Settings.module.scss';
import { useTranslation } from 'react-i18next';
import APIMe from '../../API/APIMe';
import APIOptions from '../../API/APIOptions';
import LoadingSpinner from '../../Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import Select from 'react-select';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import ThemeSelector from './ThemeSelector';
import APISettings from '../../API/APISettings';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkspaces } from '../../redux/actions/actions.workspaces';
import { setCurrentWorkspace, setLoadingGlobal } from '../../redux/actions/actions.board';
import { createDefaultWorkspaces, getWeekdays, isMentionFindr } from '../../Widgets/common/helpers';
import LabeledSwitch from '../../Widgets/common/basicElements/SwitchElements/LabeledSwitch';
import { USER_SETTINGS } from 'src/consts';
import { updateAccountSettings } from 'src/redux/actions/actions.theme';
import Popup from 'src/Widgets/common/basicElements/Popup/Popup';
import defaultWorkspaces from 'src/Board/defaultWorkspaces/dart';
import PasswordInput from 'src/Widgets/common/basicElements/PasswordInput/PasswordInput';

/*
ideas:
 - option to turn off history (epg, search)
*/

const Settings = ({showAcademy, history}) => {
    const [settings, setSettings] = useState({
        timezone: '',
        itemsPP: '',
        mediaEditor_highlightWhilePlaying: undefined,
      });
      const [userSettings, setUserSettings] = useState({
        themeName: '',
        academyEnabled: false,
        gravatarEnabled: false,
          weekend: [],

      });
    const [edited, setEdited] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [warningText, setWarningText] = useState('');
    const [timezones, setTimezones] = useState([]);
    const [loadingMe, setLoadingMe] = useState(true);

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const workspaces = useSelector(state => state.workspaces)


    useEffect(() => {
        let isMounted = true;
        dispatch(setCurrentWorkspace(undefined))
      const fetchData = async () => {
        try {
          const me = (await APIMe.get()).data;
          const tzs = (await APIOptions.getTimezones()).data;
          const fetched_userSettings = await APISettings.getItems('general');
          let userSettings = fetched_userSettings?.data.find(item => item.key === 'accountSettings');
          if (userSettings === undefined) {
            userSettings = fetched_userSettings?.data?.find((e) => e.themeName !== undefined);
          }
  
          if (isMounted) {
            setSettings(me);
            setTimezones(tzs);
            setUserSettings(userSettings);
            setLoadingMe(false);
          }
        } catch (error) {
          console.error(error);
          setLoadingMe(false);
        }
      };
  
      fetchData();
  
      return () => {
        isMounted = false;
      };
    }, []);

      const save = async () => {
        const timezoneObj = timezones.find(item => item.id === settings.timezone);
        await APIMe.put({ TZ: timezoneObj.title, itemsPP: settings.itemsPP, mediaEditor_highlightWhilePlaying: settings.mediaEditor_highlightWhilePlaying });
        const settingsUpdate = {
          ...userSettings,
          key: USER_SETTINGS.GENERAL_KEYS.ACCOUNT_SETTINGS,
        };
    
        if (userSettings.id !== undefined) {
          // if one item already exists, update the existing item in 'general' settings
          APISettings.putItem('general', userSettings.id, settingsUpdate);
        } else {
          // create a new item in general settings
          APISettings.postItem('general', settingsUpdate);
        }
    
        if (edited) {
          dispatch(updateAccountSettings(settingsUpdate))
          setEdited(false);
        }
      };

      const handleConfirm = async () => {
        setShowPopup(false);
        dispatch(setLoadingGlobal(true))
        await Promise.all(
          workspaces?.map(async (workspace) => {
            await Promise.all(
              workspace.widgets?.map(async (w) => {
                await APISettings.deleteItem('widgets', w.dbId);
              })
            );
            await APISettings.deleteItem('workspaces', workspace.id);
          })
        );
        await createDefaultWorkspaces(defaultWorkspaces);
    
        dispatch(setLoadingGlobal(false))
        dispatch(getWorkspaces())
        history?.push('/workspaces/1');
      };

      const handleCancel = () => {
        setShowPopup(false);
      };

      const resetWorkspacesHandler = () => {
        const warningText = t(
          'Do you really want to delete all current workspaces and recreate the default workspaces?'
        );
        setShowPopup(true);
        setWarningText(warningText);
      };

    // async setNewPassword(){

    //         const reset = await this.resetWorkspaces();
    //         if (reset) {
    //             this.setState({ workspacesReset: true }, () => {
    //                 this.props.getWorkspaces();
    //             });
    //         }

    // }
    if (loadingMe) {
        return <LoadingSpinner />;
    }

    const handleChange = (key, value) => {
      setSettings((prevState) => ({
        ...prevState,
        [key]: value,
      }));
      setEdited(true);
    };
    
    const timezoneOptions = timezones.map((tz) => ({
      label: tz.title,
      value: tz.id,
    }));  
        
       
    const timezoneSelected = timezoneOptions.find((tz) => tz.label === settings.timezone);   

    const hitsPerPageOptions = [
        { label: '10', value: 10 },
        { label: '15', value: 15 },
        { label: '20', value: 20 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
        { label: '100', value: 100 }
    ];
        
    const hitsPerPageSelected = hitsPerPageOptions.find((option) => option.value === settings.itemsPP);        
    const languageList = window.navigator.languages.slice(0, 3);

    const renderWeekdays = () => {
      return getWeekdays()?.map((d, i) => (
        <LabeledSwitch
          key={i}
          checked={settings.weekend?.includes(d.id)}
          disabled
          className={styles.labeledSwitch}
          onChange={() => {}}
        >
          {d.text}
        </LabeledSwitch>
      ));
    };

    return (
        <section className={styles.mainWrapper}>
            <h2>{t('Settings')}</h2>
            <div className={styles.settingWrapper}>
                <div className={styles.selectBoxGroupWrapper}>
                    {/* -----------------------------------Timezone Start---------------------------------- */}
                    <div className={styles.optionsWrap}>
                        <span className={styles.label}>{t('Timezone')}:</span>
                        <Select
                            className={styles.select}
                            placeholder={t('Select Timezone') + '*'}
                            options={timezoneOptions}
                            value={timezoneSelected}
                            onChange={(o) => {
                                handleChange('timezone', o.value);
                            }}
                        />
                    </div>
                    {/* -----------------------------------Timezone End---------------------------------- */}
                    {/*---------------------------- Number of hits per page -----------------------------*/}

                    <div className={styles.optionsWrap}>
                        <span className={styles.label}>
                            {t('Number of hits per page')}:
                        </span>
                        <Select
                            className={styles.select}
                            placeholder={t('Select hits per page')}
                            options={hitsPerPageOptions}
                            value={hitsPerPageSelected}
                            onChange={(h) => {
                                handleChange('itemsPP', h.value);
                            }}
                        />
                    </div>
                </div>
                {/*------------------------------------nhpp end --------------------------------------*/}
                {/*--------------------------------Colortheme selector--------------------------------*/}
                <div className={styles.themeMainWrapper}>
                    <div className={styles.themeSelectorWrap}>
                        <span className={styles.label}>{t('Choose a color theme')}:</span>
                        <ThemeSelector
                            selectedTheme={userSettings?.themeName}
                            onThemeChanged={(themeName, edited = true) => {
                                setUserSettings((prevUserSettings) => ({
                                    ...prevUserSettings,
                                    themeName: themeName
                                }));
                                setEdited(true);
                            }}
                        />
                    </div>

                {/*------------------------------------ cts end --------------------------------------*/}

                    {/*--------------------------------Gravatar / Academy switches--------------------------------*/}
                    <div className={styles.switchesWrapper}>
                    <div className={styles.academyWrapper}>
                        <span id={styles.switch} className={styles.label}>{t('Use Gravatar')}:</span>
                        <LabeledSwitch
                            title={
                                t('use gravatar for profile and user icons')
                            }
                            checked={userSettings?.gravatarEnabled}
                            className={styles.labeledSwitch}
                            onChange={(val) => {
                                setUserSettings((prevUserSettings) => ({
                                    ...prevUserSettings,
                                    gravatarEnabled: val
                                }));
                                setEdited(true);
                            }}
                        ></LabeledSwitch>
                    </div>


                    <div className={styles.academyWrapper}>
                        <span id={styles.switch} className={styles.label}>{t('Enable Academy')}:</span>
                        <LabeledSwitch
                            title={
                                showAcademy ? t('disabled_while_academy_mode') : undefined
                            }
                            disabled={showAcademy}
                            checked={userSettings?.academyEnabled}
                            className={styles.labeledSwitch}
                            onChange={(val) => {
                                setUserSettings((prevUserSettings) => ({
                                    ...prevUserSettings,
                                    academyEnabled: val
                                }));
                                setEdited(true);
                            }}
                        ></LabeledSwitch>
                        </div>

                        {/* <h3 style={{ marginBottom: '0.7rem' }}>{t('Media Editor Settings')}:</h3>
                        
                        <div className={styles.academyWrapper}>
                            <span id={styles.switch} className={styles.label}>{t('Highlight words while playback')}:</span>
                            <LabeledSwitch
                                title={
                                    showAcademy ? t('disabled_while_academy_mode') : undefined
                                }
                                disabled={showAcademy}
                                checked={settings?.mediaEditor_highlightWhilePlaying}
                                className={styles.labeledSwitch}
                                onChange={(val) => {
                                    // setUserSettings((prevUserSettings) => ({
                                    //     ...prevUserSettings,
                                    //     mediaEditor_highlightWhilePlaying: val
                                    // }));
                                    handleChange('mediaEditor_highlightWhilePlaying', val);
                                    setEdited(true);
                                }}
                            ></LabeledSwitch>
                        </div> */}
                    </div>
                    <div className={styles.controls}>
                        <Button
                            type="success"
                            disabled={!edited}
                            onClick={() => {
                                save();
                            }}
                        >
                            {t('Save Changes')}
                        </Button>
                    </div>
                </div>
            </div>
            <div className={styles.optionsWrap}>
                <span className={styles.label}>
                    {t('Your preferred browser languages')}:
                </span>
                <div className={styles.list}>
                    {languageList?.map((l, i) => (
                        <div className={styles.langItem} key={i}>
                            {i + 1}. {l}
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.optionsWrap}>
                <span className={styles.changeButtonLabels}>
                    {t('API Key')}:
                </span>
                <div style={{ width: 'auto', flex: 1, maxWidth: '30rem' }}>
                    <PasswordInput allowShow={true} value={settings.apikey} />
                </div>
                {/* <FontAwesomeIcon title={t('Copy to Clipboard')} style={{margin:'0 0.7rem', cursor: 'pointer'}} icon={faClipboard} onClick={()=>{}} /> */}
            </div>
            <div className={styles.optionsWrap}>
                <span className={styles.changeButtonLabels}>
                    {t('Change your current password to a new one')}:
                </span>
                <Button
                    type="secondary"
                    onClick={() =>
                        window.open(
                            '/cgi-bin/requestPwdLink?forV3=1&username=' +
                                settings?.username,
                            '_blank'
                        )
                    }
                    title={t(
                        'This will send an e-mail to you. Follow the instructions in the e-mail to set a new password.'
                    )}
                >
                    {t('set new password')}
                </Button>
            </div>

            {!showAcademy && (
                <div className={styles.optionsWrap}>
                    <span className={styles.changeButtonLabels}>
                        {t('Reset recent workspace to default workspaces')}:
                    </span>
                    <Button
                        type="secondary"
                        // btnStyle={{ marginInlineStart: 0 }}
                        onClick={() => {
                            resetWorkspacesHandler();
                        }}
                    >
                        {/* TODO: add loading indicator ? */}
                        {t('reset workspaces')}
                    </Button>
                </div>
            )}

            <div className={styles.optionsWrap}>
                <span className={styles.changeButtonLabels}>
                    {t('Support E-Mail')}:
                </span>
                <div style={{ width: 'auto', flex: 1, maxWidth: '30rem' }}>
                    <a href={`mailto:${settings.support}`}>{settings.support}</a>
                </div>
            </div>
            
            <div className={styles.adminSettings}>
                <hr className={styles.adminBorder}></hr>
                <h3>{t('Settings defined by the user administrator')}:</h3>
                <div className={styles.optionsWrap}>
                    <span className={styles.label}>{t('Weekend days')}:</span>
                    <span className={styles.weekdays}>
                    {renderWeekdays()}
                    </span>
                </div>

                <div className={styles.optionsWrap}>
                    <span className={styles.label}>{t('Maximum clip length')}:</span>
                    {Math.round((settings.maxDownloadLength / 60) * 10) / 10 +
                        ' ' +
                        t('min')}
                </div>
            </div>
            {showPopup && (
                <Popup
                    onOk={handleConfirm}
                    onCancel={handleCancel}
                    okTxt={t('Ok')}
                    cancelTxt={t('Cancel')}
                    wrapperStyle={{
                        position: 'fixed',
                        insetBlockStart: 'unset',
                        insetBlockEnd: 0,
                        height: 'calc(100% - 2.2rem)'
                    }}
                    size="auto"
                    blockContent
                >
                    <p>{warningText}</p>
                </Popup>
            )}
        </section>
    );
    }

export default Settings

