import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

/**
 * Alert box that displays information about failed actions or highlights important information (e.g. spam count warning)
*/

const box = 
{ padding: '.5em .2em'
, borderRadius: '5px'
, borderWidth: '1px'
, borderStyle: 'solid'
, margin: '.5em 0'
};

class AlertBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div
                style={box}
                className={classNames(
                    this.props.theme.textDanger,
                    this.props.theme.borderDanger    
                )}
            >
                {this.props.children}
            </div>
        )
    }
}

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(AlertBox);