import React from 'react';
import { withTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { dynamicConfig } from 'src/config/dynamicConfig/dynamicConfig';

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.props.title) {
            document.title = `${dynamicConfig.workspacePageTitlePrefix}${this.props.title}`;
        } else {
            document.title = `${dynamicConfig.pageTitlePrefix}${dynamicConfig.pageTitle}`;
        }
        return <Route {...this.props}/>
    }
}
export default withTranslation()(Page);

// TODO: use this to set page title for routes with /embed like shown here: https://stackoverflow.com/a/55415722/3215297