import {
    RECEIVE_ITEMS,
    LOADING_ITEMS,
} from '../types/actionTypes.channels';
import { STATUS_IDLE, STATUS_LOADING, STATUS_SUCCEEDED } from '../types/status';
// import _ from 'lodash';

const channelReducers = (state = {
    status: STATUS_IDLE,
    data: [],
}, action) => {
    // let idx;
    switch (action.type) {
        case LOADING_ITEMS:
            return {...state, status: STATUS_LOADING};
        case RECEIVE_ITEMS:
            return {
                data: action.data,
                status: STATUS_SUCCEEDED,
            };
        default:
            return state;
    }
};

export default channelReducers;