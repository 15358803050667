import React from 'react';
import settingsStyles from './SearchSettings.module.css';
import facetStyles from './SearchFacets.module.css';
import _ from 'lodash';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

/**
 * SearchFacets
 *
 * props
 *  - limit: number of facets to initially show (per category)
 */

class SearchFacets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: {
                byDate: false,
                byChannel: false,
            },
        };
    }

    renderFacet(type, title, count, selected, index, facetIdentifier) {
        let styles = selected 
            ? classNames(facetStyles.facet, this.props.theme.backgroundSecondary)
            : classNames(facetStyles.facet, facetStyles.nonSelectedFacet);
        return (
            <div
                key={type + index}
                className={styles}
                onClick={() => {
                    this.props.onFacetClick(type, facetIdentifier, index);
                }}
            >
                <div>{title}</div>
                <div>{count}</div>
            </div>
        );
    }

    renderShowMore(showMore, numberOfItems, limit, category){
        if(numberOfItems > limit){
            return(
                <div
                    style={{textAlign:'center', fontWeight: 'bold'}}
                    className={classNames(facetStyles.facet, facetStyles.nonSelectedFacet)}
                    onClick={()=>{
                        this.setState( s =>{
                            s.showMore[category] = !s.showMore[category];
                            return s;
                        })
                    }}
                >
                        {showMore ? this.props.t(`Show less`) : this.props.t(`Show more`)}
                </div>
            )
        }
    }

    render() {
        // translation method with fallback
        const t = this.props.t || (k => k);
        const {showMore} = this.state;
        const {facets} = this.props;
        const displayedFacets = {
            byDate: !showMore.byDate ? facets.byDate.slice(0, this.props.limit) : facets.byDate,
            byChannel: !showMore.byChannel ? facets.byChannel.slice(0, this.props.limit) : facets.byChannel
        }

        return (
            <div className={settingsStyles.settingsWrapper}>
                <div className={settingsStyles.category}>{t('Filter by date')}</div>
                {_.map(displayedFacets.byDate, (f, index) => {
                    return this.renderFacet(
                        'byDate',
                        f.date,
                        f.resultsCount,
                        f.selected,
                        index,
                        f.date,
                    );
                })}
                {this.renderShowMore(showMore.byDate, facets.byDate.length, this.props.limit, 'byDate')}
                <div className={settingsStyles.category}>{t('Filter by channel')}</div>
                {_.map(displayedFacets.byChannel, (f, index) => {
                    return this.renderFacet(
                        'byChannel',
                        f.channel.title,
                        f.resultsCount,
                        f.selected,
                        index,
                        f.id,
                    );
                })}
                {this.renderShowMore(showMore.byChannel, facets.byChannel.length, this.props.limit, 'byChannel')}
            </div>
        );
    }
}

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(withTranslation()(SearchFacets));
