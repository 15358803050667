import { sanitizeQuery } from 'src/Widgets/common/helpers';
import GenericCalls from './genericCalls';

class APIQueryVisualization {
    static async get(query:string, hideErrors:number[], lang?:string) {
        return await GenericCalls.get('/autocomplete', { query: sanitizeQuery(query), ll: lang }, undefined, hideErrors);
    }

    static async isQuerySyntaxValid(query: string) {
        const hideErrors:number[] = [90013];
        const response = await APIQueryVisualization.checkQuerySyntax(query, hideErrors);
        const isValid = response?.data?.rc !== 90013;
        return isValid;
    }

    static async checkQuerySyntax(query:string, hideErrors:number[]) {
        return await GenericCalls.get('/autocomplete', { query }, undefined, hideErrors);
    }
}

export default APIQueryVisualization;
