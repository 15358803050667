import { logo } from 'src/assets/themes/themes';
import imgFooter from 'src/assets/emm-wave.png';
import imgFooterBig from 'src/assets/emm-wave-big.png';
import { PptxGenTypes } from './Pptxgen.types';

const getSlideMasters:PptxGenTypes.GetSlideMasters = (layout, logoSize, imgFooterSize, imgFooterBigSize) => {
    const slideMaster = {
        title: "MASTER_SLIDE",
        background: { color: "FFFFFF" },
        objects: [
            {
                image: {
                    x: (layout.width - logoSize.w - 0.35),
                    y: 0.4,
                    w: logoSize.w,
                    h: logoSize.h,
                    path: logo,
                }
            },
            {
                image: {
                    x: 0,
                    y: (layout.height - imgFooterSize.h),
                    w: imgFooterSize.w,
                    h: imgFooterSize.h,
                    path: imgFooter,
                }
            },
            {
                placeholder: {
                    options: {
                        name: "title",
                        type: "title",
                        x: 0.4,
                        y: 0.8,
                        color: 'ff9300',
                        align: 'left',
                        fontSize: '24'
                    },
                }
            },
            {
                placeholder: {
                    options: {
                        name: "report_element_text",
                        type: "body",
                        x: layout.width * 0.65,
                        y: 0,
                        w: layout.width * 0.35,
                        h: layout.height * 1,
                        align: 'center',
                        valign: 'middle',
                        fontSize: '16'
                    },
                    text: "report-element-text",
                }
            }
        ],
        slideNumber: {
            x: "95%",
            y: "91%",
            // fontSize: 20,
            color: 'ffffff',
        },
    };

    const titleSlideMaster = {
        title: "TITLE_SLIDE",
        background: { color: "FFFFFF" },
        objects: [
            {
                image: {
                    x: (layout.width - logoSize.w - 0.35),
                    y: 0.4,
                    w: logoSize.w,
                    h: logoSize.h,
                    path: logo,
                }
            },
            {
                image: {
                    x: 0,
                    y: (layout.height - imgFooterBigSize.h),
                    w: imgFooterBigSize.w,
                    h: imgFooterBigSize.h,
                    path: imgFooterBig,
                }
            },
            {
                placeholder: {
                    options: {
                        name: "title",
                        type: "title",
                        x: 0.7,
                        y: 1.6,
                        color: 'ff9300',
                        align: 'left',
                        fontSize: '24',
                        bold: true,
                    },
                }
            },
            {
                placeholder: {
                    options: {
                        name: "text",
                        type: "body",
                        x: 0.7,
                        y: layout.height * 0.7,
                        w: layout.width - 1.4,
                        h: layout.height * 0.2,
                        align: 'left',
                        valign: 'middle',
                        fontSize: '18',
                        color: '#FFFFFF',
                    },
                    text: "text",
                }
            }
        ],
        slideNumber: {
            x: "95%",
            y: "91%",
            color: 'ffffff',
        },
    }

    return {
        titleSlide: titleSlideMaster,
        slide: slideMaster,
    }
}

export {getSlideMasters};