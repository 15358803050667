import React from 'react';
import styles from './LinkWidget.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from 'src/Widgets/common/basicElements/Button/Button';

class LinkWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { t, title, url } = this.props;
        return(
            <div className={styles.wrapper}>
                <a target="_blank" href={url}>
                    <Button type='primary'>
                        {t(title)}
                    </Button>
                </a>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    //theme: state.theme,
});
export default connect(mapStateToProps,{ /* insert redux actions here */ })(withTranslation()(LinkWidget));