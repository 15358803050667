import { useTranslation } from 'react-i18next';
import { ChannelType, UseChannelCountProps } from '../types/types';

const useChannelsCount = ({selectedChannels, shownChannelsData, selectedGroup, filteredDataCount}: UseChannelCountProps) => {

    const { t } = useTranslation();

    const notShownInGroupSelectedChannels = () => {
        const shownChannelsIds = shownChannelsData.map((channel: ChannelType) => channel.id);
        const shownSelectedChannels = selectedChannels.filter((channel: ChannelType) => {
            return shownChannelsIds.includes(channel.id);
        });
        return selectedChannels.length - shownSelectedChannels.length;
    };

    const dynamicHeadingText = (): JSX.Element => {
        const selectedChannelsText =
            selectedChannels.length > 0
                ? `${selectedChannels.length} ${t('Selected')},`
                : '';
        if (selectedGroup?.title) {
            return (
                <div>
                    <h2 style={{ fontSize: '1rem' }}>
                        {t('List of Channels')} "{selectedGroup.title}" (
                        {selectedChannelsText} {filteredDataCount} {t('Displayed')}{' '}
                        {notShownInGroupSelectedChannels() !== 0 && (', ' + notShownInGroupSelectedChannels() + ' ' + t('Not Shown'))})
                    </h2>
                </div>
            );
        }else{
            return (
                <div>
                    <h2 style={{ fontSize: '1rem' }}>
                        {t('List of Channels')} "{t('All Channels')}" ({selectedChannelsText}{' '}
                        {filteredDataCount} {t('Displayed')})
                    </h2>
                </div>
            );
        }
        
    };

    return {
        dynamicHeadingText
    };
};

export default useChannelsCount;