import { FC, useState } from 'react';
import styles from './History.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faChevronDown,
    faChevronLeft
} from '@fortawesome/free-solid-svg-icons';
import { HistoryProps, ItemProps } from './History.types';

const History: FC<HistoryProps> = ({
    data,
    onClick,
    onDelete,
    showAllItems,
    maxItems
}) => {

    const [openedItem, setOpenedItem] = useState<number>();

    const { i18n } = useTranslation();

    const reverseIndex = (idx: number) => {
        return data?.length - 1 - idx;
    };

    const renderRow = (item: ItemProps, idx: number) => {
        const isOpened = openedItem === idx;
        const dir = i18n.dir()
        let chevronIcon;

        if (isOpened) {
            chevronIcon = faChevronDown;
        } else {
            if (dir === 'rtl') {
                chevronIcon = faChevronLeft;
            } else {
                chevronIcon = faChevronRight;
            }
        }

        let itemStyles = [styles['history-item']];
        if (isOpened) {
            itemStyles.push(styles.opened);
        }

        return (
            <div key={idx}>
                <div className={classNames(itemStyles)}>
                    {item.content ? (
                        <FontAwesomeIcon
                            className={styles.arrow}
                            icon={chevronIcon}
                            onClick={() => {
                                isOpened ? setOpenedItem(undefined) : setOpenedItem(idx);
                            }}
                        />
                    ) : null}
                    <span
                        onClick={() => {
                            onClick(reverseIndex(idx));
                        }}
                        className={styles['history-item-title']}
                    >
                        <span>{item.textLeft}</span>
                        <span>{item.textMiddle}</span>
                        <span>{item.textRight}</span>
                        {/* <span>{dateToString(new Date(item.timestamp))}</span> */}
                        {/* <span>{item.program ? item.program : dateToTimeString(new Date(item.timestamp))}</span> */}
                    </span>
                    <FontAwesomeIcon
                        className={styles.cross}
                        onClick={() => onDelete(reverseIndex(idx))}
                        icon="times"
                    />
                </div>
                {openedItem === idx ? <div>{item.content}</div> : null}
            </div>
        );
    };

/*     let maxItemsCount = !showAllItems ? maxItems : data.length;
    if (maxItemsCount > data.length) {
        maxItemsCount = data.length;
    } */

    return (
        <div className={styles['history']}>
            {data.length > 0
                ? data
                      .slice(maxItems && data.length - maxItems, data.length)
                      .reverse()
                      .map((item, idx) => renderRow(item, idx))
                : <div></div>}
        </div>
    );
};

export default History;
