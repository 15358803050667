import React from 'react';
import styles from './WidgetSettings.module.scss';
import { withTranslation } from 'react-i18next';
import SelectAsync from 'src/Widgets/common/basicElements/SelectAsync/SelectAsync';
import _ from 'lodash';
import { connect } from 'react-redux';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { getWidgetState } from 'src/Widgets/common/helpers';
import DateRangePicker from 'src/Widgets/common/basicElements/DateRangePicker/DateRangePicker';
import PropTypes from 'prop-types';

class WidgetSettings extends React.Component {
    static propTypes = {
        settings: PropTypes.object, // available settings
        isWorkspace: PropTypes.bool, // true if rendered from WorkspaceSettings component
        saveWidgetState: PropTypes.func,
        workspaceId: PropTypes.number,
        state: PropTypes.object, // TODO improvement: should actually have isRequired if isWorkspace !== true, but it looks like 'prop-types' library is not supporting this
    }

    constructor(props) {
        super(props);
        this.state = {
            settings: {}
        };
    }

    async componentDidMount() {
        const state_settings = await this.onAvailableSettingsChanged(this.props.settings);

        if (this.props.workspaces[this.props.board.currentWorkspaceIndex]) {
            this.onSettingsChanged();
        }

        this.setState({settings: state_settings});
    }

    async onAvailableSettingsChanged(settings) {
        let state_settings = {};
        await Promise.all(Object.keys(settings).map(async (key) => {
            const options = await (settings[key].getOptions ? settings[key].getOptions() : undefined);
            state_settings[key] = {
                ..._.cloneDeep(settings[key]),
                options
            }
        }));
        return state_settings;
    }

    async onSettingsChanged (prevProps) {
        const { workspaces, board, widgetId, settings, getData } = this.props;
        if(!getData) return;
        const workspaceSettings = workspaces[board.currentWorkspaceIndex].state.settings;
        const widgetState = getWidgetState(workspaces, board, widgetId);
        if (
            getData && 
            Object.keys(workspaceSettings).length > 0
            && (!prevProps || !_.isEqual(workspaceSettings, prevProps.workspaces[board.currentWorkspaceIndex].state.settings))
        ) {
            // ensure we do not use settings which are not available for the current widget (e.g. because applied via workspace-wide setting)
            let applicableSettings = {};
            Object.keys(settings).map((s) => {
                return applicableSettings[s] = workspaceSettings[s];
            });
            
            await getData(applicableSettings, workspaceSettings.clipsType);
        } 
        else if (
            widgetState && widgetState.settings
            && (
                !prevProps
                || !_.isEqual(this.props.state.settings, prevProps.state.settings)
                || ( prevProps.workspaces[board.currentWorkspaceIndex] && !_.isEqual(prevProps.workspaces[board.currentWorkspaceIndex].state.settings, workspaceSettings) && Object.keys(workspaceSettings).length === 0 )
            )
            && Object.keys(workspaceSettings).length === 0
        ) {
            // await getData(_.cloneDeep(widgetState.settings));
            await getData(widgetState.settings, widgetState.settings.clipsType);
        }
    }

    async componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.settings, prevProps.settings)) {
            const state_settings = await this.onAvailableSettingsChanged(this.props.settings);
            this.setState({settings: state_settings});
        }
        if(this.props.workspaces[this.props.board.currentWorkspaceIndex]){
            this.onSettingsChanged(prevProps);
        }
    }

    onChange(currentSettings, key, val){
        let settings = _.cloneDeep(currentSettings);
        if (val === undefined || val === null) {
            delete settings[key];
        } else {
            settings[key] = val;
        }
        this.props.saveWidgetState(this.props.workspaceId, this.props.widgetId, { ...this.props.state, settings });
    }

    render() {
        const { settings } = this.state;

        const {
            t, show, toggleWidgetSettings, workspaces, board, widgetId,
            placeholderText, isWorkspace, className
        } = this.props;
        let workspaceSettings;
        if(workspaces[board.currentWorkspaceIndex]){
            workspaceSettings = workspaces[board.currentWorkspaceIndex].state.settings;
        }

        let currentSettings;
        let widgets;
        if(workspaces[board.currentWorkspaceIndex] !== undefined){
            widgets = _.find(workspaces[board.currentWorkspaceIndex].widgets, { id: widgetId })
        }
        if (widgets !== undefined
            && widgets.state !== undefined
            && widgets.state.settings !== undefined
            || this.props.isWorkspace
        ) {
            if (this.props.isWorkspace) {
                currentSettings = workspaces[board.currentWorkspaceIndex].state.settings;
            } else {
                currentSettings = widgets.state.settings;
            }
        }

        const clipsTypeOptions = [
            { value: 'recent', label: t('Recent_clips') },
            { value: 'edited', label: t('Edited_clips') }
        ];

        const selectedClipsType = clipsTypeOptions.find((e) => e.value === currentSettings?.clipsType) || clipsTypeOptions[0];

        return (
            <div
                style={{ display: show ? 'block' : 'none' }}
                className={[styles.widgetSettingsWrapper, className].join(' ')}
            >
                <div className={styles.widgetSettings}>
                    <h4>{t('Filters')}</h4>
                    <div className={styles.filtersInfo}>{t('filters-explanation')}</div>
                    <div className={styles.settings}>
                        {/* {isWorkspace ? <div>{ t('workspace-settings-explanation')}</div> : null} */}
                        {workspaceSettings &&
                        Object.keys(workspaceSettings).length > 0 &&
                        !isWorkspace ? (
                            <div>{t('workspace-settings-used')}</div>
                        ) : // : isWorkspace && workspaces[board.currentWorkspaceIndex] && workspaces[board.currentWorkspaceIndex].widgets.length < 2
                        //     && Object.keys(workspaceSettings).length == 0
                        //     ?
                        //         <div>
                        //             {t("workspace-settings-disabled-more-widgets-needed")}
                        //         </div>
                        Object.keys(settings).length > 0 ? (
                            <>
                                {Object.keys(settings).map((key, index) => {
                                    let val;
                                    if (currentSettings) {
                                        const ids = currentSettings[key];
                                        if (ids !== undefined && settings[key].options) {
                                            val = ids.map((id) => {
                                                if (
                                                    settings[key].options[0] &&
                                                    settings[key].options[0].options
                                                ) {
                                                    // handling grouped dropdown
                                                    for (
                                                        let i = 0;
                                                        i < settings[key].options.length;
                                                        i++
                                                    ) {
                                                        const o =
                                                            settings[key].options[i];
                                                        let profile = _.find(o.options, {
                                                            value: id
                                                        });
                                                        if (profile) return profile;
                                                    }
                                                } else {
                                                    // handling ungrouped dropdown
                                                    return _.find(settings[key].options, {
                                                        value: id
                                                    });
                                                }
                                                return undefined;
                                            });
                                        }
                                    }
                                    const s = settings[key];
                                    let domTitle = (
                                        <div style={{ fontWeight: 'bold' }}>
                                            {t(s.title)}
                                        </div>
                                    );
                                    if (s.comp) {
                                        return (
                                            <span key={index}>
                                                {domTitle}
                                                {s.comp}
                                                {/* {React.cloneElement( // add key prop to component
                                                    s.comp, { key: index }
                                                )} */}
                                            </span>
                                        );
                                    } else {
                                        let domSetting;
                                        switch (s.type) {
                                            case 'daterange':
                                                domSetting = (
                                                    <DateRangePicker
                                                        value={
                                                            currentSettings
                                                                ? currentSettings[key]
                                                                : undefined
                                                        }
                                                        onChange={(val) =>
                                                            this.onChange(
                                                                currentSettings,
                                                                key,
                                                                val
                                                            )
                                                        }
                                                        archive={true}
                                                        lastX={true}
                                                        allowClear={false}
                                                        useFormStyle={false}
                                                        // className="datepicker-full-width"
                                                    />
                                                );
                                                break;
                                            default:
                                                if (val === undefined) val = null;
                                                domSetting = (
                                                    <SelectAsync
                                                        selectAll={true}
                                                        value={val}
                                                        isMulti={true}
                                                        placeholder={s.title}
                                                        closeMenuOnSelect={false}
                                                        options={s.options}
                                                        menuList={{
                                                            width: 'max-content',
                                                            minWidth: '100%',
                                                            maxHeight: '200px'
                                                        }}
                                                        onChange={(values) =>
                                                            this.onChange(
                                                                currentSettings,
                                                                key,
                                                                values
                                                                    ? values.map(
                                                                          (v) => v.value
                                                                      )
                                                                    : undefined
                                                            )
                                                        }
                                                    />
                                                );
                                                break;
                                        }
                                        return (
                                            <span key={index}>
                                                {domTitle}
                                                {domSetting}
                                            </span>
                                        );
                                    }
                                })}
                                <div style={{ fontWeight: 'bold' }}>
                                    {t('Clips Type')}
                                </div>
                                <SelectAsync
                                    value={selectedClipsType}
                                    // placeholder={s.title}
                                    closeMenuOnSelect={true}
                                    options={clipsTypeOptions}
                                    menuList={{
                                        width: 'max-content',
                                        minWidth: '100%',
                                        maxHeight: '200px'
                                    }}
                                    onChange={(values) => {
                                        this.onChange(
                                            currentSettings,
                                            'clipsType',
                                            values ? values.value : undefined
                                        );
                                    }}
                                />
                            </>
                        ) : (
                            <div style={{ fontWeight: 'bold' }}>{placeholderText}</div>
                        )}
                    </div>
                    <Button
                        type="primary"
                        onClick={async () => {
                            toggleWidgetSettings();
                        }}
                    >
                        {t('Close Filters')}
                    </Button>

                    {(isWorkspace ||
                        (!isWorkspace &&
                            (!workspaceSettings ||
                                Object.keys(workspaceSettings).length === 0))) &&
                        currentSettings &&
                        Object.keys(currentSettings).length > 0 && (
                            <Button
                                type="danger"
                                onClick={async () => {
                                    this.props.saveWidgetState(
                                        this.props.workspaceId,
                                        this.props.widgetId,
                                        { ...this.props.state, settings: {} }
                                    );
                                }}
                            >
                                {this.props.t('Clear Filters')}
                            </Button>
                        )}
                    {/* <Button
                        type="cancel"
                        onClick={
                            toggleWidgetSettings
                            // TODO: reset filters ?
                        }
                    >
                        {t('Cancel')}
                    </Button> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ 
    workspaces: state.workspaces,
    board: state.board
});
export default connect(mapStateToProps)(withTranslation()(WidgetSettings));