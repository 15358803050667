import React from 'react';
import styles from './SearchSettings.module.css';
import _ from 'lodash';
import TabBar from '../../common/basicElements/TabBar/TabBar';
import SearchFacets from './SearchFacets';
import '../../../assets/animate.css';
import SearchOptions from './SearchOptions';
import History from '../../common/History/History';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

export class SearchSettings extends React.Component {
    static propTypes = {
        onHistoryDelete: PropTypes.func.isRequired, // description of prop goes here
        history: PropTypes.array.isRequired,
        settings: PropTypes.object.isRequired,
        facets: PropTypes.object.isRequired,
        onChannelSelection: PropTypes.func.isRequired,
        channelGroups: PropTypes.array,
        onSelectChange: PropTypes.func.isRequired,
        onDateRangeChange: PropTypes.func.isRequired,
        search: PropTypes.func.isRequired,
        query: PropTypes.string,
        onTimeAccordionChange: PropTypes.func.isRequired,
        onScopeAccordionChange: PropTypes.func.isRequired,
        channels: PropTypes.array.isRequired,
        selectedChannels: PropTypes.array.isRequired,
        onFacetClick: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);        
        this.state = {            
            activeTab: 0,
            historyTab: false,
            lastHeight: 0,
            maxItems: undefined
        };        

        this.onDateRangeChange = this.onDateRangeChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.onHistoryTabClick = this.onHistoryTabClick.bind(this);
        this.onHistoryDelete = this.onHistoryDelete.bind(this);
        this.onHistoryRowClick = this.onHistoryRowClick.bind(this);
        this.updateMaxItems = this.updateMaxItems.bind(this);
    }

    componentDidMount() {
        if(this.divRef) {
            this.setState({
                lastHeight: this.divRef.clientHeight
            })
            this.updateMaxItems()
        }
    }

    componentDidUpdate() {
        if(this.state.lastHeight && this.divRef && this.state.lastHeight !== this.divRef.clientHeight)   {
            this.updateMaxItems()
            this.setState({
                lastHeight: this.divRef.clientHeight
            })
        }
    }

    updateMaxItems() {
        const height = this.divRef.clientHeight
        this.setState({maxItems: Math.floor(height / 50)})      //Divides Height of the container by the height of each entry (50px)
    }

    onDateRangeChange(dateRange) {
        this.setState({dateRange:dateRange});
    }
    
    onSelectChange(option, key) {
        this.setState(state => {
            state.settings[key] = { value: option.value, label: option.value };

            return { settings: state.settings };
        });
    }

    onTabChange(tabId) {
        this.setState({ activeTab: tabId });
    }

    onHistoryTabClick() {        
        this.setState((s) => ({ historyTab: !s.historyTab }));  // toggle (show/hide) history view
    }
    
    async onHistoryDelete(index) {
        await this.props.onHistoryDelete(index);
        if (this.props.history.length === 0) {
            this.setState({ historyTab: false })
        };
    }

    onHistoryRowClick(clickedItemInded) {        
        this.props.onHistoryRowClick(clickedItemInded);
    }

    render() {
        const { t } = this.props;
        const activeTab = this.state.activeTab;     
        let settings = this.props.settings;
        let count = 0;
        _.forOwn(this.props.facets, (val) => { count += val.length; })
        let tabs = count > 0 ? [t('Settings'),t('Facets')] : [t('Settings')]
        
        return (
            <div className={styles.settings}>                                                
                {
                    !this.state.historyTab ?                     
                        <div style={{height:'90%'}}>
                            <TabBar tabs={tabs} active={this.state.activeTab} onClick={this.onTabChange} />
                            {activeTab === 0 ?
                                <SearchOptions
                                    settings={settings}
                                    onChannelSelection={this.props.onChannelSelection}
                                    channelGroups={this.props.channelGroups}
                                    onSelectChange={this.props.onSelectChange}
                                    onDateRangeChange={this.props.onDateRangeChange}
                                    search={this.props.search}
                                    query={this.props.query}
                                    onTimeAccordionChange={this.props.onTimeAccordionChange}
                                    onScopeAccordionChange={this.props.onScopeAccordionChange}
                                    channels={this.props.channels}
                                    selectedChannels={this.props.selectedChannels}
                                    scopeDisabled={this.props.scopeDisabled}
                                />
                                : <SearchFacets
                                    facets={this.props.facets}
                                    onFacetClick={this.props.onFacetClick}
                                    limit={4}
                                />}
                        </div>
                    : null
                }
                    
                {
                    this.props.history.length > 0 && !this.props.historyDisabled && this.props.channels.length > 0 ?
                        <div className={styles.history} ref={element => this.divRef = element}>
                            <TabBar allowActiveClick={true} tabs={[t('History')]} active={this.state.historyTab ? 0 : undefined} onClick={this.onHistoryTabClick} />
                            {/* {this.state.historyTab ? null : } */}
                            {
                                <History
                                    data={this.props.tour.show ? [] : this.props.history}      // todo later: fix width of SearchSettings getting bigger after rendering data here instead of hiding results for tour?
                                    onDelete={this.onHistoryDelete}
                                    onClick={this.onHistoryRowClick}
                                    showAllItems={this.state.historyTab}
                                    maxItems={this.state.maxItems}
                                />
                            }
                        </div>
                    : null
                }
            </div>
        )        
    }
}
const mapStateToProps = state => {
    return{
        tour: state.tour
    }
}
export default connect(mapStateToProps)(withTranslation()(SearchSettings));