import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Channel } from 'src/@types/shared.types';
import { getChannels } from 'src/redux/actions/actions.channels';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import useSelectedChannelGroup from './useSelectedChannelGroup';
import { findGroupChannelsInAllChannels } from '../utils/utils';

const useStructuredChannelsData = (selectedChannels: any, showOnlySelected: boolean, selectedChannelGroupChannels?:any) => {
    
    const [structuredChannelsData, setStructuredChannelsData] = useState<Channel[]>([])

    const {selectedGroup} = useSelectedChannelGroup();

    const channels = useAppSelector((state) => state.channelReducers.data);

    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const groupChannels = findGroupChannelsInAllChannels(selectedChannelGroupChannels.channels, channels);
    const selectedChannelsStructured = findGroupChannelsInAllChannels(selectedChannels, channels);

    useEffect(() => {
        dispatch(getChannels());
    }, []);

    const channelsSelectionToUse = () => {
        if (selectedChannelGroupChannels?.channels?.length > 0) {
            return showOnlySelected && selectedChannelGroupChannels?.channels?.length > 0 && !selectedGroup ? selectedChannelsStructured : groupChannels;
        } else if (showOnlySelected && selectedChannels.length > 0) {
            return selectedChannelsStructured
        } else if (selectedChannelGroupChannels?.channels?.length === 0) {
            return []
        }else{
            return channels
        }
    }

    useEffect(() => {
        setStructuredChannelsData((channelsSelectionToUse()).map((channel: Channel) => ({
            [t('Name')]: channel.name,
            [t('Type')]: channel.type,
            [t('Country')]: channel.country,
            [t('Language')]: channel.language,
            [t('Owner')]: channel.owner,
            [t('Scope')]: channel.scope,
            id: channel.id
        })))
    }, [channels, selectedChannelGroupChannels, selectedChannels, showOnlySelected]);

    return {structuredChannelsData};
};

export default useStructuredChannelsData;
