import Deepl from './Deepl/Deepl';
import GoogleTranslate from './Google/GoogleTranslate';

class APITranslations {
    static supportedLanguages = Deepl.languages.map(l => l.toLocaleLowerCase()).concat(GoogleTranslate.languages);
    
    static async translate(phrase, source, target) {
        let res = await Deepl.translate(phrase, source, target);
        if (res === -1) {
            res = await GoogleTranslate.translate(phrase, source, target);
        }

        return res;
    }
}

export default APITranslations;