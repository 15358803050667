import { cacheBust } from "../common/helpers";
import CoursesOverview from "./CoursesOverview/CoursesOverview";

function CoursesOverviewContainer({ }) {
    const courses = [
        {
            img: cacheBust(`${process.env.PUBLIC_URL}/academy_api/en/courses/introduction/api-illustrations_Start.svg`),
            imgAlt: 'introduction',
            heading: 'Intro to our API',
            description: 'In this course you will learn all oportunities our system offers.',
            workspace: 1,
        },
        {
            img: cacheBust(`${process.env.PUBLIC_URL}/academy_api/en/courses/pushFeed/api-illustrations_PushFeed.svg`),
            imgAlt: 'pushFeed',
            heading: 'PushFeed',
            description: 'In this course you will learn all oportunities our system offers.',
            workspace: 2,
        },
        {
            img: cacheBust(`${process.env.PUBLIC_URL}/academy_api/en/courses/eFeed/api-illustrations_eFeed.svg`),
            imgAlt: 'eFeed',
            heading: 'eFeed',
            description: 'In this course you will learn all oportunities our system offers.',
            workspace: 3,
        },
        {
            img: cacheBust(`${process.env.PUBLIC_URL}/academy_api/en/courses/play-and-download-clip/api-illustrations_PlayDownload.svg`),
            imgAlt: 'Clips',
            heading: 'Play & Download',
            description: 'In this course you will learn all oportunities our system offers.',
            workspace: 4,
        },
        {
            img: cacheBust(`${process.env.PUBLIC_URL}/academy_api/en/courses/channels/api-illustrations_channels.svg`),
            imgAlt: 'channels',
            heading: 'Channels',
            description: 'In this course you will learn all oportunities our system offers.',
            workspace: 5,
        }
    ]

    return (
        <CoursesOverview courses={courses} />
    )
}

export default CoursesOverviewContainer;