import APIStripe from 'src/API/APIStripe';
import { GET_PRODUCTS, GET_SUBSCRIPTIONS, SET_CUSTOMER_ID } from '../types/actionTypes.stripe';

export const setStripeCustomerId = (stripeCustomerId) => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_CUSTOMER_ID,
            data: stripeCustomerId,
        });
    }
}

export const getSubscriptions = (stripeCustomerId) => {
    return async (dispatch, getState) => {
        const subscriptions = await APIStripe.getSubscriptions(stripeCustomerId);

        dispatch({
            type: GET_SUBSCRIPTIONS,
            data: subscriptions?.data,
        });
    }
}

export const getProducts = () => {
    return async (dispatch, getState) => {
        const products = await APIStripe.getProducts();

        dispatch({
            type: GET_PRODUCTS,
            data: products?.data,
        });
    }
}