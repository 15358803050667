import React, { Component } from 'react';
import _ from 'lodash';
import './GroupCard.css';
import GroupCardHeader from './GroupCardHeader/GroupCardHeader';
import classNames from 'classnames';

// FontAwesome Base
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faPen, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import Button from '../../basicElements/Button/Button';
import { GroupListItem } from './GroupListItem/GroupListItem';
import GenericTableView from '../../GenericTable/GenericTableView';
import { dateToString } from '../../helpers';

// fontawesome icons
library.add(faPlus, faPen, faChevronRight, faChevronLeft);

/**
 * - id
 * - title
 * - items (see GroupCards) (required)
 * - changeGroupTitle (see GroupCards)
 * - deleteGroup (see GroupCards)
 * - addItem (see GroupCards)
 *
 * - useGravatar (see GroupCardsContainer)
 * - uneditable
 * - editItemIcon (boolean)
 * - maxItems (number, optional)
 */
export class GroupList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxItems: props.maxItems ? props.maxItems : 10,
      page: 0,
      items:[],
      secondaryItems:[],
    };

    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
  }

  renderHeader() {
    const items = this.props.getItem ? this.state.items : this.props.items;

    return (
      <div
        style={{
          display: 'flex',
          margin: '1rem .3rem 0 .3rem'
        }}
      >
        {items[0] && items[0].extended &&
          Object.keys(items[0].extended).map((h, idx) => (
            <div
              key={idx}
              style={{
                width: '10rem',
                fontWeight: 'bold',
                flex: '0 0 10rem'
              }}
            >
              {h}
            </div>
          ))}
      </div>
    );
  }

  // bool secondary
  renderItems(secondary) {
    const { page, maxItems } = this.state;
    let toMap;
    if( this.props.getItem ){
      // NOTE: should handle secondary items - if required in future ?
      toMap = secondary ? this.state.secondaryItems : this.state.items;
    }
    else { 
      toMap = secondary ? this.props.secondaryItems : this.props.items;
    }

    const from = page * maxItems;
    const to = (page + 1) * maxItems;
    if (toMap) toMap = toMap.slice(from, to);

    return _.map(toMap, item => (
      <GroupListItem
        key={(secondary ? 'sec-' : '') + item.id}
        onClick={item.onClick}
        // title={item.title}
        // secondary={secondary}
        // disabled={item.disabled}
        // highlighted={item.highlighted}
        data={item.extended ? Object.values(item.extended) : null}
      />
    ));
  }

  async getPage(){
    const {maxItems, page} = this.state;

    const start = page * maxItems;
    const end = Math.min(
        (page * maxItems + maxItems),
        this.props.items.length
    );
    
    if (this.isPageCached(start, end)) {
      const currentPage = this.state.items.slice(start, end);
      this.setState({ currentPage }) 
    } else {
        const currentPage = await this.fetchPage(start, end);
        this.setState(s => ({
            currentPage,
            items: s.items.concat(currentPage)
        }));
    }
  }

  isPageCached(start, end) {
    for (let i = start; i < end; ++i) {
        if (!this.state.items[i])
            return false;
    }

    return true;
}

async fetchPage(start, end) {
    const items = [];
    for (let i = start; i < end; ++i) {
        const g = await this.props.getItem(this.props.items[i].id, this.props.items[i].onClick);
        items.push(g);
    }

    return items;
}


  nextPage() {
    this.setState({ page: this.state.page + 1 }, (this.props.getItem ? this.getPage : null));
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 }, (this.props.getItem ? this.getPage : null));
  }

  componentDidMount(){
    if(this.props.getItem)
      this.getPage();
  }

  render() {
    const items = this.props.items;
    const secItems = this.props.secondaryItems;
    let numberOfItems = 0;
    numberOfItems += items ? items.length : 0;
    numberOfItems += secItems ? secItems.length : 0;
    const currentPage = this.state.page + 1;

    // translation method with fallback
    // const t = this.props.t || (k => k);

    // const renderItems = _.concat(this.renderItems(true), this.renderItems());

    return (
        <article ref={this.props.itemRef} className={'groupcard ' + this.props.className} style={this.props.isUserManager ?{
          overflow: 'hidden',
        } : null}>
            <GroupCardHeader
                id={this.props.id}
                title={this.props.title}
                deleteGroup={this.props.deleteGroup}
                changeGroupTitle={this.props.changeGroupTitle}
                showGroupView={this.props.showGroupView}
                uneditable={this.props.uneditable}
                pagination={{
                    currentPage: currentPage,
                    // for disabling the pagination buttons
                    // numberOfItems: numberOfItems,
                    numberOfItems: 0,
                    itemsPerPage: this.state.maxItems,
                    onNextClick: this.nextPage,
                    onPrevClick: this.previousPage
                }}
            />
            <section className="groupcard-content-list">
                <div className={this.props.isUserManager ? "add-button-wrapper-usermanager" :"add-button-wrapper"}>
                    <Button
                        type="secondary"
                        btnClass={classNames('btnRoundSmall', 'item-add-button')
                        }
                        onClick={() => this.props.addItem(this.props.id)}
                    >
                        {this.props.editItemIcon ? (
                            <FontAwesomeIcon icon="pen" />
                        ) : (
                            <FontAwesomeIcon icon="plus" />
                        )}

                        {/* {' Add User'} */}
                    </Button>
                </div>

                {/* <div 
                      style={{
                        overflow: 'auto',
                        // maxWidth: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    > */}
                {/* {(this.props.items.length > 0 || this.state.items.length > 0) && this.renderHeader()}
          {renderItems}
          </div> */}
                <GenericTableView
                    tableData={
                      this.props.listColumns ? items.map((i) => {
                        let row = {};
                        this.props.listColumns.forEach((column) => {
                            row[column.label] = column.formatter
                                ? column.formatter(i[column.key])
                                : i[column.key];
                        });
                        return row;
                    }) : items
                    }
                    areFiltersActive
                    handleRowClick={(item) => item.onClick()}
                    disabledColumns={
                      this.props.listColumns ?
                      this.props.listColumns
                        .filter((column) => column.disable)
                        .map((column) => column.label) : []
                    }
                    noResultsMessage={this.props.t('No match')}
                    customRenderers={this.props.customTableRenderers}
                    disabledColumnFilters={this.props.disabledColumnFilters}
                />
            </section>
        </article>
    );
  }
}

export default withTranslation()(GroupList);