import React
  // ,{ useEffect, useState }
from "react";
import _ from 'lodash';
// import { csv } from "d3-fetch";
// import { scaleLinear } from "d3-scale";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";
import GenericCalls from "src/API/genericCalls";
import APITelemetry from "src/API/APITelemetry";
import { cacheBust } from "../helpers";
// import randomColor from 'randomcolor';

// const colorScale = scaleLinear()
//   .domain([0.29, 0.68])
//   .range(["#ffedea", "#ff5233"]);

class MapChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          scrollEnabled: false,
        };
      
      // this.geoUrl = cacheBust(`${process.env.PUBLIC_URL}/world-110m.json`); // fetched from https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json
      this.geoUrl = cacheBust(`${process.env.PUBLIC_URL}/ne_10m_admin_0_countries-1-percent.json`); // fetched from https://github.com/micahstubbs/tiny-countries-geo/blob/master/out/ne_10m_admin_0_countries-1-percent.topojson
    }
  
    async verifyData() {  // check if provided data contains values that can not be shown on map
      const { data } = this.props;
      const geo = await GenericCalls.get(this.geoUrl, undefined, { authorization: '' });
      const geometries = geo.objects.ne_10m_admin_0_countries.geometries;
      data.map(d => {
        const foundItem = _.find(geometries, (g) => {
          return g.properties.ISO_A2 === d.name;
        } );
        if (!foundItem) {
          APITelemetry.post({
            msg: `geo json, missing value '${d.name}'. Tried to visualize value '${d.name}' (probably received from backend) 
            on a map, but the value is not included in the used geo json file '${this.geoUrl}'.`
          });
        }
        return undefined;
      });
    }
  
  async componentDidMount() {
    this.verifyData();
  }
  render() {
    const { setTooltipContent, markers, markerType, data, hoverFillColor, allowZoom } = this.props;

    return (
      <div
        ref={(r) => { this.ref = r; }}
        onWheelCapture={(e) => {
          if (!allowZoom) {
            e.stopPropagation();
          }
        }}
      >
          <ComposableMap
              data-tip=''
              projectionConfig={{
                rotate: [-10, 0, 0],
                scale: 147
              }}
            >
              <ZoomableGroup>
                  <Geographies geography={this.geoUrl}>
                    {({ geographies }) =>
                    geographies.map((geo) => {
                      const { ISO_A2 } = geo.properties;
                      const highlight = _.find(data,{name:ISO_A2});
                        // const d = data.find((s) => s.ISO3 === geo.properties.ISO_A3);
                      return (
                          <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            // onClick={() => {
                            //   const { NAME } = geo.properties;
                            //   console.log(NAME)
                            // }}
                            onMouseEnter={() => {
                              const { NAME } = geo.properties;
                              const value = highlight ? highlight.value : '';
                                // console.log(NAME)
                              setTooltipContent(`${NAME}: ${value}`);
                            }}
                            onMouseLeave={() => {
                              setTooltipContent("");
                            }}
                                fill={
                                    highlight ? highlight.fill : this.props.fill
                                    // randomColor({
                                    //     luminosity: 'bright',
                                    //     seed: geo.properties.NAME,
                                    //     format: 'rgba',
                                    //     // alpha: 0.3
                                    // })
                                    // colorScale(Math.floor(Math.random() * 1000))
                                }
                            style={{
                              default: {
                                outline: "none"
                              },
                              hover: {
                                  outline: "none",
                                  fill: hoverFillColor
                              },
                              pressed: {
                                outline: "none"
                              }
                            }}
                          />
                        );
                      })
                    }
                  </Geographies>
              {markers && markers.map((m) => {
                  return (
                    <Marker key={m.name} coordinates={m.coordinates}>
                      {markerType}
                    </Marker>
                  )
                    })}
              </ZoomableGroup>
        </ComposableMap>
        </div>
          );
    }
}

export default MapChart;
