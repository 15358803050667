import React, { Component } from 'react';
import styles from './GroupListItem.module.css';

/**
 * - title
 * - onClick
 */
export class GroupListItem extends Component {
  render() {
    // let itemStyles = [];
    // itemStyles.push(styles['groupcard-item']);
    // if (this.props.secondary) itemStyles.push(styles['groupcard-item-secondary']);
    // if (this.props.disabled) itemStyles.push(styles['groupcard-item-disabled']);
    // if (this.props.highlighted) itemStyles.push(styles['groupcard-item-highlighted']);
    // if (this.props.onClick !== undefined) itemStyles.push(styles['groupcard-item-clickable']);

    if (!this.props.data) return null;

    let itemClasses = [styles.item];
    if(this.props.onClick) itemClasses.push(styles.clickable);

    return (
      <div
          className={itemClasses.join(' ')}
          onClick={this.props.onClick}
      >
        { this.props.data.map((d, idx) => (
          <div
            key={idx}
            className={styles.entry}
          >
            {d}
          </div>
        ))}
      </div>
    );
  }
}

export default GroupListItem;
