import React from 'react';
import { connect } from 'react-redux';
import { clearError } from '../../../../redux/actions/actions.error';
import errorStyles from './ErrorBanner.module.scss';
import Popup from '../Popup/Popup';
import { withTranslation } from 'react-i18next';
import errorImg from '../../../../assets/mf-error.png';

const ErrorBannerMF = ({ theme, error, clearError, me, t }) =>{

  return error && <>
    <Popup
      wrapperStyle={{zIndex: 1000000}}
      size='auto'
      onCancel={clearError}
      cancelTxt={t('Close')}
    >
      <div className={errorStyles.wrapper}>
        <h2>{t('unexpected_error_occured')}</h2>
        <div className={errorStyles.explanation}>
          <div className={errorStyles.explanationText}>
            <div style={{marginBlockEnd:'1rem'}}>{`${t('something_wrong')} `}</div>
            <div>
              {t('we_are_informed_text')}
            </div>
          </div>
          <div className={errorStyles.ourFault}>
            { <img src={errorImg} /> }
            
          </div>
        </div>
        
      </div>
    </Popup>
  </>
}

const mapStateToProps = state => ({ error: state.error, theme: state.theme, me: state.me });
export default connect(mapStateToProps, { clearError })(withTranslation()(ErrorBannerMF));