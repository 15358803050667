import { MouseEvent, ChangeEvent, useState, useEffect } from 'react';
import { MatrixData } from './useGenericTableDataConverter';
import { GenericTableData } from 'src/Widgets/common/GenericTable/GenericTable.types';

type Matrix = MatrixData[][];
type HandleCheckboxClick = (event: ChangeEvent<HTMLInputElement>, columnHeader: string, rowIndex: number) => void;
type HandleColumnClick = (event: MouseEvent, columnHeader: string) => void;

interface ButtonHandlers {
  handleOkButtonClick: (event?: MouseEvent) => void;
  handleCancelButtonClick: () => void;
}

const useGenericTableFilterButtons = (
  data: GenericTableData,
  checkedState : Record<string, any>,
  matrix: Matrix,
  handleCheckboxClick: HandleCheckboxClick,
  openColumnHeader: string,
  handleColumnClick: HandleColumnClick
): ButtonHandlers => {

// const [initialCheckedState, setInitialCheckedState] = useState<{ [key: string]: boolean[] }>(checkedState);

//   // Update the initialCheckedState whenever the data or openColumnHeader changes
//   useEffect(() => {
//     setInitialCheckedState(checkedState);
//   }, [data, openColumnHeader]);

  //Ok button handler
  const handleOkButtonClick = (e?: MouseEvent) => {
    if (e) {
      handleColumnClick(e, openColumnHeader)
    }
  }

  //Cancel button handler
  const handleCancelButtonClick = () => {
    matrix.forEach((_rowData, rowIndex) => {
      handleCheckboxClick(
        {
          target: { checked: false }
        } as ChangeEvent<HTMLInputElement>,
        openColumnHeader,
        rowIndex
      );
    });
  };

  return { handleOkButtonClick, handleCancelButtonClick };
};

export default useGenericTableFilterButtons;