import React from 'react';
import APIUsers from '../../../../API/APIUsers';
import _ from 'lodash';
import LoadingSpinner from '../../basicElements/LoadingSpinner/LoadingSpinner';
import styles from './ShareGroup.module.scss';
import SearchBar from '../../basicElements/SearchBar/SearchBar';
import APIShareGroups from '../../../../API/APIShareGroups';
import FormFooter from '../../basicElements/FormFooter/FormFooter';
import ItemSelection from '../../basicElements/ItemSelection/ItemSelection';
import { withTranslation } from 'react-i18next';
import APIMe from '../../../../API/APIMe';
import PropTypes from 'prop-types';
import { getSortFunct } from '../../helpers';
import { connect } from 'react-redux';

class ShareGroup extends React.Component {
    static propTypes = {
        getUsers: PropTypes.func
    }
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            userIds: [],
            users: [],
            nonSearchUsers: [],
            selected: [],
            edited: false,
            updatedUsers: [],

            saveSuccessful: true,
            saveFailed: false
        };

        this.save = this.save.bind(this);
        this.search = this.search.bind(this);
        this.onChangeSelected = this.onChangeSelected.bind(this);
        this.loadNextUsers = this.loadNextUsers.bind(this);
    }

    async save() {
        let add = [];
        let remove = [];
        this.state.updatedUsers.map((u) => u.inGroup ? add.push(u.id) : remove.push(u.id));
        if (add.length > 0) {
            await APIShareGroups.addUsers(this.props.groupId, add.join(','));
        }
        if (remove.length > 0) {
            await APIShareGroups.removeUsers(this.props.groupId, remove.join(','));
        }

        this.setState({ edited: false, saveSuccessful: true, updatedUsers:[] });
    }

    async componentDidMount() {
        let userIds, users = [];
        if(this.props.getUsers){
            users = (await this.props.getUsers()).data.users;
        }
        else if(this.props.me?.isUserManagerForCompany){
            users = (await APIMe.companyUsers()).data;
        }
        users = users.map(u => ({ id: u.id, text: u.username })).sort(getSortFunct('text'));

        const shareGroup = (await APIShareGroups.get(this.props.groupId)).data;
        const selected = _.map(shareGroup, g => g.id);

        this.allUserIds = userIds ? userIds : users;
        this.nonSearchUsers = [];

        this.setState({
            users,
            userIds,
            selected,
            isLoading: false
        });
    }

    // loads details of users (currently called only if props.getUserIds is passed - this call provides userids from which we can fetch details)
    async loadNextUsers() {
        const start = this.state.users.length;
        const range = Math.min(this.state.userIds.length - start, 5);

        const newUsers = [];

        for (let i = start; i < start + range; ++i) {
            const user = (await APIUsers.get(this.state.userIds[i])).data;

            newUsers.push({
                id: this.state.userIds[i],
                text: user.username
            });
        }

        this.setState(s => ({
            users: s.users.concat(newUsers),
            nonSearchUsers: s.nonSearchUsers.concat(newUsers)
        }));
    }

    async search(searchTerm) {
        // if (searchTerm.length >= 3) {
            this.debouncedSearch(searchTerm);
        // } else {
        //     this.setState(s => ({
        //         searchTerm: '',
        //         userIds: this.allUserIds,
        //         users: s.nonSearchUsers
        //     }));
        // }
    }

    async sendSearch(searchTerm) {
        const users = this.allUserIds.filter(u => { return u.text.includes(searchTerm); });
        // console.log(userIds)
        // if(Array.isArray(userIds)){
            this.setState(s => ({
                searchTerm: searchTerm,
                // userIds,
                nonSearchUsers: s.users,
                users: users,
            }));
        // }
    }

    debouncedSearch = _.debounce(term => this.sendSearch(term), 250, {
        maxWait: 500
    });

    onChangeSelected(selected, item) {
        item.inGroup = item.isSelected;
        delete item.isSelected;
        this.setState(state => {
            let existing = _.find(state.updatedUsers, { id: item.id });
            existing ? existing.inGroup = item.inGroup : state.updatedUsers.push(item);
            return {
                selected,
                edited: true,
                updatedUsers: state.updatedUsers
            };
        });
    }

    render() {
        if (this.state.isLoading) {
            return <LoadingSpinner />;
        }

        // translation method with fallback
        const t = this.props.t || (k => k);
        return (
            <div className={styles.wrapper}>
                <article className={styles.content}>
                    <SearchBar search={this.search} />

                    <br />

                    <ItemSelection
                        items={this.state.users}
                        selected={this.state.selected}
                        onSelectionChange={this.onChangeSelected}
                        useGravatar
                        loadMore={this.loadNextUsers}
                        // loadMore={() => null}
                        // hasMore={this.props.getUserIds && this.state.users.length < this.state.userIds.length ? true : false }
                        hasMore={false}
                        className={styles.usersList}
                    />

                    <FormFooter
                        onGoBack={this.props.onGoBack}
                        onAction={this.save}
                        actionDisabled={!this.state.edited}
                        actionLabel={t("Save Changes")}
                        success={
                            this.state.saveSuccessful
                                ? t('Successfully saved')
                                : null
                        }
                        fail={this.state.saveFailed ? t('Could not save') : null}
                    />
                </article>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { 
        me: state.me,
    }
}

export default withTranslation()(connect(mapStateToProps, { })(ShareGroup));
