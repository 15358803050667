import { useEffect, useState } from "react";
import Waterfall from "./Waterfall";
import APIChannels from "src/API/APIChannels";

function WaterfallContainer() {
    const [channels, setChannels] = useState(undefined);

    useEffect(() => {
        (async() => {
            // const channels = await APIChannels.get();
            const apiChannels = await APIChannels.get_legacy();
            setChannels(apiChannels);
        })();
    },[]);

    return (
        <Waterfall
            channels={channels}
        />
    )
}

export default WaterfallContainer;