import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import IDateRangePicker from '@wojtekmaj/react-daterange-picker';
import { connect } from 'react-redux';
import LastX from './LastX';

// props:
//  - lastXOnly(default=false): shows two dropdowns for lastx (e.g. '2 days') only but hides the calendar daterangepicker

class DateRangePicker extends Component {
    
    render(){
        const { t } = this.props;
        const lastX = this.props.lastX !== undefined ? this.props.lastX : undefined;
        const dateRange = this.props.dateRange !== undefined ? this.props.dateRange : true;
        const lastXValue = !Array.isArray(this.props.value) ? this.props.value : undefined;
        const dateRangeValue = Array.isArray(this.props.value) ? this.props.value : undefined;
        return (
            <div>
                {
                    lastX && <>
                        <LastX
                            {...this.props}
                            style={this.props.lastXStyle}
                            value={lastXValue}
                            useFormStyle={this.props.useFormStyle}
                        />
                        {!this.props.lastXOnly && <span>or by daterange: </span>}
                    </>
                }
                {!this.props.lastXOnly && dateRange && <span title={t('Select date range')}>
                    <IDateRangePicker
                        {...this.props}
                        value={dateRangeValue}
                    />
                </span>}
            </div>
        );
    }
}

class emmDateRangePicker extends Component{ // separating use of redux store from DateRangePicker() (in case it should be reused somewhere else)
    render(){
        return (
            <DateRangePicker
                clearIcon={this.props.allowClear === false ? null : undefined}
                maxDate={this.props.archive !== false ? new Date(Date.now()) : undefined}
                minDate={this.props.archive !== false && this.props.me.archive ? this.props.me.archive.start : undefined }
                {...this.props}
            />
        )
    }
}

const mapStateToProps = state => ({ 
    me: state.me,
});
export default connect(mapStateToProps)(withTranslation()(emmDateRangePicker));