const {
    SELECTED_GROUP,
    EDIT_VIEW_TOGGLE,
    POPUP_MESSAGE,
    SAVE_STATUS,
    SHOW_ALL_CHANNELS,
    UNSELECTED_GROUP,
    DUPLICATE_NAME_SET,
    CREATE_STATUS,
    SELECTED_CHANNELS,
    UNSELECTED_CHANNELS,
    HIDE_ALL_CHANNELS,
    FILTERED_CHANNELS,
    SORTED_CHANNELS,
    SELECTED_FILTER,
    SELECTED_SORT_TYPE,
    SEARCH_INPUT,
    SEARCHED_CHANNELS,
    TOGGLE_FILTERBOX,
    SET_CHECKBOX_VALUES,
    CHECKBOX_FILTER_VALUES,
    FILTERBOX_INDEX,
    DELETE_STATUS,
    SET_FILTER_COLOR_VALUES,
    SELECTED_FILTERBOXES,
    HEADERS_LENGTH,
    TOGGLE_SELECTED_BUTTON_STATUS,
    SELECTED_CHANNEL_IDS,
    SET_DESELECT_CHANNELS,
} = require('../types/actionTypes.channelGroupsWidget');

const initialState = {
    editGroupOpen: false,
    selectedGroupId: null,
    selectedGroupTitle: '',
    selectedGroupChannels: null,
    selectedGroupGroups: null,
    setPopupMessage: '',
    setDuplicateName: '',
    saveStatusLog: {
        isSuccess: false,
        isError: false
    },
    createStatusLog: {
        isSuccess: false,
        isError: false
    },
    deleteStatusLog: {
        isSuccess: false,
        isError: false
    },
    allChannelsShown: false,
    isFilterBoxShown: false,
    selectedChannels: [],
    filteredChannels: [],
    sortedChannels: [],
    searchedChannels: [],
    selectedFilter: '',
    selectedSortType: '',
    searchInput: '',
    filterValuesArray: [],
    checkboxFilters: [],
    filterBoxIndex: null,
    filterColorValues: [],
    selectedFilterBoxes: [],
    headersLength: null,
    selectedButtonStatus: false,
    selectedChannelIds: [],
    channelsDeselected: false
};

const channelGroupsWidgetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECTED_GROUP:
            return {
                selectedGroupId: action.payload.id,
                selectedGroupTitle: action.payload.title,
                selectedGroupGroups: action.payload.groups,
                selectedGroupChannels: action.payload.channels
            };
        case DUPLICATE_NAME_SET:
            return {
                ...state,
                setDuplicateName: action.payload.name
            };
        case SELECTED_CHANNEL_IDS:
            return {
                ...state,
                selectedChannelIds: action.payload.ids
            }
        case SET_DESELECT_CHANNELS:
            return {
                ...state,
                channelsDeselected: action.payload.isDeselected
                
            }        
        case SET_FILTER_COLOR_VALUES:
            return {
                ...state,
                filterColorValues: action.payload.values
            }
        case HEADERS_LENGTH:
            return{
                ...state,
                headersLength: action.payload.count
            }    
        case UNSELECTED_GROUP:
            return {
                selectedGroupId: initialState.selectedGroupId,
                selectedGroupTitle: initialState.selectedGroupTitle,
                selectedGroupGroups: initialState.selectedGroupGroups,
                selectedGroupChannels: initialState.selectedGroupChannels
            }
        case EDIT_VIEW_TOGGLE:
            return {
                ...state,
                editGroupOpen: !state.editGroupOpen
            };
        case POPUP_MESSAGE:
            return {
                ...state,
                setPopupMessage: action.payload.message
            };
        case SET_CHECKBOX_VALUES:
            return{
                ...state,
                filterValuesArray: action.payload.values
            }
        case SAVE_STATUS:
            return {
                ...state,
                saveStatusLog: {
                    isSuccess: action.payload.res === 0 ? true : false,
                    isError: action.payload.res !== 0 ? true : false
                }
            };
        case CREATE_STATUS:
            return {
                ...state,
                createStatusLog: {
                    isSuccess: action.payload.res === 0 ? true : false,
                    isError: action.payload.res !== 0 ? true : false
                }
            };
            case DELETE_STATUS:
            return {
                ...state,
                deleteStatusLog: {
                    isSuccess: action.payload.res === 0 ? true : false,
                    isError: action.payload.res !== 0 ? true : false
                }
            };
        case SHOW_ALL_CHANNELS:
            return {
                ...state,
                allChannelsShown: action.payload.status
            };
        case TOGGLE_FILTERBOX:
            return {
                ...state,
                isFilterBoxShown: action.payload.booleanValue
            }
        case TOGGLE_SELECTED_BUTTON_STATUS:
            return {
                ...state,
                selectedButtonStatus: action.payload
            };    
        case CHECKBOX_FILTER_VALUES:
            return {
                ...state,
                checkboxFilters: action.payload.filters
            }        
        case SELECTED_CHANNELS:
            return{
                ...state,
                selectedChannels: action.payload.selectedChannels
            }
        case UNSELECTED_CHANNELS:
            return{
                ...state,
                selectedChannels: initialState.selectedChannels
            }
        case FILTERED_CHANNELS:
            return{
                ...state,
                filteredChannels: action.payload.filteredChannels
            }
        case SORTED_CHANNELS:
            return{
                ...state,
                sortedChannels: action.payload.filteredChannels 
            }
        case SEARCHED_CHANNELS:
            return{
                ...state,
                searchedChannels: action.payload.searchedChannels
            }    
        case SELECTED_FILTER:
            return{
                ...state,
                selectedFilter: action.payload.selectedFilter
            } 
        case SELECTED_SORT_TYPE:
            const {filterBoxIndex, sortType} = action.payload
            return{
                ...state,
                selectedSortType: {
                    [filterBoxIndex]: sortType
                }
            }
        case SEARCH_INPUT:
            return{
                ...state,
                searchInput: action.payload.searchInput
            }
        case FILTERBOX_INDEX:
            return{
                ...state,
                filterBoxIndex: action.payload.index
            }              
        default:
            return state;
    }
};

export default channelGroupsWidgetReducer;
