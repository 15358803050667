import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { changeRoute } from "src/redux/actions/actions.route";

function RouteChangeHandler(){
    const history = useHistory();
    const dispatch = useDispatch();
    const route = useSelector((state) => state.route);

    useEffect(()=>{ 
        if(route){
            history.push(route);
            dispatch(changeRoute(null));
        }
    },[route, dispatch, history])

    return null;
}

export default RouteChangeHandler;
