import { PlanTypes } from "./plans.types";

// TODO: use productId to fetch price, priceId from BE ?

const description = 'Per country package, per month';

const plans:PlanTypes.Plans = [
        {
            priceCent: 99 * 100,
            title: 'Scope S',
            features: [
                '5 Profiles',
                '10 Downloads/Exports',
                '25 Clips'
            ],
            productId: 'prod_QJlm7aVu2Z24c5',
            priceId: 'price_1PT8EqBxAbLJeG1JqqS513vu',
            description
        },
        {
            priceCent: 169 * 100,
            title: 'Scope M',
            features: [
                '10 Profiles',
                '20 Downloads/Exports',
                '50 Clips'
            ],
            description,
            productId: "prod_QEVmVRKrNotJra",
            priceId: "price_1PO2lEBxAbLJeG1JpYRsULvi"
        },
        {
            priceCent: 319 * 100,
            title: 'Scope L',
            features: [
                '20 Profiles',
                '40 Downloads/Exports',
                '100 Clips'
            ],
            description,
            productId: "prod_QJlk2E3P59furt",
            priceId: "price_1PT8ChBxAbLJeG1JHuWiEGTu"
        },
        {
            priceCent: 899 * 100,
            title: 'Scope XL',
            features: [
                'Unlimited Profiles',
                '100 Downloads/Exports',
                '200 Clips'
            ],
            description,
            productId: "prod_QJlkWCahcBLIEa",
            priceId: "price_1PT8D7BxAbLJeG1JeoPAR2Wk"
        }
]
    
export default plans;