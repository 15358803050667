import React from 'react';
import styles from './InfoBox.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const types = 
{ danger: 'danger'
, info: 'info'
}

const InfoBox = ({ text, type, theme, link, t }) => {
  const icon =
    type === types.danger
    ? faExclamationTriangle
    : faInfo;
  const background = {
    info: '#D9EDF7'
  };

  return (
    <div
      className={classNames(
        styles.wrap,
        link && styles.wrapLink,
        { [theme.borderDanger]: type === types.danger },
        { [theme.textDanger]: type === types.danger }
      )}
      style={{backgroundColor: background[type]}}
    >
      <FontAwesomeIcon
        icon={icon}
        className={styles.icon}
      />
      
      <span>
        {text}
      </span>
      {link && <a href={link} style={{paddingInlineEnd:'0.3rem'}} target='_blank'>{t('details')}</a>}
    </div>
  )
}

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(withTranslation()(InfoBox));