import { BorderBoxTypes } from "./BorderBox.types";
import styles from './BorderBox.module.scss';

function BorderBox({ style, children, className }:BorderBoxTypes.Props) {
    return (
        <div className={[styles.wrapper, className].join(' ')} style={style}>
            {children}
        </div>
    )
}

export default BorderBox;