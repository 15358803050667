import {
    ADD_PROFILE,
    ADD_PROFILE_GROUP,
    DELETE_PROFILE,
    DELETE_PROFILE_GROUP,
    PROFILE_GROUPS_LOADING,
    RECEIVE_PROFILE_GROUPS,
    UPDATE_PROFILE,
    UPDATE_PROFILE_GROUP
} from '../types/actionTypes.profileGroups';
import _ from 'lodash';

const profileGroupsReducers = (state = { data: [], status: undefined }, action) => {
    let newState, pgIndex, profileGroup ,profileIndex;
    switch (action.type) {
        case PROFILE_GROUPS_LOADING:
            return {
                ...state,
                isLoading: action.data,
            };
        case RECEIVE_PROFILE_GROUPS:
            return {
                ...state,
                data: action.data,
                status: 'fetched',
                isLoading: false,
            };
        case ADD_PROFILE:
            newState = _.cloneDeep(state);
            pgIndex = newState.data.findIndex((e)=>action.data.profileGroupId===e.id)
            profileGroup = newState.data[pgIndex];
            newState.data[pgIndex].profiles.push(action.data.profile);
            return newState;
        case UPDATE_PROFILE:
            newState = _.cloneDeep(state);
            pgIndex = newState.data.findIndex((e)=>action.data.profileGroupId===e.id)
            profileGroup = newState.data[pgIndex];
            profileIndex = profileGroup.profiles.findIndex((e)=>action.data.profile.id===e.id);
            newState.data[pgIndex].profiles[profileIndex] = action.data.profile;
            return newState;
        case DELETE_PROFILE:
            newState = _.cloneDeep(state);
            pgIndex = newState.data.findIndex((e)=>action.data.profileGroupId===e.id)
            profileGroup = newState.data[pgIndex];
            profileIndex = profileGroup.profiles.findIndex((e) => action.data.profileId === e.id);
            newState.data[pgIndex].profiles.splice(profileIndex, 1);
            return newState;
        case ADD_PROFILE_GROUP:
            newState = _.cloneDeep(state);
            newState.data.push(action.data);
            return newState;
        case UPDATE_PROFILE_GROUP:
            let groupIndex = _.findIndex(state.data, { id: action.data.id });
            newState = _.cloneDeep(state);
            newState.data[groupIndex].title = action.data.title || newState.data[groupIndex].title;
            return {...newState};
        case DELETE_PROFILE_GROUP:
            newState = _.cloneDeep(state);
            const index = _.findIndex(newState.data, { id: action.data.id });
            newState.data.splice(index, 1);
            return newState;
        default:
            return state;
    }
};

export default profileGroupsReducers;