function ContactUs({ }) {
    // TODO: soon to be deprecated ? - should use /api/me support email address instead? but not possible e.g. for 'user expired' because call is authenticated ?
    return (
        <div>
            <h1>
                Contact Us
            </h1>

            <p>
                <b>General Inquiries:</b>
            </p>
            <p>
                email: <a href="mailto:sales@eMediaMonitor.net">sales@eMediaMonitor.net</a>  <br/>
                phone: +43 1 581 26 30 100
            </p>

            <p>
                <b>Technical Support:</b>
            </p>
            <p>
                email: <a href="mailto:support@eMediaMonitor.net">support@eMediaMonitor.net</a> <br/>
                phone: +43 1 581 26 30 600
            </p>

            <p>
                <b>Headquarters:</b>
            </p>
            <p>
                eMedia Monitor GmbH <br/>
                Babenbergerstrasse 9<br/> 
                1010 Vienna         <br/>
                Austria          
            </p>
        </div>
    )
}

export default ContactUs;