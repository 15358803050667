export const SELECTED_GROUP = "channelgroupswidget/selectgroup"
export const UNSELECTED_GROUP = "channelgroupswidget/unselectgroup"
export const SELECTED_CHANNELS = "channelgroupswidget/selectchannels"
export const UNSELECTED_CHANNELS = "channelgroupswidget/unselectchannels"
export const SORTED_CHANNELS = "channelgroupswidget/sortedchannels"
export const DUPLICATE_NAME_SET = "channelgroupswidget/duplicatename"
export const EDIT_VIEW_TOGGLE = "channelgroupswidget/editviewtoggle"
export const POPUP_MESSAGE = "channelgroupswidget/popupmessage"
export const SAVE_STATUS = "channelgroupswidget/savestatus"
export const CREATE_STATUS = "channelgroupswidget/createstatus"
export const DELETE_STATUS = "channelgroupswidget/deletestatus"
export const TOGGLE_FILTERBOX = "channelgroupswidget/togglefilterbox"
export const SHOW_ALL_CHANNELS = "channelgroupswidget/showallchannels"
export const FILTERED_CHANNELS = "channelgroupswidget/filteredchannels"
export const SEARCHED_CHANNELS = "channelgroupswidget/searchedchannels"
export const SELECTED_FILTER = "channelgroupswidget/selectedfilter"
export const SELECTED_SORT_TYPE = "channelgroupswidget/selectedsorttype"
export const SEARCH_INPUT = "channelgroupswidget/searchInput"
export const SET_CHECKBOX_VALUES = "channelgroupswidget/checkboxvalues"
export const CHECKBOX_FILTER_VALUES = "channelgroupswidget/checkboxfiltervalues"
export const FILTERBOX_INDEX = "channelgroupswidget/filterboxindex"
export const SET_FILTER_COLOR_VALUES = 'channelgroupswidget/setfiltercolors';
export const HEADERS_LENGTH = 'channelgroupswidget/headerslength'
export const TOGGLE_SELECTED_BUTTON_STATUS = 'channelgroupswidget/toggleselectedbuttonstatus';
export const SELECTED_CHANNEL_IDS = 'channelgroupswidget/selectedchannelids'
export const SET_DESELECT_CHANNELS = 'channelgroupsWidget/setdeselectchannels'