const languageBreakdownData = [
    {
      "name": "es",
      "_count": 28341
    },
    {
      "name": "en",
      "_count": 14303
    },
    {
      "name": "de",
      "_count": 7520
    },
    {
      "name": "fr",
      "_count": 2308
    },
    {
      "name": "it",
      "_count": 1309
    },
    {
      "name": "el",
      "_count": 127
    },
    {
      "name": "lb",
      "_count": 120
    },
    {
      "name": "tr",
      "_count": 53
    },
    {
      "name": "hu",
      "_count": 35
    },
    {
      "name": "nl",
      "_count": 33
    },
    {
      "name": "ca",
      "_count": 15
    },
    {
      "name": "pt",
      "_count": 10
    },
    {
      "name": "da",
      "_count": 6
    },
    {
      "name": "fi",
      "_count": 4
    },
    {
      "name": "cs",
      "_count": 4
    }
]
  
export default languageBreakdownData;