import { ChangeEvent, FC } from 'react';
import { OverviewHeaderProps } from './OverviewHeader.types';
import styles from './OverviewHeader.module.scss';
import { useTranslation } from 'react-i18next';

import BackButton from 'src/Widgets/common/basicElements/BackButton/BackButton';
//@ts-ignore
import {useHistory} from 'react-router-dom'

const OverviewHeader: FC<OverviewHeaderProps> = ({
    setSelectedType,
    channelTypesWithNotification,
    selectedDates,
    selectedProfileIds,
    isMentionResults,
    dataCondition,
    mentionType,
    isTopicMentionDetails
}) => {
    const { t } = useTranslation();
    const history = useHistory()

    const showConditionalSelectBox =
        selectedDates && selectedProfileIds ? (
            <select
                className={styles.mentionDetailsTypeSelect}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    setSelectedType(e.target.value)
                }
            >
                <option value="">{t('Filter By Type')}</option>
                {channelTypesWithNotification?.map((types: any, index) => {
                    return <option key={index}>{types.type}</option>;
                })}
            </select>
        ) : null;

    return (
        <>
            <div className={styles.topbarControls}>
                {!isTopicMentionDetails && (
                    <BackButton onClick={() => history.goBack()} />
                )}
                {isMentionResults ? (
                    <>
                        <h3>
                            {`${dataCondition.length === 1000 ? t('Results Limited') + dataCondition.length : dataCondition.length} ` +
                                //t('Mentions') +
                                ' ' +
                                (!isTopicMentionDetails
                                    ? ''
                                    : '- ' +
                                      (dataCondition[0]?.profileGroupTitle || '') +
                                      '-')}{' '}
                            {mentionType}
                        </h3>
                        {/*  <select
                                className={styles.mentionDetailsTypeSelect}
                                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                                    setSelectedType(e.target.value)
                                }
                            >
                                <option value="">{t('Filter By Type')}</option>
                                {channelTypesWithNotification?.map(
                                    (types: any, index) => {
                                        return <option key={index}>{types.type}</option>;
                                    }
                                )}
                            </select> */}
                    </>
                ) : (
                    showConditionalSelectBox
                )}
            </div>
        </>
    );
};

export default OverviewHeader;
