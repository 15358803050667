import classNames from "classnames";
import { useTranslation, getI18n } from "react-i18next";
import styles from './Titlebar.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faFile, faGlobe, faUndo } from "@fortawesome/free-solid-svg-icons";
import { openChat } from 'src/common/SocialintentsChat';
//@ts-ignore
import { CSSTransition } from 'react-transition-group';
import config from 'src/config/config.js';
import { dynamicConfig } from "src/config/dynamicConfig/dynamicConfig";

interface HelpMenuProps{
    theme: any
    helpMenuOpened: boolean
    showAcademy: boolean
    hideHelpMenu: () => void
    showTour: (showTour: boolean) => void
    animate: boolean
    // setIsOpen: boolean
    supportEmail: string
}

function HelpMenu(props: HelpMenuProps) {
    const { t } = useTranslation();

    return (
        <div>
        {props.helpMenuOpened && <div
            className={classNames(
                styles.userMenu,
                styles.dropHelp,
                styles.menuTourHelper
            )}
        />
        }
            
    <CSSTransition
        in={props.helpMenuOpened}
        timeout={props.animate ? 300 : 0}
        unmountOnExit
        classNames={props.animate
            ?{ exitActive: `animated ${document.dir === 'rtl' ? 'fadeOutLeft' : 'fadeOutRight'}` }
            : undefined
        }
    >

    <div className={styles.panelWrap} onClick={props.hideHelpMenu}>
            <div
                data-tut='tour__helpmenu'
                className={classNames(
                    styles.userMenu,
                    props.animate ? `animated ${document.dir === 'rtl' ? 'fadeInLeft' : 'fadeInRight'}` : undefined,
                    props.helpMenuOpened ? props.theme.backgroundPrimary_reverse : props.theme.backgroundPrimary, 
                    styles.dropHelp
                )}
                // style={{width: '100%'}}
            >
                <a
                    target='_blank'
                    className={classNames(styles.userMenuLink, props.theme.borderSecondary)}
                    // @ts-ignore
                    href={`${process.env.PUBLIC_URL}/userguide/${config.userGuideLanguages.includes(getI18n().language) ? getI18n().language : 'en'}/UserGuide.html`}
                >
                     <FontAwesomeIcon className={styles.userGuideSymbolV3} icon={faFile} style={{ marginInlineStart: '2px', width: '1.7rem' }}/>
                    
                    {t('User Guide')}
                </a>

                <a
                    target='_blank'
                    className={classNames(styles.userMenuLink, props.theme.borderSecondary)}
                    // @ts-ignore
                    href={`https://www.emediamonitor.net/${config.onlineSupportLanguages.includes(getI18n().language) ? getI18n().language : 'en'}/support-portal/`}
                >
                  <FontAwesomeIcon icon={faGlobe} className={styles.userGuideSymbolV3} style={{ marginInlineStart: '2px', width: '1.7rem' }}/>
                    {t('Online Support')}
                </a>
                {dynamicConfig.enableChat && props?.supportEmail?.toLowerCase().includes('emediamonitor.net') && <span
                    className={classNames(styles.userMenuLink, props.theme.borderSecondary)}
                    onClick={() => {
                        openChat();
                    }}
                >
                    <FontAwesomeIcon
                        icon={faComment}
                        className={styles.userGuideSymbolV3}
                        style={{ marginInlineStart: '2px', width: '1.7rem' }}
                    />
                    {t('chat_with_us')}
                </span>}

                {!props.showAcademy &&
                    <span
                        className={classNames(styles.userMenuLink, props.theme.borderSecondary)}
                        onClick={() => {
                            // props.setIsOpen(true);
                            props.showTour(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faUndo} className={styles.userGuideSymbolV3} style={{ marginInlineStart: '2px', width: '1.7rem' }} />
                        {t('start_tour')}
                    </span>
                }

                
                </div>
        </div>
    </CSSTransition></div>
    )
}

export default HelpMenu;