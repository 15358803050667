import EmmBanner from "../EmmBanner/EmmBanner";
import { useState } from "react";
//@ts-ignore
import { useLocation } from "react-router-dom";
import styles from './ResetPassword.module.scss';
import APIAuth from "src/API/APIAuth";
import ChangePwInputs from "./ChangePwInputs";
import ResetPwSuccess from "./ResetPwSuccess";

const ResetPassword = () => {
    const [rc, setRc] = useState<number|undefined>(undefined);
    const location = useLocation();
    const params = new URLSearchParams(location.search)
    const userId = params.get('id');
    const pwCode = params.get('pwCode');
    const paramsMissing = !userId || !pwCode;

    const resetPw = async(password: string) => {
        const res = await APIAuth.resetPassword(userId, password, pwCode);
        setRc(res?.rc);
    }

    if (paramsMissing) {
        return (<div>invalid url (missing parameters)</div>)
    }

    return (
        <EmmBanner>
            <div className={styles.wrapper}>
                {rc === 0
                    ? <ResetPwSuccess userId={userId} />
                    : <ChangePwInputs
                        userId={userId}
                        onSave={resetPw}
                    />
                }
            </div>
        </EmmBanner>
    )
}

export default ResetPassword;