import APIChannels from '../../API/APIChannels';
import {
    RECEIVE_ITEMS
} from '../types/actionTypes.channels';


export const getChannels = () => {
  return (dispatch) => {
    return APIChannels.get().then((res) => {
      dispatch({
        type: RECEIVE_ITEMS,
        data: res.data,
      });
      return res.data;
    })
  }
};