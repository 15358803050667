import { useEffect, useState } from 'react';

export interface MatrixData {
  columnHeaders: string[];
  matrix: any[][];
}

const useTableDataConverter = (data: Record<string, any> | null, multipleRowSelection?: boolean): MatrixData => {
  const [convertedData, setConvertedData] = useState<MatrixData>({ columnHeaders: [], matrix: [] });

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0 && Array.isArray(data[0])) {
      const columnHeaders = Array.from({ length: data[0].length }, (_, i) => `Column ${i + 1}`);
      const matrix = data;
      setConvertedData({columnHeaders, matrix });
    } else if (Array.isArray(data) && data.length > 0) {
      const columnHeaders = multipleRowSelection ? Object.keys(data[0]).filter(key => key !== 'id') : Object.keys(data[0]);
      const matrix = data.map((item) => multipleRowSelection ? Object.keys(item).filter(key => key !== 'id').map(key => item[key]) : Object.values(item));
      setConvertedData({ columnHeaders, matrix });
    } else if (typeof data === 'object' && data !== null) {
      const columnHeaders = multipleRowSelection ? Object.keys(data).filter(key => key !== 'id') : Object.keys(data);
      const matrix = [multipleRowSelection ? Object.keys(data).filter(key => key !== 'id').map(key => data[key]) : Object.values(data)];
      setConvertedData({ columnHeaders, matrix });
    } else {
      throw new Error('Unsupported tableData structure');
    }
  }, [data, multipleRowSelection]);

  return convertedData;
};

export { useTableDataConverter };