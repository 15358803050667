import styles from './Tour.module.scss';

const Tooltip = ({
  // continuous,
  // index,
  step,
  // backProps,
  // closeProps,
  // primaryProps,
  tooltipProps,
}) => {

  return (
    <div {...tooltipProps} className={styles.tour}>
      {step.title && <span>{step.title}</span>}
      <span>{typeof step.content === 'function' && step.content()}</span>
    </div>
  )

};

export default Tooltip;