import React, { FC, useState } from 'react'
import TextInput from '../TextInput/TextInput'
import { PasswordInputProps } from './PasswordInputProps'
import styles from './PasswordInput.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from 'src/redux/hooks';

const PasswordInput: FC<PasswordInputProps> = (props) => {
    const [show, setShow] = useState(false);

    const theme = useAppSelector(state => state.theme)

    return (
        <div className={styles.wrapper}>
            <TextInput
                {...props}
                inputClassName={styles.input}
                disabled={true}
                inputType={props.allowShow && show ? 'text' : 'password'}
            />
            <button className={styles.button} onClick={()=>{setShow(!show)}}><FontAwesomeIcon icon={show ? faEyeSlash : faEye} /></button>
        </div>
  )
}

export default PasswordInput;