import {APISettings} from "./APISettings";

class APISettingsTour extends APISettings{
    
    constructor(){
        super();
        // TODO: use this, after solving existing "Introduction" workspace issue for tour settings
        this.baseURL = `/api/settings/emm/global/tour/`;
        // this.baseURL = `/api/settings/`;
    }
}

export default new APISettingsTour();