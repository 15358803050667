    import { library } from '@fortawesome/fontawesome-svg-core';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BackButton from '../BackButton/BackButton';
import './IFrameWrapper.scss';

// fontawesome icons
library.add(faCog);

/**
 * Takes 3 props:
 *
 * - url: The URL the iframe should point to
 * - title: The title the iframe should have
 * - onGoBack: Function to be called when back-button is pressed
 *      (usually something that disables the rendering of this component)
 */

class IFrameWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

        this.hideLoadingIndicator = this.hideLoadingIndicator.bind(this);
    }

    hideLoadingIndicator() {
        this.setState({ isLoading: false });
    }

    render() {
        return (
            <div ref={this.props.wrapperRef} style={this.props.wrapperStyle} className="iframe-wrapper">
                <div className="iframe-and-loading-wrapper">
                    {this.state.isLoading && (
                        <FontAwesomeIcon
                            className="iframe-loading-indicator"
                            icon="cog"
                            spin
                            key="iframe-loading-spinner"
                        />
                    )}

                    <iframe
                        src={this.props.url}
                        title={this.props.title}
                        className="iframe"
                        onLoad={this.hideLoadingIndicator}
                        // height={this.props.iframeStyle.height}
                    />
                </div>

                {
                    this.props.onGoBack ?
                        <BackButton onClick={this.props.onGoBack} />
                    : null
                }
            </div>
        );
    }
}
export default IFrameWrapper;
