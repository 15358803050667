import React, { Component } from 'react';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import _ from 'lodash';
import Item from './Item';
import styles from './ItemSelection.module.scss';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

library.add(faLevelDownAlt);

/**
 * props:
 *  - items: [
 *      {   id: integer (required),
 *          icon: string (will always be rendered, atleast as an initicon),
 *          mediaType: string,
 *          text: string,
 *          secondaryText: string
 *      }, ...
 *  ]
 *  - selected: [integer]
 *  - onSelectionChange(selected): func, parameter delivers all selected items
 *  - loadMore (infiniteScroll)
 *  - hasMore (infiniteScroll)
 *  - useGravatar (uses items.text for username)
 *  - className
 */

/* nested items prop example:
items:
[
    {
        items: [
            { items: [{ text: 'oe3' }, { text: 'orf 1' }], text: 'AT' },
            { items: [{ text: 'das erste' }, { text: 'zdf' }], text: 'DE' }
        ]
        , text: 'Europe'
    }
]
*/
export class ItemSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    // if selected contains entries that are not in items, this should
    // keep them contained in selected, which should be helpful for pagination
    // and pre-selecteds
    onClick(id) {        
        
        let selected;
        let isSelected;

        if (_.includes(this.props.selected, id)) {
            // remove if id is in selected (deselect)
            selected = _.filter(this.props.selected, s => s !== id);
            isSelected = false;
        } else {
            // add if id is not in selected (select), union to kill duplicates
            selected = _.union(this.props.selected, [id]);
            isSelected = true;
        }
        this.props.onSelectionChange(selected,{id:id, isSelected: isSelected});
    }

    renderIndentation(level) {
        return (
            <div class="indent" style={{
                width: `${(level - 1) * 1}rem`,
                display: 'inline-block'
            }} />
        )
    }

    renderIntented(item, level) {
        return (
            <div
                className={styles.level}
                style={{
                    marginInlineStart: `${(level - 1) * 1}rem`
                }}
            >
                {item}
            </div>
        )
    }

    renderItems(items, selected, level) {
        // translation method with fallback
        const t = this.props.t || (k => k);

        level = level ? level : 1;

        if (!items || items.length === 0)
            return (
                <section
                    className={classNames(styles.itemContainer, styles.noFound)}
                >
                    <span className={styles.noFoundText}>
                        {t('No items found')}
                    </span>
                </section>
            );
        
        const Row = ({ index, style, item }) => {
            if (item.items) {
                return (
                    <div style={style}>
                        { this.renderIntented(<>
                            <FontAwesomeIcon className={styles.levelDown} icon="level-down-alt" />
                            {item.text}
                        </>, level) }
                        {this.renderItems(item.items, undefined, level+1)}
                    </div>)
            } else {
                return (
                    <div style={style}>
                    {this.renderIntented(<Item
                        style={{
                            marginInlineStart: `${(level - 1) * 1}rem`
                        }}
                        key={index}
                        isSelected={_.includes(selected, item.id)}
                        {...item} // text, secondaryText, icon, mediaType
                        useGravatar={this.props.useGravatar}
                        onClick={() => this.onClick(item.id)}
                        />, level)}
                    </div>
                )
            }
        };
              
        return (
            <AutoSizer>
                {({ width, height }) => (
                    <List
                        height={height}
                        width={width}
                        itemCount={items.length}
                        itemSize={35}
                    >
                        {({ index, style }) => {
                            return <Row item={items[index]} index={index} style={style}/>
                        }}
                    </List>
                )}
            </AutoSizer>
        )
    }

    render() {
        return (
            <InfiniteScroll
                loadMore={this.props.loadMore}
                hasMore={this.props.hasMore}
                className={classNames(styles.itemContainer, this.props.className)}
            >
                {this.renderItems(this.props.items, this.props.selected)}
            </InfiniteScroll>
        );
    }
}

export default withTranslation()(ItemSelection);
