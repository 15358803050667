import React from 'react';
import styles from './QueryVisualization.module.scss';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import APIQueryVisualization from 'src/API/APIQueryVisualization';
// import { getLastQueryWord } from '../helpers';
import APILMU from 'src/API/APILMU';
import APITelemetry from 'src/API/APITelemetry';

// usage info:
// can either be used by providing the already fetched data via prop. 'data' or
// let the component fetch the data itself by providing the prop. 'query'

class QueryVisualization extends React.Component {
    static propTypes = {
        html: PropTypes.string, // html string for visualization
        data: PropTypes.object, // object for visualization
    }
    constructor(props) {
        super(props);
        this.state = {
            data:[]
        };
    }

    debouncedFetch = _.debounce((query, lang) => this.fetchTree(query, lang), 500);

    async fetchTree(query, languageIdx) {
        if(query
            // NOTE: if we want to use getLastQueryWord() here we probably need to adjust it or write an addition funct.
            // issue is: sometimes not sending query due to this, probably every time when query does not end with letters but "special chars" ( (),+- ) instead
            // && getLastQueryWord(query).length >= 3
        ){
            const res = await APIQueryVisualization.get(query, [90013], languageIdx);
            if(res && res.rc === 0){
                let treeData = res.tree;
                this.props.onAfterTreeFetch(res);
                this.setState({
                    queryInvalid: false,
                    data: treeData,
                });
            } else {
                if(res && res.data){
                    switch(res.data.rc) {
                        case 90013:
                            this.props.onAfterTreeFetch(res.data);
                            break;
                        default: break;
                    }
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.query, this.props.query)) {
            this.debouncedFetch(this.props.query, this.props.languages);
        }
    }

    componentDidMount() {
        this.debouncedFetch(this.props.query, this.props.languages);
    }

    isMissing(val) {
        val = Number(val);
        if (val === 0) {
            return true;
        } else if (val > 0) {
            return false;
        }
        return undefined;
    }

    renderWord(w, wordType, langs){
        // TODO later: add support for multi-lang queries ?
        const { queryId } = this.props;
        let vocabClass;
        if (langs && langs.length === 1) {
            let isMissing = this.isMissing(w[langs[0]]);
            if (isMissing === true) { vocabClass = styles.vocabMissing; }
            else if (isMissing === false) { vocabClass = styles.vocabIncluded; }
        }
        const isLmuPossible = vocabClass === styles.vocabMissing && queryId;

        let wordClassNames = [vocabClass];
        switch (wordType) {
            case 'phrase':
                wordClassNames.push(styles.phraseWord);
                break;
            case 'word':
                wordClassNames.push(styles.word);
                break;
            case 'notWord':
                wordClassNames = wordClassNames.concat([styles.word, styles.not]);
                break;
            default:
                APITelemetry.post({ msg: `QueryVisualization: Do not know how to render type=${wordType}` })
                break;
        }

        return (
            <span
                title={vocabClass === styles.vocabMissing && isLmuPossible
                    ? `${this.props.t('query_tree_vocab_missing_click_for_lmu')} "${w.word}"`
                    : vocabClass === styles.vocabMissing
                        ? `${this.props.t('query_tree_vocab_missing_save_before_lmu')} "${w.word}"`
                        : `${this.props.t('query_tree_vocab_included')} "${w.word}"`
                }
                onClick={async () => {
                    if (styles.vocabMissing && isLmuPossible) {
                        if (this.props.onLmuStatusChange) { this.props.onLmuStatusChange('requesting'); }
                        await APILMU.request(w.word || w.notWord, langs[0], queryId);
                        if (this.props.onLmuStatusChange) { this.props.onLmuStatusChange('requested'); }
                        // TODO: handle 'lmu requested' words. 1. for recently requested 2. for other which were already requested when requesting query-tree
                    }
                }}
                style={styles.vocabMissing && isLmuPossible ? {cursor: 'pointer'} : undefined}
                className={wordClassNames.join(' ')}
            >
                {
                    wordType === 'notWord'
                        ? this.renderNot() + ' ' + w.notWord
                        : w.word
                }
            </span>)
    }

    renderNot = () => this.props.t('not').toUpperCase();

    renderContainer(o, containerType, containerTypeString, index) {
        if (containerType === 'notAnd') {
            containerTypeString = this.props.t('and');
        } else if (containerType === 'notOr') {
            containerTypeString = this.props.t('or');
        }
        let objectType = Object.keys(o)[0]
        let containerStyle = [
            styles[objectType],
            objectType === 'and' || objectType === 'notAnd'
                ? this.props.theme.borderSecondary 
                : this.props.theme.borderPrimary,
        ];
        return (
            <span>
                {(objectType === 'notAnd' || objectType === 'notOr') && this.renderNot()}
                <span key={index} className={classNames(containerStyle)}>
                    {
                        _.map(o, (c, index) => {
                            let rv;
                            if (c.word !== undefined) {
                                rv = this.renderWord(c, 'word', this.props.languages);
                            } else if (c.words !== undefined) {  // phrase
                                rv = (
                                    <span key={index} className={[styles.phrase]}>
                                        {c.notPhrase &&
                                            <span className={styles.phraseWord}>
                                                {this.renderNot()}
                                            </span>
                                        }
                                        {_.map(c.words, (w, wordIndex) => {
                                            return this.renderWord(w, 'phrase', this.props.languages);
                                        })}
                                    </span>
                                );
                            } else if (c.notWord !== undefined) {
                                rv = this.renderWord(c, 'notWord', this.props.languages);
                            } else {
                                rv = this.renderContainer(c, objectType, this.props.t(objectType), index);
                            }
                            
                            
                            if ((o.length - 1) > index) {
                                rv = (
                                    <React.Fragment key={index+'_op'}>
                                        {rv}
                                        <span className={classNames(styles['op-'+containerType], styles.op)}>{containerTypeString.toUpperCase()}</span>
                                    </React.Fragment>
                                );   
                            }                        
                            return rv;
                        })
                    }
                </span>
            </span>
        )
    }

    render() {
        const d = this.props.data || this.state.data;
        return (
            <div className={styles.queryVisualization}>
                {d ?
                    d.or ? this.renderContainer(d, "or", this.props.t("or"))
                         : this.renderContainer(d, "and", this.props.t("and"))
                :
                    <div dangerouslySetInnerHTML={{ __html: this.props.html }}></div>
                    // NOTE: not really working yet - not sure how we could apply style of css modules here. probably we just want to use the 'data' option where we get json data from backend
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({ theme: state.theme })
export default connect(mapStateToProps)(withTranslation()(QueryVisualization));