import {
    CHANGE_ROUTE
} from '../types/actionTypes.route';

export const changeRoute = (newRoute) => {
    return async(dispatch) => {
            dispatch({
                type: CHANGE_ROUTE,
                data: newRoute,
            });
    };
};