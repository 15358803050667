import {
    DELETE_SCHEDULE,
    // DELETE_TASK,
    POST_SCHEDULE,
    PUT_SCHEDULE,
    RECEIVE_SCHEDULES,
    RECEIVE_TASKS,
    LOADING_SCHEDULES,
} from '../types/actionTypes.schedules';
import {
    STATUS_LOADING,
    STATUS_SUCCEEDED,
} from '../types/status';
import _ from 'lodash';

const scheduleReducers = (state = {
    status: 'idle', // https://redux.js.org/tutorials/essentials/part-5-async-logic#loading-state-for-requests
    schedules: [],
}, action) => {
    let rv, scheduleIdx;

    switch (action.type) {
        // case START_RECEIVE_SCHEDULES:
        //     return;
        case LOADING_SCHEDULES:
            return {...state, status: STATUS_LOADING};
        case RECEIVE_SCHEDULES:
            rv = _.cloneDeep(state);
            rv.schedules = action.data;
            rv.status = STATUS_SUCCEEDED;
            return rv;
        case RECEIVE_TASKS:
            rv = _.cloneDeep(state);
            scheduleIdx = _.findIndex(state.schedules,{id: action.id});
            rv.schedules[scheduleIdx].tasks = action.data;
            return rv;
        case DELETE_SCHEDULE:
            rv = _.cloneDeep(state);
            const i = _.findIndex(rv.schedules,{id:action.id});
            rv.schedules.splice(i,1);
            return rv;
        case POST_SCHEDULE:
            rv = _.cloneDeep(state);
            rv.schedules.push(action.data);
            return rv;
        case PUT_SCHEDULE:
            rv = _.cloneDeep(state);
            scheduleIdx = _.findIndex(state.schedules,{id: action.id});
            rv.schedules[scheduleIdx] = action.data;
            return rv;
        // case DELETE_TASK:
        //     console.log('delete task');
        //     rv = _.cloneDeep(state);
        //     return rv;
        default: return state;
    }
}

export default scheduleReducers;