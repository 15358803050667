import React, { Component } from 'react';
import styles from './Item.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Icon from '../Icon/Icon';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faMicrophone,
    faTv,
    faNewspaper
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

// fontawesome icons
library.add(faMicrophone, faTv, faNewspaper);

class Item extends Component {
    state = {};
    render() {
        let faIcon = null;
        const {
            mediaType,
            isSelected,
            text,
            icon,
            onClick,
            useGravatar
        } = this.props;

        const secondaryText = this.props.secondaryText
            ? ' (' + this.props.secondaryText + ')'
            : null;

        if (_.includes(mediaType, 'Radio')) faIcon = 'microphone';
        if (_.includes(mediaType, 'TV')) faIcon = 'tv';
        if (_.includes(mediaType, 'Text') || mediaType === 'Press')
            faIcon = 'newspaper';

        let MediaIcon = faIcon ? (
            <div className={styles.typeIcon}>
                <FontAwesomeIcon icon={faIcon} />
            </div>
        ) : null;

        return (
            <div
                className={classNames(
                    styles.wrapper, 
                    {
                        [styles.selected]: isSelected,
                        [this.props.theme.borderSecondary]: isSelected
                    }
                )}
                onClick={onClick}
            >
                <div className={classNames({ [styles.iconGroup]: faIcon })}>
                    <Icon
                        icon={icon}
                        title={text}
                        useGravatar={useGravatar}
                        size={30}
                    />

                    {MediaIcon}
                </div>

                <span className={styles.description}>
                    <span className={styles.primary}>{text}</span>
                    {secondaryText}
                </span>
            </div>
        );
    }
}

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(Item);
