import { SET_MENTIONS_DATA, SET_SENTIMENT_RESULTS } from "../types/actionTypes.mentions";

const initialState = {
sentimentResults: []    
}

const mentionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SENTIMENT_RESULTS:
            return {
                ...state,
                sentimentResults: action.payload
            }   
        default:
            return state;
    }
}    

export default mentionsReducer