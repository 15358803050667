import React from 'react';
import {
    HashRouter,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import './App.scss';

import Board from './Board/Board';
import Login from './Login/Login';
import NotificationPanel from './Widgets/NotificationPanel/NotificationPanel';
import { Offline } from 'react-detect-offline';
import './assets/animate.css';

// FontAwesome Base
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBars,
    faTimesCircle,
    faPen,
    faUserCircle,
    faCloudSun,
    faTimes,
    faUmbrellaBeach,
    faChevronDown,
    faChevronUp,
    faSearch,
    faArrowLeft,
    faCog,
    faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import Banner from './Widgets/common/basicElements/Banner/Banner';
import ErrorBanner from './Widgets/common/basicElements/Banner/ErrorBanner';
import WaterfallPlayer from './Widgets/Waterfall/WaterfallPlayer/WaterfallPlayer';
import About from './Board/About/About';
import RouteChangeListener from './RouteChangeListener/RouteChangeListener';
import Matomo from './Matomo';
import { connect } from 'react-redux';
import { clearMe, setMe } from './redux/actions/actions.me';
import { setShowAcademy } from './redux/actions/actions.board';
import { setStripeCustomerId } from './redux/actions/actions.stripe';
import { setIsAuthenticated } from './redux/actions/actions.auth';
import APIMe from './API/APIMe';
import LoadingSpinner from './Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import { withTranslation } from 'react-i18next';
import Page from './Widgets/common/basicElements/Page/Page';
import { getAvailableWidgets } from './Board/Workspace/availableWidgets';
import _ from 'lodash';
import config from 'src/config/config.js';
import IssueMsg from './IssueMsg/IssueMsg';
import APITelemetry from './API/APITelemetry';
import APISettings from './API/APISettings';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import APIAuth from './API/APIAuth';
import ReAuth from './Widgets/common/ReAuth/ReAuth';
import {
    getHashParams, urlParamsToObj,
    // isVersionCompatible, splitVersionNumber, 
} from './Widgets/common/helpers';
import SignUpContainer from './Auth/SignUp/SignUpContainer';
import OAuth2Handler from './Auth/OAuth2Handler/OAuth2Handler';
import UserExpired from './Login/UserExpired/UserExpired';
import UserLocked from './Login/UserLocked/UserLocked';
import EmmBanner from './Login/EmmBanner/EmmBanner';
import RouteChangeHandler from './RouteChangeHandler/RouteChangeHandler';
import { setParams } from './redux/actions/actions.signup';
import UserMessage from './Login/UserMessage/UserMessage';
import MentionResults from './Widgets/common/MentionResults/MentionResults';
import ResetPassword from './Login/ResetPassword/ResetPassword';
import EmailSignup from './Auth/EmailSignup/EmailSignup';
import EmailSignupSetPw from './Auth/EmailSignup/EmailSignupSetPw';
import EmailSignupCountryPackage from './Auth/EmailSignup/EmailSignupCountryPackage';
import VerifyEmail from './Auth/EmailSignup/VerifyEmail';
import { ROUTES } from './consts';
import Pricing from './stripe/Pricing/Pricing';
import CheckoutForm from './stripe/Checkout';
import CheckoutComplete from './stripe/CheckoutComplete';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { dynamicConfig } from './config/dynamicConfig/dynamicConfig';
import RSSFeed from './Widgets/common/RSSFeed/RSSFeed';
import { resetApp } from './redux/actions/actions.reset';
import ForgotPassword from './Login/ForgotPassword/ForgotPassword';

// fontawesome icons
library.add(
    faBars,
    faTimes,
    faTimesCircle,
    faPen,
    faUserCircle,
    faCloudSun,
    faUmbrellaBeach,
    faChevronUp,
    faChevronDown,
    faSearch,
    faArrowLeft,
    faCog,
);

class App extends React.Component {
    constructor(props) {
        super(props);
        this.storage = config.STORAGE;

        this.state = {    
        };
        
        this.unauthenticate = this.unauthenticate.bind(this);
        Matomo();
    }

    // TODO: handle 'userType=v3.trial' param. - which is already used in some links on the website
    getParams() { return urlParamsToObj(window.location?.search, ['academy']) };
    
    getHashParams(){ return getHashParams(['userType']) };

    async onMount() {
        const params = this.getParams();
        // const hashParams = this.getHashParams();
        if (this.props.auth.session.isAuthenticated && this.props.me === null) {
            const me = (await APIMe.get()).data;
            this.props.setStripeCustomerId('cus_QNPMBMYjiOsIro');   // TODO: use from Backend instead
            if(config.academyAdminUserIds.includes(me.id)){ me.isAcademyAdmin = true; }    // TODO: we will probably provide this to FE from "general settings" backend in the future
            this.props.setShowAcademy(
                params.academy === "1"
                    // || hashParams.academy === "1" ? true : false
            );
            this.props.setMe(me);
            this.props.matomo.push(['setUserId', me.username]);
        }
    }

    componentDidMount() {
        if (dynamicConfig.cookieConsentConfig) {
            CookieConsent.run(dynamicConfig.cookieConsentConfig);
        }
        this.onMount();
        const hashParams = this.getHashParams();
        this.props.setParams(hashParams);
    }

    shouldComponentUpdate(nextProps, nextState){
        const p = this.props;


        // do not re-render if re-authentication is needed - to keep all potential current changes in UI
        if(
            (
                (p.auth.session.isExpired === true || nextProps.auth.session.isExpired === true)
                && !p.auth.session.isManualLogout
            )
            || _.isEqual(p, nextProps)
        ) {
            return false; 
        }
        
        return true;
    }

    componentDidUpdate() {
        this.onMount();
    }


    async unauthenticate() {
        const res = await APIAuth.logout();
        if(res && Number(res.code) === -1){
            this.storage.clear();
            this.props.resetApp();
            await this.props.setIsAuthenticated({
                isAuthenticated: false,

                // we want to redirect to "v3/some/path" after login if user accessed it directly (e.g. pasting url), but we don't
                // want to redirect to previous visited page if user clicked logout (e.g. to log in with another user)
                isManualLogout: true
            });
            this.props.clearMe();
        }
    }

    render() {
        const { t } = this.props;
        // TODO: error message if me don't work
        if (
            // (!this.props.me && this.state.isAuthenticated) ||
            (!this.props.matomo))
            return <LoadingSpinner />;
        
        // if (this.props.me) {    // checking api version
        //     const servedVersion = splitVersionNumber(this.props.me.APIVERSION)//.split('.');
        //     const expectedVersion = splitVersionNumber(process.env.REACT_APP_APID_VERSION)//.split('.');

        //     if(!isVersionCompatible(servedVersion, expectedVersion)){
        //         APITelemetry.post({
        //             msg: `Expected apid version >= '${expectedVersion.major}${expectedVersion.minor}' and < '${(expectedVersion.major + 1)*100}' but got version '${this.props.me.APIVERSION}'`
        //           });
        //     }
        // }

        /* const mentionResults = this.props.mentionsReducer.mentionsData */

        return (
            <div style={{width:'100%', height:'100%'}} className={this.props.theme?.font}>
                <HashRouter>
                    <RouteChangeListener
                        actions={[
                            (path, prevPath) => {
                                const fullPath = `${process.env.PUBLIC_URL}/#${path}`;
                                this.props.matomo.push(['setCustomUrl', fullPath]);
                                // Matomo.push(['setDocumentTitle', 'new title']);
                                this.props.matomo.push(['trackPageView']);
                                // Matomo.push(['trackEvent', 'eventCategory', 'yourEvent']);
                            }
                        ]}
                    />
                    <RouteChangeHandler/>

                    <Offline polling={{enabled: false}}>
                        <Banner faIcon={faCircleNotch} faSpin>
                            Connection lost ... 
                        </Banner>
                    </Offline>

                    <ReAuth />

                    <dynamicConfig.errorBannerComponent />

                    <Switch>
                        {/* <Route path="/checkout/:priceId" component={(props) => (<CheckoutForm {...props} />)} /> */}
                        <Route path="/checkout-complete" element={<CheckoutComplete />} />
                        {/* EMBED LINKS */}
                        <PrivateRoute
                            path="/embed/notifications"
                            title={t('Notifications')}
                            component={NotificationPanel}
                            isAuthenticated={this.props.auth.session.isAuthenticated}
                        />

                        {
                            <PrivateRoute
                            path="/embed/rssFeed/:profileIds/:dateFrom/:dateTo"
                            title={"RSS Feed"}
                            component={(props) => <RSSFeed {...props}/>}
                            isAuthenticated={this.props.auth.session.isAuthenticated}
                            />
                        }

                        { <PrivateRoute
                            path="/embed/mentionResults/:profileIds?/:dateFrom/:dateTo/types/:mentionType"
                            title={t('Mentions')}
                            component={(props) => <MentionResults isTopicMentionDetails {...props}/>}
                            isAuthenticated={this.props.auth.session.isAuthenticated}
                        />}
                        { <PrivateRoute
                            path="/embed/mentionResults/:cluster/:sentiment"
                            title={t('Mentions')}
                            component={(props) => <MentionResults isTopicMentionDetails={false} {...props}/>}
                            isAuthenticated={this.props.auth.session.isAuthenticated}
                        />}
                        {
                            process.env.REACT_APP_ENV === 'development' && 
                            <PrivateRoute
                                path="/clear-settings"
                                title={'clear all settings'}
                                component={() => {
                                    const go = async () => (await APISettings.deleteAllSettings())
                                    return (
                                        <div>
                                            Warning, this will remove various settings from your account, e.g.:
                                            <pre>
                                                - workspaces<br />
                                                - widgets<br />
                                                - various histories: search, epg, ...<br />
                                            </pre>
                                            <button onClick={async () => {
                                                this.setState({done: undefined});
                                                const cleared = await go();
                                                this.setState({done: cleared ? cleared : 'nothing to do'});
                                            }}>go</button>
                                            {this.state.done}
                                        </div>
                                    );
                                }}
                                isAuthenticated={this.props.auth.session.isAuthenticated}
                            />
                        }
                        <Page
                            path="/embed/WaterfallPlayer"
                            title={t('Waterfall - Player')}
                            component={WaterfallPlayer}
                        />
                        <Page
                            path="/embed/about"
                            title={t('About')}
                            component={About}
                        />

                        {/* TODO: I'm a protoype, adapt or remove me later
                        <Page
                            path="/google"
                            title={t('About')}
                            component={GoogleAuth}
                        /> */}
                        {
                            this.props.me && _.map(getAvailableWidgets(this.props.me.experimentalWidgetsSelectable, this.props.me?.allWidgets), (widget, index) => {
                                if(widget.embedPath){
                                    let commonProps = {
                                        path: widget.embedPath,
                                        title: t(widget.name),
                                        component: () => (
                                            <div className='embed-wrapper'>
                                                <ErrorBoundary>
                                                    <widget.component {...widget.props} />
                                                </ErrorBoundary>
                                            </div>
                                        ),
                                        key:{index},
                                    }

                                    if (widget.public === true) {
                                        return <Page
                                            {...commonProps}
                                        />
                                    } else {
                                        return <PrivateRoute
                                            {...commonProps}
                                            isAuthenticated={this.props.auth.session.isAuthenticated}
                                        />
                                    }
                                }
                            })
                        }

                        {/* APPLICATION */}
                        <Route
                            path="/reset-password"
                            component={props => (
                                <ResetPassword/>
                            )}
                        />
                        <Route
                            path="/login"
                            component={props => (
                                <Login
                                    {...props}
                                    isAlreadyAuthenticated={
                                        this.props.auth.session.isAuthenticated
                                    }
                                    redirectTo={this.props.auth.session.redirectTo}
                                    isManualLogout={this.props.auth.session.isManualLogout}
                                />
                            )}
                        />
                        {/* <Route
                            path="/gauth"
                            component={props => (
                                <AuthCheck
                                    // isAuthenticated={this.state.isAuthenticated}
                                />
                            )}
                        /> */}
                        <Route
                            path={ROUTES.mef_pricing_public}
                            component={props => (
                                <Pricing auth={false} />
                            )}
                        />
                        <Route
                            path={ROUTES.mef_pricing}
                            component={props => (
                                <Pricing/>
                            )}
                        />
                        <Route
                            path={ROUTES.sign_up_email}
                            component={props => (
                                <EmailSignup/>
                            )}
                        />
                        <Route
                            path={ROUTES.sign_up_verify_email}
                            component={props => (
                                <VerifyEmail/>
                            )}
                        />
                        <Route
                            path="/sign-up-coverage"
                            component={props => (
                                <EmailSignupCountryPackage/>
                            )}
                        />
                        <Route
                            path="/oauth2/:authProvider/createNewUser"
                            component={props => (
                                <SignUpContainer
                                    authenticate={this.authenticate}
                                />
                            )}
                        />
                        <Route
                            path="/oauth2/:authProvider"
                            component={props => (
                                <OAuth2Handler />
                            )}
                        />
                        <Route
                            path="/user-expired"
                            component={props => {
                                return (<EmmBanner>
                                    <div>
                                        <UserExpired
                                            onGoBack={
                                                ()=>{ props.history.push('/login') }
                                            } />
                                    </div>
                                </EmmBanner>)
                            }}
                        />
                        <Route
                            path="/set-password"
                            component={props => {
                                return (<EmmBanner>
                                    <div>
                                        <ForgotPassword
                                            {...props?.location?.state}
                                            history={props.history}
                                            onGoBack={
                                                ()=>{ props.history.push(ROUTES.login) }
                                            } />
                                    </div>
                                </EmmBanner>)
                            }}
                        />
                        <Route
                            path="/user-locked"
                            component={props => {
                                return (<EmmBanner>
                                    <div>
                                        <UserLocked
                                            onGoBack={
                                                ()=>{ props.history.push('/login') }
                                            } />
                                    </div>
                                </EmmBanner>)
                            }}
                        />
                        <Route
                            path="/user-problem"
                            component={props => (
                                <UserMessage
                                    onGoBack={() => { props.history.push('/login') }}
                                    title={t('user_login_problem')}
                                    text={t('user_login_problem_text')}
                                />
                            )}
                        />
                        <Route
                            path="/user-no-permission"
                            component={props => (
                                <UserMessage
                                        onGoBack={() => { props.history.push('/login') }}
                                        title={t('user_no_permission_manage')}
                                        text={t('user_no_permission_manage_text')}
                                    />
                            )}
                        />
                        <Route
                            path="/auth/:username?"
                            component={props => {
                                const username = props.match.params.username;
                                return (
                                    <Login
                                        username={props.match.params.username}
                                        usernameDisabled={props.match.params.username ? true : false}
                                        {...props}
                                        isAlreadyAuthenticated={
                                            this.props.auth.session.isAuthenticated
                                        }
                                        redirectTo={this.props.auth.session.redirectTo}
                                        isManualLogout={this.props.auth.session.isManualLogout}
                                        closeOnSuccess={true}
                                    />
                                )
                            }}
                        />

                        <PrivateRoute
                            isAuthenticated={this.props.auth.session.isAuthenticated}
                            component={props => (
                                this.props.me && <Board
                                    {...props}
                                    unauthenticate={this.unauthenticate}
                                />
                            )}
                    />
                    </Switch>
                </HashRouter>
            </div>
        );
    }
}

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Page
            {...rest}
            render={props =>
                rest.isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

// export default App;

const mapStateToProps = state => ({ me: state.me, matomo: state.matomo, auth: state.auth, mentionsReducer: state.mentionsReducer, signup: state.signup, theme: state.theme });
export default connect(mapStateToProps, { clearMe, setMe, setShowAcademy, setIsAuthenticated, setParams, setStripeCustomerId, resetApp })(withTranslation()(App));
