import React from 'react';
//import styles from './Query.module.scss';
import styles from './QueryInput.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SelectAsync from 'src/Widgets/common/basicElements/SelectAsync/SelectAsync';
import _ from 'lodash';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { browserName } from 'react-device-detect';
import { BROWSER_CHROME } from 'src/consts';
import QueryVisualization from 'src/Widgets/common/QueryVisualization/QueryVisualization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye, faEyeSlash,
    // faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import LabeledSwitch from 'src/Widgets/common/basicElements/SwitchElements/LabeledSwitch';
import AutoComplete from 'src/Widgets/common/basicElements/TextInput/AutoComplete';
import SpamStatus from './SpamStatus/SpamStatus';
import { sanitizeQuery } from '../../helpers';

class Query extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
        this.queryRef = {};
    }

    onTranslateBtnClick(languageKey, index, callback) {
        // TODO: fix this if we want to show this to the user again
        // let confirm = true;
        // if(
        //     document.getElementById('goog-gt-tt') !== null &&  // browsers translate functionality was used
        //     document.getElementsByTagName('html')[0].getAttribute('lang') !== languageKey
        //     ){
        //     confirm = window.confirm(`${this.props.t("It looks like you did an automated translation but used another language")}. ${this.props.t("Are you sure the provided information is correct")}?`)
        //     // note: not sure if we should show this. it will also show up if a user clicks translate+done without having used browsers translate on the current query (but on another query)
        // }
        // return confirm;
        return true;
    }

    renderStandardQueryInput() {
        const {
            languageKey, 
            languageColor, 
            languageOptions,
            queryObj,
            onStandardQueryChange,
            t,
            theme,
            onLangChange,
            disableQuery,
            removeLanguage,
            setActiveLang,
            setQueryInvalid,
            showQueryTree,
            onAutoCompleteStandardQuery,
            onAfterTreeFetch,
            onSyntaxChecked,
            queryInvalid,
            showTree,
            profileSharedBy
        } = this.props;
        const { onTranslateBtnClick } = this;
        let queryRef = this.queryRef;
    
        const query =
            queryObj && queryObj.standardQuery &&
            (queryObj.standardQuery.query || '');
        
        if(
            // (queryObj
            // && queryObj.standardQuery
            // && queryObj.standardQuery.delete)
            // ||
            (!queryObj.standardQuery)
        ) { return; }

        const isDisabled = queryObj.standardQuery.disabled;
        const languageSelected = _.find(languageOptions, {
            value: languageKey
        });

        return (
            <section 
                className={styles.container}
                style={{
                    borderColor: languageColor
                }}
            >
                <div className={styles.queryWrap}>
                    <div className={styles.wrapper}>
                        <div
                            className={[
                                styles.languageText,
                                styles.standardQueryLangText,
                                isDisabled ? theme.backgroundPrimary_disabled : ''
                            ].join(' ')} 
                            style={{
                            backgroundColor: isDisabled ? undefined : languageColor,
                            borderColor: languageColor
                        }}>
                            {/* {!langSupport && <FontAwesomeIcon color='red' icon={faExclamationTriangle} />} */}
                            <div style={{display:'flex', alignItems:'center'}}>
                                
                                <div>
                                    {
                                        // languageKey === 'xx'
                                        //     ?
                                            <SelectAsync
                                                isDisabled={this.props.profileSharedBy !== null}
                                                options={languageOptions}
                                                value={languageSelected}
                                                className={styles.selectAsync}
                                                onChange={(val)=>onLangChange(languageSelected, val)}
                                            />
                                            // :t(languageKey)
                                    }
                                </div>
                                {!isDisabled && !queryObj.standardQuery.queryEdited && <SpamStatus
                                    spamStatus={queryObj.standardQuery.spamStatus}
                                    runSpamCheck={this.props.profileSharedBy !== null ? undefined : this.props.runSpamCheck}
                                />}
                            </div>
                                    
                            <div className={styles.rightSideLang}>
                                <div>
                                {languageKey !== 'xx' && (
                                    <Button
                                        onClick={() => {
                                            setActiveLang(languageKey);
                                            this.props.setShowTranslateModal(true);
                                        }}
                                        disabled={this.props.profileSharedBy !== null || !query || isDisabled}
                                        
                                        // title={browserName !== BROWSER_CHROME ? t('translate_use_chrome') : t('query_translate_explanation')}
                                        style={{backgroundColor: languageColor}}
                                    >
                                        {t('translate')}
                                    </Button>
                                )}
                                </div>
                                {
                                    languageKey !== 'xx' &&
                                        <Button
                                            disabled={isDisabled || !query}
                                            style={{ backgroundColor: languageColor }}
                                            onClick={() => {
                                                this.props.tryQuery({
                                                    query: queryObj.standardQuery.query,
                                                    lang: languageKey,
                                                })
                                            }}
                                        >
                                            {t('profile_try_query')}
                                        </Button>
                                }
                                <Button
                                    disabled={isDisabled || !query}
                                    style={{ backgroundColor: languageColor }}
                                    onClick={() => {
                                        const val = !showTree[languageKey];
                                        showQueryTree(val, languageKey);
                                    }}
                                    title={t('show_hide_query_vis')}
                                >
                                    <FontAwesomeIcon
                                        color='black'
                                        className={[styles.eyeIcon].join(' ')}
                                        icon={showTree[languageKey] ? faEyeSlash : faEye }
                                    />
                                </Button>
                                <LabeledSwitch
                                    disabled={this.props.profileSharedBy !== null}
                                    className={[styles.rightFlexItem,styles.switch].join(' ')}
                                    checked={isDisabled !== undefined ? !isDisabled : true}
                                    onChange={(val) => disableQuery(!val, languageKey)}
                                    labelPosition='start'
                                >
                                    {t('enable_query')}
                                </LabeledSwitch>
                            {languageKey === 'xx' &&
                                <Button
                                    disabled={this.props.profileSharedBy !== null}
                                    style={{ backgroundColor: languageColor }}
                                    onClick={() => removeLanguage(languageKey)}
                                    title={t('Delete query')}
                                >
                                    <FontAwesomeIcon
                                        color='white'
                                        icon={faTrash}
                                    />
                                </Button>
                            }
                            
                            </div>
                        </div>

                        {queryObj.standardQuery.spamCheck && this.props.renderSpamFailed(queryObj.standardQuery.spamCheck)}

                        { showTree[languageKey] 
                        ?   <span className={styles.textCenter}>
                                <QueryVisualization
                                    // data={this.props.queryTree[languageKey]}
                                    queryId={queryObj && queryObj.standardQuery && queryObj.standardQuery.id}
                                    onAfterTreeFetch={onAfterTreeFetch}
                                    query={query}
                                    languages={[languageKey]}
                                    onLmuStatusChange={this.props.onLmuStatusChange}
                                />
                            </span>
                        :null
                        }
                        
                        <AutoComplete
                        invalid={queryInvalid ? true : false}
                        onSyntaxChecked={onSyntaxChecked}
                        checkQuerySyntax={!showTree[languageKey]}
                        disabled={isDisabled || this.props.profileSharedBy !== null}
                        labelDisabled={isDisabled}
                        value={query}
                        onChange={event => {
                            setQueryInvalid(undefined, languageKey, 0);
                            onStandardQueryChange(event, languageKey, showTree[languageKey])
                        }}
                        label={t('Query')}
                        labelAppend={queryInvalid}
                        isArea isRequired
                        onSelection={val =>
                            onAutoCompleteStandardQuery(
                                query,
                                val,
                                (event)=>{ onStandardQueryChange(event, languageKey, showTree[languageKey]) }
                            )
                        }
                        onBlur={() => { 
                            if(this.props.onBlur) this.props.onBlur(); 
                            const sanitizedQuery = sanitizeQuery(query);
                            onStandardQueryChange({target:{value:sanitizedQuery}}, languageKey, showTree[languageKey])
                        }}
                        onFocus={(wasEmptyOnFocus) => { if(this.props.onFocus) this.props.onFocus(wasEmptyOnFocus); }}
                    />
                    </div>
                </div>
            </section>
        );
    }

    render() {
        return(
            this.renderStandardQueryInput() || null
        );
    }
}

const mapStateToProps = state => ({
    theme: state.theme,
});
export default connect(mapStateToProps,{ /* insert redux actions here */ })(withTranslation()(Query));