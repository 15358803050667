import { GET_ACCOUNT_SETTINGS, UPDATE_ACCOUNT_SETTINGS } from '../types/actionTypes.accountSettings';
import _ from 'lodash';


const accountSettingsReducers = (state = {data:[]}, action) => {
    switch (action.type) {
        case UPDATE_ACCOUNT_SETTINGS:
            state = _.cloneDeep(state);
            state.data = action.data || [];
            return state;
        case GET_ACCOUNT_SETTINGS:
            state = _.cloneDeep(state);
            state.data = action.data || [];
            return state;
        default:
            return state;
    }
};

export default accountSettingsReducers;