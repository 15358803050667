import { useSelector } from "react-redux";
import SearchAgentsTable from "./SearchAgentsTable";
import { getSimpleProfiles } from "src/redux/selectors/profileGroups.selectors";

function SearchAgents() {
    const profiles = useSelector((state) => getSimpleProfiles(state, false));
    let items = profiles.map((p: any) => ({ ...p, query: '+bicycle+bianchi',/*p.query*/ }));

    // const mocked = [
    //         { title: 'BMW', query: '+car+bmw',},
    //         { favorite: <Star/>, title: 'audi', TitleEdit: true, query: '+car+audi', },
    //         { favorite: <Star active={true}/>, title: 'Bianchi',titleEdit: false, query: '+bicycle+bianchi',  },
    //         { favorite: <Star/>, title: 'Long query',TitleEdit: false, query: 'some content here and its a showcase for+this+is+a very+long+que ...' },
    //         // { Title: <TextInput value={' '} disabled={true} onChange={() => {}} /> },
    //     ]

    return (
        <SearchAgentsTable
            items={items}
        />
    )
}

export default SearchAgents;