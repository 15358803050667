import {APISettings} from "./APISettings";

class APISettingsAcademy extends APISettings{
    
    constructor(){
        super();
        this.baseURL = `/api/settings/global/academy/`;
    }
}

export default new APISettingsAcademy();