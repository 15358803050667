import React from 'react';
// import styles from './GroupEdit.module.css';
import { withTranslation } from 'react-i18next';
import DeleteRow from '../../basicElements/DeleteRow/DeleteRow';
import { connect } from 'react-redux';
import TextInput from '../../basicElements/TextInput/TextInput';
import FormFooter from '../../basicElements/FormFooter/FormFooter';
import detailLayout from '../../basicStyles/detailLayout.module.scss';
import PropTypes from 'prop-types';

class GroupEdit extends React.Component {
    static propTypes = {
        group: PropTypes.object,
        onGoBack: PropTypes.func,
        updateGroup: PropTypes.func,
        deletedGroup : PropTypes.func,
    }
    constructor(props) {
        super(props);
        this.state = {
            title: undefined,
            edited: false,
            saveSuccessful: false,
        };
    }

    validate() {
        this.setState(state => {
            let edited = true;
            if (state.title.length === 0) edited = false;
            return { edited }; 
        });
    }

    componentDidMount() {
        if (this.props.group.title) {
            this.setState({ title: this.props.group.title });
        }
    }

    deleteGroup = async() => {
        await this.props.deleteGroup(this.props.group.id);
        this.props.onGoBack(false);   // do not fetch group details when going back (will not exist because it was deleted)
    };

    saveGroup = async () => {
        const save = await this.props.updateGroup(this.props.group.id, { title: this.state.title });
        const saveSuccessful = (save.rc === 0);
        this.setState({ saveSuccessful });
    }

    onTextfieldChange(event, key){
        const val = event.target.value;
        this.setState({ title: val });
        this.validate();
    }

    render() {
        const t = this.props.t || (k => k);

        return (
            <div className={detailLayout.wrapper}>
                <article className={detailLayout.view}>
                    <TextInput
                            value={this.state.title}
                            onChange={event => this.onTextfieldChange(event, 'title')}
                            label={t('Title')}
                            placeholder={t('Enter Title')}
                            isRequired
                    />
                    <DeleteRow
                        labelText = { t('Remove Group') }
                        confirmationText = { t('Really delete group?') }
                        onClick = { this.deleteGroup }
                        deletePopup={true}
                    />
                    <FormFooter
                            onGoBack={this.props.onGoBack}
                            onAction={this.saveGroup}
                            actionDisabled={!this.state.edited}
                            actionLabel={
                                t('Save Changes')
                            }
                            success={ this.state.saveSuccessful
                                ? t('Successfully saved') 
                                : null }
                            fail={ this.state.saveFailed
                                ? t('Could not save') 
                                : null }
                    />
                </article>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        theme: state.theme
    }
}

export default withTranslation()(connect(mapStateToProps, {  })(GroupEdit));