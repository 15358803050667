import APISettingsTourMEF from "src/API/APISettingsTourMEF";
import { DynamicConfigTypes } from "../dynamicConfig.types";
import { getSlideMasters } from "src/Board/Workspace/Pptxgen/PptxLayoutMentionFindr";
import defaultWorkspaces from 'src/Board/defaultWorkspaces/mentionFindr';
import { getTourSteps } from "src/Tour/tours/mentionFindrTourSteps";
import MfSettings from "src/Board/Settings/MfSettings/MfSettings";
import { cookieConsentConfig } from "src/CookieConsent/Cookieconsent";
import ErrorBannerMF from "src/Widgets/common/basicElements/Banner/ErrorBannerMF";

const config:DynamicConfigTypes.Config = {
    pptxFilename: 'MEF_report.pptx',
    pageTitle: "mentionFindr",
    pageTitlePrefix: '',
    workspacePageTitlePrefix: 'mentionFindr - ',
    matomoId: 10,
    allowEditBoard: false,
    allowEmailSignup: true,
    getSlideMasters: getSlideMasters,
    enableChat: false,
    defaultWorkspaces: defaultWorkspaces,
    allowAcademy: false,
    APISettingsTour: APISettingsTourMEF,
    getTourSteps: getTourSteps,
    favicons: [
        { rel: 'icon', size: '32x32', href: `${process.env.PUBLIC_URL}/favicon-mf-100x100.png` },
        { rel: 'icon', size: '192x192', href: `${process.env.PUBLIC_URL}/favicon-mf-100x100.png` },
        { rel: 'shortcut icon', href: `${process.env.PUBLIC_URL}/favicon-mf.ico` }
    ],
    settingsComponent: MfSettings,
    useSubscriptions: true,
    cookieConsentConfig: cookieConsentConfig,
    errorBannerComponent: ErrorBannerMF
}

export default config;