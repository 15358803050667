import React from 'react';
import { withTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, LabelList } from 'recharts';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getColors } from '../helpers';

const CustomizedAxisTick = props => {
    const { x, y, payload } = props
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text dy={16}
            textAnchor='end'
            transform="rotate(-45)"
            fill='#666'
            style={{
                fontSize: props.fontSize,
            }}>
                {payload.value}
            </text>
      </g>
    )
}

class StackedBar extends React.Component {
    static propTypes = {
        stacked: PropTypes.bool,
        percent: PropTypes.bool,
        isInteractive: PropTypes.bool, // relevant e.g. for exporting
    }
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    calcPercent(data) {
        let rv = _.cloneDeep(data); // TODO improvement: do not modify original to prevent copy of data ? 
        rv = rv.map(d => {
            const sum = Object.keys(d.data).reduce((sum, key) => sum + d.data[key], 0);
            Object.keys(d.data).map((key) => {
                d.data[key] = (d.data[key] / sum * 100).toFixed(0);
                return undefined;
            })
            return d;
        })
        return rv;
    }

    componentDidUpdate(prevProps) {
        const { percent, data } = this.props;
        if (!_.isEqual(prevProps.data, data)) {
            const d = percent ? this.calcPercent(data) : data;
            this.setState({ data: d });
        }
    }

    componentDidMount() {
        const { percent, data } = this.props;
        const d = percent ? this.calcPercent(data) : data;
        this.setState({ data: d });
    }

    formatter = (isPercent, data) => (
        (value, key) => {
            let rv = data && data.legend ? [value, data.legend[key]] : value;
            return isPercent ? `${rv}%` : rv;
        }
    )
    render() {
        const { 
            stacked,
            xLabel, 
            yLabel, 
            percent, 
            isInteractive,
            // showValues,
        } = this.props;
        const fontSize = this.props.fontSize > 40 ? 40 : this.props.fontSize;
        let {data} = this.state;
        const stackId = stacked !== true ? undefined : "1";
        const yLabelObj = yLabel ? {
            value: yLabel,
            angle: -90,
            position: 'insideLeft',
            offset: -fontSize * 2 + 20,
            fontSize,
        } : undefined;
        let bars = {};
        data.map(d => Object.keys(d.data).map(
            k => { bars[k] = null; return undefined; })
        );
        const colors = getColors(Object.keys(bars));

        const xLabelOffset= -fontSize * 5;
        
        return (
            <div style={{width:"100%", height:"99%", direction:'ltr'}}>
            <ResponsiveContainer debounce={200} ref={(r)=>{ if(this.props.onRefChange !== undefined) this.props.onRefChange(r); }}>
                <BarChart
                    data={data}
                    margin={{
                        top: 34,
                        right: 30,
                        left: yLabelObj.offset * -1 + 25,
                        bottom: fontSize ? fontSize*3.5 + Math.abs(xLabelOffset)-fontSize*3 : 35
                    }}
                    stackOffset={percent ? "expand" : undefined}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" padding={{left:20}} tickMargin={fontSize-10}
                    tick={<CustomizedAxisTick fontSize={fontSize}/>}
                    >
                        { xLabel && <Label fontSize={fontSize} value={xLabel} offset={xLabelOffset} position="insideBottom" /> }
                    </XAxis>
                    <YAxis fontSize={fontSize} tickFormatter={percent ? (v)=>(`${v*100}%`) : undefined} label={yLabelObj}/>
                    <Tooltip formatter={this.formatter(percent, data ? data[0] : undefined)} />
                    {!this.props.hideLegend && <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="top"
                        wrapperStyle={{
                            marginBlockStart: '-2rem',
                            fontSize: this.props.fontSize
                        }}
                        iconSize={this.props.fontSize}
                            formatter={(value, entry, index) => {
                                return data && data[index] && data[index].legend ? data[index].legend[value] : value;
                            }}
                    />}
                    {_.map(Object.keys(bars), (key,i) => {
                        return (
                            <Bar
                                dataKey={"data["+key+"]"}
                                key={key}
                                name={key}
                                stackId={stackId}
                                fill={colors[key][0]}
                                animationDuration={300}
                            >
                                {isInteractive === false ?
                                    <LabelList
                                        formatter={this.formatter(percent)}
                                        dataKey={"data["+key+"]"}
                                        style={{
                                            fontSize,
                                            fill: colors[key][1],
                                        }}
                                    />
                                :null}
                            </Bar>
                        )
                    })};
                </BarChart>
            </ResponsiveContainer>
            </div>
        );
    }
}
export default withTranslation()(StackedBar);