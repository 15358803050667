import {
    CHECKBOX_FILTER_VALUES,
    CREATE_STATUS,
    DELETE_STATUS,
    DUPLICATE_NAME_SET,
    EDIT_VIEW_TOGGLE,
    FILTERBOX_INDEX,
    FILTERED_CHANNELS,
    HEADERS_LENGTH,
    POPUP_MESSAGE,
    SAVE_STATUS,
    SEARCHED_CHANNELS,
    SEARCH_INPUT,
    SELECTED_CHANNELS,
    SELECTED_CHANNEL_IDS,
    SELECTED_FILTER,
    SELECTED_GROUP,
    SELECTED_SORT_TYPE,
    SET_CHECKBOX_VALUES,
    SET_DESELECT_CHANNELS,
    SET_FILTER_COLOR_VALUES,
    SHOW_ALL_CHANNELS,
    SORTED_CHANNELS,
    TOGGLE_FILTERBOX,
    TOGGLE_SELECTED_BUTTON_STATUS,
    UNSELECTED_CHANNELS,
    UNSELECTED_GROUP
} from '../types/actionTypes.channelGroupsWidget';

export const selectGroup = (id, title, groups, channels) => ({
    type: SELECTED_GROUP,
    payload: {
        id,
        title,
        groups,
        channels
    }
});

export const setSelectedChannelIds = (ids) => ({
    type: SELECTED_CHANNEL_IDS,
    payload: {
        ids
    }
})

export const duplicateNameSet = (name) => ({
    type: DUPLICATE_NAME_SET,
    payload: {
        name: name
    }
})

export const setIsChannelDeselected = (isDeselected) => ({
    type: SET_DESELECT_CHANNELS,
    payload: {
        isDeselected
    }
})

export const unSelectGroup = () => ({
    type: UNSELECTED_GROUP,
})

export const editViewToggle = () => ({
    type: EDIT_VIEW_TOGGLE
});

export const popupMessage = (message) => ({
    type: POPUP_MESSAGE,
    payload: {
        message
    }
});

export const setFilterColorValues = (values) => ({
    type: SET_FILTER_COLOR_VALUES,
    payload: {
        values
    }
})

export const saveStatus = (res) => ({
    type: SAVE_STATUS,
    payload: {
        res
    }
});

export const createStatus = (res) => ({
    type: CREATE_STATUS,
    payload: {
        res
    }
});

export const deleteStatus = (res) => ({
    type: DELETE_STATUS,
    payload: {
        res
    }
});

export const setHeadersLength = (count) => ({
    type: HEADERS_LENGTH,
    payload: {
        count
    }
})

export const setFilterIndex = (index) => ({
    type: FILTERBOX_INDEX,
    payload: {
        index
    }
})

export function toggleSelectedButtonStatus(status) {
    return {
      type: TOGGLE_SELECTED_BUTTON_STATUS,
      payload: status
    }
  }

export const showAllChannels = (status) => ({
    type: SHOW_ALL_CHANNELS,
    payload: {
        status
    }
})

export const selectChannels = (selectedChannels) => ({
    type: SELECTED_CHANNELS,
    payload: {
        selectedChannels
    }
})

export const sortChannels = (filteredChannels) => ({
    type: SORTED_CHANNELS,
    payload: {
        filteredChannels
    }
})

export const unselectChannels = () => ({
    type: UNSELECTED_CHANNELS
})

export const filterChannels  = (filteredChannels) => ({
    type: FILTERED_CHANNELS,
    payload: {
        filteredChannels
    }
})

export const setFilterValues = (values) => ({
    type: SET_CHECKBOX_VALUES,
    payload: {
        values
    }
})

export const setCheckboxFilters = (filters) => ({
    type: CHECKBOX_FILTER_VALUES,
    payload: {
        filters
    }
})

export const searchChannels = (searchedChannels) => ({
    type: SEARCHED_CHANNELS,
    payload: {
        searchedChannels
    }
})

export const selectFilter = (selectedFilter) => ({
    type: SELECTED_FILTER,
    payload: {
        selectedFilter
    }
})

export const toggleFilter = (booleanValue) => ({
    type: TOGGLE_FILTERBOX,
    payload: {
        booleanValue
    }
})

export const selectSortType = (filterBoxIndex, sortType) => ({
    type: SELECTED_SORT_TYPE,
    payload: {
        filterBoxIndex,
        sortType
    }
})

export const searchInputSet = (searchInput) => ({
    type: SEARCH_INPUT,
    payload: {
        searchInput
    }

})