import { useTranslation } from "react-i18next";
import ContactUs from "src/ContactUs/ContactUs";
import BackButton from "src/Widgets/common/basicElements/BackButton/BackButton";

function UserExpired({
    onGoBack
}) {
    const { t } = useTranslation();
    
    return (
        <div>
            <h2>{t('user_expired')}</h2>
            <p>{t('user_expired_details')}</p>
            <ContactUs />
            {onGoBack &&
                <BackButton
                    onClick={onGoBack}
                />
            }
        </div>
    )
}

export default UserExpired;