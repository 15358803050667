import { useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import styles from './ReactTable.module.scss';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getWidgetState } from '../helpers';

const ReactTable = (props) => {
    const [data, setData] = useState({ columns: props.columns, rows: props.data } || []);
    const profileGroups = useSelector((state) => state.profileGroups.data);
    const workspaces = useSelector((state) => state.workspaces);
    const board = useSelector((state) => state.board);

    const [mentionResultsLink, setMentionResultsLink] = useState('');

    // const [columns, setColumns] = useState(props.columns || []);
    // TODO: get useMemo() working
    // const data = React.useMemo(
    //     () => props.data,
    //     []
    // );
    // const columns = React.useMemo(
    //     () => props.columns,
    //     []
    // );

    // TODO: do not show empty columns
    const widgetState = getWidgetState(workspaces, board, props.widgetId);
    const selectedDates =
        workspaces[board.currentWorkspaceIndex]?.state.settings || widgetState?.settings;

    const getValueByFilterType = (type) => {
        const settings =
            workspaces[board.currentWorkspaceIndex]?.state.settings &&
            Object.keys(workspaces[board.currentWorkspaceIndex]?.state.settings).length >
                0
                ? workspaces[board.currentWorkspaceIndex].state.settings
                : widgetState?.settings;

        if (type === 'unit' && settings !== undefined) {
            return settings.date?.unit?.value;
        } else if (type === 'count' && settings !== undefined) {
            return settings.date?.number;
        }

        return null;
    };

    const dateByUnit = {
        unit: getValueByFilterType('unit'),
        count: getValueByFilterType('count')
    };

    const dateByRangeSelect = {
        fromDate: selectedDates ? selectedDates?.date?.[0] : null,
        toDate: selectedDates ? selectedDates?.date?.[1] : null
    };

    const dateConverter = (date) => {
        const year = date?.getFullYear();
        const month = String(date?.getMonth() + 1).padStart(2, '0');
        const day = String(date?.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getDateRange = (type, count) => {
        let toDate = new Date();
        toDate.setDate(toDate.getDate() - 1);
        toDate = toDate.toISOString().split('T')[0];
        let fromDate = '';

        if (type === 'days') {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - count);
            fromDate = currentDate.toISOString().split('T')[0];
        } else if (type === 'weeks') {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - count * 7);
            fromDate = currentDate.toISOString().split('T')[0];
        } else if (type === 'months') {
            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() - 1);
            fromDate = currentDate.toISOString().split('T')[0];
        }

        return [fromDate, toDate];
    };

    const dateRangeFromUnits =
        dateByUnit && getDateRange(dateByUnit.unit, dateByUnit.count);
    const defaultSevenDays = getDateRange('days', 7);
    const dateRangeByUnit = {
        fromDate: dateByUnit && dateRangeFromUnits[0],
        toDate: dateByUnit && dateRangeFromUnits[1]
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns: data.columns,
            data: data.rows,
            sortTypes: {
                alphanumeric: useMemo(() => (rowA, rowB, columnName) => {
                    // setting to null if not defined to ensure that all undefined values get sorted to begin/end
                    const a = rowA.values[columnName] || null;
                    const b = rowB.values[columnName] || null;

                    if (!isNaN(a) && !isNaN(b)) {
                        return a - b;
                    } else if (typeof a === 'string' && typeof b === 'string') {
                        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
                    } else {
                        return a > b ? 1 : -1;
                    }
                })
            }
        },
        useSortBy
    );

    const forEachCol = (columns, funct = () => {}) => {
        // future improvement: .columns recursive ?
        return columns.reduce((sum, c) => {
            if (c.columns) {
                c.columns.map((col) => funct(col));
                return sum.concat(c.columns);
            } else {
                funct(c);
                return sum;
            }
        }, []);
    };

    const getClickedProfilesFromGroups = async (groupName, type) => {
        const clipsType =
            workspaces[board.currentWorkspaceIndex].state.settings.clipsType ||
            widgetState?.settings.clipsType ||
            'recent';

        const mentionType = type;
        const selectedProfile = await profileGroups?.filter((group) => {
            return group.title === groupName;
        });
        const profileIds = await selectedProfile.flatMap((pro) =>
            pro.profiles.map((profile) =>
                props.isCreatedBy() === 'search' && clipsType !== 'edited'
                    ? profile.id + 't'
                    : props.isCreatedBy() === 'search' && clipsType === 'edited'
                    ? profile.id + 'e'
                    : props.isCreatedBy() === 'edited' || clipsType === 'edited'
                    ? profile.id + 'e'
                    : profile.id
            )
        );

        const linkUrl = `/embed/mentionResults/${profileIds}/${
            !dateRangeByUnit.fromDate.length
                ? dateConverter(dateByRangeSelect.fromDate)
                : dateRangeByUnit.fromDate
        }/${
            !dateRangeByUnit.fromDate.length
                ? dateConverter(dateByRangeSelect.toDate)
                : dateRangeByUnit.toDate
        }/types/${mentionType === 'Social TV' ? 'SocialTV' : mentionType}?${clipsType}`;
        const linkWithoutDatesUrl = `/embed/mentionResults/${profileIds}/${defaultSevenDays[0]}/${defaultSevenDays[1]}/types/${mentionType}?${clipsType}`;
        dateByRangeSelect.fromDate || dateRangeByUnit?.fromDate
            ? setMentionResultsLink(linkUrl)
            : setMentionResultsLink(linkWithoutDatesUrl);
    };

    useEffect(() => {
        let columns = props.columns;
        let data = props.data;
        if (props.colSum) {
            ({ columns, data } = calcColSums(columns, data));
        }
        ({ data } = calcColumns(columns, data));
        if (props.removeEmpty !== false) {
            ({ columns, data } = removeEmptyColumns(columns, data));
        }
        ({ columns, data } = calcColSums(columns, data));
        setData({ columns: columns, rows: data });
    }, [props.data, props.columns]);

    const removeEmptyColumns = (columns, data) => {
        let rv = _.cloneDeep(columns);
        data.map((d) => {
            forEachCol(rv, (col) => {
                const val = getValByAccessor(d, col.accessor);
                if (val) {
                    col.hasData = true;
                }
            });
            return undefined;
        });
        rv.reduce((sum, col) => {
            // future improvement: .columns recursive ?
            if (col.columns) {
                col.columns = col.columns.reduce((s, subCol) => {
                    if (subCol.hasData) {
                        col.hasData = true;
                        s.push(subCol);
                    }
                    return s;
                }, []);
            }
            if (col.hasData) {
                sum.push(col);
            }
            return sum;
        }, []);
        return { columns: rv, data };
    };

    const calcColumns = (columns, data) => {
        columns = [...columns];
        data = [...data].map((d) => {
            columns.map((c) => {
                if (c.columns) {
                    c.columns = c.columns.map((col) => {
                        if (col.calc) {
                            setValByAccessor(
                                d,
                                col.accessor,
                                calcColumn(
                                    col.calc.type,
                                    col.calc.accessor,
                                    getValByAccessor(d, col.calc.accessor),
                                    columns
                                )
                            );
                        }
                        return col;
                    });
                } else {
                    if (c.calc) {
                        setValByAccessor(
                            d,
                            c.calc.accessor,
                            calcColumn(
                                c.calc.type,
                                c.calc.accessor,
                                getValByAccessor(d, c.calc.accessor),
                                columns
                            )
                        );
                    }
                }
                return c;
            });
            return d;
        });
        return { data };
    };

    const getValByAccessor = (object, accessor) => {
        let arr = accessor.split('.');
        while (arr.length && (object = object[arr.shift()]));
        return object;
    };
    const setValByAccessor = (object, accessor, val) => {
        let arr = accessor.split('.');
        while (arr.length - 1 && object !== undefined) {
            object = object[arr.shift()];
        }
        if (object) {
            object[arr[0]] = val;
        }
    };

    const calcColSums = (columns, data) => {
        // calculate column sums
        columns = [...columns];
        data = [...data];
        forEachCol(columns, (col) => {
            col.sum = 0;
        });
        data.map((d) => {
            forEachCol(columns, (col) => {
                const val = getValByAccessor(d, col.accessor);
                if (!isNaN(val)) {
                    col.sum += val;
                }
            });
            return undefined;
        });
        rows.map((row) => {
            row.cells.map((cell) => {
                if (!isNaN(cell.value)) {
                    cell.column.sum =
                        cell.column.sum !== undefined
                            ? cell.column.sum + cell.value
                            : cell.value;
                }
                return undefined;
            });
            return undefined;
        });
        return { columns, data };
    };

    const renderColSum = () => {
        // render column sums (first col shows 'total', will only show sums for lowest level of headergroups)
        const headerGroup = headerGroups[headerGroups.length - 1];
        return (
            <tfoot className={styles.tfoot}>
                <tr {...headerGroup.getHeaderGroupProps()}>
                    <td className={styles.footerCell}>Total</td>
                    {headerGroup.headers.map((column, index) => {
                        if (index === 0) return undefined;
                        return (
                            <td
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                className={styles.footerCell}
                            >
                                {renderCell({
                                    value: column.sum,
                                    column
                                })}
                            </td>
                        );
                    })}
                </tr>
            </tfoot>
        );
    };

    const calcColumn = (calcType, accessor, baseValue, columns) => {
        switch (calcType) {
            case '%':
                // NOTE: using columns from state here because sum in headers of headerGroups is not correct here for some reason
                const header = _.find(forEachCol(columns), { accessor });
                return baseValue && header.sum
                    ? (baseValue / header.sum) * 100
                    : undefined;
            default:
                console.log(`Type '${calcType}' not implemented.`);
        }
    };

    const renderCell = (cell) => {
        if (
            (cell.value === undefined || cell.value === null) &&
            cell.column.Header !== '%'
        ) {
            return '0';
        } else if (
            (cell.value === undefined || cell.value === null) &&
            cell.column.Header === '%'
        ) {
            return '0%';
        }
        const digits = Math.pow(10, cell.column.digits);
        const val = digits ? Math.round(cell.value * digits) / digits : cell.value;
        const unit = cell.column.unit;
        return unit && val ? `${val}${unit}` : val;
    };

    return (
        <table ref={props.tableRef} {...getTableProps()} className={styles.table}>
            <thead className={styles.thead}>
                {headerGroups.map((headerGroup) => {
                    return (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                let sortTitle = '';
                                let thStyles = [styles.headerCell];
                                if (!column.columns) {
                                    sortTitle = `${props.t('Sort by')} ${
                                        column.Header
                                    }, ${
                                        column.isSorted
                                            ? column.isSortedDesc
                                                ? props.t('remove sorting')
                                                : props.t('descending')
                                            : props.t('ascending')
                                    }`;
                                    thStyles.push(styles.clickable);
                                }
                                const renderedHeader = column.render('Header');

                                return (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps({
                                                title: sortTitle
                                            })
                                        )}
                                        style={{}}
                                        className={thStyles.join(' ')}
                                    >
                                        {typeof renderedHeader === 'string' &&
                                            props.t(renderedHeader)}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                );
                            })}
                        </tr>
                    );
                })}
            </thead>

            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} className={styles.bodyRow}>
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        className={
                                            cell.column.Header === 'Mentions' &&
                                            renderCell(cell) !== '0'
                                                ? styles.bodyCellClickable
                                                : styles.bodyCell
                                        }
                                    >
                                        {cell.column.Header === 'Mentions' &&
                                        renderCell(cell) !== '0' ? (
                                            <Link
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                to={mentionResultsLink}
                                                data-testid="link"
                                            >
                                                <div
                                                    onMouseOver={async () =>
                                                        await getClickedProfilesFromGroups(
                                                            row.original.group,
                                                            cell.column.parent.Header
                                                        )
                                                    }
                                                >
                                                    {renderCell(cell)}
                                                </div>
                                            </Link>
                                        ) : (
                                            <div data-testid="cell">
                                                {renderCell(cell)}
                                            </div>
                                        )}
                                    </td>
                                );
                            })}
                            {row.original.progress && row.original.progress < 100 ? (
                                <>
                                    <td className={styles.progressOverlay}>
                                        Loading ...
                                    </td>
                                    <td
                                        className={styles.progress}
                                        style={{
                                            width: `${row.original.progress}%`
                                        }}
                                    />
                                </>
                            ) : undefined}
                        </tr>
                    );
                })}
            </tbody>
            {props.colSum && renderColSum()}
        </table>
    );
};

export default withTranslation()(ReactTable);
