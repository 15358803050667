import { getSlideMasters } from "src/Board/Workspace/Pptxgen/PptxLayoutDart";
import { DynamicConfigTypes } from "../dynamicConfig.types";
import defaultWorkspaces from 'src/Board/defaultWorkspaces/dart';
import APISettingsTour from "src/API/APISettingsTour";
import { getTourSteps } from "src/Tour/tours/dartTourSteps";
import Settings from "src/Board/Settings/Settings";
import ErrorBanner from "src/Widgets/common/basicElements/Banner/ErrorBanner";

const config:DynamicConfigTypes.Config = {
    pptxFilename: 'eMM_report.pptx',
    pageTitle: 'Dart',
    pageTitlePrefix: 'eMM - ',
    workspacePageTitlePrefix: 'eMM - ',
    matomoId: 2,
    allowEditBoard: true,
    allowEmailSignup: false,
    getSlideMasters: getSlideMasters,
    enableChat: true,
    defaultWorkspaces: defaultWorkspaces,
    allowAcademy: true,
    APISettingsTour: APISettingsTour,
    getTourSteps: getTourSteps,
    favicons: [
        { rel: 'icon', size: '32x32', href: `${process.env.PUBLIC_URL}/icon-320x320-100x100.png` },
        { rel: 'icon', size: '192x192', href: `${process.env.PUBLIC_URL}/icon-320x320-300x300.png` },
        { rel: 'shortcut icon', href: `${process.env.PUBLIC_URL}/favicon.ico` }
    ],
    settingsComponent: Settings,
    useSubscriptions: false,
    cookieConsentConfig: undefined,
    errorBannerComponent: ErrorBanner
}

export default config;