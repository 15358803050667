import React, { Component } from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import styles from './InfiniteScroll.module.scss';

/**
 * extremely simple infinite scrolling container,
 * does not do state management of items
 *
 * props
 *  - function loadMore: to call to load more elements
 *  - boolean hasMore: if loadMore should be called again
 *  - loadMoreOffset: % offset from "end of scroll" that will trigger load
 */

class InfiniteScroll extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };

        this.loadMore = this.loadMore.bind(this);
    }
    
    componentDidMount() {
        this.initialLoad();
        window.addEventListener('scroll', this.loadMore, true);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.loadMore);
    }
    
    componentDidUpdate(__, prevState) {
        if (prevState.isLoading) this.setState({ isLoading: false });
        this.initialLoad();
    }

    initialLoad(){  // fill content until scrollbar appears
        if(this.refs.scroll && this.props.hasMore && this.refs.scroll.clientHeight === this.refs.scroll.scrollHeight){
            this.loadMore();
        };
    }

    loadMore() {
        if (this.state.isLoading || !this.props.hasMore) return;
        let loadMoreOffset;
        const scroll = this.refs.scroll;
        if(scroll){
            loadMoreOffset = this.props.loadMoreOffset ? (this.props.loadMoreOffset*scroll.scrollHeight/100) : 0;
        }
        if (scroll && scroll.scrollTop + loadMoreOffset + 1 >= scroll.scrollHeight - scroll.offsetHeight) {
            this.props.loadMore();
            this.setState({ isLoading: true });
        }
    }

    render() {
        const { isLoading } = this.state;
        const {itemName} = this.props;
        return (
            <section
                ref="scroll"
                className={`${styles.wrapper} ${this.props.className}`}
            >
                {/* the to be displayed items */}
                {this.props.children}
                {isLoading && this.props.children.length > 0 ?
                    <div className={styles.loadingMoreWrapper}>
                        <LoadingSpinner
                            prependText={`Loading more` + (itemName ? ` ${itemName}` : ``)}
                            size='1rem'
                            fullSize={false}
                        />
                    </div>
                :null}
            </section>
        );
    }
}

export default InfiniteScroll;
