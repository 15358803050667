import { SET, CLEAR } from '../types/actionTypes.signup';

export const setSignupProps = data =>{
    return ({
        type: SET
   , data: data
  })};

export const clearSignupProps = () =>
  ({ type: CLEAR
  });