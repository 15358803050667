import React, { Component } from 'react';
import styles from './GroupCardItem.module.css';
import Icon from '../../../basicElements/Icon/Icon';
import classNames from 'classnames';

/**
 * - title
 * - onClick
 */
export class GroupCardItem extends Component {
    render() {
        let itemStyles = [];
        itemStyles.push(styles['groupcard-item']);
        if (this.props.secondary) itemStyles.push(styles['groupcard-item-secondary']);
        if (this.props.disabled) itemStyles.push(styles['groupcard-item-disabled']);
        if (this.props.highlighted) itemStyles.push(styles['groupcard-item-highlighted']);
        if (this.props.onClick !== undefined) itemStyles.push(styles['groupcard-item-clickable']);

        return (
            <div
                className={classNames(itemStyles)}
                onClick={this.props.onClick}
                data-testid="groupcarditem"
            >
                <Icon
                    icon={this.props.icon}
                    useGravatar={this.props.useGravatar}
                    title={this.props.title || ''}
                    disabled={this.props.disabled}                   
                    size={40}
                />
                <div className={styles['groupcard-item-content']}>{this.props.title}</div>
            </div>
        );
    }
}

export default GroupCardItem;
