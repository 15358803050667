import React from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ToggleEditButton.module.scss';

// FontAwesome Base
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faSave, faBan } from '@fortawesome/free-solid-svg-icons';
import Button from 'src/Widgets/common/basicElements/Button/Button';

// fontawesome icons
library.add(faEdit, faSave, faBan);

export function ToggleEditButton(props) {
    if (props.editable) {
        return (
            <div className={props.isNavCollapsed ? styles.lockButtonWrapCollapsed : styles.lockButtonWrap}>
                <Button
                    type='saveBoard'
                    data-testid="saveBoard-button"
                    btnClass={props.isNavCollapsed ? styles.lockButtonCollapsed : styles.lockButton}
                    onClick={() => props.onClick()}
                >
                    <FontAwesomeIcon icon="save" />
                    {' '}{props.t('Save Board')}
                </Button>

                <Button
                    type='cancelBoard'
                    btnClass={props.isNavCollapsed ? styles.lockButtonCollapsed : styles.lockButton}
                    onClick={() => props.onClick(true)}
                >
                    <FontAwesomeIcon icon="ban" />
                    {' '}{props.t('Cancel')}
                </Button>
            </div>
        );
    }

    return (
        <Button
            type='editBoard'
            onClick={props.onClick}
            data-tut="tour__editBoard"
            data-testid="editBoard-button"
        >
            <FontAwesomeIcon title={props.t('Edit Board')} icon="edit" size={props.isNavCollapsed ? 'sm' : '1x'}/>
           {!props.isNavCollapsed && <span className={styles.text}>{props.t('Edit Board')}</span>}
        </Button>
    );
}

export default withTranslation()(ToggleEditButton);