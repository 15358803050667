import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { EPGRowProps } from './EPGRow.types';
import styles from '../EPGContent/EPGContent.module.scss'


const EPGRow: FC<EPGRowProps> = ({
    genre,
    titleProp,
    onUnfold,
    description,
    onClick,
    date,
    start,
    end,
    isOpen,
    availability
}) => {
    const { t } = useTranslation();
    const icon = isOpen ? faChevronUp : faChevronDown;

    let title;
    switch (availability?.statusCode) {
        case 'P':
            title = t('goto_content_available_partial');
            break;
        case 'N':
            title = t('goto_content_not_available');
            break;
        default:
            title = t('Click to view media');
            break;
    }

    let combinedClassName = `${styles.epg}`;

    if (availability?.status === 'available') {
        combinedClassName += ` ${styles.epgAvailable}`;
      } else if (availability?.status === 'partially-available') {
        combinedClassName += ` ${styles.epgPartiallyAvailable}`;
      } else {
        combinedClassName += ` ${styles.epgUnavailable}`;
      }

    return (
        <div className={`${styles.epgRow} ${combinedClassName}`}>
            {/* <div className={styles.epgNoshrink}>{date}</div> */}
            <div className={styles.epgNoshrink}>{start}</div>
            <div className={styles.epgNoshrink}>{end}</div>
            <div className={styles.epgRowTitleCard}>
                <div className={styles.epgRowTitle}>
                    <button
                        className={styles.epgRowClickableTitle}
                        style={{
                            cursor: onClick ? 'pointer' : 'default'
                        }}
                        onClick={onClick}
                        title={title}
                    >
                        {titleProp}
                    </button>
                    <div>
                        {genre ? <span className={styles.epgGenre}>{genre}</span> : null}
                        <FontAwesomeIcon
                            icon={icon}
                            onClick={onUnfold}
                            className={styles.epgUnfoldIcon}
                        />
                    </div>
                </div>
                {isOpen && (
                    <div className={`${styles.epgDescription} ${'animated fadeIn'}`}>{description}</div>
                )}
            </div>
        </div>
    );
};

export default EPGRow