import React from 'react';
import styles from './StyledTable.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// expects html <table> as child and adds css class for styling

class StyledTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const children = React.Children.toArray(this.props.children);
        return(
            React.cloneElement(children[0], {
              className: [children[0].props.className, styles.table].join(' ')  
            })
        );
    }
}

const mapStateToProps = state => ({
    //theme: state.theme,
});
export default connect(mapStateToProps,{ /* insert redux actions here */ })(withTranslation()(StyledTable));