import React from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import _ from 'lodash';

import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import '../../assets/animate.css';
import './Workspace.scss';
import classNames from 'classnames';
import randomColor from 'randomcolor';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// HOC
import { withTranslation } from 'react-i18next';
import delayUnmounting from '../../HOC/delayUnmounting';

import WidgetSelection from './WidgetSelection/WidgetSelection';
import {getAvailableWidgets} from './availableWidgets.tsx';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import {
    faArrowsAltV,
    faTimesCircle,
    faWindowRestore,
    faArrowsAlt,
    //faCog,
    faFilter,
    // faThumbtack,
    faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import config from 'src/config/config.js';
import WorkspaceSettings from 'src/Board/Workspace/WorkspaceSettings/WorkspaceSettings';
import CurrentWidgetSettings from 'src/Widgets/common/WidgetSettings/CurrentWidgetSettings';
import { getProfileGroups } from 'src/redux/actions/actions.profileGroups';
import { setWidgetStatus } from 'src/redux/actions/actions.workspaces';
import { setShowWidgetSelection } from 'src/redux/actions/actions.board';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import { getLastX } from 'src/Widgets/common/helpers';
// import { getStrMaxLength } from 'src/Widgets/common/helpers';
import Pptxgen from './Pptxgen/Pptxgen';
import ReportElement from 'src/Widgets/common/Reporting/ReportElement/ReportElement';
import ErrorBoundary from 'src/ErrorBoundary/ErrorBoundary';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class Workspace extends React.Component {
    breakpoints = { lg: config.mobileDesktopBreakpoint, sm: 0 };

    constructor(props) {
        super(props);

        let layouts = this.createBaseLayout(props.widgets);

        const widgetWithMaxId = _.maxBy(props.widgets, 'id');
        this.rowsInViewport = 6;
        this.gridContainerPadding = [15, 15];
        this.gridItemMargin = [15, 15];

        this.state = {
            loading: true,
            layouts: layouts,
            widgetCounter: props.widgets.length,
            widgetCounter: widgetWithMaxId !== undefined ? (widgetWithMaxId.id + 1) : 0,
        };

        this.onAddWidget = this.onAddWidget.bind(this);
        this.onRemoveWidget = this.onRemoveWidget.bind(this);
        this.showWidgetSelection = this.showWidgetSelection.bind(this);
        this.hideWidgetSelection = this.hideWidgetSelection.bind(this);
        this.onBackButton = this.onBackButton.bind(this);
        this.availableWidgets = getAvailableWidgets(this.props.me?.experimentalWidgetsSelectable, this.props.me?.allWidgets);
    }

    createBaseLayout(widgets) {
        if (!['development'].includes(process.env.REACT_APP_ENV)) {
            widgets = widgets.filter(w => this.props.me.allowedWidgets?.includes(w.type))
        }

        let layout = _.map(widgets, w => ({
            i: w.id + '',
            x: w.coordinates.x,
            y: w.coordinates.y,
            w: w.coordinates.w,
            h: w.coordinates.h
        }));

        // lg and sm because of breakpoints, for reasonable responsiveness
        // sm doesn't get its own layout however
        return { lg: layout, sm: layout };
    }

    async componentDidMount() {
        this.props.getProfileGroups();
        this.props.setCurrentWorkspace(this.props.id);

        window.onpopstate = this.onBackButton;
    }

    componentDidUpdate(prevProps) {
        let stateUpdate = {};
        if (prevProps.editable !== this.props.editable && this.props.editable === false) {
            stateUpdate = {
                ...stateUpdate,
                layouts: this.createBaseLayout(this.props.widgets)
            };
            this.props.setShowWidgetSelection(false);
        }
        if(!_.isEqual(prevProps.widgets, this.props.widgets)){
            stateUpdate = {
                ...stateUpdate,
                layouts: this.createBaseLayout(this.props.widgets),
            }
        }
        if (this.state.loading && this.props.profileGroups.status) {
            stateUpdate = {
                ...stateUpdate,
                loading: false,
            }
        }
        if(Object.keys(stateUpdate).length > 0)
            this.setState(stateUpdate);
        
        // fix react-grid-layout rtl issue, see: https://github.com/react-grid-layout/react-grid-layout/issues/453#issuecomment-1021149834
        if (!_.isEqual(this.props.i18n) || !_.isEqual(this.props.i18n.language)) {
            const gridLayoutContainer = document.querySelector(".react-grid-layout");
            const children = document.querySelectorAll(".react-grid-item");
            if (gridLayoutContainer) {
                gridLayoutContainer.setAttribute("dir", "ltr");
            }
            children.forEach((child) => child.setAttribute("dir", this.props.i18n.dir()));
        }
    }

    onLayoutChange(layout, layouts) {
        // small breakpoint gets assigned current layout
        // to achieve reasonable responsiveness / likeness of set layout for mobile-style layout
        // seems a bit contrived, I know, but it gets assigned like this, so not to overwrite the
        // base (lg) layout
        this.setState(state => {
            let newLayouts = { lg: layouts.lg, sm: layout };
            return { layouts: newLayouts };
        });

        // board state change

        // TODO: REMOVE DIRECT CHANGE - BIG NO-NO
        for (let i = 0; i < layouts.lg.length; i++) {
            let widget = _.find(this.props.widgets, {
                id: parseInt(layouts.lg[i].i)
            });
            if (widget) {
                widget.coordinates = {
                    x: layouts.lg[i].x,
                    y: layouts.lg[i].y,
                    w: layouts.lg[i].w,
                    h: layouts.lg[i].h
                };
            }
        }
    }

    onAddWidget(widgetType, widgetName) {
        this.setState(state => {
            let newLayout = _.concat(state.layouts.lg, {
                i: state.widgetCounter + '',
                x: 0,
                y: Infinity,
                w: 3,
                h: 3
            });

            let workspaceSettings;
            if(this.props.workspaces[this.props.id]){
                workspaceSettings = Object.keys(this.props.workspaces[this.props.id].state.settings);
            }

            let dbWidget = {
                id: state.widgetCounter,
                type: widgetType,
                name: widgetName,
                _local: {},
                state: {
                    showSettings: workspaceSettings && workspaceSettings.length > 0 ? false : true,
                    settings: {}
                },
                coordinates: { x: 0, y: Infinity, w: 3, h: 3 },
                // workspaceId: this.props.workspaceId,
            };

            // add default settings
            const widget = _.find(this.availableWidgets, { key: widgetType });
            if(widget.props && widget.props.settings){
                Object.keys(widget.props.settings).map(key=>{
                    const setting = widget.props.settings[key];
                    if(setting.default){
                        dbWidget.state.settings[key] = setting.default
                    } else {
                        switch(setting.type){
                            case 'daterange': 
                                dbWidget.state.settings[key] = getLastX({ unit: 'days', number: 7 }); 
                                break;
                        }
                    }
                    return undefined;
                });
            }

            this.props.addWidget(this.props.workspaceId, dbWidget);

            return {
                layouts: { lg: newLayout, sm: newLayout },
                widgetCounter: state.widgetCounter + 1
            };
        });
    }

    onRemoveWidget(widgetIndex) {
        this.props.removeWidget(this.props.id, widgetIndex);
        this.setState(state => {
            let newLayout = _.reject(state.layouts.lg, ['i', widgetIndex]);
            return { layouts: { lg: newLayout, sm: newLayout } };
        });
    }

    showWidgetSelection() {
        this.props.setShowWidgetSelection(true);
    }

    hideWidgetSelection() {
        this.props.setShowWidgetSelection(false);
    }

    setFullscreenId(id) {
        this.setState(s => {
            if (s.fullscreenId === id || id === undefined) {
                this.props.history.go(-1);
                return { fullscreenId: undefined }
            }
            else {
                this.props.history.push(this.props.location.pathname + "#fs");
                return { fullscreenId: id };
            }
        });
    }

    onBackButton(e) {
        if (this.state.fullscreenId !== undefined) {
            this.setState({fullscreenId: undefined});
        }
    }

    renderWorkspaceElement(layoutItem, workspaceSettings) {
        const widgetId = parseInt(layoutItem.i);
        const {t} = this.props;

        const selectedWidget = _.find(this.props.widgets, { id: widgetId });

        const DisplayWidget = selectedWidget
            ? _.find(this.availableWidgets, { key: selectedWidget.type })
            : null;
        
        // let waitLoading;
        // if (DisplayWidget && DisplayWidget.delayedLoading) {
        //     waitLoading = false;
        //     let delayedWidgets = this.props.widgets.filter(w => {
        //         return _.find(availableWidgets, { key: w.type, delayedLoading: true }) !== undefined;
        //     })
        //     const selectedWidgetIndex = _.findIndex(delayedWidgets, { id: widgetId });
        //     waitLoading = delayedWidgets.slice(0, selectedWidgetIndex).some(w => [undefined,null,'waitLoading'].includes(w._local.status));
        //     if (!waitLoading && delayedWidgets.every(w => w._local.status === 'done')) {
        //         delayedWidgets.map(w => this.props.setWidgetStatus(this.props.workspaceId,widgetId, null));
        //     }
        // }

        // back up if all else fails, shouldn't happen
        if (!DisplayWidget) return <div key={layoutItem.i} />;
        const DisplayWidgetComponent = DisplayWidget.component;

        const applicableWorkspaceSettings = Object.keys(workspaceSettings)
            .reduce( (sum, wKey) => {
                if( DisplayWidget.props && _.has(DisplayWidget.props.settings, wKey) ) {
                    sum[wKey] = workspaceSettings[wKey];
                }
                return sum;
            },{});

        return (
            <div
                className={classNames(
                    "workspace-element",
                    {'workspace-element-fullscreen': this.state.fullscreenId === widgetId}
                )}

                style={{
                    borderColor: randomColor({
                        luminosity: 'dark',
                        seed: DisplayWidget.name,
                        format: 'rgba',
                        alpha: 0.3
                    })
                }}
                key={layoutItem.i}
            >
                <div
                    className={classNames(
                        "widget-wrapper animated fadeIn",
                        {'widget-fullscreen': this.state.fullscreenId === widgetId}
                    )}

                    onKeyDown={ e => 
                        this.state.fullscreenId === widgetId 
                            && e.key === 'Escape' 
                            && this.setFullscreenId(undefined)
                    }
                    tabIndex="0"
                >
                    <span className="widget-header">{selectedWidget?.state?.settings?.widgetTitle || t(DisplayWidget.name)} 
                        
                            {DisplayWidget.info && <FontAwesomeIcon style={{paddingInlineStart:'.3rem'}} icon={faInfoCircle} title={DisplayWidget.info} />}
                        {
                            DisplayWidget.headerElements && DisplayWidget.headerElements.length > 0 &&
                                <span className="headerButtons">
                                    {DisplayWidget.headerElements}
                                </span>
                        }
                    </span>

                    <span className="widget-settings">
                        {
                            selectedWidget.state && selectedWidget.state.settings && Object.keys(selectedWidget.state.settings).length > 0
                            && (!workspaceSettings || Object.keys(workspaceSettings).length === 0)
                                ? <CurrentWidgetSettings settings={selectedWidget.state && selectedWidget.state.settings}/>
                                : workspaceSettings && <CurrentWidgetSettings
                                    style={{color:'gray'}}
                                    settings={applicableWorkspaceSettings}
                                    title={this.props.t("settings_from_workspace_text")}
                                />
                        }
                    </span>
                    {/* <span className="widget-header" style={{fontWeight: 'normal',flexDirection:'row', flexWrap:'wrap'}}>
                        <div>{getStrMaxLength('profiles: some, more, more, another one, it will take some place for sure, i know it', 30)}</div>,
                        <div>Date: </div>
                    </span> */}
                    <div className={["widget", `widget-id-${widgetId}`].join(' ')}>
                        {/* <c/> style so to possible add props (state) to component */}
                        {DisplayWidget ? (
                            <ErrorBoundary>
                            <DisplayWidgetComponent
                                {...DisplayWidget.props}
                                widgetId={widgetId}
                                workspaceId={this.props.workspaceId}
                                saveWidgetState={this.props.saveWidgetState}
                                getWidgetState={this.props.getWidgetState}
                                state={selectedWidget.state}
                                toggleWidgetSettings={() => (this.props.toggleWidgetSettings(widgetId))}
                                keys={this.props.keys}
                                // waitLoading={waitLoading}
                                />
                            </ErrorBoundary>
                        ) : (
                            'Oops! Here should be a widget'
                        )}
                    </div>
                </div>
            
                <WidgetContentBlocker
                    t={this.props.t}
                    delayTime={200}
                    isMounted={this.props.editable ? true : false}
                />

                { this.props.editable 
                ?   <span
                        className={
                            'remove-widget' +
                            (this.props.editable ? '  animated bounceIn' : '')
                        }
                        onClick={() => this.onRemoveWidget(layoutItem.i)}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </span>
                :   
                            <span
                                className={classNames(
                                    'fullscreen-widget-btn',
                                    {'minimize-widget-btn': this.state.fullscreenId === widgetId}
                                )}
                            >
                                { DisplayWidget.props && DisplayWidget.props.settings
                                    && (this.props.me.isAcademyAdmin || ! this.props.board.showAcademy) ?   // do not show filter for non-admin in academy mode
                                    <>
                                        {/* { selectedWidget.state.showSettings ?
                                            <FontAwesomeIcon
                                                title={this.props.t("always show options")}
                                                className={"options-widget-btn"}
                                                icon={faThumbtack}
                                                onClick={() => { alert("todo: implement") }}
                                            />
                                        :null} */}
    
                                        <FontAwesomeIcon
                                        
                                    title={
                                        Object.keys(workspaceSettings).length > 0
                                            ? `${this.props.t("Filters")} (${this.props.t('workspace-settings-used')})`
                                            : this.props.t("Filters")
                                    }
                                    data-tut="tour__widgetFilter"
                                    style={{
                                        color: Object.keys(workspaceSettings).length > 0
                                            ? 'gray'
                                            : 'black'
                                    }}
                                            className={"options-widget-btn " + (selectedWidget.state.showSettings ? this.props.theme.textSecondary : "")}
                                            icon={faFilter}
                                            onClick={() => { this.props.toggleWidgetSettings(widgetId) }}
                                        />
                                    </>
                                : null }
                                { DisplayWidget.embedPath ?
                                <a href={`#${DisplayWidget.embedPath}`} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon
                                        title={this.props.t("open in new tab")}
                                        data-testid="new-tab-widget-btn"
                                        className="new-tab-widget-btn"
                                        icon={faWindowRestore}
                                        // onClick={()=>{ window.open(`/#${DisplayWidget.embedPath}`, '_blank'); }}
                                    />
                                    </a>
                                : null }
                                <FontAwesomeIcon
                                    title={this.props.t("toggle fullscreen")}
                                    onClick={() => this.setFullscreenId(widgetId)}
                                    icon={faArrowsAltV}
                                    transform={{ rotate: 45 }}
                                />
                        </span>
                }
            </div>
        );
    }

    calcGridRowHeight(ref) {
        if (!ref) {
            return undefined;
        }
        const titleBarHeight = ref.offsetHeight;
        const workspaceWrapperHeight = window.innerHeight - titleBarHeight;
        const widgetHeight = workspaceWrapperHeight - this.gridContainerPadding[1]*2 - this.gridItemMargin[1] * (this.rowsInViewport - 1);  // see also: https://github.com/react-grid-layout/react-grid-layout?tab=readme-ov-file#grid-item-heights-and-widths
        const rowHeight = widgetHeight / this.rowsInViewport;
        return rowHeight;
    }

    render() {
        const { t, workspaces } = this.props;
        const workspace = workspaces[this.props.id];
        
        let workspaceSettings = {};
        if(this.props.workspaces[this.props.id]){
            workspaceSettings = this.props.workspaces[this.props.id].state.settings;
        }

        if (this.state.loading || !this.props.titleBarRef) {
            return <LoadingSpinner />;
        } else {
            const rowHeight = this.calcGridRowHeight(this.props.titleBarRef);
            /* const hasReportElement = workspace && workspace.widgets.some((w) => {
                return _.find(availableWidgets, { key: w.type, component: ReportElement })
            }); */
            return (
                <div className="workspace-wrapper">
                    {/* {hasReportElement && <Pptxgen />} */}
                    {workspace && !this.props.tour.show && <WorkspaceSettings
                        workspace={workspace}
                        // settings={{}}
                        // show={settings && state.showSettings}
                        // settings={settings}
                        // getData={this.getData}
                        // saveWidgetState={saveWidgetState}
                        // getWidgetState={getWidgetState}
                        // widgetId={widgetId}
                        // workspaceId={workspaceId}
                        // state={state}
                    />
                    }
                    { workspaceSettings && Object.keys(workspaceSettings).length > 0 ? 
                        <div
                        style={{
                            background: 'white',
                            borderTop:'1px solid gray',
                            paddingInlineStart: '1rem',
                            paddingBlockStart: '.2rem',
                            paddingBlockEnd: '.2rem',
                        }}>
                            <CurrentWidgetSettings
                                settings={workspaceSettings}
                                title={`${t('Current Settings')}: `}
                            />
                        </div>
                    :null}
                    <div className="workspace-grid-wrapper">
                        <ResponsiveReactGridLayout
                            className="workspace"
                            layouts={this.state.layouts}
                            margin={this.gridItemMargin}
                            breakpoints={this.breakpoints}
                            onLayoutChange={(layout, layouts) => {
                                if (_.differenceWith(layout, layouts.sm, _.isEqual).length > 0) {   // check if change is really a change because onLayoutChange of ResponsiveReactGridLayout is sometimes triggered although no change happened
                                    this.onLayoutChange(layout, layouts);
                                }
                            }
                            }
                            cols={{ lg: 6, sm: 2 }}
                            autoSize={false}
                            rowHeight={rowHeight}
                            containerPadding={this.gridContainerPadding}
                            //disable the following to prevent widget rerender (and reload)
                            isDraggable={this.props.editable}
                            isResizable={this.props.editable}
                        >
                            {_.map(this.state.layouts.lg, widget =>
                                this.renderWorkspaceElement(widget, workspaceSettings)
                            )}
                        </ResponsiveReactGridLayout>
                    </div>

                    <WidgetSelection
                        delayTime={200}
                        isMounted={
                            this.props.board.showWidgetSelection && this.props.editable
                                ? true
                                : false
                        }
                        widgetsToSelect={this.availableWidgets.map(w => (
                            {
                                ...w,
                                disabled: !this.props.me.allowedWidgets?.includes(w.key)
                            }
                        )).filter(w=> this.props.board.showAcademy || !w.academyOnly || process.env.REACT_APP_ENV === 'development' )}
                        hideWidgetSelection={this.hideWidgetSelection}
                        addWidget={this.onAddWidget}
                    />

                    <EditButton
                        delayTime={200}
                        isMounted={this.props.editable}
                        showWidgetSelection={this.showWidgetSelection}
                        text={'+ ' + t('Add Widget')}
                    />
                </div>
            );
        }
    }
}

const EditButton = delayUnmounting(props => (
    <Button
        btnClass={
            'add-widget animated' + 
            (props.isMounted ? ' fadeIn' : ' fadeOut')
        }
        type='secondary'
        onClick={props.showWidgetSelection}
        data-tut="tour__addWidget"
    >
        {props.text}
    </Button>
));

const WidgetContentBlocker = delayUnmounting(props => (
    <div
        className={
            'widget-content-blocker animated' + (props.isMounted ? ' fadeIn' : ' fadeOut')
        }
    >
        <div className={'widget-content-blocker-text-wrapper'}>
            <div className={'widget-content-blocker-text'}>
                <FontAwesomeIcon
                    icon={faArrowsAlt}
                    size="4x"
                    style={{padding: '1rem'}}
                />
                <div>
                    {props.t('editWidgetText1')}
                    </div>
                    <div>
                    {props.t('editWidgetText2')}
                    </div>
                
            </div>
            </div>
    </div>
));

const mapStateToProps = (state) => ({
    theme: state.theme,
    workspaces: state.workspaces,
    profileGroups: state.profileGroups,
    me: state.me,
    board: state.board,
    tour: state.tour
});
export default connect(mapStateToProps,{getProfileGroups,setWidgetStatus, setShowWidgetSelection})(withTranslation()(Workspace));