import Button from "../common/basicElements/Button/Button";
import styles from './QuizStart.module.scss';

function QuizStart({
    onStart,
}){
    return(
        <div className={styles.startPage}>
        <h2>Take this chapter's Quiz</h2>
        <p>
            Start the quiz to check your learning progress and earn a badge for your wall.
        </p>
        <div className={styles.startBtn}><Button onClick={onStart} type="success">Start</Button></div>
    </div>
    )
}

export default QuizStart;