import GenericCalls from "src/API/genericCalls";
import languages from "./supportedLanguages";

// requests go to eMM backend, then forwarded to deepl api

class Deepl{
    static async translate(text, source_lang, target_lang) {
        source_lang = source_lang.toUpperCase();
        target_lang = target_lang.toUpperCase();
        if (Deepl.languages.includes(source_lang) && Deepl.languages.includes(target_lang)) {
            let res = await GenericCalls.post(
                `/api/translation/deepl/translate`,
                {
                    text,
                    source_lang,
                    target_lang,
                },
                {
                    contentType: 'application/x-www-form-urlencoded; charset=utf-8'
                }
            )
            return res && res.translations ? res.translations[0] : res;
        } else {
            return -1;
        }
    }

    static languages = languages;
}

export default Deepl;