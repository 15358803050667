export const SET_WORKSPACE = 'board/setCurrentWorkspace';
export const SET_DEPRECATED_WIDGETS = 'board/setDeprecatedWidgets';
export const CLEAR_DEPRECATED_WIDGETS = 'board/clearDeprecatedWidgets';
export const IGNORE_DEPRECATED_WIDGETS = 'board/ignoreDeprecatedWidgets';
export const SET_LOADING_GLOBAL = 'board/setLoadingGlobal';
export const SET_SHOW_ACADEMY = 'board/setShowAcademy';
export const SET_SHOW_HELP_MENU = 'board/setShowHelpMenu';
export const SET_SHOW_USER_MENU = 'board/setShowUserMenu';
export const SET_EDITABLE = 'board/setIsEditable';
export const SET_SHOW_WIDGET_SELECTION = 'board/setShowWidgetSelection';
export const SET_CHAT_LOADING = 'board/setChatLoading';
