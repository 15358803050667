import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CompanyCard.module.scss';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { SyntheticEvent, useEffect, useState } from 'react';
import { CompanyCardProps } from './CompanyCard.types';
import CompanyEditContainer from '../CompanyEditContainer/CompanyEditContainer';

import Button from 'src/Widgets/common/basicElements/Button/Button';
import classNames from 'classnames';
import { Company, CompanyManagerData } from '../../CompanyManager.types';
import GroupCardPagination from 'src/Widgets/common/GroupCards/GroupCard/GroupCardPagination/GroupCardPagination';
import APICompanyGroups from 'src/API/APICompanyGroups';
import GenericTableView from '../../../common/GenericTable/GenericTableView';
import { useTranslation } from 'react-i18next';
import Checkbox from 'src/Widgets/common/basicElements/Checkbox/Checkbox';

const CompanyCard = ({ groupData }: CompanyCardProps) => {
    const [isOpenStates, setIsOpenStates] = useState<{ [companyId: string]: boolean }>(
        {}
    );
    const [editIsOn, setEditIsOn] = useState<boolean>(false);
    const [isAddView, setIsAddView] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [selectedCompany, setSelectedCompany] = useState<Company>(() => {
        return {} as Company;
    });
    const [structuredCompanies, setStructuredCompanies] = useState<Company[]>([]);
    const [isActiveContractOnly, setIsActiveContractOnly] = useState(false);

    const [cardData, setCardData] = useState<CompanyManagerData>({
        companies: [],
        id: '',
        title: ''
    });

    const noTranslateHeaders = ['id'];

    const { t } = useTranslation();

    const getCompanyDataHandler = async () => {
        if (groupData.id) {
            const res = await APICompanyGroups.get(groupData.id);
            if (res.rc === 0) {
                setCardData(res.data);
            } else {
                console.log('error when fetching company');
            }
        }
    };

    useEffect(() => {
        getCompanyDataHandler();
    }, []);

    useEffect(() => {
        if (cardData.companies.length === 10 && page > 1) {
            setPage(page - 1);
        }
    }, [cardData.companies.length]);

    const showEditViewHandler = (state: boolean, selected: Company) => {
        setEditIsOn(state);
        setSelectedCompany({
            ...selected,
            companyGroupId: groupData.id
        });
        setIsAddView(false);
    };

    const addCompanyButtonHandler = () => {
        setEditIsOn(true);
        setIsAddView(true);
    };

    const paginationHandler = (e: SyntheticEvent, dir: string) => {
        e.stopPropagation();
        switch (dir) {
            case 'prev':
                setPage(page - 1);
                break;
            case 'next':
                setPage(page + 1);
                break;
            default:
                return;
        }
    };

    const toggleItemOpenState = (companyId: string) => {
        setIsOpenStates((prevState) => ({
            ...prevState,
            [companyId]: !prevState[companyId]
        }));
    };

    const isItemOpen = isOpenStates[groupData.id] || false;

    const handleRowClick = (selectedCompany: Company) => {
        showEditViewHandler(true, selectedCompany);
    };

    const addBtnStyle = {
        position: 'sticky',
        bottom: '2rem',
        right: '2rem'
    };

    const filterActiveContracts = (companies: Company[]) => {
        const currentDate = new Date();
    
        return companies.filter((company) => {
            const contractEndDate = new Date(company.contractEndDate);
    
            return (
                contractEndDate.getFullYear() > currentDate.getFullYear() ||
                (contractEndDate.getFullYear() === currentDate.getFullYear() &&
                    contractEndDate.getMonth() > currentDate.getMonth()) ||
                (contractEndDate.getFullYear() === currentDate.getFullYear() &&
                    contractEndDate.getMonth() === currentDate.getMonth() &&
                    contractEndDate.getDate() > currentDate.getDate())
            );
        });
    };
    
    const mapCompaniesToStructured = (companies: Company[]) => {
        return companies.map((company) => ({
            id: company.id,
            ['Company Name']: company.title,
            ['Nickname']: company.nickname,
            ['Contract End']: company.contractEndDate,
            ['Payment Overdue']: company.paymentOverdueDate,
            ['Support Email']: company.supportEmail,
            ['Tech Email']: company.techEmail,
            ['Ops Email']: company.opsEmail
        }));
    };
    
    useEffect(() => {
        const filteredCompanies = isActiveContractOnly
            ? filterActiveContracts(cardData.companies)
            : cardData.companies;
    
        setStructuredCompanies(mapCompaniesToStructured(filteredCompanies));
    }, [isActiveContractOnly, cardData.companies]);

    return (
        <>
            <div
                onClick={() => toggleItemOpenState(groupData.id)}
                data-testid="companyCardContainer"
                className={
                    !isItemOpen
                        ? styles.companyCardContainerClosed
                        : styles.companyCardContainerOpened
                }
            >
                <div className={styles.headerContainer}>
                    <h3 className={styles.companyCardHeader}>{groupData.title}</h3>
                    <div className={styles.companyCardArrowsContainer}>
                        {/* {isItemOpen && (
                            <GroupCardPagination
                                numberOfItems={cardData.companies.length}
                                itemsPerPage={10}
                                onPrevClick={(e: SyntheticEvent) =>
                                    paginationHandler(e, 'prev')
                                }
                                onNextClick={(e: SyntheticEvent) =>
                                    paginationHandler(e, 'next')
                                }
                                currentPage={page}
                            />
                        )} */}
                        {
                            <FontAwesomeIcon
                                icon={!isItemOpen ? faChevronDown : faChevronUp}
                            />
                        }
                    </div>
                </div>
                {isItemOpen && (
                    <div className={styles.companyCardContent}>
                        <div className={styles.isActiveCheckboxContainer} onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <Checkbox
                                text={t('Active contracts only')}
                                checked={isActiveContractOnly}
                                onClick={() => setIsActiveContractOnly(!isActiveContractOnly)}
                                withBorder
                            />
                        </div>
                        <GenericTableView
                            tableData={structuredCompanies}
                            handleRowClick={handleRowClick}
                            noTranslateHeaders={noTranslateHeaders}
                            areFiltersActive={true}
                            noResultsMessage={t('No match')}
                            disabledColumns={['id']}
                        />
                        {isItemOpen && (
                            <Button
                                type="secondary"
                                btnClass={classNames('btnRoundSmall', 'item-add-button')}
                                disabled={false}
                                onClick={addCompanyButtonHandler}
                                btnStyle={addBtnStyle}
                            >
                                <FontAwesomeIcon icon="plus" />
                            </Button>
                        )}
                    </div>
                )}
            </div>
            {editIsOn && (
                <div
                    className={styles.companyEditContainer}
                    data-testid="companyEditContainer"
                >
                    <CompanyEditContainer
                        selectedCompany={selectedCompany}
                        isAddView={isAddView}
                        setEditIsOn={setEditIsOn}
                        getCompanyHandler={getCompanyDataHandler}
                        openCardsOnBack={toggleItemOpenState}
                        groupId={groupData.id}
                    />
                </div>
            )}
        </>
    );
};

export default CompanyCard;
