import React from 'react';
//import styles from './DragDroppable.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './DragDroppable.module.scss';

class DragDroppable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const children = React.Children.toArray(this.props.children);
        let props = {};

        const dragDropProps = {
            // id: this.props.id,
            draggable: this.props.draggable !== undefined ? this.props.draggable : true,
            onDragOver: (e)=>{ e.preventDefault(); },
            onDragEnd: (e)=>{ this.props.onDragEnd(e); },
            onDragEnter: (e)=>{ this.props.onDragEnter(e); },
            onDragLeave: (e)=>{ this.props.onDragLeave(e) },
            onDrop: (e) => { this.props.onDrop(e) },
            onDragStart: (e) => {
                this.props.onDragStart(e);
            },
            style: { cursor: 'move' }
        }

        let childProps = {
            style: children[0].props.style
        };

        if(!this.props.showOverlay){
            childProps = {...childProps, ...dragDropProps}
        }

        // child['data-droppable'] = true;

        if(this.props.showOverlay){
        return(
            <div {...dragDropProps} className={[styles.overlayWrapper, this.props.overlayWrapperClass].join(' ')}>
                {React.cloneElement(children[0], childProps)}
                <div className={[styles.overlay, this.props.overlayStyle].join(' ')}></div>
            </div>
        )
        } else {
            return (React.cloneElement(children[0], childProps));
        }
    }
}

const mapStateToProps = state => ({
    //theme: state.theme,
});
export default connect(mapStateToProps,{ /* insert redux actions here */ })(withTranslation()(DragDroppable));