// listed here https://www.deepl.com/de/docs-api/translate-text/translate-text/

// can be auto fetched via api via https://api-free.deepl.com/v2/languages?type=target
// see https://www.deepl.com/de/docs-api/general/get-languages/
// however, for some reason this call returns e.g. 'EN-US' and 'EN-UK' instead(!) of 'EN' which can be used as target_lang but not as source_lang

const languages = [
    "BG",
    "CS",
    "DA",
    "DE",
    "EL",
    "EN",
    "ES",
    "ET",
    "FI",
    "FR",
    "HU",
    "ID",
    "IT",
    "JA",
    "LT",
    "LV",
    "NL",
    "PL",
    "PT",
    "RO",
    "RU",
    "SK",
    "SL",
    "SV",
    "TR",
    "UK",
    "ZH",
];
export default languages;