import GenericCalls from './genericCalls';

class APIShareGroups {
    static async get(id) {
        if (id) {
            let rv = await GenericCalls.get('/api/share-groups/' + id);
            if (rv.data) {
                rv.data = rv.data.map(sg => {
                    sg.id = sg.userid;
                    delete sg.userid;
                    return sg;
                })
            }
            return rv;
        };
        return await GenericCalls.get('/api/share-groups');
    }
    
    static async post(data) {
        return await GenericCalls.post('/api/share-groups/', data);
    }
    
    static async put(id, data) {
        return await GenericCalls.put('/api/share-groups/' + id, data);
    }
    
    static async delete(id) {
        return await GenericCalls.delete('/api/share-groups/' + id);
    }

    static async addUsers(shareGroupId, userIdsArr) {
        return await GenericCalls.post(`/api/share-groups/${shareGroupId}/users/${userIdsArr}`);
    }

    static async removeUsers(shareGroupId, userIdsArr) {
        return await GenericCalls.delete(`/api/share-groups/${shareGroupId}/users/${userIdsArr}`);
    }
}

export default APIShareGroups;
