import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';

const useFindChannelType = (data: MentionsVisualizerTypes.Clip[]) => {
    const findChannelType = (channelId: number) => {
        const channel = data.find((channel: any) => channel.channelNumber === channelId);
        if (channel) {
            if (channel.type === 'TV') {
                return 'tv';
            }
            if (channel.type === 'FM Radio' || channel.type === 'AM Radio') {
                return 'radio';
            }
            if (
                channel.type === 'Web Text' ||
                channel.type === 'Press' ||
                channel.type === 'Web TV' ||
                channel.type === 'podcast' ||
                channel.type === 'SocialTV'
            ) {
                return 'web';
            }

        }
    };

    return { findChannelType }
}

export default useFindChannelType;
