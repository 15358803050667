import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './CustomIframeSettings.module.scss';

const { default: Button } = require("../common/basicElements/Button/Button")
const { default: TextInput } = require("../common/basicElements/TextInput/TextInput")

function CustomIframeSettings({
    onSave,
    init,
    allowTitleChange,
}) {
    const [url, setUrl] = useState(undefined);
    const [widgetTitle, setWidgetTitle] = useState(undefined);

    const { t } = useTranslation();

    useEffect(()=>{
        // if(url === undefined){
            setUrl(init.url);
            setWidgetTitle(init.widgetTitle);
        // }
    },[init.url, init.widgetTitle]);

    return (
        <div className={styles.wrapper}>
            {t('Url')}
            <TextInput
                value={url}
                placeholder="Url to HTML. e.g.: /v3/academy_api/en/courses/introduction/theory.html"
                onChange={(e)=>{setUrl(e.target.value)}}
            />
            {allowTitleChange &&
                <>
                    {t('Widget Title')}
                    {/* TODO: use title from iframe instead to enable easier translation ? */}
                    <TextInput
                        value={widgetTitle}
                        placeholder="Widget Title"
                        onChange={(e)=>{setWidgetTitle(e.target.value)}}
                    />
                </>
            }
            <Button
                type="success"
                onClick={() => { onSave({url, widgetTitle}) }}
            >
                {t('Save')}
            </Button>
            {/* <Button type="primary">Close</Button> */}
        </div>
    )
}

export default CustomIframeSettings;