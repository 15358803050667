import React, { useEffect, useState } from 'react';
import APIOptions from 'src/API/APIOptions';
// import Button from 'src/Widgets/common/basicElements/Button/Button';
import { urlParamsToObj } from 'src/Widgets/common/helpers';
// import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import { useHistory, withRouter, useParams } from 'react-router-dom';
import APIOAuth2 from 'src/API/APIOauth2';
import SignUp from './SignUp';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuthenticated } from 'src/redux/actions/actions.auth';

function SignUpContainer({
    // redirect,
    // onDone,
    location,
    authenticate,
}) {
    const [timezoneOptions, setTimezoneOptions] = useState(undefined);
    const [selectedTimezone, setSelectedTimezone] = useState(undefined);
    const [languageOptions, setLanguageOptions] = useState(undefined);
    const [selectedLanguage, setSelectedLanguage] = useState(undefined);
    const [isCreatingWorkspaces, setIsCreatingWorkspaces] = useState(false);

    const getHashParams = () => { return urlParamsToObj(location?.search, ['email', 'locale', 'name', 'family_name', 'given_name', 'sub', 'academy']) };   // url params after hash
    const hashParams = getHashParams();
    const history = useHistory();
    const signupRedux = useSelector((state) => state.signup);
    const accountSettings = useSelector((state) => state.accountSettings);
    const { authProvider } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        initTZ();
        (async () => {
            // fetch possible language and pre-select based on local provided by google (account)
            const langs = (await APIOptions.getEmailLangs()).data;
            setLanguageOptions(langs);
            setSelectedLanguage(hashParams.locale);
        })()
    }, []);

    const initTZ = async () => {
        // fetch possible timezones and pre-select based on setting provided by browser
        const tzs = (await APIOptions.getTimezones()).data;
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        setTimezoneOptions(tzs);
        setSelectedTimezone(clientTimezone);
    }

    const onCreate = async () => {
        const res = await APIOAuth2.register(signupRedux?.userType || 'api.trial', hashParams.sub, hashParams.email, selectedLanguage, selectedTimezone, authProvider);
        if (res.newUserID !== undefined) {
            // dispatch(updateAccountSettings({
            //     ...accountSettings?.data, hadTour: false 
            //     }, true))
            let from = location?.state?.from || { pathname: '/' }
            dispatch(setIsAuthenticated({isAuthenticated: true, redirectTo: from}));
            history.push('/');
        }
    }

    return (
        <SignUp
            timezones={timezoneOptions}
            selectedTimezone={selectedTimezone}
            onTimezoneChange={setSelectedTimezone}
            languages={languageOptions}
            selectedLanguage={selectedLanguage}
            onLanguageChange={setSelectedLanguage}
            onCreate={onCreate}
            email={hashParams.email}
            isCreatingWorkspaces={isCreatingWorkspaces}
        />
    );
}

export default withRouter(SignUpContainer);