import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import NotificationPanel from '../../Widgets/NotificationPanel/NotificationPanel';
import APINews from '../../API/APINews';
import Gravatar from 'react-gravatar';
import _ from 'lodash';
import { connect } from 'react-redux';

import '../../assets/hamburgers.1.1.3.css';
import '../../assets/animate.css';

import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faUserCircle,
    faBell,
    faSignOutAlt,
    faCog,
    faFilter,
    faInfoCircle,
    faQuestion,
    faUndo,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import APIMe from '../../API/APIMe';
import IconCount from 'src/Widgets/common/basicElements/IconCount/IconCount';
import { putWorkspace } from 'src/redux/actions/actions.workspaces.js';
import { showTour } from 'src/redux/actions/actions.tour.js';
import { setShowHelpMenu, setShowUserMenu } from 'src/redux/actions/actions.board.js';
// import { cacheBust } from 'src/Widgets/common/helpers';
import logoAcademy from '../../assets/api-academy-logo.svg';
import styles from './Titlebar.module.scss';
import Pptxgen from '../Workspace/Pptxgen/Pptxgen';
import ReportElement from 'src/Widgets/common/Reporting/ReportElement/ReportElement';
import { getAvailableWidgets } from '../Workspace/availableWidgets';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import HelpMenu from './HelpMenu';
import UserMenu from './UserMenu';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'src/consts';
import { dynamicConfig } from 'src/config/dynamicConfig/dynamicConfig';


// fontawesome icons
library.add(faUserCircle, faBell, faSignOutAlt, faCog, faInfoCircle, faQuestion, faUndo);

class Titlebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: [[], []],
            notificationsOpened: false,
            userMenuOpened: false,
            helpMenuOpened: false,
            user: undefined,
            isNarrowScreen: false
        };
        this.availableWidgets = getAvailableWidgets(
            this.props.me?.experimentalWidgetsSelectable,
            this.props.me?.allWidgets
        );

        this.displayNotifications = this.displayNotifications.bind(this);
        this.hideNotifications = this.hideNotifications.bind(this);
        this.displayUserMenu = this.displayUserMenu.bind(this);
        this.displayHelpMenu = this.displayHelpMenu.bind(this);
        this.hideUserMenu = this.hideUserMenu.bind(this);
        this.hideHelpMenu = this.hideHelpMenu.bind(this);
        this.updateNotificationStatus = this.updateNotificationStatus.bind(this);
        this.updateNotifications = this.updateNotifications.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    async componentDidMount() {
        this.updateNotifications();
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        // get username
        const user = (await APIMe.get()).data;
        this.setState({ user });

        // update notifications periodically
        this.notifUpdate = setInterval(this.updateNotifications, 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.notifUpdate);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({
            isNarrowScreen: window.innerWidth < 650
        });
    }

    async updateNotifications() {
        // let notifications = [];
        // notifications[0] = await APINews.getUnread();
        // notifications[1] = await APINews.getRead();
        // if (!notifications[0] || notifications[0].status)
        //     notifications[0] = [];
        // if (!notifications[1] || notifications[1].status)
        //     notifications[1] = [];
        // this.setState({ notifications });
    }

    // make read
    updateNotificationStatus(id) {
        this.setState(async (state) => {
            let readNotif = _.cloneDeep(state.notifications[0][id]);

            await APINews.setToRead(state.notifications[0][id].id);

            state.notifications[1].unshift(readNotif);
            state.notifications[0].splice(id, 1);

            return {
                notifications: state.notifications
            };
        });
    }

    displayNotifications() {
        this.setState({ notificationsOpened: true });
    }

    hideNotifications(e) {
        if (_.includes(e.target.className, styles.panelWrap)) {
            this.setState({ notificationsOpened: false });
        }
    }

    displayUserMenu() {
        // this.setState({ userMenuOpened: true });
        this.props.setShowUserMenu(true);
    }

    hideUserMenu(e) {
        //if (_.includes(e.target.className, 'panel-wrap')) {
        // this.setState({ userMenuOpened: false });
        this.props.setShowUserMenu(false);
        //}
    }

    displayHelpMenu() {
        this.props.setShowHelpMenu(true);
        // this.setState({ helpMenuOpened: true });
    }

    hideHelpMenu(e) {
        //if (_.includes(e.target.className, 'panel-wrap')) {
        // this.setState({ helpMenuOpened: false });
        this.props.setShowHelpMenu(false);
        //}
    }

    render() {
        const { theme, putWorkspace } = this.props;
        let currentWorkspace =
            this.props.workspaces[this.props.board.currentWorkspaceIndex];
        // let settingsCount;
        // if (currentWorkspace) {
        //     settingsCount = currentWorkspace.state.settings && Object.keys(currentWorkspace.state.settings).length;
        // }

        const bellClasses =
            this.state.notifications[0].length > 0
                ? classNames(
                      `animated swing`,
                      styles.notificationBell,
                      styles.notificationBellUnread,
                      theme.textSecondary
                  )
                : styles.notificationBell;

        const t = this.props.t || ((k) => k);
        // const settingsEnabled = currentWorkspace.widgets.length > 2 || Object.keys(currentWorkspace.state.settings).length > 0;
        const hasReportElement =
            currentWorkspace &&
            currentWorkspace.widgets.some((w) => {
                return _.find(this.availableWidgets, {
                    key: w.type,
                    component: ReportElement
                });
            });

        const gravatarEnabled = this.props.accountSettings.data.gravatarEnabled;

        return (
            <header className={styles.header} ref={this.props.titleBarRef}>
                <h2 className={styles.titlebarTitle}>
                    <button
                        style={{ width: this.state.isNarrowScreen && '3.3rem' }}
                        className={
                            `${styles.burgerMenu} hamburger hamburger--slider` +
                            (this.props.displayMenuPortrait ? ' is-active' : '')
                        }
                        onClick={() => {
                            if (
                                currentWorkspace &&
                                currentWorkspace.state &&
                                currentWorkspace.state.showSettings &&
                                !this.props.displayMenuPortrait
                            ) {
                                currentWorkspace.state.showSettings = false;
                                putWorkspace(currentWorkspace);
                            }
                            this.props.toggleDisplayMenuPortrait();
                        }}
                    >
                        <span className="hamburger-box">
                            <span
                                className={`${
                                    this.state.isNarrowScreen && styles.hamburgerMobile
                                } hamburger-inner`}
                            />
                        </span>
                    </button>

                    <Title {...this.props} isNarrowScreen={this.state.isNarrowScreen} />
                    {currentWorkspace && (
                        <IconCount
                            // title={`${settingsCount} ${t('different types of settings are applied for some widgets within this workspace')}:\n\n- Profile\n- Daterange`}
                            // count={settingsCount}
                            onClick={() => {
                                currentWorkspace.state.showSettings =
                                    !currentWorkspace.state.showSettings;
                                if (
                                    currentWorkspace.state.showSettings &&
                                    this.props.displayMenuPortrait
                                ) {
                                    this.props.toggleDisplayMenuPortrait();
                                }
                                putWorkspace(currentWorkspace);
                            }}
                        >
                            <FontAwesomeIcon // TODO: disable / hide if < 2 widgets on workspace
                                title={
                                    // settingsEnabled ?
                                    this.props.t('Workspace Filters')
                                    // : this.props.t("workspace-settings-disabled-more-widgets-needed")
                                }
                                // style={{
                                //     color:
                                //         settingsEnabled ?
                                //         'black'
                                //         : 'gray'
                                // }}
                                className={
                                    'options-widget-btn ' +
                                    (currentWorkspace.state.showSettings
                                        ? this.props.theme.textSecondary
                                        : '')
                                }
                                size={this.state.isNarrowScreen ? 'xs' : '1x'}
                                icon={faFilter}
                                data-tut="tour__workspaceFilter"
                            />
                        </IconCount>
                    )}
                    {hasReportElement && <Pptxgen btnClass={styles.exportButton} />}
                </h2>
                <div className={styles.menuPanel}>
                    {dynamicConfig.useSubscriptions && (
                        <NavLink to={ROUTES.upgrade_plan}>
                            <Button style={{ marginInlineEnd: '1rem' }} type="secondary">
                                {t('Upgrade Plan')}
                            </Button>
                        </NavLink>
                    )}
                    {this.props.board.showAcademy && (
                        <div className={styles.academyLogo}>
                            <img src={logoAcademy} alt="API academy" />
                        </div>
                    )}
                    {/* --------------------------------------HELP-BUTTON--------------------------------- */}
                    <div className="help">
                        <div
                            className={classNames(
                                styles.helpMenu,
                                this.props.board.showHelpMenu
                                    ? theme.backgroundPrimary_reverse
                                    : theme.backgroundPrimary
                            )}
                            onClick={this.displayHelpMenu}
                        >
                            <FontAwesomeIcon
                                className={styles.helpIcon}
                                icon={faQuestion}
                            />
                        </div>
                    </div>
                    <HelpMenu
                        // t={t}
                        theme={theme}
                        unauthenticate={this.props.unauthenticate}
                        helpMenuOpened={this.props.board.showHelpMenu}
                        showAcademy={this.props.board.showAcademy}
                        hideHelpMenu={this.hideHelpMenu}
                        showTour={this.props.showTour}
                        animate={!this.props.tour.show}
                        // setIsOpen={this.props.setIsOpen}
                        supportEmail={this.props.me.support}
                    />

                    {/* --------------------------------------USER-BUTTON--------------------------------- */}

                    <div className={styles.user}>
                        {/* TODO: discuss/design backend? see bug 1168 */}
                        {/* <FontAwesomeIcon
                        className={bellClasses}
                        icon="bell"
                        onClick={this.displayNotifications}
                    />    */}

                        <div
                            className={classNames(
                                styles.userInfo,
                                this.props.board.showUserMenu
                                    ? theme.backgroundPrimary_reverse
                                    : theme.backgroundPrimary
                            )}
                            onClick={this.displayUserMenu}
                        >
                            <span
                                data-testid="titlebar-username"
                                className={styles.userName}
                                title={
                                    this.state.user !== undefined
                                        ? this.state.user.username
                                        : ''
                                }
                            >
                                {this.state.user !== undefined
                                    ? this.state.user.username
                                    : ''}
                            </span>

                            {gravatarEnabled ? (
                                this.state.user?.username ? (
                                    <Gravatar
                                        className={styles.gravatar}
                                        email={this.state.user?.username}
                                        size={45}
                                        rating="g"
                                        alt={''}
                                    />
                                ) : (
                                    <LoadingSpinner size="2rem" />
                                )
                            ) : (
                                <FontAwesomeIcon
                                    className={styles.userIcon}
                                    icon="user-circle"
                                />
                            )}
                        </div>
                    </div>

                    <UserMenu
                        t={t}
                        theme={theme}
                        unauthenticate={this.props.unauthenticate}
                        userMenuOpened={this.props.board.showUserMenu}
                        hideUserMenu={this.hideUserMenu}
                        isNarrowScreen={this.state.isNarrowScreen}
                        user={this.state.user}
                    />

                    {/* notification panel wrapper */}
                    <CSSTransition
                        in={this.state.notificationsOpened}
                        timeout={300}
                        unmountOnExit
                        classNames={{
                            exitActive: `animated ${
                                document.dir === 'rtl' ? 'fadeOutLeft' : 'fadeOutRight'
                            }`
                        }}
                    >
                        <div
                            className={styles.panelWrap}
                            onClick={this.hideNotifications}
                        >
                            <div
                                className={classNames(
                                    `${styles.notificationsPanel} animated ${
                                        document.dir === 'rtl'
                                            ? 'fadeInLeft'
                                            : 'fadeInRight'
                                    }`,
                                    theme.borderPrimary
                                )}
                            >
                                <NotificationPanel
                                    notifications={this.state.notifications}
                                    updateNotificationStatus={
                                        this.updateNotificationStatus
                                    }
                                />
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </header>
        );
    }
}

function Title(props) {
    if (!props.editable) {
        return (
            <span style={{ fontSize: props.isNarrowScreen && '1rem' }}>
                {props.title || ''}
            </span>
        );
    }

    return (
        <>
            
            <input
                ref={props.titleRef}
                type="text"
                value={props.title || ''}
                onChange={(e) => {
                    props.changeWorkspaceName(props.currentWorkspace, e.target.value);
                }}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    theme: state.theme,
    workspaces: state.workspaces,
    board: state.board,
    tour: state.tour,
    accountSettings: state.accountSettings,
    me: state.me
});
export default connect(mapStateToProps, {
    putWorkspace,
    showTour,
    setShowHelpMenu,
    setShowUserMenu
})(withTranslation()(Titlebar));
