import React from 'react';
import { withTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import PropTypes from 'prop-types';
import { randomColor } from 'randomcolor';
import { getColors } from '../helpers';

const CustomizedAxisTick = props => {
    const { x, y, payload } = props
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text dy={16} textAnchor='middle' fill='#666'>{payload.value}</text>
      </g>
    )
  }

class Chart extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired
    }
    constructor(props) {
        super(props);
        this.state = {};
    }

    getColor(name) {
        return randomColor({
            luminosity: 'bright',
            seed: name
        });
    }

    render() {
        const { xLabel, yLabel } = this.props;
        const series = this.props.data;
        const yLabelObj = yLabel ? { value: yLabel, angle: -90, position: 'insideLeft' } : undefined;
        const lines = series.map(s=>{
            return s.name;
        });
        const colors = getColors(lines);
        

        return (
            <div style={{direction:'ltr', width: "100%", height: "99%"}}>
                <ResponsiveContainer debounce={200}>
                    <LineChart margin={{top: 10, right: 30, left: 20, bottom: 35}}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="category" type="category" allowDuplicatedCategory={false} tick={<CustomizedAxisTick/>}>
                            { xLabel && <Label value={xLabel} offset={-20} position="insideBottom" /> }
                        </XAxis>
                        <YAxis dataKey="value" label={yLabelObj} />
                        <Tooltip />
                        <Legend
                            layout="horizontal"
                            verticalAlign="top"
                            align="top"
                        />
                        {series.map((s) => {
                            return <Line dataKey="value" data={s.data} name={s.name} key={s.name} stroke={colors[s.name][0]} strokeWidth="2" animationDuration={300} />
                        })}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
export default withTranslation()(Chart);