import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../common/basicElements/Button/Button";
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

function QuizFinished({
    restartQuiz,
    board,
    workspaces,
}){
    return(
        <div>
            <h2>Quiz finished</h2>
            <p>Great, you earned a badge for finishing this quiz.<FontAwesomeIcon size='2x' icon={faThumbsUp} style={{marginInlineStart:'0.5rem'}} /></p>
                You may now continue by trying our API via our Swagger documentation or implement your own solution by using our sample code as reference.

            <p>
                Other options:
                <Button type="secondary" onClick={restartQuiz}>Restart Quiz</Button>
                
                {/* add button to switch to next workspace (if there is a 'next' workspace) */}
                {board.currentWorkspaceIndex+1 < workspaces.length &&
                    <NavLink to={`/workspaces/${ board.currentWorkspaceIndex + 2 /* +2 because routes start with 1, not zero */ }`}>
                        <Button type="secondary" >Next Course</Button>
                    </NavLink>
                }
            </p>                     
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        board: state.board,
        workspaces: state.workspaces,
    }
}

export default connect(mapStateToProps, {  })(QuizFinished);