import React from 'react';
import styles from './Popup.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '../Button/Button';

class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            onOk,
            onRemove,
            onAdd,
            onCancel,
            blockContent,
            size,
            okDisabled,
            okTxt,
            okTitle,
            cancelTxt,
            wrapperStyle,
            addDisabled
        } = this.props;
        let zIndex = blockContent ? 999 : undefined;

        return (
            <div
                style={{
                    zIndex,
                    ...wrapperStyle
                }}
                className={styles.wrapper}
            >
                <div
                    className={[
                        styles.content,
                        size === 'auto' ? styles.autoSize : undefined
                    ].join(' ')}
                >
                    {this.props.children}
                    <div className={styles.addRemovePopUp}>
                        {onOk && (
                            <Button
                                disabled={okDisabled}
                                onClick={onOk}
                                type="primary"
                                title={okTitle}
                            >
                                {okTxt || this.props.t('Ok')}
                            </Button>
                        )}
                        {onAdd && (
                            <>
                                <Button
                                disabled={addDisabled}
                                onClick={onAdd} type="secondary">
                                    {this.props.t('Add')}
                                </Button>
                            </>
                        )}
                        {onRemove && (
                            <>
                                <Button onClick={onRemove} type="danger">
                                    {this.props.t('Delete')}
                                </Button>
                            </>
                        )}
                        {onCancel && (
                            <Button onClick={onCancel} type="cancel">
                                {cancelTxt || this.props.t('Cancel')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    // theme: state.theme,
});
export default connect(mapStateToProps, {
    /* insert redux actions here */
})(withTranslation()(Popup));
