import { useEffect, useState } from 'react';
import styles from './GroupsContainer.module.scss';

import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';
import GroupItem from '../GroupItem/GroupItem';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import { addGroup, getGroups } from 'src/redux/actions/actions.channelGroups';
import AddButton from 'src/Widgets/common/GroupCards/AddButton/AddButton';

const GroupsContainer = ({ isAdminUser, selectedGroup, selectGroupHandler, setIsEditViewOpen }: any) => {

    const [loading, setLoading] = useState(true);
    const [saveStatus, setSaveStatus] = useState<any>({
        isSuccess: false,
        isError: false,
    });
    const dispatch = useAppDispatch();

    useEffect(() => {
        let isMounted = true;
        dispatch(getGroups()).finally(() => {
            if (isMounted) setLoading(false);
        });
        return () => {
            isMounted = false;
        };
    }, [dispatch]);

    const privateChannelGroups = useAppSelector((state) => state.channelGroupReducers);
    const adminChannelGroups: any = [];

    const [isGroupListOpen, setIsGroupListOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const isMobileOnSmallDesktop = useIsScreenSize(1384);

    const conditionalChannelGroups = isAdminUser ? adminChannelGroups : privateChannelGroups

    const groupsConditionalRender = () => {
        if (loading) {
            return <LoadingSpinner size={'2rem'} />;
        } else if (conditionalChannelGroups.length) {
            return conditionalChannelGroups
                .sort((a: any, b: any) => a?.title.localeCompare(b?.title))
                .map((channelGroup: any) => (
                    <GroupItem
                        key={channelGroup.id}
                        groupData={channelGroup}
                        selectGroupHandler={selectGroupHandler}
                        selectedGroup={selectedGroup}
                        setIsEditViewOpen={setIsEditViewOpen}
                    />
                ));
        } else {
            return (
                <div className={styles.groupsErrorContainer}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <div className={styles.groupsErrorText}>{t('No groups created')}</div>
                </div>
            );
        }
    };

    //When screen size is not mobile, group list collapse container hidden
    const handleScreenSizeChange = () => {
        if (!isMobileOnSmallDesktop) {
            setIsGroupListOpen(false);
        }
    };
    
    useEffect(handleScreenSizeChange, [isMobileOnSmallDesktop]);

    const handleAddGroup = (groupTitle: string) => {
        if (!groupTitle) return;
        const res = dispatch(addGroup({ title: groupTitle }, [90102]));
        res.then(handleGroupAddResponse);
    };
    
    const handleGroupAddResponse = (res: any) => {
        if (res?.rc === 0) {
            setSaveStatus({ ...saveStatus, isSuccess: true });
        } else {
            setSaveStatus({ ...saveStatus, isError: true });
        }
    };

    return (
        <div className={styles.groupsOuterContainer}>
            <div className={styles.groupsHeadingSection}>
                {!isMobileOnSmallDesktop && <h2>{t('My Channel Groups')}</h2>}
            </div>
            {isMobileOnSmallDesktop && (
                <button
                    className={styles.toggleGroupsOnMobileButton}
                    onClick={() => setIsGroupListOpen(!isGroupListOpen)}
                >
                    {!isGroupListOpen ? (
                        <span>
                            <FontAwesomeIcon icon={faEye} />
                            {`${t('Show Channel Groups')}`}
                        </span>
                    ) : (
                        <span>
                            <FontAwesomeIcon icon={faEyeSlash} />
                            {`${t('Hide Channel Groups')}`}
                        </span>
                    )}
                </button>
            )}
            {
                <h2 className={`${!isGroupListOpen && styles.hiddenGroupsMobile}`}>
                    {t('My Channel Groups')}
                </h2>
            }
            {isAdminUser && !isMobileOnSmallDesktop && (
                <span className={styles.groupsHeading}>{t('Admin Groups Heading')}</span>
            )}
            {isAdminUser && (
                <span
                    className={`${styles.groupsHeading} ${
                        !isGroupListOpen && styles.hiddenGroupsMobile
                    }`}
                >
                    {t('Admin Groups Heading')}
                </span>
            )}
            {isAdminUser && !isMobileOnSmallDesktop && (
                <div className={styles.groupsInnerContainer}>
                    {groupsConditionalRender()}
                </div>
            )}
            {isAdminUser && (
                <div
                    className={`${
                        isMobileOnSmallDesktop && styles.groupsMobileInnerContainer
                    } ${!isGroupListOpen && styles.hiddenGroupsMobile}`}
                >
                    {groupsConditionalRender()}
                </div>
            )}
            {isAdminUser && !isMobileOnSmallDesktop && (
                <span className={styles.groupsHeading}>
                    {t('Private Groups Heading')}
                </span>
            )}
            {isAdminUser && (
                <span
                    className={`${styles.groupsHeading} ${
                        !isGroupListOpen && styles.hiddenGroupsMobile
                    }`}
                >
                    {t('Private Groups Heading')}
                </span>
            )}
            {
                <div
                    className={`${
                        isMobileOnSmallDesktop && styles.groupsMobileInnerContainer
                    } ${!isGroupListOpen && styles.hiddenGroupsMobile}`}
                >
                    {groupsConditionalRender()}
                </div>
            }
            {!isMobileOnSmallDesktop && (
                <div className={`${styles.groupsInnerContainer}`}>
                    {groupsConditionalRender()}
                </div>
            )}
            <div className={styles.addButtonContainer}>
                {((isGroupListOpen && isMobileOnSmallDesktop) ||
                    (!isGroupListOpen && !isMobileOnSmallDesktop)) && (
                    <AddButton
                        addGroup={handleAddGroup}
                        customInputStyle={{
                            width: '65%',
                            height: '1.3rem',
                            fontSize: '1rem'
                        }}
                        customButtonStyle={{
                            width: '3rem',
                            height: '3rem'
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default GroupsContainer;
