import { FC, useEffect, useRef, useState } from 'react';

import APIMentions from 'src/API/APIMentions';
import { getChannels } from 'src/redux/actions/actions.channels';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import styles from './MentionResults.module.scss';
//@ts-ignore
import { useParams } from 'react-router-dom/';

import { ChannelTypeLowerCase,  MentionData } from 'src/@types/shared.types';

import LoadingSpinner from '../basicElements/LoadingSpinner/LoadingSpinner';

import useDateUtils from 'src/hooks/useDateUtils';

//@ts-ignore
import { VariableSizeList } from 'react-window';

import { useIsScreenSize } from 'src/hooks/useIsScreenSize';
import OverviewHeader from './OverviewHeader/OverviewHeader';
import { MentionResultsProps } from './MentionResults.types';
import { NotificationId } from 'src/Widgets/TopicMentionsOverview/OverviewCluster/OverviewCluster.types';
import BackToTop from '../basicElements/BackToTop/BackToTop';
import MentionCardList from './MentionCardList/MentionCardList';

const MentionResults: FC<MentionResultsProps> = ({isTopicMentionDetails}) => {

    const [resultsData, setResultsData] = useState<MentionData[]>([]);
    const [channelTypesWithNotification, setChannelTypesWithNotification] = useState<string[]>();
    const [selectedType, setSelectedType] = useState<string>('');
    const [channelsByType, setChannelsByType] = useState<MentionData[]>([]);

    const params = useParams();
    
    //Variables set with url parameters
    const profileIds = params.profileIds || [];
    const mentionType = params.mentionType || '';
    const dateFrom = params.dateFrom || '';
    const dateTo = params.dateTo || '';

    //i18
    const { t } = useTranslation();

    //Redux
    const channels = useAppSelector((state) => state.channelReducers.data);
    const dispatch = useAppDispatch();
    const workspaces = useAppSelector((state) => state.workspaces);
    const board = useAppSelector((state) => state.board);
    const {sentimentResults} = useAppSelector((state) => state.mentionsReducer as any);

    const descriptionRef = useRef<HTMLDivElement>(null)
    const listRef = useRef<VariableSizeList>(null);

    const { dateConverter, getDateRange } = useDateUtils();

    const isMobileScreen = useIsScreenSize(640)
    const isTabletScreen = useIsScreenSize(1280)

    const selectedProfileIds = workspaces[board.currentWorkspaceIndex]?.state.settings.profile;
    const selectedDates = workspaces[board.currentWorkspaceIndex]?.state.settings;

    const dateByUnit = {
        unit: workspaces[board.currentWorkspaceIndex]?.state.settings.date
            ? workspaces[board.currentWorkspaceIndex].state.settings.date.unit?.value
            : null,
        count: workspaces[board.currentWorkspaceIndex]?.state.settings.date
            ? workspaces[board.currentWorkspaceIndex].state.settings.date?.number
            : null
    };

    const dateByRangeSelect = {
        fromDate: selectedDates ? selectedDates?.date?.[0] : null,
        toDate: selectedDates ? selectedDates?.date?.[1] : null
    };

    const dateRangeFromUnits =
        dateByUnit && getDateRange(dateByUnit.unit, dateByUnit.count);
    const dateRangeByUnit = {
        fromDate: dateByUnit && dateRangeFromUnits[0],
        toDate: dateByUnit && dateRangeFromUnits[1]
    };

    useEffect(() => {
        dispatch(getChannels());
    }, []);

    useEffect(() => {
        findChannelTypeById();
    }, [channels, resultsData]);

    useEffect(() => {
        if((dateByRangeSelect || dateRangeByUnit) && profileIds.length && mentionType.length){
            fetchResults()
        }
    }, [selectedDates, profileIds]);

    //Convert data before sending as props
    useEffect(() => {
    if(sentimentResults){
        const convertedArray = sentimentResults?.map((sentiment: NotificationId) => {
            return {
            channelName: sentiment.channelName,
            channelNumber: sentiment.channelNumber,
            dupType: null,
            kfLink: sentiment.kfLink,
            notifID: sentiment.notification_id,
            playerLink: sentiment.viewLink,
            profileGroupTitle: '',
            profileTitle: '',
            sentiment: sentiment.sentiment,
            userdate: '',
            icon: sentiment.icon
            }
        })
        sentimentResults.length && setResultsData(convertedArray)
}
    }, [sentimentResults])

    useEffect(() => {
        matchChannelDataWithResults();
    }, [selectedType]);

    const fetchResults = async () => {
        try {
            const res = await APIMentions.getMentionResults(
                profileIds,
                (!dateRangeByUnit.fromDate.length
                    ? dateConverter(dateByRangeSelect.fromDate)
                    : dateRangeByUnit.fromDate) && dateFrom,
                (!dateRangeByUnit.fromDate.length
                    ? dateConverter(dateByRangeSelect.toDate)
                    : dateRangeByUnit.toDate) && dateTo,
                mentionType
            );

            if (res?.data.length) {
                setResultsData(res.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const matchChannelDataWithResults = () => {
        const matchedData = resultsData?.filter((res: MentionData) =>
            channelTypesWithNotification?.some((types: any) => {
                const channelIdsArray = types.data.map((channel: ChannelTypeLowerCase) => channel.id);
                return (
                    types.type === selectedType &&
                    channelIdsArray.includes(res.channelNumber)
                );
            })
        );
        setChannelsByType(matchedData);
    };

    const findChannelTypeById = () => {
        const channelsHaveResults = channels?.filter((channel: ChannelTypeLowerCase) => {
            return resultsData?.some((res: MentionData) => {
                return channel.id === res.channelNumber;
            });
        });

        const channelTypesData: any = {};

        channelsHaveResults.forEach((channel: ChannelTypeLowerCase) => {
            if (!channelTypesData[channel.type]) {
                channelTypesData[channel.type] = [];
            }
            channelTypesData[channel.type].push(channel);
        });

        const channelTypesArray: any = Object.entries(channelTypesData).map(
            ([type, data]) => ({
                type,
                data
            })
        );

        setChannelTypesWithNotification(channelTypesArray);
    };

    const dataCondition = channelsByType?.length ? channelsByType : resultsData;

    //Calculate different itemSizes depending on screensize
    const conditionalSizeByScreen = () => {
        if(isMobileScreen){
            return 580
        }else if(isTabletScreen){
            return 380
        }else{
            return 200
        }
    } 

    const rowHeights = dataCondition?.map(() => conditionalSizeByScreen() + Math.round(Math.random() * 70));

    const getItemSize = (index: number) => rowHeights[index];

    if (!channels[0] || !dataCondition.length) {
        return <LoadingSpinner size="4rem" />;
    }
    
    return (
        <div className={styles.mentionResultsContainer}>
            {(params.profileIds || sentimentResults) && (dateByRangeSelect || dateRangeByUnit) ? (
                <>
                    <OverviewHeader
                        setSelectedType={setSelectedType}
                        channelTypesWithNotification={channelTypesWithNotification}
                        selectedDates={selectedDates}
                        selectedProfileIds={selectedProfileIds && params.profileIds}
                        isMentionResults={true}
                        dataCondition={dataCondition}
                        mentionType={mentionType}
                        isTopicMentionDetails={isTopicMentionDetails}
                    />

                    <div
                        className={styles.mentionDetailsCards}
                        style={{ height: '100%' }}
                    >
                        <MentionCardList
                            dataCondition={dataCondition}
                            isMobileScreen={isMobileScreen}
                            isTopicMentionDetails={isTopicMentionDetails}
                            getItemSize={getItemSize}
                            descriptionRef={descriptionRef}
                            listRef={listRef}
                            profileIds={profileIds}

                        />
                    </div>
                </>
            ) : (
                t('No data to show')
            )}
            <BackToTop listRef={listRef}/>
        </div>
    );
};

export default MentionResults;
