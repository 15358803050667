
import GenericTable from './GenericTable'
import useTranslatedHeaders from 'src/hooks/useTranslatedHeaders';
import { FC } from 'react';
import { GenericTableViewProps } from './GenericTable.types';
import { useTableDataConverter } from './hooks/useGenericTableDataConverter';

const GenericTableView: FC<GenericTableViewProps> = ({
    tableData,
    handleRowClick,
    selectedElements,
    noTranslateHeaders,
    areFiltersActive = false,
    multipleRowSelection = false,
    noResultsMessage,
    cgwOptions,
    disabledColumns = [],
    customRenderers,
    disabledColumnFilters
}) => {
    /*
  PROPS
  -tableData
  tableData must be in following structures with relevant hooks:

  -- useObjectArrayToMatrixConverter
  const data = [{ name: 'John', age: 25 }, { name: 'Jane', age: 30 }];

  --useArrayArrayToMatrixConverter
  const data = [[1, 2, 3], [4, 5, 6]];

  --useObjectToMatrixConverter
  const data = { name: 'John', age: 25, city: 'New York' };

  -handleRowClick
  row onClick function returns the row object

  -noTranslateHeaders
  You can send an array of strings(column headers) which not gonna be translated

  -areFiltersActive
    If true, filters will be shown
   
   -multipleRowSelection
    If true, multiple row selection will be enabled
    
   -selectedElements
    Array of selected elements
    
   -noResultsMessage
    Message to show when no results are found
   
   -cgwOptions
    Options for the channel group widget
    
   -disabledColumns
    Array of column headers to disable
    
   -filterableColumns
    Array of column headers to be filterable 

   -customRenderers
    Object with custom renderers for specific columns 

  */

  // Convert table data to a format that the table can use
    const convertedData = useTableDataConverter(tableData, multipleRowSelection);

    // Translate headers
    const translatedHeaders = useTranslatedHeaders(
        convertedData.columnHeaders,
        noTranslateHeaders
    );

    const disabledColumnsFiltersTranslated = useTranslatedHeaders(
        disabledColumnFilters || [],
        noTranslateHeaders
    );

    // Determine indices of disabled columns to filter out
    const disabledIndices = disabledColumns
        .map((disabledColumn) =>
            convertedData.columnHeaders.findIndex((header) => header === disabledColumn)
        )
        .filter((index) => index !== -1);

    // Filter out disabled columns from headers
    const activeColumns = translatedHeaders.filter(
        (_, index) => !disabledIndices.includes(index)
    );

    // Also filter out the corresponding data in each row
    const filteredMatrix = convertedData.matrix.map((row) =>
        row.filter((_, index) => !disabledIndices.includes(index))
    );

    // Send the data to the table in a format it can use
    const tableDataToSend = {
        convertedData: {
            ...convertedData,
            columnHeaders: activeColumns,
            matrix: filteredMatrix
        },
        tableData
    };

    return (
        <GenericTable
            data={tableDataToSend}
            onRowClick={handleRowClick}
            areFiltersActive={areFiltersActive}
            multipleRowSelection={multipleRowSelection}
            selectedElements={selectedElements}
            noResultsMessage={noResultsMessage}
            cgwOptions={cgwOptions}
            customRenderers={customRenderers}
            disabledColumnFilters={disabledColumnsFiltersTranslated}
        />
    );
};

export default GenericTableView