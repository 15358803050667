import React, { Component } from 'react';
import GroupCardsContainer from '../common/GroupCards/GroupCardsContainer';
import _ from 'lodash';
import User from '../common/DetailViews/User/User';
import APICompanies from '../../API/APICompanies';
import NoAccess from 'src/Board/NoAccess/NoAccess';
import { connect } from 'react-redux';
import APIMe from '../../API/APIMe';
import { withTranslation } from 'react-i18next';
import APICompanyGroups from 'src/API/APICompanyGroups';
import LoadingSpinner from '../common/basicElements/LoadingSpinner/LoadingSpinner';
import APIUsers from '../../API/APIUsers';
import { dateToString } from '../common/helpers';
import Button from '../common/basicElements/Button/Button';
import Popup from '../common/basicElements/Popup/Popup';

/* NOTE:
 * - improve caching in list view (currently needs to fetch data again after group page switch)? move to redux?
 */
/**
 * - searchTerm - for search prefill (state persistency)
 */
class UserManager extends Component {
    constructor(props) {
        super(props);

        this.getGroup = this.getGroup.bind(this);
        this.DetailView = this.DetailView.bind(this);
        this.CreateView = this.CreateView.bind(this);
        this.getItem = this.getItem.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.state = {
            companyIds: undefined,
            isLoading: true,
            currentGroup: {},
            sendingEmail: {
                user: null,
                manager: this.props.me.username,
                isSendingToUser: false,
                isSendingToManager: false,
                status: null,
                errorMessage: ''
            },
        }
    }

    // rename a few keys for generic cards
    buildCompanyGroupState(apiCompanies) {
        // return _.map(apiCompanies, g => ({ id: g.id }));
        return apiCompanies;
    }

    // returns single group in generic format (formally buildGroupState above)
    async getGroup(id, showDetailView) {
        if (id === null || id === undefined) return;

        // const group = await APICompanies.get(id);
        let company, users, title;

        if (id === 'own') {
            company  = (await APIMe.company()).data;
            // TODO when API is ready allow to create users for own company
            // company  = {id: 'own', ...(await APIMe.company()).data};
            users = (await APIMe.companyUsers()).data;
            title = this.props.t("My Company");
        } else {
            company = (await APICompanies.get(id)).data;
            users = company.users;
            title = company.title;
        }


        const formattedGroup = {
            id: company.id,
            title,
            items: _.map(users, u => (                {
                id: u.id,
                title: u.username,
                disabled: false, // TODO: expiry date calc
                highlighted: false,
                expiryTS: u.expiryTS,
                created: u.created,
                timezone: u.timezone,
                onClick: () => showDetailView(u.id),
            }))
        }
        return formattedGroup;
    }

    async getItem(id, showDetailView){
        const u = this.state.currentGroup?.users?.find(u => u.id === id) || {id: id};
        return {
            id: u.id,
            title: u.title,
            disabled: u.disabled, // TODO: expiry date calc
            highlighted: u.highlighted,
            onClick: () => showDetailView(u.id),

            extended: { // add stuff here to show in list view
                "Username": u.title,
                "Created On": dateToString(new Date(u.created * 1000)),
                "Expiry Date": dateToString(new Date(u.expiryTS * 1000)),
                "Timezone": u.timezone,
            }
        }
    }

    async componentDidMount() {
        let stateUpdate = {};
        if (this.props.me?.managedCompanyGroups){
            const companies = (await Promise.all(this.props.me.managedCompanyGroups.map(async (cg)=>{
                const res = (await APICompanyGroups.get(cg)).data;
                return res.companies.map((c)=> ({id: c.id, title: c.title}));
            }))).reduce((sum,i)=>sum.concat(i),[]);
            stateUpdate.companyIds = companies;
    }   
        this.setState({isLoading: false, ...stateUpdate});
    }

    DetailView = props => (
        <User
            id={props.id}
            onGoBack={props.goBack}
            currentGroup={this.state.currentGroup}
            update
        />
    );

    CreateView = props => (
        <User
            id={props.id}
            onGoBack={props.goBack}
            currentGroup={this.state.currentGroup}
        />
    );

    sendEmail = async (emailAddress, toCreatorOnly, forV3) => {
        if (this.state.sendingEmail.isSending) return;
    
        let sendingEmail = {
            user: emailAddress,
            manager: this.props.me.username,
            isSendingToUser: toCreatorOnly === undefined,
            isSendingToManager: toCreatorOnly === 1,
            status: null
        };
    
        this.setState({ 
            sendingEmail
         });
    
        const res = await APIUsers.sendEmail(emailAddress, this.props.me.username, toCreatorOnly, forV3);
    
        if(res.rc === 0) {
            sendingEmail.status = 1;
        } else {
            sendingEmail.errorMessage = res.msg;
            sendingEmail.status = 0;
        }
    
        setTimeout(() => {
            this.setState({ sendingEmail });
            sendingEmail.isSendingToManager = false;
            sendingEmail.isSendingToUser = false;
        }, 500);
    }

    renderSendEmailButton = (cellData, rowIndex) => {
        const isSendingToUser = this.state.sendingEmail &&
            this.state.sendingEmail.isSending &&
            this.state.sendingEmail.user === cellData.Username;
    
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '100%',
                height: '100%'
            }}>
            <Button
                type="primary"
                disabled={this.state.sendingEmail.isSendingToUser}
                id={rowIndex}
                btnStyle={{
                    width: 'fitContent',
                    height: '100%',
                    margin: '0',
                    padding: '0.2rem',
                    fontSize: '0.8em',
                    textAlign: 'center'
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    this.sendEmail(cellData.Username);
                }}
            >
                {this.state.sendingEmail.isSendingToUser
                    ? this.props.t('Sending Email...')
                    : this.props.t('Email to User')}
            </Button>
            <Button
                type="primary"
                disabled={this.state.sendingEmail.isSendingToManager}
                id={rowIndex}
                btnStyle={{
                    width: 'fitContent',
                    height: '100%',
                    margin: '0',
                    padding: '0.2rem',
                    fontSize: '0.8em',
                    textAlign: 'center'
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    this.sendEmail(cellData.Username, 1, 0);
                }}
            >
                {this.state.sendingEmail.isSendingToManager
                    ? this.props.t('Sending Email...')
                    : this.props.t('Email to Me')}
            </Button>
            </div>
        );
    }

    render() {
        const {t} = this.props;
        const {companyIds, isLoading, sendingEmail} = this.state;

        if (!this.props.me || 
            (
                !this.props.me.isUserManagerForCompany &&
                (!this.props.me.managedCompanyGroups || this.props.me.managedCompanyGroups.length === 0)
            )
        ) {
            return <NoAccess />;
        }

        const { isUserManagerForCompany } = this.props.me;

        const groups = this.props.me.managedCompanyGroups !== 0 && (isUserManagerForCompany !== 1) 
            ? companyIds
            : [{ id: 'own', title: 'My Company' }];

        if (groups && !isLoading) {
            return (
                <>
                    {(sendingEmail.status === 0 || sendingEmail.status === 1) && (
                    <Popup
                        size="auto"
                        blockContent={true}
                        onOk={() => this.setState({ sendingEmail: { user: null } })}
                        wrapperStyle={{
                            position: 'fixed',
                            insetBlockStart: 'unset',
                            insetBlockEnd: 0,
                            height: 'calc(100% - 2.2rem)'
                        }}
                    >
                        {this.props.t(
                            `${sendingEmail.status === 0 ? 'Error sending' : 'Email sent to'} email to ${this.state.sendingEmail.isSendingToManager ? this.state.sendingEmail.manager : sendingEmail.user}.`
                        )}
                        {sendingEmail.status === 0 && (
                            <p>{sendingEmail.errorMessage}</p>
                        )}
                    </Popup>
                )}
                    <GroupCardsContainer
                        groupName={{ 1: t('Company'), n: t('Companies') }}
                        onGroupOpened={(id, users, title) => {
                            this.setState({
                                currentGroup: {
                                    id,
                                    users,
                                    title
                                }
                            });
                        }}
                        itemName={{ 1: t('User'), n: t('Users') }}
                        buildGroupState={this.buildCompanyGroupState}
                        createGroup={null}
                        updateGroup={APICompanies.put}
                        deleteGroup={APICompanies.delete}
                        // getGroups={APICompanies.get}
                        groups={groups}
                        getGroupSearch={APICompanies.search}
                        searchTerm={this.props.searchTerm}
                        detailView={this.DetailView}
                        createView={this.CreateView}
                        groupView={null}
                        useGravatar
                        uneditable={true}
                        getGroup={this.getGroup}
                        getItem={this.getItem}
                        isUserManager={true}
                        listColumns={[
                            { key: 'title', label: 'Username' },
                            {
                                key: 'created',
                                label: 'Created On',
                                formatter: (value) => dateToString(new Date(value * 1000))
                            },
                            {
                                key: 'expiryTS',
                                label: 'Expiry Date',
                                formatter: (value) => dateToString(new Date(value * 1000))
                            },
                            { key: 'timezone', label: 'Timezone' },
                            { key: 'sendEmail', label: 'Send Email' },
                            { key: 'id', label: 'id', disable: true },
                            { key: 'onClick', label: 'onClick', disable: true }
                        ]}
                        customTableRenderers={{
                            [t('Send Email')]: this.renderSendEmailButton
                        }}
                        disabledColumnFilters={['Send Email']}
                        listView
                    />
                </>
            );
        } else {
            return <LoadingSpinner/>;
        }
    }
}

const mapStateToProps = state => ({ me: state.me });
export default withTranslation()(connect(mapStateToProps)(UserManager));
