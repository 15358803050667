import React from 'react'
import styles from '../Pricing.module.scss';
import plans from "../plans";
import PricingCard from '../PricingCard';
import { useSelector } from 'react-redux';

function PricingCards({onCardClicked, selectedPlan}:any) {
    // @ts-ignore
    const { customerId } = useSelector((state) => state.stripe.data);

    return (
        <div className={styles.cards}>
                {plans.map((plan, index) => {
                    return <PricingCard
                        key={index}
                        isSelected={plan.productId===selectedPlan?.productId}
                        features={plan.features}
                        title={plan.title}
                        price={plan.priceCent / 100}
                        priceId={plan.priceId}
                        customerId={customerId}
                        description={plan.description}
                        onClick={() => {
                            onCardClicked(plan);
                        }}
                    />
                })}
        </div>
    )
}

export default PricingCards