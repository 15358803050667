import { useTranslation } from "react-i18next";

const useTranslatedHeaders = (headers: string[] = [], noTranslateHeaders: string[] = []): string[] => {
    
    const { t } = useTranslation();
    
    if (!headers || !noTranslateHeaders) {
        return [];
    }

    const translatedColumnHeaders = headers.map((tString: string) => {
        const translatedValue = t(tString) || "";
        return noTranslateHeaders.includes(tString) ? tString : translatedValue;
    });

    return translatedColumnHeaders;
}

export default useTranslatedHeaders;
