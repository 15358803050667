import { useEffect, useState } from "react";
import IFrameWrapper from "../common/basicElements/IFrameWrapper/IFrameWrapper";
import CustomIframeSettings from "./CustomIframeSettings";
import styles from './CustomIframe.module.scss';
import { useTranslation } from "react-i18next";
import { cacheBust } from "../common/helpers";

function CustomIframe({
    state,
    saveWidgetState,
    workspaceId,
    widgetId,
    allowTitleChange=true,
}) {
    const [url, setUrl] = useState(undefined);
    const [widgetTitle, setWidgetTitle] = useState(undefined);

    const { i18n } = useTranslation();
    
    useEffect(()=>{
        setUrl(state.settings?.url);
        setWidgetTitle(state.settings?.widgetTitle);
    }, [state]);
    
    let finalUrl = url?.replace(/{{LANG}}/, i18n.language);
    if (finalUrl) {
        const re = RegExp(`^${process.env.PUBLIC_URL}/academy_api/.*`,'g')
        if (finalUrl.match(re)) {
            // apply cacheBust on url if url starts with public_url (e.g. '/v3/some')
            finalUrl = cacheBust(finalUrl);
        }
    }

    return (
        <div className={styles.wrapper}>
            {state.showSettings &&
                <CustomIframeSettings
                    allowTitleChange={allowTitleChange}
                    init={{widgetTitle, url}}
                    onSave={(data) => {
                        const {url, widgetTitle} = data;
                        saveWidgetState(workspaceId, widgetId, { state, settings: {url, widgetTitle} });
                        setUrl(url);
                    }}
                />
            }
            <IFrameWrapper
                url={finalUrl}
                wrapperStyle={{height:'100%'}}
            />
        </div>
    );
}

export default CustomIframe;