import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import Titlebar from './Titlebar/Titlebar';
import Workspace from './Workspace/Workspace';
import _ from 'lodash';
import Home from './Home/Home';

// import demo from './demo';
import { connect } from 'react-redux';
import {
    getWorkspaces, putWorkspace, postWorkspace, deleteWorkspace,
    putWidget, deleteWidget
} from '../redux/actions/actions.workspaces';
import { getAccountSettings } from 'src/redux/actions/actions.theme';

import About from './About/About';
import LoadingSpinner from '../Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import APISettings from 'src/API/APISettings';
import Page from '../Widgets/common/basicElements/Page/Page';
import { setCurrentWorkspace, removeAllDeprecatedWidgets, ignoreDeprecatedWidgets, setIsEditable as boardSetIsEditable, setLoadingGlobal } from '../redux/actions/actions.board';
import config from 'src/config/config.js';
import Popup from 'src/Widgets/common/basicElements/Popup/Popup';
import { withTranslation } from 'react-i18next';
import ErrorBoundary from 'src/ErrorBoundary/ErrorBoundary';
// import TourProvider from 'src/Tour/TourProvider.js';
import JoyrideTour from '../Tour/JoyrideTour';
import { createDefaultWorkspaces, getAPISettingsType } from 'src/Widgets/common/helpers';
import { dynamicConfig } from "src/config/dynamicConfig/dynamicConfig";
import EmmBanner from 'src/Login/EmmBanner/EmmBanner';
import AccountSetup from './AccountSetup/AccountSetup';
import SubscriptionsManagement from 'src/stripe/SubscriptionsManagement/SubscriptionsManagement';
import Pricing from 'src/stripe/Pricing/Pricing';
import { ROUTES } from 'src/consts';
import {faChartLine, faCompass, faFileVideo, faFilm, faGlobe, faLayerGroup, faQuoteRight, faRetweet, faSearch, faStar, faTable } from '@fortawesome/free-solid-svg-icons';
import { reorderItems } from 'src/Widgets/common/helpers-tsx';

const { defaultWorkspaces } = dynamicConfig;

// TODO: investigate bug (looks like this is not happening anymore): looks like ui is crashing (sometimes?) if removing a widget and then adding another one
// looks like this is related to the recent change of remembering widgetSettings / state (and is a timing based issue ?)

class Board extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayMenuPortrait: false,
            workspaces: [],
            currentWorkspace: -1,
            isDesktop: undefined,
            toDelete: new Set(),
            widgetsToDelete: new Set(),
            prevWorkspaces: undefined,
            loading: true,
            keys: {},   // currently pressed keys - e.g. check for ctrl / shift / alt to enable/disable scroll
            settingUpAcc: false,    // if true, account setup loading banner showing up
        };

        // binding 'this' to methods for use of 'this' in components further down
        this.toggleDisplayMenuPortrait = this.toggleDisplayMenuPortrait.bind(this);
        this.addWorkspace = this.addWorkspace.bind(this);
        this.removeWorkspace = this.removeWorkspace.bind(this);
        this.setCurrentWorkspace = this.setCurrentWorkspace.bind(this);
        this.changeWorkspaceName = this.changeWorkspaceName.bind(this);
        this.changeWorkspaceLayout = this.changeWorkspaceLayout.bind(this);
        this.toggleBoardEdit = this.toggleBoardEdit.bind(this);
        this.updateIsDesktop = this.updateIsDesktop.bind(this);
        this.saveWidgetState = this.saveWidgetState.bind(this);
        this.getWidgetState = this.getWidgetState.bind(this);
        this.addWidget = this.addWidget.bind(this);
        this.toggleWidgetSettings = this.toggleWidgetSettings.bind(this);
        this.removeWidget = this.removeWidget.bind(this);
        this.getWorkspaceIcon = this.getWorkspaceIcon.bind(this);
        this.checkIfReportWorkspace = this.checkIfReportWorkspace.bind(this);
        this.setWorkspaceUIPosition = this.setWorkspaceUIPosition.bind(this);
    }

    async componentDidMount() {
        if(this.props.board.showAcademy !== undefined){
            this.props.getWorkspaces();
        }
        this.props.getAccountSettings();

        window.addEventListener('keydown', this.onKeyDown);
        window.addEventListener('keyup', this.onKeyUp);

        window.addEventListener("resize", this.updateIsDesktop);
        this.updateIsDesktop(); 
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
        window.removeEventListener('keyup', this.onKeyUp);
        window.removeEventListener("resize", this.updateIsDesktop);
    }

    componentDidUpdate(prevProps, prevState) {
        let loading = false;
        let settingUpAcc = false;
        // fetch tour/user workspaces if starting/ending tour
        if (prevProps.tour.show !== this.props.tour.show) {
            this.props.getWorkspaces();
        }
        if (prevProps.workspaces !== this.props.workspaces) {
            if(prevProps.workspaces?.length === 0 && this.props.workspaces?.length === 0){
                // user has no workspaces - setting default workspaces for user
                settingUpAcc = true;
                (async()=>{
                    await createDefaultWorkspaces(defaultWorkspaces);
                    this.props.getWorkspaces();
                    settingUpAcc = false;
                }
                )();
            }
            this.setState({workspaces: this.props.workspaces.map((workspace) => {
                if(workspace.name === this.props.t('Recent_clips')){
                    workspace.name = 'Recent Clips';
                }else if(workspace.name === this.props.t('Edited_clips')){
                    workspace.name = 'Edited Clips';
                }
                return {
                    ...workspace,
                   icon: this.getWorkspaceIcon(workspace.name),
                }
            }), loading: loading, settingUpAcc});
        }
        if(
            this.props.board.isEditable === true && this.workspaceTitleRef
            && (prevProps.board.isEditable === false
                || prevProps.location.pathname !== this.props.location.pathname
                || (prevState.workspaces.length === 0 && this.state.workspaces.length > 0) )
            ){
            this.workspaceTitleRef.focus();
        }
    }

    // checks if it is desktop or mobile and disables editable if its mobile
    updateIsDesktop() {
        const isDesktop = window.innerWidth > config.mobileDesktopBreakpoint;

        this.setState(state => 
            ({ isDesktop })
        );
        this.props.boardSetIsEditable(isDesktop ? this.props.board.isEditable : false);
    }
    
    toggleDisplayMenuPortrait() {
        this.setState(state => ({
            displayMenuPortrait: !state.displayMenuPortrait
        }));
    }

    async addWorkspace() {
        this.setState(state => ({
            workspaces: 
                _.concat(
                    state.workspaces, 
                    
                    { id: undefined
                    , name: 'New Workspace'
                    , widgets: []
                    , icon: this.getWorkspaceIcon('New Workspace')
                    , uiPosition: _.maxBy(this.state.workspaces, 'uiPosition').uiPosition + 1
                    }
                )
        }),()=>{
                this.props.history.push('/workspaces/' + (this.state.workspaces.length));
        });
    }

    async removeWorkspace(i) {
        this.setState(state => {
            let newCurrentWorkspace = state.currentWorkspace;

            if (state.currentWorkspace === i) {
                newCurrentWorkspace = -1;
                // deleting the workspace we're on? to home we go
                this.props.history.push('/');
            } else if (state.currentWorkspace > i) {
                newCurrentWorkspace--;

                if (newCurrentWorkspace > -1) {
                    // redirect to new current ws
                    this.props.history.push('/workspaces/' + (newCurrentWorkspace + 1));
                }
            }

            const id = state.workspaces[i].id;
            const widgetsToDelete = new Set([
                ...state.widgetsToDelete,
                ...state.workspaces[i].widgets.map((w) => w.dbId)
            ]);
            state.workspaces.splice(i, 1);

            return {
                workspaces: state.workspaces,
                currentWorkspace: newCurrentWorkspace,
                widgetsToDelete,
                toDelete: id !== undefined ? new Set([...state.toDelete, id]) : state.toDelete
            };
        });
    }

    setWorkspaceUIPosition(oldPos, newPos) {
        this.setState((state) => ({
            workspaces: reorderItems(state.workspaces, oldPos, newPos)
        }))
    }

    addWidget(workspaceId, widget){
        const workspace = _.find(this.state.workspaces, currentWorkspace => currentWorkspace.id === workspaceId)
        workspace.widgets.push(widget);
    }

    removeWidget(workspaceIndex, widgetIndex) {
        this.setState(state => {
            const wi = this.state.workspaces[workspaceIndex].widgets.findIndex(w => w.id === parseInt(widgetIndex));
            let workspaces = _.cloneDeep(state.workspaces);
            workspaces[workspaceIndex].widgets.splice(wi, 1);
            const dbId = state.workspaces[workspaceIndex].widgets[wi].dbId;
            let widgetsToDelete = [...state.widgetsToDelete];
            if (dbId) widgetsToDelete.push(dbId);
            return {
                workspaces,
                widgetsToDelete: new Set(widgetsToDelete)
            }
        });
    }

    setCurrentWorkspace(workspaceId) {
        this.props.setCurrentWorkspace(workspaceId);    // id is acutally index ?
        this.setState({ currentWorkspace: workspaceId });
    }

    changeWorkspaceName(workspaceIndex, newName) {
        this.setCurrentWorkspace(workspaceIndex);

        this.setState(state => {
            state.workspaces[workspaceIndex].name = newName;
            return { workspaces: state.workspaces };
        });
    }

    changeWorkspaceLayout(workspaceIndex, newLayout) {
        if (this.props.board.isEditable) {
            this.setState(state => {
                state.workspaces[workspaceIndex].widgets = newLayout;
                return { workspaces: state.workspaces };
            });
        }
    }

    async saveBoardChanges() {
        const { workspaces, toDelete, widgetsToDelete } = this.state;
        const {
            postWorkspace, putWorkspace, deleteWorkspace,
            deleteWidget
        } = this.props;

        let out = 
            {
                workspaces: _.cloneDeep(workspaces)
                , toDelete: new Set()
            }

        for (let workspace of workspaces) {
            if (workspace.id !== undefined) {   // modifying existing workspace
                putWorkspace(workspace);
            }
            else {   // adding new workspace
                postWorkspace(workspace).then((res) => {
                    if(res.rc === 0){
                        this.props.history.push('/workspaces/' + workspaces.length)
                    }  
                });
                
            }
        }

        // for (let i = 0; i < workspaces.length; ++i) {
        //     let workspace = workspaces[i];

        //     if (workspace.id !== undefined) {   // modifying existing workspace
        //         putWorkspace(workspace);
        //     }
        //     else {   // adding new workspace
        //         postWorkspace(workspace);
        //         const isWorkspaceAdded = workspaces.some((ws) => {
        //             return workspace.name === ws.name
        //         })
        //         setTimeout(() => {
        //             if(isWorkspaceAdded){
        //                 this.props.history.push('/workspaces/' + workspaces.length)
        //             }
        //         }, 800)
        //     }

        for (let i of toDelete) {
            deleteWorkspace(this.props.workspaces.find((workspace)=>workspace.id === i));
        }
        for (let i of widgetsToDelete) {
            deleteWidget(i);
        }

        this.props.boardSetIsEditable(false);
        this.setState(out);
    }

    async saveWidgetState(workspaceId, widgetId, data) {
        let workspaces = this.state.workspaces
        let workspace = _.find(workspaces, currentWorkspace => currentWorkspace.id === workspaceId)
        if(workspace !== undefined) {       //when the workspace was just created it is undefined
            let widgets = workspace.widgets
            let widget = _.find(widgets, currentWidget => currentWidget.id === widgetId)
            if(widget !== undefined) {      //when the workspace was just created it is undefined
                widget.state = data;
                await this.props.putWidget(widget);
            }
        }
    }

    async getWidgetState(workspaceId, widgetId) {
        const api = getAPISettingsType();
        let data = (await api.getItems('workspaces')).data;
        data = _.find(data, workspace => workspace.id === workspaceId)
        if(data !== undefined) {        //when the workspace was just created it is undefined
            data = data.widgets
            data = data = _.find(data, widget => widget.id === widgetId)
            if(data !== undefined) {    //when the workspace was just created it is undefined
                data = data.state
            }
        }
        return data;
    }

    cancelBoardChanges() {
        this.setState(s => (
            {
                workspaces: s.prevWorkspaces
            }
        ))
        this.props.boardSetIsEditable(false);
    }

    toggleBoardEdit(cancel) {
        // edit -> non-edit
        if (this.props.board.isEditable) {
            if (cancel) this.cancelBoardChanges();
            else this.saveBoardChanges();
        }
        // non-edit -> edit
        else {
            this.props.boardSetIsEditable(true);
            this.setState(s => (
                {
                    prevWorkspaces: _.cloneDeep(s.workspaces)
                }
            ));
        }
    }

    getWorkspaceIcon = (workspaceName) => {
        let icon;
        let reportWorkspaces = this.checkIfReportWorkspace();
        switch(workspaceName){
            case 'Recent Clips':
                icon = faFilm;
                break;
            case 'Edited Clips':
                icon = faFileVideo;
                break;
            case this.props.t('Coverage'):
                icon = faGlobe;
                break;
            case this.props.t('Go To'):
                icon = faCompass;
                break;
            case this.props.t('Waterfall'):
                icon = faRetweet;
                break;
            case this.props.t('Profiles'):
                icon = faQuoteRight;
                break;
            case this.props.t('Channel Groups'):
                icon = faLayerGroup;
                break;
            case this.props.t('Search'):
                icon = faSearch;
                break;
            case this.props.t('Analytics'):
                icon = faChartLine;
                break;    
            case reportWorkspaces.includes(workspaceName) ? workspaceName : '':
                icon = faTable;
                break;      
            default:
                icon = faStar;                     
        }
        return icon;
    }
    
    toggleWidgetSettings(widgetId) {
        const { workspaces, currentWorkspace } = this.state;
        let widget = _.find(workspaces[currentWorkspace].widgets, { id: widgetId });
        widget.state.showSettings = widget.state.showSettings ? false : true;
        this.saveWidgetState(workspaces[currentWorkspace].id, widgetId, widget.state);

        this.setState({ workspaces: _.cloneDeep(workspaces) });
    }

    // helper functions
    getWorkspaceProperty(state, prop) {
        return _.map(state.workspaces, workspace => _.get(workspace, prop));
    }

    onKeyDown = (e) => {    // TODO: move to redux to allow components deciding on their own if they want to receive this information ?
        if (['Shift', 'Shift'].includes(e.key)) {
            let keys = { ...this.state.keys };
            keys[e.key] = true;
            this.setState({ keys });
        }
}

    onKeyUp = (e) => {
        if (['Shift', 'Shift'].includes(e.key)) {
            let keys = { ...this.state.keys };
            keys[e.key] = false;
            this.setState({ keys });
        }
    }

    checkIfReportWorkspace = () => {
        // check all workspaces for a property 'createdBy' and if it is true, return the workspace name
        let reportWorkspaces = [];
        this.state.workspaces.forEach(workspace => {
            if(workspace.createdBy){
                reportWorkspaces.push(workspace.name);
            }
        });
        return reportWorkspaces;
    }
    
    render() {
        const workspaces = this.state.workspaces
            .map((workspace) => ({
                name: workspace.name,
                icon: workspace.icon,
                uiPosition: workspace.uiPosition,
            }));

        let title = null;
        if (this.props.location.pathname.includes('workspaces')) {
            title = this.state.workspaces[this.state.currentWorkspace]
                ? this.state.workspaces[this.state.currentWorkspace].name
                : null;
        }

        if (this.state.loading) {
            return <LoadingSpinner />;
        } else if(this.state.settingUpAcc){
            return <AccountSetup/>
        } else if(this.props.board.deprecatedWidgets && !this.props.board.ignoreDeprecatedWidgets){
            return (
                <Popup
                    size='auto'
                    blockContent={true}
                    onOk={()=>{this.props.removeAllDeprecatedWidgets(this.props.board.deprecatedWidgets, this.props.workspaces)}}
                    onCancel={()=>{this.props.ignoreDeprecatedWidgets()}}
                >
                    {this.props.t('widgets_deprecated_confirm')}
                    <ul>{Object.keys(this.props.board.deprecatedWidgets).map((key)=>{
                        let workspace = this.props.board.deprecatedWidgets[key];
                        return workspace.map(e=>{
                            return <li>{`${this.props.t('Workspace')} "${e.workspaceName}", ${this.props.t('widget type')}: "${e.widgetName}"\n`}</li>
                        })
                    })}
                    </ul>
                    
                </Popup>
            )
        } else {
            return (
                // <TourProvider>
                <div data-tut="tour__board" id="board" style={{ backgroundColor: '#F5F5F5' }}>
                    <span data-tut="tour_placeholder" style={{width:'1px', height:'1px'}}/>
                    {!this.props.board.showAcademy && <JoyrideTour />}
                    { this.props.board.chatIsLoading &&
                        <Popup
                            size='auto'
                            blockContent={false}
                        >
                            <LoadingSpinner size={"2rem"}/>
                            <div style={{ marginTop: '1rem' }}>
                                {this.props.t('chat_loading')}
                            </div>
                        </Popup>
                    }
                    {this.props.board.loadingGlobal &&
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            background: 'white',
                            zIndex: 14,
                        }}>
                        <LoadingSpinner />
                    </div>
                    }
                        <Navbar
                            className={this.state.displayMenuPortrait ? 'display' : ''}
                            toggleDisplayMenuPortrait={this.toggleDisplayMenuPortrait}
                            workspaces={workspaces}
                            addWorkspace={this.addWorkspace}
                            removeWorkspace={this.removeWorkspace}
                            toggleBoardEdit={this.toggleBoardEdit}
                            editable={this.props.board.isEditable}
                            currentWorkspace={this.state.currentWorkspace}
                            location={this.props.location}
                            isDesktop={this.state.isDesktop}
                            setWorkspaceUIPosition={this.setWorkspaceUIPosition}
                        />
        
                        <section>
                            <Titlebar
                                title={title}
                                titleRef={(r) => { this.workspaceTitleRef = r; }}
                                titleBarRef={(r) => { this.titleBarRef = r; }}
                                currentWorkspace={this.state.currentWorkspace}
                                changeWorkspaceName={this.changeWorkspaceName}
                                displayMenuPortrait={this.state.displayMenuPortrait}
                                toggleDisplayMenuPortrait={this.toggleDisplayMenuPortrait}
                                editable={
                                    this.props.location.pathname.includes('workspaces')
                                        ? (this.props.board.isEditable && this.state.workspaces.length > 0 )
                                        : false
                                }
                                unauthenticate={this.props.unauthenticate}
                            />
                            <Switch>
                                {/* Only one of those should be rendered at a time */}
                                <Route exact path="/" component={() => {
                                    if (this.state.workspaces.length > 0) {
                                        return <Redirect to={{ pathname: '/workspaces/1' }} />;
                                    } else {
                                        return <Home toggleMenu={this.toggleDisplayMenuPortrait} />
                                    }
                                }} />

                                {/* generate a <Workspace /> route for every workspace in the state */
                                    _.map(this.state.workspaces, (workspace, i) => {
                                        return (
                                            <Page
                                                key={i}
                                                title={workspace.name}
                                                path={'/workspaces/' + (i + 1)}
                                            render={() => (
                                                <ErrorBoundary>
                                                    <Workspace
                                                        name={workspace.name}
                                                        id={i}      //Index of workspace in Workspace list
                                                        workspaceId={this.state.workspaces[i].id} //Id of workspace in the API
                                                        setCurrentWorkspace={this.setCurrentWorkspace}
                                                        // layout={workspace.layout}
                                                        saveWidgetState={this.saveWidgetState}
                                                        getWidgetState={this.getWidgetState}
                                                        widgets={workspace.widgets}
                                                        changeLayout={this.changeWorkspaceLayout}
                                                        editable={this.props.board.isEditable}
                                                        location={this.props.location}
                                                        history={this.props.history}
                                                        addWidget={this.addWidget}
                                                        removeWidget={this.removeWidget}
                                                        toggleWidgetSettings={this.toggleWidgetSettings}
                                                        keys={this.state.keys}
                                                        titleBarRef={this.titleBarRef}
                                                    />
                                                    </ErrorBoundary>
                                                )}
                                            />
                                        );
                                    })}
                                <Page title="Settings" path="/account/settings" render={ () =>
                                    <dynamicConfig.settingsComponent
                                        history={this.props.history} 
                                        showAcademy={this.props.board.showAcademy}
                                    />
                                } />
                            
                                <Page title="Settings" path="/account/subscriptions" render={ () =>
                                    <SubscriptionsManagement/>
                                } />
                            
                                <Page title="Settings" path={ROUTES.upgrade_plan} render={ () =>
                                    <Pricing/>
                                } />
                            
                                <Page title="About" path="/about" component={About} />
                                <Redirect to={{ pathname: '/workspaces/1' }} />
                            </Switch>
                        </section>
                </div>                 
                // </TourProvider>
            );
        }
    }
}

const mapStateToProps = (state) => ({ 
    workspaces: state.workspaces,
    board: state.board,
    tour: state.tour
});

export default connect(mapStateToProps, {
    getWorkspaces, postWorkspace, putWorkspace, deleteWorkspace, setCurrentWorkspace,
    putWidget, deleteWidget,
    removeAllDeprecatedWidgets, ignoreDeprecatedWidgets,
    getAccountSettings,
    boardSetIsEditable,
    setLoadingGlobal
})(withTranslation()(Board));