import React, { useMemo, FC } from 'react';
import styles from './TableHeading.module.scss';
import ChannelButtons from '../ChannelButtons/ChannelButtons';
import { useTranslation } from 'react-i18next';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';
import { ChannelButtonsProps } from '../ChannelButtons/ChannelButtons.types';
import { useAppSelector } from 'src/redux/hooks';
import { Channel, ChannelTypeUpperCase } from 'src/@types/shared.types';

const TableHeading: FC<ChannelButtonsProps> = ({
    setIsOpen,
    setButtonType,
    channelsNotInGroup,
    channelsWillRemovedInGroup,
    isChannelGroupsWidget
}) => {

    const { t } = useTranslation();
    const isMobileOnPhone = useIsScreenSize(864);

    const {
        selectedChannels,
        selectedButtonStatus,
        selectedGroupTitle,
        allChannelsShown,
        selectedGroupId,
        searchedChannels,
        selectedGroupChannels,
        filterValuesArray,
        allChannels
    } = useAppSelector((state: any) => ({
        ...state.channelGroupsWidgetReducer,
        allChannels: state.channelReducers
    }));

    const allChannelsCount = allChannels?.data?.length || '0';
    const selectedGroupCount = selectedGroupChannels?.length || '0';
    const filteredChannelCount = searchedChannels?.includes('')
        ? '0'
        : searchedChannels?.length || '0';

    const headingText = useMemo(() => {
        let text = '';
        let count = null;
        if (allChannelsShown && !selectedButtonStatus) {
            text = t('All Channels');
            count = allChannelsCount;
        } else if (selectedGroupId && !selectedButtonStatus) {
            text = `${selectedGroupTitle}`;
            count = selectedGroupCount;
        } else if (selectedButtonStatus && selectedChannels?.length) {
            text = t('Selected Channels');
            count = selectedChannels?.length;
        }
        return { title: text, count };
    }, [
        allChannelsShown,
        selectedChannels,
        selectedButtonStatus,
        selectedGroupId,
        allChannelsCount,
        selectedGroupCount,
        t
    ]);

    const channelsNotInGroupText = useMemo(() => {
        const checkChannelType = (channel: Channel) => {
            return (channel as ChannelTypeUpperCase).Name !== undefined;
        };
        const names = channelsNotInGroup
            ?.map((channel: Channel) => {
                return checkChannelType(channel) ? channel.Name : channel.name;
            })
            .join(', ');
        return (names && `, ${channelsNotInGroup?.length} ${t('Not Shown')}`) || '';
    }, [channelsNotInGroup, t]);

    const channelCount = selectedChannels?.length;
    const showSelectedChannelCount = useMemo(
        () => (channelCount > 0 ? `${channelCount} ${t('Selected')}, ` : ''),
        [channelCount, t]
    );

    return (
        <div className={styles.headingContainer}>
            <div className={styles.headingSection}>
                <h2 data-testid="count-view">
                    {t('List of Channels')}
                    {headingText?.title ? ` "${headingText.title}"` : ''}
                    {isMobileOnPhone && <br />}
                    {` (${showSelectedChannelCount}${
                        headingText?.count && searchedChannels?.length && allChannelsShown
                            ? allChannelsCount && filteredChannelCount
                            : (selectedGroupCount && filteredChannelCount) ||
                              (!selectedGroupId && allChannelsCount)
                    } ${t('Displayed')}${
                        !allChannelsShown ||
                        filterValuesArray?.length ||
                        !selectedButtonStatus
                            ? channelsNotInGroupText
                            : ''
                    })`}
                </h2>
            </div>
            <ChannelButtons
                setIsOpen={setIsOpen}
                setButtonType={setButtonType}
                allChannelsCount={allChannelsCount}
                channelsWillRemovedInGroup={channelsWillRemovedInGroup}
                isChannelGroupsWidget={isChannelGroupsWidget}
            />
        </div>
    );
};

export default TableHeading;
