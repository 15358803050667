import Button from 'src/Widgets/common/basicElements/Button/Button';
import styles from './PricingCard.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface PricingCardProps{
    title: string,
    price: number,
    unit?: string,
    description?: string,
    onClick: () => void,
    features: string[],
    priceId: string,
    customerId: string,
    isSelected?: boolean,
}

function PricingCard({ price, title, unit = 'US $', description, onClick, features, priceId, customerId, isSelected }: PricingCardProps) {
    const { t } = useTranslation();
    // @ts-ignore
    const theme = useSelector((state) => state.theme);

    return (
        <div className={[styles.card, isSelected ? theme.backgroundSecondary : undefined].join(' ')}>
            <div className={styles.content}>
                <div><b>{title}</b></div>
                <div>
                    <ul>
                        {features.map(feature => <li>{feature}</li>)}
                    </ul>
                </div>
                <div className={styles.price}>{unit}{price}</div>
                <div>{description}</div>
                    <Button btnType='submit' type='primary' onClick={onClick}>{t('Continue')}</Button>
                </div>
        </div>
    )
}
export default PricingCard;