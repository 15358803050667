import React, { useState } from 'react';

interface UseCheckboxStateOptions {
    onToggle: (header: string, rowIndex: number, checked: boolean, value: any) => void;
}

interface UseFilterCheckboxStateReturnValues {
    checkedState: { [header: string]: { [rowIndex: number]: { checked: boolean; value: any } } };
    handleCheckboxClick: (e: React.ChangeEvent<HTMLInputElement>, header: string, rowIndex: number) => void;
    hasCheckedItems: (header: string) => boolean;
    currentlyCheckedValues: any[];
    setCheckedState: React.Dispatch<React.SetStateAction<{ [header: string]: { [rowIndex: number]: { checked: boolean; value: any } } }>>;
}

const useGenericTableFilterCheckbox = (options: UseCheckboxStateOptions): UseFilterCheckboxStateReturnValues => {

    const [checkedState, setCheckedState] = useState<{ [header: string]: { [rowIndex: number]: { checked: boolean; value: any } } }>({});

    //handleCheckboxClick is used for checking and unchecking states of the checkboxes inside filterboxes
    const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>, header: string, rowIndex: number) => {
        setCheckedState((prevState) => {
            const updatedState = { ...prevState };

            if (!updatedState[header]) {
                updatedState[header] = {};
            }

            const checked = !e.target.checked;
            let value: any = null;

            if (!checked) {
                value = options.onToggle(header, rowIndex, true, rowIndex);
                updatedState[header][rowIndex] = {
                    checked: true,
                    value: value ?? rowIndex,
                };
            } else {
                delete updatedState[header][rowIndex];
                options.onToggle(header, rowIndex, false, rowIndex);
            }

            return updatedState;
        });
    };

    //Its checking if filterboxes have checked values
    const hasCheckedItems = (header: string): boolean => {
        const headerState = checkedState[header];
        return headerState ? Object.values(headerState).some(item => item.checked) : false;
    };

        // function to get currently checked values
        const getCheckedValues = (): any[] => {
            const values: any[] = [];
            Object.keys(checkedState).forEach((header) => {
                const headerValues: any[] = [];
                Object.values(checkedState[header]).forEach((item) => {
                    if (item.checked) {
                        // Push the value into the headerValues array
                        headerValues.push(item.value);
                    }
                });
                // Push an object with the header and values array
                if (headerValues.length > 0) {
                    values.push({ header: header, values: headerValues });
                }
            });
            return values;
        }

    const currentlyCheckedValues = getCheckedValues();

    return { checkedState, handleCheckboxClick, hasCheckedItems, currentlyCheckedValues, setCheckedState };
};

export default useGenericTableFilterCheckbox;
