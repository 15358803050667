import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStar } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faStar, faStarRegular);

const StarInactive = () => (<FontAwesomeIcon style={{ color: 'black' }} icon={faStarRegular} />);
const StarActive = ({style}:any) => (<div style={{ position: 'relative', display: 'inline-block', width:'1em', height:'1em' }}>
    <FontAwesomeIcon style={{ color: 'black', position: 'absolute', top: 0, left: 0, zIndex:1, width:'100%', height:'100%', ...style }} icon={faStarRegular} />
    <FontAwesomeIcon style={{ color: 'yellow', position: 'absolute', top: 0, left: 0, width:'100%', height:'100%', ...style }} icon={faStar} />
</div>);

interface StarProps{
    style?: any,
    active?: boolean
}

function Star({style, active}:StarProps) {
    
    return active ? <StarActive style={style} /> : <StarInactive/>

}

export default Star;