import { setChatLoading } from "src/redux/actions/actions.board";

export function openChat(){
  // loads scripts (if not done yet), then opens chat
  initChat( () => {
    window.SI_API.showTab();
    window.SI_API.showPopup();
  });
}

function loadScript(url, onLoad){
  let head = document.getElementsByTagName('head')[0];
  let script = document.createElement('script');
  script.src = url;
  script.defer = true;
  script.async = false;
  script.onload = onLoad;
  head.appendChild(script);
}

function closeChat(){
  if(window.SI_API){
    window.SI_API.hideTab();
    window.SI_API.showPopup();
  }
}

function initChat(onAfterInit){
  const socialintentsSrc = `${process.env.PUBLIC_URL}/lib/socialintents.1.3.js#2c9fa563696d207d01697142fb8e025a`;
  const isInit = [...document.getElementsByTagName('script')].some(e=>e.src === `${window.location.origin}${socialintentsSrc}` );

  if (!isInit) {
    window.rxStore.dispatch(setChatLoading(true));  // set redux state to allow UI to show loading indicator
    window.onSIApiReady = function () {  // gets called after socialintent is ready
      window.rxStore.dispatch(setChatLoading(false));
      if(onAfterInit){ onAfterInit(); }
      window.SI_API.onChatEnded = closeChat;
    }

    // ensure we always loaded jquery 3.5.1 already before adding socialintents
    // socialintents would load an additional (older) version otherwise
    loadScript(`${process.env.PUBLIC_URL}/lib/jquery-3.5.1.min.js`, function(){
      loadScript(socialintentsSrc)
    });
  } else if(!window.SI_API){
    // TODO: this case needs proper solution if we introduce more possibilities than just calling openChat()
  } else {
    onAfterInit();
  }
}