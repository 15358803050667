import React from "react";
import { connect } from "react-redux";
import CustomIframe from "../CustomIframe/CustomIframe";
import CourseEarnedBadges from "./CourseEarnedBadges/CourseEarnedBadges";
import styles from './CourseOverview.module.scss';

function CourseOverview({
    state,
    saveWidgetState,
    workspaceId,
    widgetId,
}){

    return (
        <div className={styles.wrapper}>
            <div style={{flexGrow: 1}}>
                <CustomIframe
                    state={state}
                    saveWidgetState={saveWidgetState}
                    workspaceId={workspaceId}
                    widgetId={widgetId}
                    allowTitleChange={false}
                />
            </div>
            <div className={styles.badges}>
                <CourseEarnedBadges url={state?.settings?.url} />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
});
export default connect(mapStateToProps,{ 
 })(CourseOverview);
