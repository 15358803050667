import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './CourseEarnedBadges.module.scss';
import { cacheBust, isRelativeUrl } from "src/Widgets/common/helpers";
import { connect } from "react-redux";
import APIQuiz from "src/API/APIQuiz";
import _ from 'lodash';

// TODO: currently showing either 0 or both badges (if quiz is done).
// should instead: show first badge after reading theory (confirm with button)

function CourseEarnedBadges({
    url,
    quizzes
}) {
    const [quiz, setQuiz] = useState(_.cloneDeep(undefined));
    const [quizDone, setQuizDone] = useState(_.cloneDeep(undefined));

    const { t, i18n } = useTranslation(); 
    let badges;
    // const total = 2;
    if(url){
        const badegesPath = url?.replace(/\/[^/]*$/, '');
        badges = [
            // { img: cacheBust(`${badegesPath}/badge-1.svg`) },
            { img: cacheBust(`${badegesPath}/badge-2.svg`) },
        ];
    }

    useEffect(() => {
        // improvement: cleanup. get some of the 'quiz finished' handling outside of here. maybe move to redux ?
        // also: do not rely on url path ?
        const quizUrl = url?.replace(/\/[^/]*$/, '') + '/quiz.json';
        (async () => {
            let langUrl = quizUrl.replace(/{{LANG}}/, i18n.language);
            const finalUrl = isRelativeUrl(langUrl) ? cacheBust(`${langUrl}`) : langUrl;
            const quiz = await APIQuiz.getQuiz(finalUrl);
            setQuiz(quiz);
        })()
    }, [url]);

    useEffect(() => {
        if (quiz && quiz.questions && url && quizzes?.data?.length > 0) {
            const quizAnswers = _.find(quizzes.data, { quiz_id: quiz.id })?.correctQuestionIds;
            let quizDone;
            if (quizAnswers) {
                quizDone = quiz.questions.every(q=>quizAnswers.includes(q.id))
            }
            if (quizDone) { setQuizDone(true); }
        }
    }, [quiz, url, quizzes])

    const emptyBadge = (
        <svg className={styles.badge} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="49" fill="lightgray" />
            <text x="47" y="57.5" fontSize="25px">?</text>
        </svg>
    );

    if(!badges) return null;

    return (
        <div className={styles.wrapper}>
            {/* <div className={styles.header}>
                <h2>{t('Badges Earned')}</h2>
                <div>{`(${(!quizDone ? 0 : badges.length)}/${(total)})`}</div>
            </div> */}
            <div className={styles.badges}
            >
                {badges.map((b, i) => {
                    return (quizDone ?
                        <img key={i} className={styles.badge} alt="" src={b.img}/>
                        : <div key={i}>{emptyBadge}</div>
                    )
                })}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    quizzes: state.quizzes
});

export default connect(mapStateToProps,{ })(CourseEarnedBadges);