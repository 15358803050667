import GenericCalls from "./genericCalls";

class APIClips {
    static async getClips(profileIds: string, startDate: string, endDate: string) {
        const url = `/api/analytics/_recent/select/profiles/${encodeURIComponent(profileIds)}?from=${encodeURIComponent(startDate)}&to=${encodeURIComponent(endDate)}`;
        return await GenericCalls.get(url);
}
/*     static async getEditorialClips(profileId: string, startDate: string, endDate: string) {
        const url = `/api/analytics/_recent2/select/profiles/${encodeURIComponent(profileId)}?from=${encodeURIComponent(startDate)}&to=${encodeURIComponent(endDate)}`;
        return await GenericCalls.get(url);
    } */

    static async getCountsByProfile(profileId: string[] | string, startDate: string, endDate: string) {
        const url = `/api/analytics/_count/by/type,cc,profileID,userdate/select/profiles/${profileId}?from=${encodeURIComponent(startDate)}&to=${encodeURIComponent(endDate)}`;
        return await GenericCalls.get(url);
    }

    static async getEditedClips(profileId: string, startDate: string, endDate: string) {
        const url = `/api/analytics/_eFeed/select/profiles/${encodeURIComponent(profileId)}e?from=${encodeURIComponent(startDate)}&to=${encodeURIComponent(endDate)}`;
        return await GenericCalls.get(url);
    }

    static async getSearchQueryClips(searchProfile: string, startDate: string, endDate: string) {
        const url = `/api/search?outputType=jsonTnotif&tnotProfileid=${searchProfile}&tnotQueryid=0&profileid=${searchProfile}&from=${encodeURIComponent(startDate)}&to=${encodeURIComponent(endDate)}`;
        return await GenericCalls.get(url);
    }

    static async makeClipsPermanent(profileID: string | number, notifIDs: string[]) {
        const url = `/api/search/results/make/permanent/${profileID}/${notifIDs}`;
        return await GenericCalls.get(url);
    }

    static async getClipsStates(notifIds: string) {
        const url = `/workflow?action=getStates&ids=${notifIds}&cl=0&outputType=JSON`;
        return await GenericCalls.get(url);
    }

    static async updateClipState(notifId: string, cas: number, cl: number, action: string, data?:{[key: string]: string}, hideErrors?: [number]) {
        const url = `/workflow?action=${action}&id=${notifId}&cas=${cas}&cl=${cl}&${data?.headline ? 'head=' + data?.headline + '&' : ''}${data?.summary ? 'summary=' + data?.summary + '&' : ''}${data?.sentiment ? 'sent=' + data?.sentiment + '&' : ''}kfurl=&uv&outputType=JSON`;
        return await GenericCalls.get(url, undefined, undefined, hideErrors);
    }

    static async clipStatesUpdateObserver(cl: number, abortSignal?:AbortSignal, hideErrors?: boolean){
        const url = `/wFeed?cl=${cl}&timeout=90`;
        return await GenericCalls.get(url,undefined,{abortSignal}, hideErrors);
    }
}

export default APIClips;