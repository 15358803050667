import { cacheBust } from 'src/Widgets/common/helpers';
import GenericCalls from './genericCalls';

class APIMe {
    static async get() {
        let d = await GenericCalls.get('/api/me');
        let res = d.data;
        res.timezone = res.TZ;
        res.username = res.name;
        res.mediaEditor_highlightWhilePlaying = Boolean(res.mediaEditor_highlightWhilePlaying);
        if(res.managedCompanyGroups !== null 
            && res.managedCompanyGroups !== undefined
            && res.managedCompanyGroups.length > 0)
        {
            res.isCompanyGroupAdmin = true; // defines if companyGroupCards widget should be available
        }
        res.can_AutoSummarize = Number(res.can_AutoSummarize);  // convert to number (can be string - "0", "1", null)
        delete res.TZ;
        delete res.user;
        return d;
    }

    static async getLanguage() {
        const res = await GenericCalls.get('/api/me/language');
        return res && res.data && res.data.language ? res.data.language : 'en';
    }

    static async getTranslation(language) {
        let translations = {};
        const files = [
            // `t_custom_iframe.json`,
            // `t_academy.json`,
            // `t_quiz.json`,
            `t_board.json`,
            `t_channels.json`,
            `t_coverage.json`,
            `t_framework.json`,
            `t_goto.json`,
            `t_locale.json`,
            `t_login.json`,
            `t_tmo.json`,
//            `t_company_manager.json`,
            `t_profiles.json`,
            `t_reporting.json`,
            `t_schedules.json`,
            `t_search.json`,
            `t_settings.json`,
            `t_waterfall.json`,
            `t_welcome.json`,
            `t_widget_selection.json`,
            `t_query_visualization.json`,
            `t_trial_tour.json`,
            `t_search_agents.json`,
            `translations.json`,
        ];

        for (let i = 0; i < files.length; i++){
            const res = await GenericCalls.get(cacheBust(`${process.env.PUBLIC_URL}/translations/${language}/${files[i]}`));
            translations = {
                ...translations,
                ...res
            }
        };
        return translations;
    }

    // gets list for mapping two letter language codes to language name (e.g.: 'en' --> 'english')
    static async getLanguages(language) {
        return await GenericCalls.get(cacheBust(`${process.env.PUBLIC_URL}/translations/${language}/languages.json`));
    }

    static async put(data) {
        data.mediaEditor_highlightWhilePlaying = Number(data.mediaEditor_highlightWhilePlaying);
        return await GenericCalls.put('/api/me/', data);
    }

    static async shareGroups() {
        return await GenericCalls.get('/api/me/share-groups');
    }

    static async company() {
        return await GenericCalls.get('/api/me/company');
    }

    static async companyUsers() {
        return await GenericCalls.get('/api/me/company/users');
    }

    static async channelGroups() {
        return await GenericCalls.get('/api/me/channel-groups');
    }
}

export default APIMe;
