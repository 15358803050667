import GenericCalls from "./genericCalls";
import _ from 'lodash';

class APIChannelGroups {
    static async get(id) {
        if (id) return await GenericCalls.get('/api/channel-groups/' + id);
        else {
            let rv = await GenericCalls.get('/api/channel-groups');
            rv.data = _.map(rv.data.groups, (g) => {
                g.groups = g.subGroupIDs;
                delete g.subGroupIDs;
                g.channels = g.channelIDs.map(cid => _.find(rv.data.channels, { id: cid }));
                delete g.channelIDs;
                return g;
            });
            return rv;
        }
        // else return mock;
    }

    static async search(searchTerm) {
        return await GenericCalls.get('/api/channel-groups/search/' + searchTerm);
    }
 
    static async languages(id) {
        id = id === null ? 0 : id;
        return await GenericCalls.get('/api/channel-groups/' + id + '/languages');
    }

    static async getAdminGroups() {
        return await GenericCalls.get('/api/channel-groups/admin/list');
    }

    static async post(data, hideErrors) {
        return await GenericCalls.post('/api/channel-groups/', data, undefined, hideErrors);
    }

    static async put(id, data, hideErrors) {
        return await GenericCalls.put('/api/channel-groups/' + id, data, undefined, hideErrors);
    }
    
    static async delete(id, hideErrors) {
        return await GenericCalls.delete('/api/channel-groups/' + id, hideErrors);
    }

    static async addChannels(channelGroupId, channelIdsArr) {
        return await GenericCalls.post(`/api/channel-groups/${channelGroupId}/channels/${channelIdsArr}`);
    }

    static async removeChannels(channelGroupId, channelIdsArr) {
        return await GenericCalls.delete(`/api/channel-groups/${channelGroupId}/channels/${channelIdsArr}`);
    }

    static async addGroups(channelGroupId, channelGroupIdsArr) {
        return await GenericCalls.post(`/api/channel-groups/${channelGroupId}/groups/${channelGroupIdsArr}`);
    }
    
    static async removeGroups(channelGroupId, channelGroupIdsArr) {
        return await GenericCalls.delete(`/api/channel-groups/${channelGroupId}/groups/${channelGroupIdsArr}`);
    }

    static reduceUpdate(data) {
        return _.map(data, o => ({
            id: o.id,
            inGroup: o.isSelected,
        }));
    }
}

// let mock = [
//     {
//         id: 1,
//         title: 'Some Channelgroup Name',
//         channelGroups: [],
//         channels: {
//             tv: [
//                 {
//                     id: 123,
//                     title: 'Channel Name',
//                     icon: 'https://via.placeholder.com/100'
//                 }
//             ],
//             radio: [
//                 {
//                     id: 124,
//                     title: 'Channel Name',
//                     icon: 'https://via.placeholder.com/100'
//                 }
//             ]
//         }
//     },
//     {
//         id: 2,
//         title: 'Some Channelgroup Name 2',
//         channelGroups: [1],
//         channels: {
//             tv: [
//                 {
//                     id: 125,
//                     title: 'Channel Name',
//                     icon: 'https://via.placeholder.com/100'
//                 }
//             ],
//             radio: [
//                 {
//                     id: 126,
//                     title: 'Channel Name',
//                     icon: 'https://via.placeholder.com/100'
//                 }
//             ]
//         }
//     }
// ];

export default APIChannelGroups;