import { FC } from 'react';
import { MentionCardProps } from './MentionCard.types';
import styles from './MentionCard.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import defaultIcon from '../../../../assets/defaultChannelIcon.png';
import defaultKeyframe from '../../../../assets/thumbnail.png';
import defaultRadioKeyframe from '../../../../assets/radio.jpg'
import LoadingSpinner from '../../basicElements/LoadingSpinner/LoadingSpinner';
import useChannelIcons from 'src/hooks/useChannelIcons';

export const highlightChannelName = (inputString: string, searchText: string) => {
    const parts = inputString?.split(searchText);
    return parts?.map((part: string, index: number) => {
        if (index === parts.length - 1) {
            return part;
        } else {
            return (
                <span key={index}>
                    {part}
                    <span className={styles.highlightedChannelName}>
                        {searchText}
                    </span>
                </span>
            );
        }
    });
};

const MentionCard: FC<MentionCardProps> = ({ cardData, isTopicMentionDetails, descriptionRef, index, notificationData }) => {
    
    const { findChannelIconsById } = useChannelIcons();

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const conditionalSentimentIcon = () => {
        switch (cardData.sentiment) {
            case 'positive':
                return (
                    <FontAwesomeIcon
                        title={cardData.sentiment}
                        className={styles.sentiment}
                        style={{ color: 'green' }}
                        size="2x"
                        icon={faThumbsUp}
                        data-testid="sentiment-icon"
                    />
                );
            case 'neutral':
                return (
                    <FontAwesomeIcon
                        title={cardData.sentiment}
                        className={styles.sentiment}
                        style={{ rotate: '-90deg', color: 'gray' }}
                        size="2x"
                        icon={faThumbsUp}
                        data-testid="sentiment-icon"
                    />
                );
            case 'negative':
                return (
                    <FontAwesomeIcon
                        title={cardData.sentiment}
                        className={styles.sentiment}
                        style={{ color: 'red' }}
                        size="2x"
                        icon={faThumbsDown}
                        data-testid="sentiment-icon"
                    />
                );
            default:
        }
    };

    return (
        <div
            className={styles.mentionCardContainer}
            onClick={() => openInNewTab(notificationData[index]?.viewLink)}
        >
            {notificationData[index]?.desc ? <>
              <div className={styles.keyframeSection}
            >
                <img
                    className={styles.keyframe}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = defaultKeyframe;
                    }}
                    src={notificationData[index]?.mediatype === 'radio' ? defaultRadioKeyframe : notificationData[index].kfLink}
                    alt="keyframe"
                />
                {notificationData[index]?.mediatype !== 'web' &&<FontAwesomeIcon className={styles.playIcon} size="2x" icon={faPlay} />}
            </div>
            <div className={styles.titleArea}>
                <h3 className={styles.title}>
                    <span>{notificationData[index]?.beginF}</span>
                    <img
                        className={styles.channelIcon}
                        src={!isTopicMentionDetails ? cardData.icon : findChannelIconsById(cardData.channelNumber)}
                        alt="icon"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = defaultIcon;
                        }}
                    />
                    {highlightChannelName(notificationData[index]?.channel, cardData.channelName)}

       
                        {notificationData[index]?.program && '-'} {notificationData[index]?.program}

                </h3>
                <div
                    dangerouslySetInnerHTML={{
                        __html: notificationData[index]?.desc.length > 336
                            ? notificationData[index]?.desc.slice(0, 336) + '...'
                            : notificationData[index]?.desc
                    }}
                    ref={descriptionRef}
                    className={styles.description}
                ></div>
            </div>
            {!isTopicMentionDetails && conditionalSentimentIcon()}
            </> : <LoadingSpinner size='4rem'/>}
        </div>
    );
};

export default MentionCard;
