import { addDaysToDate, dateToString, getDateRangeArrFormated, substrDaysFromDate } from "src/Widgets/common/helpers";


// generate dates for last 6 days + today and use them in data
const now = new Date();
const dates = getDateRangeArrFormated(substrDaysFromDate(now, 6), now);


const scopeOfMentionsData = [
        {
          "data": {
            "national": 3499,
            "regional": 3432,
            "intl": 449,
            "sports": 23,
            "null": 17
          },
          "name": dates[0]
        },
        {
          "data": {
            "regional": 4305,
            "national": 3885,
            "intl": 516,
            "sports": 75,
            "null": 16
          },
          "name": dates[1]
        },
        {
          "data": {
            "regional": 4194,
            "national": 3641,
            "intl": 537,
            "sports": 46,
            "null": 13
          },
          "name": dates[2]
        },
        {
          "data": {
            "regional": 3300,
            "national": 2936,
            "intl": 556,
            "sports": 40,
            "null": 11
          },
          "name": dates[3]
        },
        {
          "data": {
            "regional": 2856,
            "national": 2432,
            "intl": 443,
            "sports": 55,
            "null": 7
          },
          "name": dates[4]
        },
        {
          "data": {
            "regional": 3866,
            "national": 3621,
            "intl": 355,
            "sports": 18,
            "null": 14
          },
          "name": dates[5]
        },
        {
          "data": {
            "regional": 4260,
            "national": 3939,
            "intl": 378,
            "sports": 78,
            "null": 12
          },
          "name": dates[6]
        },
        {
          "data": {
            "national": 182,
            "regional": 167,
            "intl": 13,
            "sports": 1
          },
          "name": dates[7]
        }
    ]
export default scopeOfMentionsData;