import { useTranslation } from 'react-i18next';
import styles from './GenericTableSort.module.scss';
import { FC } from 'react';
import { GenericTableSortProps } from './GenericTableSort.types';

const GenericTableSort: FC<GenericTableSortProps> = ({
  openColumnHeader,
  setSortingDirection,
  sortingDirection,
}) => {

  const { t } = useTranslation();

  const handleSortClick = (direction: 'asc' | 'desc') => {
    // Update only the specific key associated with the current openColumnHeader
    setSortingDirection({
      [openColumnHeader]: direction,
    });
  };

  return (
    <div className={styles.sortButton}>
      <button
        className={sortingDirection[openColumnHeader] === 'asc' ? styles.selected : styles.nonSelected}
        onClick={() => handleSortClick('asc')}
      >
        {t('Sort Ascending')}
      </button>
      <button
        className={sortingDirection[openColumnHeader] === 'desc' ? styles.selected : styles.nonSelected}
        onClick={() => handleSortClick('desc')}
      >
        {t('Sort Descending')}
      </button>
    </div>
  );
};

export default GenericTableSort;
