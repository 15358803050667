import { logo } from 'src/assets/themes/themes';
import imgFooter from 'src/assets/mf-wave-pptx.png';
import imgFooterBig from 'src/assets/mf-titleSlide-pptx.png';
import { PptxGenTypes } from './Pptxgen.types';

const getSlideMasters:PptxGenTypes.GetSlideMasters = (layout, logoSize, imgFooterSize, imgFooterBigSize) => {
    const slideMaster = {
        title: "MASTER_SLIDE",
        background: { color: "FFFFFF" },
        objects: [
            {
                image: {
                    x: (layout.width - logoSize.w - 0.35),
                    y: 0.4,
                    w: logoSize.w,
                    h: logoSize.h,
                    path: logo,
                }
            },
            {
                image: {
                    x: 0,
                    y: (layout.height - imgFooterSize.h),
                    w: imgFooterSize.w,
                    h: imgFooterSize.h,
                    path: imgFooter,
                }
            },
            {
                placeholder: {
                    options: {
                        name: "title",
                        type: "title",
                        x: 0.4,
                        y: 0.8,
                        color: '575366',
                        align: 'left',
                        fontSize: '24'
                    },
                }
            },
            {
                placeholder: {
                    options: {
                        name: "report_element_text",
                        type: "body",
                        x: layout.width * 0.65,
                        y: 0,
                        w: layout.width * 0.35,
                        h: layout.height * 1,
                        align:'center',
                        valign:'middle',
                        fontSize: '16'
                    },
                    text: "report-element-text",
                }
            }
        ],
        slideNumber: {
            x: "95%",
            y: "91%",
            color: '000000',
        },
    };
    
    const titleSlideMaster = {
        title: "TITLE_SLIDE",
        background: { color: "FFFFFF" },
        objects: [
            {
                image: {
                    x: 0,
                    y: 0,
                    w: layout.width,
                    h: layout.height,
                    path: imgFooterBig,
                }
            },
            {
                placeholder: {
                    options: {
                        name: "title",
                        type: "title",
                        x: 2.4,
                        y: layout.height/2,
                        w: 5.3,
                        color: 'f35f45',
                        align: 'center',
                        fontSize: '24',
                        bold: false,
                    },
                }
            },
            {
                placeholder: {
                    options: {
                        name: "text",
                        type: "body",
                        x: 2.4,
                        y: layout.height - 0.7,
                        w: 5.3,
                        align:'center',
                        valign:'middle',
                        fontSize: '12',
                        color: '#000000',
                    },
                    text: "text",
                }
            }
        ],
        slideNumber: {
            x: "95%",
            y: "91%",
            color: 'ffffff',
        },
    }

    return {
        titleSlide: titleSlideMaster,
        slide: slideMaster,
    }
}

export {getSlideMasters};