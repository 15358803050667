import { createStore, combineReducers, applyMiddleware, compose } from "redux";

// import historyReducers from "./reducers/reducers.history";
import channelGroupReducers from "./reducers/reducers.channelGroups";
import channelGroupsWidgetReducer from "./reducers/reducers.channelGroupsWidget";
import channelReducers from "./reducers/reducers.channels";
import mentionsReducer from "./reducers/reducers.mentions";

import theme from "./reducers/reducers.theme";
import error from "./reducers/reducers.error";
import workspaces from "./reducers/reducers.workspaces";
import epgHistory from "./reducers/reducers.epgHistory";
import matomo from "./reducers/reducers.matomo";
import me from "./reducers/reducers.me";
import board from "./reducers/reducers.board";
import profileGroups from "./reducers/reducers.profileGroups";
import schedules from "./reducers/reducers.schedules";
import auth from "./reducers/reducers.auth";
import accountSettings from "./reducers/reducers.accountSettings";
import quizzes from "./reducers/reducers.quiz";
import route from "./reducers/reducers.route";
import signup from "./reducers/reducers.signup";
import signupEmail from "./reducers/reducers.signupEmail";
import tour from "./reducers/reducers.tour";
import stripe from "./reducers/reducers.stripe";

import thunk from 'redux-thunk';  // middleware to enable usage of functions for actions (redux)
import { createLogger } from 'redux-logger';

const logger = createLogger({ collapsed: true });

const reducers = combineReducers({
  // historyReducers,
  theme,
  channelGroupReducers,
  channelGroupsWidgetReducer,
  mentionsReducer,
  channelReducers,
  error,
  workspaces,
  epgHistory,
  matomo,
  me,
  board,
  profileGroups,
  schedules,
  auth,
  accountSettings,
  quizzes,
  route,
  signup,
  signupEmail,
  tour,
  stripe,
});

const wrappedReducers = (state, action) => {
  if (action.type === 'RESET_APP') {
    // reset the state of all reducers (except matomo) to their initial values
    state = {
      matomo: state.matomo,
    }
  }
  return reducers(state, action);
}

// state may be addded as param here if we need to "resume" from state stored in localStorage - details see https://stackoverflow.com/questions/36619093/why-do-i-get-reducer-returned-undefined-during-initialization-despite-pr
function configureStore(preloadedState) {
  if (window.__REDUX_DEVTOOLS_EXTENSION__)
    return createStore(
      wrappedReducers,
      // state,
      compose(
        applyMiddleware(thunk, logger),
        
        // required for debugging with chrome "redux-devtools" extension - see https://github.com/zalmoxisus/redux-devtools-extension#usage
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
      )
    );
  
  return createStore(
    reducers, 
    preloadedState || compose(applyMiddleware(thunk, logger))
  );
}

export const store = configureStore()

export default configureStore;