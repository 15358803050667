import React from 'react';
// import styles from './IconCount.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class IconCount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { theme, count, children, title, onClick, showZero } = this.props;

        const faLayersStyles = {};
        if (onClick) {
            faLayersStyles.cursor = 'pointer';
        }

        return (
            <span
                title={title}
                className="fa-layers"
                onClick={onClick ? onClick : undefined}
                style={faLayersStyles}
            >
                {children}
                {count > 0 || showZero ?
                    <span
                        style={{
                            background: theme.secondary,
                            fontSize:'50px'
                        }}
                        className="fa-layers-counter fa-2x"
                    >
                        {count}
                    </span>
                : null}
            </span>
        );
    }
}

const mapStateToProps = state => ({
    theme: state.theme,
});
export default connect(mapStateToProps)(withTranslation()(IconCount));