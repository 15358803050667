import { logoNegative } from 'src/assets/themes/themes';
import styles from './EmmBanner.module.scss';
import React from 'react';
import { useSelector } from 'react-redux';

interface EmmBannerProps {
    children: React.JSX.Element;
}

const EmmBanner = ({ children }: EmmBannerProps) => {
    // @ts-ignore
    const theme = useSelector((state) => state.theme);
    return (
        <article className={styles.loginComponent}>
            <section className={[styles.loginBanner, theme.backgroundPrimary].join(' ')}>
                <img
                    className={styles.bannerImage}
                    alt="emm logo negativ"
                    src={logoNegative}
                />
            </section>
            <section className={styles.loginForm}>{children}</section>
        </article>
    );
};

export default EmmBanner;