import React, { useEffect, useState } from 'react';
import countryPackages from './countryPackages';
import Checkbox from 'src/Widgets/common/basicElements/Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CountryPackageSelection.module.scss';
import BorderBox from 'src/Widgets/common/basicElements/BorderBox/BorderBox';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { useTranslation } from 'react-i18next';
import { CountryPackageSelectionTypes } from './CountryPackageSelection.types';
import { getSubscriptions } from 'src/redux/actions/actions.stripe';
import Checkout from '../Checkout/Checkout';
import APIStripe from 'src/API/APIStripe';
//@ts-ignore
import _ from 'lodash';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import BackButton from 'src/Widgets/common/basicElements/BackButton/BackButton';

function CountryPackageSelection({ selectedPlan, onGoBack }: CountryPackageSelectionTypes.Props) {
    // TODO: implement scope (s,m,l) change
    // const dispatch = useDispatch();
    // @ts-ignore
    const theme = useSelector((state) => state.theme);
    // @ts-ignore
    const { customerId, subscriptions } = useSelector((state) => state.stripe.data);
    const { t } = useTranslation();
    const [selectedCountryPkgs, setSelectedCountryPkgs] = useState(new Array(countryPackages.length));
    const [pricePreview, setPricePreview] = useState<number>();
    const [loadingPricePreview, setLoadingPricePreview] = useState<boolean>();
    const [nextInvoicePricePreview, setNextInvoicePricePreview] = useState<number>();

    useEffect(() => {
        if (!loadingPricePreview) {
            setLoadingPricePreview(true);
            debouncedCalcPricePreview();
        }
    }, [subscriptions, selectedCountryPkgs]);

    const onCountryPkgClick = async(val:boolean, index: number) => {
        setSelectedCountryPkgs(() => {
            let update = [...selectedCountryPkgs];
            update[index] = !update[index] ? val : undefined;
            return update;
        });
    }

    const calcPricePreview = () => {
        (async() => {
            const pricePreview = await APIStripe.getSubscriptionUpdatePricePreview(subscriptions[0]?.id, selectedPlan.priceId, selectedCountryPkgs.filter(cp => cp !== undefined));
            setPricePreview(pricePreview.lines.data[pricePreview.lines.data.length-1].amount / 100);
            setNextInvoicePricePreview(pricePreview?.amount_remaining / 100);
            setLoadingPricePreview(false);
        })()
    }

    const debouncedCalcPricePreview = _.debounce(() => calcPricePreview(), 500, {
        maxWait: 500
    });

    const updateSubscription = () => {
        APIStripe.updateSubscription(subscriptions[0]?.id, subscriptions[0]?.plan?.id, selectedCountryPkgs.filter(cp=>cp !== undefined));
    }

    return (
        <div className={styles.wrapper}>
            <BackButton
                    onClick={onGoBack}
            />
            <div className={styles.contentWrapper}>
                <div className={styles.overview}>
                    <h2 className={theme.textPrimary}>Your Selection</h2>
                    <BorderBox>
                        <p>You have selected a mentionFindr {selectedPlan.title} Subscription</p>
                        <div>
                            <b>Country Packages</b>
                            <div>
                                {selectedCountryPkgs.map((cp, index) => { 
                                    if(cp){ return <div key={index}>{cp.name}</div> }
                                })}
                            </div>
                        </div>
                        <p>
                            <b>Monthly subscription</b>
                        </p>
                    </BorderBox>
                    <BorderBox className={styles.total}>
                        <b>Total Price</b>
                        <span>{`US$ ${selectedPlan.priceCent/100*selectedCountryPkgs.filter(cp=>cp !== undefined).length} per month`}</span>
                    </BorderBox>
                </div>
                <div className={styles.details}>
                    <h2 className={theme.textPrimary}>Subscription Details</h2>
                    <b>Country Packages</b>
                    <div>
                        {countryPackages.map((cp:any, index: number) => {
                            return (
                                <div key={index} className={styles.cp} onClick={(val: any) => onCountryPkgClick(cp, index)}>
                                    <Checkbox
                                        disabled={false}
                                        checked={selectedCountryPkgs[index]}
                                        withBorder={true}
                                    />
                                    {cp.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div>
                {loadingPricePreview ? <LoadingSpinner prependText='Calculating prices' size='1rem' /> : pricePreview &&
                    <>
                        <p>By confirming your selection, your next invoice will be US$ {nextInvoicePricePreview}.</p>
                        <p>Future monthly payments: US$ {pricePreview}</p>
                    </>
                }
                <p>If you want to change your payment options, please use the "My Account" menu section.</p>
            </div>
            
            {subscriptions?.length > 0 
                ? <Button disabled={loadingPricePreview} type={'success'} onClick={() => { updateSubscription(); }}>{t('Buy')}</Button>
             
             : <Checkout
                priceId={selectedPlan.priceId}
                customerId={customerId}
                selectedCountryPackages={selectedCountryPkgs.filter(cp=>cp!== undefined)}
            />}
        </div>
    );
}

export default CountryPackageSelection;
