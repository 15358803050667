import React from 'react';
import { Redirect } from 'react-router-dom';

import '../assets/animate.css';

import { withTranslation } from 'react-i18next';

import APIAuth from '../API/APIAuth';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { connect } from 'react-redux';
import IssueMsg from 'src/IssueMsg/IssueMsg';
import Link from 'src/Widgets/common/basicElements/Link/Link';
import APISettings from 'src/API/APISettings';
import { setSessionExpired, setIsAuthenticated, setIsWrongUser } from 'src/redux/actions/actions.auth';
// import { resetApp } from 'src/redux/actions/actions.reset';
import { clearMe } from 'src/redux/actions/actions.me';
import EmmBanner from './EmmBanner/EmmBanner';
import _ from 'lodash';
import { isMentionFindr, redirectToUrl, urlParamsToObj } from 'src/Widgets/common/helpers';
import styles from './Login.module.scss';
import googleLogo from 'src/assets/google-logo.png';
import linkedinLoginBtn_default from 'src/assets/linkedin_sign_in_default.png';
import linkedinLoginBtn_active from 'src/assets/linkedin_sign_in_active.png';
import APIOAuth2 from 'src/API/APIOauth2';
import TextInput from 'src/Widgets/common/basicElements/TextInput/TextInput';
import Divider from 'src/Widgets/common/basicElements/Divider/Divider';
import APITelemetry from 'src/API/APITelemetry';
import AuthExpired from './AuthExpired/AuthExpired';
import config from 'src/config/config';
import { dynamicConfig } from 'src/config/dynamicConfig/dynamicConfig';
import { ROUTES } from 'src/consts';


class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: this.props.username ? this.props.username : '',
            password: '',
            loginFailed: false,
            linkedinBtnHovered: false,
            showPopup: false,
            // isUser: undefined,
            // google_id_token: undefined,
        };

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.tryLogin = this.tryLogin.bind(this);
    }

    getHashParams(){ return urlParamsToObj(this.props.location?.search, ['redirect', 'g']) };   // url params after hash
    getUrlParams(){ return urlParamsToObj(window.location?.search, ['academy']) };    // url params before hash

    handleUsernameChange(event) {
        this.setState({
            username: event.target.value,
            loginFailed: false
        });
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value, loginFailed: false });
    }


    async componentDidMount() {
        if (this.refUser) this.refUser.focus();
    }

    reportUnknownError = (code, detailCode) => {
        APITelemetry.post({ msg: `#/login: Do not know how to handle code=${code} with 'errorDetail code'=${detailCode}` })
    }

    async tryLogin(
        event,
        from,
        auth = {},   // { google: {id_token: 'Bearer ...'} }
        hashParams,
    ) {
        let authString;
        let basicEncoded = btoa(this.state.username + ':' + this.state.password);
        if (event) { event.preventDefault(); }

        authString = 'Basic ' + basicEncoded;
        
        let authenticated;
        authenticated = await APIAuth.validate(authString);

        if (authenticated === true) {
            if (hashParams?.redirect && redirectToUrl(hashParams.redirect)) {
                // waiting for redirect to happen
                return null;
            } else {
                this.props.setSessionExpired(false);
                if (this.props.closeOnSuccess) {
                    window.close();
                } else {
                    this.props.setIsAuthenticated({isAuthenticated: true, redirectTo: from});
                }
            }
            this.props.setIsWrongUser(false);
        } else if (authenticated.code) {
            // TODO: improvement: refactor DRY - dupl. with error handling from Oauth2Handler ?
            switch (authenticated.code) {
                case '339':
                    this.setState({ loginFailed: true })
                    break;
                case '335':
                    if (authenticated.errorDetails && authenticated.errorDetails.length > 0) {
                        switch (authenticated.errorDetails[0].toString()) {
                            case '20199':   // user expired
                                this.props.history.push('/user-expired');
                                break;
                            case '20196':   // problem
                                this.props.history.push('/user-problem');
                                break;
                            case '20197':   // no permission to manage user
                                this.props.history.push('/user-no-permission');
                                break;
                            case '20198':   // user locked for administrative reasons - contact accounting department
                                this.props.history.push('/user-locked');
                                break;
                            default:
                                this.setState({ loginFailed: true });
                                this.reportUnknownError(authenticated.code, authenticated.errorDetails[0]); break;
                        }
                    }
                    break;
                case '336':
                    if (authenticated.errorDetails && authenticated.errorDetails.length > 0) {
                        switch (authenticated.errorDetails[0].toString()) {
                            case 'EMM00009':
                                this.props.history.push({
                                    pathname: ROUTES.set_password,
                                    state: {
                                        initUsername: this.state.username,
                                        text: <>
                                            <h2 style={{ marginBottom: '0.4rem' }}>{this.props.t('need_to_set_pw_heading')}</h2>
                                            <span>{this.props.t('need_to_set_pw_text')}</span>
                                        </>
                                    }
                                });
                                break;
                            default:
                                break;
                        }
                    }
                default: 
                    this.setState({ loginFailed: true })
                    this.reportUnknownError(authenticated.code, authenticated.errorDetails[0]);
                    break;
            }
        }
        // else if (auth.google && authenticated.code === '334') {    // no user yet. user registration required
            // this.setState({isUser: false, google_id_token: auth.google.id_token});
        // }
        else if (authenticated.status) {
            // if (authenticated.status === 401 && authenticated.statusText === 'Authentication Required') {
            //     // TODO: have better backend response here ? this gets returned if google user is not emm user, but maybe in other cases too?
            //     this.props.history.push({
            //         pathname: '/signup',
            //         search: this.props.location.search,
            //     });
            // }
            this.setState({serverIssue: true})
        } else this.setState({ loginFailed: true });
    }

    render() {
        let from;
        if(this.props.location && this.props.location.state && !this.props.isManualLogout){
            from  = this.props.location.state.from;
        } else{
            from = { pathname: '/' };
        }
        
        const { t, isReAuthentication } = this.props;
        
        const hashParams = this.getHashParams();

        // if(isUser === false){
        //     return (
        //         <SignUp
        //             googleToken={google_id_token}
        //             onDone={()=>{
        //                 // user set up finished --> show application
        //                 this.props.authenticate(from)}
        //             }
        //     />)
        // }

        if (this.props.isAlreadyAuthenticated) {
            if (hashParams?.redirect && redirectToUrl(hashParams.redirect)) {
                // waiting for redirect to happen
                return null;
            } else{
                return <Redirect to={this.props.redirectTo} />;
            }
        }

        const failedLogin = this.state.loginFailed ? (
            <h2 className={`${styles.loginFailed} animated bounceIn`}>
                {t('Login failed')}
            </h2>
        ) : null;

        const inputClass = this.state.loginFailed
            ? `animated shake ${styles.loginFailedInput}`
            : '';
        return (
            <EmmBanner>
                {
                    <div className={styles.loginFormWrapper}>
                        {isReAuthentication &&
                            <AuthExpired
                                onChangeUser={() => {
                                    config?.STORAGE?.clear();
                                    window.location?.reload();
                                }}
                                // onChangeUser={
                                // this.props.history
                                //     ? ()=>{
                                //         this.props.resetApp();  // reset redux store
                                //         this.props.history.push('/login');
                                //         config.STORAGE.clear();
                                //     }
                                //     : undefined
                                // }
                            />
                        }
                        {/* {(urlParams?.academy === '1' || hashParams?.g === '1') && */}
                            <>
                                <div>
                                    <Button
                                        type='white'
                                        onClick={() => APIOAuth2.login(isReAuthentication ? this.props.userid : undefined, 'google')}
                                        style={{width:'100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}
                                    >
                                        <img className={styles.googleLogo} src={googleLogo} alt="Google"/>
                                        {t('log_in_with_google')}
                                    </Button>
                                </div>
                                {/* <Divider>{t('or')}</Divider> */}
                            
                            <div
                                style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                    <Button
                                    type='white'
                                    onMouseEnter={()=>{this.setState({linkedinBtnHovered: true})}}
                                    onMouseLeave={()=>{this.setState({linkedinBtnHovered: false})}}
                                    onClick={() => APIOAuth2.login(isReAuthentication ? this.props.userid : undefined, 'linkedin')} 
                                    style={{padding:0, border:'none'}}
                                    >
                                        <img style={{width:'calc(100% + 0.3rem)', maxWidth:'230px'}} src={this.state.linkedinBtnHovered ? linkedinLoginBtn_active : linkedinLoginBtn_default} alt="Google"/>
                                        {/* {t('log_in_with_linkedin')} */}
                                    </Button>
                                </div>
                                <Divider>{t('or')}</Divider>
                            </>
                        {/* } */}
                        {failedLogin}
                        <div id='eMM-login'>
                            <form>
                                <TextInput
                                    disabled={this.props.usernameDisabled}
                                    className={styles.textInputWrapper}
                                    value={this.props.username || this.state.username}
                                    onChange={this.handleUsernameChange}
                                    onKeyPress={e => e.key === 'Enter' && this.tryLogin(e, from)}
                                    label={t('Username')}
                                    showPlaceholder={false}
                                    inputClassName={`${inputClass} ${styles.textInput}`}
                                />
                                <TextInput
                                    inputType="password"
                                    className={styles.textInputWrapper}
                                    value={this.state.password}
                                    onChange={this.handlePasswordChange}
                                    onKeyPress={e => e.key === 'Enter' && this.tryLogin(e, from)}
                                    label={t('Password')}
                                    showPlaceholder={false}
                                    inputClassName={`${inputClass} ${styles.textInput}`}
                                    labelRight={
                                        <Link
                                            onClick={() => {
                                                this.props.history.push({
                                                    pathname: ROUTES.set_password,
                                                    state: {
                                                        initUsername: this.state.username,
                                                    }
                                                });
                                            }}
                                        >
                                            {`${t('Forgot password')}`}
                                        </Link>
                                    }
                                />
            
                                <div>
                                    <Button
                                        type='secondary'
                                        aria-label="login"
                                        onClick={(e) => {
                                            this.tryLogin(e, from)
                                        }}
                                        style={{width:'100%'}}
                                    >
                                        {t('Log In')}
                                    </Button>

                                    {dynamicConfig.allowEmailSignup && <div style={{ fontSize: '0.8rem', marginTop: '1.2rem' }}>
                                        {t("if_no_account")} <Link onClick={()=>this.props.history.push(ROUTES.sign_up_email)}>{t("sign_up_here")}</Link>
                                    </div>}
                                </div>
                            </form>
                        </div>
                        {this.state.serverIssue ? <IssueMsg showOptions={false} /> : null}
                        {
                            process.env.REACT_APP_ENV === 'testing' ?
                                <div>Hint: Running in mode 'testing'. You may use username: 'admin', password: '0' for login.</div>
                                : null
                        }
            
                    </div>
                }
            </EmmBanner>
        );
    }
}

export default connect(() => ({}), { setSessionExpired, setIsAuthenticated, clearMe, setIsWrongUser })(withTranslation()(Login));