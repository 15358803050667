import {
    SET_WORKSPACE,
    SET_DEPRECATED_WIDGETS,
    IGNORE_DEPRECATED_WIDGETS,
    CLEAR_DEPRECATED_WIDGETS,
    SET_LOADING_GLOBAL,
    SET_SHOW_ACADEMY,
    SET_SHOW_HELP_MENU,
    SET_EDITABLE,
    SET_SHOW_WIDGET_SELECTION,
    SET_SHOW_USER_MENU,
    SET_CHAT_LOADING,
 } from '../types/actionTypes.board';
 import _ from 'lodash';

const workspaceReducers = (state = {
    currentWorkspaceIndex: 0,
    showHelpMenu: false,
    isEditable: false,
    chatIsLoading: false,
}, action) => {
    switch (action.type) {
        case SET_WORKSPACE:
            state.currentWorkspaceIndex = action.data;
            return state;

            
        // deprecated widget = a widget which is returned by /api/settings/widgets/...
        // but not included in availableWidgets list (anymore)
            case SET_DEPRECATED_WIDGETS:
            state.deprecatedWidgets = action.data;
            return state;
        case IGNORE_DEPRECATED_WIDGETS:
            state = _.cloneDeep(state);
            state.ignoreDeprecatedWidgets = true;
            return state;
        case CLEAR_DEPRECATED_WIDGETS:
            state = _.cloneDeep(state);
            state.deprecatedWidgets = undefined;
            return state;
        case SET_SHOW_ACADEMY:
            state = _.cloneDeep(state);
            state.showAcademy = action.data;
            return state;
        case SET_SHOW_HELP_MENU:
            state = _.cloneDeep(state);
            state.showHelpMenu = action.data;
            return state;
            case SET_SHOW_USER_MENU:
                state = _.cloneDeep(state);
                state.showUserMenu = action.data;
                return state;
        case SET_EDITABLE:
            state = _.cloneDeep(state);
            state.isEditable = action.data;
            return state;
        case SET_SHOW_WIDGET_SELECTION:
            state = _.cloneDeep(state);
            state.showWidgetSelection = action.data;
            return state;
        case SET_CHAT_LOADING:
                state = _.cloneDeep(state);
                state.chatIsLoading = action.data;
                return state;
        case SET_LOADING_GLOBAL:
            state = _.cloneDeep(state);
            state.loadingGlobal = action.data;
        default:
            return state;
    }
};

export default workspaceReducers;