export const getSimpleProfiles = (state: any, withGroup:boolean) => {
    return state.profileGroups?.data?.reduce((acc: [], group: any) => {
        return acc.concat(
            group?.profiles.filter((profile: any) => profile.isSuper !== 1).map((profile: any) => (
                withGroup ? { ...profile, group: group } : profile
        ))
    )}, []);
};
export const getProfiles = (state: any, withGroup: boolean) => {
    return state.profileGroups?.data?.reduce((acc: [], group: any) => {
        return acc.concat(
        group?.profiles.map((profile: any) => (
            withGroup ? { ...profile, group: group } : profile
        ))
    )}, []);
};