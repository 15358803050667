import { SET, CLEAR } from '../types/actionTypes.me';

const meReducers = (state = null, action) => {
    switch (action.type) {
        case SET:
            if (action.data && action.data.archive && action.data.archive.userArchiveStart) {
                
                // save max value of db and user archive start date
                action.data.archive.start = new Date(Math.max(action.data.archive.dbArchiveStart, action.data.archive.userArchiveStart) * 1000);

                action.data.archive.userArchiveStart = new Date(action.data.archive.userArchiveStart * 1000);
                action.data.archive.dbArchiveStart = new Date(action.data.archive.dbArchiveStart * 1000);
                action.data.experimentalWidgetsSelectable = action.data.experimentalWidgets === 1;
            }
            return action.data;
        case CLEAR:
            return null;
        default:
            return state;
    }
};

export default meReducers;