import React, { useEffect, useState } from 'react'

const useDragHorizontal = ({itemsRef}: {itemsRef: React.RefObject<HTMLElement>}) => {


    const [isDragging, setIsDragging] = useState(false);
    const [startPosition, setStartPosition] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);

    //handle mouse events for profile titles
    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setStartPosition(e.clientX);
        if (itemsRef.current) {
            setScrollPosition(itemsRef.current.scrollLeft);
        }
    };

    //handle mouse move and up events for profile titles
    const handleMouseMove = (e: MouseEvent) => {
        if (isDragging && itemsRef.current) {
            const newScrollPosition = scrollPosition - (e.clientX - startPosition);
            itemsRef.current.scrollLeft = newScrollPosition;
        }
    };

    //handle mouse up event for profile titles
    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // add event listeners for mouse move and up
    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

  return {
    handleMouseDown
  }
}

export default useDragHorizontal