import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import styles from './Titlebar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { dynamicConfig } from 'src/config/dynamicConfig/dynamicConfig';

library.add(faCreditCard);

const UserMenu = props => (
    <CSSTransition
        in={props.userMenuOpened}
        timeout={300}
        unmountOnExit
        classNames={{ exitActive: `animated ${document.dir === 'rtl' ? 'fadeOutLeft' : 'fadeOutRight'}` }}
    >
        
        <div className={styles.panelWrap} onClick={props.hideUserMenu}>
            <div
                data-tut='tour__usermenu'
                className={classNames(
                    styles.userMenu,
                    `animated ${document.dir === 'rtl' ? 'fadeInLeft' : 'fadeInRight'}`,
                    props.userMenuOpened ? props.theme.backgroundPrimary_reverse : props.theme.backgroundPrimary, 
                    styles.dropUser
                )}
            >
                {props.isNarrowScreen && <NavLink to='#' className={styles.mobileUserName}>
                {<span>
                            <strong>{props.user !== undefined ? props.user.username : ''}</strong>
                        </span>   }
                </NavLink>}
                <NavLink
                    className={classNames(styles.userMenuLink, props.theme.borderSecondary)}
                    to="/about"
                >
                    <FontAwesomeIcon icon="info-circle" />
                    {props.t('About')}
                </NavLink>


                <NavLink
                    className={classNames(styles.userMenuLink, props.theme.borderSecondary)}
                    to="/account/settings"
                >
                    <FontAwesomeIcon icon="cog" />
                    {props.t('Settings')}
                </NavLink>
                {dynamicConfig.useSubscriptions && <NavLink
                    className={classNames(styles.userMenuLink, props.theme.borderSecondary)}
                    to="/account/subscriptions"
                >
                    <FontAwesomeIcon icon="credit-card" />
                    {props.t('My Account')}
                </NavLink>}

                <button
                    className={classNames(styles.userMenuLink, props.theme.borderSecondary)}
                    onClick={props.unauthenticate}
                >
                    <FontAwesomeIcon icon="sign-out-alt" />
                    {props.t('Log Out')}
                </button>
            </div>
        </div>
    </CSSTransition>
);

export default UserMenu;