import logo from 'src/assets/mention-findr-logo.png';
import mf_theme_emm from './emm.module.scss';
import './fontFace.scss';

export {
    logo,
    logo as logoNegative,
};

export default {
    'emm': mf_theme_emm
}; 