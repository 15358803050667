// const workspaces = [
//     {
//         name: 'Demo Manage',
//         layout: [
//             { i: 'profileGroupCards-1', x: 0, y: 0, w: 3, h: 3 },
//             { i: 'channelGroupCards-1', x: 4, y: 0, w: 3, h: 3 },
//             { i: 'companyCards-1', x: 0, y: 4, w: 3, h: 3 },
//             { i: 'companyGroupCards-1', x: 4, y: 4, w: 3, h: 3 },
//             { i: 'shareGroupCards-1', x: 0, y: Infinity, w: 3, h: 3}
//         ]
//     }
// ];

// const getDefaultState = () => {
//     return {
//         settings: {
//             date: { unit: { label: 'days', value: 'days' }, number: 7 }
//         }
//     };
// };

let workspaces = [
    {
        name: 'Recent Clips',
        widgets: [
            // {
            //     "id": 1,
            //     "type": "editorial",
            //     "state": {},
            //     "coordinates": { "x": 0, "y": 0, "w": 2, "h": 1 }
            // },
            {
                "id": 0,
                "type": "clips_recent",
                "state": {},
                "coordinates": { "x": 0, "y": 1, "w": 6, "h": 6 }
            },
        ],
        state: { settings: {} }
    },
    {
        name: 'Edited Clips',
        widgets: [
            {
                "id": 0,
                "type": "editorial2",
                "state": {},
                "coordinates": { "x": 0, "y": 0, "w": 6, "h": 1}
            },
            {
                "id": 1,
                "type": "clips_edited",
                "state": {},
                "coordinates": { "x": 0, "y": 1, "w": 6, "h": 5 }
            },
        ],
        state: { settings: {} }
    },
    {
        name: 'Coverage',
        widgets: [
            {
                id: 0,
                type: 'hitsPerProfile',
                state: {},
                coordinates: { x: 0, y: 0, w: 6, h: 6 },
            }
        ],
        state: { settings: {} }
    },
    {
        name: 'Go To',
        widgets: [
            {
                id: 0,
                type: 'epg',
                state: {},
                coordinates: { x: 0, y: 0, w: 6, h: 6 },
            }
        ],
        state: { settings: {} }
    },
    {
        name: 'Waterfall',
        widgets: [
            {
                id: 0,
                type: 'waterfall',
                state: {},
                coordinates: { x: 0, y: 0, w: 6, h: 6 },
            }
        ],
        state: { settings: {} }
    },
    {
        name: 'Profiles',
        widgets: [
            {
                id: 0,
                type: 'profileGroupCards',
                state: {},
                coordinates: { x: 0, y: 0, w: 6, h: 6 },
            },
            {
                id: 1,
                type: 'schedules',
                state: {},
                coordinates: { x: 0, y: 6, w: 6, h: 6 },
            },
            // {
            //     id: 3,
            //     type: 'shareGroupCards',
            //     state: {},
            //     coordinates: { x: 3, y: 6, w: 3, h: 6 },
            // }
        ],
        state: { settings: {} }
    },
    {
        name: 'Channel Groups',
        widgets: [
            {
                id: 0,
                type: 'channelGroupCards',
                state: {},
                coordinates: { x: 0, y: 0, w: 6, h: 6 },
            },
        ],
        state: { settings: {} }
    },
    {
        name: 'Search',
        widgets: [
            {
                id: 0,
                type: 'search',
                state: {},
                coordinates: { x: 0, y: 0, w: 6, h: 6 },
            }
        ],
        state: { settings: {} }
    },
    {
        name: 'Analytics',
        widgets: [
            {
                id: 0,
                type: "reportTitlePage",
                name: "Title Page",
                state: {
                    showSettings: false
                },
                coordinates: { x: 0, y: 0, w: 3, h: 1 }
            },
            {
                id: 1,
                type: 'sentimentBreakdown',
                state: {
                    showSettings: false,
                    settings: {
                        date: { unit: { label: 'days', value: 'days' }, number: 7 }
                    }
                },
                coordinates: { x: 0, y: 1, w: 3, h: 3 },
            },
            {
                id: 2,
                type: 'languageBreakdown',
                state: {
                    showSettings: false,
                    settings: {
                        date: { unit: { label: 'days', value: 'days' }, number: 7 }
                    }
                },
                coordinates: { x: 3, y: 0, w: 3, h: 3 },
            },
            {
                id: 3,
                type: 'mediaTypeBreakdown',
                state: {
                    showSettings: false,
                    settings: {
                        date: { unit: { label: 'days', value: 'days' }, number: 7 }
                    }
                },
                coordinates: { x: 0, y: 4, w: 3, h: 3 },
            },
            {
                id: 4,
                type: 'scopeBreakdown',
                state: {
                    showSettings: false,
                    settings: {
                        date: { unit: { label: 'days', value: 'days' }, number: 7 }
                    }
                },
                coordinates: { x: 0, y: 7, w: 3, h: 3 },
            },
            {
                id: 5,
                type: 'scopeOfMentions',
                state: {
                    showSettings: false,
                    settings: {
                        date: { unit: { label: 'days', value: 'days' }, number: 7 }
                    }
                },
                coordinates: { x: 3, y: 6, w: 3, h: 4 },
            },
            {
                id: 6,
                type: 'worldMap',
                state: {
                    showSettings: false,
                    settings: {
                        date: { unit: { label: 'days', value: 'days' }, number: 7 }
                    }
                },
                coordinates: { x: 3, y: 6, w: 3, h: 3 },
            },
            {
                id: 7,
                type: 'topicMentionDetailsTable',
                state: {
                    showSettings: false,
                    settings: {
                        date: { unit: { label: 'days', value: 'days' }, number: 7 }
                    }
                },
                coordinates: { x: 0, y: 10, w: 6, h: 3 },
            },
        ],
        state: {
            settings: { date: { unit: { label: 'days', value: 'days' }, number: 7 } },
            showSettings: true
        }
    }
];

export default workspaces;
