// @ts-ignore
import { withRouter } from 'react-router-dom';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EmmBanner from "src/Login/EmmBanner/EmmBanner";
import Button from "src/Widgets/common/basicElements/Button/Button";
import TextInput from "src/Widgets/common/basicElements/TextInput/TextInput";
import LabeledSelect from 'src/Widgets/common/basicElements/LabeledSelect/LabeledSelect';
import Checkbox from 'src/Widgets/common/basicElements/Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { setSignupProps } from 'src/redux/actions/actions.signupEmail';

function EmailSignupDetails({history}:any) {
    const { t } = useTranslation();
    const [selectedTzOption, setSelectedTzOption] = useState();
    const [selectedLanguageOption, setSelectedLanguageOption] = useState();
    const [password, setPassword] = useState('');
    const [tosCheckbox, setTosCheckbox] = useState(false);
    const dispatch = useDispatch();
    // @ts-ignore
    const signupEmail = useSelector((state) => state.signupEmail);

    const timezoneOptions: any = [];
    const languageOptions: any = [];
    
    const urls = {
        termsOfUse: 'https://www.mentionfindr.com/en/terms-of-use/',
        privacyPolicy: 'https://www.mentionfindr.com/en/product-privacy-policy/'
    }

    const onClickNext = () => {
        dispatch(setSignupProps({ ...signupEmail, timezone: selectedTzOption, language: selectedLanguageOption, password }));
        history.push('/sign-up-coverage')
    }

    return (
        <EmmBanner>
            <div style={{flexDirection:'column'}}>
                <h1>{t('Welcome!')}</h1>
                <div>
                <p>
                    </p>
                    {/* @ts-ignore */}
                    {/* <LabeledSelect
                        style={{width:'100%'}}
                        label={t('Timezone')}
                        info={t('info_timezone')}
                        placeholder=''
                        options={timezoneOptions}
                        value={selectedTzOption}
                        onChange={(o: any) => { setSelectedTzOption(o); }}
                    /> */}
                    {/* @ts-ignore */}
                    {/* <LabeledSelect
                        style={{width:'100%'}}
                        label={t('E-Mail Language')}
                        info={t('info_email')}
                        placeholder=''
                        options={languageOptions}
                        value={selectedLanguageOption}
                        onChange={(o: any) => { setSelectedLanguageOption(o); }}
                    /> */}
                    <TextInput
                            value={password}
                            onChange={(e) => { setPassword(e.target.value); }}
                            showPlaceholder={false}
                            label={t('Password')}
                    />
                    {/* {t('If you already have an account click')} <Link onClick={() => { history.push('/login') }}>here</Link> */}
                    <div
                        style={{ display:'flex' }}
                    >
                    <Checkbox
                        disabled={false}
                        onClick={() => { setTosCheckbox(!tosCheckbox) }}
                        checked={tosCheckbox}
                        withBorder={true}
                    />
                    <p>
                        {t('click_agree_tos')} <a target="_blank" rel="noreferrer" href={urls.termsOfUse}>{t('terms_of_use')}</a>, <a target="_blank" rel="noreferrer" href={urls.privacyPolicy}>{t('privacy_policy')}</a>.
                    </p>
                </div>
                    <Button onClick={onClickNext} type="secondary">{t('Continue')}</Button>
                </div>
            </div>
        </EmmBanner>
    )
}

export default withRouter(EmailSignupDetails);