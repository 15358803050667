import APISettings from 'src/API/APISettings';
import { CLEAR_DEPRECATED_WIDGETS, IGNORE_DEPRECATED_WIDGETS, SET_WORKSPACE, SET_LOADING_GLOBAL, SET_SHOW_ACADEMY, SET_SHOW_HELP_MENU, SET_EDITABLE, SET_SHOW_WIDGET_SELECTION, SET_SHOW_USER_MENU, SET_CHAT_LOADING } from '../types/actionTypes.board';
import _ from 'lodash';

export const setCurrentWorkspace = (id) => {
    return (dispatch) => {
        dispatch({
            type: SET_WORKSPACE,
            data: id
        });
    }
};



export const removeAllDeprecatedWidgets = (deprecated, workspaces) => {
    Promise.all(Object.keys(deprecated).map(
        async (workspaceId)=>{
            const workspace = _.find(workspaces, {id: Number(workspaceId)});
            await Promise.all(deprecated[workspaceId].map(widget=>{
                APISettings.deleteItem('widgets', (widget.widgetId));
            }));
            let save = _.cloneDeep(workspace);
            save.widgets = workspace.widgets.map(w=>w.dbId);
            APISettings.putItem('workspaces', workspace.id, save);
        }
    ));

    return (dispatch) => {
        dispatch({
            type: CLEAR_DEPRECATED_WIDGETS,
            data: undefined,
        });
    }
};

export const ignoreDeprecatedWidgets = ()=>{
    return (dispatch) => {
        dispatch({
            type: IGNORE_DEPRECATED_WIDGETS,
            data: undefined,
        });
    }
}

export const setLoadingGlobal = (bool) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING_GLOBAL,
            data: bool,
        });
    }
}

export const setShowAcademy = (bool) => {
    return async (dispatch) => {
        dispatch({
            type: SET_SHOW_ACADEMY,
            data: bool,
        });
    }
}

export const setShowHelpMenu = (bool) => {
    return async (dispatch) => {
        dispatch({
            type: SET_SHOW_HELP_MENU,
            data: bool,
        });
    }
}

export const setShowUserMenu = (bool) => {
    return async (dispatch) => {
        dispatch({
            type: SET_SHOW_USER_MENU,
            data: bool,
        });
    }
}

export const setIsEditable = (bool) => {
    return async (dispatch) => {
        dispatch({
            type: SET_EDITABLE,
            data: bool,
        });
    }
}

export const setShowWidgetSelection = (bool) => {
    return async (dispatch) => {
        dispatch({
            type: SET_SHOW_WIDGET_SELECTION,
            data: bool,
        })
    }
};

export const setChatLoading = (bool) => {
    return async (dispatch) => {
        dispatch({
            type: SET_CHAT_LOADING,
            data: bool,
        });
    }
}