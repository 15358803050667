import _ from 'lodash';
import geographicalBreakDownData from './demoData/geographicalBreakdownData';
import scopeOfMentionsData from './demoData/scopeOfMentionsData';
import topicMentionDetailsTableData from './demoData/topicMentionsDetailsTableData';
import languageBreakdownData from './demoData/languageBreakdownData';

export function getFakeChartData(dataSourceType, groupBy, select) {
  
  if (_.isEqual(groupBy, ['sentiment']) && _.isEqual(select, ['_count'])) {
    // sentiment breakdown
    return [
      { name: 'positive', _count: 55 },
      { name: 'negative', _count: 10 },
      { name: 'neutral', _count: 35 }
    ]
  } else if (_.isEqual(groupBy, ['type']) && _.isEqual(select, ['_count'])) {
    // media type breakdown
    return [
      { name: 'FM Radio', _count: 5 },
      { name: 'TV', _count: 50 },
      { name: 'AM Radio', _count: 5 },
      { name: 'Web', _count: 20 },
      { name: 'Social TV', _count: 20 }
    ]
  } else if (_.isEqual(groupBy, ['scope']) && _.isEqual(select, ['_count'])) {
    // scope breakdown
    return [
      { name: 'regional', _count: 26380 },
      { name: 'national', _count: 24135 },
      { name: 'intl', _count: 3247 },
      { name: 'Sports', _count: 336 },
      // { name: null, _count: 90 },
    ]
  } else if (_.isEqual(groupBy, ['language']) && _.isEqual(select, ['_count'])) {
    // language breakdown
    return languageBreakdownData;
  } else if (_.isEqual(groupBy, ['cc']) && _.isEqual(select, ['_count'])) {
    // geographical breakdown
    return geographicalBreakDownData;
  } else if (_.isEqual(groupBy, ['userdate', 'scope']) && _.isEqual(select, ['_count', 'scope'])) {
    // scope of mentions
    return scopeOfMentionsData;
  } else if (_.isEqual(groupBy, ['type']) && _.isEqual(select, ['_count', 'ave', 'reach'])) {
    // topic mentions details table
    return topicMentionDetailsTableData;
  }
}