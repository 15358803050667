import React from 'react';
import styles from './NotificationPanel.module.scss';
import classNames from 'classnames';
import _ from 'lodash';
import NotificationCard from './NotificationCard/NotificationCard';
import AllDone from './AllDone/AllDone';
import { CSSTransition } from 'react-transition-group';
import '../../assets/animate.css';
import TabBar from '../common/basicElements/TabBar/TabBar';

import testData from './testdata';
import LoadingSpinner from '../common/basicElements/LoadingSpinner/LoadingSpinner';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// FontAwesome Base
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../common/basicElements/Button/Button';

// fontawesome icons
library.add(faCheckDouble);

/**
 * Notification Panel
 * to be used as widget, or as notification center in main application
 *
 * props:
 *  - notifications: array[2] (unread, read) of arrays[] of notification objects (see api)
 *  - updateNotificationStatus: func(), call to update notification status to read
 */
class NotificationPanel extends React.Component {
    views = {
        unread: 0,
        read: 1
    };

    constructor(props) {
        super(props);

        this.state = {
            activeView: 0,
            notifications: undefined
        };

        this.setActiveView = this.setActiveView.bind(this);
        this.removeNotification = this.updateReadNotifications.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.readAllNotifications = this.readAllNotifications.bind(this);
    }

    componentDidMount() {
        // TODO: remove test mock
        if (this.props.notifications === undefined) {
            this.setState({
                notifications: [
                    this.buildState(_.cloneDeep(testData)),
                    this.buildState(_.cloneDeep(testData))
                ]
            });
        } else {
            this.setState({
                notifications: [
                    this.buildState(this.props.notifications[0]),
                    this.buildState(this.props.notifications[1])
                ]
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.notifications, this.props.notifications)) {
            this.componentDidMount();
        }
    }

    buildState(apiNotifications) {
        return _.map(apiNotifications, n => ({
            id: n.id,
            __html: n.content,
            ref: n.ref,
            mounted: true
        }));
    }

    setActiveView(activeView) {
        this.setState({ activeView });
    }

    closeNotification(view, index) {
        this.setState(state => {
            state.notifications[view][index].mounted = false;
            return state;
        });
    }

    updateReadNotifications() {
        const unread = this.views.unread;

        this.setState(state => {
            // work backwards so splicing works without issues
            for (let i = state.notifications[unread].length - 1; i >= 0; i--) {
                if (state.notifications[unread][i].mounted) continue;

                let readNotif = _.cloneDeep(state.notifications[unread][i]);
                readNotif.mounted = true;

                state.notifications[this.views.read].unshift(readNotif);
                state.notifications[unread].splice(i, 1);

                if (this.props.updateNotificationStatus)
                    this.props.updateNotificationStatus(i);
            }

            return { notifications: state.notifications };
        });
    }

    readAllNotifications() {
        this.setState(state => {
            const newUnreads = _.map(state.notifications[this.views.unread], notif => {
                notif.mounted = false;
                return notif;
            });

            return { notifications: [newUnreads, state.notifications[1]] };
        });
    }

    renderNotificationCard(view, content, index) {
        return (
            <CSSTransition
                key={index}
                in={
                    this.state.notifications[view][index] &&
                    this.state.notifications[view][index].mounted
                }
                timeout={300}
                classNames={{
                    exitActive: 'animated slideOutRight'
                }}
                onExited={() => this.updateReadNotifications()}
                unmountOnExit
            >
                <NotificationCard
                    dangerouslySetInnerHTML={content}
                    link={content.ref}
                    closeNotification={
                        view === this.views.unread
                            ? () => this.closeNotification(view, index)
                            : null
                    }
                />
            </CSSTransition>
        );
    }

    renderAllDone(view) {
        return (
            <CSSTransition
                in={this.state.notifications[view].length === 0}
                timeout={{ enter: 1000 }}
                classNames={{
                    enter: 'animated fadeIn'
                }}
                unmountOnExit
            >
                <AllDone />
            </CSSTransition>
        );
    }

    renderReadAllButton() {
        if (this.state.notifications[this.views.unread].length === 0) return null;

        // translation method with fallback
        const t = this.props.t || (k => k);

        return (
            <Button
                btnClass={classNames(
                    styles.readAll,
                    this.props.theme.textSuccess
                )}
                onClick={this.readAllNotifications}
                title={t('Mark all as read')}
            >
                <FontAwesomeIcon icon="check-double" />
            </Button>
        );
    }

    render() {
        if (!this.state.notifications) return <LoadingSpinner />;

        return (
            <div className={styles.wrapper}>
                <TabBar
                    tabs={_.keys(this.views)}
                    active={this.state.activeView}
                    onClick={this.setActiveView}
                />

                <CSSTransition
                    in={this.state.activeView === this.views.read}
                    timeout={0}
                    classNames={{
                        enterDone: styles.panelRight
                    }}
                >
                    <div className={styles.panel}>
                        <div className={styles.sidebyside}>
                            {this.renderAllDone(this.views.unread)}
                            {_.map(
                                this.state.notifications[this.views.unread],
                                (content, index) =>
                                    this.renderNotificationCard(
                                        this.views.unread,
                                        content,
                                        index
                                    )
                            )}
                            {this.renderReadAllButton()}
                        </div>

                        <div className={styles.sidebyside}>
                            {_.map(
                                this.state.notifications[this.views.read],
                                (content, index) =>
                                    this.renderNotificationCard(
                                        this.views.read,
                                        content,
                                        index
                                    )
                            )}
                        </div>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

const mapStateToProps = state => ({ theme: state.theme });
export default connect(mapStateToProps)(withTranslation()(NotificationPanel));
