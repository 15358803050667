export namespace MessageHandlerTypes {
    export type Component = 'editorial' // | '...';
}

export class MessageHandler{
    contentWindow?: Window | null;
    allowedOrigin: string = window.location.origin;
    private eventHandlers: Map<MessageHandlerTypes.Component, (event: MessageEvent) => void>;

    constructor(contentWindow?:Window|null, origin?: string) {
        this.contentWindow = contentWindow;
        this.allowedOrigin = origin || this.allowedOrigin;
        this.eventHandlers = new Map();
    }

    setWindow(window:Window|null) {
        this.contentWindow = window;
    }

    getWindow() {
        return this.contentWindow
    }

    send(component: MessageHandlerTypes.Component, type: string, payload: any, window?: Window|null) {
        window = window || this.contentWindow;
        // window not available
        if (!window) {
            console.log('send aborted: no window');
            return;
        }

        // Data to send
        const message = { component: `${component}`, type: `${type}`, payload };

        // Send message (to same origin only)
        window.postMessage(message, this.allowedOrigin);
    }

    private onListen(event:MessageEvent, component:MessageHandlerTypes.Component, callback: Function) {
        // Do we trust the sender of this message? (might be
        // different from what we originally opened, for example).
        if (
            event.origin !== this.allowedOrigin
            || event.data.component !== component
        ) {
            // console.log('received event ignored: either not same origin or component', event, event.data.component, component, event.origin, this.allowedOrigin)
            return;
        }
        
        if (callback) { callback(event); }
    }

    listen(component: MessageHandlerTypes.Component, eventHandlerCallback: Function) {
        const handler = (event: MessageEvent) => this.onListen(event, component, eventHandlerCallback);
        this.eventHandlers.set(component, handler);
        window.addEventListener(
            "message",
            handler,
            false,
        );
    }

    //remove listener
    removeListener(component: MessageHandlerTypes.Component, eventHandlerCallback: Function) {
        const handler = this.eventHandlers.get(component);
        if(handler){
            window.removeEventListener(
                "message",
                handler,
                false,
            );
            this.eventHandlers.delete(component);
        }
    }

    // destroy() {
    //     window.removeEventListener(
    //         "message",
    //         this.onListen,
    //         false,
    //     );
    // }
}