import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { dateToString } from '../../common/helpers';
import { withTranslation } from 'react-i18next';
import styles from './SearchHistoryContent.module.scss';

/**
 * props:
 *  - channels (list of all channels - needed to render channel names of history entries)
 */

class SearchHistoryContent extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired // description of prop goes here
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const data = this.props.data;
        const channels = this.props.channels;
        const channelGroups = this.props.channelGroups;
        data.dateFrom = dateToString(data.dateFrom);
        data.dateTo = dateToString(data.dateTo);

        // translation method with fallback
        const t = this.props.t || (k => k);

        return (
            <div>
                <table className={styles.table}>
                    <tbody>
                        {data.lastXNumber ? (
                            <tr>
                                <td>{t('Last')}</td>
                                <td>{data.lastXNumber + ' ' + data.lastXUnit}</td>
                            </tr>
                        ) : null}
                        {data.dateFrom ? (
                            <React.Fragment>
                                <tr>
                                    <td>{t('From')}</td>
                                    <td>{data.dateFrom}</td>
                                </tr>
                                <tr>
                                    <td>{t('To')}</td>
                                    <td>{data.dateTo}</td>
                                </tr>
                            </React.Fragment>
                        ) : null}
                        {data.channelGroupId ? (
                            <tr>
                                <td>{t('Channel Group')}</td>
                                <td>
                                    {_.map(channelGroups, c => {
                                        return c.id === data.channelGroupId
                                            ? c.title
                                            : null;
                                    })}
                                </td>
                            </tr>
                        ) : null}
                        <tr>
                            <td>{t('Sort By')}</td>
                            <td>{data.rankBy}</td>
                        </tr>
                        {data.channelIds && data.channelIds.length > 0 ? (
                            <tr>
                                <td colSpan="2">
                                    <React.Fragment>
                                        <b>{t('Channels')}:</b>
                                        <br />
                                        {_.map(data.channelIds, (sc, index) => {
                                            return _.map(channels, c => {
                                                if (c.id === sc) {
                                                    if (
                                                        index ===
                                                        data.channelIds.length - 1
                                                    )
                                                        return c.text;
                                                    else {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {c.text}
                                                                <br />
                                                            </React.Fragment>
                                                        );
                                                    }
                                                }
                                            });
                                        })}
                                    </React.Fragment>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            </div>
        );
    }
}
export default withTranslation()(SearchHistoryContent);
