import React, { useState } from 'react'

const useGenericTableToggleFilterState = () => {
    
    const [openColumnHeader, setOpenColumnHeader] = useState<string | null>(null);

    const handleColumnClick = (e: React.MouseEvent, columnHeader: string) => {
        e.stopPropagation();
        setOpenColumnHeader(openColumnHeader === columnHeader ? null : columnHeader);
    };

    return{handleColumnClick, openColumnHeader, setOpenColumnHeader}
}

export default useGenericTableToggleFilterState