import { useTranslation } from 'react-i18next';
import styles from './CourseOverview.module.scss';
import Button from '../../common/basicElements/Button/Button';
import { NavLink } from 'react-router-dom';

function CoursesOverview({
    courses
}) {

    const { t } = useTranslation();

    const renderCourse = (index, imgSrc, imgAlt, heading, description, workspace) => {
        return (
            <div key={index} className={styles.course}>
                <div className={styles.content}>
                    <img className={styles.image} src={imgSrc} alt={imgAlt}/>
                    <div className={styles.description}>
                        <h4>{heading}</h4>
                        {description}
                    </div>
                    <NavLink to={'/workspaces/' + (workspace)}>
                        <Button type='success'>{t('Continue')}</Button>
                    </NavLink>
                </div>
                <div>
                
                </div>
            </div>
        )
    }

    return (
        <div className={styles.wrapper}>
            <h2>Courses Overview</h2>
            <div className={styles.coursesOverview}>
                {courses.map((c, idx) => renderCourse(idx, c.img, c.imgAlt, c.heading, c.description, c.workspace))}
            </div>
        </div>
    )
}

export default CoursesOverview;
