// export default [
//     {
//         mounted: true,
//         __html:
//             "<div style='display: flex; align-items: center;'><img style='margin-inline-end: .2rem;' src='https://via.placeholder.com/100' /><div><h3>This is notification 1</h3><p>Lorem ipsum dolor sit amet, nec alia sint cu, ius ea epicuri adipiscing. Mea integre diceret in, ea nec graeci possit iriure, pri invidunt comprehensam no. .</p></div></div>"
//     },
//     {
//         mounted: true,
//         __html:
//             '<h3>This is notification 2</h3><p>Lorem ipsum dolor sit amet, nec alia sint cu, ius ea epicuri adipiscing. Mea integre diceret in, ea nec graeci possit iriure, pri invidunt comprehensam no. No usu erat saperet constituam. Cu vim dicat sonet, tritani molestiae nec ei. Eum impetus aliquando eu, habemus torquatos assueverit eum ei, putent temporibus per ei.</p>'
//     },
//     {
//         mounted: true,
//         __html:
//             "<table><tbody><tr><td><img style='margin-inline-end: .2rem;' src='https://via.placeholder.com/100' /></td><td><p>This is a notification</p><p>Lorem ipsum dolor sit amet, nec alia sint cu, ius ea epicuri adipiscing. Mea integre diceret in, ea nec graeci possit iriure, pri invidunt comprehensam no</p></td></tr></tbody></table>"
//     },
//     {
//         mounted: true,
//         __html:
//             '<h3>This is notification 4</h3><p>Lorem ipsum dolor sit amet, nec alia sint cu, ius ea epicuri adipiscing. Mea integre diceret in, ea nec graeci possit iriure, pri invidunt comprehensam no.</p>'
//     }
// ];

const testdata = [
    {
        id: 0,
        ref: "google.com",
        content:
            "<div style='display: flex; align-items: center;'><img style='margin-inline-end: .2rem;' src='https://via.placeholder.com/100' /><div><h3>This is notification 1</h3><p>Lorem ipsum dolor sit amet, nec alia sint cu, ius ea epicuri adipiscing. Mea integre diceret in, ea nec graeci possit iriure, pri invidunt comprehensam no. .</p></div></div>"
    },
    {
        id: 1,
        ref: "google.com",
        content:
            '<h3>This is notification 2</h3><p>Lorem ipsum dolor sit amet, nec alia sint cu, ius ea epicuri adipiscing. Mea integre diceret in, ea nec graeci possit iriure, pri invidunt comprehensam no. No usu erat saperet constituam. Cu vim dicat sonet, tritani molestiae nec ei. Eum impetus aliquando eu, habemus torquatos assueverit eum ei, putent temporibus per ei.</p>'
    },
    {
        id: 2,
        ref: "google.com",
        content:
            "<table><tbody><tr><td><img style='margin-inline-end: .2rem;' src='https://via.placeholder.com/100' /></td><td><p>This is a notification</p><p>Lorem ipsum dolor sit amet, nec alia sint cu, ius ea epicuri adipiscing. Mea integre diceret in, ea nec graeci possit iriure, pri invidunt comprehensam no</p></td></tr></tbody></table>"
    },
    {
        id: 3,
        ref: "google.com",
        content:
            '<h3>This is notification 4</h3><p>Lorem ipsum dolor sit amet, nec alia sint cu, ius ea epicuri adipiscing. Mea integre diceret in, ea nec graeci possit iriure, pri invidunt comprehensam no.</p>'
    }
];
export default testdata;