import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './AddButtonSmall.module.scss';
import { addGroup } from 'src/redux/actions/actions.channelGroups';
import { useTranslation } from 'react-i18next';
import { popupMessage, saveStatus } from 'src/redux/actions/actions.channelGroupsWidget';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { AddButtonProps } from './AddButtonSmall.types';
import { trackEvent } from 'src/Widgets/common/helpers';

const AddButtonSmall: FC<AddButtonProps> = ({isAdminUser}: AddButtonProps) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [updatedValue, setUpdatedValue] = useState<string>('');

    const dispatch = useAppDispatch();
    const { saveStatusLog } = useAppSelector((state: any) => state.channelGroupsWidgetReducer);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { t } = useTranslation();

    const matomo = useAppSelector((state) => state.matomo);

    const addGroupConditional = () => {
        if (updatedValue && isOpen) {
            const res = dispatch(addGroup({ title: updatedValue }, [90102]));
            res.then((res:any) => {
                if(res?.rc === 0){
                    dispatch(saveStatus(0))
                    trackEvent(matomo, 'Channel Groups', 'Add Channel Group', {
                        newGroup: updatedValue
                    });
                    /* dispatch(selectGroup(res?.data?.id)) */
                }else{
                    dispatch(saveStatus(res?.rc))
                }   
            }
                
            );
            setIsOpen(false);
        }
    };
    
    useEffect(() => {
        saveStatusLog?.isError &&
            dispatch(
                popupMessage(
                    `${t('cg_could_not_create')} ${updatedValue}. ${t(
                        'cg_title_exists'
                    )}.`
                )
            );
            setUpdatedValue('');
    }, [saveStatusLog?.isError]);
    

    const onEnterKeySubmitHandler = (e:SyntheticEvent) => {
        e.preventDefault();
        addGroupConditional();
    };

    const onClickSubmitHandler = () => {
        //Toggle input if value is empty
        //!updatedValue.length && 
        setIsOpen(!isOpen);
        addGroupConditional();
    };

    const groupInputRender = isOpen && (
        <div className={styles.addInputContainer}>
            <form onSubmit={onEnterKeySubmitHandler}>
                <input
                    type="text"
                    onChange={(e) => setUpdatedValue(e.target.value)}
                    className={styles.addInput}
                    placeholder={t('Group Title')}
                    onBlur={() => inputRef.current?.focus()}
                    ref={inputRef}
                    autoFocus
                    data-testid="add-button-input"
                />
            </form>
        </div>
    );

    return (
        <>
            <div className={styles.addButtonContainer}>
                <Button
                    onClick={onClickSubmitHandler}
                    type="secondary"
                    btnClass="btnRound"
                    btnStyle={{ width: '2.5rem', height: '2.5rem' }}
                    data-testid="add-button"
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
                {groupInputRender}
            </div>
        </>
    );
};

export default AddButtonSmall;
