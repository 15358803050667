import React from 'react'
import ReactSwitch from 'react-switch'
import { AdminRowProps } from './AdminRow.types'
import styles from './AdminRow.module.scss'
import { useTranslation } from 'react-i18next'

const AdminRow = ({setIsAdminGroup, isAdminGroup}: AdminRowProps) => {
  const {t} = useTranslation()
  return (
    <>
    <div className={styles.adminSwitchRow}>
    <h3>{t('Admin Toggle Heading')}</h3>
    <ReactSwitch checked={isAdminGroup} onChange={() => setIsAdminGroup(!isAdminGroup)}/>
    </div>
    <p>{t('Admin Toggle Description')}</p>
    </>
  )
}

export default AdminRow