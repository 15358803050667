import React, { Component } from 'react';
import styles from './Table.module.css';
import classNames from 'classnames';
import _ from 'lodash';
import { randomColor } from 'randomcolor';
import { dateToNoTime, isDateEqual } from '../../common/helpers';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';

// TODO: remove addColumns property and integrate to data property instead ?

class Table extends Component {
    static propTypes = {
        data: PropTypes.array,  // array of table rows - either as [[]] or [{}] (but do not mix within one table). each row may contain a progress value [0-99] to show a loading indicator
        addColumns: PropTypes.array, // adds column to beginning of the table. e.g.: [{ title: 'Profile', propName: 'title', colored: true }]
        showRowSum: PropTypes.bool, // default = true
        
        // [] which may contain values or objects as items.
        // e.g. [
        //      'col1 header title', 
        //      { title: 'col2 header title', type: { name: '%', baseCol: 'col1 header title' } }   // adds column which shows '% of column sum' of other column
        // ]
        header: PropTypes.array
    }

    constructor(props) {
        super(props);

        this.state = {
            sortFunction: null,
            sortedBy: {
                field: null,
                index: null,
                timesClicked: 0
            },
            totals: []
        };

        this.updateSorting = this.updateSorting.bind(this);
        this.monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        this.monthNames = _.map(this.monthNames, m => props.t(m));
        
        this.weekdayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        this.weekdayNames = _.map(this.weekdayNames, d => props.t(d));
    }

    static objSum = (obj) => Object.values(obj).length === 0 ? 0 : Object.values(obj).reduce((a, b) => b ? a + parseFloat(b) : a);    // calculates sum of object values

    generateSorter(descend, field, index, isArray=true) {
        let func;

        if (field === 'data' && index) {
            if (descend) func = (b, a) => a.data[index] - b.data[index];
            else func = (a, b) => a.data[index] - b.data[index];
        } else if (field === 'total') {
            if (descend) func = (b, a) => isArray ? ( _.sum(a.data) - _.sum(b.data) ) : ( Table.objSum(a.data) - Table.objSum(b.data) );
            else func = (a, b) => isArray ? ( _.sum(a.data) - _.sum(b.data) ) : ( Table.objSum(a.data) - Table.objSum(b.data) );
        } else {
            if (descend) func = (b, a) => (a[field] > b[field] ? 1 : -1);
            else func = (a, b) => (a[field] > b[field] ? 1 : -1);
        }

        return func;
    }

    updateSorting(field, index, isArray = true) {
        this.setState(state => {
            let sortFunction;

            if (state.sortedBy.field !== field || state.sortedBy.index !== index) {
                sortFunction = this.generateSorter(false, field, index, isArray);
                state.sortedBy = { field, index, timesClicked: 1 };
            } else if (state.sortedBy.timesClicked === 1) {
                sortFunction = this.generateSorter(true, field, index, isArray);
                state.sortedBy.timesClicked++;
            } else {
                sortFunction = null;
                state.sortedBy = {
                    field: null,
                    index: null,
                    timesClicked: 0
                };
            }

            return {
                sortFunction,
                sortedBy: state.sortedBy
            };
        });
    }

    // pure version of JS Array.prototype.sort
    // doesn't mutate input arr
    // returns input arr if !sorter
    pureSort(arr, sorter) {
        if (!sorter) return arr;

        let sortedArr = _.cloneDeep(arr);
        sortedArr.sort(sorter);
        return sortedArr;
    }

    getTotals(state, showRowSum = true) {
        const isArray = Array.isArray(state[0].data);
        let out = isArray ? [] : {};

        // goes through every object containing data
        // then goes through data array and adds to output array
        for (let i = 0; i < state.length; i++) {
            if (isArray) {
                for (let j = 0; j < state[i].data.length; j++) {
                    if (!out[j]) out[j] = 0;

                    out[j] += state[i].data[j];
                }
            } else {
                let keys = Object.keys(state[i].data)
                for (let j = 0; j < keys.length; j++) {
                    if (!out[keys[j]]) out[keys[j]] = 0;
                    if (state[i].data[keys[j]]) out[keys[j]] += state[i].data[keys[j]];
                }
            }
        }

        if(showRowSum) {
            // calculates total of totals (output array)
            let total = Array.isArray(out) ? _.sum(out) : Table.objSum(out);
            if (total) isArray ? out.push(total) : out['total'] = total;
        }

        return out;
    }
    
    calcLabels(startDate, endDate) {
        // ensure that we calculate with full days only (do not consider time)
        startDate = dateToNoTime(startDate);
        endDate = dateToNoTime(endDate);

        if (!startDate || !startDate.getTime) return [];
        if (!endDate || !endDate.getTime) return [];

        const days = this.calcDayLabels(startDate, endDate);
        const month = this.calcMonthLabel(startDate, endDate);
        
        return { days, month };
    }

    calcDayLabels(startDate, endDate) {
        if (!startDate || !startDate.getTime) return [];
        if (!endDate || !endDate.getTime) return [];

        let days = [];
        let date = new Date(startDate);
        if (date > endDate) return [];

        for (;;) {
            days.push(this.weekdayNames[date.getDay()] + ' ' + date.getDate());
            
            if (isDateEqual(date, endDate)) break;
            
            date.setDate(date.getDate() + 1);
        }

        return days;
    }

    calcMonthLabel(startDate, endDate) {
        if (!startDate || !startDate.getTime) return [];
        if (!endDate || !endDate.getTime) return [];

        let start = this.monthNames[startDate.getMonth()];
        let end = this.monthNames[endDate.getMonth()];

        if (start === end) return start;

        return `${start} - ${end}`;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.data, this.props.data) && this.props.data.length>0) {
            // calculate totals every time when data changes
            this.setState({ totals: this.getTotals(this.props.data, this.props.showRowSum) });
        }
    }

    componentWillMount() {
        if (this.props.data.length > 0) {
            this.setState({ totals: this.getTotals(this.props.data, this.props.showRowSum) });
        }
    }

    render() {
        const { data, theme, t, header } = this.props;
        const { totals } = this.state;

        if (!data || data.length === 0)
            return <section className={styles.wrapper}>{t('No profile selected')}</section>;
        let days, month;
        if (header) {   // use header column labels provided via props
            days = header;
        } else {    // generate date header columns based on provided start/end date
            const labels = this.calcLabels(this.props.startDate || new Date(), this.props.endDate || new Date());
            days = labels.days;
            month = labels.month;
        }

        return (
            <div className={styles.wrapper}>
                <div className={styles.rowWrapper}>
                    <div style={{flexGrow: '1'}}>
                        <Header
                            days={days}
                            month={month}
                            updateSorting={this.updateSorting}
                            sortedBy={this.state.sortedBy}
                            theme={theme}
                            addColumns={this.props.addColumns}
                            t={t}
                            dataIsArray={Array.isArray(this.props.data[0].data)}
                            showRowSum={this.props.showRowSum}
                        />
                    
                        {_.map(this.pureSort(data, this.state.sortFunction), (d, idx) => (
                            <DataRow
                                showRowSum={this.props.showRowSum}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                onSumClick={
                                    this.props.onRowSumClick
                                        ? () => { this.props.onRowSumClick(this.props.startDate, this.props.endDate, d.id, d.title) }
                                        : undefined
                                }
                                onCellClick={
                                    this.props.onDataClick
                                        ? (date, profileId, profileTitle) => (this.props.onDataClick(date, profileId, profileTitle))
                                        : undefined
                                }
                                key={idx}
                                addColumns={this.props.addColumns}
                                data={d}
                                header={this.props.header}
                                t={t}
                                totals={totals}
                            />
                        ))}

                        <Footer
                            addColumns={this.props.addColumns}
                            data={this.state.totals}
                            header={this.props.header}
                            showRowSum={this.props.showRowSum}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const returnSortingClass = (field, index, sortedBy, theme) => {
    if (sortedBy === null) return null;

    if (
        sortedBy.field === field &&
        (sortedBy.index !== undefined ? sortedBy.index === index : true)
    ) {
        return sortedBy.timesClicked === 1
            ? classNames(
                styles.sortedAsc,
                theme.textPrimary,
                theme.borderPrimary
                )
            : sortedBy.timesClicked === 2
            ? classNames(
                styles.sortedDesc,
                theme.textPrimary,
                theme.borderPrimary
                )
            : null;
    }

    return null;
};

const Header = props => (
    <>
        <Row>
            <Cell className={styles.dateSpanning}>
                {props.month}
            </Cell>
        </Row>
        <Row className={styles.header}>
            {
                _.map(props.addColumns, (col, index) => {
                    return (
                        <WideCell key={index} onClick={() => props.updateSorting(col.propName)}>
                            <span
                                className={
                                    returnSortingClass('group', null, props.sortedBy, props.theme)
                                }
                            >
                                {props.t(col.title)}
                            </span>
                        </WideCell>
                    )
                })
            }
            {_.map(props.days, (d, idx) => {
                if (typeof d !== 'string') {
                    d = d.title;
                }
                return (
                    <Cell onClick={() => props.updateSorting('data', props.dataIsArray ? idx : d)} key={idx}>
                        <span
                            className={
                                returnSortingClass('data', props.dataIsArray ? idx : d, props.sortedBy, props.theme)
                            }
                        >
                            {d}
                        </span>
                    </Cell>
                )
            })}
            {props.showRowSum !== false &&
                <BoldCell onClick={() => props.updateSorting('total', undefined, props.dataIsArray)}>
                    <span
                        className={
                            returnSortingClass('total', null, props.sortedBy, props.theme)
                        }
                    >
                        {props.t('Total')}
                    </span>
                </BoldCell>
            }
        </Row>
    </>
);

const Footer = props => {
    return(
        <Row className={styles.footer}>
            {_.map(props.addColumns, (d, idx) => <WideCell key={idx} />)}
            {Array.isArray(props.data)
                ?
                    _.map(props.data, (d, idx) => (
                        <BoldCell key={idx}>{d || '-'}</BoldCell>
                    ))
                :
                _.map(props.showRowSum !== false ? [...props.header, 'total'] : [...props.header], (h, idx) =>
                    <BoldCell key={idx}>{props.data[h] || '-'}</BoldCell>
                )
        }
        </Row>
    )
};

const DataRow = props => {
    return (<Row
        // style={{
        //     backgroundColor: randomColor({
        //         seed: props.profile,
        //         luminosity: 'bright',
        //         format: 'rgba',
        //         alpha: 0.05
        //     })
        // }}
    >
        {_.map(props.addColumns, (c, index) => {
            return (
                <WideCell
                    title={props.data[c.propName]} 
                    onClick={ c.onClick ? 
                            ()=>{ c.onClick(props.data.id, props.data.title, props.startDate, props.endDate)}
                        : undefined
                    }
                    key={index}
                >
                    <span
                        style={c.colored ? {
                            color: randomColor({
                                seed: props.data[c.propName],
                                luminosity: 'bright'
                            })
                        }: null}
                    >
                        {props.data[c.propName]}
                    </span>
                </WideCell>
            )
        })}
        {
            Array.isArray(props.data.data) ?
                _.map(props.data.data, (d, idx) => (
                    <Cell
                        onClick={
                            props.onCellClick
                                ?   () => {
                                        let date = new Date(props.startDate);
                                        date.setDate(date.getDate() + idx);
                                        return props.onCellClick(date, props.data.id, props.data.title);
                                    }
                                : undefined}
                        key={idx}>
                            {(d) || '-'}
                    </Cell>
                ))
            :
                _.map(props.header, (h, idx) => {
                    let value;
                    if (typeof h !== 'string') {
                        if (h.type && h.type.name === '%') {
                            if(props.data.data[h.type.baseCol]){
                                value = `${Math.round(props.data.data[h.type.baseCol] / props.totals[h.type.baseCol] * 100)} %`;
                            }
                        }
                    } else { value = props.data.data[h] }
                    return <Cell
                        onClick={
                            props.onCellClick
                                ?   () => {
                                        let date = new Date(props.startDate);
                                        date.setDate(date.getDate() + idx);
                                        return props.onCellClick(date, props.data.id, props.data.title);
                                    }
                                : undefined}
                        key={idx}>
                        {value || '-'}
                    </Cell>
                })
        }
        {props.showRowSum !== false &&
            <BoldCell
                startDate={props.startDate}
                endDate={props.endDate}
                onClick={props.onSumClick}
            >
            {
                Array.isArray(props.data.data)
                    ? _.sum(props.data.data)
                    : Table.objSum(props.data.data)
            }
            </BoldCell>
        }
        { props.data.progress !== undefined && props.data.progress < 100 &&
            <div className={styles.loadingWrapper}>
                <div
                    style={{
                        width: `${(props.data.progress) || 0}%`
                    }} className={styles.loading}>
            </div>
                <LoadingSpinner
                    prependText={props.t('Loading')}
                    size='1rem'
                    fullSize={false}
                />
            </div>
        }
    </Row>)
};

const Cell = props => (
    <div
        title={props.title}
        className={classNames(styles.cell, props.className, props.onClick ? styles.clickable : undefined)}
        onClick={(date)=>{props.onClick && props.onClick(date)}}
    >
        {props.children}
    </div>
);

const BoldCell = props => (
    <Cell className={classNames(styles.total, props.className)} {...props}>
        {props.children}
    </Cell>
);

const WideCell = props => (
    <BoldCell
        title={props.title}
        className={classNames(styles.wide, props.className, props.onClick ? styles.clickable : undefined)}
        onClick={props.onClick}
    >
        {props.children}
    </BoldCell>
);

const Row = props => (
    <div style={props.style} className={classNames(styles.row, props.className)}>
        {props.children}
    </div>
);

const mapStateToProps = state => ({ theme: state.theme });
export default withTranslation()(connect(mapStateToProps)(Table));
