// @ts-ignore
import { withRouter } from 'react-router-dom';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EmmBanner from "src/Login/EmmBanner/EmmBanner";
import Button from "src/Widgets/common/basicElements/Button/Button";
import LabeledSelect from 'src/Widgets/common/basicElements/LabeledSelect/LabeledSelect';
import { useDispatch, useSelector } from 'react-redux';
import { setSignupProps } from 'src/redux/actions/actions.signupEmail';

function EmailSignupCountryPackage({ history }: any) {
    const { t } = useTranslation();
    const [selectedCountryOption, setSelectedCountryOption] = useState();
    const dispatch = useDispatch();
    // @ts-ignore
    const signupEmail = useSelector((state) => state.signupEmail);

    const countryOptions: any = [];

    const onClickNext = () => {
        dispatch(setSignupProps({ ...signupEmail, countryPackage: selectedCountryOption }));
        history.push('/login');
    }

    return (
        <EmmBanner>
            <div style={{ flexDirection: 'column' }}>
                Please select a country from the list below, for which you want to try MentionFindr for free:
                    {/* @ts-ignore */}
                    <LabeledSelect
                        style={{width:'100%'}}
                        label={t('Country Package')}
                        info={t('info_timezone')}
                        // className={styles.select}
                        placeholder=''
                        // placeholder={t('Country Package')}
                        options={countryOptions}
                        value={selectedCountryOption}
                        onChange={(o: any) => { setSelectedCountryOption(o); }}
                />
                <Button onClick={onClickNext} type="secondary">Continue</Button>
            </div>
        </EmmBanner>
    )
};

export default withRouter(EmailSignupCountryPackage);