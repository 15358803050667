import GenericCalls from './genericCalls';
import _ from 'lodash';
import { dateToString, downloadFile, objectToParamsString } from 'src/Widgets/common/helpers';
import SerialQueue from 'src/common/Queue/SerialQueue';

const q = SerialQueue();

const BASE_URL = '/api/analytics';

class APIReporting {

    // TODO: is mock implementation, should be replaced with actual backend calls later

    // static async getFilters(type) {
    //     switch (type) {
    //         case 'profileGroup': return ['group a', 'group b'];
    //             // break;
    //         case 'profile': return ['profile a', 'profile b'];
    //             // break;
    //     }
        // return await GenericCalls.get('/api/reporting/filters');
    // }

    static async getChartData(dataSourceType, groupBy, filters = {}, select, isXlsx) {
        // switch (dataSourceType) {
        //     case 'count':
        //         const res = [
        //             { "x": "positive", "y": 2 },
        //             { "x": "negative", "y": 1 },
        //             { "x": "neutral", "y": 1 },
        //         ];
        //         return res.map((item) => ({ name: item.x, value: item.y }));
        //     case 'time-based':
        //         break;
        // }

        let url;
        let res;
        let params = {};
        if(filters.date) {
            if(Array.isArray(filters.date)) {
                params.from = dateToString(filters.date[0]);
                params.to = dateToString(filters.date[1]);
            } else {
                params.last = `${filters.date.number}${filters.date.unit.value}`;
            }
        }else{
            params.last = `7days`
        }

        const profileIdModifiedForClipsType = filters.profile.map((profile, index) => (
             `${profile}e`
          ));

        switch (dataSourceType) {
            case 'count':
                url = `${BASE_URL}/${select}/by/${groupBy}/select/profiles/${filters.clipsType !== 'edited' ? filters.profile.join(',') : profileIdModifiedForClipsType}${isXlsx ? '/xlsx' : ''}`;
                res = await q.add(()=>GenericCalls.get(
                    url,
                    params,
                    isXlsx ? { responseType: 'blob' } : undefined,
                ));
                if (isXlsx){ return res; }
                else if(res.data) {
                    let rv = {
                        ...res,
                        data: res.data.map((item) => ({ name: item[groupBy], _count: item._count })),
                    }
                    return rv;
                }
                break;
            case 'time-based':
                url = `${BASE_URL}/${select}/by/${groupBy}/select/profiles/${filters.clipsType !== 'edited' ? filters.profile.join(',') : profileIdModifiedForClipsType}${isXlsx ? '/xlsx' : ''}`;
                res = await q.add(()=>GenericCalls.get(
                    url,
                    params,
                    isXlsx ? { responseType: 'blob' } : undefined,
                ));
                if (res.data) {
                    let rv = res.data;
                    // console.log(_.cloneDeep(rv.sort((a, b) => a[groupBy] > b[groupBy] && 1 || -1)));
                    
                    let group = function(arr, key) {
                        return arr.reduce((rv, obj) => {
                            const val = obj[key];
                            rv[val] = rv[val] || [];
                            rv[val].push(obj);
                          return rv;
                        }, {});
                    };
                      
                    // restructure data
                    const dates = group(rv, 'userdate');
                    let attr = _.cloneDeep(select);
                    attr.splice(select.indexOf('_count'), 1);
                    rv = Object.keys(dates).map((key) => {
                        const date = dates[key];
                        return date.reduce((rv, e) => {
                            rv.name = e['userdate'];
                            rv.data[e[attr[0]]] = e._count;
                            return rv;
                        }, { data: {} })
                    })
                    rv = rv.sort((a, b) => a.name > b.name && 1 || -1);   // ensure dates are in correct order
                    return {
                        ...res,
                        data: rv,
                    };
                }
                break;
            default: break;
        }
    }

    static async getAllHitsXlsx(profiles, dateOptions) {
        // improvement: unify this and move it to getChartData() ?
        if (!dateOptions) { dateOptions = { last: '7days' }; }
        if (dateOptions.last && (dateOptions.from || dateOptions.to)) {
            throw TypeError('param "last" can not be used in combination with "from" or "to"');
        }
        const res = await q.add(()=>GenericCalls.get(
            `${BASE_URL}/all/select/profiles/${profiles}/xlsx${objectToParamsString(dateOptions)}`,
            undefined,
            {responseType: 'blob'},
        ));
        return res;
    }
}

export default APIReporting;
