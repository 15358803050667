import theme_emm from './emm.module.scss';
import theme_emm2 from './emm2.module.scss';
import theme_monochrome from './monochrome.module.scss';
import theme_classic from './classic.module.scss';
import logo from 'src/assets/eMM-Logo-HQ.png';
import logoNegative from 'src/assets/emm-logo-negativ.png';


export {logo, logoNegative}

export default {
    'emm': theme_emm
    , 'emm2': theme_emm2
    , 'monochrome': theme_monochrome
    , 'classic': theme_classic
}