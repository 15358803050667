
import { useMemo, useState } from 'react';
import styles from '../GenericTableFilter/GenericTableFilter.module.scss';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/Widgets/common/basicElements/TextInput/TextInput';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { GenericTableData } from 'src/Widgets/common/GenericTable/GenericTable.types';

const useGenericTableFiltering = (
    checkedState: CheckedState,
    data: GenericTableData,
    header?: string | null,
    currentlyCheckedValues?: any[],
    sortingDirection?: {[key: string]: string},
): UseGenericTableFilteringReturnValues => {
    const {
        convertedData: { columnHeaders, matrix },
        tableData
    } = data;

    const { t } = useTranslation();

    //Define Set and States
    const seenValues = new Set<string>();
    const [searchInputData, setSearchInputData] = useState<string>('');

    const filteredData = useMemo(() => {
        const checkedValues = currentlyCheckedValues || [];
        return matrix
            .map((matrixRow, rowIndex) => ({
                matrixRow,
                tableDataRow: tableData?.[rowIndex]
            }))
            .filter(({ matrixRow }) => {
                let values:any[] = [];
                values = Object.values(matrixRow);
                // If all selected filters are from the same filter box
                if (checkedValues.length === 1) {
                    return checkedValues?.some((filter) =>
                        filter.values?.some((value: any) => values.includes(value))
                );
                } else {
                    // If selected filters are from different filter boxes
                    // Check if the row includes all selected filters
                    return checkedValues?.every((filter) =>
                        filter.values?.some((value: any) => values.includes(value))
                    );
                }
            });
    }, [checkedState, data]);

    //Generating unique checkbox values from currently active column
    const generatedCheckboxes: RowWithIndex[] = useMemo(() => {
        let checkboxes = matrix
            .map((row, originalRowIndex) => ({ row, originalRowIndex }))
            .filter(({ row }) => {
                const value = header && row[columnHeaders.indexOf(header)];
                const stringValue =
                    typeof value === 'string' ? value.toLowerCase() : value?.toString();
                // Check for uniqueness using the Set
                if (!seenValues.has(stringValue)) {
                    seenValues.add(stringValue);
                    return true;
                }
                return false;
            });

        // If searchInputData exists, filter the checkboxes based on the search input
        if (searchInputData) {
            checkboxes = checkboxes.filter(({ row }) => {
                const value = header && row[columnHeaders.indexOf(header)];
                const stringValue =
                    typeof value === 'string' ? value.toLowerCase() : value?.toString();
                return stringValue?.includes(searchInputData.toLowerCase());
            });
        }

        return checkboxes;
    }, [matrix, columnHeaders, header, searchInputData, seenValues]);

    //Filterbox checkbox search handler
    const searchInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInputData(e.target.value);
    };

    //Filterbox checkbox search element creation which is already functional
    const FunctionalSearchComponent = (
        <TextInput
            className={styles.genericTableFilterSearchInput}
            placeholder={t('Search')}
            value={searchInputData}
            onChange={searchInputChangeHandler}
            icon={faSearch}
        />
    );

    //Sorting filtered data
    const sortData = (order = sortingDirection) => {
        //If there is no sort order ignore the whole logic and continue with filteredData
        if (!order || !header || !order[header]) {
            return;
        }
        //If __T__ exists when no translated string used remove logic
        const removePrefixIfExists = (header: string) =>
            header?.startsWith('__T__') ? header.substring(5) : header;
        const removedPrefixHeader = header && removePrefixIfExists(header);

        //Actual data sorting logic - Ascending / Descending orders
        const sortedData = filteredData.sort((a, b) => {
            if (!order && Object.keys(order).length === 0) return 0;
            const valueA =
                removedPrefixHeader &&
                (a.tableDataRow as Record<string, any>)?.[removedPrefixHeader];
            const valueB =
                removedPrefixHeader &&
                (b.tableDataRow as Record<string, any>)?.[removedPrefixHeader];

            if (valueA === valueB) return 0;

            if (typeof valueA === 'number' && typeof valueB === 'number') {
                // Numeric comparison
                return order?.[header] === 'asc' ? valueA - valueB : valueB - valueA;
            } else if (typeof valueA === 'string' && typeof valueB === 'string') {
                // String comparison
                return order?.[header] === 'asc'
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            } else {
                return 0;
            }
        });
        return sortedData;
    };

    const sortedData = sortData(sortingDirection) || filteredData;

//     //Used for determine if shown checkboxes exists in current state of table
//     const isInFilteredData = (checkboxValue: string) => {
//         if(!isOnlyColumnChecked && currentlyCheckedValues?.length === 1) {
//             return filteredData.some((row) => {
//                 return Object.values(row.tableDataRow).some((value: any) => value === checkboxValue);
//             });
//         }else {
//             return filteredData.some((row) => {
//                 return !Object.values(row.tableDataRow).every((value: any) => value === checkboxValue);
//             });
//         }
// };

    
    //Checking if the current filterbox is the only one has checked values
    const isOnlyColumnChecked = Object.keys(checkedState).every((column) => {
        return column === header || !Object.values(checkedState[column]).some(Boolean);
    });

    return {
        filteredMatrixData: sortedData.map(({ matrixRow }) => matrixRow),
        filteredRows: sortedData.map(({ tableDataRow }) => tableDataRow),
        isInFilteredData: () => true,
        isOnlyColumnChecked,
        FunctionalSearchComponent,
        generatedCheckboxes
    };
};

export { useGenericTableFiltering };
